import { List, ListItem } from "@mui/material";
import { Box } from "@mui/system";
import { ItemPublicacionesAsesores } from "./ItemPublicacionesAsesores";

const PublicacionesAsesores = ({ posts, asesor, }) => {
    return(
        <Box>
            <List dense={true}>
                {
                    posts.map((post) => (
                        <ListItem disableGutters>
                            <ItemPublicacionesAsesores post={post} asesor={asesor}/>
                        </ListItem>
                    ))
                }                
            </List>
        </Box>
    );
}

export { PublicacionesAsesores }