import { Alert, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { CheckboxInversion } from "../../../components/ListasClientes/Lista/CheckboxInversion";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const makeLote = (transaccion) => {
    return`Lote:${transaccion.lote.numeroDeLote}, Mzna:${transaccion.lote.manzana.nombre}, ${transaccion.lote.manzana.etapa.fraccionamiento.nombre}, Modelo ${transaccion.lote.fachada.modelo.nombre} `;
};
const Renglones = ({ transaccion}) => {
    let navigate = useNavigate();

    const reedirigirSuccess = () => {
        // window.open('/profile/movimientos');
        navigate(0);
    }
    return(
        <TableRow
            //key={transaccion.name}
            sx={{ '&:last-child td, &:last-child th': { border: 0 }, }}
        >
            <TableCell component="th" scope="row" align="center" sx={{ color:'#707070' }}>
                {`#${transaccion.folio}`}
            </TableCell>
            {/* <TableCell align="left" sx={{ color:'#707070' }}>{transaccion.createdAt}</TableCell> */}
            <TableCell align="left" sx={{ color:'#707070' }}>{moment(transaccion.createdAt).format("DD MMM YYYY")}</TableCell>
            <TableCell align="left" sx={{ color:'#707070' }}>{makeLote(transaccion)}</TableCell>
            <TableCell align="center" sx={{ color:'#707070' }}>
                <CheckboxInversion transaccion={transaccion} inversion={transaccion.separado.inversion} cliente={true} handleChangeProp={reedirigirSuccess}/>
            </TableCell>
            <TableCell align="center" sx={{ color:'#707070' }}><b>{ transaccion.estatus.nombre }</b></TableCell>
        </TableRow>
    );
}
const MisCompras = () => {
    const [open, setOpen] = useState(false);
    const [transacciones, setTransacciones] = useState([]);

    const MostarAlert = () => {
        setOpen(true);
    };
    
    const CerrarAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
    };
    

    useEffect(()=>{
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');
    
        axios.post(`${process.env.REACT_APP_API_URL}/api/clientes/compras/`,{
            folio: folio
        },{
            headers: { 
              Authorization: `Bearer ${token}`,
            }              
        })
        .then((response) => {
            // handle success
            console.log(response);
            setTransacciones(response.data.transacciones);
        })
        .catch((error) => {
          // handle success
          console.log(error);
        })
        .then(() => {
          // handle success
          //console.log('sabe');
        });
    }, [])
    return(
        <Box sx={{ minHeight:840 }}>
            <Helmet>
                <title>Mi Cuenta - Mis movimientos | Casas Pecsa</title>
                <meta name="description" content="Encuentra Casa en Saltillo, ¿No sabes cuál es tu mejor opción de crédito para comprar una casa? Nosotros te ayudamos. Agenda tu cita y obtén Asesoría Gratis." />
            </Helmet>
            <Container>
                <Box>
                    <Typography variant="h6" gutterBottom component="div" sx={{ color:'#F5323F', fontFamily:'Montserrat' }}>
                        <b>Mis movimientos</b>
                    </Typography>
                </Box>
                <Box>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell align="center" sx={{ color:'#707070' }}><b>Folio</b></TableCell>
                                <TableCell align="center" sx={{ color:'#707070' }}><b>Fecha</b></TableCell>
                                <TableCell align="left" sx={{ color:'#707070' }}><b>Lote</b></TableCell>
                                <TableCell align="center" sx={{ color:'#707070' }}><b>Para inversion</b></TableCell>
                                <TableCell align="center" sx={{ color:'#707070' }}><b>Estatus</b></TableCell>
                                {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    transacciones.length < 1 && (
                                        <TableRow
                                        //key={transaccion.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, }}
                                        >                                        
                                            <TableCell align="center" sx={{ color:'#707070' }} colSpan={4}>
                                                <h2>No cuenta con compras realizadas.</h2>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                                {
                                    transacciones.map((transaccion) => (
                                        <Renglones transaccion={transaccion} />
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                
            </Container>
            <Snackbar open={open} autoHideDuration={6000} onClose={CerrarAlert}>
                <Alert onClose={CerrarAlert} severity="success" sx={{ width: '100%' }}>
                    Datos de Usuario Guardados Correctamente
                </Alert>
            </Snackbar>
        </Box>            
    );
}

export { MisCompras }