import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      light: "hsl(356, 100%, 74%)",
      light2: "hsl(356, 100%, 62%)",
      main: "hsl(356, 91%, 58%)",
      dark: "hsl(356, 91%, 44%)",
    },
    secondary: {
      light: "hsl(197, 49%, 76%)",
      main: "hsl(197, 49%, 48%)",
      dark: "hsl(197, 49%, 32%)",
    },
    grey: {
      100: "hsl(0, 0%, 97%)",
      200: "hsl(0, 0%, 94%)",
      300: "hsl(0, 0%, 90%)",
      400: "hsl(0, 0%, 85%)",
      500: "hsl(0, 0%, 80%)",
      600: "hsl(0, 0%, 44%)",
      700: "hsl(0, 0%, 36%)",
      800: "hsl(0, 0%, 28%)",
      900: "hsl(0, 0%, 20%)",
    },
    common: {
      white: "hsl(0, 0%, 98%)",
      white2: "hsl(0, 0%, 95%)",
      black: "hsl(0, 0%, 10%)",
    },
    success: {
      main: "hsl(120, 99%, 37%)",
      light: "hsl(120, 99%, 92%)",
    },
    info: {
      main: "hsl(206, 73%, 47%)",
    },
    error: {
      main: "hsl(0, 99%, 59%)",
    },
    warning: {
      main: "hsl(45, 97%, 51%)",
      light: "hsl(45, 97%, 71%)",
    },
  },

  typography: {
    htmlFontSize: 10,
    heading2: {
      fontSize: "clamp(3rem, 5vw + 1rem, 6rem)",
    },
    heading3: {
      fontSize: "clamp(2.5rem, 5vw + 1rem, 5rem)",
    },
    subheading1: {
      fontSize: "clamp(2rem, 5vw + .5rem, 4rem)",
    },
    subheading2: {
      fontSize: "clamp(1.5rem, 5vw + .3rem, 2rem)",
    },
    body1: {
      fontSize: "1.125rem",
    },
    body2: {
      fontSize: "1rem",
    },
    body3: {
      fontSize: "0.875rem",
    },
    lg: {
      fontSize: "clamp(2.125rem, 10vw + 2rem , 3.75rem)",
    },
  },
});

export default theme;
