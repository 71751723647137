import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { AddIdiomas } from "./AddIdiomas";
import { ListaIdiomas } from "./ListaIdiomas";

import EditIcon from '@mui/icons-material/Edit';

const Idiomas = ({ idiomas }) => {
    const [agregar, setAgregar] = useState(false);
    const getAgregar = () => {
        setAgregar(!agregar);
    }
    
    const [listIdiomas, setListIdiomas] = useState(idiomas);
    const addListIdiomas = (newIdioma) => {
        console.log('adlist', newIdioma);
        setListIdiomas((oldListIdiomas)=>[...oldListIdiomas, newIdioma]);
        console.log('list', listIdiomas);
    }

    return(
        <Box
            sx={{
                minHeight:100,
            }}
        >
            <Typography variant="h5">
                Idiomas
            </Typography>
            <Box
                sx={{
                    width:'100%',
                }}
            >
                {
                    agregar && (
                        <AddIdiomas getAgregar={getAgregar} addListIdiomas={addListIdiomas} />
                    )
                }
                {
                    listIdiomas.length > 0 ? (
                        <ListaIdiomas idiomas={listIdiomas}/>
                    ) : (
                        <Box>
                            <Typography>
                                No hay nada
                            </Typography>                    
                        </Box>
                    )
                }
                {
                    !agregar && (
                        <Box
                            sx={{
                                mt:1,
                                mb:1,
                                // backgroundColor:'red',
                                width:'100%',
                                textAlign:'right',
                            }}
                        >
                            <Button 
                                variant="contained" 
                                endIcon={<EditIcon />}
                                sx={{
                                    backgroundColor:'#707070',
                                    textTransform:'none',
                                    borderRadius:'10px',
                                    width:{ xs:'100%', sm:'100%', md:'15%' }
                                }}
                                onClick={getAgregar}
                            >
                                <b>Agregar</b>
                            </Button>
                        </Box>
                    )
                }             
                        
            </Box>
        </Box>
    );
}

export { Idiomas }