import { Avatar, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Rating, Typography } from "@mui/material";
import { Box } from "@mui/system";

import StarRoundedIcon from '@mui/icons-material/StarRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';

const OpinionesAsesores = ({ opiniones }) => {
    const getPromedio = (opiniones) => {
        const puntuaciones = opiniones.map((opinion)=> opinion.puntuacion );
        const total = puntuaciones.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        return total / puntuaciones.length;
    }
    const getText = (parrafos) => {
        return parrafos.map((parrafo)=> parrafo.texto ).join('')
    }    
    return(
        <Box>
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <Box
                        sx={{
                            textAlign:'center',
                        }}
                    >
                        <Box>
                            {/* <Rating name="half-rating-read" defaultValue={4.5} precision={0.5} size="large" readOnly /> */}
                            <Typography variant="body1" sx={{ color:'#707070', }}>
                                Valoracion General
                            </Typography>
                            {
                                opiniones.length > 0 ? (
                                    <Rating
                                        value={getPromedio(opiniones)}
                                        // getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
                                        precision={0.5}
                                        icon={<StarRoundedIcon fontSize="large" />}
                                        emptyIcon={<StarBorderRoundedIcon fontSize="large" />}
                                        size="large" readOnly
                                    />
                                ) : (
                                    <Typography variant="h6" sx={{ color:'#707070', }}>
                                        <b>Aun no hay valoracion</b>
                                    </Typography>
                                )
                            }
                                    
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    {
                        opiniones.length > 0 ? (
                            <List>
                                {
                                    opiniones.map((opinion) => (
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar>
                                                    { opinion.nombre.charAt(0) }
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Box>
                                                        <Typography>
                                                            <b>{ opinion.nombre }</b>
                                                        </Typography>
                                                        <Rating value={opinion.puntuacion} size="small" readOnly/>
                                                    </Box>
                                                }
                                                secondary={ opinion.comentario.length > 0 ? getText(opinion.comentario) : false }
                                            />
                                        </ListItem>
                                    ))
                                }    
                            </List>
                        ) : (
                            <Box
                                sx={{
                                    // backgroundColor:'red',
                                    width:'100%',
                                    textAlign:'center',
                                }}
                            >
                                <Typography variant="h5" sx={{ color:'#707070', mt:3, }}>
                                    <b>No hay opiniones</b>
                                </Typography>
                            </Box>
                        )
                    }                            
                </Grid>
            </Grid>
        </Box>
    );
}

export { OpinionesAsesores }