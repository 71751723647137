import { Box, Grid, Typography } from "@mui/material";
import { getFormatoMoneda } from "../../../../../../functions/getFormatoMoneda";
import { CustomTypography } from "../../../../../CustomTypography";

const ItemIncome = ({ title, body, price}) => {
	return(
		<Grid item xs={12} sm={12} md={12}>
			<Box
				sx={{
					// backgroundColor:'#5F9EA0',
					// mb:1,
					display:'flex',
					flexDirection:'column',
					gap:0.5,
				}}
			>
				{
					title && (
						<CustomTypography variant="textTertiaryBold">
							{ title }
						</CustomTypography>
					)
				}
				{
					body && (
						<CustomTypography variant="textTertiaryLight">
							{ body }
						</CustomTypography>
					)
				}
				{
					price && (
						<CustomTypography variant="textTertiaryBold">
							{ getFormatoMoneda(price) }
							<CustomTypography variant="textTertiaryLight">
								MXN
							</CustomTypography>
						</CustomTypography>
					)
				}
				
				
				
			</Box>
		</Grid>
	);
}

export { ItemIncome }