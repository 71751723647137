import React, { useEffect } from "react";
import { useRef, useState } from "react";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import anuncio from "../../assets/slideanuncios/anuncio.png";
import anuncio2 from "../../assets/slideanuncios/anuncio2.png";
import anuncio3 from "../../assets/slideanuncios/anuncio3.png";

// import portada_pc from '../../assets/anuncios/septiembre/portada_pc.png';
// import portada_movil from '../../assets/anuncios/septiembre/portada_movil.png';

import portada_mayo_pc from "../../assets/anuncios/portada_mayo_PC.png";
import portada_mayo_movil from "../../assets/anuncios/portada_mayo_movil.png";
import portada_mayo_2_pc from "../../assets/anuncios/portada_mayo_2_PC.png";
import portada_mayo_2_movil from "../../assets/anuncios/portada_mayo_2_movil.png";
import portada_mayo_hotsale_pc from "../../assets/anuncios/portada_mayo_hotsale_pc.png";
import portada_mayo_hotsale_movil from "../../assets/anuncios/portada_mayo_hotsale_movil.png";
import portada_pc from "../../assets/anuncios/base/portada_pc.png";
import portada_movil from "../../assets/anuncios/base/portada_movil.png";

import { Switch } from "@mui/material";

const anuncios = [
  {
    titulo: "uno",
    imagen: anuncio,
  },
  {
    titulo: "dos",
    imagen: anuncio2,
  },
  {
    titulo: "tres",
    imagen: anuncio3,
  },
];

const SlideAnuncios = () => {
  const [mexico, setMexico] = useState(false);

  const [usuario, setUsuario] = useState(null);
  const handleChange = (event) => {
    const d = new Date();
    console.log(d);
    setMexico(event.target.checked);
  };
  useEffect(() => {
    const d = new Date();
    if (d.getDate() >= 14) {
      setMexico(true);
    }
  }, []);
  return (
    <React.Fragment>
      <Box sx={{ width: "100%", display: { xs: "none", md: "flex" } }}>
        <Box
          component="img"
          sx={{
            height: 563,
            display: "block",
            // maxWidth: 400,
            overflow: "hidden",
            width: "100%",
            objectFit: "cover",
          }}
          // src={anuncios[0].imagen}
          // alt={anuncios[0].titulo}
          src={
            "https://storage.casaspecsa.com/Portadas/portada_kermes_2024_pc.webp"
          }
          // src={portada_pc}
          // src={mexico ? 'https://storage.casaspecsa.com/Portadas/portada_14_febrero_uc_pc.png' : 'https://storage.casaspecsa.com/Portadas/portada_febrero_pc.png'}
          alt="Casas Pecsa - Bienvenido"
        />
      </Box>
      <Container sx={{ display: { xs: "flex", md: "none" } }}>
        <Box
          component="img"
          sx={{
            // height: 255,
            display: "block",
            minHeight: "150px",
            overflow: "hidden",
            marginTop: 2,
            marginBottom: 1,
            borderRadius: "6px",
            boxShadow: 3,
          }}
          // src={anuncios[0].imagen}
          // alt={anuncios[0].titulo}
          src={
            "https://storage.casaspecsa.com/Portadas/portada_kermes_2024_movil.webp"
          }
          // src={portada_movil}
          // src={mexico ? 'https://storage.casaspecsa.com/Portadas/portada_14_febrero_uc_movil.png' : 'https://storage.casaspecsa.com/Portadas/portada_febrero_movil.png'}
          alt="Casas Pecsa - Bienvenido"
        />
      </Container>
      {/* <Box>
         <Switch 
          checked={mexico}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        </Box> */}
    </React.Fragment>
  );
};

export { SlideAnuncios };
