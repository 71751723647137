import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { Container } from "@mui/system";

import pagosuccessblanco from "../../assets/pagosuccessblanco.svg";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { CircularLoadingAnimation } from "../../components/CircularLoadingAnimation";
import { useTheme } from "@mui/material/styles";
import { logOut } from "../../functions/login/logOut";

const PagoRealizadoConExito = () => {
  let { folio } = useParams();

  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));

  const [separacion, setSeparacion] = useState(null);

  const reedirigirALlamada = () => {
    window.location.href = "tel:+52 8441630701";
  };
  const reedirigirAChatWhatsapp = () => {
    window.open(
      `https://api.whatsapp.com/send?phone=528443492981&text=Hola!%20Soy%20${separacion.transaccion.cliente.datos.primerNombre}%20y%20acabo%20de%20separar%20mi%20casa%20Pecsa,%20mi%20folio%20de%20compra%20es:%20${separacion.transaccion.folio}`,
      "_blank"
    );
  };
  const reedirigirMoffin = () => {
    window.open(
      "https://pecsa.moffin.co/solicitud/hipotecario/comenzar",
      "_blank"
    );
  };
  const clientIsLogin = (userClient, email) => {    
    try{
      if(userClient  === undefined){        
        return;
      }
      const jsonClient = JSON.parse(userClient);
      if(typeof jsonClient === 'object' && jsonClient !== null){        
        if(jsonClient.email){
          if(jsonClient.email !== email){            
            logOut();
          }
        }else{          
          throw new Error('No es un objeto JSON');  
        }
      }else{        
        throw new Error('No es un objeto JSON');
      }
    } catch(error){
      logOut();
    }
  }
  useEffect(() => {
    window.scroll(0, 0);

    axios
      .post(`${process.env.REACT_APP_API_URL}/api/venta/success`, {
        folio: folio,
      })
      .then((response) => {
        // console.log("Tucan:::", response.data.transaccion.cliente.id);
        setSeparacion(response.data);
        clientIsLogin(localStorage.getItem('userClient'), response.data.transaccion.cliente.autenticacion.email);
      })
      .catch((error) => {
        console.log(error);
      })
      .then(() => {});
  }, [folio]);
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "#00B347",
          color: "#fff",
          pt: 12,
        }}
      >
        <Container
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ textAlign: "center", pb: 2 }}
        >
          <Grid>
            <Box
              component="img"
              src={pagosuccessblanco}
              sx={{
                width: 200,
              }}
            />
          </Grid>
          <Grid>
            <Typography variant="h4" component="div" fontFamily={"Montserrat"}>
              <b>Pago realizado con éxito</b>
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="h6" component="div" fontFamily={"Montserrat"}>
              Folio de compra:{" "}
              <b>{`#${separacion && separacion.transaccion.folio}`}</b>
            </Typography>
          </Grid>
        </Container>
      </Box>

      <Box
        sx={{
          py: 5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
          color: "#707070",
          gap: 3,
        }}
      >
        <Typography variant="h3" component="div" fontFamily={"Montserrat"}>
          <b>¡Felicidades!</b>
        </Typography>

        <Container
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 2,
            p: 0,
          }}
        >
          <Container
            sx={{
              width: { xs: "100%", md: "60%" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography
                variant="h5"
                component="div"
                fontFamily={"Montserrat"}
              >
                <b>Separaste tu casa con éxito.</b>
              </Typography>
              <Typography
                variant="body1"
                component="div"
                fontFamily={"Montserrat"}
              >
                Cuentas con 3 días hábiles para entregar la documentación en
                nuestras oficinas ubicadas en Torre Insignia Piso 10 (Blvd. José
                Sarmiento 1515 Col. Rancho de Peña CP 25210, en horario de Lunes
                a Viernes de 9:00 am a 2:00 pm y 4:00 pm a 7:00 pm) o uno de
                nuestros asesores se pondra en contacto.
              </Typography>
              <Typography
                variant="body1"
                component="div"
                fontFamily={"Montserrat"}
              >
                <b>Contactanos:</b>
              </Typography>
              <Box>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid>
                    <Button
                      variant="text"
                      color="success"
                      startIcon={<WhatsAppIcon />}
                      onClick={reedirigirAChatWhatsapp}
                      sx={{ fontFamily: "Montserrat" }}
                    >
                      Ir al chat
                    </Button>
                  </Grid>
                  <Grid>
                    <Button
                      variant="text"
                      startIcon={<PhoneIcon />}
                      onClick={reedirigirALlamada}
                      sx={{ fontFamily: "Montserrat" }}
                    >
                      +52 844 163 0701
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography
                variant="h5"
                component="div"
                sx={{ fontFamily: "Montserrat" }}
              >
                <b>¿Cuentas con algun credito para tu vivienda?</b>
              </Typography>
              <Typography
                variant="body1"
                component="div"
                sx={{ fontFamily: "Montserrat" }}
              >
                En Casas Pecsa te ofrecemos distintas opciones de crédito de
                manera que puedas elegir cual se adapta más a tus necesidades,
                Para comenzar con el proceso da click en el siguiente boton:
              </Typography>
              <Button
                variant="outlined"
                type="submit"
                color="inherit"
                size="large"
                sx={{
                  width: { xs: "100%", sm: "100%", md: "70%" },
                  p: { xs: "none", sm: "none", md: 1 },
                  borderColor: "#707070",
                  color: "#707070",
                  borderWidth: 3,
                  borderRadius: "12px",
                  fontFamily: "Montserrat",
                }}
                onClick={reedirigirMoffin}
              >
                <b>Iniciar Pre Autorizacion de Crédito</b>
              </Button>
            </Box>
          </Container>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              position: "sticky",
              bgcolor: "#fff",
              height: "100%",
              padding: "20px",
              ...(small ? { bottom: 0 } : { top: 100 }),
              maxWidth: { xs: "100%", md: "40%" },
              borderRadius: { xs: 0, md: "10px" },
              boxShadow: {
                xs: "0px -3px 5px 0px rgba(0,0,0,0.1)",
                md: "3px 3px 10px 3px rgba(0,0,0,0.1)",
              },
            }}
          >
            <Typography
              variant="h5"
              component="div"
              sx={{ fontFamily: "Montserrat" }}
            >
              Mira el estatus de tu separacion, ingresa o crea tu contraseña
              para ver:
            </Typography>
            {separacion ? (
              <Link
                to="/profile/movimientos"
                state={{
                  cliente: {
                    idCliente: separacion.transaccion.cliente.id,
                    email: separacion.transaccion.cliente.autenticacion.email,
                    nuevo: separacion.transaccion.cliente.autenticacion.nuevo,
                  },
                  origen: "/profile/movimientos",
                }}
              >
                <Button
                  variant="outlined"
                  type="submit"
                  color="inherit"
                  size="large"
                  sx={{
                    width: "100%",
                    p: { xs: "none", sm: "none", md: 1 },
                    borderColor: "#F5323F",
                    color: "#F5323F",
                    borderWidth: 3,
                    borderRadius: "12px",
                    fontFamily: "Montserrat",
                  }}
                >
                  <b>Ver</b>
                </Button>
              </Link>
            ) : (
              <Box>
                <CircularLoadingAnimation />
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export { PagoRealizadoConExito };
