import { Box, Button, Grid, Typography } from "@mui/material";
import pruebadecoracion from '../../assets/prueba/pruebadecoracion.jpg'
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

const Post = ({ post }) => {
    let navigate = useNavigate();
    const redirigirPublicacion = () => {
        navigate(`/blog/${post.id}/${post.titulo}`);
    }

    const [likes, setLikes] = useState(0);
    const [views, setViews] = useState(0);
    const [shared, setShared] = useState(0);
    useEffect(() => {
        setLikes(post.likes.length);
        setViews(post.views.length);
    }, [post]);
    return(
        <Box
            sx={{
                // backgroundColor:'green',
                pt:1,
                pb:1,
                color:'#707070',
                cursor:'pointer',
            }}
            onClick={redirigirPublicacion}
        >
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <Box
                        sx={{
                            // backgroundColor:'green',
                            width:'100%',
                            // height:360,
                            display:'flex',
                            alignItems:'center',
                        }}
                    >
                        <Box
                            sx={{
                                width:'95%',
                                height:{xs:200, sm:250, md:350},
                                // backgroundColor:'yellow',
                                borderRadius:'15px',
                                overflow: 'hidden',
                                m:'0 auto',
                                boxShadow:1,
                            }}
                        >
                            <Box
                                component='img'
                                src={post.imagen}
                                sx={{
                                    minWidth:'100%',
                                    minHeight:'100%',
                                    maxWidth:{xs:'100%', sm:'110%', md:'110%'},
                                    // borderRadius:'10px',
                                    objectFit:'cover'
                                }}
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Box
                        sx={{
                            width:'100%',
                            display:'flex',
                            alignItems:'center',
                        }}
                    >
                        <Box
                            sx={{
                                width:'95%',
                                // height:350,
                                // backgroundColor:'yellow',
                                // borderRadius:'10px',
                                // overflow: 'hidden',
                                m:'0 auto',
                                mt:0.8,
                                mb:0.5,
                                // boxShadow:1,
                                color:'#DE4647',                                                
                            }}
                        >
                            <Typography variant="h5" sx={{ fontFamily:'Montserrat', }}>
                                <b>{post.titulo}</b>
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Box
                        sx={{
                            width:'100%',
                            display:'flex',
                            alignItems:'center',
                        }}
                    >
                        <Box
                            sx={{
                                width:'95%',
                                // height:350,
                                // backgroundColor:'yellow',
                                // borderRadius:'10px',
                                // overflow: 'hidden',
                                m:'0 auto',
                                // boxShadow:1,
                                display:'flex',
                            }}
                        >
                            <Box
                                sx={{
                                    display:'flex',
                                    mr:1,
                                    // backgroundColor:'yellow',
                                    alignItems:'center',
                                    alignContent:'center',
                                }}
                            >
                                <ThumbUpIcon sx={{ fontSize: 15 }}/>
                                <Typography variant="caption" display="block" sx={{ fontFamily:'Montserrat', ml:0.5 }}>{likes}</Typography>
                            </Box>
                            <Box
                                sx={{
                                    display:'flex',
                                    mr:1,
                                    // backgroundColor:'yellow',
                                    alignItems:'center',
                                    alignContent:'center',
                                }}
                            >
                                <RemoveRedEyeOutlinedIcon sx={{ fontSize: 15 }}/>
                                <Typography variant="caption" display="block" sx={{ fontFamily:'Montserrat', ml:0.5 }}>{views}</Typography>
                            </Box>                                                    
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Box
                        sx={{
                            width:'100%',
                            display:'flex',
                            alignItems:'center',
                        }}
                    >
                        <Box
                            sx={{
                                width:'95%',
                                height:{xs:150, sm:150, md:250},
                                // backgroundColor:'yellow',
                                // borderRadius:'10px',
                                overflow: 'hidden',
                                m:'0 auto',
                                // boxShadow:1,
                                textOverflow: 'ellipsis'
                            }}
                        >
                            <Typography variant="subtitle1" sx={{ fontFamily:'Montserrat', }}>
                                {post.texto}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Box
                        sx={{
                            width:'100%',
                            display:'flex',
                            alignItems:'center',
                        }}
                    >
                        <Box
                            sx={{
                                width:'95%',
                                // height:350,
                                // backgroundColor:'yellow',
                                // borderRadius:'10px',
                                // overflow: 'hidden',
                                m:'0 auto',
                                textAlign:'center',
                                // boxShadow:1,                                                    
                            }}
                        >
                            <Button
                                sx={{
                                    color:'#DE4647',
                                    fontFamily:'Montserrat',
                                }}
                            >
                                <b>Ver mas</b>
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export { Post }