import { Box, Collapse, Container, Grid, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const ListItemCollapse = ({ children, list, headItem, optionItem }) => {
    // console.log('watch',list.map((element)=>headItem(element)));
    const [open, setOpen] = useState(true);

    const handleClick = () => {
        setOpen(!open);
    };
    return(
        <>
            <ListItem disablePadding onClick={handleClick}>
                <ListItemButton>
                    { children }
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>            
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {
                        list.map((element)=>(
                            <ListItemButton  onClick={()=>optionItem(element)} sx={{ pl: 4 }}>
                                <ListItemText primary={headItem(element)} />
                            </ListItemButton>
                        ))
                    }                    
                </List>
            </Collapse>
        </>
    );
}

const useLogin = () => {
    let navigate = useNavigate();
    let { pathname, state } = useLocation();
    const [cliente, setCliente] = useState(null);
    const [estado, setEstado] = useState({...state, pathname});

    console.log('PRUEBA ANETS QUE LO QUITE-mi cuenta::::::',pathname, state);

    useEffect(()=>{
        console.log('validar login');
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');
    
        axios.post(`${process.env.REACT_APP_API_URL}/api/clientes/acount/`,{
            folio: folio
        },{
            headers: {
                Authorization: `Bearer ${token}`,
            }              
        })
        .then((response) => {
            console.log('then',response.data);
            setCliente(response.data.cliente);
            // setEstado({...estado, pathname});
            console.log('direccion::__:::',pathname);
        })
        .catch((error) => {
            console.log('error');
            navigate('/login', { state: { ...estado } });
        })
    },[pathname])

    return {
        cliente,
        estado,
    }
}
const MiCuenta = () => {
    let navigate = useNavigate();
    // let location = useLocation();

    // console.log('PRUEBA ANETS QUE LO QUITE-mi cuenta::::::',location);
    // const [ciudades, setCiudades] = useState([]);
    // const [selectedCiudad, setSelectedCiudad] = useState(null);
    // const [cliente, setCliente] = useState(null);

    const { cliente, estado } = useLogin();
    console.log('YA EN MI CUENTA::::::',cliente);
    // useEffect(()=>{
    //     if(!cliente){
            
    //     }
    // },[cliente]);
    
    const checarSiTieneInversiones = (registros) => {
        if(registros.find((element)=> element.lote.datosDeInversion)){
            return true;
        }else{
            return false;
        }
    }
    // useEffect(()=>{
    //     const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
    //     const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');
    
    //     axios.post(`${process.env.REACT_APP_API_URL}/api/clientes/acount/`,{
    //         folio: folio
    //     },{
    //         headers: { 
    //           Authorization: `Bearer ${token}`,
    //         }              
    //     })
    //     .then((response) => {
    //         // handle success
    //         console.log(response);
    //         //   setLote(response.data.lote);
    //         setCliente(response.data.cliente);
    //         setCiudades(response.data.ciudades);
    //     })
    //     .catch((error) => {
    //       // handle success
    //       console.log(error);
    //       console.log('tecladista',location);
    //       if(location.state){            
    //         if(location.state.cliente){                
    //             // navigate('/login', { state: { cliente: location.state.cliente }});
    //             console.log('hay cliente');
    //         }
    //       }else{
    //         if(location.pathname){
    //             // navigate('/login', {state:{origen:location.pathname}});
    //             console.log('no hay cliente, pathname', location.pathname);
    //         }else{
    //             // navigate('/login');
    //             console.log('home');
    //         }
    //       }
    //           if(location.state){            
    //             if(location.state.cliente){  
    //                 console.log('llego');
    //                 navigate('/login', { state: { cliente: location.state.cliente, origen:location.pathname }});
    //             }
    //           }else{
    //             // navigate('/login');
    //             navigate('/login', { state: { origen:location.pathname }});
    //           }
          
    //     })
    //     .then(() => {
    //       // handle success
    //       //console.log('sabe');
    //     });
    // }, [setCliente, setCiudades])
    return(
        <div>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, mt:{ xs:9, md:12 } }}>
                <Container sx={{ backgroundColor:'white' }}>
                    {/* {
                        cliente.autenticacion.nuevo === true ? (
                            <Box sx={{ mt:20 }}>
                                <h1>Nuevo</h1>
                            </Box>
                        ) : ( */}
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={3} md={3}>
                                    <Typography variant="h6" gutterBottom component="div" sx={{ color:'#F5323F', fontFamily:'Montserrat' }}>
                                        Mi Cuenta
                                    </Typography>
                                    <List sx={{ color:'#707070', fontFamily:'Montserrat' }}>
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={()=>{ navigate('datos') }}>
                                                <ListItemText primary="Detalles de la cuenta" />
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={()=>{ navigate('movimientos') }}>
                                                <ListItemText primary="Mis movimientos" />
                                            </ListItemButton>
                                        </ListItem>
                                        {
                                            cliente && (
                                                cliente.registrosDeVentasConcluidas.length > 0 && (
                                                    checarSiTieneInversiones(cliente.registrosDeVentasConcluidas) && (
                                                        <ListItemCollapse 
                                                            list={cliente.registrosDeVentasConcluidas.filter((registro)=>registro.lote.datosDeInversion)}
                                                            headItem={ (list)=>`Lote: ${list.lote.numeroDeLote} Mzna:${list.lote.manzana.nombre} ${list.lote.manzana.etapa.fraccionamiento.nombre}`}
                                                            optionItem={ (list)=>navigate(`inversiones/${list.lote.datosDeInversion.id}`) }
                                                        >
                                                            <ListItemText primary="Mis inversiones" />
                                                        </ListItemCollapse>
                                                    )
                                                )
                                            )
                                        }
                                    </List>
                                </Grid>
                                <Grid item xs={12} sm={9} md={9}>
                                    <Outlet/>
                                </Grid>
                            </Grid>
                        {/* )
                    } */}
                </Container>
            </Box>
        </div>
    );
}

export { MiCuenta }