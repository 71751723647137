import { Box, IconButton } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import "../../../estilos.css"
import { useState } from "react";
import { wrap } from "popmotion";

const variants = {
    enter: (direction) => {
        return {
            x: direction > 0 ? 1000 : -1000,
            opacity: 0
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1
    },
    exit: (direction) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0
        };
    }
};
const swipeConfidenceThreshold = 10000;

const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
};

const CarouselPhotos = ({ images, indexImage, getIndexImageNumber, getIndexImageNumberMenos, }) => {
    // console.log('llego??', indexImage);
    const [visible, setVisible] = useState(true);
    const [[page, direction], setPage] = useState([indexImage, 0]);
    const imageIndex = wrap(0, images.length, page);

    const paginate = (newDirection) => {
        setPage([page + newDirection, newDirection]);
        getIndexImageNumber(imageIndex + 1)
    };

    const getVisible = () => {
        setVisible(!visible);
    }
    return(
        <>
            <AnimatePresence initial={false} custom={direction}>
                <motion.img
                    className="imgCarousel" //position: absolute; max-width: 100%;
                    key={page}
                    src={images[imageIndex]}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{
                        x: { type: "spring", stiffness: 300, damping: 30 },
                        opacity: { duration: 0.2 }
                    }}
                    drag="x"
                    dragConstraints={{ left: 0, right: 0 }}
                    dragElastic={1}
                    onDragEnd={(e, { offset, velocity }) => {
                        const swipe = swipePower(offset.x, velocity.x);

                        if (swipe < -swipeConfidenceThreshold) {
                            paginate(1);
                        } else if (swipe > swipeConfidenceThreshold) {
                            paginate(-1);
                        }
                    }}
                    onClick={getVisible}
                />
            </AnimatePresence>
            {
                visible && (
                    <Box sx={{ position:'absolute', zIndex:1, top:{ xs:'45%', md:'45%'}, left:{ xs:0, md:0} }}>
                        <IconButton
                            onClick={() => paginate(1)} 
                            color="primary"
                            size="small"
                            sx={{ 
                                backgroundColor:'#F9F9F9',
                                boxShadow:3, 
                                '&:hover':{
                                    backgroundColor:'#FFF',
                                    boxShadow:5,
                                }
                            }}
                        >
                            <ChevronLeftIcon fontSize="small"/>
                        </IconButton>
                    </Box>
                )
            }
            {
                visible && (
                    <Box sx={{ position:'absolute', zIndex:1, top:{ xs:'45%', md:'45%'}, right:{ xs:0, md:0} }}>
                        <IconButton 
                            onClick={() => paginate(-1)} 
                            color="primary" 
                            size="small"
                            sx={{ 
                                backgroundColor:'#F9F9F9', 
                                boxShadow:3,
                                '&:hover':{
                                    backgroundColor:'#FFF',
                                    boxShadow:5,
                                } 
                            }}
                        >
                            <ChevronRightIcon fontSize="small"/>
                        </IconButton>
                    </Box>
                )
            }
            
            
        </>
    );
}

export { CarouselPhotos }