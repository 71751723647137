import { Box, Breadcrumbs, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { ColeccionShorts } from "./ColeccionShorts";
import { ColeccionTodosVideosMiniatura } from "./ColeccionTodosVideosMiniatura";
import { ColeccionVideosMiniatura } from "./ColeccionVideosMiniatura";
import { VideoMiniatura } from "./VideoMiniatura";
import HomeIcon from '@mui/icons-material/Home';
import moment from "moment";
import { Helmet } from "react-helmet";

const VideoBlogReproductor = () => {    
    let { idYoutube } = useParams();

    const [video, setVideo] = useState(null);
    const [videos, setVideos] = useState([]);
    const [playlists, setPlaylists] = useState([]);
    // const [shorts, setShorts] = useState(null);
    
    useEffect(()=>{
        window.scroll(0,0);
        axios.get(`${process.env.REACT_APP_API_URL}/api/video-blog//getVideo/${idYoutube}`)
        .then((response) => {
            // handle success            
            console.log(response);
            console.log('success axio');
            setVideo(response.data.video);
            if(response.data.video.playlists){
                setPlaylists(response.data.video.playlists);
            }
            setVideos(response.data.videos);            
            // setShorts(response.data.shorts);
        })
        .catch((error) => {
            // handle success
            console.log(error);
        })
        .then(() => {
            // handle success
            console.log('sabe');
        });
    },[idYoutube])
    return(
        <div>
            <Helmet>
                <title>{ video ? `Casas Pecsa | Video Blog - ${video.titulo}` : `Casas Pecsa | Video Blog - cargando...`}</title>
                <meta name="description" content={ video ? `${video.descripcion}` : `Video Blog`} />
            </Helmet>
            <Container>
                <Box sx={{ mt:12 }}>
                    <Box
                        sx={{
                            // backgroundColor:'red',
                            pb:1,
                            pt:0.6,
                            pl:1,
                            color:'#707070',
                            
                        }}
                    >
                        <Breadcrumbs aria-label="breadcrumb">
                            {/* <Link underline="hover" color="inherit">
                                Inicio
                            </Link> */}
                            <Link to={'/'} style={{ color:'#707070', }}>
                                {/* Inicio */}
                                {/* <HomeIcon fontSize="small"/> */}
                                {
                                    window.innerWidth > 591 ? (`Inicio`) : (<HomeIcon fontSize="small" sx={{ color:'#707070' }}/>)
                                }
                            </Link>
                            <Link to={'/videoblog'} style={{ color:'#707070', }}>
                                Video Blog
                            </Link>
                            <Typography sx={{ color:'#F5323F', }}>
                                Video
                            </Typography>
                            
                        </Breadcrumbs>
                    </Box>
                    <Box>
                        {/* <Box
                            sx={{
                                textAlign:'center',
                            }}
                        >
                            <Typography variant="h3" sx={{ color:'#F5323F', }}>
                                <b>Video Blog</b>
                            </Typography>
                        </Box> */}
                        <Box
                            sx={{
                                // backgroundColor:'red',
                                width:'100%',
                                mb:5,
                            }}
                        >
                            <Box
                                component="iframe"
                                src={`https://www.youtube.com/embed/${idYoutube}?&autoplay=1`}
                                // width='100%'
                                // height={{ xs: 300, sm:400, md:560 }}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowfullscreen={true}
                                sx={{
                                    width:{ xs: '100%', sm:'99.6%', md:'99.6%' },
                                    height:{ xs: 260, sm:400, md:600 },
                                    borderRadius:{ xs:'10px', sm:'12px', md:'16px' },
                                }}
                            />
                            <Box
                                sx={{
                                    // backgroundColor:'yellow',
                                    width:'100%',
                                    display:'flex',
                                    alignItems:'center',
                                    alignContent:'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        // backgroundColor:'white',
                                        width:'99.6%',
                                        // height:139,
                                        m:'0 auto',
                                        color:'#707070',
                                    }}
                                >
                                    <Typography 
                                        variant="h5" 
                                        sx={{
                                            // backgroundColor:'yellow',
                                            width:'100%', 
                                            maxHeight:60, 
                                            textOverflow: 'ellipsis',
                                            overflow:'hidden',
                                            display:'-webkit-box !important',
                                            WebkitLineClamp:2,
                                            WebkitBoxOrient:'vertical',
                                            whiteSpace:'normal',
                                            color:'#F5323F',
                                        }}
                                    >
                                        <b>{ video && (video.titulo) }</b>
                                    </Typography>
                                    <Typography variant="body1">
                                    { video && (video.descripcion) }
                                    </Typography>
                                    <Typography variant="body1">
                                        {/* Hace 2 semanas */}
                                        { video && (moment(video.createdAt).fromNow()) }
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    {/* <Box>
                        <ColeccionVideosMiniatura titulo={'Todos los videos'}videoPrueba={videoPrueba} />
                    </Box> */}                    
                    {
                        playlists && (
                            playlists.map((playlist) => (
                                <Box>
                                    <ColeccionVideosMiniatura titulo={'Creditos'} playlist={playlist} />
                                </Box>
                            ))
                        )
                    }
                    {
                        videos && (
                            <ColeccionTodosVideosMiniatura playlist={videos} />
                        )
                    }
                    
                </Box>
            </Container>
        </div>
    );
}

export { VideoBlogReproductor }