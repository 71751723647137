import { Box, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const PlazosSelect = ({ plazo, handleChange }) => {
    const theme = useTheme();
    const mobilWay = useMediaQuery(theme.breakpoints.down('sm'));
    if(mobilWay) {
        return(
            <Box
                sx={{
                    // backgroundColor:'brown',
                    width:'100%',
                    textAlign:'center',
                    display:'flex',
                    flexDirection:'column',
                    gap:1,
                    
                }}
            >
                <FormLabel>Plazo del Crédito</FormLabel>
                <FormControl fullWidth sx={{ textAlign:'left' }}>
                    <InputLabel id="demo-simple-select-label">Selecciona</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={plazo}
                        label="Selecciona"
                        onChange={handleChange}
                        // onChange={()=>console.log('hola')}
                    >
                        <MenuItem value={0} disabled>Selecciona</MenuItem>
                        <MenuItem value={5}>5 años</MenuItem>
                        <MenuItem value={10}>10 años</MenuItem>
                        <MenuItem value={15}>15 años</MenuItem>
                        <MenuItem value={20}>20 años</MenuItem>
                    </Select>
                </FormControl>
            </Box>
        );
    }
    return(
                   
            <Box
                sx={{
                    textAlign:'center'
                }}
            >
                <FormControl sx={{  }}>
                    <FormLabel id="demo-row-radio-buttons-group-label">Plazo del Crédito</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={plazo}
                        onChange={handleChange}
                    >
                        <FormControlLabel value={5} control={<Radio />} label="5 años" />
                        <FormControlLabel value={10} control={<Radio />} label="10 años" />
                        <FormControlLabel value={15} control={<Radio />} label="15 años" />
                        <FormControlLabel value={20} control={<Radio />} label="20 años" />
                    </RadioGroup>
                </FormControl>
            </Box>
        
    );
}

export { PlazosSelect }