import { Box, Container } from "@mui/system";
import { Button, Grid, Stack, Fab, Skeleton, Collapse } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { VideosFraccionamientoItem } from "./VideosFraccionamientoItem";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import axios from "axios";

const VideosFraccionamiento = ({ fraccionamiento }) => {
    const [playlist, setPlaylist] = useState([]);

    const constraintsRef = useRef(null);
      const containerRef = useRef(null);
  
      const constraintsRefMovil = useRef(null);
      const containerRefMovil = useRef(null);
  
      //const Contenedor = containerRef.current.clientWidth == null || containerRef.current.clientWidth == undefined ? 0 : containerRef.current.clientWidth;
      // const DivVisible = constraintsRef.current.clientWidth;
      // const Diferencia = Contenedor - DivVisible;
  
      const [Xside, setXside] = React.useState(0);
      const [XIside, setXIside] = React.useState(0);
  
      const [constraintsWidth, setConstraintsWidth] = React.useState(0);
      const [containerWidth, setContainerWidth] = React.useState(0);
  
      const atras = (Xside) => {
        console.log(XIside);
        console.log((XIside + 384));
        console.log(constraintsWidth);
        console.log(containerWidth);
        setXside(Xside+384);
        setXIside((XIside - 384) < constraintsWidth ? constraintsWidth : (XIside - 384))
        // console.log(XIside);
        // console.log(constraintsRef.current.clientWidth);
        // console.log(containerRef.current.clientWidth);
        
      };
      const adelante = (Xside) => {
        console.log('adelante')
        console.log(XIside);
        console.log((XIside - 384));
        console.log(constraintsWidth);
        console.log(containerWidth);
        setXside(Xside-384);      
        setXIside((XIside + 384) > containerWidth ? containerWidth : (XIside + 384))
        // console.log(XIside);
        // console.log(containerRef.current.clientWidth);
      };
      
      const checarXIside = (Xside) => {
         
      }
      
      const [open, setOpen] = useState(false);
      const [video, setVideo] = useState(null);

      const handleClickOpen = () => {
        setOpen(!open);
      };

      const getVideo = (video) => {
        //console.log(video); return;
        if(!open){
          handleClickOpen();
        }        
        setVideo(video);
      };

      useEffect(() => {
        setXIside(constraintsRef.current.clientWidth);
        setConstraintsWidth(constraintsRef.current.clientWidth)
        setContainerWidth(containerRef.current.clientWidth)
        //
      }, [setXIside, setConstraintsWidth, setContainerWidth]);
      useEffect(()=>{
        // if(localStorage.getItem(`playlist_${fraccionamiento.nombre}`)){
        //   console.log('playlist::::',localStorage.getItem(`playlist_${fraccionamiento.nombre}`));
        //   setPlaylist(JSON.parse(localStorage.getItem(`playlist_${fraccionamiento.nombre}`)));
        //   console.log('se lleno del local storage');
        //   return;
        // }
        // if(localStorage.getItem(`playlist_${fraccionamiento.nombre}`) === '[]'){
        //   // setPlaylist(JSON.parse(localStorage.getItem(`playlist_${fraccionamiento.nombre}`)));
        //   console.log('estavacio');
        //   return;
        // }

        // // if(localStorage.getItem(`playlist_${fraccionamiento.nombre}`)){
        // //   // console.log('estavacio1');
        // //   if(localStorage.getItem(`playlist_${fraccionamiento.nombre}`) !== '[]'){
        // //     setPlaylist(JSON.parse(localStorage.getItem(`playlist_${fraccionamiento.nombre}`)));
        // //     // console.log('estavacio');
        // //     return;
        // //   }
        // //   // console.log('playlist::::',localStorage.getItem(`playlist_${fraccionamiento.nombre}`));
        // //   // setPlaylist(JSON.parse(localStorage.getItem(`playlist_${fraccionamiento.nombre}`)));
        // //   // console.log('se lleno del local storage');
        // //   // return;
        // // }
        // if(localStorage.getItem(`last_update_${process.env.REACT_APP_YOUTUBE_LIST_UPDATE}`)){
          // console.log('QQQQ', localStorage.getItem(`last_update_videos_${fraccionamiento.nombre}`), process.env.REACT_APP_YOUTUBE_LIST_UPDATE);
        if(localStorage.getItem(`last_update_videos_${fraccionamiento.nombre}`)){
          if(localStorage.getItem(`last_update_videos_${fraccionamiento.nombre}`) === process.env.REACT_APP_YOUTUBE_LIST_UPDATE){
            console.log('estavacio1');
            if(localStorage.getItem(`playlist_${fraccionamiento.nombre}`) !== '[]'){
              setPlaylist(JSON.parse(localStorage.getItem(`playlist_${fraccionamiento.nombre}`)));
              // console.log('estavacio');
              return;
            }
          }
        }
        const blacklist = ['El', 'Residencial'];
        const expresionFracc = new RegExp("\\b(" + blacklist.join("|") + ")\\b", "gi");
        const newWordFraccionamiento = fraccionamiento.nombre.replace(expresionFracc, "")
        // console.log('LINK DEL MAPAAAAA',`https://youtube.googleapis.com/youtube/v3/search?part=snippet&channelId=${process.env.REACT_APP_YOUTUBE_CHANNEL_ID}&order=date&q=${newWordFraccionamiento}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`);
        // alert('holaaaaa');
        // return;
        axios.get(`https://youtube.googleapis.com/youtube/v3/search?part=snippet&channelId=${process.env.REACT_APP_YOUTUBE_CHANNEL_ID}&order=date&q=${newWordFraccionamiento}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`)
        .then((response) => {
          // handle success
          console.log(response);
          setPlaylist(response.data.items);
          localStorage.setItem(`last_update_videos_${fraccionamiento.nombre}`, process.env.REACT_APP_YOUTUBE_LIST_UPDATE);
          localStorage.setItem(`playlist_${fraccionamiento.nombre}`, JSON.stringify(response.data.items));
          console.log('se lleno de la api');
        })
        .catch((error) => {
          // handle success
          console.log(error);
        })
        .then(() => {
          // handle success
          //console.log('sabe');
        });
      }, [fraccionamiento]);
      // useEffect(() => {
      //   localStorage.setItem(`playlist_${fraccionamiento.nombre}`, JSON.stringify(playlist))
      //   //
      // }, [playlist]);
    return(
        
          <Container sx={{ backgroundColor:'white' }}>
            <Box sx={{ color:'#f5323f' /*backgroundColor:'yellow'*/ }}>
                <h1>Mira este Fraccionamiento en:</h1>                
            </Box>
                    
            <Box
              component={motion.div}
              ref={constraintsRef}
              sx={{
                backgroundColor:'white', 
                display: { xs: 'none', md: 'flex' },
                placeContent: 'start',
                placeItems: 'center',
                overflow: 'hidden',
                position:'relative',
                marginTop:2,
                mb:2,
              }}
            >
              <Stack
                ref={containerRef}
                direction="row"
                justifyContent="center"
                alignItems="center"
                // spacing={2}
                component={motion.div}
                animate={{ x:Xside }}

              >
                {
                  // playlist && (
                    playlist.length > 0 ? (
                      playlist.map((video) => (
                        <Box
                          component={motion.div}
                          sx={{ 
                            width:{ xs: '185px', sm:'185px', md:'360px', },
                            ml:1.5,
                            mr:1.5,
                          }}                          
                        >
                          <VideosFraccionamientoItem video={video} getVideo={getVideo}/>
                        </Box>
                      ))
                    ) 
                    : (
                      <React.Fragment>
                        <Box>
                          <Stack spacing={1}>      
                            <Box sx={{ ml:1 }}>
                              <Skeleton variant="rectangular" width={375} height={260} />
                            </Box>
                          </Stack>
                        </Box>
                        <Box>
                          <Stack spacing={1}>      
                            <Box sx={{ ml:1 }}>
                              <Skeleton variant="rectangular" width={375} height={260} />
                            </Box>
                          </Stack>
                        </Box>
                        <Box>
                          <Stack spacing={1}>      
                            <Box sx={{ ml:1 }}>
                              <Skeleton variant="rectangular" width={375} height={260} />
                            </Box>
                          </Stack>
                        </Box>
                      </React.Fragment>
                    )
                  // )
                }
                {/* <Box
                  component={motion.div}
                  sx={{ 
                    // width: '375px'
                    // width: '185px'
                    width:{ xs: '185px', sm:'185px', md:'375px', lg:'375px' }
                    
                  }}
                  
                >
                  <ModeloItem/>  
                </Box>
                <Box
                  component={motion.div}
                  sx={{ 
                    // width: '375px'
                    // width: '185px'
                    width:{ xs: '185px', sm:'185px', md:'375px', lg:'375px' }
                    
                  }}
                  
                >
                  <ModeloItem/>  
                </Box>
                <Box
                  component={motion.div}
                  sx={{ 
                    // width: '375px'
                    // width: '185px'
                    width:{ xs: '185px', sm:'185px', md:'185px', lg:'375px' }
                    
                  }}
                  
                >
                  <ModeloItem/>  
                </Box>
                <Box
                  component={motion.div}
                  sx={{ 
                    // width: '375px'
                    // width: '185px'
                    width:{ xs: '185px', sm:'185px', md:'375px', lg:'375px' }
                    
                  }}
                  
                >
                  <ModeloItem/>  
                </Box>  */}
                               
              </Stack>
              {
                XIside !== constraintsWidth && (
                  <Box 
                component="Button" 
                sx={{ 
                  pt: 3, pb:3, pl:0, pr:0,
                  backgroundColor:'white', 
                  border:'none',
                  color:'#f5323f',
                  zIndex:1, 
                  position:'absolute',
                  left:0,
                }}
                variant="contained"
                onClick={()=> atras(Xside)}
              >
                  <ChevronLeftIcon />
                </Box>
                )
              }
              {
                XIside !== containerWidth && (
                  <Box 
                component="Button" 
                sx={{ 
                  pt: 3, pb:3, pl:0, pr:0,
                  backgroundColor:'white', 
                  border:'none',
                  color:'#f5323f',
                  zIndex:1, 
                  position:'absolute',
                  right:0,
                }}
                variant="contained"
                onClick={()=> adelante(Xside)}
              >
                  <ChevronRightIcon />
                </Box>
                )
              }
                
            </Box>

            <Box
              component={motion.div}
              ref={constraintsRefMovil}
              sx={{
                backgroundColor:'white', 
                display: { xs: 'flex', md: 'none' },
                placeContent: 'start',
                placeItems: 'center',
                overflow: 'hidden',
                position:'relative',
                marginTop:2,
                mb:3,
              }}
            >
              <Stack
                ref={containerRefMovil}
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                component={motion.div}
                animate={{ x:Xside }}
                drag='x' 
                dragConstraints={constraintsRefMovil}
              >
                {
                  playlist.length > 0 ? (
                    playlist.map((video) => (
                      <Box
                        component={motion.div}
                        sx={{ 
                          // width: '375px'
                          // width: '185px'
                          width:{ xs: '250px', sm:'285px', md:'375px', lg:'375px' }
                          
                        }}
                        
                      >
                        <VideosFraccionamientoItem video={video} getVideo={getVideo}/>
                      </Box>
                    ))
                  ) : (
                    <Box>
                      <Stack spacing={1}>      
                        <Skeleton variant="rectangular" width={380} height={360} />
                      </Stack>
                    </Box>
                  )
                }
                {/* <Box
                  component={motion.div}
                  sx={{ 
                    // width: '375px'
                    // width: '185px'
                    width:{ xs: '250px', sm:'285px', md:'375px', lg:'375px' }
                    
                  }}
                  
                >
                  <ModeloItem/>  
                </Box>
                <Box
                  component={motion.div}
                  sx={{ 
                    // width: '375px'
                    // width: '185px'
                    width:{ xs: '250px', sm:'285px', md:'375px', lg:'375px' }
                    
                  }}
                  
                >
                  <ModeloItem/>  
                </Box> 
                <Box
                  component={motion.div}
                  sx={{ 
                    // width: '375px'
                    // width: '185px'
                    width:{ xs: '250px', sm:'285px', md:'375px', lg:'375px' }
                    
                  }}
                  
                >
                  <ModeloItem/>  
                </Box>
                <Box
                  component={motion.div}
                  sx={{ 
                    // width: '375px'
                    // width: '185px'
                    width:{ xs: '250px', sm:'285px', md:'375px', lg:'375px' }
                    
                  }}
                  
                >
                  <ModeloItem/>  
                </Box>  */}

              </Stack>                
            </Box>
           
            <Box>
              <Collapse in={open} timeout="auto" unmountOnExit>
                {
                  video ? (
                    <Box
                      component="iframe"
                      src={`https://www.youtube.com/embed/${video}`}
                      width='100%'
                      height={{ xs: 300, sm:400, md:560 }}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                      allowfullscreen={true}
                      sx={{
                        borderRadius:{ xs:'10px', sm:'12px', md:'16px' },
                      }}
                    />   
                  ) : (
                    <Skeleton variant="rectangular" width='100%' height={{ xs: 300, sm:400, md:560 }} />
                  )
                }                
              </Collapse>
              
            {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/qdHBosK1mWo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
            </Box>   

        </Container>
        
    );
}

export { VideosFraccionamiento }