import React from "react";
import { Container, Box } from "@mui/material";
import { BenefitsContainer } from "../../../components/BenefitsContainer";
import { CustomTypography } from "../../../components/CustomTypography";
import theme from "../../../App/theme";

const BenefitsSection = () => {
  const benefitsList = [
    {
      img: "https://storage.casaspecsa.com/invierte-renta/beneficios/comodidad-y-espacio.jpg",
      title: "Comodidad y Espacio",
      description: (
        <CustomTypography variant="textPrimary">
          Vive la perfecta combinación de comodidad y espacio en una{" "}
          <CustomTypography variant="textPrimaryBold" txtColor="primary">
            Casa Pecsa
          </CustomTypography>
          .
        </CustomTypography>
      ),
    },

    {
      img: "https://storage.casaspecsa.com/invierte-renta/beneficios/ubicacion-estrategica.jpg",
      title: "Ubicacion Estrategica",
      direction: "row-reverse",
      description: (
        <CustomTypography variant="textPrimary">
          Nuestras casas están estratégicamente ubicadas{" "}
          <CustomTypography variant="textPrimaryBold">
            cerca de atracciones y servicios.
          </CustomTypography>
        </CustomTypography>
      ),
    },

    {
      img: "https://storage.casaspecsa.com/invierte-renta/beneficios/privacidad-y-exlusividad.jpg",
      title: "Privacidad y Exclusividad",
      customStyle: "thirdOption",
      description: (
        <CustomTypography variant="textPrimary">
          Disfruta de la privacidad y exclusividad que solo una casa puede
          ofrecer.
        </CustomTypography>
      ),
    },
  ];

  return (
    <Box
      component="section"
      sx={{
        backgroundColor: theme.palette.grey[100],
        py: "2.5rem",
        [theme.breakpoints.up("sm")]: {
          py: "5rem",
        },
      }}
    >
      <Container
        sx={{
          display: "flex",
          textAlign: "center",
          flexDirection: "column",
          gap: 3.75,
        }}
      >
        <CustomTypography variant="subheadingPrimary" txtColor="primary">
          Beneficios de Rentar en Casas Pecsa
        </CustomTypography>

        <BenefitsContainer benefits={benefitsList} />
      </Container>
    </Box>
  );
};

export { BenefitsSection };
