import { Grid, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";

import tarjetaFamiliaPecsa from '../../assets/prueba/tarjetaFamiliaPecsa.svg';

const Requisitos = () => {
    return(
        <Container>
            <Box
                sx={{
                    // backgroundColor:'yellow',
                    width:'100%',
                    minHeight:300,
                    color:'#707070',
                    pt:3,
                    pb:2,
                }}
            >
                <Grid 
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    {/* <Grid item xs={12} sm={12} md={12}>
                        <Box
                            sx={{
                                
                            }}
                        >
                            <Typography variant="h4" sx={{ color:'#F5323F' }}>
                                <b>Requisitos para participar</b>
                            </Typography>
                            <Typography variant="h6" sx={{ color:'#707070', }}>
                                Hay tres maneras de hacer llegar sus recomendaciones:
                            </Typography>
                        </Box>
                    </Grid> */}
                    
                    <Grid item xs={12} sm={12} md={6}>
                        <Box
                            sx={{
                                // backgroundColor:'green',
                                width:'100%',
                                display:'flex',
                                alignItems:'center',
                                alignContent:'center',
                            }}
                        >
                            <Box
                                component='img'
                                src={tarjetaFamiliaPecsa}
                                sx={{
                                    width:'80%',
                                    m:'0 auto',
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box
                            sx={{
                                mr:2,
                                mt:2,
                            }}
                        >
                            <Typography variant="h4" sx={{ color:'#F5323F' }}>
                                <b>Requisitos para participar</b>
                            </Typography>
                            <Typography variant="subtitle1" sx={{ mb:1, }}>                                        
                                <li><b>Ser cliente Pecsa habiendo comprado casa en cualquiera de nuestros fraccionamientos.</b></li>
                            </Typography>
                            <Typography variant="subtitle1" sx={{ mb:1, }}>
                                <li><b>Ser mayor de edad.</b></li>
                            </Typography>
                            <Typography variant="subtitle1" sx={{ mb:1, }}>
                                <li><b>Presentar identificación oficial con fotografía (INE, Pasaporte o Licencia de conducir)</b></li>
                            </Typography>
                            <Typography variant="subtitle1" sx={{ mb:1, }}>
                                <li><b>Llenar y firmar formato de registro y política de promoción.</b></li>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

export { Requisitos }