import { Button, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import EditIcon from '@mui/icons-material/Edit';
import { useState } from "react";
import { AddExperiencia } from "./AddExperiencia";
import { ListaExperiencia } from "./ListaExperiencia";

const Experiencia = ({ experiencia }) => {
    const [agregar, setAgregar] = useState(false);
    const getAgregar = () => {
        setAgregar(!agregar);
    }

    const [listExperiencia, setListExperiencia] = useState(experiencia);
    const addListExperiencia = (newExperiencia) => {
        console.log('adlist', newExperiencia);
        setListExperiencia((oldListExperiencia)=>[...oldListExperiencia, newExperiencia]);
        console.log('list', listExperiencia);
    }
    
    return(
        <Box
            sx={{
                minHeight:100,
            }}
        >
            <Typography variant="h5">
                Experiencia
            </Typography>
            <Box
                sx={{
                    width:'100%',
                }}
            >
                {
                    agregar && (
                        <AddExperiencia getAgregar={getAgregar} addListExperiencia={addListExperiencia} />
                    )
                }
                {
                    listExperiencia.length > 0 ? (
                        <ListaExperiencia experiencia={listExperiencia} />
                    ) : (
                        <Box>
                            <Typography>
                                No hay nada
                            </Typography>                    
                        </Box>
                    )
                }
                {
                    !agregar && (
                        <Box
                            sx={{
                                mt:1,
                                mb:1,
                                // backgroundColor:'red',
                                width:'100%',
                                textAlign:'right',
                            }}
                        >
                            <Button 
                                variant="contained" 
                                endIcon={<EditIcon />}
                                sx={{
                                    backgroundColor:'#707070',
                                    textTransform:'none',
                                    borderRadius:'10px',
                                    width:{ xs:'100%', sm:'100%', md:'15%' }
                                }}
                                onClick={getAgregar}
                            >
                                <b>Agregar</b>
                            </Button>
                        </Box>
                    )
                }             
                        
            </Box>
        </Box>
    );
}

export { Experiencia }