import { Breadcrumbs, Divider, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { CalendarioAgenda } from "./CalendarioAgenda";
import { ListaCitas } from "./ListaCitas";

const EspacioAsesorAgenda = () => {
    const { folio } = useParams();

    const [asesor, setAsesor] = useState(null);

    const [dia, setDia] = useState(null);
    const getDia = (d) => {
        setDia(d);
    }

    const [error, setError] = useState(null);
    
    useEffect(()=>{
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');

        axios.get(`${process.env.REACT_APP_API_URL}/api/asesores/profile/${folio}/agenda`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        )
        .then((response) => {
            // handle success
            console.log(response);
            setAsesor(response.data);
        })
        .catch((err) => {
            // handle success
            console.log(err);
            setError(true);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            // console.log('sae');
        });
    },[folio]);
    return(
        <Box
            sx={{
                // backgroundColor:'red',
                width:'100%',
            }}
        >
            <Box>
                <Breadcrumbs aria-label="breadcrumb">
                    {/* <Link underline="hover" color="inherit">
                        Inicio
                    </Link> */}
                    <Link to={'/'} style={{ color:'#707070', }}>
                        Espacio Asesor
                    </Link>
                    <Typography sx={{ color:'#F5323F', }}>Agenda</Typography>
                </Breadcrumbs>
            </Box>
            {
                asesor ? (
                    <Box>
                        <Box>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    {
                                        asesor && (
                                            <CalendarioAgenda asesor={asesor} getDia={getDia} />
                                        )
                                    }
                                    
                                </Grid>
                            </Grid>
                        </Box>
                        <Divider variant="middle"/>
                        <Box>
                            <ListaCitas citas={asesor.citas} dia={dia}/>
                        </Box>
                    </Box>
                ) : (
                    <Box>
                        {
                            error ? (
                                <h1>Error</h1>
                            ) : (
                                <h1>cargarando</h1>
                            )
                        }
                    </Box>
                )
            }
            
        </Box>
    );
}

export { EspacioAsesorAgenda }