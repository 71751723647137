import { Button, Grid, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { useNavigate } from "react-router-dom";

const BannerOpciones = () => {
    let navigate = useNavigate();
    const reedirigirCalendly = () =>{
        window.open('https://calendly.com/casas-pecsa')
    }
    const reedirigirCompracasa = () =>{
        navigate('/tienda');
    }
    return(
        <Container>
            <Box>
                <Grid 
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    //spacing={8}
                    sx={{
                        pt:3,
                        pb:4
                    }}
                >
                    <Grid item xs={12} sm={6} md={6}>
                        <Box 
                            sx={{
                                textAlign:'center',
                                m:5,
                                
                            }}
                        >
                            <Typography variant="h6" gutterBottom sx={{ color:'#f5323f' }}>
                                <b>Visita el Fraccionamiento</b>
                            </Typography>
                            <Box
                                sx={{
                                    mb:5,
                                }}
                            >
                                <Typography variant="body1" gutterBottom sx={{ color:'#707070' }}>
                                    Visita nuestros fraccionamientos y conoce de primera mano nuestros modelos o bien agenda una cita virtual con nuestros asesores y obten asesoria online.
                                </Typography>
                            </Box>                            
                            <Button 
                                variant="outlined" 
                                color="inherit" 
                                sx={{ 
                                    // backgroundColor:'#707070', 
                                    color:'#707070' 
                                }}
                                onClick={reedirigirCalendly}
                            >
                                <b>Agendar Cita</b>
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box 
                            sx={{
                                textAlign:'center',
                                m:5,
                                
                            }}
                        >
                            <Typography variant="h6" gutterBottom sx={{ color:'#f5323f' }}>
                                <b>Amor a primera vista</b>
                            </Typography>
                            <Box
                                sx={{
                                    mb:5,
                                }}
                            >
                                <Typography variant="body1" gutterBottom sx={{ color:'#707070' }}>
                                    ¿Ya te enamoraste de nuestros modelos? ¡No esperes más! Separa tu casa en este instante completamente online. Rapido, comodo y seguro.
                                </Typography>
                            </Box>
                            <Button 
                                variant="outlined" 
                                color="inherit" 
                                sx={{ 
                                    // backgroundColor:'#707070', 
                                    color:'#707070' 
                                }} 
                                onClick={reedirigirCompracasa}
                            >
                                <b>Separa tu casa</b>
                            </Button>
                        </Box>                            
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

export { BannerOpciones }