import { Outlet } from "react-router-dom";

const CargasEmpresas = () => {
    return(
        <div>            
            <Outlet/>
        </div>
    );
}

export { CargasEmpresas }