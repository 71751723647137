import { TableCell, TableRow } from "@mui/material";
import { CheckboxInversion } from "./CheckboxInversion";
import { SelectEstatus } from "./SelectEstatus";

const ItemLista = ({ transaccion, handleChange }) => {
    return(
        <TableRow
            // key={row.name}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell component="th" scope="row">
                { `${transaccion.cliente.datos.primerNombre} ${transaccion.cliente.datos.segundoNombre !==null ? (transaccion.cliente.datos.segundoNombre + ' ') : ''}${transaccion.cliente.datos.primerApellido} ${transaccion.cliente.datos.segundoApellido}` }
            </TableCell>
            <TableCell align="center">
                { transaccion.lote.manzana.nombre }
            </TableCell>
            <TableCell align="center">
                { transaccion.lote.numeroDeLote }
            </TableCell>            
            <TableCell align="center">
                { `${transaccion.lote.manzana.etapa.fraccionamiento.nombre} ${transaccion.lote.manzana.etapa.nombre}` }
            </TableCell>
            <TableCell align="center">
                <CheckboxInversion transaccion={transaccion} handleChangeProp={handleChange} inversion={transaccion.separado.inversion} />
            </TableCell>
            <TableCell align="right">
                <SelectEstatus transaccion={transaccion} estatus={transaccion.idEstatus} />
            </TableCell>
        </TableRow>
    );
}

export { ItemLista }