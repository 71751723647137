import React, { useEffect, useState } from "react";
import axios from "axios";
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation, Link, NavLink, Navigate } from "react-router-dom";
import { Autocomplete, Box, Button, ButtonBase, Checkbox, Chip, Container, Divider, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import ClearIcon from '@mui/icons-material/Clear';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const elementosPlantas = [
    '2 Baños Completos',
    '3 Recamaras',
    'Sala',
    'Comedor'

]

const AddModelo = () => {
    let navigate = useNavigate();
    const [selectedImagePlantaBaja, setSelectedImagePlantaBaja] = useState(null);
    const [imagePlantaBajaUrl, setImagePlantaBajaUrll] = useState(null);
    const [selectedImagePlantaAlta, setSelectedImagePlantaAlta] = useState(null);
    const [imagePlantaAltaUrl, setImagePlantaAltaUrll] = useState(null);

    const [selectedElementosPlantaBaja, setSelectedElementosPlantaBaja] = useState(null);
    const [selectedElementosPlantaAlta, setSelectedElementosPlantaAlta] = useState(null);

    const [storageImages, setStorageImages] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [imagesUrl, setImagesUrl] = useState([]);

    const [principal, setPrincipal] = useState(0);

    const add = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');

        // console.log(data.get('precio')); return;

        data.set('plantaBaja', selectedElementosPlantaBaja);
        data.set('plantaAlta', selectedElementosPlantaAlta);
        data.set('folioCreateBy', folio);
        axios.post(`${process.env.REACT_APP_API_URL}/api/modelo/`, 
            // // {
            // //     nombre: data.get('nombre'),
            // //     idMpio: selectedCiudad.id,
            // //     logoImg: selectedImage,
            // //     fotosImg: storageImages,
            // //     folioCreateBy: folio,
            // // },
            data,
            {
              headers: { 
                Authorization: `Bearer ${token}`,   
                'Content-Type': 'multipart/form-data',               
                //ContentType: 'multipart/form-data'
              }              
        })
        .then((response) => {
            // handle success            
            console.log(response);
            console.log('success axio');
            navigate(-1, { replace: true });
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sabe');
        });

    };
    const removeImagen = (index) => {
        storageImages.splice(index, 1);
        imagesUrl.splice(index, 1);
        setStorageImages(storageImages);
        setImagesUrl(imagesUrl);
    }
    const asignarImagenes = (files) => {
        setSelectedImages(files)
        setImagesUrl([...imagesUrl, ...selectedImages]);
    }
    useEffect(() => {
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        
        if (selectedImagePlantaBaja) {
            setImagePlantaBajaUrll(URL.createObjectURL(selectedImagePlantaBaja[0]));
        }
        if (selectedImagePlantaAlta) {
            setImagePlantaAltaUrll(URL.createObjectURL(selectedImagePlantaAlta[0]));
        }

        if (selectedImages.length > 0) {
            console.log(selectedImages.length);
            // console.log('imagenes seleccionsadoas: ', selectedImages);
            for (let i = 0; i < selectedImages.length; i++) {
                if(!storageImages.find( element => element === selectedImages[i])){
                    storageImages.push(selectedImages[i]);
                    imagesUrl.push(URL.createObjectURL(selectedImages[i]))                    
                }                
            }
        }


        //setPrincipal(Math.random(10) *100);
        axios.get(`${process.env.REACT_APP_API_URL}/api/modelo/createview`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((response) => {
            // handle success
            console.log(response);
            console.log(response.data);
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
        });
    }, [selectedImagePlantaBaja, selectedImagePlantaAlta, selectedImages, imagesUrl]);
    return(
        <Container sx={{ backgroundColor:'white' }}>
            <Box component="form" onSubmit={add}>
                <h2>Nuevo Modelo</h2>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item xs={12} sm={6} md={12}>
                        <TextField id="outlined-basic" label="Nombre" variant="outlined" name="nombre" sx={{ width: { xs:'100%', sm:'100%', md:'70%' } }} required/>
                    </Grid>                    
                    <Grid item xs={12} sm={6} md={12}>
                        <TextField id="outlined-basic" label="Superficie de Construccion" variant="outlined" name="superficieDeConstruccion" sx={{ width: { xs:'100%', sm:'100%', md:'70%' } }} required/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                        {/* <TextField id="outlined-basic" label="Superficie de Construccion" variant="outlined" name="precio" sx={{ width: { xs:'100%', sm:'100%', md:'70%' } }} required/> */}
                        <FormControl sx={{ width: { xs:'100%', sm:'100%', md:'30%' } }}>
                            <InputLabel htmlFor="outlined-adornment-amount">Precio Aproximado</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-amount"
                                // value={values.amount}
                                // onChange={handleChange('amount')}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                label="Precio Aproximado"
                                type='number'
                                step="any"
                                name="precio"
                                //defaultValue={precio} onChange={(e)=>{ setPrecio(e.target.value) }}
                                required
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                        <label htmlFor="contained-button-file-plano-arquitectonico">
                            <Input accept="image/*, .pdf" id="contained-button-file-plano-arquitectonico" name="archivoPlanoArquitectonicoDoc" type="file" sx={{ display:'none' }} />
                            <Button variant="contained" component="span" endIcon={<UploadFileIcon />}>
                                Cargar Plano Arquitectonico 
                            </Button>
                        </label>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                        <TextField id="outlined-basic" label="Link de Tour Virtual" variant="outlined" name="linkTourVirtual" sx={{ width: { xs:'100%', sm:'100%', md:'50%' } }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" type='number' label="Baños" variant="outlined" name="banios" sx={{ width: { xs:'100%', sm:'100%', md:'50%' } }} required/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" type='number' label="Habitaciones" variant="outlined" name="habitaciones" sx={{ width: { xs:'100%', sm:'100%', md:'50%' } }} required/>
                    </Grid>
                </Grid>
                <Divider />
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item xs={12} sm={6} md={12}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={2}
                        >
                            <Grid item xs={12} sm={6} md={6}>
                                <Typography variant="h5" gutterBottom component="div">
                                    Planta Baja
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom component="div">
                                    Selecciona los elementos de la Planta baja.
                                </Typography>
                                <Autocomplete
                                    multiple
                                    id="tags-filled"
                                    options={elementosPlantas.map((elemento) => elemento)}
                                    name='elementosPlantaBaja'
                                    freeSolo
                                    renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                    }
                                    
                                    onChange={(event, newTags)=>{setSelectedElementosPlantaBaja(newTags);}}
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        
                                        label="Elementos"
                                        placeholder="Seleciona o escribe los elementos de planta baja"
                                    />
                                    )}
                                />
                            </Grid> 
                            <Grid item xs={12} sm={6} md={6}>
                                <label htmlFor="contained-button-file-plantabaja">                            
                                    <input accept="image/*" id="contained-button-file-plantabaja" type="file" name="plantabajaImg" style={{ display: 'none' }} onChange={e => setSelectedImagePlantaBaja([e.target.files[0]])}/>
                                    <ButtonBase
                                        component="span"
                                        sx={{
                                            m:1,
                                            width: '40%',
                                        }}
                                    >
                                        {
                                            imagePlantaBajaUrl ? (
                                                <Box
                                                    component='img'
                                                    src={imagePlantaBajaUrl}
                                                    sx={{
                                                        m:1,
                                                        width: '100%',
                                                        height: 400,
                                                        borderStyle: 'dotted'
                                                    }}
                                                />
                                            ) 
                                            : (                                                
                                                <Box
                                                    component='div'
                                                    sx={{
                                                        m:1,
                                                        width: '100%',
                                                        height: 400,
                                                        borderStyle: 'dotted'
                                                    }}
                                                >
                                                    <AddAPhotoIcon/>
                                                    <Typography variant="h5" gutterBottom component="div">
                                                        Clic para cargar Foto Planta Baja
                                                    </Typography>
                                                </Box>
                                            )
                                        }
                                        {/* <Box
                                            component='div'
                                            sx={{
                                                m:1,
                                                width: '100%',
                                                height: 400,
                                                borderStyle: 'dotted'
                                            }}
                                        >
                                            <AddAPhotoIcon/>
                                            <Typography variant="h5" gutterBottom component="div">
                                                Clic para cargar Foto Planta Baja
                                            </Typography>
                                        </Box> */}
                                        {/* <Box
                                            component='img'
                                            src={imagePlantaBajaUrl}
                                            sx={{
                                                m:1,
                                                width: '100%',
                                                height: 400,
                                                borderStyle: 'dotted'
                                            }}
                                        /> */}
                                    </ButtonBase>
                                </label>

                            </Grid> 
                        </Grid>
                    </Grid> 
                </Grid>
                <Divider />
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item xs={12} sm={6} md={12}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={2}
                        >
                            <Grid item xs={12} sm={6} md={6}>
                                <Typography variant="h5" gutterBottom component="div">
                                    Planta Alta
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom component="div">
                                    Selecciona o escribe los elementos de la Planta Alta.
                                </Typography>
                                <Autocomplete
                                    multiple
                                    id="tags-filled"
                                    options={elementosPlantas.map((elemento) => elemento)}
                                    name='elementosPlantaAlta'
                                    freeSolo
                                    renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                    }
                                    onChange={(event, newTags)=>{setSelectedElementosPlantaAlta(newTags);}}
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        
                                        label="Elementos"
                                        placeholder="Seleciona o escribe los elementos de planta alta"
                                    />
                                    )}
                                />
                            </Grid> 
                            <Grid item xs={12} sm={6} md={6}>
                                <label htmlFor="contained-button-file-plantaalta">                            
                                    <input accept="image/*" id="contained-button-file-plantaalta" type="file" name="plantaaltaImg" style={{ display: 'none' }} onChange={e => setSelectedImagePlantaAlta([e.target.files[0]])}/>
                                    <ButtonBase
                                        component="span"
                                        sx={{
                                            m:1,
                                            width: '40%',
                                        }}
                                    >
                                        {
                                            imagePlantaAltaUrl ? (
                                                <Box
                                                    component='img'
                                                    src={imagePlantaAltaUrl}
                                                    sx={{
                                                        m:1,
                                                        width: '100%',
                                                        height: 400,
                                                        borderStyle: 'dotted'
                                                    }}
                                                />
                                            ) 
                                            : (                                                
                                                <Box
                                                    component='div'
                                                    sx={{
                                                        m:1,
                                                        width: '100%',
                                                        height: 400,
                                                        borderStyle: 'dotted'
                                                    }}
                                                >
                                                    <AddAPhotoIcon/>
                                                    <Typography variant="h5" gutterBottom component="div">
                                                        Clic para cargar Foto Planta Alta
                                                    </Typography>
                                                </Box>
                                            )
                                        }
                                        {/* <Box
                                            component='div'
                                            sx={{
                                                m:1,
                                                width: '100%',
                                                height: 400,
                                                borderStyle: 'dotted'
                                            }}
                                        >
                                            <AddAPhotoIcon/>
                                            <Typography variant="h5" gutterBottom component="div">
                                                Clic para cargar Foto Planta Baja
                                            </Typography>
                                        </Box> */}
                                        {/* <Box
                                            component='img'
                                            src={imagePlantaBajaUrl}
                                            sx={{
                                                m:1,
                                                width: '100%',
                                                height: 400,
                                                borderStyle: 'dotted'
                                            }}
                                        /> */}
                                    </ButtonBase>
                                </label>

                            </Grid> 
                        </Grid>
                    </Grid> 
                </Grid>
                <Divider />
                <Button variant="contained" type="submit">Guardar</Button>
            </Box>
        </Container>
    );
}

export { AddModelo }