import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Paper from '@mui/material/Paper';
import { ItemLista } from "./ItemLista";

const Lista = ({ transacciones, handleChangeProp }) => {

    const handleChange = (newData) => {
        // console.log('mr white::',newData);
        // console.log(transacciones);
        // console.log([...transacciones, newData]);
        // console.log(transacciones.map((item)=>{
        //     if(item.id === newData.id){
        //         return newData;
        //     }
        //     return item
        // }));
        handleChangeProp(transacciones.map((item)=>{
            if(item.id === newData.id){
                return newData;
            }
            return item
        }))
    }

    return(
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Cliente</TableCell>
                        <TableCell align="center">Manzana</TableCell>
                        <TableCell align="center">lote</TableCell>                            
                        <TableCell align="center">Fraccionamiento</TableCell>
                        <TableCell align="center">Para Invertir</TableCell>
                        <TableCell align="center">Estatus</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        transacciones.map((transaccion)=>(
                            <ItemLista key={transaccion.id} transaccion={transaccion} handleChange={handleChange} />
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export { Lista }