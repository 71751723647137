import {
  Box,
  Container,
  Link,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { CustomTypography } from "../../../components/CustomTypography";
import { MyAccordion } from "../../../components/MyAccordion";
import theme from "../../../App/theme";

const faqs = [
  {
    title: "¿Como rentar una casa Pecsa?",
    body: (
      <Box>
        Da clic <Link href="#listing">aquí</Link> y busca entre nuestro listado
        de opciones la casa que más te guste o se acomode a tus necesidades,
        puedes buscar por precio de renta o por fraccionamiento, cuando
        encuentres la casa que más te guste y da clic en contactar, llena el
        formulario y el dueño se pondrá en contacto contigo.
      </Box>
    ),
  },
  {
    title: "¿Como ponerme en contacto con un asesor para mas informacion?",
    body: (
      <Box>
        <Box>Puede ser de las siguientes dos formas:</Box>
        <Box>
          <List dense={true}>
            <ListItem>
              <ListItemText
                primary={
                  <Box sx={{ fontFamily: "Montserrat" }}>
                    Puedes agendar una cita con un asesor, ya sea presencial o
                    virtual, <Link href="/citas">aquí</Link>.
                  </Box>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Box sx={{ fontFamily: "Montserrat" }}>
                    Envíanos un Whatsapp al{" "}
                    <Link
                      href="https://api.whatsapp.com/send?phone=528441630722&text=Hola!,%20me%20gustaria%20rentar%20una%20casa%20Pecsa"
                      target="_blank"
                    >
                      +52 844 163 0722
                    </Link>{" "}
                    o dando clic{" "}
                    <Link
                      href="https://api.whatsapp.com/send?phone=528441630722&text=Hola!,%20me%20gustaria%20rentar%20una%20casa%20Pecsa"
                      target="_blank"
                    >
                      aquí
                    </Link>
                    .
                  </Box>
                }
              />
            </ListItem>
          </List>
        </Box>
      </Box>
    ),
  },
  {
    title:
      "Si estoy interesado en rentar casa y no hay casas en renta disponibles en este momento ¿Qué puedo hacer?",
    body: (
      <Box>
        Puedes registrarte en este{" "}
        <Link href="renta/contacto">formulario</Link> y estar en
        una lista de espera para cuando se habilite una casa en renta.
      </Box>
    ),
  },
  {
    title: "¿Puedo negociar el precio de renta?",
    body: (
      <Box>
        Depende de cada dueño, pero al ponerse en contacto contigo pueden
        negociar o llegar a un acuerdo acerca del precio de renta.
      </Box>
    ),
  },
];
const FAQSection = () => {
  return (
    <Box
      component="section"
      sx={{
        backgroundColor: theme.palette.grey[100],
        py: "2.5rem",
        [theme.breakpoints.up("sm")]: {
          py: "5rem",
        },
      }}
    >
      <Container
        sx={{
          display: "flex",
          textAlign: "center",
          flexDirection: "column",
          // gap: 1
        }}
      >
        <Box>
          <CustomTypography variant="subheadingPrimary" txtColor="primary">
            Preguntas Frecuentes
          </CustomTypography>
        </Box>
        {faqs.map((faq) => (
          <MyAccordion title={faq.title} body={faq.body} />
        ))}
        {/* <MyAccordion title="¿Como comprar una casa Pecsa?" body="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500" />
                <MyAccordion title="¿Como puedo poner mi casa en renta?" body="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen." /> */}
      </Container>
    </Box>
  );
};

export { FAQSection };
