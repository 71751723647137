import { Outlet, useNavigate } from "react-router-dom";

const CargasManzana = (props) => {
    let navigate = useNavigate();
    //const [personajes, setPersonajes] = useState(props.datos.personajes || []);

    return(
        <div>            
            <Outlet/>
        </div>
    );
}

export { CargasManzana }