import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { useState } from "react";
import { AddHorario } from "./AddHorario";
import 'moment/locale/es';
// moment().locale("es")
const ItemCalendarioSemanaAsesores = ({ dia, asesor }) => {    
    // if(asesor.horario.length > 0){
    //     console.log('asesor', asesor.nombre);
        console.log('dia', dia.format('DD-MM-YYYY'));
        console.log('hey', asesor.horario.map((hor)=>hor.fecha));
        // console.log('dia aa',moment(asesor.horario[0].fecha, 'DD-MM-YYYY').format('DD-MM-YYYY'));
        // console.log('Que trajiste_', asesor.horario.find((diaH)=>{
        //     if(moment(diaH.fecha, 'DD-MM-YYYY').format('DD-MM-YYYY') === dia.format('DD-MM-YYYY')){
        //         return diaH
        //     }else{
        //         console.log('diaH', moment(diaH.fecha, 'DD-MM-YYYY').format('DD-MM-YYYY'));
        //         console.log('dia', dia.format('DD-MM-YYYY'));
        //     }
        // }));
    // }
    const [horario, SetHorario] = useState(asesor.horario.find((diaH)=>moment(diaH.fecha, 'YYYY-MM-DD').format('DD-MM-YYYY')===dia.format('DD-MM-YYYY')));

    const getDia = () => {
        const soloDias = asesor.horario.map((fecha)=>{
            return fecha.fecha
        });
        // console.log(soloDias);
        // console.log(dia);
        const hay = soloDias.find((soloDia)=>moment(soloDia).format('DD-MM-YYYY')===dia.format('DD-MM-YYYY'));
        if(hay){
            console.log(hay);
            return true;
        }else{
            return false;
        }
    }
    const [agregar, setAgregar] = useState(false);
    const getAgregar = () => {
        setAgregar(!agregar);
    }
    const [editar, setEditar] = useState(false);
    const getEditar = () => {
        setEditar(!editar);
    }

    return(
        <Box
            sx={{
                width:'100%',
            }}
        >
            {
                horario ? (
                    <Card>
                        <CardActionArea>
                            <CardContent>
                                <Typography variant="caption">
                                    {/* { `${moment('15:00:00', 'HH:mm:ss').format('HH:mm')} - ${moment('20:00:00', 'HH:mm:ss').format('HH:mm')}` } */}
                                    { `${moment(horario.horaInicio, 'HH:mm:ss').format('HH:mm')} - ${moment(horario.horaFinal, 'HH:mm:ss').format('HH:mm')}` }
                                </Typography>
                                <Typography variant="body2">
                                    { horario.fraccionamiento.nombre }
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                ) : (
                    <Card>
                        <CardActionArea onClick={getAgregar}>
                            <CardContent>
                                <Typography variant="body2">
                                    Agregar Horario
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                )
            }
            <AddHorario handleClose={getAgregar} open={agregar} asesor={asesor} dia={dia} />
        </Box>
    );
}

export { ItemCalendarioSemanaAsesores }