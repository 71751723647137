

import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { DeleteEducacion } from './DeleteEducacion';
import { EditEducacion } from './EditEducacion';

const ItemEducacion = ({ escuela }) => {
    // const [fechaInicio, setFechaInicio] = useState(null);
    // const [fechaFinal, setFechaFinal] = useState(null);
    const [editar, setEditar] = useState(false);
    const getEditar = () => {
        setEditar(!editar);
    }
    const [instituto, setInstituto] = useState(escuela.escuela);
    const [titulo, setTitulo] = useState(escuela.titulo);
    const [fechaInicio, setFechaInicio] = useState(moment(escuela.fechaInicio));
    const [fechaFinal, setFechaFinal] = useState(moment(escuela.fechaFinal));

    const updateDatos = (instituto, titulo, fechaInicio, fechaFinal) => {
        setInstituto(instituto);
        setTitulo(titulo);
        setFechaInicio(fechaInicio);
        setFechaFinal(fechaFinal);
    }
    return(
        <Box
            sx={{
                // backgroundColor:'yellow',
                width:'100%',
            }}
        >
            {
                !editar ? (
                    <Grid container>
                        <Grid item xs={12} sm={5} md={5}>
                            <Box>
                                <Typography>
                                    { instituto }
                                </Typography>
                                <Typography>
                                    { titulo }
                                </Typography>
                                <Typography>
                                    {
                                        `${fechaInicio.year()} - ${fechaFinal.year()}`
                                    }
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Box
                                sx={{
                                    mt:1,
                                    mb:1,
                                    // backgroundColor:'red',
                                    width:'100%',
                                    textAlign:'left',
                                }}
                            >
                                <Button 
                                    variant="contained" 
                                    endIcon={<EditIcon />}
                                    sx={{
                                        backgroundColor:'#707070',
                                        textTransform:'none',
                                        borderRadius:'10px',
                                        width:{ xs:'100%', sm:'100%', md:'35%' },
                                        mr:2,
                                    }}
                                    onClick={getEditar}
                                >
                                    <b>Editar</b>
                                </Button>
                                <DeleteEducacion idEducacion={escuela.id}/>
                            </Box>                                                                                                    
                        </Grid>
                    </Grid>
                ) : (
                    <EditEducacion getEditar={getEditar} escuela={escuela} updateDatos={updateDatos} />
                )
            }            
            
        </Box>
    );
}

export { ItemEducacion }