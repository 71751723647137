import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Box, Container, ThemeProvider } from "@mui/material";
import { BannerSection } from "./BannerSection";
import { InvestmentAmountSection } from "./InvestmentAmountSection";
import { SummarySection } from "./SummarySection";
import { CTASection } from "./CTASection";
import theme from "../../App/theme";
import { BackButton } from "../../components/BackButton";

const InvestmentComparator = () => {
  const [searchParams] = useSearchParams();
  const [inversionInicial, setInversionInicial] = useState(
    searchParams.get("inversionInicial")
  );
  const getInversionInicial = (newValue) => {
    setInversionInicial(newValue);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>{`Invierte en casas Pecsa - Comparador | Casas Pecsa`}</title>
        <meta name="description" content="Rentar copy" />
      </Helmet>
      <Box
        sx={{ bgcolor: theme.palette.grey[200], mt: { xs: 9, sm: 8, md: 12 } }}
      >
        <BannerSection />
        <Container>
          <BackButton label={"Regresar"} />
        </Container>
        {/* <InvestmentAmountSection /> */}
        <SummarySection inversionInicial={inversionInicial} />
        <CTASection inversionInicial={inversionInicial} />
      </Box>
    </ThemeProvider>
  );
};

export { InvestmentComparator };
