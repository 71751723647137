import { Typography } from "@mui/material";
import { Box, Container } from "@mui/system";

const Ubicacion = () => {
    return(
        <Box
            sx={{
                // backgroundColor:'#c7b497',
            }}
        >
            <Container>
                <Box
                    sx={{
                        width:'100%',
                        textAlign:'center',
                        color:'#69542c',
                        mt:4,
                        mb:1,
                    }}
                >
                    <Typography variant="h3" sx={{ fontFamily:'Montserrat', }}>
                        <b>Te encantará su ubicación privilegiada al oriente de Saltillo</b>
                    </Typography>
                </Box>
                {/* <Box
                    sx={{
                        width:'100%',
                        // textAlign:'center',
                        // mt:4,
                        mb:1,
                        color:'#615c51',
                    }}
                >
                    <Typography variant="body1">
                        <b>Ubicacion dfdfdf dgffg fg</b>
                    </Typography>
                </Box> */}
                <Box
                    sx={{
                        // backgroundColor:'#523f32',
                        width:'100%',
                        height:420,

                    }}
                >
                    <Box
                        component="iframe"
                        src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14414.685476389264!2d-100.9258037!3d25.4158088!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86880da3817a017d%3A0x8b790402000b437b!2sAltavista%20Residencial!5e0!3m2!1ses-419!2smx!4v1677624400961!5m2!1ses-419!2smx'
                        sx={{
                            width:'99.82%',
                            height:400,
                            borderRadius:'12px',
                        }}
                    />
                </Box>
            </Container>
        </Box>
    );
}

export { Ubicacion }