import { Avatar, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, CardMedia, Grid, IconButton, ImageList, ImageListItem, List, ListItem, ListItemText, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";

import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";


import asesorPerfil2 from "../../assets/asesores/asesorPerfil2.png";
import imagenPromo from "../../assets/prueba/imagenPromo.png";
import { useParams } from "react-router-dom";
import axios from "axios";

const EspacioAsesorFotos = () => {
    const [value, setValue] = useState(null);
    const { folio } = useParams();

    const [posts, setPosts] = useState([]);

    const [fotos, setFotos] = useState([]);

    useEffect(()=>{
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');

        axios.get(`${process.env.REACT_APP_API_URL}/api/asesores/profile/${folio}/posts`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
        .then((response)=>{
            console.log(response);
            setPosts(response.data);
        })
        .catch((error)=>{
            console.log(error);
        });
    },[folio]);
    useEffect(()=>{
      // setFotos(posts.map());
    },[posts])
    function srcset(image, size, rows = 1, cols = 1) {
        return {
          src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
          srcSet: `${image}?w=${size * cols}&h=${
            size * rows
          }&fit=crop&auto=format&dpr=2 2x`,
        };
    }
    return(
        <Box
            sx={{
                // backgroundColor:'red',
                width:'100%',
            }}
        >
            <ImageList
                sx={{ width: '100%',  }}
                variant="quilted"
                cols={4}
                rowHeight={121}
            >
                <ImageListItem 
                    key={0} 
                    cols={1} 
                    rows={2}
                    onClick={()=>{ alert('heehehe'); }}
                >
                    <Box
                        sx={{
                            backgroundColor:'#f2f2f2',
                            width:'100%',
                            height:'100%',
                            display:'flex',
                            alignItems:'center',
                            alignContent:'center',
                            borderRadius:'12px',
                        }}
                    >
                        <Box
                            sx={{
                                textAlign:'center',
                                m:'0 auto',
                                color:'#707070',                                
                            }}
                        >
                            <AddPhotoAlternateIcon/>
                            <Typography variant="body1">
                                <b>Agregar</b>
                            </Typography>
                        </Box>
                    </Box>
                </ImageListItem>
                {
                    itemData.map((item) => (
                        <ImageListItem 
                            key={item.img} 
                            cols={item.cols || 1} 
                            rows={item.rows || 1}
                            onClick={()=>{ alert('heehehe'); }}
                        >
                            <img
                                {...srcset(item.img, 121, item.rows, item.cols)}
                                alt={item.title}
                                loading="lazy"
                                style={{ borderRadius:'12px', }}
                            />
                        </ImageListItem>
                    ))
                }
            </ImageList>
        </Box>
    );
}

export { EspacioAsesorFotos }

const itemData = [
    {
      img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
      title: 'Breakfast',
      rows: 2,
      cols: 2,
    },
    {
      img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
      title: 'Burger',
    },
    {
      img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
      title: 'Camera',
    },
    {
      img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
      title: 'Coffee',
      cols: 2,
    },
    {
      img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
      title: 'Hats',
      cols: 2,
    },
    {
      img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
      title: 'Honey',
      author: '@arwinneil',
      rows: 2,
      cols: 2,
    },
    {
      img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
      title: 'Basketball',
    },
    {
      img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
      title: 'Fern',
    },
    {
      img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
      title: 'Mushrooms',
      rows: 2,
      cols: 2,
    },
    {
      img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
      title: 'Tomato basil',
    },
    {
      img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
      title: 'Sea star',
    },
    {
      img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
      title: 'Bike',
      cols: 2,
    },
];