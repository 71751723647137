import { Box, Grid } from "@mui/material";
import { ItemTableCharacteristics } from "./ItemTableCharacteristics";

const TableCharacteristics = ({ children }) => {
    return(
        <Box
            sx={{
                // backgroundColor:'#5F9EA0',
                mb:0.5,
            }}
        >
            <Grid 
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                { children }
            </Grid>
        </Box>
    );
}

export { TableCharacteristics }