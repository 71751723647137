import { Box, Button, Grid, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { getFormatoMoneda } from "../../../functions/getFormatoMoneda";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useOfertasBancos } from "../../../hooks/useOfertasBancos";
import { useNavigate } from "react-router-dom";

const Segundo = ({ precio, plazo, handleClickCalcular, handleClickPreAutorizar }) => {
    let navigate = useNavigate();
    const reedirect = () => {
        navigate('/preautorizacion');
    }
    const theme = useTheme();
    const mobilWay = useMediaQuery(theme.breakpoints.down('sm'));

    const { ofertas, loading } = useOfertasBancos(precio, plazo);
    console.log(ofertas);
    const TableTypography = ({ children, bold = false }) => {
        if(bold){
            return(
                <Typography sx={{ fontSize:{ md:20 }, color:'#707070' }}>
                    <b>{ children }</b>
                </Typography>
            );
        }
        return(
            <Typography sx={{ fontSize:{ md:20 }, color:'#707070' }}>
                { children }
            </Typography>
        );
    }
    const TableLabel = ({ children }) => {
        return(
            <Typography sx={{ fontSize:{ xs:13, sm:14, md:14 }, color:'#707070' }}>
                <b>{ children }</b>
            </Typography>
        );
    }
    const TableCellItemsContainer = ({ children }) => {
        return(
            <Box>
                <Grid container 
                    sx={{
                        // backgroundColor:'greenyellow',
                    }}
                >{ children }</Grid>
            </Box>
        );
    }
    const TableCellItem = ({ label, value, colSpan = 1 }) => {
        return(
            <Grid item xs={6*colSpan} sm={6*colSpan} md={6*colSpan}>
                <Box
                    sx={{
                        // backgroundColor:'pink',
                        width:'100%',
                        minHeight:48,
                        display:'flex',
                        flexDirection:'column',
                        alignItems: 'flex-start',
                        justifyContent:'flex-start',
                        

                    }}
                >
                    <Box
                        sx={{
                            // backgroundColor:'purple',
                            
                        }}
                    >
                        <TableLabel>
                            { label }
                        </TableLabel>
                        <TableTypography>
                            { value }
                        </TableTypography>
                    </Box>
                </Box>
            </Grid>
        );
    }

    const Botones = () => {
        return(
            <Box
                sx={{
                    // backgroundColor:'pink',
                    width:'100%',
                    minHeight:{ xs:100, sm:60, md:60 },
                    display:'flex',
                    flexDirection:{ xs:'column', sm:'row', md:'row' },
                    alignItems:'center',
                    justifyContent:'space-evenly',
                }}
            >
                <Button
                    variant="outlined"
                    sx={{
                        width: { xs:'100%', sm:'100%', md:'20%' }
                    }}
                    onClick={handleClickCalcular}
                >
                    <b>Calcular de nuevo</b>
                </Button>
                <Button
                    variant="outlined"
                    sx={{
                        width: { xs:'100%', sm:'100%', md:'20%' }
                    }}
                    onClick={reedirect}
                >
                    <b>Preautorizar</b>
                </Button>
            </Box>
        );
    }
    if(loading){
        return(
            <Box
                sx={{
                    // backgroundColor:'yellow',
                    width:'100%',
                    height:200,
                    display:'flex',
                    alignItems:'center',
                }}
            >               
                <LinearProgress sx={{ width:'90%', m:'0 auto', }}/>                
            </Box>
        )
    }
    if(mobilWay){
        return(
            <Box>
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            {
                                ofertas.map((oferta) => (
                                    <TableRow>
                                        <TableCell>
                                            <Box
                                                sx={{
                                                    // backgroundColor:'yellow',
                                                    width:'100%',
                                                    display:'flex',
                                                    flexDirection:'column',
                                                    gap:1
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        // backgroundColor:'pink',
                                                        width:'100%',
                                                        height:40,
                                                        display:'flex',
                                                        alignItems:'center',
                                                        justifyContent:'center',
                                                    }}
                                                >
                                                    <Box
                                                        component="img"
                                                        src={oferta.img}
                                                        alt={oferta.nombre}
                                                        sx={{
                                                            maxWidth:'100%',
                                                            maxHeight:'100%',
                                                        }}
                                                    />
                                                </Box>
                                                <TableCellItemsContainer>
                                                    <TableCellItem label="Monto de Credito*" value={getFormatoMoneda(oferta.cantidadMaxima)}/>
                                                    <TableCellItem label="Mensualidad*" value={getFormatoMoneda(oferta.mensualidad)}/>
                                                    {
                                                        oferta.ingresosRequeqridos ? (
                                                            <>                                                                
                                                                <TableCellItem label="Enganche*" value={getFormatoMoneda(oferta.enganche)}/>
                                                                <TableCellItem label="Ingresos Requeridos*" value={getFormatoMoneda(oferta.ingresosRequeqridos)}/>
                                                            </>
                                                        ) : (
                                                            <TableCellItem label="Ingresos Requeridos*" value={`**Varia de acuerdo al monto del saldo de subcuenta de la vivienda.`} colSpan={2}/>
                                                        )
                                                    }
                                                </TableCellItemsContainer>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Typography variant="caption" display="block" gutterBottom sx={{ color: '#707070', fontFamily:'Montserrat' }}>
                    *Datos aproximados informativos, pueden variar de acuerdo a condiciones financieras del acreditado
                </Typography>
                <Botones/>
            </Box>
        );
    }
    return(
        <Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ color: '#707070', }}><b>Banco</b></TableCell>
                            <TableCell align="center" sx={{ color: '#707070', }}><b>Monto de Crédito*</b></TableCell>
                            <TableCell align="center" sx={{ color: '#707070', }}><b>Mensualidad*</b></TableCell>
                            <TableCell align="center" sx={{ color: '#707070', }}><b>Ingresos Requeridos*</b></TableCell>
                            <TableCell align="center" sx={{ color: '#707070', }}><b>Enganche*</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            ofertas.map((oferta) => (
                                <TableRow>
                                    <TableCell component="th" scope="row" align="center" sx={{ width:200, }}>
                                        <Box
                                            sx={{
                                                // backgroundColor:'pink',
                                                width:'100%',
                                                height:40,
                                                display:'flex',
                                                alignItems:'center',
                                                justifyContent:'center',
                                            }}
                                        >
                                            <Box
                                                component="img"
                                                src={oferta.img}
                                                alt={oferta.nombre}
                                                sx={{
                                                    maxWidth:'100%',
                                                    maxHeight:'100%',
                                                }}
                                            />
                                        </Box>
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="center">
                                        <TableTypography>
                                            { getFormatoMoneda(oferta.cantidadMaxima) }
                                        </TableTypography>
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="center">
                                        <TableTypography>
                                            { getFormatoMoneda(oferta.mensualidad) }
                                        </TableTypography>
                                    </TableCell>
                                    {
                                        oferta.ingresosRequeqridos ? (
                                            <>
                                                <TableCell component="th" scope="row" align="center">
                                                    <TableTypography>
                                                        { getFormatoMoneda(oferta.ingresosRequeqridos) }
                                                    </TableTypography>
                                                </TableCell>
                                                <TableCell component="th" scope="row" align="center">
                                                    <TableTypography>
                                                        { getFormatoMoneda(oferta.enganche) }
                                                    </TableTypography>
                                                </TableCell>
                                            </>
                                        ) : (
                                            <TableCell component="th" scope="row" align="center" colSpan={2}>
                                                <TableTypography>
                                                    {`**Varia de acuerdo al monto del saldo de subcuenta de la vivienda.`}
                                                </TableTypography>
                                            </TableCell>
                                        )
                                    }
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant="caption" display="block" gutterBottom sx={{ color: '#707070', fontFamily:'Montserrat' }}>
                *Datos aproximados informativos, pueden variar de acuerdo a condiciones financieras del acreditado
            </Typography>
            <Botones/>
        </Box>
    );
}

export { Segundo }