import { useLocation } from "react-router-dom";

const PruebaPDF = () => {
    let location = useLocation();
    console.log(location);
    if(location.pathname === '/pruebaPDF'){
        return(
            <object data="" width='100%' height={1000}></object>
        );
    }    
}

export { PruebaPDF }