import { Typography } from "@mui/material";
import { Box, Container } from "@mui/system";

const Ubicacion = () => {
    return(
        <Box
            sx={{
                // backgroundColor:'#5AB07C',
            }}
        >
            <Container>
                <Box
                    sx={{
                        width:'100%',
                        textAlign:'center',
                        color:'#138541',
                        mt:4,
                        mb:1,
                    }}
                >
                    <Typography variant="h3" sx={{ fontFamily:'Montserrat', }}>
                        <b>A sólo minutos de  las vías rápidas</b>
                    </Typography>
                </Box>
                {/* <Box
                    sx={{
                        width:'100%',
                        // textAlign:'center',
                        // mt:4,
                        mb:1,
                        color:'#615c51',
                    }}
                >
                    <Typography variant="body1">
                        <b>Ubicacion dfdfdf dgffg fg</b>
                    </Typography>
                </Box> */}
                <Box
                    sx={{
                        // backgroundColor:'#523f32',
                        width:'100%',
                        height:420,

                    }}
                >
                    <Box
                        component="iframe"
                        src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14417.3822441342!2d-101.0211505!3d25.393241!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x868873d4bee3743b%3A0x723b5d79c045351f!2sFracc.%20Lomas%20del%20Morillo%20Residencial!5e0!3m2!1ses-419!2smx!4v1678235634732!5m2!1ses-419!2smx'
                        sx={{
                            width:'99.82%',
                            height:400,
                            borderRadius:'12px',
                        }}
                    />
                </Box>
            </Container>
        </Box>
    );
}

export { Ubicacion }