import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { VideoMiniatura } from "./VideoMiniatura";

const ColeccionTodosVideosMiniatura = ({ playlist }) => {
    const [verMas, setVerMas] = useState(false);
    const getVerMas = () => {
        setVerMas(!verMas);
    }
    return(
        <Box>
            <Box>
                <Typography variant="h5" sx={{ color:'#F5323F', }}>
                    <b>Todos los Videos</b>
                </Typography>
            </Box>
            <Box
                sx={{
                    mt:1,
                    mb:2,
                }}
            >
                <Grid container>
                    {
                        verMas ? (
                            playlist.map((video, index) => (
                                <Grid item xs={12} sm={2} md={3}>
                                    <VideoMiniatura video={video}/>
                                </Grid>
                            )) 
                        ) : (
                            playlist.map((video, index) => {
                                if(index < 4){
                                    return(
                                        <Grid item xs={12} sm={3} md={3}>
                                            <VideoMiniatura video={video}/>
                                        </Grid>
                                    )
                                }
                            })
                        )
                    }
                    <Grid item xs={12} sm={12} md={12}>
                        <Box
                            sx={{
                                // backgroundColor:'red',
                                width:'100%',
                                textAlign:'center',
                                color:'#F5323F',
                            }}
                        >
                            <Button
                                color="inherit"
                                sx={{
                                    // backgroundColor:'red',
                                    width:'100%',
                                    // textAlign:'center',
                                    textTransform:'none',
                                }}
                                onClick={getVerMas}
                            >
                                <b>{ verMas ? 'Ver Menos' : 'Ver Mas' }</b>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export { ColeccionTodosVideosMiniatura }