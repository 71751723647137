import { Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { CustomTypography } from "../CustomTypography";
import theme from "../../App/theme";

const colors = {
  white: theme.palette.common.white,
  primaryLight2: theme.palette.primary.light2,
};

const baseBoxStyles = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const advantageTitleStyles = (isDesktop) => ({
  ...baseBoxStyles,
  py: { xs: "10px", md: 0 },
  bgcolor: isDesktop ? colors.white : colors.primaryLight2,
});

const descriptionMobileStyles = {
  px: "10px",
  pt: "10px",
  pb: "20px",
  bgcolor: colors.white,
};

const descriptionDesktopStyles = {
  ...baseBoxStyles,
  position: "absolute",
  height: "100%",
  top: 0,
  left: "-100%",
  bgcolor: colors.primaryLight2,
  color: colors.white,
  transition: "left 0.9s ease",
};

const advantageStyles = {
  position: "relative",
  height: { md: "100px" },
  display: { md: "flex" },
  overflow: "hidden",
  borderRadius: "10px",
  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.35)",
  transition: "background-color 0.3s ease",
  "&:hover": {
    ".descripcion": {
      left: 0,
    },
  },
};

const iconBoxStyles = {
  display: "flex",
  alignItems: "center",
  px: "10px",
  bgcolor: colors.primaryLight2,
  "&::before": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: 55,
    width: 0,
    height: 0,
    borderTop: "12px solid transparent",
    borderBottom: "12px solid transparent",
    borderLeft: `12px solid ${colors.primaryLight2}`,
    transform: "translateY(-50%)",
  },
};

const checkIconStyles = {
  color: colors.white,
};

const getTextColor = (isDesktop) =>
  isDesktop ? theme.palette.common.black : theme.palette.common.white2;

const AdvantageTitle = ({ children, isDesktop }) => (
  <Box sx={advantageTitleStyles(isDesktop)}>
    <CustomTypography
      variant="subheadingSecondary"
      txtColor={getTextColor(isDesktop)}
    >
      {children}
    </CustomTypography>
  </Box>
);

const Advantage = ({ index, advantage, isDesktop }) => (
  <Box key={index} sx={advantageStyles}>
    {isDesktop && (
      <Box sx={iconBoxStyles}>
        <CheckIcon sx={checkIconStyles} />
      </Box>
    )}
    <AdvantageTitle isDesktop={isDesktop}>{advantage.title}</AdvantageTitle>
    {!isDesktop ? (
      <Box sx={descriptionMobileStyles}>{advantage.description}</Box>
    ) : (
      <Box className="descripcion" sx={descriptionDesktopStyles}>
        {advantage.description}
      </Box>
    )}
  </Box>
);

export { Advantage };
