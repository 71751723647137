import { useEffect } from "react";
import { useState } from "react";

export const useOfertasBancos = (precio, plazo) => {
    // console.log(precio, plazo);
    const bancos = [
        {
            nombre: 'BBVA',
            img:'https://storage.casaspecsa.com/otros/logos+bancos+2023/bbva.png',
            porcentajeMaximo : 0.90,
            plazos: {
                5:{
                    total:1.3365,
                    mensualidades: 60,
                    ingresoRequerido: 2.5245,
                },
                10:{
                    total:1.7078,
                    mensualidades: 120,
                    ingresoRequerido: 2.54,
                },
                15:{
                    total:2.1201,
                    mensualidades: 180,
                    ingresoRequerido: 2.5450,
                },
                20:{
                    total:2.5585,
                    mensualidades: 240,
                    ingresoRequerido: 2.5450,
                }
            }
        },
        {
            nombre: 'BANORTE',
            img:'https://storage.casaspecsa.com/otros/logos+bancos+2023/banorte.png',
            porcentajeMaximo : 0.90,
            plazos: {
                5:{
                    total:1.3213,
                    mensualidades: 60,
                    ingresoRequerido: 2.0245,
                },
                10:{
                    total:1.6843,
                    mensualidades: 120,
                    ingresoRequerido: 2.0350,
                },
                15:{
                    total:2.0905,
                    mensualidades: 180,
                    ingresoRequerido: 2.0390,
                },
                20:{
                    total:2.5343,
                    mensualidades: 240,
                    ingresoRequerido: 2.0390,
                }
            }
        },
        {
            nombre: 'SCOTIABANK',
            img:'https://storage.casaspecsa.com/otros/logos+bancos+2023/scotiabank.png',
            porcentajeMaximo : 0.95,
            plazos: {
                10:{
                    total:1.6630,
                    mensualidades: 120,
                    ingresoRequerido: 1.6965,
                },
                15:{
                    total:2.0645,
                    mensualidades: 180,
                    ingresoRequerido: 1.6720,
                },
                20:{
                    total:2.5070,
                    mensualidades: 240,
                    ingresoRequerido: 1.6569,
                }
            }
        },
        {
            nombre: 'HSBC',
            img:'https://storage.casaspecsa.com/otros/logos+bancos+2023/hsbc.png',
            porcentajeMaximo : 0.90,
            plazos: {
                5:{
                    total:1.2945,
                    mensualidades: 60,
                    ingresoRequerido: 1.5550,
                },
                10:{
                    total:1.6417,
                    mensualidades: 120,
                    ingresoRequerido: 1.5300,
                },
                15:{
                    total:2.0300,
                    mensualidades: 180,
                    ingresoRequerido: 1.5500,
                },
                20:{
                    total:2.4550,
                    mensualidades: 240,
                    ingresoRequerido: 1.5400,
                }
            }
        },
        {
            nombre: 'INFONAVIT',
            img:'https://storage.casaspecsa.com/otros/logos+bancos+2023/infonavit.png',
            porcentajeMaximo : 1,
            plazos: {
                // 15:{
                //     total:0.9981,
                //     mensualidades: 180,
                //     ingresoRequerido: null,
                // },
                20:{
                    total:3.5950,
                    mensualidades: 360,
                    ingresoRequerido: null,
                }
            }
        },
    ];
    const [ofertas, setOfertas] = useState([]);
    const [loading, setLoading] = useState(true);
    
    const getOfertas = (precio, plazo) => {
        setLoading(true);
        
        setOfertas(bancos.filter((banco) => banco.plazos[plazo]).map((banco) => {
            let cantidadMaxima = precio * banco.porcentajeMaximo;
            let enganche = precio - cantidadMaxima;
            let totalCredito = null;
            let mensualidad = null;
            let ingresosRequeqridos = null;

            if(banco.plazos[plazo]){
                totalCredito = cantidadMaxima * banco.plazos[plazo].total;                
                mensualidad = totalCredito / banco.plazos[plazo].mensualidades;
                if(banco.plazos[plazo].ingresoRequerido){
                    ingresosRequeqridos = mensualidad * banco.plazos[plazo].ingresoRequerido;
                }
            }

            return {
                nombre: banco.nombre,
                img: banco.img,
                cantidadMaxima,
                enganche,
                totalCredito,
                mensualidad,
                ingresosRequeqridos
            }
        }));
        setLoading(false);
    };

    useEffect(()=>{
        getOfertas(precio, plazo);
    },[precio, plazo]);

    return { ofertas, loading }
}