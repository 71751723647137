import { Accordion, AccordionDetails, AccordionSummary, Box, Divider } from "@mui/material";
import { CustomTypography } from "../CustomTypography";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const MyAccordion = ({ title = '', body = '' }) => {
    return(
        <Box>
            <Accordion
                sx={{
                    backgroundColor:'transparent',
                    boxShadow:0,
                    // borderRadius:'1px',
                    // border: 1,
                    // borderBottom: 1
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ textAlign:'left', pt:0.5, pb:0.5 }}
                >
                    <CustomTypography variant="textPrimaryBold">{ title }</CustomTypography>
                </AccordionSummary>
                <AccordionDetails sx={{ textAlign:'left', pt:0 }}>
                    <CustomTypography variant="textPrimary">
                        { body }
                    </CustomTypography>
                </AccordionDetails>
            </Accordion>
            <Divider/>
        </Box>
    );
}

export { MyAccordion }