import React from "react";
import PropTypes from "prop-types";
import { BaseIcon } from "../BaseIcon";

/**
 * Icono de Facebook.
 *
 * @component
 * @example
 * // Ejemplo de uso:
 * <FacebookIcon color="#0077B5" onClick={handleClick} />
 *
 * @param {string} [color] - Color del ícono.
 * @param {function} [onClick] - Función manejadora del clic.
 * @returns {JSX.Element} Componente LinkedInIcon.
 *
 */
const FacebookIcon = ({ color, onClick }) => {
  return (
    <BaseIcon color={color} onClick={onClick}>
      <path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z" />
    </BaseIcon>
  );
};

FacebookIcon.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
};

export { FacebookIcon };
