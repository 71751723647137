import { Avatar, Button, Card, CardActionArea, CardMedia, Grid, IconButton, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import asesorPerfil2 from "../../assets/asesores/asesorPerfil2.png";
import imagenPromo from "../../assets/prueba/imagenPromo.png";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";

const PublicarPost = () => {
    const { folio } = useParams();
    
    const [storageImages, setStorageImages] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [imagesUrl, setImagesUrl] = useState([]);

    const [buttonDisabled, setButtonDisabled] = useState(false);
    
    const [addFotos, setAddFotos] = useState(false);
    const getAddFotos = () => {
        setAddFotos(!addFotos);
    }
    const [addDocument, setAddDocument] = useState(false);
    const getAddDocument = () => {
        setAddDocument(!addDocument);
    }
    const add = (event) => {        
        event.preventDefault();
        const data = new FormData(event.currentTarget);        
        if(data.get('texto') === '' && storageImages.length === 0){
            console.log('nada');
            return;
        }
        setButtonDisabled(true);
        
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');

        data.delete('prefotosImg');
        storageImages.map((foto) => {
            data.append('fotosImg', foto);
        })        

        axios.post(`${process.env.REACT_APP_API_URL}/api/asesores/profile/${folio}/posts`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
        .then((response)=>{
            console.log(response); 
            setButtonDisabled(false);
            window.location.reload();
        })
        .catch((error)=>{
            console.log(error);
        });

    }
    useEffect(()=>{
        console.log('selectedImages');
        if (selectedImages.length > 0) {
            // console.log('imagenes seleccionsadoas: ', selectedImages);
            for (let i = 0; i < selectedImages.length; i++) {
                if(!storageImages.find( element => element === selectedImages[i])){
                    // storageImages.push(selectedImages[i]);
                    setStorageImages((oldStorageImages)=>[...oldStorageImages, selectedImages[i]]);
                    // imagesUrl.push(URL.createObjectURL(selectedImages[i]))
                    setImagesUrl((oldImagesURL)=>[...oldImagesURL, URL.createObjectURL(selectedImages[i])]);
                }                
            }
        }
    },[selectedImages])
    return(
        <Box>
            <Box component="form" onSubmit={add} encType="multipart/form-data">
                <Grid container>
                    {/* <Grid item xs={1} sm={1} md={1} sx={{ display: { xs:'none', sm:'block', md:'block', } }}>
                        <Box
                            sx={{
                                // backgroundColor:'yellow',
                                width:'100%',
                                height:79,
                                display:'flex',
                                alignItems:'center',
                                alignContent:'center'
                            }}
                        >
                            <Avatar
                                alt="Remy Sharp"
                                src={asesorPerfil2}
                                variant="rounded"
                                sx={{ 
                                    width: 56, 
                                    height: 56,
                                    m:'0 auto',
                                }}
                            />
                        </Box>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={12}>
                        <Box
                            sx={{
                                // backgroundColor:'greenyellow',
                                width:'100%',
                                display:'flex',
                                alignItems:'center',
                                alignContent:'center'
                            }}
                        >
                            <TextField
                                id="outlined-multiline-static"
                                // label="Multiline"
                                multiline
                                rows={2}
                                // defaultValue="Default Value"
                                name="texto"
                                placeholder="Escribe tu publicacion..."
                                helperText="Maximo de 3000 caracteres."
                                fullWidth
                                
                            />
                        </Box>
                        {
                            addFotos && (
                                <Box
                                    sx={{
                                        mt:1,
                                        mb:1,
                                    }}
                                >
                                    <Grid container spacing={1}>
                                        <Grid item xs={4} sm={2} md={2}>
                                            <Card 
                                                sx={{ 
                                                    height: 120                                            
                                                }}
                                            >
                                                <label htmlFor="contained-button-file">                            
                                                    <input accept="image/*" id="contained-button-file" type="file" name="prefotosImg" multiple style={{ display: 'none' }} onChange={e => setSelectedImages(e.target.files)}/>
                                                    <CardActionArea
                                                        sx={{
                                                            width:'100%',
                                                            height:'100%',
                                                        }}
                                                        component="span"
                                                    >
                                                        <Box
                                                            sx={{
                                                                backgroundColor:'#f2f2f2',
                                                                width:'100%',
                                                                height:'100%',
                                                                display:'flex',
                                                                alignItems:'center',
                                                                alignContent:'center',
                                                            }}
                                                            // component="span"
                                                        >
                                                            <Box
                                                                sx={{
                                                                    m:'0 auto',
                                                                    textAlign:'center',
                                                                    color:'#707070',
                                                                }}
                                                            >
                                                                <AddPhotoAlternateIcon/>
                                                                <Typography variant="body2">
                                                                    <b>Agregar</b>
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </CardActionArea>                                                        
                                                </label>                                                
                                                {/* <Box
                                                    sx={{
                                                        backgroundColor:'#f2f2f2',
                                                        width:'100%',
                                                        height:'100%',
                                                        display:'flex',
                                                        alignItems:'center',
                                                        alignContent:'center',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            m:'0 auto',
                                                            textAlign:'center',
                                                            color:'#707070',
                                                        }}
                                                    >
                                                        <AddPhotoAlternateIcon/>
                                                        <Typography variant="body2">
                                                            <b>Agregar</b>
                                                        </Typography>
                                                    </Box>
                                                </Box> */}
                                            </Card>
                                        </Grid>
                                        {
                                            imagesUrl.map((imagen)=>(
                                                <Grid item xs={4} sm={2} md={2}>
                                                    <Box
                                                        sx={{
                                                            // backgroundColor:'red',
                                                            width:'100%',
                                                        }}
                                                    >
                                                        <Card>
                                                            <CardMedia
                                                                sx={{ height: 120 }}
                                                                image={imagen}
                                                                title="green iguana"
                                                            />
                                                        </Card>
                                                    </Box>
                                                </Grid>
                                            ))
                                        }                                        
                                    </Grid>
                                </Box>
                            )
                        }
                        <Box
                            sx={{
                                // backgroundColor:'greenyellow',
                                width:'100%',
                            }}
                        >                            
                            <Grid 
                                container
                                direction="row"
                            >
                                <Grid item xs={12} sm={8} md={8}>
                                    <Box
                                        sx={{
                                            // backgroundColor:'red',
                                            display:'flex',
                                        }}
                                    >
                                        <IconButton aria-label="delete" onClick={getAddFotos}>
                                            <AddPhotoAlternateIcon />
                                        </IconButton>
                                        {/* <IconButton aria-label="delete">
                                            <InsertDriveFileIcon />
                                        </IconButton> */}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={3} md={4}>
                                    <Box
                                        sx={{
                                            // backgroundColor:'red',
                                            width:'100%',
                                            textAlign:'right',
                                            pt:0.5,
                                        }}
                                    >
                                        <Button 
                                            variant="contained"
                                            sx={{
                                                width:{ xs:'100%', sm:'40%', md:'40%'},
                                                textTransform:'none',

                                            }}
                                            type="submit"
                                            disabled={buttonDisabled}
                                        >
                                            Publicar
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export { PublicarPost }