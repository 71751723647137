import { Box, Container } from "@mui/material";
import { BoxComparator } from "../../../components/Investment/Comparator/BoxComparator";
import { TitleComparator } from "../../../components/Investment/Comparator/TitleComparator";
import { ListComparator } from "../../../components/Investment/Comparator/ListComparator";
import { ListItemComparator } from "../../../components/Investment/Comparator/ListComparator/ListItemComparator";
import { Summary } from "../../../components/Investment/Comparator/Lists/Summary";
import { Investment } from "../../../components/Investment/Comparator/Lists/Investment";
import { Income } from "../../../components/Investment/Comparator/Lists/Income";
import { Characteristics } from "../../../components/Investment/Comparator/Lists/Characteristics";
import { useQueryParamsToJson } from "../../../hooks/useQueryParamsToJson";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLotesToCompare } from "../../../hooks/investment/comparator/useLotesToCompare";
import { ItemIncome } from "../../../components/Investment/Comparator/Lists/Income/ItemIncome";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { CustomTypography } from "../../../components/CustomTypography";
import { getFormatoMoneda } from "../../../functions/getFormatoMoneda";

const useGetLotesAMostrar = (lotes) => {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));
  const [newArray, setNewArray] = useState([]);

  const getLotes = () => {
    setNewArray(
      lotes.map((lote, index) => {
        if (small) {
          if (index < 2) {
            return lote;
          }
        } else {
          return lote;
        }
      })
    );
  };
  useEffect(() => {
    getLotes();
  }, [small]);

  return newArray;
};

const SummarySection = ({ inversionInicial }) => {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));

  const itemsToCompare = useLotesToCompare(inversionInicial);
  // const newArray = useGetLotesAMostrar(itemsToCompare);

  console.log("chido", itemsToCompare);

  return (
    <Box
      sx={{
        backgroundColor: "#F0F0F0",
      }}
    >
      <Container>
        <BoxComparator>
          <TitleComparator>Resumen</TitleComparator>
          <ListComparator>
            {itemsToCompare.map((item, index) => {
              if (small) {
                if (index >= 2) {
                  return;
                }
              }
              return (
                <ListItemComparator>
                  <Summary
                    fotos={item.lote.fotos}
                    nombre={item.lote.manzana.etapa.fraccionamiento.nombre}
                    precio={item.lote.precio}
                    modelo={item.lote.fachada.modelo.nombre}
                    sku={item.lote.sku}
                  />
                </ListItemComparator>
              );
            })}
          </ListComparator>
        </BoxComparator>
        <BoxComparator>
          <TitleComparator>Inversión Total</TitleComparator>
          <ListComparator>
            {itemsToCompare.map((item, index) => {
              if (small) {
                if (index >= 2) {
                  return;
                }
              }
              return (
                <ListItemComparator>
                  <Investment
                    apartado={item.apartado}
                    enganche={item.enganche}
                    inversionMinima={item.inversionMinima}
                    inversionInicial={item.inversionInicial}
                    restanteDeInversionInicial={item.restanteDeInversionInicial}
                    gastos={item.gastos}
                    restanteAPagar={item.restanteAPagar}
                  />
                </ListItemComparator>
              );
            })}
          </ListComparator>
        </BoxComparator>
        <BoxComparator>
          <TitleComparator>Ingreso por renta</TitleComparator>
          <ListComparator>
            {itemsToCompare.map((item, index) => {
              if (small) {
                if (index >= 2) {
                  return;
                }
              }
              return (
                <ListItemComparator>
                  <Income>
                    <ItemIncome
                      title={"¿Cuanto ganaria por renta?"}
                      price={item.rentaMensualAproximada}
                    />
                    <ItemIncome
                      title={`Rentabilidad anual ${item.rentabilidadAnual.toFixed(
                        2
                      )}%`}
                      body={
                        "Esta es una simulación aproximada con tasas de ocupacion del 95% anual"
                      }
                    />
                    <ItemIncome
                      title={"Total renta mensual:"}
                      price={item.rentaMensualAproximada}
                    />
                    <ItemIncome
                      title={"Cuota mes estimada crédito:"}
                      price={item.mensualidadesCreditoHipotecarioAproximadas}
                    />
                    <Box
                      sx={{
                        backgroundColor: theme.palette.success.light,
                        border: 2,
                        borderColor: theme.palette.success.main,
                        width: "100%",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "10px 20px",
                      }}
                    >
                      <CustomTypography
                        variant="textTertiaryBold"
                        txtColor={theme.palette.text.primary}
                      >
                        {item.restanteAlMes <= 0 &&
                          `La renta cubre una parte del crédito hipotecario y en total pagarias ${getFormatoMoneda(
                            item.restanteAlMes * -1
                          )} MXN al mes`}
                        {item.restanteAlMes > 0 &&
                          (item.credito <= 0
                            ? `Tu inversion te daria aproximadamente ${getFormatoMoneda(
                                item.restanteAlMes
                              )} MXN al mes`
                            : `La renta pagaría el crédito hipotecario y te sobran aproximadamente ${getFormatoMoneda(
                                item.restanteAlMes
                              )} MXN al mes`)}
                      </CustomTypography>
                    </Box>
                  </Income>
                </ListItemComparator>
              );
            })}
          </ListComparator>
        </BoxComparator>
        <BoxComparator>
          <TitleComparator>Características</TitleComparator>
          <ListComparator>
            {itemsToCompare.map((item, index) => {
              if (small) {
                if (index >= 2) {
                  return;
                }
              }
              return (
                <ListItemComparator>
                  <Characteristics lote={item.lote} />
                </ListItemComparator>
              );
            })}
          </ListComparator>
        </BoxComparator>
      </Container>
    </Box>
  );
};

export { SummarySection };
