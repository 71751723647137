import React from "react";
import PropTypes from "prop-types";
import { Button, useMediaQuery, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";

/**
 * Componente para crear un botón primario personalizado.
 *
 * @param {Object} props - Propiedades del botón.
 * @param {string} props.text - Texto del botón (obligatorio).
 * @param {function} props.onClick - Función a ejecutar cuando se hace clic en el botón (obligatorio).
 * @param {string} props.color - Color del botón (opcional).
 * @param {string} props.variant - Variante del botón (opcional).
 * @param {string} props.txtColor - Color del texto (opcional).
 * @param {string} props.size - Tamaño del botón (opcional).
 *
 * @returns {JSX.Element} - Componente de botón personalizado.
 */

function CustomButton({
  text,
  onClick,
  color,
  variant,
  txtColor,
  size,
  endIcon,
  startIcon,
  disabled = false,
  type,
}) {
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);

  // Detecta si la pantalla es de tamaño grande (lg) > 1200px.
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  // Define alturas del botón por tamaño (puedes personalizar estos valores).
  const heightBySize = {
    small: "43px",
    medium: "53px",
  };

  // Obtén la altura del botón basada en el tamaño proporcionado o utiliza una altura predeterminada (large).
  const height = heightBySize[size] || "63px";

  const buttonStyles = {
    fontSize: "1.125rem",
    textTransform: "none",
    borderRadius: "10px",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    width: "100%",
    maxWidth: "420px",
    color: txtColor,
    height,
  };

  if (variant === "outlined") {
    buttonStyles.border = `3px solid ${theme.palette[color].main}`;
    buttonStyles.backgroundColor = "transparent"; // theme.palette[color].light;
    buttonStyles["&:hover"] = {
      backgroundColor: theme.palette[color].dark,
      color: theme.palette.common.white,
    };
  } else if (variant === "text") {
    buttonStyles.width = "auto";
    buttonStyles.height = "auto";
    buttonStyles.boxShadow = "none";
    buttonStyles["&:hover"] = {
      backgroundColor: "transparent",
      textDecoration: "underline",
    };
  }

  const startIconStyles = {
    display: "flex",
    alignItems: "center",
    marginRight: "10px",
    color: "inherit",
    fontSize: "20px",
  };

  const endIconStyles = {
    display: "flex",
    alignItems: "center",
    marginLeft: "10px",
    color: "inherit",
    transition: "opacity .3s ease-in-out, transform .3s ease-in-out",
    transform: isHovered ? "translateX(5px)" : "translateX(0)",
  };

  return (
    <Button
      variant={variant} // Puedes especificar el tipo de botón (contained, outlined, text).
      color={color} // Puedes especificar el color (primary, secondary, etc.).
      onClick={onClick} // Función a ejecutar cuando se hace clic en el botón.
      disableRipple={isLargeScreen} // Desactiva el efecto Ripple en pantallas grandes.
      sx={buttonStyles}
      disabled={disabled}
      type={type}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box sx={startIconStyles}>
        {startIcon &&
          React.cloneElement(startIcon, {
            sx: { fontSize: "1.5rem" },
          })}
      </Box>
      {text}
      <Box sx={endIconStyles}>
        {endIcon &&
          React.cloneElement(endIcon, {
            sx: { fontSize: "1.5rem" },
          })}
      </Box>
    </Button>
  );
}

CustomButton.propTypes = {
  text: PropTypes.string.isRequired, // Texto del botón.
  onClick: PropTypes.func.isRequired, // Función a ejecutar cuando se hace clic en el botón.
  color: PropTypes.string, // Color del botón (primary, secondary, etc.).
  variant: PropTypes.oneOf(["contained", "outlined", "text"]), // Variante del botón.
  txtColor: PropTypes.string, // Color del texto (solo para la variante "text").
};

export { CustomButton };
