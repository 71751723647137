

import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { DeleteIdioma } from './DeleteIdioma';
import { EditIdioma } from './EditIdioma';

const ItemIdiomas = ({ idioma }) => {
    // const [fechaInicio, setFechaInicio] = useState(null);
    // const [fechaFinal, setFechaFinal] = useState(null);
    const [editar, setEditar] = useState(false);
    const getEditar = () => {
        setEditar(!editar);
    }
    const [lengua, setLengua] = useState(idioma.idioma);
    const [nivel, setNivel] = useState(idioma.nivel);

    const updateDatos = (lengua, nivel) => {
        setLengua(lengua);
        setNivel(nivel);
    }

    return(
        <Box
            sx={{
                // backgroundColor:'yellow',
                width:'100%',
            }}
        >
            {
                !editar ? (
                    <Grid container>
                        <Grid item xs={12} sm={5} md={5}>
                            <Box>
                                <Typography>
                                    { lengua }
                                </Typography>
                                {/* <Typography>
                                    { nivel }
                                </Typography> */}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Box
                                sx={{
                                    mt:1,
                                    mb:1,
                                    // backgroundColor:'red',
                                    width:'100%',
                                    textAlign:'left',
                                }}
                            >
                                <Button 
                                    variant="contained" 
                                    endIcon={<EditIcon />}
                                    sx={{
                                        backgroundColor:'#707070',
                                        textTransform:'none',
                                        borderRadius:'10px',
                                        width:{ xs:'100%', sm:'100%', md:'35%' },
                                        mr:2,
                                    }}
                                    onClick={getEditar}
                                >
                                    <b>Editar</b>
                                </Button>
                                <DeleteIdioma idIdioma={idioma.id}/>
                            </Box>                                                                                                    
                        </Grid>
                    </Grid>
                ) : (
                    <EditIdioma getEditar={getEditar} idioma={idioma} updateDatos={updateDatos} />
                )
            }            
            
        </Box>
    );
}

export { ItemIdiomas }