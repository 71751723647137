import React, {useState,useEffect} from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation, useParams, Link, NavLink, Navigate } from "react-router-dom";
import { Box, Skeleton } from '@mui/material';
import { DatosEtapa } from '../../components/DatosEtapa';
import { MenuManzanas } from '../../components/MenuManzanas';

const InventarioEtapa = () => {
    let navigate = useNavigate();
    const location = useLocation();
    let parametros = useParams();
    // const [etapa, setEtapa] = useState(location.state.etapa);
    const [etapa, setEtapa] = useState(null);
    //console.log(location.state.etapa);
    console.log(parametros);

    useEffect(() => {
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        

        axios.get(`${process.env.REACT_APP_API_URL}/api/etapa/${parametros.etapa}`, {
              headers: {
                  Authorization: `Bearer ${token}`
              }
        })
        .then((response) => {
            // handle success
            console.log(response);
            setEtapa(response.data)            
        })
        .catch((error) => {
            // handle success
            console.log(error);
            navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sae');
        });
    }, [setEtapa]);

    return(
        <div>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'green' }}>
                {
                    !etapa ?
                    <Skeleton variant="rectangular" width={510} height={118} /> :
                    <DatosEtapa datos={etapa}/>
                }                              
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'yellow' }}>
                {
                    !etapa ?
                    <Skeleton variant="rectangular" width={510} height={118} /> :
                    <MenuManzanas etapa={etapa}/>
                }                 
            </Box>
        </div>
    );
}

export { InventarioEtapa }