import React from "react";
import { useRef, useState } from "react";
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import anuncio from '../../assets/slideanuncios/anuncio.png';
import anuncio2 from '../../assets/slideanuncios/anuncio2.png';
import anuncio3 from '../../assets/slideanuncios/anuncio3.png';
import anuncioEstrenaton from '../../assets/slideanuncios/estrenaton2022.png';
import anuncioEstrenatonMovil from '../../assets/slideanuncios/estrenaton2022Movil.jpg';

import casaCompracasa from '../../assets/casa_compracasa.svg';
import ubicacionCompracasa from '../../assets/foto2.svg';
import pagoCompracasa from '../../assets/pago.svg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { Grid } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { SlideCompraEnUnSoloDiaItem } from "./SlideComprarEnUnSoloDiaItem";

const items = [
    {
      textoMD: <Typography variant="h3" gutterBottom component="div" sx={{ color:'#707070' }}>
              <b>Elige el modelo</b> que mas te guste
            </Typography>,
      textoXS: <Typography variant="body1" gutterBottom component="div">
      <b>Elige el modelo</b> que mas te guste
    </Typography>,
      imagen: casaCompracasa,
    },
    {
      textoMD: <Typography variant="h3" gutterBottom component="div" sx={{ color:'#707070' }}>
              <b>Escoge la mejor</b> ubicacion para ti
            </Typography>,
      textoXS: <Typography variant="body1" gutterBottom component="div">
      <b>Escoge la mejor</b> ubicacion para ti
    </Typography>,
      imagen: ubicacionCompracasa,
    },
    {
      textoMD: <Typography variant="h3" gutterBottom component="div" sx={{ color:'#707070' }}>
              <b>Realiza tu pago</b> y listo, habrás separado tu nueva casa.
            </Typography>,
      textoXS: <Typography variant="body1" gutterBottom component="div">
      <b>Realiza tu pago</b> y listo, habrás separado tu nueva casa.
    </Typography>,
      imagen: pagoCompracasa,
    },
];

const SlideCompraEnUnSoloDia = () => {
    return (
     
        
        <Container sx={{ minHeight:500, }}>
          <Carousel sx={{ width:'100%', height:'100%' }}>
            {
              items.map((item)=>(
                <SlideCompraEnUnSoloDiaItem datos={item} />
              ))
            }
          </Carousel>
        </Container>        
        

    );
}

export { SlideCompraEnUnSoloDia };