import axios from "axios";
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation, Navigate, Link } from "react-router-dom";

import { Alert, Avatar, Button, Checkbox, Container, FormControlLabel, Grid, Snackbar, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system";

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useState } from "react";

import logo from '../../assets/logo.svg';

const stylePecsaInputs = {
    "& label.Mui-focused": {
        color: "#f5323f"
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#d9d9d9"
    },
    "& .MuiOutlinedInput-root": {
        // '& fieldset': {
        //   borderColor: 'black',
        // },
        "&:hover fieldset": {
            borderColor: "#d9d9d9"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#d9d9d9"
        }
    }
}

const Signin = () => {
    let navigate = useNavigate();

    const [open, setOpen] = useState(false);

    const MostarAlert = () => {
        setOpen(true);
    };
    
    const CerrarAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
    };

    const Add = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
          email: data.get('email'),
          password: data.get('password'),
        });

        axios.post(`${process.env.REACT_APP_API_URL}/api/clientes`,{
            email: data.get('email'),
            password: data.get('password'),
        })
        .then((response) => {
            // handle success
            if(!response.data){
                MostarAlert();
                return;
            }
            console.log('no manches', response);
            axios.post(`${process.env.REACT_APP_API_URL}/api/clientes/login`,{
                email: data.get('email'),
                password: data.get('password'),
            })
            .then((response) => {
                // handle success            
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('id', response.data.client.folio);
                localStorage.setItem('type', 'cliente');
                navigate('/profile/datos');
                //navigate(`/cargas/inventario/${fraccionamiento.folio.toString()}`, {state:{fraccionamiento:fraccionamiento}});
                //navigate(-1, { replace: true });
            })
            .catch((error) => {
                // handle success
                console.log(error);
                // navigate('/usuarios/login');
            })
            .then(() => {
                // handle success
                console.log('sabe');
            });
            
            //navigate(`/cargas/inventario/${fraccionamiento.folio.toString()}`, {state:{fraccionamiento:fraccionamiento}});
            // navigate('profile/datos');
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sabe');
        });
    };
    return(
        <Container sx={{ mt: { xs: 9, md:17 }, minHeight:790, }}>
            <Grid 
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item md={6}>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            component='img'
                            src={logo}
                        />
                        {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar> */}
                        <Typography component="h1" variant="h5" sx={{ color: '#707070' }}>
                            Registro
                        </Typography>
                        <Box component="form" onSubmit={Add} noValidate sx={{ mt: 1 }}>
                            {/* <Alert severity="error">Correo electronico ya registrado</Alert> */}
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Correo Electronico"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                sx={{ ...stylePecsaInputs }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Contraseña"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                sx={{ ...stylePecsaInputs }}
                            />
                            {/* <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                            /> */}
                            <Button
                                type="submit"
                                fullWidth
                                variant="outlined" 
                                color="inherit" 
                                sx={{ /*fontSize:'11px',*/ 
                                    mt: 3,
                                    mb: 2,
                                    //width: { xs:'95%', sm:'90%', md:'100%' }, 
                                    p:{ xs:1, sm:1, md:1 },
                                    borderColor:'#F5323F',
                                    color:'#F5323F',
                                    borderWidth:3,
                                }}
                            >
                                Guardar
                            </Button>
                            <Grid container>
                                <Grid item>
                                    <Link to={'/login'}>
                                    {"¿Ya tienes una cuenta con nosotros? Inicia sesion"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                        
                    </Box>
                </Grid>
            </Grid>
            <Snackbar open={open} autoHideDuration={6000} onClose={CerrarAlert} anchorOrigin={{ vertical: 'top', horizontal:'center' }}>
                <Alert onClose={CerrarAlert} severity="warning" sx={{ width: '100%' }}>
                    Usuario ya registrado
                </Alert>
            </Snackbar>
        </Container>
    );
}

export { Signin }