import { Box, Button, Card, CardActions, CardContent, CardMedia, CircularProgress, Fab, Fade, Paper, Popper, Skeleton, Typography } from "@mui/material";
import { Container } from "@mui/system";
import axios from "axios";
import { createRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MapaSVG } from "../MapaSVG";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

import FmdBadIcon from '@mui/icons-material/FmdBad';

import PrismaZoom from 'react-prismazoom'

//import foto_de_mientras from "../../assets/master_uno_altavista_de_mientras.svg";

const MapaFraccionamiento = ({ fraccionamiento }) => {
    let navigate = useNavigate();
    const prismazoom = createRef();
    const [cantidadZoom, setCantidadZoom] = useState(1);
    const [mapLoading, setMapLoading] = useState(0);
    // console.log('menufraccionamiento', fraccionamiento);
    const [svgAPI, setSvgAPI] = useState(null);
    const [lotes, setLotes] = useState([]);
    const [lote, setLote] = useState(null);
    const [prevLote, setPrevLote] = useState(null);

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState();

    const [errorMapa, setErrorMapa] = useState(false);
    // const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
    // const [childNode, setChildNode] = useState<HTMLElement | null>(null);

    const handleClick = (newPlacement, lote) => (event) => {
        console.log('lote',lote)
        console.log('prev',prevLote)
        if(lote){
            // if(open){
            //     handleClickClose(event)
            //     if
            // }
            setPrevLote(lote);
            setAnchorEl(event.currentTarget);
            // setOpen((prev) => placement !== newPlacement || !prev);
            setOpen((prev) => lote !== prevLote || !prev);
            // setOpen(true);
            setPlacement(newPlacement);
            setLote(lote);
        }
    };
    const handleClickClose = (event) => {
            console.log('closeeee');
            setAnchorEl(event.currentTarget);
            setOpen(false);
            setPlacement('top');

    };
    const getLotes = () => {
        const prelotes = [];

        for (let element of fraccionamiento.etapas){
            for (let element2 of element.manzanas){
                for (let element3 of element2.lotes){
                    // console.log({manzana: element2.nombre, LoteDisponible: `prefix___${element2.nombre}_${element3.numeroDeLote}`, ...element3});
                    // setLotes((current)=>[...current, {manzana: element2.nombre, ...element3}]);
                    prelotes.push({manzana: element2.nombre, LoteDisponible: `prefix___${element2.nombre}_${element3.numeroDeLote}`, ...element3});
                }
            }
        }
        return prelotes;
    }
    const getFormatoMoneda = (cantidad) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD', //MXN
            minimumFractionDigits: 0
          })

          var value = 10000

          return formatter.format(cantidad)
    }
    const onClickOnZoomOut = () => {
        prismazoom.current.zoomOut(1)
    }
    const onClickOnZoomIn = () => {
        prismazoom.current.zoomIn(1)
    }
    const onZoomChange = (zoom) => {
        //console.log(zoom);
        setCantidadZoom(zoom);
    }
    // const getLote = (lote) => {
    //     setLote(lote)
    // }
    useEffect(()=>{
        setLotes(getLotes());
        console.log('nuevos mapa',lotes);
    }, [fraccionamiento])
    useEffect(()=>{
        setSvgAPI(null);
        setErrorMapa(false);
        if(fraccionamiento.mapa){
            axios.post(`${process.env.REACT_APP_API_URL}/api/getmapa/fraccionamiento`,
                {
                    idMapaFraccionamiento: fraccionamiento.mapa.id,
                },
                {
                headers: {
                }
            })
            .then((response) => {
                // handle success
                setSvgAPI(response.data)
                // // setSvgAPI(JSON.parse(response.data))
                console.log('MAPA svg',response);
                console.log('success axio');

            })
            .catch((error) => {
                // handle success
                console.log(error);
                setErrorMapa(true);
            })
            .then(() => {
                // handle success
                console.log('sabe');
            });
        }
    }, [fraccionamiento]);
    useEffect(()=>{
        if(!svgAPI){
            setMapLoading(0);
            const timer = setInterval(() => {
                setMapLoading((prevProgress) => prevProgress + 5);
              }, 2000);
              return () => {
                clearInterval(timer);
              };
        }else{
            setMapLoading(0);
        }
    }, [svgAPI])
    return(
        <Container>
            <Box sx={{ textAlign:'left', marginTop:2, color:'#F5323F',  }}>
              <h1>¡Separa tu casa con un solo click!</h1>
            </Box>
            <Box sx={{ textAlign:'left', marginTop:2, color:'#707070',  }}>
              <Typography variant="body2" gutterBottom onClick={handleClickClose}>
                    Elige el modelo y la ubicacion que mas te guste dentro del fraccionamiento
              </Typography>
            </Box>
            <Popper
                open={open}
                anchorEl={anchorEl}
                placement={placement}
                transition
                // modifiers={{
                //     arrow: {
                //         enabled: true,
                //         element: arrowRef
                //     }
                // }}
                // modifiers={[
                //     {
                //         name: 'arrow',
                //         enabled: true,
                //         options: {
                //           element: arrowRef,
                //         },
                //     },
                // ]}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        {/* {arrow ? <span className={classes.arrow} ref={setArrowRef} /> : null} */}
                        <Card>
                            <CardMedia
                                component="img"
                                height="140"
                                image={ lote.fotos.length > 0 ? lote.fotos[0].url : '' }
                                alt="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="div">
                                    {
                                        `Lote ${lote.numeroDeLote} - Mzn ${lote.manzana}`
                                    }
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {
                                        `Modelo ${lote.fachada.modelo.nombre}`
                                    }
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {
                                        `Fachada ${lote.fachada.nombre}`
                                    }
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {
                                        `Terreno: ${lote.superficieDeTerreno}m²`
                                    }
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {
                                        getFormatoMoneda(lote.precio)
                                    }
                                </Typography>
                            </CardContent>
                            <CardActions>
                                {/* <Button size="small">Share</Button> */}
                                <Button size="small" onClick={()=>{ navigate(`/tienda/${lote.sku.replace(/ /g, "_")}`) }} sx={{ color:'#F5323F' }}>Separar</Button>
                                <Button size="small" onClick={handleClickClose} sx={{ color:'#F5323F' }}>cerrar</Button>

                            </CardActions>
                        </Card>
                        {/* <Paper>
                        <Typography sx={{ p: 2 }}>The content of the Popper.</Typography>
                        </Paper> */}
                    </Fade>
                )}
            </Popper>
            <Box
                sx={{

                    width:'100%',
                    pb:2,

                }}
            >
                {
                    fraccionamiento.mapa ? (
                        svgAPI ? (
                            <Box
                                sx={{
                                    //backgroundColor:'red',
                                    overflow:'hidden',
                                    position: 'relative',
                                    borderRadius:'10px',
                                }}
                            >
                                {/* <PrismaZoom onZoomChange={onZoomChange} ref={prismazoom}> */}
                                    <MapaSVG lotes={lotes} svgAPI={svgAPI} handleClick={handleClick} handleClickClose={handleClickClose} />
                                {/* </PrismaZoom> */}
                                {/* <Box
                                    sx={{
                                        backgroundColor:{ xs:'none', sm:'#F4F4F4', md:'#F4F4F4' },
                                        //margin: '0 auto',
                                        top: 'auto',
                                        right: 'auto',
                                        bottom: { xs:8, sm:8, md:10, },
                                        left: { xs:'27%', sm:'40%', md:'42%', },
                                        position: 'absolute',
                                        display:{ xs:'flex', sm:'flex', md:'flex' },
                                        boxShadow:4,
                                        borderRadius:'10px',
                                    }}
                                >
                                    <Button
                                        // variant="contained"
                                        onClick={onClickOnZoomOut}
                                        color='inherit'
                                        sx={{
                                            backgroundColor:{ xs:'none', sm:'#F4F4F4', md:'#F4F4F4' },
                                            color:'#F5323F',
                                            borderRadius:'10px',
                                        }}
                                    >
                                        <ZoomOutIcon/>
                                    </Button>
                                    <Box
                                        sx={{
                                            textAlign:'center',
                                            ml:0.5,
                                            mr:0.5,
                                            mt:0.7,
                                        }}
                                    >
                                        <Typography variant="body1" sx={{ m:'0 auto', color:{ xs:'#F5323F', sm:'#707070', md:'#707070' }}}>
                                            <b>{`${parseInt(cantidadZoom * 100)}%`}</b>
                                        </Typography>
                                    </Box>
                                    <Button
                                        // variant="contained"
                                        onClick={onClickOnZoomIn}
                                        color='inherit'
                                        sx={{
                                            backgroundColor:{ xs:'none', sm:'#F4F4F4', md:'#F4F4F4' },
                                            color:'#F5323F',
                                            borderRadius:'10px',
                                        }}
                                    >
                                        <ZoomInIcon/>
                                    </Button>
                                </Box> */}
                            </Box>
                        ) : (
                            // <Skeleton variant="rectangular" width={'100%'} height={200} />
                            errorMapa ? (
                                <Box
                                    sx={{
                                        backgroundColor:'#f2f2f2',
                                        color:'#F5323F',
                                        textAlign:'center',
                                        height:400,
                                        borderRadius:'10px',
                                        display:'flex',
                                        alignItems:'center'
                                    }}
                                >                                    
                                   <Box sx={{ m:'0 auto', }}>
                                        <FmdBadIcon sx={{ fontSize:100 }} />
                                        <h1>Mapa No Disponible</h1>
                                   </Box>
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        color:'#F5323F',
                                        textAlign:'center',
                                        height:400,
                                    }}
                                >
                                    <h1>Cargando mapa...</h1>
                                    <CircularProgress variant="determinate" value={mapLoading} color='error'/>
                                </Box>
                            )
                            
                        )
                    ) : (
                        // <Box
                        //     component='img'
                        //     src='https://casaspecsa-bucket.s3.us-east-2.amazonaws.com/pruebaMapa/master_uno_altavista_de_mientras.svg'
                        //     sx={{
                        //         width:'100%',
                        //     }}
                        // />
                        <Box
                            sx={{
                                backgroundColor:'#f2f2f2',
                                color:'#F5323F',
                                textAlign:'center',
                                height:400,
                                borderRadius:'10px',
                                display:'flex',
                                alignItems:'center'
                            }}
                        >                                    
                            <Box sx={{ m:'0 auto', }}>
                                <FmdBadIcon sx={{ fontSize:100 }} />
                                <h1>Mapa No Disponible</h1>
                            </Box>
                        </Box>
                    )
                }
                {/* {
                    svgAPI ? (
                        <MapaSVG lotes={lotes} svgAPI={svgAPI} handleClick={handleClick} handleClickClose={handleClickClose} />
                    ) : (
                        <Skeleton variant="rectangular" width={'100%'} height={200} />
                    )
                } */}
            </Box>

        </Container>
    );
}

export { MapaFraccionamiento }