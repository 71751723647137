import { Box, Container } from "@mui/material";
import { CustomTypography } from "../../../components/CustomTypography";
import theme from "../../../App/theme";

const HeroSection = () => {
  return (
    <Box
      component="section"
      sx={{
        position: "relative",
        pt: "8vh",
        pb: "10vh",
        backgroundImage: `url('https://storage.casaspecsa.com/invierte-renta/banners/principal-inversion-desktop.png')`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        overflow: "hidden",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(0, 0, 0, 0.2)",
        },
        // "@media (max-width: 600px)": {
        //   backgroundImage: `url('https://storage.casaspecsa.com/invierte-renta/banners/principal-mobile.jpg')`,
        // }
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <CustomTypography
          variant="headingTertiary"
          txtColor={theme.palette.common.white}
        >
          Conoce todas las casas disponibles para Invertir
        </CustomTypography>
      </Container>
    </Box>
  );
};

export { HeroSection };
