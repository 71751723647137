import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import { Box, Grid, Divider } from "@mui/material";
import { CustomTypography } from "../CustomTypography";
import { CustomButton } from "../CustomButton";
import { getFormatoMoneda } from "../../functions/getFormatoMoneda";
import { useNavigate } from "react-router-dom";
import theme from "../../App/theme";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const SimpleHomeCard = ({ homeData, toRedirect, showSection }) => {
  let navigate = useNavigate();

  const navigateToPage = (url) => {
    navigate(url);
  };

  const {
    fotos,
    fachada,
    numeroDeLote,
    superficieDeTerreno,
    caracteristicas,
    precio,
  } = homeData;

  return (
    <Card
      sx={{
        minWidth: 310,
        maxWidth: 420,
        borderRadius: "10px",
        boxShadow: "0px 5px 12px 0px rgba(0, 0, 0, 0.19)",
        transition: "all .3s ease",
        "&:hover": {
          boxShadow: "0px 5px 12px 0px rgba(0, 0, 0, 0.39)",
        },
      }}
    >
      <CardMedia
        component="img"
        alt={`Casa Modelo:${fachada.modelo.nombre} Lote:${numeroDeLote} Fraccionamiento:${homeData.manzana.etapa.fraccionamiento.nombre}`}
        height="340"
        image={fotos[0].url}
      />
      <CardContent
        sx={{
          paddingTop: 2.5,
          paddingRight: 1.25,
          paddingBottom: 2.5,
          paddingLeft: 1.25,
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6} textAlign="left">
            <CustomTypography variant="textPrimaryBold">
              Modelo {fachada.modelo.nombre}
            </CustomTypography>
          </Grid>

          <Grid item xs={6} textAlign="right">
            <CustomTypography variant="textTertiaryLight">
              {fachada.modelo.superficieDeConstruccion} m<sup>2</sup> de
              construcción
            </CustomTypography>
          </Grid>

          <Grid item xs={6} textAlign="left">
            <CustomTypography variant="textPrimaryLight">
              Lote {numeroDeLote}
            </CustomTypography>
          </Grid>

          <Grid item xs={6} textAlign="right">
            <CustomTypography variant="textTertiaryLight">
              {superficieDeTerreno} m<sup>2</sup> de terreno
            </CustomTypography>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderTop: `2px solid ${theme.palette.grey[200]}`,
            borderBottom: `2px solid ${theme.palette.grey[200]}`,
            marginLeft: "-10px",
            marginRight: "-10px",
            padding: "20px 0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
              width: "calc(50% - 2px)", // Ocupar el 50% del espacio disponible - 2px para la línea divisoria
            }}
          >
            <BedOutlinedIcon sx={{ fontSize: "0.9rem" }} />
            {caracteristicas[0].recamaras} Habitaciones
          </Box>

          <Divider
            orientation="vertical"
            flexItem
            sx={{ bgcolor: theme.palette.grey[200] }}
          />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
              width: "calc(50% - 2px)", // Ocupar el 50% del espacio disponible - 2px para la línea divisoria
            }}
          >
            <BathtubOutlinedIcon sx={{ fontSize: "0.9rem" }} />
            {caracteristicas[0].banios} Baños
          </Box>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CustomTypography variant="subheadingSecondary">
            {homeData.datosDeInversion
              ? homeData.datosDeInversion.valorRenta
                ? getFormatoMoneda(homeData.datosDeInversion.valorRenta)
                : homeData.caracteristicas[0].valorRenta
              : getFormatoMoneda(precio)}
            <CustomTypography variant="textTertiaryBold">MXN</CustomTypography>
          </CustomTypography>
        </Box>

        {showSection && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderTop: `2px solid ${theme.palette.grey[200]}`,
              marginLeft: "-10px",
              marginRight: "-10px",
              paddingTop: "20px",
            }}
          >
            <Grid
              container
              gap={1.25}
              textAlign={"left"}
              sx={{
                marginX: "10px",
              }}
            >
              <Grid item xs={12}>
                <Box>
                  <CustomTypography variant="textPrimaryBold">
                    Lo que inviertes:
                  </CustomTypography>
                </Box>
              </Grid>
              <Grid container display="flex" justifyContent="space-between">
                <Grid item xs={6}>
                  <Box>
                    <CustomTypography variant="textPrimary">
                      Apartado:
                    </CustomTypography>
                  </Box>
                </Grid>
                <Grid item xs={6} textAlign={"right"}>
                  <Box>
                    <CustomTypography variant="textPrimaryBold">
                      {getFormatoMoneda(homeData.apartado)}
                    </CustomTypography>
                    <CustomTypography variant="textTertiaryLight">
                      MXN
                    </CustomTypography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container display="flex" justifyContent="space-between">
                <Grid item xs={6}>
                  <CustomTypography variant="textPrimary">
                    Enganche:
                  </CustomTypography>
                </Grid>
                <Grid item xs={6} textAlign={"right"}>
                  <CustomTypography variant="textPrimaryBold">
                    {getFormatoMoneda(homeData.enganche)}
                  </CustomTypography>
                  <CustomTypography variant="textTertiaryLight">
                    MXN
                  </CustomTypography>
                </Grid>
              </Grid>
              <Grid container display="flex" justifyContent="space-between">
                <Grid item xs={6}>
                  <CustomTypography variant="textPrimary">
                    Gastos:
                  </CustomTypography>
                </Grid>
                <Grid item xs={6} textAlign={"right"}>
                  <CustomTypography variant="textPrimaryBold">
                    {getFormatoMoneda(homeData.gastos)}
                  </CustomTypography>
                  <CustomTypography variant="textTertiaryLight">
                    MXN
                  </CustomTypography>
                </Grid>
              </Grid>
              <Grid container display="flex" justifyContent="space-between">
                <Grid item xs={7}>
                  <CustomTypography variant="textPrimary">
                    Monto de crédito:
                  </CustomTypography>
                </Grid>
                <Grid item xs={5} textAlign={"right"}>
                  <CustomTypography variant="textPrimaryBold">
                    {getFormatoMoneda(
                      homeData.montoDeCredito < 0
                        ? homeData.montoDeCredito * -1
                        : homeData.montoDeCredito
                    )}
                  </CustomTypography>
                  <CustomTypography variant="textTertiaryLight">
                    MXN
                  </CustomTypography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </CardContent>
      <CustomButton
        endIcon={<ArrowForwardIcon />}
        text="Conocer Casa"
        variant="contained"
        size="medium"
        onClick={() => navigateToPage(toRedirect)}
      />
    </Card>
  );
};

export { SimpleHomeCard };
