/**
 * Componente MyFooter representa el pie de página de Casas Pecsa.
 * Incluye en la sección superior el logo con redes sociales, información de la empresa y mapa del sitio.
 * En la sección inferior documentación legal pública de la empresa.
 *
 * @component
 * @param {Object} props - Propiedades del componente.
 * @param {Array} props.fraccionamientos - Lista de fraccionamientos.
 * @returns {JSX.Element} JSX Element que representa el pie de página de Casas Pecsa.
 *
 * @example
 * // Ejemplo de uso del componente MyFooter
 * <MyFooter fraccionamientos={fraccionamientosData} />
 */

import { Container, Divider, Box } from "@mui/material";
import { BottomContent } from "./BottomContent";
import { UpperContent } from "./UpperContent";
import { WhatsAppIcon } from "../Icons/WhatsappIcon";
import { LinkedInIcon } from "../Icons/LinkedInicon";
import { InstagramIcon } from "../Icons/InstagramIcon";
import { TikTokIcon } from "../Icons/TikTokIcon";
import { FacebookIcon } from "../Icons/FacebookIcon";
import logoImg from "../../assets/logo.svg";
import * as Icons from "@mui/icons-material";

/* Estilos */

const SectionStyles = {
  backgroundColor: "#E5E5E5",
  pt: { xs: "1.5rem", md: "3.125rem" },
  pb: "1rem",
};

const ContainerStyles = {
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
};

const MyFooter = ({ fraccionamientos }) => {
  /**
   * Función que redirige a la URL proporcionada en una nueva ventana del navegador.
   *
   * @param {string} url - URL a la que redirigir.
   * @returns {void}
   *
   * @example
   * // Ejemplo de uso de la función redirectToSocialMedia
   * redirectToSocialMedia("https://es-la.facebook.com/casaspecsa1/");
   */

  const redirectToSocialMedia = (url) => {
    window.open(url);
  };

  // Texto del pie de página
  const footerCopy = "Casas Pecsa";

  // Logo
  const logo = {
    src: logoImg,
    alt: "Logo de Casas Pecsa",
  };

  // Enlaces a documentos PDF
  const PDFlinks = [
    {
      href: "https://storage.casaspecsa.com/Documentos/T%C3%89RMINOS+Y+CONDICIONES+PECSA.pdf",
      text: "Términos y Condiciones",
    },
    {
      href: "https://storage.casaspecsa.com/Documentos/aviso-de-privacidad.pdf",
      text: "Aviso de Privacidad",
    },
  ];

  // Lista de enlaces a redes sociales con sus respectivos íconos
  const socialMediaLinks = [
    {
      icon: (
        <FacebookIcon
          color="#F5323F"
          onClick={() =>
            redirectToSocialMedia("https://es-la.facebook.com/casaspecsa1/")
          }
        />
      ),
    },
    {
      icon: (
        <WhatsAppIcon
          color="#F5323F"
          onClick={() =>
            redirectToSocialMedia(
              "https://api.whatsapp.com/send?phone=528441630722&text=Hola,%20buen%20dia!"
            )
          }
        />
      ),
    },
    {
      icon: (
        <TikTokIcon
          color="#F5323F"
          onClick={() =>
            redirectToSocialMedia("https://www.tiktok.com/@casaspecsa")
          }
        />
      ),
    },
    {
      icon: (
        <InstagramIcon
          color="#F5323F"
          onClick={() =>
            redirectToSocialMedia("https://www.instagram.com/casaspecsa/")
          }
        />
      ),
    },
    {
      icon: (
        <LinkedInIcon
          color="#F5323F"
          onClick={() =>
            redirectToSocialMedia("https://mx.linkedin.com/company/casas-pecsa")
          }
        />
      ),
    },
  ];

  // Información de contacto
  const contactMethod = [
    {
      icon: <Icons.LocationOn />,
      title: "Dirección",
      description:
        "Torre Insignia Piso 10, Blvd. José Sarmiento, #1515, Col. Rancho de Peña.",
      url: "https://www.google.com.mx/maps/place/Torre+Insignia+Saltillo/@25.4806781,-100.9812012,17z/data=!3m1!4b1!4m6!3m5!1s0x868812d49b997db7:0x2b706f2961621f8c!8m2!3d25.4806733!4d-100.9786263!16s%2Fg%2F11c6ydjv58?entry=ttu",
    },
    {
      icon: <Icons.AccessTimeFilled />,
      title: "Horario",
      description: "Lunes a Domingo de 11:00 am a 8:00 pm",
      applySecondaryStyles: true,
    },
    {
      icon: <Icons.Phone />,
      title: "Teléfono",
      description: "+52 844 163 0722",
    },
    {
      icon: <Icons.Email />,
      title: "Email",
      description: "earroyo@casaspecsa.com",
    },
  ];

  // Enlaces de programas de compra
  const purchasePrograms = [
    {
      title: "Agendar cita",
      path: "/citas",
    },
    {
      title: "Comprar tu Casa",
      path: "/tienda",
    },
    {
      title: "Tu Casa en México",
      path: "/tu-casa-en-mexico",
    },
    {
      title: "Invierte en Casas Pecsa",
      path: "/invierte",
    },
    {
      title: "Renta una Casa Pecsa",
      path: "/renta",
    },
  ];

  // Enlaces de características
  const features = [
    {
      title: "¡Recomienda y Gana!",
      path: "/recomienda",
    },
    {
      title: "Revista DECORA",
      path: "/revista-decora",
    },
    {
      title: "Equipa tu Casa Pecsa",
      path: "https://storage.casaspecsa.com/Documentos/catálogo-equipamiento-casas-pecsa.pdf",
    },
  ];

  return (
    <Box component="footer" sx={SectionStyles}>
      <Container sx={ContainerStyles}>
        <UpperContent
          fraccionamientos={fraccionamientos}
          socialMediaLinks={socialMediaLinks}
          logo={logo}
          contactMethod={contactMethod}
          purchasePrograms={purchasePrograms}
          features={features}
        />
        <Divider />
        <BottomContent PDFlinks={PDFlinks} footerCopy={footerCopy} />
      </Container>
    </Box>
  );
};

export { MyFooter };
