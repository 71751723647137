import axios from "axios";
import { useEffect, useState } from "react";

// export const useLoteToSimulator = () => {
// 	const [lote, setLote] = useState(null);		

// 	const getLote = (idLote, inversionInicial) => {
// 		console.log('inside', idLote);
// 		const queryInversionInicial = inversionInicial ? `?inversionInicial=${inversionInicial}` : ``;

// 		axios.get(`${process.env.REACT_APP_API_URL}/api/lotes-inversion/simulacion/${idLote}${queryInversionInicial}`)
// 		.then((response)=>{
// 			return response.data
// 		})
// 		.catch((error)=>{});
// 	}

// 	return { getLote }
// }
// export const useLoteToSimulator = async () => {
// 	const [lote, setLote] = useState(null);		

// 	const { data, error } = await axios.get(`${process.env.REACT_APP_API_URL}/api/lotes-inversion/simulacion/${94}`)
// 	if(error){
// 		setLote(null)
// 	}
// 	useEffect(async()=>{
// 		setLote(data);
// 		return await lote
// 	},[]);

// 	return await lote
// }
// --

// // export const useLoteToSimulator = (id) => {
// // 	const [lote, setLote] = useState(null);		

// // 	const getLote = (idLote, inversionInicial) => {
// // 		console.log('inside', idLote);
// // 		if(!idLote) return

// // 		const queryInversionInicial = inversionInicial ? `?inversionInicial=${inversionInicial}` : ``;

// // 		axios.get(`${process.env.REACT_APP_API_URL}/api/lotes-inversion/simulacion/${idLote}${queryInversionInicial}`)
// // 		.then((response)=>{
// // 			setLote(response.data)
// // 		})
// // 		.catch((error)=>{
// // 			setLote(null)
// // 		});
// // 	}
// // 	useEffect(() => {
// //         getLote(id);
// //     }, [id]);

// // 	return { lote }
// // }

export const useLoteToSimulator = (idLote, inversionInicial = 0) => {
	const [datosLote, setDatosLote] = useState(null);
	
	const getDatosLote = (idLote, inversionInicial = 0 ) => {
		console.log('inside', idLote, inversionInicial);
		if(!idLote) return

		const queryInversionInicial = inversionInicial ? `?inversionInicial=${inversionInicial}` : ``;

		axios.get(`${process.env.REACT_APP_API_URL}/api/lotes-inversion/simulacion/${idLote}${queryInversionInicial}`)
		.then((response)=>{
			console.log(response.data)
			setDatosLote(response.data);
		})
		.catch((error)=>{
			setDatosLote(null);
		});
	}
	useEffect(()=>{
		getDatosLote(idLote, inversionInicial)
	},[idLote]);
	return [datosLote, getDatosLote]
}