/**
 * El componente Address Container se encarga de generar la sección de medios de contacto del footer.
 *
 * @example
 * Modo de uso: Se declaran los datos de contacto en el componente principal del footer y se pasan como props contactMethod.
 */

import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

/* Estilos */
const colors = {
  text: "#1f1f1f",
  primary: "#f5323F",
};

const WrapperStyles = {
  width: "100%",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
};

const TypographyStyles = {
  fontFamily: "Montserrat",
  color: colors.text,
  transition: "color 0.2s ease",
  cursor: "pointer",
  "&:hover": {
    color: colors.primary,
  },
};

const PrimaryTypographyStyles = {
  fontWeight: "bold",
  fontFamily: "inherit",
};

const SecondaryTypographyNoHoverStyles = {
  ...TypographyStyles,
  "&:hover": {
    cursor: "text",
    color: colors.text,
  },
};

/**
 * Abre una URL en una nueva ventana del navegador
 * @param {string} url - La URL a la que se redirigirá
 */

const redirectToUrl = (url) => {
  window.open(url);
};

/**
 * Abre una URL en la misma ventana del navegador
 * @param {string} url - La URL a la que se redirigirá
 */

const openContact = (url) => {
  window.location.href = url;
};

/**
 * Componente funcional para mostrar texto secundario con estilos opcionales
 * @param {Object} props - Propiedades del componente
 * @param {string} props.text - Texto a mostrar
 * @param {Function} props.onClick - Función a ejecutar al hacer clic en el texto
 * @param {boolean} props.applySecondaryStyles - Indica si se deben aplicar estilos secundarios (Estilos secundarios copian el estilo base durante el efecto hover.)
 * @returns {JSX.Element} - Elemento de texto secundario
 */

const ListSecondaryTypography = ({ text, onClick, applySecondaryStyles }) => (
  <Typography
    role="button"
    tabIndex={0}
    onClick={onClick}
    sx={
      applySecondaryStyles ? SecondaryTypographyNoHoverStyles : TypographyStyles
    }
  >
    {text}
  </Typography>
);

/**
 * Maneja el clic en un elemento de contacto y redirige según el tipo de contacto
 * @param {string} url - La URL asociada al contacto
 * @param {string} description - Descripción del contacto
 */

const handleContactClick = (url, description) => {
  if (url) {
    redirectToUrl(url);
  } else {
    const isPhoneNumber = /^\+\d+/.test(description);
    if (isPhoneNumber) {
      openContact(`tel:${description}`);
    } else {
      openContact(`mailto:${description}`);
    }
  }
};

/**
 * Componente funcional que representa un elemento de contacto
 * @param {Object} props - Propiedades del componente
 * @param {JSX.Element} props.icon - Icono del contacto
 * @param {string} props.title - Título del contacto
 * @param {string} props.description - Descripción del contacto
 * @param {string} props.url - URL asociada al contacto
 * @param {boolean} props.applySecondaryStyles - Indica si se deben aplicar estilos secundarios
 * @returns {JSX.Element} - Elemento de contacto
 */

const ContactItem = ({
  icon,
  title,
  description,
  url,
  applySecondaryStyles,
}) => (
  <ListItem disablePadding>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText
      primary={<Typography sx={PrimaryTypographyStyles}>{title}</Typography>}
      secondary={
        <ListSecondaryTypography
          text={description}
          onClick={() => handleContactClick(url, description)}
          applySecondaryStyles={applySecondaryStyles}
        />
      }
    />
  </ListItem>
);

/**
 * Componente AddressContainer
 * @param {Array} contactMethod - Arreglo de objetos que representan métodos de contacto
 * @returns {JSX.Element} - Elemento de React que muestra una lista de métodos de contacto
 */

const AddressContainer = ({ contactMethod }) => {
  return (
    <Box sx={WrapperStyles}>
      <List disablePadding>
        {contactMethod.map((contact, index) => (
          <ContactItem key={index} {...contact} />
        ))}
      </List>
    </Box>
  );
};

export { AddressContainer };
