import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";

import pruebadecoracion from '../../assets/prueba/pruebadecoracion.jpg'

const PostMasPopulares = ({ post }) => {
    let navigate = useNavigate();
    const redirigirPublicacion = () => {
        navigate(`/blog/${post.id}/${post.titulo}`);
    }
    return(
        <Box
            sx={{
                mt:1,
                mb:1,
                color:'#707070',
                cursor:'pointer',
            }}
            onClick={redirigirPublicacion}
        >
            <Grid container spacing={1}>
                <Grid item xs={4} sm={4} md={4}>
                    <Box
                        sx={{
                            width:'100%',
                            height:'100%',
                            // backgroundColor:'red',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                width:'90%',
                                height:70,
                                // backgroundColor:'green',
                                // m:'0 auto',
                                borderRadius:'8px',
                                overflow: 'hidden',
                                top: '50%',
                                left: '50%',
                            }}
                        >
                            <Box
                                component='img'
                                src={post.imagen}
                                sx={{
                                    minWidth:'100%',
                                    minHeight:'100%',
                                    maxWidth:'110%',
                                }}
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                    <Box>
                        <Typography variant="body1" sx={{ color:'#DE4647', fontFamily:'Montserrat' }}>
                            <b>{post.titulo}</b>
                        </Typography>
                        <Box
                            sx={{
                                // backgroundColor:'yellow',
                                height:{xs:150, sm:150, md:80},
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            <Typography variant="caption" sx={{ fontFamily:'Montserrat' }}>
                                {post.texto}
                            </Typography>
                        </Box>
                        
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export { PostMasPopulares }