import * as React from 'react';
import { useEffect, useState } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Card, CardActionArea, CardContent, Grid, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";

import HomeIcon from '@mui/icons-material/Home';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import GroupIcon from '@mui/icons-material/Group';
import SellIcon from '@mui/icons-material/Sell';

const getPermisosDeAcceso = (perfil) => {
    const permisos = [
        { name: 'Administrador', accesos: [1,2,3,4,5,6,7] },
        { name: 'Asesor', accesos: [] },
        { name: 'Diseño', accesos: [] },
        { name: 'Tramitador', accesos: [3] },
    ]
    // console.log('llego');
    return permisos.find((element)=>element.name === perfil);
}

const CargasMenu = () =>{
    let navigate = useNavigate();
    
    const [usuario, setUsuario] = useState(null);

    const [permisos, setPermisos] = useState(null);

    const getAcceso = (index) =>{
        if(permisos){
            return permisos.accesos.includes(index)
        }
    }

    useEffect(() => {
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
          
        axios.get(`${process.env.REACT_APP_API_URL}/api/usuarios/by-folio/${localStorage.getItem('id')}`, 
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((response) => {
            // handle success
            console.log(response.data);
            setUsuario(response.data);
            // setPermisos(getPermisosDeAcceso(usuario.perfil.nombre))
        })
        .catch((error) => {
            // handle success
            console.log(error);
            navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sae');
        });
    }, []);
    useEffect(() => {
        if(usuario){
            setPermisos(getPermisosDeAcceso(usuario.perfil.nombre))
            console.log('hey',usuario);
        }
        // console.log('hey',usuario);
    }, [usuario]);

    return(
        <div>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'white' }}>
                <Container sx={{ backgroundColor:'white' }}>
                    <Box sx={{ /*backgroundColor:'yellow'*/ }}>                
                        <h1>Carga de informacion</h1>                                 
                    </Box>
                    <Box sx={{ mt:8, mb:8, }}>                
                        <Grid
                            container 
                            direction="row" 
                            spacing={{ xs: 2, md: 3 }} 
                            columns={{ xs: 12, sm: 12, md: 12, lg:12 }}
                            justifyContent="center"
                            alignItems="center"
                        >
                            {
                                getAcceso(1) && (
                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                        <Card>
                                            <CardActionArea onClick={()=>{ navigate('/cargas/inventario')}}>
                                                <CardContent>
                                                    <HomeIcon
                                                        sx={{
                                                            fontSize:100,
                                                            color: "text.secondary"
                                                        }}
                                                    />
                                                    <Typography color="text.secondary" gutterBottom variant="h5" component="div">
                                                        Inventario
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                )
                            }
                            {
                                getAcceso(2) && (
                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                        <Card>
                                            <CardActionArea onClick={()=>{ navigate('/cargas/modelos')}}>
                                                <CardContent>
                                                    <HomeIcon
                                                        sx={{
                                                            fontSize:100,
                                                            color: "text.secondary"
                                                        }}
                                                    />
                                                    <Typography color="text.secondary" gutterBottom variant="h5" component="div">
                                                        Modelos
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                )
                            }
                            {
                                getAcceso(3) && (
                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                        <Card>
                                            <CardActionArea onClick={()=>{ navigate('/cargas/clientes')}}>
                                                <CardContent>
                                                    <GroupIcon
                                                        sx={{
                                                            fontSize:100,
                                                            color: "text.secondary"
                                                        }}
                                                    />
                                                    <Typography color="text.secondary" gutterBottom variant="h5" component="div">
                                                        Clientes
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                )
                            }
                            {
                                getAcceso(4) && (
                                        <Grid item xs={12} sm={4} md={4} lg={4}>
                                        <Card>
                                            <CardActionArea onClick={()=>{ navigate('/cargas/promociones')}}>
                                                <CardContent>
                                                    <SellIcon
                                                        sx={{
                                                            fontSize:100,
                                                            color: "text.secondary"
                                                        }}
                                                    />
                                                    <Typography color="text.secondary" gutterBottom variant="h5" component="div">
                                                        Promociones
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                )
                            }
                            {
                                getAcceso(5) && (
                                        <Grid item xs={12} sm={4} md={4} lg={4}>
                                        <Card>
                                            <CardActionArea onClick={()=>{ navigate('/cargas/empresas')}}>
                                                <CardContent>
                                                    <CorporateFareIcon
                                                        sx={{
                                                            fontSize:100,
                                                            color: "text.secondary"
                                                        }}
                                                    />
                                                    <Typography color="text.secondary" gutterBottom variant="h5" component="div">
                                                        Empresas
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                )
                            }
                            {
                                getAcceso(6) && (
                                        <Grid item xs={12} sm={4} md={4} lg={4}>
                                        <Card>
                                            <CardActionArea onClick={()=>{ navigate('/cargas/usuarios')}}>
                                                <CardContent>
                                                    <GroupIcon
                                                        sx={{
                                                            fontSize:100,
                                                            color: "text.secondary"
                                                        }}
                                                    />
                                                    <Typography color="text.secondary" gutterBottom variant="h5" component="div">
                                                        Usuarios
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                )
                            }
                            {
                                getAcceso(7) && (
                                        <Grid item xs={12} sm={4} md={4} lg={4}>
                                        <Card>
                                            <CardActionArea onClick={()=>{ navigate('/cargas/asesores')}}>
                                                <CardContent>
                                                    <GroupIcon
                                                        sx={{
                                                            fontSize:100,
                                                            color: "text.secondary"
                                                        }}
                                                    />
                                                    <Typography color="text.secondary" gutterBottom variant="h5" component="div">
                                                        Asesores
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </div>
    );
}

export { CargasMenu }