import { Box, Button, Grid, Typography } from "@mui/material";
import { getFormatoMoneda } from "../../../functions/getFormatoMoneda";
import { PlazosSelect } from "./PlazosSelect";

const Primero = ({ precio, plazo, handleChangePlazo, handleClick }) => {
    return(
        <Box>
            <Grid container>
                <Grid item xs={12} sm={7} md={6}>
                    <Box
                        sx={{
                            // backgroundColor:'pink',
                            display:'flex',
                            height:'100%',
                            // flexDirection:'column',
                            alignItems:'center',
                            justifyContent:'center',
                        }}
                    >
                        <Box
                            sx={{
                                // backgroundColor:'greenyellow',
                                width:{ xs:'70%', sm:'80%', md:'80%' },
                                // height:'60%',
                                display:'flex',
                                flexDirection:'column',
                                alignItems:'center',
                                justifyContent:'space-between',
                                gap:3,
                            }}
                        >
                            <Box
                                sx={{
                                    // backgroundColor:'yellow',
                                    display:'flex',
                                    flexDirection:'column',
                                    alignItems:'center',
                                    justifyContent:'center',
                                    // gap:2,
                                }}
                            >
                                <Typography variant="body2" color="primary">
                                    <b>Valor de la casa</b>
                                </Typography>
                                <Typography variant="h5" sx={{ color:'#707070' }}>
                                    <b>{ getFormatoMoneda(precio) }</b>
                                </Typography>
                            </Box>
                            <PlazosSelect plazo={plazo} handleChange={handleChangePlazo}/>
                            <Button
                                variant="outlined"
                                sx={{
                                    width: { xs:'100%', sm:'100%', md:'40%' }
                                }}
                                onClick={handleClick}
                                disabled={!plazo}
                            >
                                <b>Calcular Crédito</b>
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={5} md={6}>
                    <Box
                        sx={{
                            // backgroundColor:'yellow',
                            height: { xs: 300, sm:300, md:350 },
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'center',
                        }}
                    >
                        <Box
                            component="img"
                            src="https://storage.casaspecsa.com/otros/simulador-image.svg"
                            sx={{
                                maxHeight:'100%'
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export { Primero }