import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";

import imagenPromo from '../../assets/prueba/imagenPromo.png'

const PromoMes = ({ promo }) => {
    let navigate = useNavigate();
    const redirigirTienda = () => {
        navigate('/tienda');
    }
    return(
        <Box
            sx={{
                width:'95%',
                // backgroundColor:'red',
                m:'0 auto',
                borderRadius:'15px',
                overflow:'hidden',
                cursor:'pointer',
            }}
            onClick={redirigirTienda}
        >
            <Box
                component='img'
                src={promo.foto}
                sx={{
                    width:'100%'
                }}
            />
        </Box>
    );
}

export { PromoMes }