import { Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import SaveIcon from '@mui/icons-material/Save';
import { useParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useCallback } from "react";
import { useEffect } from "react";

const EditAcercaDeMi = ({ texto, updateTexto, cancelar, }) => {
    const { folio } = useParams();
    const [textoTextField, setTextoTextField] = useState(texto);
    const getTextoTextField = (event) => {
        setTextoTextField(event.target.value)
    }
    const [datas, setDatas] = useState([]);

    const add = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        // const prueba = {
        //     idioma: data.get('idioma'),
        //     nivel: data.get('nivel'),
        // };
        // convertStringToArray()
        // console.log(datas); return;
        axios.post(`${process.env.REACT_APP_API_URL}/api/asesores/profile/${folio}/informacion/acercaDeMi/edit`,
            {
                text: textoTextField,
                // nivel: data.get('nivel'),
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
        .then((response)=>{
            console.log(response);
            updateTexto(textoTextField);
            cancelar();
            // addListIdiomas({
            //     idioma: data.get('idioma'),
            //     // nivel: data.get('nivel'),
            // });
            // getAgregar()
            // setInformacion(response.data)            
        })
        .catch((error)=>{
            console.log(error);
        });
    }
    return(
        <Box>
            <Box component="form" onSubmit={add}>
                <Box>
                    <TextField
                        id="outlined-multiline-static"                                        
                        multiline
                        rows={5}
                        defaultValue={textoTextField}
                        onChange={getTextoTextField}
                        name="texto"
                        sx={{
                            width:{ xs:'100%', sm:'95%', md:'95%', }, 
                            whiteSpace:'pre-wrap',
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        mt:1,
                        mb:1,
                    }}
                >
                    <Button 
                        variant="contained" 
                        endIcon={<SaveIcon />}
                        sx={{
                            backgroundColor:'#F5323F',
                            textTransform:'none',
                            borderRadius:'10px',
                            width:{ xs:'100%', sm:'100%', md:'20%' }
                        }}
                        type="submit"
                    >
                        <b>Guardar</b>
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

export { EditAcercaDeMi }