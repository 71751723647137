import React from "react";
import { Box, Container, Link } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CustomTypography } from "../../../components/CustomTypography";
import { CustomTimeline } from "../../../components/CustomTimeline";
import theme from "../../../App/theme";

const StepsSection = () => {
  const isMdScreen = useMediaQuery(theme.breakpoints.up("md"));

  const stepsList = [
    {
      number: "1",
      title: "Busca una Casa",
      description: (
        <CustomTypography variant="textPrimary">
          Revisa las casas disponibles <Link href="#listing">aquí</Link> y elige
          la casa que mejor se adapte a tus necesidades y gustos.
        </CustomTypography>
      ),
      img: "https://storage.casaspecsa.com/invierte-renta/Pasos/Renta/Busca.png",
    },

    {
      number: "2",
      title: "Elígela",
      description: (
        <CustomTypography variant="textPrimary">
          Una vez que hayas revisado toda la información y te hayas decidido por
          una casa, simplemente haz clic en{" "}
          <CustomTypography variant="textPrimaryBold" txtColor="primary">
            Contactar.
          </CustomTypography>
        </CustomTypography>
      ),
      img: "https://storage.casaspecsa.com/invierte-renta/Pasos/Renta/Elige.png",
    },

    {
      number: "3",
      title: "Regístrate",
      description: (
        <CustomTypography variant="textPrimary">
          Llena un formulario con tu nombre completo, correo electrónico y
          número de teléfono y haz clic en{" "}
          <CustomTypography variant="textPrimaryBold" txtColor="primary">
            Enviar
          </CustomTypography>
          . El propietario recibirá tu información y podrá ponerse en contacto
          contigo.
        </CustomTypography>
      ),
      img: "https://storage.casaspecsa.com/invierte-renta/Pasos/Renta/Registrate.png",
    },

    {
      number: "4",
      title: "¡Listo!",
      description: (
        <CustomTypography variant="textPrimary">
          Luego, solo necesitas ponerte de acuerdo con el propietario para
          finalizar el proceso de alquiler de la casa.{" "}
          <CustomTypography variant="textPrimaryBold">
            ¡Tu nueva vivienda está a solo un paso de convertirse en tu hogar!
          </CustomTypography>
        </CustomTypography>
      ),
      img: "https://storage.casaspecsa.com/invierte-renta/Pasos/Renta/Listo.png",
    },
  ];
  return (
    <Box
      component="section"
      sx={{
        backgroundColor: theme.palette.grey[100],
        py: "2.5rem",
        [theme.breakpoints.up("sm")]: {
          py: "5rem",
        },
      }}
    >
      <Container
        sx={{
          display: "flex",
          textAlign: "center",
          flexDirection: "column",
          gap: 3.75,
        }}
      >
        <Box>
          <CustomTypography variant="subheadingPrimary" txtColor="primary">
            ¿Cómo Rentar en Casas Pecsa?
          </CustomTypography>
        </Box>

        <CustomTimeline stepsList={stepsList} isMdScreen={isMdScreen} />
      </Container>
    </Box>
  );
};

export { StepsSection };
