import { Box, } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Principal } from "./Principal";
import { Objetivo } from "./Objetivo";
import { Requisitos } from "./Requisitos";
import { Mecanica } from "./Mecanica";
import { Restricciones } from "./Restricciones";
import { Formulario } from "./Formulario";
import { Ubicaciones } from "./Ubicaciones";

const RecomiendaYGana = () => {
    const refFormulario = useRef(null);
    const refUbicacion = useRef(null);

    const [fraccionamientos, setFraccionamientos] = useState([]);
    const [modelos, setModelos] = useState([]);

    const moverteFormulario = () => {
        // refFormulario.current.scrollIntoView();
        window.scrollTo(0, (refFormulario.current.offsetTop - 100));
        console.log('refFormulario.current.offsetTop');
    }
    const moverteUbicacion = () => {
        // refUbicacion.current.scrollIntoView();
        window.scrollTo(0, (refUbicacion.current.offsetTop - 70));
    }
    const reedirigirALlamada = () =>{
        window.location.href = 'tel:+52 8441630722';
    }
    useEffect(()=>{
        window.scroll(0,0);
        axios.get(`${process.env.REACT_APP_API_URL}/api/recomienda-y-gana/view`)
        .then((response)=>{
            console.log(response)
            setFraccionamientos(response.data.fraccionamientos)
            setModelos(response.data.modelos)
        })
        .catch((error) => {
            // handle success
            console.log(error);
        })
        .then(() => {
            // handle success
            //console.log('sabe');
        });
    }, [])
    return(
        <div>
            <Box sx={{ mt:{ xs:12, sm:12, md:12, } }}>                
                <Principal moverteFormulario={moverteFormulario}/>
            </Box>
            <Box sx={{ }}>
                <Objetivo/>
            </Box>
            <Box sx={{ }}>
                <Requisitos/>
            </Box>
            <Box sx={{ }}>
                <Mecanica moverteFormulario={moverteFormulario} reedirigirALlamada={reedirigirALlamada} moverteUbicacion={moverteUbicacion}/>
            </Box>
            <Box sx={{ }}>
                <Restricciones/>
            </Box>
            <Box ref={refFormulario} sx={{  }}>
                <Formulario fraccionamientos={fraccionamientos} modelos={modelos} />
            </Box>
            <Box ref={refUbicacion} sx={{ }}>
                <Ubicaciones/>
            </Box>
        </div>
    );
}

export { RecomiendaYGana }