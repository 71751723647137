import Typography from "@mui/material/Typography";

import theme from "../../App/theme";

/**
 * Componente personalizado que permite aplicar estilos de texto predefinidos.
 *
 * @component
 * @param {string} variant - La variante de estilo de texto a aplicar.
 * @param {string} children - El contenido de texto que se mostrará con los estilos aplicados.
 * @param {string} txtColor - El color del texto (opcional).
 * @returns {JSX.Element} - Componente Typography de Material-UI con los estilos aplicados.
 */

const textStyles = {
  headingSecondary: {
    fontSize: theme.typography.heading2.fontSize,
    fontWeight: 700,
  },
  headingTertiary: {
    fontSize: theme.typography.heading3.fontSize,
    fontWeight: 700,
  },
  subheadingPrimary: {
    fontSize: theme.typography.subheading1.fontSize,
    fontWeight: 500,
  },
  subheadingSecondary: {
    fontSize: theme.typography.subheading2.fontSize,
    fontWeight: 500,
  },
  subheadingSecondaryBold: {
    fontSize: theme.typography.subheading2.fontSize,
    fontWeight: 700,
  },
  textPrimary: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 400,
  },
  textSecondary: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 400,
  },
  textTertiary: {
    fontSize: theme.typography.body3.fontSize,
    fontWeight: 400,
  },
  textPrimaryBold: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 600,
  },
  textSecondaryBold: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 600,
  },
  textTertiaryBold: {
    fontSize: theme.typography.body3.fontSize,
    fontWeight: 600,
  },
  textPrimaryLight: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 300,
  },
  textSecondaryLight: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 300,
  },
  textTertiaryLight: {
    fontSize: theme.typography.body3.fontSize,
    fontWeight: 300,
  },
};

function CustomTypography({ variant, children, txtColor }) {
  return (
    <Typography variant={variant} style={textStyles[variant]} color={txtColor}>
      {children}
    </Typography>
  );
}

export { CustomTypography };
