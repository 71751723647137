import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

// import { SlideAnuncios } from '../../components/SlideAnuncios';
// import { ModelosCarrusel } from '../../components/ModelosCarrusel';
// import { SolicitaCredito } from "../../components/Banners/SolicitaCredito";
// import { FraccionamientosCarrusel } from '../../components/FraccionamientosCarrusel';
// import { OchoRazones } from "../../components/OchoRazones";

import Box from '@mui/material/Box';
import { Breadcrumbs, Container, Grid, Stack, Typography } from "@mui/material";
import { TiendaItems } from "../../components/TiendaItems";
import { TiendaFiltro } from "../../components/TiendaFiltro";
import { Link, useSearchParams } from "react-router-dom";
import { TiendaItemList } from "../../components/TiendaItems/TiendaItemList";
import { MetaPixel } from "../../components/MetaPixel";

const Tienda = ({ lotes }) => {
    let [searchParams, setSearchParams] = useSearchParams();
    // console.log('tiendaaaaa',lotes);
    // const [lotes, setLotes] = useState(props.lotes);
    const [lotesFltrados, setLotesFltrados] = useState(lotes);

    const handleChangeSearch = (modelo, fraccionamiento) => {
        // console.log('handleChangeSearch 1',modelo);
        let modelofilter = modelo.nombre;
        let fraccionamientofilter = fraccionamiento.nombre;

        console.log(modelofilter, fraccionamientofilter);


        if (modelofilter || fraccionamientofilter){
            setSearchParams({modelofilter: modelofilter || '', fraccionamientofilter: fraccionamientofilter || ''});
            // console.log(modelofilter);
            // console.log(fraccionamientofilter);
            console.log(
                lotes.filter((lote) => {
                    // const filter = searchParams.get("modelofilter");
                    const filter = modelo.nombre || '';
                    // console.log(filter);
                    if (!filter) return true;                                
                    return lote.fachada.modelo.nombre === filter
                }).filter((lote) => {
                    // const fraccionamientofilter = searchParams.get("fraccionamientofilter");
                    const fraccionamientofilter = fraccionamiento.nombre || '';
                    // console.log(fraccionamientofilter);

                    if (!fraccionamientofilter) return true;                               
                    return lote.manzana.etapa.fraccionamiento.nombre === fraccionamientofilter
                })
            );
        }else{
            setSearchParams({});
            // console.log(modelofilter);
        }          
    }

    useEffect(()=>{
        window.scroll(0,0);
    }, [lotes])
    return(
        <div>
            <Helmet>
                <title>Casas Pecsa | Tienda</title>
                <meta name="description" content="¿Tienes Alguna duda sobre tu casa? Nosotros Te Ayudamos. Separa Tu Casa En Línea. Puedes Ver Tu Casa 100% En Línea a Detalle y Conocer tu Fraccionamiento 100% En Línea." />
            </Helmet>
            <MetaPixel/>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, mt:{ xs:7, sm:8, md:11 }, mb:5, minHeight:830 }}>
                <Container sx={{ backgroundColor:'white' }}>
                    <Box
                        sx={{
                            // backgroundColor:'red',
                            pb:1,
                            pt:0.6,
                            pl:1,
                            color:'#707070',
                        }}
                    >
                        <Breadcrumbs aria-label="breadcrumb">
                            {/* <Link underline="hover" color="inherit">
                                Inicio
                            </Link> */}
                            <Link to={'/'} style={{ color:'#707070', }}>
                                Inicio
                            </Link>
                            <Typography sx={{ color:'#F5323F', }}>Tienda</Typography>
                        </Breadcrumbs>
                    </Box>
                    <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={2}>
                            <TiendaFiltro lotes={lotes} handleChangeSearch={handleChangeSearch}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={10}>
                            <Box
                                sx={{
                                    display:{ xs:'block', sm:'block', md:'block', }
                                }}
                            >
                                <TiendaItems 
                                    lotes={
                                        lotes.filter((lote) => {
                                            const filter = searchParams.get("modelofilter");
                                            // console.log(filter);
                                            if (!filter) return true;                                
                                            return lote.fachada.modelo.nombre === filter
                                        }).filter((lote) => {
                                            const fraccionamientofilter = searchParams.get("fraccionamientofilter");
                                            // console.log(fraccionamientofilter);
                    
                                            if (!fraccionamientofilter) return true;                               
                                            return lote.manzana.etapa.fraccionamiento.nombre === fraccionamientofilter
                                        })
                                    }
                                    // lotes={lotesFltrados}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    </Box>
                    {/* <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={2}>
                            <TiendaFiltro lotes={lotes} handleChangeSearch={handleChangeSearch}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={10}>
                            <Box
                                sx={{
                                    display:{ xs:'block', sm:'block', md:'block', }
                                }}
                            >
                                <TiendaItems 
                                    lotes={
                                        lotesFltrados.filter((lote) => {
                                            const filter = searchParams.get("modelofilter");
                                            // console.log(filter);
                                            if (!filter) return true;                                
                                            return lote.fachada.modelo.nombre === filter
                                        }).filter((lote) => {
                                            const fraccionamientofilter = searchParams.get("fraccionamientofilter");
                                            console.log(fraccionamientofilter);
                    
                                            if (!fraccionamientofilter) return true;                               
                                            return lote.manzana.etapa.fraccionamiento.nombre === fraccionamientofilter
                                        })
                                    }
                                />
                            </Box>
                        </Grid>
                    </Grid> */}
                </Container>
            </Box>
        </div>
    );
}
export { Tienda };