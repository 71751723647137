import { Button, Card, Grid, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";

import PhonelinkIcon from '@mui/icons-material/Phonelink';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PeopleIcon from '@mui/icons-material/People';
import PhoneInput from "react-phone-input-2";

const Mecanica = ({ moverteFormulario, reedirigirALlamada, moverteUbicacion }) => {
    return(
        <Container>
            <Box
                sx={{
                    // backgroundColor:'red',
                    width:'100%',
                }}
            >
                <Box>
                    <Typography variant="h4" sx={{ color:'#F5323F' }}>
                        <b>Mecánica de la promoción</b>
                    </Typography>
                    <Typography variant="h6" sx={{ color:'#707070', }}>
                        Hay tres maneras de hacer llegar sus recomendaciones:
                    </Typography>
                </Box>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12} sm={4} md={4} sx={{ /*backgroundColor:'greenyellow'*/ }}>
                        <Box
                            sx={{
                                // backgroundColor:'green',
                                width:'100%',
                                height:400,
                                display:'flex',
                                alignItems:'center',
                                alignContent:'center',
                            }}
                        >
                            <Card
                                sx={{
                                    width:'95%',
                                    minHeight:300,
                                    m:'0 auto',
                                    textAlign:'center',
                                    alignItems:'center',
                                    alignContent:'center',
                                    color:'#707070',
                                    borderRadius:'15px',
                                    boxShadow: '0px 5px 12px 0px rgb(0, 0, 0, 0.19)',
                                }}
                            >
                                <PhonelinkIcon sx={{ fontSize:80, mt:3 }}/>
                                <Typography variant="h5" sx={{ color:'#F5323F' }}>
                                    <b>Web</b>
                                </Typography>
                                <Box
                                    sx={{
                                        // backgroundColor:'yellow',
                                        width:'80%',
                                        height:110,
                                        m:'0 auto',
                                        textAlign:'left',
                                        mb:1
                                    }}
                                >
                                    <Typography variant="body1">
                                        A través de nuestro sitio web: www.casaspecsa.com, deberá de llenar el formulario en la sección “Recomienda y gana”
                                    </Typography>
                                </Box>
                                <Button 
                                    variant="contained"
                                    color='inherit'
                                    sx={{
                                        backgroundColor:'#F5323F',
                                        color:'white',
                                        borderRadius:'7px',
                                        '&:hover': {
                                            backgroundColor: '#db0a18',
                                        },
                                        mb:3,
                                        textTransform: 'none',
                                    }}
                                    onClick={moverteFormulario}
                                >
                                    <b>Ir</b>
                                </Button>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} sx={{ /*backgroundColor:'greenyellow'*/ }}>
                        <Box
                            sx={{
                                // backgroundColor:'green',
                                width:'100%',
                                height:400,
                                display:'flex',
                                alignItems:'center',
                                alignContent:'center',
                            }}
                        >
                            <Card
                                sx={{
                                    width:'95%',
                                    minHeight:300,
                                    m:'0 auto',
                                    textAlign:'center',
                                    alignItems:'center',
                                    alignContent:'center',
                                    color:'#707070',
                                    borderRadius:'15px',
                                    boxShadow: '0px 5px 12px 0px rgb(0, 0, 0, 0.19)',
                                }}
                            >
                                <LocalPhoneIcon sx={{ fontSize:80, mt:3 }}/>
                                <Typography variant="h5" sx={{ color:'#F5323F' }}>
                                    <b>Telefono</b>
                                </Typography>
                                <Box
                                    sx={{
                                        // backgroundColor:'green',
                                        width:'80%',
                                        height:110,
                                        m:'0 auto',
                                        textAlign:'left',
                                        mb:1
                                    }}
                                >
                                    <Typography variant="body1">
                                        Via telefónica al 844 163 0722 y unos de nuestros asesores te atendera.
                                    </Typography>
                                </Box>
                                <Button 
                                    variant="contained"
                                    color='inherit'
                                    sx={{
                                        backgroundColor:'#F5323F',
                                        color:'white',
                                        borderRadius:'7px',
                                        '&:hover': {
                                            backgroundColor: '#db0a18',
                                        },
                                        mb:3,
                                        textTransform: 'none',
                                    }}
                                    onClick={reedirigirALlamada}
                                >
                                    <b>Llamar</b>
                                </Button>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} sx={{ /*backgroundColor:'greenyellow'*/ }}>
                        <Box
                            sx={{
                                // backgroundColor:'green',
                                width:'100%',
                                height:400,
                                display:'flex',
                                alignItems:'center',
                                alignContent:'center',
                            }}
                        >
                            <Card
                                sx={{
                                    width:'95%',
                                    minHeight:300,
                                    m:'0 auto',
                                    textAlign:'center',
                                    alignItems:'center',
                                    alignContent:'center',
                                    color:'#707070',
                                    borderRadius:'15px',
                                    boxShadow: '0px 5px 12px 0px rgb(0, 0, 0, 0.19)',
                                }}
                            >
                                <PeopleIcon sx={{ fontSize:80, mt:3 }}/>
                                <Typography variant="h5" sx={{ color:'#F5323F' }}>
                                    <b>Personalmente</b>
                                </Typography>
                                <Box
                                    sx={{
                                        // backgroundColor:'green',
                                        width:'80%',
                                        height:110,
                                        m:'0 auto',
                                        textAlign:'left',
                                        mb:1
                                    }}
                                >
                                    <Typography variant="body1">
                                        En cualqueira de nuestras casas muestra, en donde deberá proporcionar los datos de su recomendado al asesor.
                                    </Typography>
                                </Box>
                                <Button 
                                    variant="contained"
                                    color='inherit'
                                    sx={{
                                        backgroundColor:'#F5323F',
                                        color:'white',
                                        borderRadius:'7px',
                                        '&:hover': {
                                            backgroundColor: '#db0a18',
                                        },
                                        mb:3,
                                        textTransform: 'none',
                                    }}
                                    onClick={moverteUbicacion}
                                >
                                    <b>Ubicar</b>
                                </Button>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

export { Mecanica }