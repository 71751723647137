import * as React from "react";
import { useRef } from "react";
import { Box, height } from "@mui/system";
import Container from '@mui/material/Container';

//import Carousel from "react-multi-carousel";
//import './styles.css';
import { Button, Grid, Stack, Fab, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { motion } from "framer-motion";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Garantia } from "./Garantia";

import estructura from '../../assets/garantias/estructura.png'
import garantiadeporvida from '../../assets/garantias/garantiadeporvida.png'
import garantia23dias from '../../assets/garantias/garantia23dias.png'
import impermeabilizante from '../../assets/garantias/impermeabilizante.png'
import instalaciones from '../../assets/garantias/instalaciones.png'

import Carousel from "react-material-ui-carousel";

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const garantias = [
  { name: 'Estructura', desc: 'Esta garantía incluye los elementos estructurales tales como cimentaciones, muros, losas, trabes, vigas, castillos y todolo que compone la parte estructural de una vivienda.', img: estructura }, 
  { name: 'Garantía de Por Vida', desc: 'Pisos interceramic garantizados de por vida, para que vivas con la tranquilidad de tener los acabados mas duraderos en tu nueva casa.', img: garantiadeporvida },
  { name: 'Garantía 23 Días', desc: 'Sabemos que tener tu nueva casa es una prioridad, por eso te garantizamos tu trámite en maximo 23 días o se te abonará el monto de tu separacion.', img: garantia23dias },
  { name: 'Impermeabilizante', desc: 'La losa de azotea cuenta con la aplicacion de un impermeabilizante con ganantía de 3 años.', img: impermeabilizante },
  { name: 'Instalaciones', desc: 'Todas las instalaciones sanitarias, hidraulizas, de gas y eléctricas cuentan con garantía de 1 año.', img: instalaciones },
]

const Garantias = ({ color, align, size }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const constraintsRef = useRef(null);
    const containerRef = useRef(null);

    //const Contenedor = containerRef.current.clientWidth == null || containerRef.current.clientWidth == undefined ? 0 : containerRef.current.clientWidth;
    // const DivVisible = constraintsRef.current.clientWidth;
    // const Diferencia = Contenedor - DivVisible;

    const [Xside, setXside] = React.useState(0);
    const atras = (Xside) => {
      setXside(Xside+395.96);
      console.log(constraintsRef.current.clientWidth);
      console.log(containerRef.current.clientWidth);
    };
    const adelante = (Xside) => {
      setXside(Xside-395.96);
      console.log(constraintsRef.current.clientWidth);
    console.log(containerRef.current.clientWidth);
    };

    return(
        <Container sx={{  }}>
            <Box sx={{ textAlign: align ? align:'left', marginTop:2, color: color ? color :'#F5323F', fontSize:{ xs:20, sm:20, md:25 } }}>
              <h1>Garantías</h1>
            </Box>
            <Box
                sx={{
                    display: { xs:'block', sm:'block', md:'block', }
                }}
            >
              {
                !fullScreen ? (
                  <Carousel
                      sx={{
                          width: '100%', 
                          height: { xs: 340, sm: 350, md: 300 }
                      }}
                  >
                      {/* {
                          razones.map((razon, index)=>( */}
                              <Box
                                  sx={{ 
                                      display: { xs: 'flex', sm:'flex', md:'flex'},
                                  }}
                              >
                                  <Garantia garantia={garantias[0]} />
                                  <Garantia garantia={garantias[1]} />
                                  <Garantia garantia={garantias[2]} />                                
                              </Box>
                              <Box
                                  sx={{ display: { xs: 'flex', sm:'flex', md:'flex'} }}
                              >
                                  <Garantia garantia={garantias[3]} />
                                  <Garantia garantia={garantias[4]} />
                              </Box>
                      {/* ))
                      } */}
                  </Carousel>
                ) : (
                  <Carousel
                      sx={{
                          width: '100%', 
                          height: { xs: 380, sm: 400, md: 300 }
                      }}
                  >
                      {/* {
                          razones.map((razon, index)=>( */}
                              <Box
                                  sx={{ 
                                      display: { xs: 'flex', sm:'flex', md:'flex'},
                                  }}
                              >
                                  <Garantia garantia={garantias[0]} />
                              </Box>
                              <Box
                                  sx={{ display: { xs: 'flex', sm:'flex', md:'flex'} }}
                              >
                                  <Garantia garantia={garantias[1]} />
                              </Box>
                              <Box
                                  sx={{ display: { xs: 'flex', sm:'flex', md:'flex'} }}
                              >
                                  <Garantia garantia={garantias[2]} />
                              </Box>
                              <Box
                                  sx={{ display: { xs: 'flex', sm:'flex', md:'flex'} }}
                              >
                                  <Garantia garantia={garantias[3]} />
                              </Box>
                              <Box
                                  sx={{ display: { xs: 'flex', sm:'flex', md:'flex'} }}
                              >
                                  <Garantia garantia={garantias[4]} />
                              </Box>
                      {/* ))
                      } */}
                  </Carousel>
                )
              }
                
            </Box>    
            {/* <Box
              component={motion.div}
              ref={constraintsRef}
              sx={{
                //backgroundColor:'white', 
                display: 'flex',
                placeContent: 'start',
                placeItems: 'center',
                overflow: 'hidden',
                position:'relative',
                marginTop: 5,
                marginBottom: 9
              }}
            >
              <Stack
              ref={containerRef}
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
                component={motion.div}
                animate={{ x:Xside }}
                drag='x' 
                dragConstraints={constraintsRef}
              >
                {
                  garantias.map((garantia) => (
                    <Box
                      component={motion.div}
                      sx={{ 
                        width:{ xs: '185px', sm:'185px', md:'375px', lg:'375px' }                    
                      }}                  
                    >
                      <Garantia garantia={garantia}/>  
                    </Box>
                  ))
                }                                               
              </Stack>
              <Box 
                component="Button" 
                sx={{ 
                  pt: 3, pb:3, pl:0, pr:0,
                  backgroundColor:'white', 
                  border:'none',
                  color:'red',
                  zIndex:1, 
                  position:'absolute',
                  left:0,
                }}
                variant="contained"
                onClick={()=> atras(Xside)}
              >
                  <ChevronLeftIcon />
                </Box>
                <Box 
                component="Button" 
                sx={{ 
                  pt: 3, pb:3, pl:0, pr:0,
                  backgroundColor:'white', 
                  border:'none',
                  color:'red',
                  zIndex:1, 
                  position:'absolute',
                  right:0,
                }}
                variant="contained"
                onClick={()=> adelante(Xside)}
              >
                  <ChevronRightIcon />
                </Box>
            </Box> */}

                

        </Container>
    );
}

export { Garantias }