import React from "react";
import { Box, Container, Link } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CustomTypography } from "../../../components/CustomTypography";
import { CustomTimeline } from "../../../components/CustomTimeline";
import theme from "../../../App/theme";

const StepsSection = () => {
  const isMdScreen = useMediaQuery(theme.breakpoints.up("md"));

  const stepsList = [
    {
      number: "1",
      title: "Separa tu Casa",
      description: (
        <CustomTypography variant="textPrimary">
          Ingresa <Link href="invierte/listado">aquí</Link>,
          elige la casa que más te guste y{" "}
          <CustomTypography variant="textPrimaryBold">
            sepárala dando clic en Invertir.
          </CustomTypography>
        </CustomTypography>
      ),
      img: "https://storage.casaspecsa.com/invierte-renta/Pasos/Inversion/Separa.png",
    },

    {
      number: "2",
      title: "Ponle Precio",
      description: (
        <CustomTypography variant="textPrimary">
          Una vez adquirida tu nueva casa, en{" "}
          <CustomTypography variant="textPrimaryBold" txtColor="primary">
            Mi Cuenta{"->"}Mis Inversiones
          </CustomTypography>{" "}
          podrás asignar un precio de renta.
        </CustomTypography>
      ),
      img: "https://storage.casaspecsa.com/invierte-renta/Pasos/Inversion/Pon+precio.png",
    },

    {
      number: "3",
      title: "Contacta Interesados",
      description: (
        <CustomTypography variant="textPrimary">
          Promocionamos tu casa, gestionamos contactos con interesados y te
          ahorramos tiempo,{" "}
          <CustomTypography variant="textPrimaryBold">
            permitiéndote enfocarte directamente en la renta.
          </CustomTypography>
        </CustomTypography>
      ),
      img: "https://storage.casaspecsa.com/invierte-renta/Pasos/Inversion/Contacta.png",
    },

    {
      number: "4",
      title: "Renta tu Casa",
      description: (
        <CustomTypography variant="textPrimary">
          Cuando encuentres un inquilino adecuado,{" "}
          <CustomTypography variant="textPrimaryBold">
            pon tu casa en renta y comienza a disfrutar de tu inversión.
          </CustomTypography>
        </CustomTypography>
      ),
      img: "https://storage.casaspecsa.com/invierte-renta/Pasos/Inversion/Renta+tu+casa.png",
    },
  ];

  return (
    <Box
      component="section"
      sx={{
        backgroundColor: theme.palette.grey[200],
        py: "2.5rem",
        [theme.breakpoints.up("sm")]: {
          py: "5rem",
        },
      }}
    >
      <Container
        sx={{
          display: "flex",
          textAlign: "center",
          flexDirection: "column",
          gap: 3.75,
        }}
      >
        <Box>
          <CustomTypography variant="subheadingPrimary" txtColor="primary">
            ¿Cómo Invertir en Casas Pecsa?
          </CustomTypography>
        </Box>

        <CustomTimeline stepsList={stepsList} isMdScreen={isMdScreen} />
      </Container>
    </Box>
  );
};

export { StepsSection };
