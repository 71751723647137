import { Button, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import EditIcon from '@mui/icons-material/Edit';
import { useState } from "react";
import { AddEducacion } from "./AddEducacion";
import { ListaEducacion } from "./ListaEducacion";

const Educacion = ({ educacion }) => {
    const [agregar, setAgregar] = useState(false);
    const getAgregar = () => {
        setAgregar(!agregar);
    }

    const [listEducacion, setListEducacion] = useState(educacion);
    const addListEducacion = (newEducacion) => {
        console.log('adlist', newEducacion);
        setListEducacion((oldListEducacion)=>[...oldListEducacion, newEducacion]);
        console.log('list', listEducacion);
    }

    return(
        <Box
            sx={{
                minHeight:100,
            }}
        >
            <Typography variant="h5">
                Educacion
            </Typography>
            <Box
                sx={{
                    width:'100%',
                }}
            >
                {
                    agregar && (
                        <AddEducacion getAgregar={getAgregar} addListEducacion={addListEducacion} />
                    )
                }
                {
                    listEducacion.length > 0 ? (
                        <ListaEducacion educacion={listEducacion}/>
                    ) : (
                        <Box>
                            <Typography>
                                No hay nada
                            </Typography>                    
                        </Box>
                    )
                }
                {
                    !agregar && (
                        <Box
                            sx={{
                                mt:1,
                                mb:1,
                                // backgroundColor:'red',
                                width:'100%',
                                textAlign:'right',
                            }}
                        >
                            <Button 
                                variant="contained" 
                                endIcon={<EditIcon />}
                                sx={{
                                    backgroundColor:'#707070',
                                    textTransform:'none',
                                    borderRadius:'10px',
                                    width:{ xs:'100%', sm:'100%', md:'15%' }
                                }}
                                onClick={getAgregar}
                            >
                                <b>Agregar</b>
                            </Button>
                        </Box>
                    )
                }             
                        
            </Box>
        </Box>
    );
}

export { Educacion }