import { Avatar, Backdrop, Card, CardActions, CardContent, CardHeader, CardMedia, CircularProgress, IconButton, ImageList, ImageListItem, Menu, MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import asesorPerfil2 from "../../assets/asesores/asesorPerfil2.png";
import FavoriteIcon from '@mui/icons-material/Favorite';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from "react";
import Carousel from "react-material-ui-carousel";
import { EditarPost } from "./EditarPost";
import axios from "axios";
import { useParams } from "react-router-dom";

function srcset(image, size, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${
            size * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
}

const ItemListaPosts = ({ post }) => {
    const { folio } = useParams();
    const [fotosSelect, setFotosSelect] = useState([]);
    const getFotoSelect = (fotos) => {
        setFotosSelect(oldFotos=>[...oldFotos, ...fotos]);
    };

    const [openFotos, setOpenFotos] = useState(false);
    
    const handleCloseFotos = () => {
        setOpenFotos(false);
    };
    const handleToggleFotos = (foto) => {
        getFotoSelect(foto);
        setOpenFotos(!openFotos);
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const [editar, setEditar] = useState(false);
    const [idEditar, setIdEditar] = useState(null);
    // const [selectedValue, setSelectedValue] = useState(emails[1]);

    const handleClickOpenEditar = () => {
        setIdEditar(post.id)
        handleCloseMenu();
        setEditar(true);
    };

    const handleCloseEditar = (value) => {
        setEditar(false);
        // setSelectedValue(value);
    };

    const getFormatoVariasImagenes = (fotos) => {
        console.log(fotos);
        if(fotos.length === 2){
            return [
                {
                    img: fotos[0].url,
                    title: 'Breakfast',
                    rows: 4,
                    cols: 2,
                },
                {
                    img: fotos[1].url,
                    title: 'Burger',
                    rows: 4,
                    cols: 2,
                },
            ];
        }
        if(fotos.length === 3){
            return [
                {
                    img: fotos[0].url,
                    title: 'Breakfast',
                    rows: 2,
                    cols: 4,
                },
                {
                    img: fotos[1].url,
                    title: 'Burger',
                    rows: 2,
                    cols: 2,
                },
                {
                    img: fotos[2].url,
                    title: 'Burger',
                    rows: 2,
                    cols: 2,
                },
            ];
        }
        if(fotos.length >= 4){
            return [
                {
                    img: fotos[0].url,
                    title: 'Breakfast',
                    rows: 2,
                    cols: 2,
                },
                {
                    img: fotos[1].url,
                    title: 'Burger',
                    rows: 2,
                    cols: 2,
                },
                {
                    img: fotos[2].url,
                    title: 'Burger',
                    rows: 2,
                    cols: 2,
                },
                {
                    img: fotos[3].url,
                    title: 'Burger',
                    rows: 2,
                    cols: 2,
                },
            ];
        }
    }
    const borrar = (event) => {
        
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');       

        axios.post(`${process.env.REACT_APP_API_URL}/api/asesores/profile/${folio}/posts/${post.id}`,
            { activo: false },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
        .then((response)=>{
            console.log(response); 
            // setButtonDisabled(false);
            window.location.reload();
        })
        .catch((error)=>{
            console.log(error);
        });
    }
    return(
        <Box
            sx={{
                width:'100%',
            }}
        >
            <Card 
                sx={{
                    borderRadius:'12px',
                }}
            >
                <CardHeader
                    avatar={
                        <Avatar 
                            sx={{ bgcolor: '#707070' }} 
                            aria-label="recipe" 
                            variant="rounded" 
                            src={asesorPerfil2}>
                            R
                        </Avatar>
                    }
                    action={
                        <Box>
                            <IconButton aria-label="settings" onClick={handleClickMenu}>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={handleCloseMenu}
                                MenuListProps={{
                                'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={handleClickOpenEditar}>Editar</MenuItem>
                                <MenuItem onClick={borrar}>Borrar</MenuItem>
                            </Menu>
                        </Box>
                    }
                    title="Nombre del Vendedor"
                    subheader="September 14, 2016"
                />
                {
                    (post.fotos.length > 0) && (
                        post.fotos.length > 1 ? (
                            <Box
                                sx={{
                                    // backgroundColor:'yellow',
                                    width:'100%',
                                }}
                            >
                                <ImageList
                                    sx={{ width: '100%', height: 500 }}
                                    variant="quilted"
                                    cols={4}
                                    rowHeight={121}
                                    onClick={()=>{handleToggleFotos(post.fotos)}}
                                >
                                    {
                                        getFormatoVariasImagenes(post.fotos).map((item) => (
                                            <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1} >
                                                <img
                                                    {...srcset(item.img, 121, item.rows, item.cols)}
                                                    alt={item.title}
                                                    loading="lazy"
                                                    // onClick={()=>{handleToggle(item.img)}}
                                                />
                                            </ImageListItem>
                                        ))
                                    }
                                </ImageList>
                            </Box>
                        ) : (
                            <CardMedia
                                component="img"
                                // height="194"
                                image={post.fotos[0].url}
                                alt="Paella dish"
                                sx={{
                                    minHeight:'194',
                                }}
                                onClick={()=>{handleToggleFotos(post.fotos)}}
                            />
                        )
                    )
                }
                {
                    post.texto && (
                        <CardContent>
                            <Typography variant="body2" color="text.secondary">
                                { post.texto }
                            </Typography>
                        </CardContent>
                    )
                }                                            
                <CardActions disableSpacing>
                    <IconButton aria-label="add to favorites">
                        <FavoriteIcon />
                    </IconButton>
                    {/* <IconButton aria-label="share">
                        <ShareIcon />
                    </IconButton> */}
                    {/* <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </ExpandMore> */}
                </CardActions>
            </Card>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openFotos}
                onClick={handleCloseFotos}
            >
                <Box sx={{ width:'100%', }}>
                    {/* {
                        fotoSelect ? (
                            <Box
                                component='img'
                                src={fotoSelect}
                                sx={{
                                    maxWidth:'100%',
                                    maxHeight:1000,
                                }}
                            />
                        ) : (
                            <CircularProgress color="inherit" />
                        )
                    } */}
                    {
                        fotosSelect.length > 0 ? (
                            <Carousel
                                indicators={false}
                                autoPlay={false}
                                sx={{ 
                                    width:'100%', 
                                    // height:1000, 
                                    
                                }}
                            >
                                {
                                    fotosSelect.map((foto)=>(
                                        <Box
                                            sx={{
                                                width:'100%',
                                                display:'flex', 
                                                alignItems:'center', 
                                                alignContent:'center',
                                            }}
                                        >
                                            <Box
                                                component='img'
                                                src={foto.url}
                                                sx={{
                                                    m:'0 auto',
                                                    maxWidth:'100%',
                                                    maxHeight:900,
                                                }}
                                            />
                                        </Box>
                                    ))
                                }
                            </Carousel>
                        ) : (
                            <CircularProgress color="inherit" />
                        )
                    }
                            
                        
                </Box>
                {/* <CircularProgress color="inherit" /> */}
            </Backdrop>
            <EditarPost
                // selectedValue={selectedValue}
                open={editar}
                onClose={handleCloseEditar}
                postId={idEditar}
            />
        </Box>
    );
}

export { ItemListaPosts }