import React from "react";
import { Box, Container } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CustomTypography } from "../../../components/CustomTypography";
import { AdvantagesContainer } from "../../../components/AdvantagesContainer";
import theme from "../../../App/theme";

const sectionStyles = {
  backgroundColor: theme.palette.grey[100],
  py: { xs: "2.5rem", md: "5rem" },
};

const containerStyles = {
  display: "flex",
  textAlign: "center",
  flexDirection: "column",
  gap: 3.75,
};

const AdvantagesSection = () => {
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const advantagesList = [
    {
      title: "Experiencia",
      description: (
        <CustomTypography variant="textPrimary">
          Invertir con nosotros significa aprovechar nuestra{" "}
          <CustomTypography variant="textPrimaryBold">
            amplia experiencia en el mercado inmobiliario.
          </CustomTypography>
        </CustomTypography>
      ),
    },

    {
      title: "Ofertas Exclusivas",
      description: (
        <CustomTypography variant="textPrimary">
          En{" "}
          <CustomTypography
            variant="textPrimaryBold"
            txtColor={isDesktop ? theme.palette.common.white2 : "primary"}
          >
            Casas Pecsa
          </CustomTypography>
          , accede a propiedades exclusivas que no encontrarás en otro lugar.
        </CustomTypography>
      ),
    },

    {
      title: "Asesoramiento a Medida",
      description: (
        <CustomTypography variant="textPrimary">
          Nuestro equipo{" "}
          <CustomTypography variant="textPrimaryBold">
            adapta tus inversiones a tus objetivos y necesidades específicas{" "}
          </CustomTypography>
          para alcanzar tus metas financieras.
        </CustomTypography>
      ),
    },

    {
      title: "Transparencia y Confianza",
      description: (
        <CustomTypography variant="textPrimary">
          En{" "}
          <CustomTypography
            variant="textPrimaryBold"
            txtColor={isDesktop ? theme.palette.common.white2 : "primary"}
          >
            Casas Pecsa
          </CustomTypography>
          , creemos en la transparencia y construimos relaciones basadas en la
          confianza. Te mantenemos informado en cada etapa del proceso.
        </CustomTypography>
      ),
    },
  ];

  return (
    <Box component="section" sx={sectionStyles}>
      <Container sx={containerStyles}>
        <CustomTypography variant="subheadingPrimary" txtColor="primary">
          Ventajas de Invertir en Casas Pecsa
        </CustomTypography>

        <AdvantagesContainer
          advantagesList={advantagesList}
          isDesktop={isDesktop}
        />
      </Container>
    </Box>
  );
};

export { AdvantagesSection };
