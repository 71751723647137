import { Checkbox } from "@mui/material";
import { useState } from "react";
import { updateInversion, updateInversionCliente } from "../../../functions/ListasClientes";

const CheckboxInversion = ({ transaccion, handleChangeProp, inversion, cliente }) => {
    const [checked, setChecked] = useState(inversion);
    const handleChange = (event) => {
        setChecked(event.target.checked);
        if(cliente){
            updateInversionCliente(transaccion.id, event.target.checked)
        }else{
            updateInversion(transaccion.id, event.target.checked)
        }        
        // console.log(transaccion)
        // console.log({ ...transaccion, separado:{ ...transaccion.separado, inversion:event.target.checked } })
        if(handleChangeProp){
            handleChangeProp({ ...transaccion, separado:{ ...transaccion.separado, inversion:event.target.checked } })
        }        
    };
    
    return(
        <Checkbox  
            checked={checked}
            onChange={handleChange}
        />
    );
}

export { CheckboxInversion }