import React from "react";
import { Box, Container } from "@mui/material";
import { CustomTypography } from "../../../components/CustomTypography";
import { CustomButton } from "../../../components/CustomButton";
import theme from "../../../App/theme";
import { useNavigate } from "react-router-dom";

const CTASection = ({ activeButtonsCount, idLotes, inversionInicial }) => {
	let ctaMessage = "";
	let isButtonDisabled = false;
	
	let navigate = useNavigate();

  const navigateToPage = (url) => {
    navigate(url)
  }

	if (activeButtonsCount === 1) {
    ctaMessage = "Puedes seleccionar hasta 2 propiedades más para comparar";
		isButtonDisabled = true;
  } else if (activeButtonsCount === 2) {
    ctaMessage = "Puedes comparar o seleccionar 1 más";
  } else if (activeButtonsCount === 3) {
    ctaMessage = "Compara tus opciones de inversión";
  }

	const handleButtonClick = () => {
		let lotes = "";
		idLotes.forEach((element, index) => {
			lotes += `lote${index+1}=${element}&`			
		});
		let parametro = inversionInicial ? `inversionInicial=${inversionInicial}` : "";
		navigateToPage(`/invierte/comparador?${lotes}${parametro}`)
	}

	return (
		<Box
			component="section"
			sx={{
				backgroundColor: theme.palette.common.white,
				py: "1.25rem",
				position: "sticky",
				bottom: 0,
				left: 0,
				right: 0,
			}}
		>
			<Container 
				sx={{
					display: "flex",
					textAlign: "center",
					flexDirection: "column",
					gap: 3.75
				}}
			>
				<Box>
					<CustomTypography
						variant="subheadingSecondary"
					>
						{ctaMessage}
					</CustomTypography>
				</Box>
				<Box>
					<CustomButton
						variant="contained"
						size="small"
						text="Comparar"
						color="primary"
						disabled={isButtonDisabled}
						onClick={handleButtonClick}
					></CustomButton>
				</Box>
			</Container>
		</Box>
	)
}

export { CTASection }