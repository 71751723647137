import { CardActionArea, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";

import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

//import m106 from '../assets/casas/m106.png';
import m106 from '../../assets/casas/m106.png';
import m122_a from '../../assets/casas/m122_a.png';
import m122_b from '../../assets/casas/m122_b.png';
import m146_1 from '../../assets/casas/m146_1.png';
import m146_6 from '../../assets/casas/m146_6.png';

import ms1 from '../../assets/modelossimilares/ms1.png';
import  modelonotfound  from '../../assets/modelonotfound.png'


const LotesCarruselItem = ({ lote }) => {
    // console.log('---LotesCarruselItem', lote);
    let navigate = useNavigate();
    return(
        <React.Fragment>
            <Card 
                sx={{
                    width:'100%',
                    display: { xs: 'none', md: 'flex' },
                    borderRadius: '16px',
                    boxShadow: '0px 5px 12px 0px rgb(0, 0, 0, 0.19)',
                    mt:5,
                    mb:5,
                }}
            >
                <CardActionArea
                    onClick={() => navigate(`/tienda/${lote.sku.replace(/ /g, "_")}`)}
                >
                    {
                        lote.fotos && (
                            <CardMedia
                                component="img"
                                height="360"
                                image={lote.fotos[0].url}
                                alt="green iguana"
                            />
                        )
                    }
                    
                    
                    <CardContent>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                            //spacing={2}                    
                            
                        >
                            <Typography gutterBottom variant="h5" component="div">
                                MODELO <b>{lote.fachada.modelo.nombre}</b>
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Stack direction="column"
                                        justifyContent="center"
                                        alignItems="flex-start"
                                        sx={{
                                            
                                        }}
                                    >
                                        <Typography  variant="body1" color="text.secondary">
                                            Fraccionamiento: <b>{lote.manzana.etapa.fraccionamiento.nombre}</b>
                                        </Typography>
                                        <Typography variant="body1" color="text.secondary">
                                            Manzana: <b>{lote.numeroDeLote}</b>
                                        </Typography>
                                        <Typography variant="body1" color="text.secondary">
                                            Lote: <b>{lote.manzana.nombre}</b>
                                        </Typography>
                                    </Stack>
                                </Grid>
                                {/* <Grid item xs={4}>
                                    <Button size="small" color="error" endIcon={<ArrowForwardIcon />}>
                                        VER MÁS
                                    </Button>
                                </Grid> */}
                            </Grid>
                        </Stack>
                    </CardContent>
                    <CardActions>
                        <Box
                            sx={{ 
                                // backgroundColor:'green',
                                width:'100%',
                                textAlign:'center',
                            }}
                        >
                            <Button 
                                size="small" 
                                endIcon={<ArrowForwardIcon />} 
                                sx={{ color:'#f5323f', textTransform: 'none'  }}
                                //onClick={() => navigate(`/modelo/${modelo.nombre}`, {state:{modelo:modelo}})}
                            >
                                <b>Ver más</b>
                            </Button>
                        </Box>
                    </CardActions>
                </CardActionArea>
            </Card>
            <Card 
                sx={{
                    width:'100%',
                    display: { xs: 'flex', md: 'none' },
                    m:1,
                    borderRadius: '16px',
                    // boxShadow: 8,
                    // boxShadow: '0 10px 5px 0 rgba(0, 0, 0, 0.12), 0 4px 10px 0 rgba(0, 0, 0, 0.19)',
                    boxShadow: '0px 5px 12px 0px rgb(0, 0, 0, 0.19)',
                    mt:2,
                    ml:2,
                    mb:3,
                }}
            >
                <CardActionArea 
                    onClick={() => navigate(`/tienda/${lote.sku.replace(/ /g, "_")}`)}
                >
                    <CardMedia
                        component="img"
                        height="200"
                        image={lote.fotos[0].url}
                        alt="green iguana"
                    />
                    <CardContent>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                            //spacing={2}                    
                            
                        >
                            <Typography gutterBottom variant="h6" component="div">
                                MODELO <b>{lote.fachada.modelo.nombre}</b>
                            </Typography>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Stack direction="column"
                                        justifyContent="center"
                                        alignItems="flex-start"
                                        sx={{
                                            marginLeft:1
                                        }}
                                    >
                                        <Typography  variant="body2" color="text.secondary">
                                            Fraccionamiento: <b>{lote.manzana.etapa.fraccionamiento.nombre}</b>
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Manzana: <b>{lote.numeroDeLote}</b>
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Lote: <b>{lote.manzana.nombre}</b>
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    </CardContent>
                </CardActionArea>
            </Card>
        </React.Fragment>
    );
};

export { LotesCarruselItem };