import { Box, Stack, Typography } from "@mui/material";

const Garantia = (props) => {
    
    return(
        <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
                // backgroundColor:'red',
                color:'#707070',
                width:{ xs: '100%', sm:'185px', md:'360px',  },
                ml:1.5,
                mr:1.5,
            }}
        >
            <Box
                sx={{
                    // backgroundColor:'yellowgreen',
                    width:'100%',
                    height:{xs:200, sm:100, md:150 },
                    display:'flex',
                    alignItems:'flex-start'
                }}
            >
                <Box
                    component="img"
                    src={props.garantia.img}
                    sx={{
                        maxWidth: { xs: '70%', sm: '45%', md: '40%' },
                        // width:'20px',
                        m:'0 auto',
                    }}
                />
            </Box>
            <Box
                sx={{
                    // backgroundColor:'yello',
                    minHeight:{xs:100, sm:100, md:150 },
                    textAlign:'center'
                }}
            >
                <Typography variant="p" component="div" sx={{ m:1 }}>
                    <b>{ props.garantia.name }</b>
                </Typography>
                <Typography variant="P" component="div" sx={{ ml: { xs: 1, sm: 2, md: 6 }, mr: { xs: 1, sm: 2, md: 6 }, fontSize: { xs: 11, sm: 11, md: 13 } }}>
                    { props.garantia.desc }
                </Typography>
            </Box>
            
        </Stack>
    );
}

export { Garantia }