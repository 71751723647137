import { Button, Container, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Amenidades } from "./Amenidades";
import { Modelo } from "./Modelo";
import { Portada } from "./Portada";

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { InlineWidget } from "react-calendly";
import { useState } from "react";
import { Experiencia } from "./Experiencia";
import { Helmet } from "react-helmet";
import { Ubicacion } from "./Ubicacion";
import { MyCalendlyComponent } from "./MyCalendlyComponent";
import { Formulario } from "./Formulario";
import { OchoRazones } from "../../../components/OchoRazones";
import { Garantias } from "../../../components/Garantias";
import TagManager from "react-gtm-module";
import banorte from '../../../assets/solicitacredito/banorte.svg';
import bbva from '../../../assets/solicitacredito/bbva.svg';
import fobissste from '../../../assets/solicitacredito/fobissste.svg';
import HSBC from '../../../assets/solicitacredito/HSBC.svg';
import infonavit from '../../../assets/solicitacredito/infonavit.svg';
import scotiabank from '../../../assets/solicitacredito/scotiabank.svg';
import { PopupFoto } from "../../../components/Popups/PopupFoto";
import { BannerAnuncio } from "./BannerAnuncio";

const bancosPreautorizan = [
    { nombre:'Banorte', imagen:banorte, },
    { nombre:'BBVA', imagen:bbva, },
    // { nombre:'Fobissste', imagen:fobissste, },
    { nombre:'HSBC', imagen:HSBC, },
    // { nombre:'Infonavit', imagen:infonavit, },
    { nombre:'ScotiaBank', imagen:scotiabank, },
]

const otrosPreautorizan = [
    { nombre:'Fobissste', imagen:fobissste, },
    { nombre:'Infonavit', imagen:infonavit, },
    { nombre:'Cofinavit', imagen:'https://www.torreasesores.com/wp-content/uploads/2015/06/Cofinavit-vector-logo-e1434046485409.png', },
    { nombre:'Unamos Créditos', imagen:'https://revistaespacios.mx/wp-content/uploads/2020/07/imagen.png', },
    { nombre:'Apoyo Infonavit', imagen:'https://seeklogo.com/images/A/apoyo-infonavit-logo-8C486CA90D-seeklogo.com.png', },
    // { nombre:'ScotiaBank', imagen:scotiabank, },
]

const tagManagerArgs = {
    // gtmId: 'G-4YSMCL1D97',
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_BOSQUES
    // dataLayer: {
    //     userId: '001',
    //     userProject: 'project'
    // }
}

TagManager.initialize(tagManagerArgs)
const fraccionamiento = { 
    modelos:[
        {
            nombre:'T-102',
            precio: '$1,711,511.00',
            foto:'https://d3ihta9hbp4hol.cloudfront.net/Fachadas/3/A/FACHADA-A-2019-1.jpg',
            plantas:[
                'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-102/Plantas/plantabaja.png',
                'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-102/Plantas/plantaalta.png',
            ],
            caracteristicas: [
                '102 m² de construcción',
                'Cochera',
                '2 Habitaciones',
                '2 Baños',
            ],
            interiores: {
                total:1420,
                items: [
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-102/Interiores/102_1.jpg',
                        title: 'Breakfast',
                        rows: 2,
                        cols: 2,
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-102/Interiores/102_2.jpg',
                        title: 'Burger',
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-102/Interiores/102_3.jpg',
                        title: 'Camera',
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-102/Interiores/102_4.jpg',
                        title: 'Coffee',
                        cols: 2,
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-102/Interiores/102_5.jpg',
                        title: 'Hats',
                        cols: 2,
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-102/Interiores/102_6.jpg',
                        title: 'Honey',
                        rows: 2,
                        cols: 2,
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-102/Interiores/102_7.jpg',
                        title: 'Burger',
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-102/Interiores/102_8.jpg',
                        title: 'Camera',
                    },
                    // {
                    //     img: interior1069,
                    //     title: 'Coffee',
                    //     cols: 2,
                    // },
                    // {
                    //     img: interior10610,
                    //     title: 'Hats',
                    //     cols: 2,
                    // },
                ]
            }
        },
        {
            nombre:'T-146',
            precio: '$2,110,799.00',
            foto:'https://d3ihta9hbp4hol.cloudfront.net/Fachadas/2/A%20%28Bosques%20de%20las%20Lomas%29/146_A.png',
            plantas:[
                'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-146/Plantas/plantaalta.png',
                'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-146/Plantas/plantabaja.png',
            ],
            caracteristicas: [
                '146 m² de construcción',
                'Cochera 2 Vehiculos',
                '3 Habitaciones',
                '2 Baños',
            ],
            interiores: {
                total:1060,
                items: [
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-146/Interiores/LomasDelMorillo3.jpg',
                        title: 'Breakfast',
                        rows: 2,
                        cols: 2,
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-146/Interiores/LomasDelMorillo4.jpg',
                        title: 'Burger',
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-146/Interiores/LomasDelMorillo2.jpg',
                        title: 'Camera',
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-146/Interiores/LomasDelMorillo1-4.jpg',
                        title: 'Coffee',
                        cols: 2,
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-146/Interiores/LomasDelMorillo1-3.jpg',
                        title: 'Hats',
                        cols: 2,
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-146/Interiores/LomasDelMorillo5.jpg',
                        title: 'Honey',
                        rows: 2,
                        cols: 2,
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-146/Interiores/LomasDelMorillo6.jpg',
                        title: 'Burger',
                    }, 
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-146/Interiores/LomasDelMorillo10.jpg',
                        title: 'Burger',
                    },    
                ],
            }
        },
    ],
    amenidades:[
        {
            nombre:'Palapa',
            texto:'En nuestra palapa, encontrarás todo lo necesario para disfrutar de un día al aire libre, incluyendo mesas y bancas, asadores y un espacio para cocinar ,es perfecta para disfrutar del atardecer o simplemente relajarse y desconectar del ajetreo de la vida diaria con tu familia y amigos.',
            foto:'https://d3ihta9hbp4hol.cloudfront.net/Amenidades/4/Palapa/palapa+%281%29.png',
        },
        {
            nombre:'Plaza',
            texto:'Nuestra plaza es un espacio al aire libre diseñado para la comunidad, donde los residentes pueden disfrutar de actividades al exterior, hacer ejercicio, relajarse, jugar y socializar en un entorno natural y seguro. Además podrás divertirte con tu mascota en un espacio exclusivo diseñado para ellos.',
            foto:'https://d3ihta9hbp4hol.cloudfront.net/Amenidades/4/Plaza/JUEGITOS_2.png',
        },
        {
            nombre:'Cancha',
            texto:'Nuestra cancha multiusos es el lugar perfecto para practicar deportes y actividades al aire libre en un ambiente seguro y tranquilo, con espacio suficiente para jugar al fútbol, baloncesto, voleibol y otros deportes.',
            foto:'https://d3ihta9hbp4hol.cloudfront.net/Amenidades/4/Cancha/cancha_2.png',
        },
    ]
}

const BosquesLandingPage = () => {
    const [open, setOpen] = useState(false);
    const getOpen = () => {
        setOpen(!open)
    }
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    
    return(
        <Box
            sx={{
                mt:{ xs:7, sm:8, md:10 }
            }}
        >
            <Helmet>
                <title>Bosques de las Lomas Residencial | Casas Pecsa</title>
                <meta name="description" content="Bosques de las Lomas te ofrece privacidad, exclusividad y la vista mas espectacular de la ciudad. Con un conjunto de asombrosas amenidades, amplios espacios y acabados inigualables. A solo minutos de las vías mas rápidas de la ciudad con un entorno natural único y una vista panorámica impresionante al sur de la ciudad." />
            </Helmet>
            <Portada getOpen={getOpen} />
            {/* <BannerAnuncio/> */}
            {/* <Formulario getOpen={getOpen} /> */}
            {/* <Container>
                <Box
                    sx={{
                        width:'100%',
                        textAlign:'center',
                        color:'#7c4c1c',
                        mt:4,
                        mb:2,
                    }}
                >
                    <Typography variant="h3" sx={{ fontFamily:'Montserrat', }}>
                        <b>Modelos Disponibles</b>
                    </Typography>
                </Box>
            </Container> */}
            {
                fraccionamiento.modelos.map((modelo) => (
                    <Modelo modelo={modelo} getOpen={getOpen} />
                ))
            }
            {/* <Experiencia/> */}
            <Container>
                <Box
                    sx={{
                        width:'100%',
                        textAlign:'center',
                        color:'#7C4C1C',
                        mt:4,
                        mb:2,
                    }}
                >
                    <OchoRazones titulo="¿Porque comprar una Casa Pecsa?" color="#7C4C1C"/>
                </Box>
            </Container>
            <Box
                sx={{
                    backgroundColor:'#E6BC92',
                    width:'100%',
                    minHeight:400,
                    textAlign:'center',
                    color:'#7C4C1C',
                    mt:4,
                    mb:2,
                }}
            >
                <Container>
                    <Box
                        sx={{
                            width:'100%',
                            textAlign:'center',
                            color:'#7C4C1C',
                            mt:4,
                            mb:2,
                        }}
                    >
                        <Garantias color="#7C4C1C" align="center" />
                    </Box>
                </Container>
            </Box>
            <Box>
                <Box
                    sx={{
                        textAlign:'center',
                        color:'#69542c',
                    }}
                >
                    <Typography variant="h3" sx={{ fontFamily:'Montserrat', }}>
                        <b>Te ayudamos a encontrar la mejor opción de crédito para ti</b>
                    </Typography>
                </Box>
                <Box
                    sx={{
                        // mb:2,
                    }}
                >
                    <Box
                        sx={{
                            width:'100%',
                            textAlign:'center',
                            color:'#69542c',
                            mt:4,
                            mb:2,
                            
                        }}
                    >
                        <Typography variant="h5" sx={{ fontFamily:'Montserrat', }}>
                            <b>Créditos bancarios que puedes utilizar</b>
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            mb:2,
                        }}
                    >
                        <Grid 
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {
                                bancosPreautorizan.map((banco) => (
                                    <Grid item xs={4} sm={2} md={2} key={banco.nombre}>
                                        <Box
                                            sx={{
                                                // backgroundColor:'red',
                                                width:'100%',
                                                height:100,
                                                display:'flex',
                                                alignItems:'center',
                                                alignContent:'center',
                                                mb:2,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    // backgroundColor:'yellow',
                                                    width:'70%',
                                                    maxHeight:'70%',
                                                    m:'0 auto',
                                                }}
                                            >
                                                <Box
                                                    component='img'
                                                    src={banco.imagen}
                                                    sx={{
                                                        maxWidth:'100%',
                                                        maxHeight:70
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))
                            }
                            
                        </Grid>
                    </Box>
                </Box>
                <Box
                    sx={{
                        // mb:2,
                    }}
                >
                    <Box
                        sx={{
                            width:'100%',
                            textAlign:'center',
                            color:'#69542c',
                            mt:4,
                            mb:2,
                            
                        }}
                    >
                        <Typography variant="h5" sx={{ fontFamily:'Montserrat', }}>
                            <b>Otros tipos de créditos</b>
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            mb:2,
                        }}
                    >
                        <Grid 
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {
                                otrosPreautorizan.map((banco) => (
                                    <Grid item xs={4} sm={2} md={2} key={banco.nombre}>
                                        <Box
                                            sx={{
                                                // backgroundColor:'red',
                                                width:'100%',
                                                height:100,
                                                display:'flex',
                                                alignItems:'center',
                                                alignContent:'center',
                                                mb:2,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    // backgroundColor:'yellow',
                                                    width:'70%',
                                                    maxHeight:'70%',
                                                    m:'0 auto',
                                                }}
                                            >
                                                <Box
                                                    component='img'
                                                    src={banco.imagen}
                                                    sx={{
                                                        maxWidth:'100%',
                                                        maxHeight:70
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))
                            }
                            
                        </Grid>
                    </Box>
                </Box>
            </Box>
            <MyCalendlyComponent/>
            <Ubicacion/>            
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth="lg"
                fullScreen={fullScreen}
                sx={{
                    
                }}
                onClose={getOpen}
            >                
                <Box
                    sx={{
                        // backgroundColor:'red',
                        width:'100%',
                        height:{ xs:900, sm:900, md:810 }
                    }}
                >
                    <InlineWidget url="https://calendly.com/casas-pecsa/bosquesdelaslomas?hide_landing_page_details=1&hide_gdpr_banner=1&text_color=2a1a09&primary_color=a66626" styles={{ width:'100%', height:800, }} />
                </Box>
                <DialogActions>
                    <Button 
                        autoFocus 
                        sx={{
                            // textTransform:'none',
                            color:'#a66626',
                            '&:hover':{
                                backgroundColor:'#e6bc92',

                            }
                        }}
                        onClick={getOpen}
                    >
                        <b>Cancelar</b>
                    </Button>
                </DialogActions>                
            </Dialog>
            {/* <PopupFoto/> */}
        </Box>
    );
}

export { BosquesLandingPage }