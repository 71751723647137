import React from "react";
import { Box, Container } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AdvantagesContainer } from "../../../components/AdvantagesContainer";
import { CustomTypography } from "../../../components/CustomTypography";
import theme from "../../../App/theme";

const sectionStyles = {
  backgroundColor: theme.palette.grey[100],
  py: { xs: "2.5rem", md: "5rem" },
};

const containerStyles = {
  display: "flex",
  textAlign: "center",
  flexDirection: "column",
  gap: 3.75,
};

const AdvantagesSection = () => {
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const advantagesList = [
    {
      title: "Ofertas Exclusivas",
      description: (
        <CustomTypography variant="textPrimary">
          En{" "}
          <CustomTypography
            variant="textPrimaryBold"
            txtColor={isDesktop ? theme.palette.common.white2 : "primary"}
          >
            Casas Pecsa
          </CustomTypography>
          , accede a propiedades exclusivas que no encontrarás en otro lugar.
        </CustomTypography>
      ),
    },
    {
      title: "Seguridad y Tranquilidad",
      description: (
        <CustomTypography variant="textPrimary">
          Nuestras casas están diseñadas para proporcionar un{" "}
          <CustomTypography variant="textPrimaryBold">
            ambiente seguro y tranquilo
          </CustomTypography>
          , ideal para descansar y relajarte.
        </CustomTypography>
      ),
    },
    {
      title: "Fácil Reserva en Línea",
      description: (
        <CustomTypography variant="textPrimary">
          Reserva tu casa en casa de manera sencilla y segura en línea, sin
          complicaciones ni demoras.
        </CustomTypography>
      ),
    },
    {
      title: "Ideal para Familias",
      description: (
        <CustomTypography variant="textPrimary">
          Nuestras casas son ideales para{" "}
          <CustomTypography variant="textPrimaryBold">
            familias y grupos, con suficiente espacio para todos y áreas comunes{" "}
          </CustomTypography>
          para pasar tiempo juntos.
        </CustomTypography>
      ),
    },
  ];

  return (
    <Box component="section" sx={sectionStyles}>
      <Container sx={containerStyles}>
        <CustomTypography variant="subheadingPrimary" txtColor="primary">
          Ventajas de Rentar en Casas Pecsa
        </CustomTypography>

        <AdvantagesContainer
          advantagesList={advantagesList}
          isDesktop={isDesktop}
        />
      </Container>
    </Box>
  );
};

export { AdvantagesSection };
