import { Alert, CircularProgress, FormControlLabel, Grid, IconButton, Paper, Snackbar, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { CheckboxInversion } from "../../../components/ListasClientes/Lista/CheckboxInversion";
import { useNavigate, useParams } from "react-router-dom";
import { InputButtonCombo } from "../../../components/Investment/InputButtonCombo";
import { CustomTypography } from "../../../components/CustomTypography";
import theme from "../../../App/theme";
import { updateInversionCliente } from "../../../functions/ListasClientes";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Helmet } from "react-helmet";

const makeLote = (transaccion) => {
    return`Lote:${transaccion.lote.numeroDeLote}, Mzna:${transaccion.lote.manzana.nombre}, ${transaccion.lote.manzana.etapa.fraccionamiento.nombre}, Modelo ${transaccion.lote.fachada.modelo.nombre} `;
};
const TablaInteresados = ({ interesados = [], title }) => {
    const themeForSize = useTheme();
    const small = useMediaQuery(themeForSize.breakpoints.down('md'));

    return(
        <Box>
            <CustomTypography variant="textPrimaryBold" txtColor={theme.palette.primary.main}>
                { title }
            </CustomTypography>
            <TableContainer component={Paper}>
                {
                    small ? (
                        <Table sx={{  }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell align="left" sx={{ color:'#707070' }}><b>Nombre</b></TableCell> */}
                                    <TableCell align="center" sx={{ color:'#707070' }}><b>Datos</b></TableCell>
                                    {/* <TableCell align="center" sx={{ color:'#707070' }}><b>Email</b></TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    interesados.length <= 0 && (
                                        <TableRow
                                        //key={transaccion.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, }}
                                        >                                        
                                            <TableCell align="center" sx={{ color:'#707070' }} colSpan={4}>
                                                <h2>No hay personas interesadas aun</h2>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                                {
                                    interesados.map((interesado)=> (
                                        <TableRow>
                                            <TableCell component="th" scope="row" align="left" sx={{ color:'#707070' }}>
                                                <Grid container>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <Typography sx={{ fontSize:12, }}>
                                                            <b>Nombre:</b>
                                                        </Typography>
                                                        <Typography sx={{ fontSize:15 }} >
                                                            <b>{`${interesado.cliente.datos.primerNombre} ${interesado.cliente.datos.segundoNombre ? interesado.cliente.datos.segundoNombre : ''} ${interesado.cliente.datos.primerApellido} ${interesado.cliente.datos.segundoApellido}`}</b>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <Typography sx={{ fontSize:12, }}>
                                                            <b>Telefono:</b>
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                // backgroundColor:'pink',
                                                                display:'flex',
                                                                alignItems:'center',
                                                                // justifyContent:'flex-end',
                                                            }}
                                                        >
                                                            <Typography 
                                                                sx={{
                                                                    // backgroundColor:'greenyellow',
                                                                    color:'#F5323F',
                                                                    fontSize:14,
                                                                    '&:hover': {
                                                                        backgroundColor:'#F8F8F8',
                                                                    },
                                                                    display:'flex',
                                                                    alignItems:'center',
                                                                    cursor:'pointer',
                                                                }}
                                                                onClick={()=>reedirigirALlamada(interesado.cliente.autenticacion.telefono)}
                                                            >
                                                                {/* <SmartphoneIcon sx={{ fontSize:15 }}/> */}
                                                                <b>{`${interesado.cliente.autenticacion.telefono}`}</b>
                                                            </Typography>
                                                            <IconButton 
                                                                aria-label="delete" 
                                                                size="small" 
                                                                onClick={() => {navigator.clipboard.writeText(interesado.cliente.autenticacion.telefono)}}
                                                            >
                                                                <ContentCopyIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <Typography sx={{ fontSize:12, }}>
                                                            <b>Correo:</b>
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                // backgroundColor:'pink',
                                                                display:'flex',
                                                                alignItems:'center',
                                                                // justifyContent:'flex-end',
                                                            }}
                                                        >                                                                        
                                                            <Typography 
                                                                sx={{
                                                                    // backgroundColor:'greenyellow',
                                                                    color:'#F5323F',
                                                                    fontSize:14,
                                                                    '&:hover': {
                                                                        backgroundColor:'#F8F8F8',
                                                                    },
                                                                    display:'flex',
                                                                    alignItems:'center',
                                                                    cursor:'pointer',
                                                                }}
                                                                onClick={()=>reedirigirAEmail(interesado.cliente.autenticacion.email)}
                                                            >
                                                                {/* <EmailOutlinedIcon sx={{ fontSize:15 }}/> */}
                                                                <b>{`${interesado.cliente.autenticacion.email}`}</b>
                                                            </Typography>
                                                            <IconButton 
                                                                aria-label="delete" 
                                                                size="small" 
                                                                onClick={() => {navigator.clipboard.writeText(interesado.cliente.autenticacion.email)}}
                                                            >
                                                                <ContentCopyIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Box>
                                                    </Grid>
                                                </Grid>                                                                            
                                            </TableCell>                                                                        
                                        </TableRow>
                                    ))
                                }
                                <TableRow>

                                </TableRow>
                            </TableBody>
                        </Table>
                    ) : (
                        <Table sx={{  }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" sx={{ color:'#707070' }}><b>Nombre</b></TableCell>
                                    <TableCell align="center" sx={{ color:'#707070' }}><b>Telefono</b></TableCell>
                                    <TableCell align="center" sx={{ color:'#707070' }}><b>Email</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    interesados.length <= 0 && (
                                        <TableRow
                                        //key={transaccion.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, }}
                                        >                                        
                                            <TableCell align="center" sx={{ color:'#707070' }} colSpan={4}>
                                                <h2>No hay personas interesadas aun</h2>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                                {
                                    interesados.map((interesado)=> (
                                        <TableRow>
                                            <TableCell component="th" scope="row" align="left" sx={{ color:'#707070' }}>
                                                <Typography sx={{ fontSize:13 }} >
                                                    <b>{`${interesado.cliente.datos.primerNombre} ${interesado.cliente.datos.segundoNombre ? interesado.cliente.datos.segundoNombre : ''} ${interesado.cliente.datos.primerApellido} ${interesado.cliente.datos.segundoApellido}`}</b>
                                                </Typography>                                                                    
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="center" sx={{ color:'#707070' }}>
                                                <Box
                                                    sx={{
                                                        // backgroundColor:'pink',
                                                        display:'flex',
                                                        alignItems:'center',
                                                    }}
                                                >                                                                        
                                                    <Typography 
                                                        sx={{
                                                            // backgroundColor:'greenyellow',
                                                            fontSize:14,
                                                            '&:hover': {
                                                                backgroundColor:'#F8F8F8',
                                                            },
                                                            display:'flex',
                                                            alignItems:'center',
                                                            cursor:'pointer',
                                                        }}
                                                        onClick={()=>reedirigirALlamada(interesado.cliente.autenticacion.telefono)}
                                                    >
                                                        {/* <SmartphoneIcon sx={{ fontSize:15 }}/> */}
                                                        <b>{`${interesado.cliente.autenticacion.telefono}`}</b>
                                                    </Typography>
                                                    <Tooltip title="Copiar">
                                                        <IconButton 
                                                            aria-label="Copiar" 
                                                            size="small" 
                                                            onClick={() => {navigator.clipboard.writeText(interesado.cliente.autenticacion.telefono)}}
                                                        >
                                                            <ContentCopyIcon fontSize="inherit" />
                                                        </IconButton>
                                                    </Tooltip>                                                                                
                                                </Box>
                                            </TableCell>
                                            <TableCell component="th" scope="row" align="left" sx={{ color:'#707070' }}>
                                                <Box
                                                    sx={{
                                                        // backgroundColor:'pink',
                                                        display:'flex',
                                                        alignItems:'center',
                                                    }}
                                                >                                                                        
                                                    <Typography 
                                                        sx={{
                                                            // backgroundColor:'greenyellow',
                                                            fontSize:14,
                                                            '&:hover': {
                                                                backgroundColor:'#F8F8F8',
                                                            },
                                                            display:'flex',
                                                            alignItems:'center',
                                                            cursor:'pointer',
                                                        }}
                                                        onClick={()=>reedirigirAEmail(interesado.cliente.autenticacion.email)}
                                                    >
                                                        {/* <EmailOutlinedIcon sx={{ fontSize:15 }}/> */}
                                                        <b>{`${interesado.cliente.autenticacion.email}`}</b>
                                                    </Typography>
                                                    <Tooltip title="Copiar">
                                                        <IconButton 
                                                            aria-label="Copiar" 
                                                            size="small" 
                                                            onClick={() => {navigator.clipboard.writeText(interesado.cliente.autenticacion.email)}}
                                                        >
                                                            <ContentCopyIcon fontSize="inherit" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    
                                                </Box>                                                                    
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                                <TableRow>

                                </TableRow>
                            </TableBody>
                        </Table>
                    )
                }                                                    
            </TableContainer>                                        
        </Box>
    );
}
const TablaOtrosInteresados = ({ interesados }) => {
    const [otrosInteresados, setOtrosInteresados] = useState([]);

    const filter = (otrosInteresados, interesados) => {
        console.log(interesados);
        const result = otrosInteresados.filter(o => !interesados.some(inte => inte.cliente.autenticacion.email === o.cliente.autenticacion.email)); 
        // const result = arr2.filter(element => !arr.some(aElement => aElement.id === element.id))
        return result
    }
    useEffect(()=>{
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');
    
        axios.get(`${process.env.REACT_APP_API_URL}/api/lotes-inversion/otros-clientes-interesados`,{
            headers: { 
              Authorization: `Bearer ${token}`,
            }              
        })
        .then((response) => {
            // handle success
            // console.log('si o no',response);
            setOtrosInteresados(response.data);
            
        })
        .catch((error) => {
          // handle success
          console.log(error);
        })
        .then(() => {
          // handle success
          //console.log('sabe');
        });
    },[]);
    return(
        <Box sx={{ mt:2 }}>
            <TablaInteresados interesados={filter(otrosInteresados, interesados)} title="Otras personas interesadas en rentar" />
        </Box>
    );
}
const reedirigirALlamada = (tel) =>{
    window.location.href = `tel:+52 ${tel}`;
}
const reedirigirAEmail = (email) =>{
    window.location.href = `mailto:${email}`;
}
const SwitchInversion = ({ inversion, checked }) => {
    let navigate = useNavigate();
    const reedirigirSuccess = () => {
        // window.open('/profile/movimientos');
        navigate(0);
    }

    console.log('cable', inversion);
    
    const [available, setAvailable] = useState(checked);
    const getAvailable = (event) => {
        setAvailable(event.target.checked)
        updateInversionCliente(inversion.lote.transaccion.id, event.target.checked, reedirigirSuccess)
    }    
    //AGREGAR UNA FUNCION
    const handleChange = (transaccion, event) => {
        updateInversionCliente(transaccion.id, event.target.checked)
    }
    return(
        <>
            <FormControlLabel 
                control={
                    <Switch
                        color="success"
                        checked={available}
                        onChange={getAvailable}
                        sx={{
                            width: 62,
                            height: 44,
                            // backgroundColor:'pink',
                            // TAMAÑO DE LABEL Y SWITCH
                            '& .MuiSwitch-thumb':{
                                width: 26,
                                height: 26,
                            },
                            '& .MuiSwitch-track': {
                                // opacity: 1,
                                // backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
                                borderRadius: 20 / 2,
                            },
                        }}
                    />
                } 
                label={available ? "Habilitado" : "Deshabilitado"}
            />
        </>        
    );
}
const MisInversiones = () => {
    const { id } = useParams();

    const [open, setOpen] = useState(false);
    const [inversion, setInversion] = useState(null);
    const [loading, setLoading] = useState(true);

    const mostarAlert = () => {
        setOpen(true);
    };
    
    const cerrarAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
    };
    
    const changeValorRenta = (valorRenta) => {
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');
    
        axios.post(`${process.env.REACT_APP_API_URL}/api/lotes-inversion/update/${id}`,{
            valorRenta: valorRenta
        },{
            headers: { 
              Authorization: `Bearer ${token}`,
            }              
        })
        .then((response) => {
            // handle success
            console.log('Nuevo Valor renta???',response);
            mostarAlert();
        })
        .catch((error) => {
          // handle success
          console.log(error);
        })
        .then(() => {
          // handle success
          //console.log('sabe');
        });
    }

    useEffect(()=>{
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');
    
        axios.get(`${process.env.REACT_APP_API_URL}/api/lotes-inversion/${id}`,{
            headers: { 
              Authorization: `Bearer ${token}`,
            }              
        })
        .then((response) => {
            // handle success
            // console.log('si o no',response);
            setInversion(response.data);
            setLoading(false);
        })
        .catch((error) => {
          // handle success
          console.log(error);
          setLoading(false);
        })
        .then(() => {
          // handle success
          //console.log('sabe');
        });
    }, [id])
    return(
        <Box sx={{ minHeight:840 }}>
            <Helmet>
                <title>Mi Cuenta - Mis inversiones | Casas Pecsa</title>
                <meta name="description" content="Encuentra Casa en Saltillo, ¿No sabes cuál es tu mejor opción de crédito para comprar una casa? Nosotros te ayudamos. Agenda tu cita y obtén Asesoría Gratis." />
            </Helmet>
            <Container>
                <Box>
                    <Typography variant="h6" gutterBottom component="div" sx={{ color:'#F5323F', fontFamily:'Montserrat' }}>
                        <b>Mis Inversiones</b>
                    </Typography>
                </Box>
                {
                    loading && !inversion && (
                        <Box
                            sx={{
                                // backgroundColor:'pink',
                                width:'100%',
                                height:200,
                                display:'flex',
                                flexDirection:'column',
                                alignItems:'center',
                                justifyContent:'center',
                                color:'#707070'
                            }}
                        >
                            <CircularProgress sx={{ color:'#F5323F' }} />
                            <CustomTypography variant="textPrimaryBold" >
                                Cargando...
                            </CustomTypography>
                        </Box>
                    )
                }
                {
                    !loading && !inversion && (
                        <h1>No hay info</h1>
                        // agregar botones de volver a movimientos
                    )
                }
                {
                    inversion && (
                        <Box>
                            <Grid container>
                                <Grid item xs={12} sm={4} md={4}>
                                    <Box
                                        sx={{
                                            // backgroundColor:'pink',
                                            width:'100%',
                                            minHeight:250,
                                            display:'flex',
                                            alignItems:'center',
                                            justifyContent:'center',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                // backgroundColor:'greenyellow',
                                                width:'80%',
                                                height:200,                                        
                                                display:'flex',
                                                alignItems:'center',
                                                justifyContent:'center',
                                                overflow:'hidden',
                                                borderRadius:'12px',
                                            }}
                                        >
                                            <Box
                                                component="img"
                                                src={inversion.lote.fotos[0].url}
                                                sx={{
                                                    // // maxWidth:'100%',
                                                    // // // maxHeight:200,
                                                    // // borderRadius:'12px',
                                                    minWidth:'100%',
                                                    maxWidth:'150%',
                                                    minHeight:'100%',
                                                    maxHeight:'150%',

                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8}>
                                    <Box
                                        sx={{
                                            // backgroundColor:'pink',
                                            width:'100%',
                                            minHeight:250,
                                            display:'flex',
                                            alignItems:'center',
                                            justifyContent:'center',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                // backgroundColor:'greenyellow',
                                                width:'90%',
                                                minHeight:200,                                        
                                                display:'flex',
                                                flexDirection:'column',
                                                gap:1,
                                                // alignItems:'center',
                                                // justifyContent:'center',
                                                // overflow:'hidden',                                        
                                            }}
                                        >
                                            <CustomTypography variant="textPrimaryBold" txtColor={theme.palette.primary.main}>
                                                Datos
                                            </CustomTypography>
                                            <CustomTypography variant="textPrimaryBold" txtColor={theme.palette.grey[700]}>
                                                { `Modelo ${inversion.lote.fachada.modelo.nombre}` }
                                            </CustomTypography>
                                            <CustomTypography variant="textPrimary" txtColor={theme.palette.grey[700]}>
                                                { `Lote: ${inversion.lote.numeroDeLote} Manzana: ${inversion.lote.manzana.nombre}` }
                                            </CustomTypography>
                                            <CustomTypography variant="textPrimary" txtColor={theme.palette.grey[700]}>
                                                { `${inversion.lote.manzana.etapa.fraccionamiento.nombre}` }
                                            </CustomTypography>
                                            <Box
                                                sx={{
                                                    // backgroundColor:'burlywood',
                                                    width:'100%',
                                                    display:'flex',
                                                    flexDirection:'column',
                                                    gap:1,
                                                }}
                                            >
                                                <CustomTypography variant="textPrimaryBold" txtColor={theme.palette.primary.main}>
                                                    Datos de Renta
                                                </CustomTypography>
                                                <Grid container>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <Box>
                                                            <InputButtonCombo
                                                                initialValue={inversion.valorRenta ? inversion.valorRenta : null}
                                                                variantLabel="textPrimary" 
                                                                label={inversion.valorRenta ? "¿Deseas cambiar el valor de la renta?" : "Asignale un precio de renta a tu inversion"} 
                                                                buttonText={inversion.valorRenta ? "Cambiar" : "Agregar"}
                                                                handleClick={changeValorRenta}
                                                                disabled={inversion.activo ? false : true}
                                                            />
                                                            <CustomTypography variant="textSecondaryLight">
                                                                { `Precio de renta promedio: ${inversion.lote.caracteristicas[0].valorRenta}` }
                                                            </CustomTypography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <Box
                                                            sx={{
                                                                display:'flex',
                                                                flexDirection:'column',
                                                                mt:1,
                                                            }}
                                                        >
                                                            <CustomTypography variant="textPrimary" txtColor={theme.palette.grey[700]}>
                                                                {/* { `Precio de renta recomendado: ${inversion.lote.caracteristicas[0].valorRenta}` } */}
                                                                Habilitar lote para renta:
                                                            </CustomTypography>
                                                            <SwitchInversion inversion={inversion} checked={inversion.activo} />
                                                            {/* <Switch
                                                                color="success"
                                                                sx={{
                                                                    width: 62,
                                                                    height: 44,
                                                                    '& .MuiSwitch-thumb':{
                                                                        width: 26,
                                                                        height: 26,
                                                                    },
                                                                    '& .MuiSwitch-track': {
                                                                        // opacity: 1,
                                                                        // backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
                                                                        borderRadius: 20 / 2,
                                                                    },
                                                                }}
                                                            /> */}
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>                                    
                                    <TablaInteresados interesados={inversion.clientesInteresados} title="Interesados en tu casa" />
                                    <TablaOtrosInteresados interesados={inversion.clientesInteresados}/>                                    
                                </Grid>
                            </Grid>
                        </Box>
                    )
                }                            
                
            </Container>
            <Snackbar 
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} 
                open={open} 
                autoHideDuration={6000} 
                onClose={cerrarAlert} 
                sx={{ 
                    mb:{ xs:0, sm:30, md:10 },
                    ml:{ xs:0, sm:30, md:-14 },
                }}
            >
                <Alert onClose={cerrarAlert} severity="success" sx={{ width: '100%' }}>
                    Dato guardado correctamente
                </Alert>
            </Snackbar>
        </Box>            
    );
}

export { MisInversiones }