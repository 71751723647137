import { Box, Skeleton } from '@mui/material';
import React, {useState,useEffect} from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation, useParams, Link, NavLink, Navigate } from "react-router-dom";
import { DatosFraccionamiento } from '../../components/DatosFraccionamiento';
import { MenuEtapas } from '../../components/MenuEtapas';
import { DatosModelo } from '../../components/DatosModelo';
import { MenuFachadas } from '../../components/MenuFachadas';

const InventarioModelo = () => {
    let navigate = useNavigate();
    const location = useLocation();
    let parametros = useParams();
    
    const [modelo, setModelo] = useState(null);
    //console.log(location.state.fraccionamiento);
    console.log(parametros);

    useEffect(() => {
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        

        axios.get(`${process.env.REACT_APP_API_URL}/api/modelo/${parametros.modelo}`, {
              headers: {
                  Authorization: `Bearer ${token}`
              }
        })
        .then((response) => {
            // handle success
            console.log(response);
            setModelo(response.data)            
        })
        .catch((error) => {
            // handle success
            console.log(error);
            navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sae');
        });
    }, [setModelo]);

    return(
        <div>

            <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'#f4f4f4' }}>
                {
                    !modelo ?
                    <Skeleton variant="rectangular" width={510} height={118} /> :
                    <DatosModelo datos={modelo}/>
                }
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'white' }}>   
                {
                    !modelo ?
                    <Skeleton variant="rectangular" width={510} height={118} /> :
                    <MenuFachadas modelo={modelo}/>
                }                                         
            </Box>
        </div>
    );
}

export { InventarioModelo }