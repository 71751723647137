import { Box } from '@mui/system';
import * as PANOLENS from 'panolens';
import { useEffect } from 'react';

import foto360 from '../../../assets/prueba/GS__4166.JPG';
import foto360_2 from '../../../assets/prueba/GS__4166 (2).jpg';

const PortaPano = ({ fotos }) => {
    // const Mostrar = () => {
    //     return()
    // }
    
    
    useEffect(()=>{
        
        const panorama = new PANOLENS.ImagePanorama('https://d2kg7bu049hzg.cloudfront.net/mapa/parque/DJI_0127.JPG');
        const panorama2 = new PANOLENS.ImagePanorama('https://d2kg7bu049hzg.cloudfront.net/mapa/parque/DJI_0128.JPG');
        const viewer = new PANOLENS.Viewer({ 
            // output: "console",
            container:document.getElementById('pano')
        });
        const infospot = new PANOLENS.Infospot( 500, PANOLENS.DataImage.Arrow );
        // infospot.position.set( -1000, 500, 5000 );
        // infospot.position.set( 3000, -500, 1000 );
        infospot.position.set( 9000, -100, 500 );
        // infospot.addHoverText( 'Modelo T-106', 50 );
        infospot.addEventListener( 'click', function(){
            viewer.setPanorama( panorama2 );
        } );
        panorama.add( infospot );

        const infospot2 = new PANOLENS.Infospot( 500, PANOLENS.DataImage.Arrow );
        infospot2.position.set( -9000, 100, 1000 );
        //infospot2.addHoverText( 'Modelo T-106', 50 );
        infospot2.addEventListener( 'click', function(){
            viewer.setPanorama( panorama );
        } );
        panorama2.add( infospot2 );

        viewer.add(panorama, panorama2);
        // viewer.add(panorama2);
    },[]);
    return(
        <Box 
            component='div' 
            id='pano'
            sx={{
                width:'100%',
                height:'100%',
            }}
        >
        </Box>
    );
}

export { PortaPano }