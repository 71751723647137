import { Button, Grid, Rating, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import fotoAsesor from "../../../assets/asesores/cesar.png";
import sinFotoAsesor from "../../../assets/asesores/sinFotoAsesorPrueba.png";
import asesorPerfil from "../../../assets/asesores/asesorPerfil.png";
import asesorPerfil2 from "../../../assets/asesores/asesorPerfil2.png";

const Encabezado = ({ asesor }) => {
    const mover = () => {
        // refFormulario.current.scrollIntoView();
        window.scrollTo(0, 515);
    
    }
    const getPromedio = (opiniones) => {
        const puntuaciones = opiniones.map((opinion)=> opinion.puntuacion );
        const total = puntuaciones.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        return total / puntuaciones.length;
    }
    return(
        <Box
            sx={{
                // backgroundColor:'yellow',

            }}
        >
            <Box
                sx={{
                    // backgroundColor:'red',
                    width:'100%',
                    display: { xs:'block', sm:'none', md:'none', }
                }}
            >
                <Box
                    component='img'
                    src={asesor.foto}
                    sx={{
                        maxWidth:'100%',
                        // height:'90%',
                        m:'0 auto',
                        borderRadius:'10px',
                    }}
                />
            </Box>
            <Grid container>
                <Grid item xs={12} sm={5} md={5} sx={{ display: { xs:'none', sm:'block', md:'block', } }}>
                    <Box
                        sx={{
                            // backgroundColor:'red',
                            width:'100%',
                            height:{ xs:330, sm:500, md:500 },
                            display:'flex',
                            alignItems:'center',
                            alignContent:'center',
                        }}
                    >
                        <Box
                            sx={{
                                // backgroundColor:'yellow',
                                width:{ xs:'80%', sm:'90%', md:'90%' },
                                height:{ xs:280, sm:400, md:400 },
                                m:'0 auto',
                                borderRadius:'16px',
                                display:'flex',
                                alignItems:'center',
                                alignContent:'center',
                                
                                border: '1px solid #f2f2f2',
                                boxShadow: '0px 5px 12px 0px rgb(0, 0, 0, 0.19)',
                                // p:'10px 10px',
                            }}
                        >
                            <Box
                                sx={{
                                    width:'90%',
                                    height:'90%',
                                    m:'0 auto',
                                    overflow:'hidden',
                                    borderRadius:'10px',
                                }}
                            >
                                <Box
                                    component='img'
                                    src={asesor.foto}
                                    sx={{
                                        maxWidth:'100%',
                                        // height:'90%',
                                        m:'0 auto',
                                        mt:-3
                                        
                                    }}
                                />
                            </Box>                            
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={7} md={7}>
                    <Box
                        sx={{
                            // backgroundColor:'yellow',
                            width:'100%',
                            minHeight:{ xs:250, sm:500, md:500 },
                            display:'flex',
                            alignItems:'center',
                            alignContent:'center',
                        }}
                    >
                        <Box
                            sx={{
                                m:'0 auto',
                                width:'90%',
                            }}
                        >
                            <Typography variant="h4" sx={{ color:'#F5323F', }}>
                                <b>{ asesor.nombre }</b>
                            </Typography>
                            <Typography variant="h5" sx={{ color:'#707070', }}>
                                Vendedor
                            </Typography>
                            <Box
                                sx={{
                                    display:'flex',
                                    alignItems:'center',
                                    mt:1,
                                }}
                            >
                                <Rating name="size-large" value={getPromedio(asesor.opiniones)} size="large" readOnly />
                                {
                                    asesor.opiniones.length > 0 ? (
                                        <Typography 
                                            sx={{ 
                                                color:'#707070',
                                                '&:hover':{
                                                    color:'#4d4d4d',
                                                    
                                                },
                                                cursor:'pointer' 
                                            }} 
                                            onClick={mover}
                                        >
                                            <u>Ver opiniones</u>
                                        </Typography>
                                    ) : (
                                        <Typography sx={{ color:'#707070', }}>
                                            <u>No hay opiniones</u>
                                        </Typography>
                                    )
                                }                                
                            </Box>
                            <Typography variant="body1" sx={{ mt:2, color:'#707070', }}>
                                { asesor.datos.descripcion }
                            </Typography>
                            <Box
                                sx={{
                                    // backgroundColor:'green',
                                    display:'flex',
                                    width:'100%',
                                    mt:3,
                                }}
                            >
                                <Grid 
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Grid item xs={12} sm={4} md={4}>
                                        <Box
                                            sx={{
                                                // backgroundColor:'white',
                                                width:'100%',
                                                textAlign:'center',
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                startIcon={<CalendarMonthIcon />}
                                                sx={{
                                                    width:{ xs:'100%', sm:'90%', md:'90%',},
                                                    height:40,
                                                    backgroundColor:'#F5323F',
                                                    // borderColor:'#F5323F',
                                                    color:'white',
                                                    // borderWidth:2,
                                                    borderRadius:'6px',
                                                    '&:hover': {
                                                        backgroundColor: '#db0a18',
                                                        // color: 'white',
                                                    },
                                                    textTransform: 'none',
                                                    display:{xs:'none', sm:'flex', md:'flex',},
                                                }}
                                                onClick={mover}
                                            >
                                                <b>Agendar Cita</b>
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export { Encabezado }