import { Grid, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";

import Amigosprueba from '../../assets/prueba/amigosprueba.png';

const Objetivo = () => {
    return(
        <Container>
            <Box
                sx={{
                    // backgroundColor:'red',
                    minHeight:300,
                    mt:2,
                    mb:2,
                }}                        
            >
                <Grid 
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12} sm={12} md={6}>
                        <Box>
                            <Typography variant="h4" sx={{ color:'#F5323F' }}>
                                <b>Objetivo</b>
                            </Typography>
                            <Typography variant="body1" sx={{ color:'#707070' }}>
                                <b>La promoción tiene como objetivo invitar a todos nuestros clientes a que nos recomienden a un conocido, familiar o amigo que este buscando casa y a cambio de su recomendación, y en caso de que esta persona lleva a cabo una operación, se pagara un bono de $4,000 pesos en efectivo, mas posteriormente un bono de $1,000 por cada recomendado que firme la persona que el recomendó y compro casa inicialmente.</b>
                            </Typography>
                        </Box>
                    </Grid>                            
                    <Grid item xs={12} sm={12} md={6}>
                        <Box
                            sx={{
                                // backgroundColor:'yellow',
                                width:{ xs:'100%', sm:'100%', md:'100%', },
                                display:'flex',
                                alignItems:'center',
                                alignContent:'center',
                            }}
                        >
                            <Box
                                sx={{
                                    // backgroundColor:'green',
                                    width:{ xs:'100%', sm:'100%', md:'50%', },
                                    display:'flex',
                                    alignItems:'center',
                                    alignContent:'center',
                                    m:'0 auto',
                                }}
                            >
                                <Box
                                    component='img'
                                    src={Amigosprueba}
                                    sx={{
                                        width:{ xs:'100%', sm:'100%', md:'100%', },
                                        m:'0 auto',
                                    }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

export { Objetivo }