import { Fab, Popover, Typography } from "@mui/material";
import { Box } from "@mui/system";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from "react";
import { useLocation } from "react-router-dom";

const UpFAB = () => {
    let location = useLocation();
    const subir = () => {
        window.scroll(0,0);
    }
    if(location.pathname !== '/pruebaPDF'){
        return(
            <Box
                sx={{ 
                    margin: 0,
                    top: 'auto',
                    right: { xs:25, sm:25, md:20, },
                    bottom: { xs:65, sm:95, md:65, },
                    left: 'auto',
                    position: 'fixed',
                    display:{ xs:'none', sm:'block', md:'block' },
                    zIndex:2,
                }}
            >
                <Fab 
                    color="inherit" 
                    aria-label="add" 
                    sx={{ 
                        backgroundColor:'#bfbfbf' 
                    }} 
                    onClick={subir}
                >
                    <KeyboardArrowUpIcon/>
                </Fab>
            </Box>
        );
    }
}

export { UpFAB }