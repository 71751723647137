import { Button, Container, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Amenidades } from "./Amenidades";
import { Modelo } from "./Modelo";
import { Portada } from "./Portada";

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { InlineWidget } from "react-calendly";
import { useState } from "react";
import { Experiencia } from "./Experiencia";
import { Helmet } from "react-helmet";
import { Ubicacion } from "./Ubicacion";
import { MyCalendlyComponent } from "./MyCalendlyComponent";
import { Formulario } from "./Formulario";
import { OchoRazones } from "../../../components/OchoRazones";
import { Garantias } from "../../../components/Garantias";
import TagManager from "react-gtm-module";
import { PopupFoto } from "../../../components/Popups/PopupFoto";

const tagManagerArgs = {
    // gtmId: 'G-BRVTR7BMH1',
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ROCAL
    // dataLayer: {
    //     userId: '001',
    //     userProject: 'project'
    // }
}

TagManager.initialize(tagManagerArgs)
const fraccionamiento = { 
    modelos:[
        {
            nombre:'T-102',
            precio: '$1,634,847.00',
            foto:'https://d3ihta9hbp4hol.cloudfront.net/Fachadas/3/A/FACHADA-A-2019-1.jpg',
            plantas:[
                'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-102/Plantas/plantabaja.png',
                'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-102/Plantas/plantaalta.png',
            ],
            caracteristicas: [
                '102 m² de construcción',
                'Cochera',
                '3 Habitaciones',
                '2 Baños',
            ],
            interiores: {
                total:1420,
                items: [
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-102/Interiores/102_1.jpg',
                        title: 'Breakfast',
                        rows: 2,
                        cols: 2,
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-102/Interiores/102_2.jpg',
                        title: 'Burger',
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-102/Interiores/102_3.jpg',
                        title: 'Camera',
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-102/Interiores/102_4.jpg',
                        title: 'Coffee',
                        cols: 2,
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-102/Interiores/102_5.jpg',
                        title: 'Hats',
                        cols: 2,
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-102/Interiores/102_6.jpg',
                        title: 'Honey',
                        rows: 2,
                        cols: 2,
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-102/Interiores/102_7.jpg',
                        title: 'Burger',
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-102/Interiores/102_8.jpg',
                        title: 'Camera',
                    },
                    // {
                    //     img: interior1069,
                    //     title: 'Coffee',
                    //     cols: 2,
                    // },
                    // {
                    //     img: interior10610,
                    //     title: 'Hats',
                    //     cols: 2,
                    // },
                ]
            }
        },
        // {
        //     nombre:'T-122',
        //     precio: '$1,794,351.00',
        //     foto:'https://d3ihta9hbp4hol.cloudfront.net/Fachadas/4/A/t122A_03.jpg',
        //     plantas:[
        //         'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-122/Plantas/T122_planta_alta.png',
        //         'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-122/Plantas/T122_planta_baja.png',
        //     ],
        //     ubicacion:'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14408.493425185598!2d-101.0067203!3d25.4675561!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86886d4e2163afef%3A0xe4da77f911e906d1!2sFraccionamiento%20El%20Rocal!5e0!3m2!1ses-419!2smx!4v1677542625316!5m2!1ses-419!2smx',
        //     caracteristicas: [
        //         '122 m² de construcción',
        //         'Cochera',
        //         '3 Habitaciones',
        //         '3 Baños',
        //     ],
        //     interiores: {
        //         total:1060,
        //         items: [
        //             {
        //                 img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-122/Interiores/GPTempDownload+3+copy.JPG',
        //                 title: 'Breakfast',
        //                 rows: 2,
        //                 cols: 2,
        //             },
        //             {
        //                 img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-122/Interiores/GPTempDownload+6+copy.JPG',
        //                 title: 'Burger',
        //             },
        //             {
        //                 img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-122/Interiores/recamara.jpg',
        //                 title: 'Camera',
        //             },                    
        //         ],
        //     }
        // },
    ],
    amenidades:[
        {
            nombre:'Palapa',
            texto:'Ven y disfruta de una experiencia única en nuestra palapa . ¡Te esperamos para que disfrutes de un rato agradable en compañía de tus seres queridos!',
            foto:'https://d3ihta9hbp4hol.cloudfront.net/Amenidades/2/Palapa/PALAPA.png',
        },
        {
            nombre:'Plaza',
            texto:'La plaza es un lugar ideal para realizar diversas actividades como picnics, juegos de mesa, lectura o simplemente relajarse mientras disfrutas del ambiente. Además, contamos con una programación constante de actividades recreativas para toda la familia.',
            foto:'https://d3ihta9hbp4hol.cloudfront.net/Amenidades/2/Plaza/plaza.jpg',
        },
        {
            nombre:'Cancha',
            texto:'Ejercitarte con tu deporte favorito será muy fácil en está cancha, podrás disfrutar de Futball y Basketball; así como también encontrarás al lado un espacio con gimnasio al aire libre.',
            foto:'https://d3ihta9hbp4hol.cloudfront.net/Amenidades/2/Cancha+Multi-Usos/cancha.png',
        },
        // {
        //     nombre:'Plaza',
        //     texto:'Nuestra cancha cuenta con un diseño versátil que te permite practicar varios deportes como fútbol, baloncesto y voleibol.',
        //     foto:'https://d3ihta9hbp4hol.cloudfront.net/Amenidades/2/%C3%81rea%20de%20juegos/plaza_2.jpg',
        // }
    ]
}

const RocalLandingPage = () => {
    const [open, setOpen] = useState(false);
    const getOpen = () => {
        setOpen(!open)
    }
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    
    return(
        <Box
            sx={{
                mt:{ xs:7, sm:8, md:10 }
            }}
        >
            <Helmet>
                <title>El Rocal Residencial | Casas Pecsa</title>
                <meta name="description" content=" El Rocal residencial,te ofrece un espacio lleno de tranquilidad y seguridad. El fraccionamiento se encuentra al poniente de la ciudad con diseñados para satisfacer las necesidades y gustos de toda tu familia." />
            </Helmet>
            <Portada getOpen={getOpen} />
            {/* <Formulario getOpen={getOpen}/> */}            
            {/* <Container>
                <Box
                    sx={{
                        width:'100%',
                        textAlign:'center',
                        color:'#523f32',
                        mt:4,
                        mb:2,
                    }}
                >
                    <Typography variant="h3" sx={{ fontSize:{ xs:35, sm:10, md:50 } }}>
                        <b>Última Casa</b>
                    </Typography>
                </Box>
            </Container> */}
            {
                fraccionamiento.modelos.map((modelo) => (
                    <Modelo modelo={modelo} getOpen={getOpen} />
                ))
            }
            {/* <Experiencia/> */}
            <Container>
                <Box
                    sx={{
                        width:'100%',
                        textAlign:'center',
                        color:'#523f32',
                        mt:4,
                        mb:2,
                    }}
                >
                    <OchoRazones titulo="¿Porque comprar una Casa Pecsa?" color="#523F32"/>
                </Box>
            </Container>
            <Box
                sx={{
                    backgroundColor:'#f5f2ef',
                    width:'100%',
                    minHeight:400,
                    textAlign:'center',
                    color:'#523f32',
                    mt:4,
                    mb:2,
                }}
            >
                <Container>
                    <Box
                        sx={{
                            width:'100%',
                            textAlign:'center',
                            color:'#523f32',
                            mt:4,
                            mb:2,
                        }}
                    >
                        <Garantias color="#523f32" align="center" />
                    </Box>
                </Container>
            </Box>            
            
            <MyCalendlyComponent/>
            <Ubicacion/>
            {/* <Amenidades amenidades={fraccionamiento.amenidades}/>             */}
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth="lg"
                fullScreen={fullScreen}
                sx={{
                    
                }}
                onClose={getOpen}
            >                
                <Box
                    sx={{
                        // backgroundColor:'red',
                        width:'100%',
                        height:{ xs:900, sm:900, md:810 }
                    }}
                >
                    <InlineWidget url="https://calendly.com/casas-pecsa/fracc-rocal-residencial?hide_landing_page_details=1&hide_gdpr_banner=1&text_color=615c51&primary_color=9e7960" styles={{ width:'100%', height:800, }} />
                </Box>
                <DialogActions>
                    <Button 
                        autoFocus 
                        sx={{
                            // textTransform:'none',
                            color:'#9e7960',
                            '&:hover':{
                                backgroundColor:'#e2d7cf',

                            }
                        }}
                        onClick={getOpen}
                    >
                        <b>Cancelar</b>
                    </Button>
                </DialogActions>                
            </Dialog>
            {/* <PopupFoto/> */}
        </Box>
    );
}

export { RocalLandingPage }