import { Breadcrumbs, Button, Divider, Grid, Pagination, Skeleton, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

// import pruebadecoracion from '../../assets/prueba/pruebadecoracion.jpg'
// import ThumbUpIcon from '@mui/icons-material/ThumbUp';
// import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import YouTubeIcon from '@mui/icons-material/YouTube';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Post } from "./Post";
import { PostMasPopulares } from "./PostMasPopulares";
import { PromoMes } from "./PromoMes";
import { RedesSociales } from "./RedesSociales";
const Blog = () => {
    const [offset, setOffset] = useState(0);
    const [posts, setPosts] = useState([]);
    const [countPosts, setCountPosts] = useState(0);    

    const getCountPosts = (count) => {
        setCountPosts(Math.ceil((count/4)));
    }

    const [masPopulares, setMasPopulares] = useState([]);

    const [promoActual, setPromoActual] = useState(null);

    const getAll = (offset) => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/blog`,{
            offset:offset,
        })
        .then((response) => {
            // handle success
            console.log(response);
            setPosts(response.data.posts);
            getCountPosts(response.data.count);
            setMasPopulares(response.data.masPopulares);
            setPromoActual(response.data.promoActual);
        })
        .catch((error) => {
            // handle success
            console.log(error);
        })
        .then(() => {
            // handle success
            //console.log('sabea');
        });
    }

    const [page, setPage] = React.useState(1);
    const handleChange = (event, value) => {
        setPage(value);
        alert(value);
    };
    useEffect(()=>{
        getAll(offset);
    },[]);
    return(
        <Container>
            <Helmet>
                <title>Blog Pecsa</title>
                <meta name="description" content={`Encuentra en nuestro Blog Pecsa padrísimo contenido con diversos temas de interés: salud, finanzas, bienestar emocional, tips, tendencias para tu casa y mucho más, ¡Esperamos que lo disfrutes!`} />
            </Helmet>
            <Box sx={{ mt:12, mb:5, }}>
                {/* <Box>
                    <Box
                        sx={{
                            width:'100%',
                            height:450,
                            // backgroundColor:'red',
                            display:'flex',
                            alignItems:'center',
                            alignContent:'center',
                        }}
                    >
                        <Box
                            sx={{
                                width:'100%',
                                height:425,
                                // backgroundColor:'green',
                                backgroundImage: `url(${pruebadecoracion})`,
                                // objectFit:'fill',
                                backgroundRepeat:'no-repeat',
                                backgroundSize:'100%',
                                overflow:'hidden',
                                borderRadius:'15px',
                                m:'0 auto',
                                boxShadow:3,
                            }}
                        >
                            <Box
                                sx={{
                                    width:'100%',
                                    height:'100%',
                                    background:'linear-gradient(to bottom, transparent, white)',
                                    textAlign:'center',
                                }}
                            >
                                <Typography variant="h5">
                                    <b>Titulo</b>
                                </Typography>
                                <Box
                                    component='img'
                                    src={pruebadecoracion}
                                    sx={{
                                        minWidth:'100%',
                                        minHeight:'100%',
                                    }}
                                />
                            </Box>
                            <Box
                                component='img'
                                src={pruebadecoracion}
                                sx={{
                                    minWidth:'100%',
                                    minHeight:'100%',
                                }}
                            />
                        </Box>
                    </Box>
                </Box> */}
                <Box
                    sx={{
                        // backgroundColor:'red',
                        pb:1,
                        pt:0.6,
                        pl:1,
                        color:'#707070',
                        
                    }}
                >
                    <Breadcrumbs aria-label="breadcrumb">
                        {/* <Link underline="hover" color="inherit">
                            Inicio
                        </Link> */}
                        <Link to={'/'} style={{ color:'#707070', }}>
                            Inicio
                        </Link>
                        {/* <Link to={'/tienda'} style={{ color:'#707070', }}>
                            Blog
                        </Link> */}
                        <Typography sx={{ color:'#F5323F', }}>
                            Blog
                        </Typography>
                    </Breadcrumbs>
                </Box>
                <Box>
                    <Box
                        sx={{
                            width:'100%',
                            height:200,
                            backgroundColor:'#F4F4F4',
                            textAlign:'center',
                            display:'flex',
                            alignItems:'center',
                            alignContent:'center',
                            color:'#707070',
                            fontFamily:'Montserrat',
                            borderRadius:'12px',
                        }}
                    >
                        <Box
                            sx={{
                                m:'0 auto',
                                ml:{ xs:1, sm:2, md:2, },
                                mr:{ xs:1, sm:2, md:2, },
                            }}
                        >
                            <Typography variant="h2" sx={{ color:'#DE4647', fontFamily:'Montserrat', display:{xs:'none', sm:'block', md:'block'} }}>
                                <b>Blog Pecsa</b>
                            </Typography>
                            <Typography variant="h6" sx={{ color:'#707070', fontFamily:'Montserrat', display:{xs:'none', sm:'block', md:'block'} }}>
                                Encuentra en nuestro Blog Pecsa padrísimo contenido con diversos temas de interés: salud, finanzas, bienestar emocional, tips, tendencias para tu casa y mucho más, ¡Esperamos que lo disfrutes!
                            </Typography>
                            <Typography variant="h4" sx={{ color:'#DE4647', fontFamily:'Montserrat', display:{xs:'block', sm:'none', md:'none'} }}>
                                <b>Blog Pecsa</b>
                            </Typography>
                            <Typography variant="body1" sx={{ color:'#707070', fontFamily:'Montserrat', display:{xs:'block', sm:'none', md:'none'} }}>
                                Encuentra en nuestro Blog Pecsa padrísimo contenido con diversos temas de interés: salud, finanzas, bienestar emocional, tips, tendencias para tu casa y mucho más, ¡Esperamos que lo disfrutes!
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={8} md={8}>
                            <Box>
                                <Box
                                    sx={{
                                        color:'#DE4647',
                                        textAlign:{ xs:'center', sm:'center', md:'left' },
                                        pl: { xs:2, md:2, },
                                        mt:{ xs:2, md:2, },
                                        //mb:{ xs:8, md:0.5, }, 
                                    }}
                                >
                                    <Typography variant="h6" sx={{ fontFamily:'Montserrat', }}>
                                        <b>Publicaciones</b>
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    minHeight:{ xs:550, sm:600, md:1000 },
                                }}
                            >
                                {
                                    posts ? (
                                        posts.map((post) => (
                                            <React.Fragment>
                                                <Post post={post}/>
                                                <Divider variant="middle"/>
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <React.Fragment>
                                            <Box
                                                sx={{
                                                    width:'100%',
                                                    display:'flex',
                                                    alignItems:'center',
                                                }}
                                            >
                                                <Skeleton 
                                                    variant="rectangular" 
                                                    sx={{
                                                        width:'95%',
                                                        height:{xs:200, sm:250, md:350},
                                                        borderRadius:'15px',
                                                        m:'0 auto',
                                                    }}
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    width:'100%',
                                                    display:'flex',
                                                    alignItems:'center',
                                                }}
                                            >
                                                <Skeleton 
                                                    variant="rectangular" 
                                                    sx={{
                                                        width:'95%',
                                                        height:{xs:200, sm:250, md:40},
                                                        borderRadius:'5px',
                                                        m:'0 auto',
                                                        mt:{xs:1, sm:1, md:1},

                                                    }}
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    width:'100%',
                                                    display:'flex',
                                                    alignItems:'center',
                                                }}
                                            >
                                                <Skeleton 
                                                    variant="rectangular" 
                                                    sx={{
                                                        width:'15%',
                                                        height:{xs:200, sm:250, md:40},
                                                        borderRadius:'5px',
                                                        m:'0 auto',
                                                        mt:{xs:1, sm:1, md:1},

                                                    }}
                                                />
                                                <Skeleton 
                                                    variant="rectangular" 
                                                    sx={{
                                                        width:'15%',
                                                        height:{xs:200, sm:250, md:40},
                                                        borderRadius:'5px',
                                                        m:'0 auto',
                                                        mt:{xs:1, sm:1, md:1},

                                                    }}
                                                />
                                                <Skeleton 
                                                    variant="rectangular" 
                                                    sx={{
                                                        width:'15%',
                                                        height:{xs:200, sm:250, md:40},
                                                        borderRadius:'5px',
                                                        m:'0 auto',
                                                        mt:{xs:1, sm:1, md:1},

                                                    }}
                                                />
                                            </Box>
                                        </React.Fragment>
                                    )                                    
                                }                               
                            </Box>
                            

                            {/* <Box>
                                <Box
                                    sx={{
                                        width:'100%',
                                        display:'flex',
                                        alignItems:'center',
                                        alignContent:'center',
                                        mt:3,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            m:'0 auto',
                                            color:'#707070',
                                        }}
                                    >
                                        <Pagination count={countPosts} page={page} onChange={handleChange} />
                                    </Box>
                                </Box>
                            </Box> */}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Box
                                sx={{
                                    width:'100%',
                                    // backgroundColor:'yellow',
                                }}
                            >
                                <RedesSociales/>
                                {/* <Divider variant="middle"/> */}
                                <Box
                                    sx={{
                                        mt:2,
                                        mb:1,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            pl:1,
                                        }}
                                    >
                                        <Typography variant="h5" sx={{ color:'#DE4647', fontFamily:'Montserrat', }}>
                                            <b>Mas Populares</b>
                                        </Typography>
                                    </Box>
                                    <Box>
                                        {
                                            masPopulares.map((post) => (
                                                <React.Fragment>
                                                    <PostMasPopulares post={post} />
                                                    <Divider variant="middle"/>
                                                </React.Fragment>
                                            ))
                                        }                                       
                                    </Box>
                                    
                                </Box>
                                <Box
                                    sx={{
                                        mt:2,
                                        mb:1,
                                        // backgroundColor:'green',
                                        display:'flex',
                                        alignItems:'center',
                                        alignContent:'center',
                                    }}
                                >
                                    {
                                        promoActual && (
                                            <PromoMes promo={promoActual}/>  
                                        )
                                    }                                                                      
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}

export { Blog }