import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { useEffect, useState } from "react";

const ListaCitas = ({ citas, dia }) => {
    // console.log('veamos si ya jalo',  moment(dia.fecha, 'YYYY-MM-DD').format('DD-MM-YYYY'));
    // console.log(citas.map((cita) => moment(cita.fecha, 'YYYY-MM-DD').format('DD-MM-YYYY') === moment(dia.fecha, 'YYYY-MM-DD').format('DD-MM-YYYY')));
    const [citasDelDia, setCitasDelDia] = useState(citas.map((cita) => moment(cita.fecha, 'YYYY-MM-DD').format('DD-MM-YYYY') === moment(dia.fecha, 'YYYY-MM-DD').format('DD-MM-YYYY')));
    // useEffect(()=>{
    //     if(dia){
    //         console.log('veamos si ya jalo', moment(dia).format('DD-MM-YYYY'));
    //     }
    // }, [dia]);
    return(
        <Box>
            {
                dia ? (
                    <Box
                        sx={{
                            ml:2,
                            color:'#707070',
                        }}
                    >
                        <Box>
                            <Typography variant="h6">
                                <b>Citas del dia</b>
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="body1">
                                Dia: <b>{ moment(dia.fecha, 'YYYY-MM-DD').format('LL') }</b>
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="body1">
                                Guardia: <b>{ `${moment(dia.horaInicio,'HH:mm:ss').format('HH:mm')} - ${moment(dia.horaFinal,'HH:mm:ss').format('HH:mm')}` }</b>
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                ml:2,
                            }}
                        >
                            <List>
                                {
                                    citasDelDia.length > 0 ? (
                                        citasDelDia.map((cita)=>(
                                            <ListItem divider>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="body2">
                                                            <b>{ cita.fraccionamiento.nombre }</b>
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <Box>
                                                            <Typography>
                                                                { `${moment(cita.horaInicio,'HH:mm:ss').format('HH:mm')} - ${moment(cita.horaFinal,'HH:mm:ss').format('HH:mm')}` }
                                                            </Typography>
                                                        </Box>
                                                    }
                                                />
                                            </ListItem>
                                        ))
                                    ) : (
                                        <Box>
                                            <Typography variant="h6" sx={{ color:'#F2353F' }}>
                                                No hay citas
                                            </Typography>
                                        </Box>
                                    )
                                }                                    
                            </List>
                        </Box>
                    </Box>
                ) : (
                    <Box sx={{ color:'#707070', ml:2, }}>
                        <Box>
                            <Typography variant="h5">
                                <b>Citas del dia</b>
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="h6" sx={{ color:'#F2353F' }}>
                                No has seleccionado dia
                            </Typography>
                        </Box>
                        
                    </Box>
                )
            }
        </Box>
    );
}

export { ListaCitas }