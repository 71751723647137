const getLoteConPrecioMasBajo = (elements) => {
    if (elements.length === 0 || !elements.every(element => element.precio)) {
        return { error:'error', precio: 0 }; // O puedes devolver un valor por defecto, como undefined o un mensaje de error
    }

    const lote = elements.reduce((menor, actual) => {
        return (actual.precio < menor.precio) ? actual : menor;
    })

    return lote
}

export { getLoteConPrecioMasBajo }