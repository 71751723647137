import { CustomTypography } from "../../components/CustomTypography";
import { Box } from "@mui/material";
import theme from "../../App/theme";

const Steps = ({ step }) => {
  return (
    <Box
      sx={{
        textAlign: "center",
        borderRadius: "10px",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.35)",
        overflow: "hidden",
        fontFamily: "Montserrat",
      }}
    >
      <Box
        display="flex"
        gap={1.25}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          padding: "0 10px",
          bgcolor: theme.palette.primary.light2,
        }}
      >
        <CustomTypography variant="lg" txtColor={theme.palette.common.white2}>
          <b>{step.number}</b>
        </CustomTypography>
        <CustomTypography
          variant="subheadingSecondary"
          txtColor={theme.palette.common.white2}
        >
          {step.title}
        </CustomTypography>
      </Box>
      <Box
        sx={{
          padding: { xs: "10px", sm: "20px" },
          bgcolor: theme.palette.common.white,
        }}
      >
        {step.description}
      </Box>
    </Box>
  );
};

export { Steps };
