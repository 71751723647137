import axios from "axios";
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation, Navigate, Link } from "react-router-dom";
import { Alert, Avatar, Backdrop, Button, Checkbox, Container, Divider, FormControlLabel, Grid, Snackbar, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system";

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React, { useState } from "react";

import CircularProgress from '@mui/material/CircularProgress';

import logo from '../../assets/logo.svg'

const stylePecsaInputs = {
    "& label.Mui-focused": {
        color: "#f5323f"
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#d9d9d9"
    },
    "& .MuiOutlinedInput-root": {
        // '& fieldset': {
        //   borderColor: 'black',
        // },
        "&:hover fieldset": {
            borderColor: "#d9d9d9"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#d9d9d9"
        }
    }
}

const OlvidastePassword = () => {
    let navigate = useNavigate();
    let location = useLocation();

    const [open, setOpen] = useState(false);

    const [cargando, setCargando] = useState(false);

    const [MsgRecoveryPasswordState, setMsgRecoveryPasswordState] = useState(false);

    const MostarAlert = () => {
        setOpen(true);
    };
    
    const CerrarAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
    };
    const recovery = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setCargando(true);
        console.log({
          email: data.get('email'),
        });
        axios.post(`${process.env.REACT_APP_API_URL}/api/clientes/passwordRecovery`,{
            email: data.get('email'),
        })
        .then((response) => {
            // handle success            
            console.log(response.data);
            setMsgRecoveryPasswordState(true);
            setCargando(false);
            //navigate('/profile/datos');            
            //navigate(`/cargas/inventario/${fraccionamiento.folio.toString()}`, {state:{fraccionamiento:fraccionamiento}});
            //navigate(-1, { replace: true });
        })
        .catch((error) => {
            // handle success
            console.log(error);
            setCargando(false);
            MostarAlert();
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sabe');
        });
    }
    return(
        <Box >
            <Container sx={{ mt: { xs:8, md:15 }, minHeight:830, }}>
                <Grid 
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    
                >
                    <Grid item md={6}>
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                component='img'
                                src={logo}
                            />
                            {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                <LockOutlinedIcon />
                            </Avatar> */}
                            <Typography component="h1" variant="h5" sx={{ color: '#707070' }}>
                                Restablecer Contraseña
                            </Typography>
                            
                            {
                                MsgRecoveryPasswordState ? (
                                    <Typography component="body2"  sx={{ color: '#038926', textAlign:'center' }}>
                                        Las instrucciones para reestablecer tu contraseña han sido enviadas a tu correo electrónico
                                    </Typography>
                                ) 
                                : (
                                    <React.Fragment>
                                        <Typography component="body2"  sx={{ color: '#707070', textAlign:'center' }}>
                                            Ingresa tu correo electronico, donde recibiras el medio donde pordras restablecer tu contraseña.
                                        </Typography>

                                        <Typography component="body2"  sx={{ color: '#707070', textAlign:'center' }}>
                                            O en caso de que ya hayas separado tu casa, asignar una contraseña a tu cuenta Pecsa. 
                                        </Typography>
                                    </React.Fragment>
                                )
                            }                                                            
                            <Box component="form" onSubmit={recovery} noValidate sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Correo Electronico"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    disabled={MsgRecoveryPasswordState ? true : false}
                                    sx={{ ...stylePecsaInputs }}
                                />                                
                                {/* <FormControlLabel
                                    control={<Checkbox value="remember" color="primary" />}
                                    label="Remember me"
                                /> */}
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="outlined" 
                                    color={MsgRecoveryPasswordState ? "success": "inherit"}
                                    sx={{ /*fontSize:'11px',*/ 
                                        mt: 3,
                                        mb: 2,
                                        //width: { xs:'95%', sm:'90%', md:'100%' }, 
                                        p:{ xs:1, sm:1, md:1 },
                                        borderColor: (MsgRecoveryPasswordState ? '038926':'#F5323F') ,
                                        color: (MsgRecoveryPasswordState ? '038926':'#F5323F'),
                                        borderWidth:3,
                                    }}
                                    disabled={MsgRecoveryPasswordState ? true : false}
                                >
                                    <b>Restablecer Contraseña</b>
                                </Button>
                                {/* <Divider>ó</Divider>*/}
                            </Box>
                            {
                                MsgRecoveryPasswordState && (
                                    <Button                                
                                        variant="outlined" 
                                        color="inherit" 
                                        sx={{ /*fontSize:'11px',*/ 
                                            mt: 3,
                                            mb: 2,
                                            width: { xs:'95%', sm:'90%', md:'80%' }, 
                                            p:{ xs:1, sm:1, md:1 },
                                            borderColor:'#707070',
                                            color:'#707070',
                                            borderWidth:2,
                                        }}
                                        onClick={()=>{ navigate('/login'); }}
                                    >
                                        Volver a Iniciar Sesion
                                    </Button>
                                )
                            }                             
                        </Box>
                    </Grid>
                </Grid>
                
                <Snackbar open={open} autoHideDuration={6000} onClose={CerrarAlert}>
                    <Alert onClose={CerrarAlert} severity="error" sx={{ width: '100%' }}>
                        Usuario no encontrado
                    </Alert>
                </Snackbar>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={cargando}
                    //onClick={handleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        </Box>
    );
}

export { OlvidastePassword }