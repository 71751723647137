const downloadFile = (data, fileName) => {
    // Crea un enlace temporal para descargar el archivo
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); // Nombre del archivo a descargar
    document.body.appendChild(link);
    link.click();

    // Limpia el enlace y el objeto URL creado
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
}

export default downloadFile;