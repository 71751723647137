import { Box, Typography } from "@mui/material";
import { CustomTypography } from "../../../../CustomTypography";

const ItemCharacteristics = ({ texto }) => {
    return(
        <Box
            sx={{
                // backgroundColor:'#5F9EA0',
                // mb:0.5,
            }}
        >
            <CustomTypography variant="textTertiary">
                { texto }
            </CustomTypography>
        </Box>
    );
}

export { ItemCharacteristics }