

const handleDownload = (ExamplePdf, nombre) => {
	const link = document.createElement('a');
	link.download = nombre;

	link.href = ExamplePdf;

	document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export { handleDownload }