import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Principal } from "../Lote/Principal";
import { PlantaArquitectonica } from "../../components/PlantaArquitectonica";
import { CaracteristicasLote } from "../../components/CaracteristicasLote";
import { OrientcionDelSol } from "../../components/OrientacionDelSol";
import { UbicacionDelFraccionamiento } from "../../components/UbicacionDelFraccionamiento";
import { UbicacionDentroDelFraccionamiento } from "../../components/UbicacionDentroDelFraccionamiento";
import { LugaresCercanos } from "../../components/LugaresCercanos";
import { ProcesosConstructivos } from "../../components/ProcesosConstructivos";
import { Helmet } from "react-helmet";
import { LoadingLote } from "../../components/Loadings/LoadingLote";

const LoteParaRenta = () => {
    const { folio } = useParams();
    const [lote, setLote] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        window.scroll(0,0);
    },[]);
    useEffect(()=>{
        console.log('jajaja')
        axios.get(`${process.env.REACT_APP_API_URL}/api/lotes-inversion/en-renta/${folio}`)
        .then((response) => {
            // handle success
            console.log('54',response.data);
            setLote(response.data);
            setLoading(false);
        })
        .catch((error) => {
            // handle success
            console.log(error);
            setLoading(false);
        })
        .then(() => {
            // handle success
            //console.log('sabea');
        });
    },[folio]);
    return(
        <div>
            {
                loading && !lote && (
                    <Box sx={{ display: { xs: 'flex', md: 'flex' }, mt: { xs:9, sm:9, md: 11 } }}>
                        <LoadingLote/>
                    </Box>
                )
            }
            {
                !loading && !lote && (
                    <Box sx={{ display: { xs: 'flex', md: 'flex' }, mt: { xs:7, sm:9, md: 11 } }}>
                        <h1>No hay Datos</h1>
                    </Box>
                )
            }
            {
                lote && (
                    <>
                        <Helmet>
                            <title>{ `Rentar - Modelo ${lote.fachada.modelo.nombre} ${lote.manzana.etapa.fraccionamiento.nombre} | Casas Pecsa` }</title>
                            <meta name="description" content="Rentar copy" />
                        </Helmet>
                        <Box sx={{ display: { xs: 'flex', md: 'flex' }, mt: { xs:7, sm:9, md: 11 } }}>
                            <Container>
                                <Box
                                    sx={{
                                        // backgroundColor:'red',
                                        pb:1,
                                        pt:0.6,
                                        pl:1,
                                        color:'#707070',
                                        
                                    }}
                                >
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link to={'/'} style={{ color:'#707070', }}>
                                            Inicio
                                        </Link>
                                        <Link to={'/renta'} style={{ color:'#707070', }}>
                                            Lotes en renta
                                        </Link>
                                        <Typography sx={{ color:'#F5323F', }}>
                                            { `${lote.fachada.modelo.nombre} ${lote.manzana.etapa.fraccionamiento.nombre}` }
                                        </Typography>
                                    </Breadcrumbs>
                                </Box>
                            </Container>
                        </Box>
                        <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                            <Principal lote={lote} />
                        </Box>
                        <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                            <PlantaArquitectonica plantas= {lote.fachada.modelo.plantas}/>
                        </Box>
                        <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'#F4F4F4' }}>
                            <CaracteristicasLote caracteristicas={lote.caracteristicas[0]}/>
                        </Box>
                        <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                            <OrientcionDelSol lote={lote}/>
                        </Box>
                        <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'#F4F4F4' }}>
                            <UbicacionDelFraccionamiento fraccionamiento={lote.manzana.etapa.fraccionamiento}/>
                        </Box>
                        <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                            <UbicacionDentroDelFraccionamiento lote={lote}/>
                        </Box>
                        <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'#F4F4F4' }}>
                            <LugaresCercanos lugares={lote.manzana.etapa.fraccionamiento.lugaresCerca}/>
                        </Box>
                        <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                            <ProcesosConstructivos modelo={lote.fachada.modelo}/>
                        </Box>
                    </>
                )
            }
            
        </div>
    );
}

export { LoteParaRenta }