import * as React from 'react';
import { useEffect, useState } from "react";
import { Button, Container, Divider, Grid, Stack } from "@mui/material";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ApartmentSharpIcon from '@mui/icons-material/ApartmentSharp';
import MapsHomeWorkSharpIcon from '@mui/icons-material/MapsHomeWorkSharp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useNavigate } from 'react-router-dom';

const DatosEtapa = (props) => {
    let navigate = useNavigate();
    const[etapa, setEtapa] = useState(props.datos);
    return(
        <Container sx={{ backgroundColor:'red' }}>
            <Grid container spacing={2}>
                <Grid item xs={6} md={12}>
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" variant="middle" flexItem />}
                        spacing={4}
                        width='100%'
                    >
                        <Box
                            component="div"
                            
                            sx={{ 
                                width: 240,
                                backgroundColor: 'white',
                                borderRadius: '50%',
                                m:3,
                                boxShadow: 3
                            }}
                        >
                            <ApartmentSharpIcon
                                sx={{
                                    fontSize:180,
                                    color: "text.secondary"
                                }}
                            />
                        </Box>
                        <Box
                            sx={{ 
                                backgroundColor: 'white',
                                p:3,
                                display: 'block'
                            }}
                        >
                            <Typography gutterBottom variant="h4" component="div" align='left'>
                                {etapa.nombre}
                            </Typography>
                            <Typography variant="h6" component="div" color="text.secondary" align='left'>
                                Folio: {etapa.folio}
                            </Typography>
                            <Typography variant="h6" component="div" color="text.secondary" align='left'>
                                <MapsHomeWorkSharpIcon/> Nombre Fraccionamiento
                            </Typography>
                            <Typography variant="h6" component="div" color="text.secondary" align='left'>
                                <LocationOnIcon/> Empresa
                            </Typography>
                            <Typography variant="h6" component="div" color="text.secondary" align='left'>
                                <LocationOnIcon/> Saltillo, Coahuila
                            </Typography>
                            <Box>
                                <Button 
                                    variant="outlined" 
                                    startIcon={<EditIcon />}
                                    sx={{ 
                                        width: '100%',
                                        marginTop: 2
                                    }}
                                    onClick={()=>{navigate('edit')}}
                                >
                                    Editar
                                </Button>
                                <Button 
                                    variant="outlined" 
                                    startIcon={<DeleteIcon />}
                                    sx={{ 
                                        width: '100%',
                                        marginTop: 1
                                    }}
                                >
                                    Borrar
                                </Button>
                            </Box>                            
                        </Box>                        
                    </Stack>
                </Grid>                
            </Grid>
        </Container>
    );
}

export { DatosEtapa }