import * as React from 'react';
import { List, ListSubheader } from "@mui/material";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import StarBorder from '@mui/icons-material/StarBorder';
import { useState, useEffect } from 'react';

const TiendaFiltro = ({ lotes, handleChangeSearch }) => {
    // console.log('filtro', lotes);
    const [openModelo, setOpenModelo] = React.useState(false);
    const [openFraccionamiento, setOpenFraccionamiento] = React.useState(false);
    const [openM2, setOpenM2] = React.useState(false);
    const [openOrdenar, setOpenOrdenar] = React.useState(true);
    const [openPromo, setOpenPromo] = React.useState(true);

    const [modelosD, setModelosD] = useState([]);
    const [fraccionamientosD, setFraccionamientosD] = useState([]);
    const [superficiesDeTerrenoD, setSuperficiesDeTerrenoD] = useState([]);

    const [selectedModelosD, setSelectedModelosD] = useState('');
    const [selectedFraccionamientosD, setSelectedFraccionamientosD] = useState('');

  const handleClickModelo = () => {
    setOpenModelo(!openModelo);
  };
  const handleClickFracc = () => {
    setOpenFraccionamiento(!openFraccionamiento);
  };
  const handleClickM2 = () => {
    setOpenM2(!openM2);
  };
  const handleClickOrdenar = () => {
    setOpenOrdenar(!openOrdenar);
  };
  const handleClickPromo = () => {
    setOpenPromo(!openPromo);
  };

  const addModeloD = (lote) => {    
    if(!modelosD.find((modelo) => { return modelo.nombre === lote.fachada.modelo.nombre})){
        // console.log('modelo que se agregarta', lote.fachada.modelo.nombre);        
        //setModelosD([...modelosD, lote.fachada.modelo]);
        modelosD.push(lote.fachada.modelo);
    }
  };
  const addFraccionamientoD = (lote) => {    
    if(!fraccionamientosD.find((fraccionamiento) => { return fraccionamiento.nombre === lote.manzana.etapa.fraccionamiento.nombre})){
        // console.log('fraccionamiento que se agregarta', lote.manzana.etapa.fraccionamiento.nombre);        
        //setModelosD([...modelosD, lote.fachada.modelo]);
        fraccionamientosD.push(lote.manzana.etapa.fraccionamiento);
    }
  };

  useEffect(() => {
    //setModelosD([])
    // console.log('modeloDPrincipio', modelosD);
    lotes.map((lote) => {
        // setModelosD([...modelosD, lote.fachada.modelo]);        
        addModeloD(lote)
        addFraccionamientoD(lote);
        // console.log('lotesmap', lote.fachada.modelo);
        //setFraccionamientosD([...fraccionamientosD, lote.manzana.etapa.fraccionamiento])
        //setSuperficiesDeTerrenoD([...superficiesDeTerrenoD, lote.superficieDeTerreno])
    })
  }, [])
  const getfiltros = (modelo, fraccionamiento) => {
    // console.log(modelo);
    // console.log(fraccionamiento);
    handleChangeSearch(modelo, fraccionamiento);    
  }
  useEffect(() => {
    getfiltros(selectedModelosD, selectedFraccionamientosD);    
  }, [selectedModelosD, selectedFraccionamientosD])
    return(
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader component="div" id="nested-list-subheader" sx={{ color:'#F5323F' }}> 
                    Filtro
                </ListSubheader>
            }
            sx={{ color:'#F5323F' }}
        >
            <ListItemButton onClick={handleClickModelo}>
                <ListItemText primary="Modelo" />
                {openModelo ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openModelo} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }} onClick={()=>{ setSelectedModelosD('') }}>
                        <ListItemText primary={'Todos'} />
                    </ListItemButton>
                    {
                        modelosD.map((modeloD) => (
                            <ListItemButton sx={{ pl: 4 }} 
                                // onClick={()=>{ handleChangeSearch(modeloD) }}
                                onClick={()=>{ setSelectedModelosD(modeloD) }}
                                selected={selectedModelosD === modeloD ? true : false}
                            >
                                <ListItemText primary={modeloD.nombre} />
                            </ListItemButton>
                        ))
                    }                    
                </List>
            </Collapse>
            <ListItemButton onClick={handleClickFracc}>
                <ListItemText primary="Fraccionamiento" />
                {openFraccionamiento ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openFraccionamiento} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }} onClick={()=>{ setSelectedFraccionamientosD('') }}>
                        <ListItemText primary={'Todos'} />
                    </ListItemButton>
                    {
                        fraccionamientosD.map((fraccionamientoD) => (
                            <ListItemButton sx={{ pl: 4 }}
                                // onClick={()=>{ handleChangeSearch(fraccionamientoD) }}
                                onClick={()=>{ setSelectedFraccionamientosD(fraccionamientoD) }}
                                selected={selectedFraccionamientosD === fraccionamientoD ? true : false}
                            >
                                <ListItemText primary={fraccionamientoD.nombre} />
                            </ListItemButton>
                        ))
                    }
                </List>
            </Collapse>
            {/* <ListItemButton onClick={handleClickM2}>
                <ListItemText primary="m²" />
                {openM2 ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openM2} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {
                        superficiesDeTerrenoD.map((superficieDeTerrenoD) => (
                            <ListItemButton sx={{ pl: 4 }}>
                                <ListItemText primary={`${superficieDeTerrenoD} m²`} />
                            </ListItemButton>
                        ))
                    }
                </List>
            </Collapse> */}
            {/* <ListItemButton onClick={handleClickOrdenar}>
                <ListItemText primary="Ordenar por precio" />
                {openOrdenar ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openOrdenar} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <StarBorder />
                        </ListItemIcon>
                        <ListItemText primary="Starred" />
                    </ListItemButton>
                </List>
            </Collapse>
            <ListItemButton onClick={handleClickPromo}>
                <ListItemText primary="Promociones" />
                {openPromo ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openPromo} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <StarBorder />
                        </ListItemIcon>
                        <ListItemText primary="Starred" />
                    </ListItemButton>
                </List>
            </Collapse> */}
        </List>
    );
}

export { TiendaFiltro }