import { Button, Grid, ImageList, ImageListItem, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import Carousel from "react-material-ui-carousel";

function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
}
const Modelo = ({ modelo, getOpen }) => {
    return(
        <Box>
            <Box>
                <Container>
                    <Box
                        sx={{
                            minHeight:300,
                            mt:2,
                        }}
                    >
                        <Grid container spacing={1}>
                            {/* <Grid item xs={12} sm={12} md={12}>
                                <Box
                                    sx={{
                                        textAlign:'center',
                                    }}
                                >
                                    <Typography>
                                        <b>Modelos Disponibles</b>
                                    </Typography>
                                </Box>
                            </Grid> */}
                            <Grid item xs={12} sm={6} md={6}>
                                <Box
                                    sx={{
                                        // backgroundColor:'red',
                                        width:'100%',
                                        height:{ xs:350, sm:400, md:600, },
                                        display:'flex',
                                        alignItems:'center',
                                        alignContent:'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            // backgroundColor:'yellow',
                                            width:{ xs:'100%', sm:'90%', md:'100%', },
                                            height:{ xs:'100%', sm:'95%', md:'95%', },
                                            m:'0 auto',
                                            overflow:'hidden',
                                            display:'flex',
                                            alignItems:'center',
                                            alignContent:'center',
                                            borderRadius:{ xs:'12px', sm:'85px', md:'85px', },
                                            border: 1,
                                            borderColor:'#f2f2f2',
                                            boxShadow:3,
                                        }}
                                    >
                                        <Box
                                            component='img'
                                            // src={'https://casaspecsa-bucket.s3.us-east-2.amazonaws.com/Fachadas/2/A/fachada+A.png'}
                                            src={modelo.foto}
                                            sx={{
                                                m:'0 auto',
                                                minHeight:'100%',
                                                maxHeight:'105%',
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Box
                                    sx={{
                                        // backgroundColor:'green',
                                        width:'100%',
                                        minHeight:{ xs:400, sm:500, md:600, },
                                        display:'flex',
                                        alignItems:'center',
                                        alignContent:'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            // backgroundColor:'yellow',
                                            width:'95%',
                                            height:'95%',
                                            m:'0 auto',
                                            color:'#745f3e',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                mt:{ xs:0, sm:1, md:1 },
                                            }}
                                        >
                                            <Typography variant="h3" sx={{ color:'#138541', fontFamily:'Montserrat', }}>
                                                Modelo <b>{modelo.nombre}</b>
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                mt:1,
                                                ml:1,
                                            }}
                                        >
                                            <Typography variant="body2" sx={{ fontFamily:'Montserrat', }}>
                                                <b>Precio desde:</b>
                                            </Typography>
                                            <Typography variant="h4" sx={{ fontFamily:'Montserrat', }}>
                                                <b>{modelo.precio}</b>
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                mt:2,
                                                ml:{ xs:1, sm:2, md:2, },
                                                mr:{ xs:1, sm:2, md:2, },
                                                // backgroundColor:'red',
                                                // width:'100%',
                                            }}
                                        >
                                            <Grid container>
                                                {
                                                    modelo.caracteristicas.map((caracteristica) => (
                                                        <Grid item xs={6} sm={6} md={6}>
                                                            <Typography variant="body1" sx={{ fontFamily:'Montserrat', }}>
                                                                <b>-{ caracteristica }</b>
                                                            </Typography>
                                                        </Grid>
                                                    ))
                                                }
                                            </Grid>
                                        </Box>
                                        <Box>
                                            <Grid container>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Box
                                                        sx={{ 
                                                            mt:1, 
                                                            textAlign:'center',
                                                        }}
                                                    >
                                                        <Typography variant="body1" sx={{ fontFamily:'Montserrat', }}>
                                                            <b>Plantas:</b>
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={6}>
                                                    <Box
                                                        sx={{
                                                            // backgroundColor:'red',
                                                            width:'100%',
                                                            height:275,
                                                            display:'flex',
                                                            alignItems:'center',
                                                            alignContent:'center',
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                // backgroundColor:'green',
                                                                width:'70%',
                                                                height:'100%',
                                                                m:'0 auto',
                                                                display:'flex',
                                                                alignItems:'center',
                                                                alignContent:'center',
                                                                overflow:'hidden',
                                                            }}
                                                        >
                                                            <Box
                                                                component="img"
                                                                src={modelo.plantas[0]}
                                                                sx={{
                                                                    // backgroundColor:'green',
                                                                    height:'100%',
                                                                    m:'0 auto',
                                                                    // height:250,
                                                                }}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={6}>
                                                <Box
                                                        sx={{
                                                            // backgroundColor:'red',
                                                            width:'100%',
                                                            height:275,
                                                            display:'flex',
                                                            alignItems:'center',
                                                            alignContent:'center',
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                // backgroundColor:'green',
                                                                width:'70%',
                                                                height:'100%',
                                                                m:'0 auto',
                                                                display:'flex',
                                                                alignItems:'center',
                                                                alignContent:'center',
                                                                overflow:'hidden',
                                                            }}
                                                        >
                                                            <Box
                                                                component="img"
                                                                src={modelo.plantas[1]}
                                                                sx={{
                                                                    // backgroundColor:'green',
                                                                    height:'100%',
                                                                    m:'0 auto',
                                                                    // height:250,
                                                                }}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box
                                            sx={{
                                                // backgroundColor:'red',
                                                width:'100%',
                                                textAlign:'center',
                                                mt:{xs:0, sm:0, md:2 },
                                                mb:{xs:2, sm:2, md:0 },
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                color="inherit"
                                                sx={{
                                                    width:'50%',
                                                    borderRadius:'9px',
                                                    // textTransform:'none',
                                                    backgroundColor:'#138541',
                                                    color:'white',
                                                    '&:hover':{
                                                        backgroundColor:'#c7b497',
                                                        color:'white',
                                                    }
                                                }}
                                                onClick={getOpen}
                                            >
                                                <Typography variant="h6" sx={{ fontFamily:'Montserrat', }}>
                                                    <b>Agendar cita</b>
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>            
            <Box
                sx={{
                    
                    // backgroundImage: 'url("https://d3ihta9hbp4hol.cloudfront.net/Fraccionamientos/El+Rocal+Residencial/Fotos/caseta2.png")',
                    // backgroundPosition: 'center',
                    // backgroundRepeat: 'no-repeat',
                    // backgroundSize: 'cover',
                    width:'100%',
                    minHeight:50,
                    // backgroundColor:'rgb(204, 185, 172)',
                }}
            >
                {
                    modelo.interiores.items.length > 0 && (
                        <Box
                            sx={{
                            
        
                                width:'100%',
                                // backgroundColor:'#f5f2ef',
                                backgroundColor:'#C7B497',
                            }}
                        >
                            <Container>
                                <Box
                                sx={{
                                        pt:1,
                                        pb:0.1,
                                }}
                                >
                                    {/* <Box
                                        sx={{
                                            width:'100%',
                                            textAlign:'center',
                                            color:'white',
                                        }}
                                    >
                                        <Typography variant="h4">
                                            <b>Interiores del Modelo</b>
                                        </Typography>
                                    </Box> */}
                                    <Box
                                        sx={{
                                            // backgroundColor:'yellowgreen',
                                            width:'100%',
                                        }}
                                    >
                                        <Carousel
                                            sx={{
                                                width: '100%', 
                                                height: { xs: 340, sm: 450, md: 750 }

                                            }}
                                        >
                                            {
                                                modelo.interiores.items.map((item) => (
                                                    <Box
                                                        sx={{
                                                            // backgroundColor:'#ece4df',
                                                            width:'100%',
                                                            height:{ xs:300, sm:320, md:700 },
                                                            display:'flex',
                                                            alignItems:'center',
                                                            alignContent:'center',
                                                            overflow:'hidden',
                                                            borderRadius:'12px',
                                                        }}
                                                    >                                                
                                                        <Box
                                                            component="img"
                                                            src={item.img}
                                                            sx={{
                                                                // minWidth:'100%',
                                                                // minHeight:'100%',
                                                                maxHeight:'100%',
                                                                m:'0 auto',
                                                                borderRadius:'12px',
                                                            }}
                                                        />
                                                    </Box>
                                                ))
                                            }
                                            
                                        </Carousel>
                                    </Box>
                                    {/* <Box
                                        sx={{
                                            // backgroundColor:'red',
                                            width:'100%',
                                            // height: modelo.interiores.total,
                                            // display: { xs:'none', md:'block' },
                                            mb:1,
                                        }}
                                    >
                                        <Grid container spacing={1}>
                                            {
                                                modelo.interiores.items.map((item) => (
                                                    <Grid item xs={6} sm={4} md={4}>
                                                        <Box
                                                            sx={{
                                                                backgroundColor:'#ece4df',
                                                                width:'100%',
                                                                height:{ xs:150, sm:320, md:320 },
                                                                display:'flex',
                                                                alignItems:'center',
                                                                alignContent:'center',
                                                                overflow:'hidden',
                                                                borderRadius:'12px',
                                                            }}
                                                        >                                                
                                                            <Box
                                                                component="img"
                                                                src={item.img}
                                                                sx={{
                                                                    minWidth:'100%',
                                                                    minHeight:'100%',
                                                                    m:'0 auto'
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                    </Box> */}
                                    {/* <Box
                                        sx={{
                                            display: { xs:'block', md:'none' },
                                            pb:2,
                                        }}
                                    >
                                        <ImageList sx={{ width:'100%', height:modelo.interiores.total+350, }} variant="quilted" cols={2} rowHeight={350}>
                                            {modelo.interiores.items.map((item) => (
                                                <ImageListItem key={item.img} cols={1} rows={1}>
                                                    <img
                                                        {...srcset(item.img, 350, item.rows, item.cols)}
                                                        alt={item.title}
                                                        loading="lazy"
                                                        style={{ borderRadius:'12px', }}
                                                    />                                        
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </Box> */}
                                    
                                </Box>
                            </Container>
                        </Box>
                    )
                }                
                
            </Box>
        </Box>
    );
}

export { Modelo }