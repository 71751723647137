import { Container, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";

import t146_SO_ from '../../assets/t146_SO_.mp4'
import t146_SO_gif from '../../assets/puestadesol/t146_SO-3.gif'

import distanciaareaverde from '../../assets/iconospuestadesol/distanciaareaverde.svg'
import orientacion from '../../assets/iconospuestadesol/orientacion.svg'
import distanciaalacceso from '../../assets/iconospuestadesol/distanciaalacceso.svg'
import { AddEtapa } from "../../pages/AddEtapa";

const OrientcionDelSol = ({ lote }) => {
    console.log('soool', lote);
    return(
        <Container>
            <Grid 
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{
                    mt:4,
                    mb:4
                }}
            >
                <Grid item xs={12} sm={6} md={6}>
                    <Box
                        component='img'
                        src={lote.archivoOrientacionDelSol}
                        sx={{
                            width: { xs:'100%', sm:'95%', md:'90%' }
                        }}
                    />
                    {/* <Box
                        component='video'
                        autoPlay={true}
                        loop={true}
                        src={t146_SO_}
                        sx={{
                            width: { xs:'90%', sm:'95%', md:'80%' }
                        }}
                    /> */}
                    {/* <video
                        width='80%'
                        autoPlay={true}
                    >
                        <source src={t146_SO_} type="video/mp4"></source>
                    </video> */}
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        
                    >
                        <Typography variant="h5" component='div' sx={{ mb:2, color:'#F5323F', }}>
                            <b>ORIENTACIÓN</b> DEL SOL
                        </Typography>
                        <Typography variant="body2" component='div' sx={{ mb:2,color:'#707070', }}>
                            Para tu nueva casa cada detalle es importante. Y sin duda la orientación del sol lo es. Checa en este video ilustrativo cómo será la iluminación natural de tu nuevo hogar a través del día.
                        </Typography>
                        <Grid 
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={4} sm={4} md={4}>
                                <Box
                                    component='img'
                                    src={distanciaareaverde}
                                    sx={{
                                        width: { xs:'25%', sm:'25%', md:'25%' }
                                    }}
                                />
                                <Typography variant="body1" component='div'>
                                    Distancia al <b>área verde</b>
                                </Typography>
                                <Typography variant="body2" component='div'>
                                    <b>{lote.distanciaAreaVerde} m</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}>
                                <Box
                                    component='img'
                                    src={orientacion}
                                    sx={{
                                        width: { xs:'25%', sm:'25%', md:'25%' }
                                    }}
                                />
                                <Typography variant="body1" component='div'>
                                    <b>Orientación</b>
                                </Typography>
                                <Typography variant="body2" component='div'>
                                    <b>{lote.manzana.etapa.fraccionamiento.descripcionUbicacion}</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}>
                                <Box
                                    component='img'
                                    src={distanciaalacceso}
                                    sx={{
                                        width: { xs:'25%', sm:'25%', md:'25%' }
                                    }}
                                />
                                <Typography variant="body1" component='div'>
                                    Distancia al <b>Acceso</b>
                                </Typography>
                                <Typography variant="body2" component='div'>
                                    <b>{lote.distanciaEntrada} m</b>
                                </Typography>
                            </Grid>                            
                        </Grid>
                    </Stack>
                </Grid>
            </Grid>
        </Container>
    );
}

export { OrientcionDelSol }