import { Box, Stack, Typography } from "@mui/material";

const ServicioDisponible = (props) => {
    
    return(
        <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
                color:'#707070',
                // backgroundColor:'red',
            }}
        >
            <Box
                sx={{
                    // backgroundColor:'yellow',
                    width:'100%',
                    height:80,
                    display:'flex',
                    alignItems:'center',
                }}
            >
                <Box
                    component="img"
                    src={props.garantia.img}
                    sx={{
                        width: { xs: '60%', sm: '45%', md: '30%' },
                        // width:'20px',
                        m:'0 auto',
                    }}
                />
            </Box>
            <Typography variant="p" component="div" sx={{ m:1,  }}>
                <b>{ props.garantia.name }</b>
            </Typography>
            <Typography variant="P" component="div" sx={{ ml: { xs: 1, sm: 2, md: 2 }, mr: { xs: 1, sm: 2, md: 2 }, fontSize: { xs: 11, sm: 11, md: 13 },  }}>
                { props.garantia.desc }
            </Typography>
            {/* <Typography variant="P" component="div" sx={{ ml: { xs: 1, sm: 2, md: 6 }, mr: { xs: 1, sm: 2, md: 6 }, fontSize: { xs: 7, sm: 7, md: 9 },  }}>
                *Sujeto a disponibilidad
            </Typography> */}
        </Stack>
    );
}

export { ServicioDisponible }