/**
 * Componente FooterLinksContainer representa los enlaces del pie de página,
 * mostrando fraccionamientos, programas de compra y características.
 * Permite la navegación a diferentes secciones del sitio.
 *
 * @component
 * @param {Object} props - Propiedades del componente.
 * @param {Array} props.fraccionamientos - Lista de fraccionamientos.
 * @param {Array} props.purchasePrograms - Lista de programas de compra.
 * @param {Array} props.features - Lista de características.
 * @returns {JSX.Element} JSX Element que representa los enlaces del pie de página.
 *
 * @example
 * // Ejemplo de uso del componente FooterLinksContainer
 * <FooterLinksContainer
 *   fraccionamientos={fraccionamientosData}
 *   purchasePrograms={purchaseProgramsData}
 *   features={featuresData}
 * />
 */

import React from "react";
import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

/* Estilos */

const colors = {
  txtColor: "#1f1f1f",
  primary: "#f5323f",
};

const baseTypography = {
  fontFamily: "Montserrat",
};

const wrapper = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 1.5,
};

const boxContent = {
  width: "100%",
  display: "flex",
};

const primaryTypography = {
  ...baseTypography,
  fontWeight: "bold",
};

const secondaryTypography = {
  ...baseTypography,
  color: colors.txtColor,
  transition: "color 0.2s ease",
  "&:hover": {
    color: colors.primary,
    cursor: "pointer",
  },
};

const FooterLinksContainer = ({
  fraccionamientos,
  purchasePrograms,
  features,
}) => {
  let navigate = useNavigate();

  /**
   * Función para renderizar una lista de enlaces.
   *
   * @param {Array} links - Lista de enlaces a renderizar.
   * @returns {JSX.Element} JSX Element que representa la lista de enlaces.
   *
   * @example
   * // Ejemplo de llamada a la función renderLinks
   * renderLinks(purchasePrograms);
   */

  const renderLinks = (links) => (
    <Box>
      {links.map((link) => (
        <Typography
          key={link.title}
          onClick={() => {
            if (link.path.startsWith("http")) {
              // Si la URL comienza con "http", se considera un enlace externo
              window.open(link.path, "_blank");
            } else {
              navigate(link.path);
            }
          }}
          sx={secondaryTypography}
        >
          {link.title}
        </Typography>
      ))}
    </Box>
  );

  return (
    <Box sx={wrapper}>
      <Box sx={boxContent}>
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemText
              primary={
                <Typography sx={primaryTypography}>Fraccionamientos</Typography>
              }
            />
          </ListItem>
          {fraccionamientos.map((fraccionamiento) => (
            <ListItem
              key={fraccionamiento.id}
              disablePadding
              onClick={() =>
                navigate(
                  `/fraccionamiento/${fraccionamiento.nombre.replace(
                    / /g,
                    "_"
                  )}`
                )
              }
            >
              <ListItemText
                secondary={
                  <Typography sx={secondaryTypography}>
                    {fraccionamiento.nombre}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </Box>
      <Box sx={boxContent}>
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemText secondary={renderLinks(purchasePrograms)} />
          </ListItem>
        </List>
      </Box>
      <Box sx={boxContent}>
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemText secondary={renderLinks(features)} />
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export { FooterLinksContainer };
