import { Box, Container, ThemeProvider, createTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Primero } from "./Primero/index.js";
import { Segundo } from "./Segundo/index.js";

const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#F5323F',
        },
        secondary: {
            main: '#707070',
        },
    },
    typography: {
        fontFamily: 'Montserrat',
        color:'#707070',
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '7px',
                    textTransform:'none',
                    // borderWidth:2
                }
            }
        },
        // MuiOutlinedInput: {
        //     styleOverrides: {   
        //         root: {
        //             borderRadius: '7px',
        //             // height:20
        //         },
                
        //     },
            
        // }
    }
});

const SimuladorDeCredito = ({ precio }) => {    
    const [calcular, setCalcular] = useState(false);
    const getCalcular = () => {
        setCalcular(!calcular);
    }
    const [plazo, setPlazo] = useState(null);
    const getPlazo = (e) => {
        // console.log(e.target.value);
        setPlazo(e.target.value);
    }
    useEffect(()=>{
        if(calcular){
            getCalcular();
        }
    },[precio]);
    
    return(
        <ThemeProvider theme={theme}> 
            <Container>
                <Box sx={{ color:'#F5323F' }}>
                    <h1>Simula tu Crédito</h1>
                </Box>
                {
                    !calcular ? (
                        <Primero precio={precio} plazo={plazo} handleChangePlazo={getPlazo} handleClick={getCalcular} />
                    ) : (
                        <Segundo precio={precio} plazo={plazo} handleClickCalcular={getCalcular} />
                    )
                }
            </Container>
        </ThemeProvider>
    );
}

export { SimuladorDeCredito }