import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment/moment";
// import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const VideoMiniatura = ({ video }) => {
    

    let navigate = useNavigate();
    const reedirigirReproductor = () => {
        navigate(`/videoblog/${video.idYoutube}`);
        // alert('hey');
    }
    // useEffect(()=>{
    //     moment().locale('es');
    // },[video])
    return(
        <Box
            sx={{
                // backgroundColor:'red',
                width:'100%',
                mb:2,
                cursor:'pointer',
            }}
            onClick={reedirigirReproductor}
        >
            <Box
                sx={{
                    // backgroundColor:'green',
                    width:'100%',
                    display:'flex',
                    alignItems:'center',
                    alignContent:'center',
                }}
            >
                <Box
                    sx={{
                        // backgroundColor:'white',
                        width: { xs:'100%', sm:'97%', md:'97%', },
                        height:{ xs:190, sm:169, md:169, },
                        m:'0 auto',
                        display:'flex',
                        alignItems:'center',
                        alignContent:'center',
                        overflow:'hidden',
                        borderRadius:{ xs:'9px', sm:'12px', md:'12px', },
                    }}
                >
                    <Box
                        component='img'
                        src={video.imagenHigh}
                        sx={{
                            width:'100%',
                            // maxHeight:'105%'
                            m:'0 auto',
                        }}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    // backgroundColor:'yellow',
                    width:'100%',
                    display:'flex',
                    alignItems:'center',
                    alignContent:'center',
                }}
            >
                <Box
                    sx={{
                        // backgroundColor:'white',
                        width:'97%',
                        // height:139,
                        m:'0 auto',
                        color:'#707070',
                    }}
                >
                    <Typography 
                        variant="body2" 
                        sx={{
                            // backgroundColor:'red',
                            width:'100%', 
                            height:{ xs:38, sm:43, md:43, }, 
                            textOverflow: 'ellipsis',
                            overflow:'hidden',
                            display:'-webkit-box !important',
                            WebkitLineClamp:2,
                            WebkitBoxOrient:'vertical',
                            whiteSpace:'normal',
                            color:'#F5323F',
                        }}
                    >
                        <b>{ video.titulo }</b>
                    </Typography>
                    <Typography variant="caption">
                        {/* Hace 2 semanas */}
                        { moment(video.createdAt).fromNow() }
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export { VideoMiniatura }