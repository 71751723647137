import React from "react";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../CustomButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

/**
 * El componente BackButton proporciona un botón con un ícono de flecha para navegar hacia atrás en el historial del navegador.
 *
 * @component
 * @example
 * // Ejemplo de uso:
 * <BackButton label="Volver" />
 *
 * @param {Object} props - Props de React para el componente BackButton.
 * @param {string} props.label - La etiqueta para el botón.
 * @returns {React.Component} Retorna el componente BackButton.
 */
const BackButton = ({ label }) => {
  const navigate = useNavigate();

  /**
   * Maneja el evento de clic en el botón BackButton, navegando hacia atrás en el historial del navegador.
   */
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <CustomButton
      startIcon={<ArrowBackIcon />}
      text={label}
      variant="text"
      size="small"
      onClick={handleGoBack}
    />
  );
};

export { BackButton };
