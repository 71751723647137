import * as React from "react";
import { useRef } from "react";
import { Box, height } from "@mui/system";
import Container from '@mui/material/Container';

import { RazonesItem } from "./RazonesItem";

//import Carousel from "react-multi-carousel";
//import './styles.css';
import { Button, Grid, Stack, Fab } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { motion } from "framer-motion";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import  razonuno  from '../../assets/razonespecsa/uno.png';
import  razondos  from '../../assets/razonespecsa/dos.png';
import  razontres  from '../../assets/razonespecsa/tres.png';
import  razoncuatro  from '../../assets/razonespecsa/cuatro.png';
import  razoncinco  from '../../assets/razonespecsa/cinco.png';
import  razonseis  from '../../assets/razonespecsa/seis.png';
import  razonsiete  from '../../assets/razonespecsa/siete.png';
import  razonocho  from '../../assets/razonespecsa/ocho.png';
import Carousel from "react-material-ui-carousel";

const razones = [
    {
        titulo: "TU CASA EN TIEMPO RECORD",
        desc: "¡Tu casa en 23 días!* Si así de fácil y sin complicaciones, firma tus escrituras en 23 días o te abonamos tu separación.*Aplican restricciones.",
        imagen: razonuno,
    },
    {
        titulo: "SERVICIO POSTVENTA",
        desc: "El mejor servicio Post Venta lo tiene PECSA. A tu ritmo y sin perder tiempo.",
        imagen: razondos,
    },
    {
        titulo: "FAMILIA PECSA",
        desc: "Vive la experiencia de ser parte de una gran comunidad y recibe beneficios y experiencia únicas.",
        imagen: razontres,
    },
    {
        titulo: "TODO INCLUIDO",
        desc: "En nuestro catálogo Pecsa Todo Incluido* encontrarás todo lo que necesitas para que recibas tu casa lista para vivir. *Consulta costos con tu asesor.",
        imagen: razoncuatro,
    },
    {
        titulo: "COMPRA TU CASA 100% EN LINEA",
        desc: "O vive la increíble experiencia de visitar una de nuestras casas muestra.",
        imagen: razoncinco,
    },
    {
        titulo: "OFRECEMOS EL MEJOR FINANCIAMIENTO",
        desc: "Preaprobamos tu crédito en minutos con tasas únicas y exclusivas para cualquiera de nuestros desarrollos. *Sujeto a aprobación de crédito.",
        imagen: razonseis,
    },
    {
        titulo: "TE DAMOS HASTA 5 AÑOS DE GARANTÍA*",
        desc: "Y en pisos y azulejos es ¡DE POR VIDA! Increíble ¿No? *Consulta nuestra póliza de garantías",
        imagen: razonsiete,
    },
    {
        titulo: "TU CASA EN TU BOLSILLO",
        desc: "Da seguimiento a garantías, mantenimiento y trámites con un solo click.",
        imagen: razonocho,
    },
];

const OchoRazones = ({ titulo, color }) => {
    const constraintsRef = useRef(null);
    const containerRef = useRef(null);

    const [Xside, setXside] = React.useState(0);
    const atras = (Xside) => {
        setXside(Xside+395.96);
        console.log(constraintsRef.current.clientWidth);
        console.log(containerRef.current.clientWidth);
    };
    const adelante = (Xside) => {
        setXside(Xside-395.96);
        console.log(constraintsRef.current.clientWidth);
        console.log(containerRef.current.clientWidth);
    };

    //console.log(razones.length);
    // const Mostar = () => {
    //     for (let index = 0; index < razones.length; index++) {
    //         if(Number.isInteger(4 / (index + 1))){

    //         }
    //     }
    // }
    return (
        <Container sx={{  }}>
            <Box sx={{ color: color ? color : '#f5323f', fontSize:{ xs:12, sm:10, md:25 } }}>
                <h1>{ titulo ? titulo : '8 Razones para comprar una Casa Pecsa' }</h1>                
            </Box>            
            <Box
                sx={{
                    display: { xs:'none', sm:'block', md:'block', }
                }}
            >
                <Carousel
                    sx={{
                        width: '100%', 
                        height: { xs: 340, sm: 450, md: 450 }
                    }}
                >
                    {/* {
                        razones.map((razon, index)=>( */}
                            <Box
                                sx={{ 
                                    display: { xs: 'none', sm:'flex', md:'flex'},
                                }}
                            >
                                <RazonesItem razon={razones[0]} />
                                <RazonesItem razon={razones[1]} />
                                <RazonesItem razon={razones[2]} />
                                <RazonesItem razon={razones[3]} />
                            </Box>
                            <Box
                                sx={{ display: { xs: 'none', sm:'flex', md:'flex'} }}
                            >
                                <RazonesItem razon={razones[4]} />
                                <RazonesItem razon={razones[5]} />
                                <RazonesItem razon={razones[6]} />
                                <RazonesItem razon={razones[7]} />
                            </Box>
                    {/* ))
                    } */}
                </Carousel>
            </Box>
            <Box
                sx={{
                    display: { xs:'block', sm:'none', md:'none', }
                }}  
            >
                <Carousel
                    sx={{
                        width: '100%', 
                        height: { xs: 340, sm: 500, md: 450 }
                    }}
                >
                    {/* {
                        razones.map((razon, index)=>( */}
                            <Box
                                sx={{ 
                                    display: { xs: 'flex',  sm:'none', md:'none'},
                                }}
                            >
                                <RazonesItem razon={razones[0]} />
                                <RazonesItem razon={razones[1]} />                                
                            </Box>
                            <Box
                                sx={{ display: { xs: 'flex',  sm:'none', md:'none'} }}
                            >
                                <RazonesItem razon={razones[2]} />
                                <RazonesItem razon={razones[3]} />
                            </Box>
                            <Box
                                sx={{ display: { xs: 'flex',  sm:'none', md:'none'} }}
                            >
                                <RazonesItem razon={razones[4]} />
                                <RazonesItem razon={razones[5]} />
                            </Box>
                            <Box
                                sx={{ display: { xs: 'flex',  sm:'none', md:'none'} }}
                            >
                                <RazonesItem razon={razones[6]} />
                                <RazonesItem razon={razones[7]} />
                            </Box>
                    {/* ))
                    } */}
                </Carousel>
            </Box>
            {/* <Box
                component={motion.div}
                ref={constraintsRef}
                sx={{
                    //backgroundColor:'white', 
                    display: 'flex',
                    placeContent: 'start',
                    placeItems: 'center',
                    overflow: 'hidden',
                    position:'relative'
                }}
            >
                <Stack
                    ref={containerRef}
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                    component={motion.div}
                    animate={{ x:Xside }}
                    drag='x' 
                    dragConstraints={constraintsRef}
                >
                    { razones.map((razon) =>                   
                        
                        <Box
                        component={motion.div}
                        sx={{ 
                            width:{ xs: '185px', sm:'185px', md:'288px', lg:'300px' },
                            
                        }}
                    
                    >
                        <RazonesItem razon={razon}/>
                    </Box>
                    ) }
                </Stack>
                <Box 
                    component="Button" 
                    sx={{ 
                        pt: 3, pb:3, pl:0, pr:0,
                        backgroundColor:'white', 
                        border:'none',
                        color:'red',
                        zIndex:1, 
                        position:'absolute',
                        left:0,
                    }}
                    variant="contained"
                    onClick={()=> atras(Xside)}
                >
                  <ChevronLeftIcon />
                </Box>
                <Box 
                    component="Button" 
                    sx={{ 
                        pt: 3, pb:3, pl:0, pr:0,
                        backgroundColor:'white', 
                        border:'none',
                        color:'red',
                        zIndex:1, 
                        position:'absolute',
                        right:0,
                    }}
                    variant="contained"
                    onClick={()=> adelante(Xside)}
                >
                  <ChevronRightIcon />
                </Box>
            </Box> */}
        </Container>
    );
}

export { OchoRazones };