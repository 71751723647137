import React from "react";
import { Box } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Steps } from "../Steps";
import theme from "../../App/theme";

const CustomTimeline = ({ stepsList, isMdScreen }) => {
  return (
    <Timeline position={isMdScreen ? "alternate" : "right"}>
      {stepsList.map((step, index) => (
        <TimelineItem
          key={index}
          sx={{
            "&:hover img": {
              transform: "scale(1.3)",
              dropShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.35)",
            },
            "&:hover .timeline-content": {
              transform: `translateX(${
                isMdScreen && index % 2 !== 0 ? "-20px" : "20px"
              })`,
            },
          }}
        >
          <TimelineOppositeContent
            sx={{
              display: isMdScreen ? "block" : "none",
            }}
          />
          <TimelineSeparator>
            <TimelineConnector sx={{ bgcolor: theme.palette.grey[800] }} />
            <TimelineDot variant="outlined" color="inherit">
              <Box
                component="img"
                src={step.img}
                alt={`Step ${step.number}`}
                sx={{
                  width: "50px",
                  height: "50px",
                  transition: "all .5s ease",
                }}
              />
            </TimelineDot>
            <TimelineConnector sx={{ bgcolor: theme.palette.grey[800] }} />
          </TimelineSeparator>
          <TimelineContent
            className="timeline-content"
            sx={{
              marginBottom: { xs: "10px", sm: "20px", md: "30px" },
              transform: "translateX(0)",
              transition: "transform .5s ease",
            }}
          >
            <Steps step={step} />
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export { CustomTimeline };
