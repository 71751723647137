import { Grid, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";

const Restricciones = () => {
    return(
        <Container>
            <Box
                sx={{
                    // backgroundColor:'red',
                    minHeight:200,
                    mt:2,
                    mb:2,
                }}
            >
                <Grid 
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    {/* <Grid item xs={12} sm={12} md={12}>
                        <Box>
                            
                        </Box>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={6}>
                        <Box
                            sx={{
                                
                            }}
                        >
                            <Typography variant="h5" sx={{ color:'#F5323F', textAlign:'center', }}>
                                <b>Restricciones</b>
                            </Typography>
                            <Typography variant="body1" sx={{ color:'#707070' }}>
                                El pago del bono se desembolsara una vez finiquitado el proceso de compra-venta del recomendado. En caso de que el cliente llegara a cancelar la compra de la casa, el monto de la separación no es rembolsable y se perderá el bono de recomendación.
                            </Typography>
                            {/* <Typography variant="h6" sx={{ color:'#707070', }}>
                                Hay tres maneras de hacer llegar sus recomendaciones:
                            </Typography> */}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

export { Restricciones }