import React, { useEffect, useState } from "react";
import { Box, Container, ThemeProvider } from "@mui/material";
import { HeroSection } from "./HeroSection";
import { InvestmentAmountSection } from "./InvestmentAmountSection";
import { ListingSection } from "./ListingSection";
import { CTASection } from "./CTASection";
import theme from "../../App/theme";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import { BackButton } from "../../components/BackButton";

const InvestmentListing = () => {
  const [searchParams] = useSearchParams();
  const [activeButtonsCount, setActiveButtonsCount] = useState(0);
  const [isCTAVisible, setIsCTAVisible] = useState(false);
  const [idLotes, setIdLotes] = useState([]);
  const [inversionInicial, setInversionInicial] = useState(
    searchParams.get("inversionInicial")
  );

  const toggleCTAVisibility = () => {
    setIsCTAVisible(!isCTAVisible);
  };

  const addLotes = (newValue) => {
    if (idLotes.includes(newValue)) {
      setIdLotes(idLotes.filter((element) => element !== newValue));
    } else {
      setIdLotes([...idLotes, newValue]);
    }
  };

  const getInversionInicial = (newValue) => {
    setInversionInicial(newValue);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{ bgcolor: theme.palette.grey[200], mt: { xs: 7, sm: 8, md: 10 } }}
      >
        <Helmet>
          <title>
            Invierte en Casas Pecsa - Listado de casas | Casas Pecsa
          </title>
          <meta name="description" />
        </Helmet>
        <HeroSection />
        <Container>
          <BackButton label={"Regresar"} />
        </Container>
        <InvestmentAmountSection
          getInversionInicial={getInversionInicial}
          inversionInicial={inversionInicial}
        />
        <ListingSection
          isCTAVisible={isCTAVisible}
          toggleCTAVisibility={toggleCTAVisibility}
          activeButtonsCount={activeButtonsCount}
          setActiveButtonsCount={setActiveButtonsCount}
          addLotes={addLotes}
          inversionInicial={inversionInicial}
          getInversionInicial={getInversionInicial}
        />
        {activeButtonsCount > 0 && (
          <CTASection
            activeButtonsCount={activeButtonsCount}
            idLotes={idLotes}
            inversionInicial={inversionInicial}
          />
        )}
      </Box>
    </ThemeProvider>
  );
};

export { InvestmentListing };
