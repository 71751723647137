


import { Box, List, ListItem, } from '@mui/material';
import { ItemEducacion } from './ItemEducacion';

import EditIcon from '@mui/icons-material/Edit';

const ListaEducacion = ({ educacion }) => {
    return(
        <Box
            sx={{
                // backgroundColor:'red',
            }}
        >
            <List
                sx={{ width:'100%', }}
            >
                {
                    educacion.map((escuela)=>(
                        <ListItem sx={{ width:'100%', }}>
                            <ItemEducacion escuela={escuela} />
                        </ListItem>
                    ))
                }
            </List>
        </Box>
    );
}

export { ListaEducacion }