import axios from "axios";
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation, Navigate, Link } from "react-router-dom";
import { Alert, Avatar, Button, Checkbox, Container, Divider, FormControlLabel, Grid, Snackbar, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system";

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useState } from "react";

import logo from '../../assets/logo.svg'
import { CustomInputPassword } from "../../components/CustomInputPassword";

const LoginUsuarios = () => {
    let navigate = useNavigate();
    let location = useLocation();
    
    const [perfil, setPerfil] = useState(null);
    // console.log(location);
    const [open, setOpen] = useState(false);

    const MostarAlert = () => {
        setOpen(true);
    };
    
    const CerrarAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
    };

    const login = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // console.log({
        //   email: data.get('email'),
        //   password: data.get('password'),
        // });
        axios.post(`${process.env.REACT_APP_API_URL}/api/usuarios/login`, {
            email: data.get('email'),
            password: data.get('password'),
        })
        .then((response) => {
          // handle success
          console.log('logiiiiiiinnnn',response);
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('id', response.data.user.folio);
          localStorage.setItem('type', 'usuario');
          setPerfil(response.data.user.perfil.nombre);
          if(response.data.user.perfil.nombre === "Administrador"){
            navigate('/cargas');
          }
          if(response.data.user.perfil.nombre === "Asesor"){
            navigate(`/espacio-asesor/${response.data.user.folio}/perfil`);
          }
        //   navigate('/usuarios');
        })
        .catch((error) => {
          // handle success
          console.log(error);
          navigate('/usuarios/login');
        })
        .then(() => {
          // handle success
          console.log('sabe');          
        });
      };
    return(
        <Container sx={{ mt: { xs:8, md:15 }, minHeight:830, }}>
            <Grid 
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                
            >
                <Grid item md={6}>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            component='img'
                            src={logo}
                        />
                        {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar> */}
                        <Typography component="h1" variant="h5" sx={{ color: '#707070' }}>
                            Iniciar Sesion Usuarios Pecsa
                        </Typography>
                        <Box component="form" onSubmit={login} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Correo Electronico"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            {/* <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Contraseña"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            /> */}
                            <CustomInputPassword 
                                id="password"
                                name="password"
                                label="Contraseña"
                                required
                            />
                            {/* <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                            /> */}
                            <Button
                                type="submit"
                                fullWidth
                                variant="outlined" 
                                color="inherit" 
                                sx={{ /*fontSize:'11px',*/ 
                                    mt: 3,
                                    mb: 2,
                                    //width: { xs:'95%', sm:'90%', md:'100%' }, 
                                    p:{ xs:1, sm:1, md:1 },
                                    borderColor:'#F5323F',
                                    color:'#F5323F',
                                    borderWidth:3,
                                }}
                            >
                                <b>Ingresar</b>
                            </Button>
                            {/* <Grid container sx={{ mb:3 }}>
                                <Grid item>
                                    <Link to={'/olvide-password'}>
                                    {"¿Olvido su contraseña? O ¿Separo su casa y no sabe cuál es su contraseña? Clic Aquí"}
                                    </Link>
                                </Grid>
                            </Grid>
                            <Divider>ó</Divider>                            */}
                        </Box>                        
                            {/* <Button
                                
                                variant="outlined" 
                                color="inherit" 
                                sx={{ 
                                    mt: 3,
                                    mb: 2,
                                    width: { xs:'95%', sm:'90%', md:'95%' }, 
                                    p:{ xs:1, sm:1, md:1 },
                                    borderColor:'#707070',
                                    color:'#707070',
                                    borderWidth:3,
                                }}
                                onClick={()=>{ navigate('/registrarse'); }}
                            >
                                Registrarse
                            </Button>  */}
                    </Box>
                </Grid>
            </Grid>
            
            <Snackbar open={open} autoHideDuration={6000} onClose={CerrarAlert}>
                <Alert onClose={CerrarAlert} severity="error" sx={{ width: '100%' }}>
                    Usuario no encontrado
                </Alert>
            </Snackbar>
        </Container>
    );
}

export { LoginUsuarios }