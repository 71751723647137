import { Helmet } from "react-helmet"
import { Box } from '@mui/material';
import { useEffect } from 'react';
const ManyChatPixel = () => {

    
    // // window.MC_PIXEL.fireLogConversionEvent('Cita_Exitosa')
    return (
        <>
            <Helmet>
                <title>Cita realizada con exito! - Casas Pecsa</title>
                {/* <!-- Manychat --> */}
                <script src="//widget.manychat.com/321851611018871.js" defer="defer"></script>
                <script src="https://mccdn.me/assets/js/widget.js" defer="defer"></script>
                <script type= "text/javascript" id="manychat-pixel-script"> 
                    {
                        `
                        window.addEventListener ('load', function () {
                            window.MC_PIXEL.fireLogConversionEvent("Cita_Exitosa"); 
                        }); 
                        `
                    }
                </script>
            </Helmet>
            {/* <Box component="div" onLoad={window.MC_PIXEL.fireLogConversionEvent('Cita_Exitosa')}/> */}
        </>
    )
}

export { ManyChatPixel }