import * as React from "react";
import { useRef, useEffect } from "react";
import { Box, height } from "@mui/system";
import Container from '@mui/material/Container';

import { OpinionesItem } from "./OpinionesItem";

//import Carousel from "react-multi-carousel";
//import './styles.css';
import { Button, Grid, Stack, Fab } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { motion } from "framer-motion";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import  razonuno  from '../../assets/razonespecsa/uno.png';
import  razondos  from '../../assets/razonespecsa/dos.png';
import  razontres  from '../../assets/razonespecsa/tres.png';
import  razoncuatro  from '../../assets/razonespecsa/cuatro.png';
import  razoncinco  from '../../assets/razonespecsa/cinco.png';
import  razonseis  from '../../assets/razonespecsa/seis.png';
import  razonsiete  from '../../assets/razonespecsa/siete.png';
import  razonocho  from '../../assets/razonespecsa/ocho.png';
import Carousel from "react-material-ui-carousel";
import axios from "axios";

const razonesp = [
    {
        nombre: "Mia Silers",
        desc: "Tengo poco de haber comprado una casa con Pecsa. Y realmente fue la mejor decisión  de mi vida, primeramente tienen un excelente, rápido y muy profesional servicio de atención al cliente. Y en cuestión de las casas ni hablar; Las mejores de saltillo!!!",
        puntuacion: 5,
    },
    {
        nombre: "Sandra Vergara",
        desc: "Me encantan las casas de esta constructora porque manejan estilos contemporaneos, distribución súper óptima para vivir en una casa con múltiples detalles lindos. En particular me considero una cliente exigente y casas Pecsa ha sabido entender las expectativas que tienen los clientes. La relacion precio -calidad va de la mano y ademas de todo esto siempre manejan promociones increíbles.  Finalmente su diseñadora de interiores hace un trabajo impecable que hace enamorarte de sus proyectos como un todo.",
        puntuacion: 5,
    },
    {
        nombre: "Chuy Galaviz",
        desc: "Excelente atención",
        puntuacion: 4,
    },
];

const OpinionesFamiliaPecsa = () => {
    const [reviewsBD, setReviewsBD] = React.useState([]);
    const [razones, setRazones] = React.useState(razonesp);
    
    const constraintsRef = useRef(null);
    const containerRef = useRef(null);

    const [Xside, setXside] = React.useState(0);
    const atras = (Xside) => {
        setXside(Xside+395.96);
        console.log(constraintsRef.current.clientWidth);
        console.log(containerRef.current.clientWidth);
    };
    const adelante = (Xside) => {
        setXside(Xside-395.96);
        console.log(constraintsRef.current.clientWidth);
        console.log(containerRef.current.clientWidth);
    };
    const unirReviews = () => {
        const addreviews = reviewsBD.map((review) => {
            return { nombre: review.nombre, desc: review.descripcion, puntuacion:review.calificacion }
        });
        setRazones([...razones, ...addreviews]);
    }
    useEffect(()=>{
        unirReviews();
    },[reviewsBD]);
    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_API_URL}/api/review/modelo/list`)
        .then((response) => {
            // handle success
            console.log(response);
            setReviewsBD(response.data);
        })
        .catch((error) => {
            // handle success
            console.log(error);
        })
        .then(() => {
            // handle success
            //console.log('sabe');
        });
    },[])
    //console.log(razones.length);
    return (
        <Container sx={{ p:5, color:'#DE4647' }}>
            <Box sx={{ color:'#f5323f',/*backgroundColor:'yellow'*/ }}>
                <h1>Lo que opina nuestra familia Pecsa</h1>                
            </Box>
            {/* <Box
                component={motion.div}
                ref={constraintsRef}
                sx={{
                    //backgroundColor:'white', 
                    display: 'flex',
                    placeContent: 'start',
                    placeItems: 'center',
                    overflow: 'hidden',
                    position:'relative'
                }}
            >
                <Stack
                    ref={containerRef}
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                    component={motion.div}
                    animate={{ x:Xside }}
                    drag='x' 
                    dragConstraints={constraintsRef}
                >
                    { razones.map((razon) =>                   
                        
                        <Box
                        component={motion.div}
                        sx={{ 
                            width:{ xs: '300px', sm:'820px', md:'1150px', },
                            //backgroundColor:'#DE4647',
                        }}
                    
                    >
                        <OpinionesItem razon={razon}/>
                    </Box>
                    ) }
                </Stack>
                <Box 
                    component="Button" 
                    sx={{ 
                        pt: 3, pb:3, pl:0, pr:0,
                        backgroundColor:'white', 
                        border:'none',
                        color:'red',
                        zIndex:1, 
                        position:'absolute',
                        left:0,
                    }}
                    variant="contained"
                    onClick={()=> atras(Xside)}
                >
                  <ChevronLeftIcon />
                </Box>
                <Box 
                    component="Button" 
                    sx={{ 
                        pt: 3, pb:3, pl:0, pr:0,
                        backgroundColor:'white', 
                        border:'none',
                        color:'red',
                        zIndex:1, 
                        position:'absolute',
                        right:0,
                    }}
                    variant="contained"
                    onClick={()=> adelante(Xside)}
                >
                  <ChevronRightIcon />
                </Box>
            </Box> */}
            <Box sx={{ height:{xs:400, sm:400, md:400, /*backgroundColor:'yellow'*/ }, }}>
                <Carousel sx={{ width:'100%', height:'100%' }}>
                    { 
                        razones.map((razon, index) =>                                           
                            <Box
                                key={`OpinionesPecsa-${index}`}
                                // component={motion.div}
                                sx={{ 
                                    //width:{ xs: '300px', sm:'820px', md:'1150px', },
                                    //backgroundColor:'#DE4647',
                                }}
                            
                            >
                                <OpinionesItem key={`OpinionesPecsaItem-${index}`} razon={razon}/>
                            </Box>
                        ) 
                    }
                    
                </Carousel>
            </Box>
        </Container>
    );
}

export { OpinionesFamiliaPecsa };