import { useNavigate, useLocation } from "react-router-dom";
import { Button, Card, CardActionArea, CardContent, CardMedia, Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ApartmentSharpIcon from '@mui/icons-material/ApartmentSharp';

const MenuEtapas = (props) => {
    let navigate = useNavigate();
    const location = useLocation();
    console.log(location.state || 'nada');

    return(
        <Container sx={{ backgroundColor:'white' }}>
             
            <Box sx={{  }}>                
                <h1>Etapas</h1>                                 
            </Box>
            <Box sx={{  }}>                
                <Grid container 
                    direction="row" 
                    spacing={{ xs: 2, md: 3 }} 
                    columns={{ xs: 12, sm: 12, md: 12, lg:12 }}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                        <Card>
                            <CardActionArea onClick={()=>{ navigate('add', {state:{fraccionamiento:props.fraccionamiento}})}}>
                                <CardContent>
                                    <AddCircleOutlineIcon
                                        sx={{
                                            fontSize:100,
                                            color: "text.secondary"
                                        }}
                                    />
                                    <Typography color="text.secondary" gutterBottom variant="h5" component="div">
                                        Agregar Etapa
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    {
                        props.fraccionamiento.etapas.map((etapa) => ( 
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <Card>
                                    <CardActionArea onClick={() => { navigate(`${etapa.folio}`, {state:{etapa:etapa}}) }}>
                                        <CardContent>

                                            <ApartmentSharpIcon
                                                sx={{
                                                    fontSize:100,
                                                    color: "text.secondary"
                                                }}
                                            />
                                            <Typography color="text.secondary" gutterBottom variant="h5" component="div">
                                                { etapa.nombre}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))
                    }
                    
                </Grid>                                
            </Box>
        </Container>
    );
}

export { MenuEtapas }