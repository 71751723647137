import { Alert, Avatar, Breadcrumbs, Button, ButtonBase, ButtonGroup, Card, CardActions, CardContent, CardHeader, CardMedia, Grid, IconButton, ImageList, ImageListItem, List, ListItem, ListItemText, Snackbar, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";

import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PhotoIcon from '@mui/icons-material/Photo';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useState } from "react";


import asesorPerfil2 from "../../assets/asesores/asesorPerfil2.png";
import imagenPromo from "../../assets/prueba/imagenPromo.png";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import moment from "moment";

const EspacioAsesorPerfil = () => {
  // let navigate = useNavigate();
  const { folio } = useParams()
  const [asesor, setAsesor] = useState(null);

  const [primerNombre, setPrimerNombre] = useState('');
  const [segundoNombre, setSegundoNombre] = useState('');
  const [primerApellido, setPrimerApellido] = useState('');
  const [segundoApellido, setSegundoApellido] = useState('');
  const [fechaNacimiento, setFechaNacimiento] = useState(null);
  const [telefono, setTelefono] = useState('');
  const [email, setEmail] = useState('');
  const [descripcion, setDescripcion] = useState('');

  const [selectedImage, setSelectedImage] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);

  const [openAlert, setOpenAlert] = useState(false);

  const handleClickAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };
  const [openError, setOpenError] = useState(false);

  const handleClickError = () => {
    setOpenError(true);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenError(false);
  };

  const add = (event) => {
    event.preventDefault();
        const data = new FormData(event.currentTarget);
        
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');

        if(selectedImage === []){
          console.log('hehd');
          return;
        }

        data.set('nombre', ( data.get('primerNombre')+' '+data.get('segundoNombre')+' '+data.get('primerApellido')+' '+data.get('segundoApellido')))
        data.set('fechaNacimiento', fechaNacimiento.format('yyyy-MM-DD HH:mm'))
        // console.log('nombre', data.get('nombre'))
        // console.log('email', data.get('email'))
        // console.log('telefono', data.get('telefono'))
        // console.log('fotoImg', data.get('fotoImg'))
        // console.log('primerNombre', data.get('primerNombre'))
        // console.log('segundoNombre', data.get('segundoNombre'))
        // console.log('primerApellido', data.get('primerApellido'))
        // console.log('segundoApellido', data.get('segundoApellido'))
        // console.log('descripcion', data.get('descripcion'))
        // console.log('fechaNacimiento', data.get('fechaNacimiento'))
        // console.log(data); return;
        axios.post(`${process.env.REACT_APP_API_URL}/api/asesores/profile/${folio}`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
        .then((response)=>{
            console.log(response);
            handleClickAlert();
            // addListExperiencia({
            //     nombre: data.get('nombre'),
            //     puesto: data.get('puesto'),
            //     fechaInicio: fechaInicio.format('yyyy-MM-DD HH:mm'),
            //     fechaFinal: fechaFinal.format('yyyy-MM-DD HH:mm'),
            // });
            // getAgregar()
            // setInformacion(response.data)            
        })
        .catch((error)=>{
            console.log(error);
            handleClickError();
        });
  }
  
  useEffect(()=>{
      const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');

      axios.get(`${process.env.REACT_APP_API_URL}/api/asesores/profile/${folio}`,
          {
              headers: {
                  Authorization: `Bearer ${token}`
              }
          }
      )
      .then((response)=>{
          console.log(response);
          setAsesor(response.data)            
      })
      .catch((error)=>{
          console.log(error);
      });
  }, [folio]);
  useEffect(()=>{
    if(asesor){
      if(asesor.datos){
        setPrimerNombre(asesor.datos.primerNombre)
        setSegundoNombre(asesor.datos.segundoNombre)
        setPrimerApellido(asesor.datos.primerApellido)
        setSegundoApellido(asesor.datos.segundoApellido)
        setFechaNacimiento(moment(asesor.datos.fechaNacimiento))
        setTelefono(asesor.telefono)
        setEmail(asesor.email)
        setDescripcion(asesor.datos.descripcion)
      }
    }
  }, [asesor]);
  useEffect(()=>{
    if(selectedImage.length > 0){
        setImageUrl(URL.createObjectURL(selectedImage[0]));
    }
  },[selectedImage]);

  return(
      <Box
          sx={{
              // backgroundColor:'red',
              width:'100%',
          }}
      >
        <Box>
          <Breadcrumbs aria-label="breadcrumb">
              {/* <Link underline="hover" color="inherit">
                  Inicio
              </Link> */}
              <Link to={'/'} style={{ color:'#707070', }}>
                  Espacio Asesor
              </Link>
              <Typography sx={{ color:'#F5323F', }}>Perfil</Typography>
          </Breadcrumbs>
        </Box>
        <Box component="form" onSubmit={add} encType="multipart/form-data">
          <List>
            {/* <ListItem disablePadding divider>
              <ListItemText primary={
                <Box
                  sx={{
                    mb:2
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography variant="h6">
                        Foto de Perfil
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <label htmlFor="contained-button-file">                            
                            <input accept="image/*" id="contained-button-file" type="file" name="fotoImg" style={{ display: 'none' }} onChange={e => setSelectedImage([e.target.files[0]])}/>
                            {
                              (imageUrl && selectedImage) ? (
                                <Button endIcon={<PhotoIcon />} component="span" sx={{ color:'#50B767' }}>
                                    Cambiar Foto
                                </Button>                               
                              ) : (
                                asesor && (
                                  asesor.foto ? (
                                    <Button endIcon={<PhotoIcon />} component="span" sx={{ color:'#50B767' }}>
                                      Cambiar Foto
                                    </Button> 
                                  ) : (
                                    <Button endIcon={<AddAPhotoIcon />} component="span" sx={{ color:'#F5323F' }}>
                                      Cargar Foto
                                    </Button>
                                  )
                                )
                                
                              )
                            }                                
                        </label> 
                    </Grid>                  
                  </Grid>
                </Box>
              }/>
            </ListItem> */}
            <ListItem disablePadding divider>
              <ListItemText primary={
                <Box
                  sx={{
                    mb:2
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography variant="h6">
                        Descripcion de mi:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={8}>
                      <Box>
                        <TextField
                          id="outlined-multiline-static"
                          label="Descripcion sobre mi..."
                          multiline
                          rows={2}
                          value={descripcion}
                          onChange={(e)=>{setDescripcion(e.target.value)}}
                          sx={{
                            width:'100%',
                          }}
                          name="descripcion"
                          required
                          helperText="Maximo 3000 caracteres"
                        />
                      </Box>
                    </Grid>                  
                  </Grid>
                </Box>
              }/>
            </ListItem>
            <ListItem disablePadding divider>
              <ListItemText primary={
                <Box
                  sx={{
                    mb:2
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography variant="h6">
                        Datos de Perfil
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField id="outlined-basic" label="Primer Nombre" variant="outlined" name="primerNombre" value={primerNombre} onChange={(e)=>{setPrimerNombre(e.target.value)}} sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField id="outlined-basic" label="Segundo Nombre" variant="outlined" name="segundoNombre" value={segundoNombre} onChange={(e)=>{setSegundoNombre(e.target.value)}} sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField id="outlined-basic" label="Primer Apellido" variant="outlined" name="primerApellido" value={primerApellido} onChange={(e)=>{setPrimerApellido(e.target.value)}} sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField id="outlined-basic" label="Segundo Apellido" variant="outlined" name="segundoApellido" value={segundoApellido} onChange={(e)=>{setSegundoApellido(e.target.value)}} sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                              label="Fecha de Nacimiento"
                              value={fechaNacimiento}
                              onChange={(newValue) => {
                                  setFechaNacimiento(newValue);
                              }}
                              name="fechaNacimiento"
                              renderInput={(params) => <TextField {...params} />}
                          />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Box>
              }/>
            </ListItem>
            <ListItem disablePadding divider>
              <ListItemText primary={
                <Box
                  sx={{
                    mb:2
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography variant="h6">
                        Datos de Contacto
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField id="outlined-basic" label="Telefono" variant="outlined" name="telefono" value={telefono} onChange={(e)=>{setTelefono(e.target.value)}} sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField id="outlined-basic" label="Correo Electronico" variant="outlined" name="email" value={email} onChange={(e)=>{setEmail(e.target.value)}} sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid>                  
                  </Grid>
                </Box>
              }/>
            </ListItem>
          </List>
          <Box
              sx={{
                  mt:1,
                  // mb:1,
                  // backgroundColor:'red',
                  width:'100%',
                  textAlign:'center',
              }}
          >
              <Button 
                  variant="contained" 
                  color="inherit"
                  endIcon={<EditIcon />}
                  sx={{
                      backgroundColor:'#707070',
                      textTransform:'none',
                      borderRadius:'10px',
                      width:{ xs:'100%', sm:'100%', md:'50%' },
                      color:'white',
                      '&:hover':{
                        backgroundColor:'#595959',
                        color:'white',
                      }
                  }}
                  type="submit"
              >
                  <b>Guardar</b>
              </Button>
          </Box>
        </Box>
        <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert} anchorOrigin={{ vertical:'bottom', horizontal:'center' }}>
          <Alert onClose={handleCloseAlert} severity="success" sx={{ width: '100%' }}>
            ¡Informacion guardada con exito!
          </Alert>
        </Snackbar>
        <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError} anchorOrigin={{ vertical:'bottom', horizontal:'center' }}>
          <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
            La informacion no fue guardada
          </Alert>
        </Snackbar>
      </Box>
  );
}

export { EspacioAsesorPerfil }