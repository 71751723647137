import { Button, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";

import { motion } from "framer-motion";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { VideoMiniatura } from "./VideoMiniatura";
import { ShortItem } from "./ShortItem";

const ColeccionShorts = ({ shorts }) => {
    console.log(shorts);
    const [verMas, setVerMas] = useState(false);
    const getVerMas = () => {
        setVerMas(!verMas);
    }
    const constraintsRef = useRef(null);
    const containerRef = useRef(null);

    const constraintsRefMovil = useRef(null);
    const containerRefMovil = useRef(null);

    const [Xside, setXside] = useState(0);
    const [XIside, setXIside] = useState(0);

    const [constraintsWidth, setConstraintsWidth] = useState(0);
    const [containerWidth, setContainerWidth] = useState(0);
  
    const atras = (Xside) => {
        console.log(XIside);
        console.log((XIside + 384));
        console.log(constraintsWidth);
        console.log(containerWidth);
        setXside(Xside+384);
        setXIside((XIside - 384) < constraintsWidth ? constraintsWidth : (XIside - 384))
        // console.log(XIside);
        // console.log(constraintsRef.current.clientWidth);
        // console.log(containerRef.current.clientWidth);
        
    };
    const adelante = (Xside) => {
        console.log('adelante')
        console.log(XIside);
        console.log((XIside - 384));
        console.log(constraintsWidth);
        console.log(containerWidth);
        setXside(Xside-384);      
        setXIside((XIside + 384) > containerWidth ? containerWidth : (XIside + 384))
        // console.log(XIside);
        // console.log(containerRef.current.clientWidth);
    };
    useEffect(() => {
        setXIside(constraintsRef.current.clientWidth);
        setConstraintsWidth(constraintsRef.current.clientWidth)
        setContainerWidth(containerRef.current.clientWidth)
        //
    }, [setXIside, setConstraintsWidth, setContainerWidth]);
    return(
        <Box>
            <Box>
                <Typography variant="h5" sx={{ color:'#F5323F', }}>
                    <b>{shorts.titulo}</b>
                </Typography>
            </Box>
            <Box
                sx={{
                    pb:2,
                }}
            >
                <Box
                    component={motion.div}
                    ref={constraintsRef}
                    sx={{
                        backgroundColor:'white', 
                        display: { xs: 'none', md: 'flex' },
                        placeContent: 'start',
                        placeItems: 'center',
                        overflow: 'hidden',
                        position:'relative',
                        marginTop:2,
                        mb:2,
                    }}
                >
                    <Stack
                        ref={containerRef}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        // spacing={2}
                        component={motion.div}
                        animate={{ x:Xside }}
                    >
                        {
                            shorts.videos.map((video) => (
                                <Box
                                    component={motion.div}
                                    sx={{
                                        // backgroundColor:'brown', 
                                        width:{ xs: '185px', sm:'185px', md:'185px', },
                                        minHeight:{ xs: '185px', sm:'185px', md:'300px', },
                                        ml:0.5,
                                        mr:0.5,
                                    }}  
                                >
                                    <ShortItem video={video} />
                                </Box>
                            ))
                        }
                        
                    </Stack>
                    {
                        XIside !== constraintsWidth && (
                            <Box 
                                component="Button" 
                                sx={{ 
                                    pt: 3, pb:3, pl:0, pr:0,
                                    backgroundColor:'white', 
                                    border:'none',
                                    color:'#f5323f',
                                    zIndex:1, 
                                    position:'absolute',
                                    left:0,
                                }}
                                variant="contained"
                                onClick={()=> atras(Xside)}
                            >
                                <ChevronLeftIcon />
                            </Box>
                        )
                    }
                    {
                        XIside !== containerWidth && (
                            <Box 
                                component="Button" 
                                sx={{ 
                                    pt: 3, pb:3, pl:0, pr:0,
                                    backgroundColor:'white', 
                                    border:'none',
                                    color:'#f5323f',
                                    zIndex:1, 
                                    position:'absolute',
                                    right:0,
                                }}
                                variant="contained"
                                onClick={()=> adelante(Xside)}
                            >
                                <ChevronRightIcon />
                            </Box>
                        )
                    }
                </Box>
                <Box
                    component={motion.div}
                    ref={constraintsRefMovil}
                    sx={{
                        backgroundColor:'white', 
                        display: { xs: 'flex', md: 'none' },
                        placeContent: 'start',
                        placeItems: 'center',
                        overflow: 'hidden',
                        position:'relative',
                        marginTop:2,
                        mb:3,
                    }}
                >
                    <Stack
                        ref={containerRefMovil}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        // spacing={2}
                        component={motion.div}
                        animate={{ x:Xside }}
                        drag='x' 
                        dragConstraints={constraintsRefMovil}
                    >
                        {
                            shorts.videos.map((video) => (
                                <Box
                                    component={motion.div}
                                    sx={{
                                        // backgroundColor:'brown', 
                                        width:{ xs: '155px', sm:'155px', md:'185px', },
                                        minHeight:{ xs: '250px', sm:'175px', md:'300px', },
                                        ml:0.5,
                                        mr:0.5,
                                    }}  
                                >
                                    <ShortItem video={video} />
                                </Box>
                            ))
                        }
                    </Stack>
                </Box>
            </Box>
        </Box>
    );
}

export { ColeccionShorts }