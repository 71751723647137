import { Box, Container } from "@mui/material";
import { Link } from "react-router-dom";
import { CustomTypography } from "../../../components/CustomTypography";
import { CustomButton } from "../../../components/CustomButton";
import theme from "../../../App/theme";

const CTASection = () => {
  return (
    <Box
      component="section"
      sx={{
        background: theme.palette.grey[200],
        pt: "2.5rem",
        pb: "3.125rem",
        [theme.breakpoints.up("sm")]: {
          pt: "5rem",
          pb: "6.25rem",
        },
      }}
    >
      <Container
        sx={{
          display: "flex",
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
          gap: 3.75,
        }}
      >
        <Box>
          <CustomTypography
            variant="subheadingPrimary"
            txtColor={theme.palette.primary.main}
          >
            Encuentra tu Casa Pecsa para Rentar
          </CustomTypography>
        </Box>

        <Box>
          <Link to="/renta#listing">
            <CustomButton
              text="Ver Casas Disponibles"
              variant="contained"
              color="primary"
            />
          </Link>
        </Box>
      </Container>
    </Box>
  );
};

export { CTASection };
