import { Box, Container } from "@mui/material";
import { CustomTypography } from "../../../components/CustomTypography";
import theme from "../../../App/theme";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../../components/CustomButton";

const CTASection = () => {
  let navigate = useNavigate();
  return (
    <Box
      component="section"
      sx={{
        backgroundColor: theme.palette.grey[200],
        pt: "2.5rem",
        pb: "3.125rem",
        [theme.breakpoints.up("sm")]: {
          pt: "5rem",
          pb: "6.25rem",
        },
      }}
    >
      <Container
        sx={{
          display: "flex",
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
          gap: 3.75,
        }}
      >
        <Box>
          <CustomTypography
            variant="subheadingPrimary"
            txtColor={theme.palette.primary.main}
          >
            Encuentra tu Casa Pecsa para Invertir
          </CustomTypography>
        </Box>

        <Box>
          <CustomButton
            text="Quiero Invertir"
            variant="contained"
            onClick={() => navigate("listado")}
            color="primary"
          ></CustomButton>
        </Box>
      </Container>
    </Box>
  );
};

export { CTASection };
