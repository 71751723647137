import * as React from 'react';
import { useEffect, useState } from "react";
import { Button, Container, Divider, Grid, Stack } from "@mui/material";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SignpostSharpIcon from '@mui/icons-material/SignpostSharp';
import { useNavigate } from 'react-router-dom';

const DatosManzana = (props) => {
    const navigate = useNavigate();
    const[manzana, setManzana] = useState(props.datos);
    return(
        <Container sx={{ backgroundColor:'red' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" variant="middle" flexItem />}
                        spacing={2}
                        
                    >
                        {/* <Box
                            component="img"
                            src={m146}
                            sx={{ 
                                width: 500,
                                backgroundColor: 'white',
                                borderRadius: '16px',
                                m:3,
                                boxShadow: 3
                            }}
                        /> */}
                        <Box
                            component="div"
                            
                            sx={{ 
                                width: 240,
                                backgroundColor: 'white',
                                borderRadius: '50%',
                                m:3,
                                boxShadow: 3
                            }}
                        >
                            <SignpostSharpIcon
                                sx={{
                                    fontSize:180,
                                    color: "text.secondary"
                                }}
                            />
                        </Box>
                        <Box
                            sx={{ 
                                backgroundColor: 'white',
                                p:3,
                                display: 'block'
                            }}
                        >
                            <Typography gutterBottom variant="h4" component="div" align='left'>
                                {manzana.nombre}
                            </Typography>
                            <Typography variant="h6" component="div" color="text.secondary" align='left'>
                                Folio: {manzana.folio}
                            </Typography>
                            <Typography variant="h6" component="div" color="text.secondary" align='left'>
                                <LocationOnIcon/> Fraccionamiento
                            </Typography>
                            <Typography variant="h6" component="div" color="text.secondary" align='left'>
                                <LocationOnIcon/> Etapa
                            </Typography>
                            <Typography variant="h6" component="div" color="text.secondary" align='left'>
                                <LocationOnIcon/> Saltillo, Coahuila
                            </Typography>
                            <Box>
                                <Button 
                                    variant="outlined" 
                                    startIcon={<EditIcon />}
                                    sx={{ 
                                        width: '100%',
                                        marginTop: 2
                                    }}
                                    onClick={()=>navigate('edit')}
                                >
                                    Editar
                                </Button>
                                <Button 
                                    variant="outlined" 
                                    startIcon={<DeleteIcon />}
                                    sx={{ 
                                        width: '100%',
                                        marginTop: 1
                                    }}
                                >
                                    Borrar
                                </Button>
                            </Box>                            
                        </Box>                        
                    </Stack>
                </Grid>
                {/* <Grid item xs={6} md={9}>
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" variant="middle" flexItem />}
                        spacing={2}
                        sx={{ backgroundColor: 'greenyellow' }}
                    >
                        <Box
                            component="img"
                            src={m146}
                        />
                        <Box>
                            <Typography gutterBottom variant="h5" component="div">
                                {manzana.nombre}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Folio: {manzana.folio}
                            </Typography>
                        </Box>
                    </Stack>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Button variant="outlined" startIcon={<EditIcon />}>
                        Editar
                    </Button>
                    <Button variant="outlined" startIcon={<DeleteIcon />}>
                        Borrar
                    </Button>
                </Grid> */}
            </Grid>
        </Container>
    );
}

export { DatosManzana }