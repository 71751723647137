import * as React from "react";
import { useRef, useState } from "react";
import { Box, height } from "@mui/system";
import Container from '@mui/material/Container';

import { AmenidadFraccionamiento } from "./AmenidadFraccionamiento";

//import Carousel from "react-multi-carousel";
//import './styles.css';
import { Button, Grid, Stack, Fab, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { motion } from "framer-motion";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Carousel from "react-material-ui-carousel";
import { useEffect } from "react";

const AmenidadesFraccionamiento = ({ amenidades }) => {
    // console.log(props.amenidades);

    // const [amenidades, setAmenidades] = useState(props.amenidades || []);

    const constraintsRef = useRef(null);
    const containerRef = useRef(null);

    //const Contenedor = containerRef.current.clientWidth == null || containerRef.current.clientWidth == undefined ? 0 : containerRef.current.clientWidth;
    // const DivVisible = constraintsRef.current.clientWidth;
    // const Diferencia = Contenedor - DivVisible;

    const [Xside, setXside] = React.useState(0);
    const atras = (Xside) => {
      setXside(Xside+395.96);
      console.log(constraintsRef.current.clientWidth);
      console.log(containerRef.current.clientWidth);
    };
    const adelante = (Xside) => {
      setXside(Xside-395.96);
      console.log(constraintsRef.current.clientWidth);
    console.log(containerRef.current.clientWidth);
    };
    useEffect(()=>{
      console.log('window.width',window.width);
    },[amenidades]);
    return(
        <Container sx={{  }}>
            <Box sx={{ textAlign:'left', marginTop:2, color:'#F5323F',  }}>
              <h1>Amenidades del Fraccionamiento</h1>
            </Box>
            <Box sx={{ height:{xs:440, sm:340, md:440, }, }}>
              <Carousel sx={{ width:'100%', height:'100%' }}>
                {
                  amenidades.map((amenidad, index)=>{
                    if(index % 2 === 0 && window.width > 400 ){                      
                      return(
                        <Box
                          sx={{
                            // backgroundColor:'red',
                            height:{xs:400, sm:300, md:400, },
                          }}
                        >
                          <Grid 
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Grid item xs={12} sm={6} md={6}>
                              <Box
                                sx={{
                                  ml:{xs:1, sm:7, md:7, },
                                  mr:{xs:1, sm:2, md:7, },
                                }}
                              >
                                <Typography variant="h4" sx={{ color:'#F5323F' }}>
                                  <b>{ amenidad.nombre }</b>
                                </Typography>
                                <Typography variant="body1" sx={{ color:'#707070' }}>
                                  { amenidad.descripcion }
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item sm={6} md={6}>
                              <Box
                                sx={{
                                  // backgroundColor:'green',
                                  width:'100%',
                                  height:{xs:400, sm:300, md:400, },
                                  display:'flex',
                                  alignItems:'center'
                                }}
                              >
                                <Box
                                  component='img'
                                  src={amenidad.foto}
                                  sx={{
                                    minHeight:{xs:400, sm:150, md:200, },
                                    maxHeight:{xs:400, sm:180, md:250, },
                                    borderRadius:'14px',
                                    boxShadow: '0px 5px 12px 0px rgb(0, 0, 0, 0.19)',
                                    m:'0 auto'
                                  }}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      );
                    }
                    else{
                      return(
                        <Box
                          sx={{
                            // backgroundColor:'red',
                            height:{xs:400, sm:300, md:400, },
                          }}
                        >
                          <Grid 
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Grid item xs={12} sm={6} md={6}>
                              <Box
                                sx={{
                                  // backgroundColor:'green',
                                  width:'100%',
                                  height:{xs:180, sm:300, md:400, },
                                  display:'flex',
                                  alignItems:'center'
                                }}
                              >
                                <Box
                                  component='img'
                                  src={amenidad.foto}
                                  sx={{
                                    minHeight:{xs:150, sm:150, md:200, },
                                    maxHeight:{xs:170, sm:180, md:250, },
                                    borderRadius:'14px',
                                    boxShadow: '0px 5px 12px 0px rgb(0, 0, 0, 0.19)',
                                    m:'0 auto'
                                  }}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                            <Box
                                sx={{
                                  ml:{xs:1, sm:2, md:7, },
                                  mr:{xs:1, sm:7, md:7, },
                                }}
                              >
                                <Typography variant="h4" sx={{ color:'#F5323F' }}>
                                  <b>{ amenidad.nombre }</b>
                                </Typography>
                                <Typography variant="body1" sx={{ color:'#707070' }}>
                                  { amenidad.descripcion }
                                </Typography>
                              </Box>
                            </Grid>                            
                          </Grid>
                        </Box>
                      );
                    }
                  })
                }
                
                {/* <Box>
                  <Grid 
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item md={6}>sss2</Grid>
                    <Grid item md={6}>fotos2</Grid>
                  </Grid>
                </Box> */}
              </Carousel>
            </Box>      
            {/* <Box
              component={motion.div}
              ref={constraintsRef}
              sx={{
                //backgroundColor:'white', 
                display: 'flex',
                placeContent: 'start',
                placeItems: 'center',
                overflow: 'hidden',
                position:'relative',
                marginTop: 5,
                marginBottom: 9
              }}
            >
              <Stack
              ref={containerRef}
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                component={motion.div}
                animate={{ x:Xside }}
                drag='x' 
                dragConstraints={constraintsRef}
              >
                {
                  amenidades.length > 0 && (
                    amenidades.map((amenidad) => (
                      <Box
                        component={motion.div}
                        sx={{ 
                          // width: '375px'
                          // width: '185px'
                          width:{ xs: '165px', sm:'245px', md:'375px', lg:'375px' }
                          
                        }}
                        
                      >
                        <AmenidadFraccionamiento amenidad ={amenidad}/>  
                      </Box>
                    ))
                  )
                }
                
                               
              </Stack>
              <Box 
                component="Button" 
                sx={{ 
                  pt: 3, pb:3, pl:0, pr:0,
                  backgroundColor:'white', 
                  border:'none',
                  color:'red',
                  zIndex:1, 
                  position:'absolute',
                  left:0,
                }}
                variant="contained"
                onClick={()=> atras(Xside)}
              >
                  <ChevronLeftIcon />
                </Box>
                <Box 
                component="Button" 
                sx={{ 
                  pt: 3, pb:3, pl:0, pr:0,
                  backgroundColor:'white', 
                  border:'none',
                  color:'red',
                  zIndex:1, 
                  position:'absolute',
                  right:0,
                }}
                variant="contained"
                onClick={()=> adelante(Xside)}
              >
                  <ChevronRightIcon />
                </Box>
            </Box> */}

                

        </Container>
    );
}

export { AmenidadesFraccionamiento }