import { Button, Checkbox, Container, Dialog, DialogActions, DialogContent, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Amenidades } from "./Amenidades";
import { Modelo } from "./Modelo";
import { Portada } from "./Portada";

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

// import M106 from '../../../assets/landingPages/morillo/modelos/106.png';
// import M146 from '../../../assets/landingPages/morillo/modelos/146.png';


// import amenidad1 from '../../../assets/landingPages/morillo/amenidades/canchas.jpg';
// import amenidad2 from '../../../assets/landingPages/morillo/amenidades/dog_park.jpg';
// import amenidad3 from '../../../assets/landingPages/morillo/amenidades/juegitos.jpg';
// import amenidad4 from '../../../assets/landingPages/morillo/amenidades/juegitos_2.jpg';
import { InlineWidget } from "react-calendly";
import { useState } from "react";
import { Experiencia } from "./Experiencia";
import { Helmet } from "react-helmet";
import { MyCalendlyComponent } from "./MyCalendlyComponent";
import { Ubicacion } from "./Ubicacion";
import { Formulario } from "./Formulario";
import { Garantias } from "../../../components/Garantias";
import { OchoRazones } from "../../../components/OchoRazones";
import TagManager from "react-gtm-module";
import banorte from '../../../assets/solicitacredito/banorte.svg';
import bbva from '../../../assets/solicitacredito/bbva.svg';
import fobissste from '../../../assets/solicitacredito/fobissste.svg';
import HSBC from '../../../assets/solicitacredito/HSBC.svg';
import infonavit from '../../../assets/solicitacredito/infonavit.svg';
import scotiabank from '../../../assets/solicitacredito/scotiabank.svg';
import { PopupFoto } from "../../../components/Popups/PopupFoto";
import { BannerAnuncio } from "./BannerAnuncio";

const bancosPreautorizan = [
    { nombre:'Banorte', imagen:banorte, },
    { nombre:'BBVA', imagen:bbva, },
    // { nombre:'Fobissste', imagen:fobissste, },
    { nombre:'HSBC', imagen:HSBC, },
    // { nombre:'Infonavit', imagen:infonavit, },
    { nombre:'ScotiaBank', imagen:scotiabank, },
]

const otrosPreautorizan = [
    { nombre:'Fobissste', imagen:fobissste, },
    { nombre:'Infonavit', imagen:infonavit, },
    { nombre:'Cofinavit', imagen:'https://www.torreasesores.com/wp-content/uploads/2015/06/Cofinavit-vector-logo-e1434046485409.png', },
    { nombre:'Unamos Créditos', imagen:'https://revistaespacios.mx/wp-content/uploads/2020/07/imagen.png', },
    { nombre:'Apoyo Infonavit', imagen:'https://seeklogo.com/images/A/apoyo-infonavit-logo-8C486CA90D-seeklogo.com.png', },
    // { nombre:'ScotiaBank', imagen:scotiabank, },
]

const tagManagerArgs = {
    // gtmId: 'G-RZZJTCG50P',
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_MORILLO
    // dataLayer: {
    //     userId: '001',
    //     userProject: 'project'
    // }
}

TagManager.initialize(tagManagerArgs)
const fraccionamiento = { 
    modelos:[
        {
            nombre:'T-106',
            precio: '$1,732,500.00',
            // foto:'https://casaspecsa-bucket.s3.us-east-2.amazonaws.com/Fachadas/1/A/A.png',
            foto: 'https://d3ihta9hbp4hol.cloudfront.net/Lotes/23/Fotos/DSC04010.png',
            plantas:[
                'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-106/Plantas/plantaalta.png',
                'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-106/Plantas/plantabaja.png',
            ],
            caracteristicas: [
                '106 m² de construcción',
                'Cochera',
                '3 Habitaciones',
                '2 Baños',
            ],
            interiores: {
                total:1420,
                items: [
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-106/Interiores/LomasDelMorillo1-2.jpg',
                        title: 'Breakfast',
                        rows: 2,
                        cols: 2,
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-106/Interiores/LomasDelMorillo11.jpg',
                        title: 'Burger',
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-106/Interiores/LomasDelMorillo12.jpg',
                        title: 'Camera',
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-106/Interiores/LomasDelMorillo13.jpg',
                        title: 'Coffee',
                        cols: 2,
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-106/Interiores/LomasDelMorillo14.jpg',
                        title: 'Hats',
                        cols: 2,
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-106/Interiores/LomasDelMorillo15.jpg',
                        title: 'Honey',
                        rows: 2,
                        cols: 2,
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-106/Interiores/LomasDelMorillo17.jpg',
                        title: 'Burger',
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-106/Interiores/LomasDelMorillo19.jpg',
                        title: 'Camera',
                    },
                    // {
                    //     img: interior1069,
                    //     title: 'Coffee',
                    //     cols: 2,
                    // },
                    // {
                    //     img: interior10610,
                    //     title: 'Hats',
                    //     cols: 2,
                    // },
                ]
            }
        },
        {
            nombre:'T-146',
            precio: '$2,073,750.00',
            foto:'https://d3ihta9hbp4hol.cloudfront.net/Lotes/13/Fotos/lote13.png',
            plantas:[
                'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-146/Plantas/plantaalta.png',
                'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-146/Plantas/plantabaja.png',
            ],
            caracteristicas: [
                '146 m² de construcción',
                'Cochera 2 Vehiculos',
                '3 Habitaciones',
                '2 Baños',
            ],
            interiores: {
                total:1060,
                items: [
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-146/Interiores/LomasDelMorillo3.jpg',
                        title: 'Breakfast',
                        rows: 2,
                        cols: 2,
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-146/Interiores/LomasDelMorillo4.jpg',
                        title: 'Burger',
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-146/Interiores/LomasDelMorillo2.jpg',
                        title: 'Camera',
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-146/Interiores/LomasDelMorillo1-4.jpg',
                        title: 'Coffee',
                        cols: 2,
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-146/Interiores/LomasDelMorillo1-3.jpg',
                        title: 'Hats',
                        cols: 2,
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-146/Interiores/LomasDelMorillo5.jpg',
                        title: 'Honey',
                        rows: 2,
                        cols: 2,
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-146/Interiores/LomasDelMorillo6.jpg',
                        title: 'Burger',
                    }, 
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-146/Interiores/LomasDelMorillo10.jpg',
                        title: 'Burger',
                    },    
                ],
            }
        },
    ],
    amenidades:[
        {
            nombre:'Canchas de Pickleball',
            texto:'Nos enorgullece ser el único fraccionamiento en la zona que cuenta con canchas de pickleball para la práctica de este deporte tan único y divertido.Contamos con dos canchas de alta calidad  para que puedan disfrutar de partidos con amigos y familiares, apto para todas las edades.',
            foto:'https://casaspecsa-bucket.s3.us-east-2.amazonaws.com/Amenidades/1/Canchas+de+Pickleball/canchas.jpg',
        },
        {
            nombre:'Dog-Park',
            texto:'Aquí todos viven felices. Porque todos son importantes para nosotros hemos creado un espacio donde junto a tú mascota podrás disfrutar de un ambiente seguro y divertido mientras socializan y hacen actividades. Tenemos todo lo necesario para que se diviertan  y  ejerciten en un espacio  controlado y amigable.',
            foto:'https://casaspecsa-bucket.s3.us-east-2.amazonaws.com/Amenidades/1/Dog-Park/dog_park.jpg',
        },
        {
            nombre:'Area de juegos',
            texto:'En nuestra área de juegos los niños pueden disfrutar de un ambiente seguro, mientras se divierten y juegan con amigos y familiares. Contamos con una variedad de equipos que  han sido seleccionados para asegurar la seguridad y la diversión de los niños.',
            foto:'https://casaspecsa-bucket.s3.us-east-2.amazonaws.com/Amenidades/1/%C3%81rea+de+Juegos/juegitos.jpg',
        },
        {
            nombre:'Area Kids de juegos',
            texto:' Los más pequeños pueden disfrutar de un ambiente seguro y divertido mientras aprenden y juegan con amigos y familiares;con una variedad de equipos y juegos adecuados para edades tempranas, lo que les permite desarrollar habilidades físicas y cognitivas,diseñados para niños de 2 a 4 años.',
            foto:'https://casaspecsa-bucket.s3.us-east-2.amazonaws.com/Amenidades/1/%C3%81rea+de+juegos+para+ni%C3%B1os+peque%C3%B1os/juegitos_2.jpg',
        }
    ]
}

const MorilloLandingPage = () => {
    const [open, setOpen] = useState(false);
    const getOpen = () => {
        setOpen(!open)
    }
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    
    return(
        <Box
            sx={{
                mt:{ xs:7, sm:8, md:10 }
            }}
        >
            <Helmet>
                <title>Lomas del Morillo Residencial | Casas Pecsa</title>
                <meta name="description" content="Lomas del Morillo Residencial es un exclusivo desarrollo residencial diseñado para ofrecer una experiencia de vida de primer nivel, el fraccionamiento cuenta con control de acceso para la seguridad de tu familia y hermosas amenidades para disfrutrar con tus seres queridos." />
            </Helmet>
            <Portada getOpen={getOpen} />
            {/* <BannerAnuncio/> */}
            {/* <Formulario getOpen={getOpen} /> */}
            <Container>
                <Box
                    sx={{
                        width:'100%',
                        textAlign:'center',
                        color:'#138541',
                        mt:4,
                        mb:2,
                    }}
                >
                    <Typography variant="h3" sx={{ fontFamily:'Montserrat', }}>
                        <b>¡Últimos Modelos!</b>
                    </Typography>
                </Box>
            </Container>
            {
                fraccionamiento.modelos.map((modelo) => (
                    <Modelo modelo={modelo} getOpen={getOpen} />
                ))
            }
            <Container>
                <Box
                    sx={{
                        width:'100%',
                        textAlign:'center',
                        color:'#523f32',
                        mt:4,
                        mb:2,
                    }}
                >
                    <OchoRazones titulo="¿Porque comprar una Casa Pecsa?" color="#138541"/>
                </Box>
            </Container>
            <Box
                sx={{
                    backgroundColor:'#ddeee4',
                    width:'100%',
                    minHeight:300,
                    textAlign:'center',
                    color:'#523f32',
                    mt:4,
                    mb:2,
                }}
            >
                <Container>
                    <Box
                        sx={{
                            width:'100%',
                            textAlign:'center',
                            color:'#523f32',
                            mt:4,
                            mb:2,
                        }}
                    >
                        <Garantias color="#138541" align="center" />
                    </Box>
                </Container>
            </Box>
            {/* <Experiencia/> */}
            <Box>
                <Box
                    sx={{
                        textAlign:'center',
                        color:'#69542c',
                    }}
                >
                    <Typography variant="h3" sx={{ fontFamily:'Montserrat', }}>
                        <b>Te ayudamos a encontrar la mejor opción de crédito para ti</b>
                    </Typography>
                </Box>
                <Box
                    sx={{
                        // mb:2,
                    }}
                >
                    <Box
                        sx={{
                            width:'100%',
                            textAlign:'center',
                            color:'#69542c',
                            mt:4,
                            mb:2,
                            
                        }}
                    >
                        <Typography variant="h5" sx={{ fontFamily:'Montserrat', }}>
                            <b>Créditos bancarios que puedes utilizar</b>
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            mb:2,
                        }}
                    >
                        <Grid 
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {
                                bancosPreautorizan.map((banco) => (
                                    <Grid item xs={4} sm={2} md={2} key={banco.nombre}>
                                        <Box
                                            sx={{
                                                // backgroundColor:'red',
                                                width:'100%',
                                                height:100,
                                                display:'flex',
                                                alignItems:'center',
                                                alignContent:'center',
                                                mb:2,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    // backgroundColor:'yellow',
                                                    width:'70%',
                                                    maxHeight:'70%',
                                                    m:'0 auto',
                                                }}
                                            >
                                                <Box
                                                    component='img'
                                                    src={banco.imagen}
                                                    sx={{
                                                        maxWidth:'100%',
                                                        maxHeight:70
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))
                            }
                            
                        </Grid>
                    </Box>
                </Box>
                <Box
                    sx={{
                        // mb:2,
                    }}
                >
                    <Box
                        sx={{
                            width:'100%',
                            textAlign:'center',
                            color:'#69542c',
                            mt:4,
                            mb:2,
                            
                        }}
                    >
                        <Typography variant="h5" sx={{ fontFamily:'Montserrat', }}>
                            <b>Otros tipos de créditos</b>
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            mb:2,
                        }}
                    >
                        <Grid 
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {
                                otrosPreautorizan.map((banco) => (
                                    <Grid item xs={4} sm={2} md={2} key={banco.nombre}>
                                        <Box
                                            sx={{
                                                // backgroundColor:'red',
                                                width:'100%',
                                                height:100,
                                                display:'flex',
                                                alignItems:'center',
                                                alignContent:'center',
                                                mb:2,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    // backgroundColor:'yellow',
                                                    width:'70%',
                                                    maxHeight:'70%',
                                                    m:'0 auto',
                                                }}
                                            >
                                                <Box
                                                    component='img'
                                                    src={banco.imagen}
                                                    sx={{
                                                        maxWidth:'100%',
                                                        maxHeight:70
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))
                            }
                            
                        </Grid>
                    </Box>
                </Box>
            </Box>
            <MyCalendlyComponent/>
            <Ubicacion/>
            {/* <Amenidades amenidades={fraccionamiento.amenidades}/> */}
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth="lg"
                fullScreen={fullScreen}
                sx={{
                    
                }}
                onClose={getOpen}
            >                
                <Box
                    sx={{
                        // backgroundColor:'red',
                        width:'100%',
                        height:{ xs:900, sm:900, md:810 }
                    }}
                >
                    <InlineWidget url="https://calendly.com/casas-pecsa/fracc-lomas-del-morillo?hide_landing_page_details=1&hide_gdpr_banner=1&text_color=666666&primary_color=148c44" styles={{ width:'100%', height:800, }} />
                </Box>
                <DialogActions>
                    <Button 
                        autoFocus 
                        sx={{
                            // textTransform:'none',
                            color:'#148c44',
                            '&:hover':{
                                backgroundColor:'#ddeee4',

                            }
                        }}
                        onClick={getOpen}
                    >
                        <b>Cancelar</b>
                    </Button>
                </DialogActions>                
            </Dialog>
            {/* <PopupFoto/> */}
        </Box>
    );
}

export { MorilloLandingPage }