import React from "react";
import PropTypes from "prop-types";
import { BaseIcon } from "../BaseIcon";

/**
 * Icono de TikTok.
 *
 * @component
 * @example
 * // Ejemplo de uso:
 * <LinkedInIcon color="#0077B5" onClick={handleClick} />
 *
 * @param {string} [color] - Color del ícono.
 * @param {function} [onClick] - Función manejadora del clic.
 * @returns {JSX.Element} Componente LinkedInIcon.
 *
 */
const TikTokIcon = ({ color, onClick }) => {
  return (
    <BaseIcon color={color} onClick={onClick}>
      <path d="M19.59 6.69a4.83 4.83 0 0 1-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 0 1-5.2 1.74 2.89 2.89 0 0 1 2.31-4.64 2.93 2.93 0 0 1 .88.13V9.4a6.84 6.84 0 0 0-1-.05A6.33 6.33 0 0 0 5 20.1a6.34 6.34 0 0 0 10.86-4.43v-7a8.16 8.16 0 0 0 4.77 1.52v-3.4a4.85 4.85 0 0 1-1-.1z" />
    </BaseIcon>
  );
};

TikTokIcon.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
};

export { TikTokIcon };
