import { Button, Collapse } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";

const Texto = ({ acercademi }) => {
    const [texto, setTexto] = useState(acercademi.map((parrafo)=> parrafo.texto ).join(''))
    const [abrirCollapse, setAbrirCollapse] = useState(false);
    return(
        <Box>
            <Collapse in={abrirCollapse} collapsedSize={40}>
                {
                    texto
                }
            </Collapse>
            {
                texto.length > 149 && (
                    <Button 
                        variant="text" 
                        onClick={()=>{ setAbrirCollapse(!abrirCollapse) }}
                        sx={{
                            // backgroundColor:'red',
                            width:'100%',
                            textTransform:'none',
                            color:'#F5323F',
                            '&:hover':{
                                backgroundColor:'#f2f2f2',

                            }
                        }}
                    >
                        <b>Ver Más</b>
                    </Button>
                )
            }                    
        </Box>
    );
}

export { Texto }