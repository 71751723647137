import { Button, Divider, Grid, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { useState } from "react";

const Ubicaciones = () => {
    const [lugar, setLugar] = useState('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3601.7303457667826!2d-100.98081498454758!3d25.480678126392952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x868812d49b997db7%3A0x2b706f2961621f8c!2sTorre%20Insignia%20Saltillo!5e0!3m2!1ses-419!2smx!4v1670871619067!5m2!1ses-419!2smx');
    const getLugarInsignia = () => {
        setLugar('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3601.7303457667826!2d-100.98081498454758!3d25.480678126392952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x868812d49b997db7%3A0x2b706f2961621f8c!2sTorre%20Insignia%20Saltillo!5e0!3m2!1ses-419!2smx!4v1670871619067!5m2!1ses-419!2smx')
    }
    const getLugarRocal = () => {
        setLugar('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d57658.19696828445!2d-101.04589423182509!3d25.416948375400047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86886d4e2163afef%3A0xe4da77f911e906d1!2sFraccionamiento%20El%20Rocal!5e0!3m2!1ses-419!2smx!4v1670887272783!5m2!1ses-419!2smx')
    }
    const getLugarMorillo = () => {
        setLugar('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3604.3001930140094!2d-101.0239360845486!3d25.39476022988756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8688738d51a8db0b%3A0x3ec4b17a1a4534c1!2sLomas%20Del%20Morillo%20Residencial!5e0!3m2!1ses-419!2smx!4v1670887796122!5m2!1ses-419!2smx')
    }
    const getLugarBosques = () => {
        setLugar('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3605.13539923629!2d-100.99916618454901!3d25.366778231023492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8688735573f6d5ef%3A0xcd22a9738ffcab8d!2sBosques%20de%20las%20Lomas!5e0!3m2!1ses-419!2smx!4v1670887815682!5m2!1ses-419!2smx')
    }
    return(
        <Container>
            <Box
                sx={{
                    // backgroundColor:'red',
                    minHeight:200,
                    mt:2,
                    mb:2,
                }}
            >
                <Grid 
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12} sm={12} md={12}>
                        <Box
                            sx={{
                                mt:2,
                                mb:2,
                            }}
                        >
                            <Typography variant="h4" sx={{ color:'#F5323F', }}>
                                <b>Visitanos</b>
                            </Typography>
                            <Typography variant="body1" sx={{ color:'#707070' }}>
                                Visitanos en cualquiera de nuestras oficinas, un asesor te atendera.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Box
                            sx={{
                                // backgroundColor:'yellow',
                                minHeight:900,
                                // mt:2,
                                // mb:2,
                            }}
                        >
                            <Grid 
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item xs={12} sm={12} md={4}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Box
                                                sx={{
                                                    // backgroundColor:'yellow',
                                                    alignItems:'center',
                                                    alignContent:'center',
                                                    // pl:0.5,
                                                    // pr:0.5,
                                                    // mt:2,
                                                    cursor:'pointer'
                                                }}
                                                onClick={getLugarInsignia}
                                            >
                                                <Box
                                                    sx={{
                                                        // backgroundColor:'green',
                                                        width:'95%',
                                                        m:'0 auto',
                                                        // pl:0.5,
                                                        // pr:0.5,
                                                        // mt:1,
                                                    }}
                                                >
                                                    <Typography variant="h5" sx={{ color:'#F5323F', }}>
                                                        <b>Oficinas (Torre Insignia)</b>
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ color:'#707070' }}>
                                                        <b>Direccion:</b>
                                                    </Typography>
                                                    <Typography variant="body1" sx={{ color:'#707070' }}>
                                                        Torre Insignia Piso 10, Blvd. José Sarmiento, #1515, Col. Rancho de Peña
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ color:'#707070' }}>
                                                        <b>Horario:</b>
                                                    </Typography>
                                                    <Typography variant="body1" sx={{ color:'#707070' }}>
                                                        Lunes a Viernes de 9:00 am a 7:00 pm.
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ color:'#707070' }}>
                                                        <b>Telefono:</b>
                                                    </Typography>
                                                    <Typography variant="body1" sx={{ color:'#707070' }}>
                                                        +52 844 123 4545
                                                    </Typography>
                                                    
                                                    <Box
                                                        sx={{
                                                            mt:1,
                                                            mb:1,
                                                            width:'100%',
                                                            textAlign:'center',
                                                        }}
                                                    >
                                                        <Button
                                                            color="inherit"
                                                            sx={{
                                                                color:'#F5323F',
                                                                textTransform: 'none',
                                                            }}
                                                            
                                                        >
                                                            <b>Como Llegar</b>
                                                        </Button>
                                                    </Box>
                                                    <Divider variant="middle" />
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Box
                                                sx={{
                                                    // backgroundColor:'yellow',
                                                    alignItems:'center',
                                                    alignContent:'center',
                                                    // pl:0.5,
                                                    // pr:0.5,
                                                    // mt:2,
                                                    cursor:'pointer'
                                                }}
                                                onClick={getLugarBosques}
                                            >
                                                <Box
                                                    sx={{
                                                        // backgroundColor:'green',
                                                        width:'95%',
                                                        m:'0 auto',
                                                        // pl:0.5,
                                                        // pr:0.5,
                                                        mt:1,
                                                    }}
                                                >
                                                    <Typography variant="h5" sx={{ color:'#F5323F', }}>
                                                        <b>Bosques de las Lomas</b>
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ color:'#707070' }}>
                                                        <b>Direccion:</b>
                                                    </Typography>
                                                    <Typography variant="body1" sx={{ color:'#707070' }}>
                                                        Fraccionamiento Bosques de las Lomas
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ color:'#707070' }}>
                                                        <b>Horario:</b>
                                                    </Typography>
                                                    <Typography variant="body1" sx={{ color:'#707070' }}>
                                                        Lunes a Domingo de 11:00 am a 8:00 pm.
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ color:'#707070' }}>
                                                        <b>Telefono:</b>
                                                    </Typography>
                                                    <Typography variant="body1" sx={{ color:'#707070' }}>
                                                        +52 844 123 4545
                                                    </Typography>
                                                    
                                                    <Box
                                                        sx={{
                                                            mt:1,
                                                            mb:1,
                                                            width:'100%',
                                                            textAlign:'center',
                                                        }}
                                                    >
                                                        <Button
                                                            color="inherit"
                                                            sx={{
                                                                color:'#F5323F',
                                                                textTransform: 'none',
                                                            }}
                                                            
                                                        >
                                                            <b>Como Llegar</b>
                                                        </Button>
                                                    </Box>
                                                    <Divider variant="middle" />
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Box
                                                sx={{
                                                    // backgroundColor:'yellow',
                                                    alignItems:'center',
                                                    alignContent:'center',
                                                    // pl:0.5,
                                                    // pr:0.5,
                                                    // mt:2,
                                                    cursor:'pointer'
                                                }}
                                                onClick={getLugarMorillo}
                                            >
                                                <Box
                                                    sx={{
                                                        // backgroundColor:'green',
                                                        width:'95%',
                                                        m:'0 auto',
                                                        // pl:0.5,
                                                        // pr:0.5,
                                                        mt:1,
                                                    }}
                                                >
                                                    <Typography variant="h5" sx={{ color:'#F5323F', }}>
                                                        <b>Lomas del Morillo Residencial</b>
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ color:'#707070' }}>
                                                        <b>Direccion:</b>
                                                    </Typography>
                                                    <Typography variant="body1" sx={{ color:'#707070' }}>
                                                        Fraccionamiento Lomas del Morillo Residencial
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ color:'#707070' }}>
                                                        <b>Horario:</b>
                                                    </Typography>
                                                    <Typography variant="body1" sx={{ color:'#707070' }}>
                                                        Lunes a Domingo de 11:00 am a 8:00 pm.
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ color:'#707070' }}>
                                                        <b>Telefono:</b>
                                                    </Typography>
                                                    <Typography variant="body1" sx={{ color:'#707070' }}>
                                                        +52 844 123 4545
                                                    </Typography>
                                                    
                                                    <Box
                                                        sx={{
                                                            mt:1,
                                                            mb:1,
                                                            width:'100%',
                                                            textAlign:'center',
                                                        }}
                                                    >
                                                        <Button
                                                            color="inherit"
                                                            sx={{
                                                                color:'#F5323F',
                                                                textTransform: 'none',
                                                            }}
                                                            
                                                        >
                                                            <b>Como Llegar</b>
                                                        </Button>
                                                    </Box>
                                                    <Divider variant="middle" />
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Box
                                                sx={{
                                                    // backgroundColor:'yellow',
                                                    alignItems:'center',
                                                    alignContent:'center',
                                                    // pl:0.5,
                                                    // pr:0.5,
                                                    // mt:2,
                                                    cursor:'pointer'
                                                }}
                                                onClick={getLugarRocal}
                                            >
                                                <Box
                                                    sx={{
                                                        // backgroundColor:'green',
                                                        width:'95%',
                                                        m:'0 auto',
                                                        // pl:0.5,
                                                        // pr:0.5,
                                                        mt:1,
                                                    }}
                                                >
                                                    <Typography variant="h5" sx={{ color:'#F5323F', }}>
                                                        <b>El Rocal Residencial</b>
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ color:'#707070' }}>
                                                        <b>Direccion:</b>
                                                    </Typography>
                                                    <Typography variant="body1" sx={{ color:'#707070' }}>
                                                        Fraccionamiento El Rocal Residencial
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ color:'#707070' }}>
                                                        <b>Horario:</b>
                                                    </Typography>
                                                    <Typography variant="body1" sx={{ color:'#707070' }}>
                                                        Lunes a Domingo de 11:00 am a 8:00 pm.
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ color:'#707070' }}>
                                                        <b>Telefono:</b>
                                                    </Typography>
                                                    <Typography variant="body1" sx={{ color:'#707070' }}>
                                                        +52 844 123 4545
                                                    </Typography>
                                                    
                                                    <Box
                                                        sx={{
                                                            mt:1,
                                                            mb:1,
                                                            width:'100%',
                                                            textAlign:'center',
                                                        }}
                                                    >
                                                        <Button
                                                            color="inherit"
                                                            sx={{
                                                                color:'#F5323F',
                                                                textTransform: 'none',
                                                            }}
                                                            
                                                        >
                                                            <b>Como Llegar</b>
                                                        </Button>
                                                    </Box>
                                                    <Divider variant="middle" />
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8}>
                                    <Box>
                                        <Box
                                            sx={{
                                                // mt:1,
                                                display:"flex",
                                                alignItems:'center',
                                                alignContent:'center',
                                                // height:{ xs:300, sm:250, md:'100%' },
                                            }}
                                        >
                                            <Box
                                                component='iframe'
                                                // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3601.7303457667826!2d-100.98081498454758!3d25.480678126392952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x868812d49b997db7%3A0x2b706f2961621f8c!2sTorre%20Insignia%20Saltillo!5e0!3m2!1ses-419!2smx!4v1670871619067!5m2!1ses-419!2smx"
                                                src={lugar}
                                                sx={{
                                                    width:{ xs:"100%", sm:"100%", md:"100%" },
                                                    height:{ xs:300, sm:250, md:970 },
                                                    borderRadius:'15px',
                                                    m:'0 auto',
                                                }}                        
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>                           
                </Grid>
            </Box>
        </Container>
    );
}

export { Ubicaciones }