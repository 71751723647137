import React from "react";

import { Helmet } from "react-helmet";

import { SlideAnuncios } from "../../components/SlideAnuncios";
import { ModelosCarrusel } from "../../components/ModelosCarrusel";
import { SolicitaCredito } from "../../components/Banners/SolicitaCredito";
import { FraccionamientosCarrusel } from "../../components/FraccionamientosCarrusel";
import { OchoRazones } from "../../components/OchoRazones";

import Box from "@mui/material/Box";
import { SlideCompraCasa } from "../../components/SlideCompraCasa";
import { OpinionesFamiliaPecsa } from "../../components/OpinionesFamiliaPecsa";
import { VideoBlogBanner } from "../../components/VideoBlogBanner";
import { SlideCompraEnUnSoloDia } from "../../components/SlideCompraEnUnSoloDia";
import { BannerOpciones } from "../../components/BannerOpciones";
import PortadaVideo from "../../components/SlideAnuncios/PortadaVideo";
import { VideoHome } from "../../components/VideoHome";
import { PopupYoutube } from "../../components/Popups/PopupYoutube";
import { PopupFoto } from "../../components/Popups/PopupFoto";
import { PopUpPromocion } from "../../components/PopUpPromocion";
import { MetaPixel } from "../../components/MetaPixel";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { InfiniteScrollHorizontal } from "../../components/InfiniteHorizontalBanner";

const Home = (props) => {
  let navigate = useNavigate();
  // console.log('estoy en Home', props);
  return (
    <div>
      <Helmet>
        <title>Casas Pecsa | Home</title>
        <meta
          name="description"
          content="Encuentra Casa en Saltillo, ¿No sabes cuál es tu mejor opción de crédito para comprar una casa? Nosotros te ayudamos. Agenda tu cita y obtén Asesoría Gratis."
        />
      </Helmet>
      <MetaPixel />

      {/* Contenedor de scroll infinito. Comentar/Descomentar todo el Box */}
      {/* <Box
        sx={{
          display: { xs: "flex", md: "flex" },
          mt: { xs: 6.5, sm: 8, md: 12 },
        }}
      >
        <InfiniteScrollHorizontal />
      </Box> */}

      <Box
        sx={{
          display: { xs: "flex", md: "flex" },
          // Comentar o Descomentar cuando se active o desactive el scroll infinito
          mt: { xs: 6.5, sm: 8, md: 12 },
        }}
      >
        <SlideAnuncios />
      </Box>
      {/* <Box sx={{ display: { xs: 'flex', md: 'flex' }, mt: { xs:2, sm:2, md: 4 } }}>
                <VideoHome />
            </Box> */}
      <Box sx={{ display: { xs: "flex", md: "flex" }, paddingBottom: 3 }}>
        <ModelosCarrusel modelos={props.datos.modelos} />
      </Box>
      <Box
        sx={{ display: { xs: "flex", md: "flex" }, backgroundColor: "#F5F5F5" }}
      >
        <SolicitaCredito />
      </Box>
      <Box sx={{ display: { xs: "flex", md: "flex" }, paddingBottom: 3 }}>
        <FraccionamientosCarrusel
          fraccionamientos={props.datos.fraccionamientos}
        />
      </Box>
      <Box
        sx={{ display: { xs: "flex", md: "flex" }, backgroundColor: "#F5F5F5" }}
      >
        <OchoRazones />
      </Box>
      {/* <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                <SlideCompraCasa />
            </Box> */}
      <Box sx={{ display: { xs: "flex", md: "flex" } }}>
        <SlideCompraEnUnSoloDia />
      </Box>
      <Box
        sx={{
          display: { xs: "flex", md: "flex" },
          backgroundColor: "#F5F5F5" /*backgroundColor:'#DE4647'*/,
        }}
      >
        <OpinionesFamiliaPecsa />
      </Box>
      <Box sx={{ display: { xs: "flex", md: "flex" } }}>
        <VideoBlogBanner />
      </Box>
      <Box
        sx={{ display: { xs: "flex", md: "flex" }, backgroundColor: "#F4F4F4" }}
      >
        <BannerOpciones />
      </Box>
      {/* <PopupYoutube idVideo="Fuf2PFbCkq0"/> */}
      {/* <PopupFoto image="https://storage.casaspecsa.com/Promociones/Popup.jpg" handleClick={() => navigate("/tienda/192T-102Bosques_De_Las_Lomas_Residencial")} /> */}
      {/* <PopUpPromocion/> */}
    </div>
  );
};

export { Home };
