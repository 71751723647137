import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import m106 from '../../assets/casas/m106.png';

import d from '../../assets/razonespecsa/uno.png'

const RazonesItem = (props) => {
    
    return (
        <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
                width: { xs: '80%', sm: '65%', md: '30%' },
                height:{xs: 250, sm:400, md:400},
                mb:{xs:5, sm:1, md:1}
            }}
        >
            <Box
                component="img"
                src={props.razon.imagen}
                sx={{
                    width: { xs: '80%', sm: '80%', md: '70%' },
                    height: { xs: '80%', sm: '40%', md: '50%' },
                    // width:'20px',
                    
                }}
                alt={props.razon.titulo}
            />
            <Typography variant="p" component="div" sx={{ m:1, color:'#707070', textAlign:'center', }}>
                <b>{ props.razon.titulo }</b>
            </Typography>
            <Typography variant="P" component="div" sx={{ ml: { xs: 1, sm: 2, md: 6 }, mr: { xs: 1, sm: 2, md: 6 }, fontSize: { xs: 11, sm: 11, md: 13 }, textAlign:'center', }}>
                { props.razon.desc }
            </Typography>
        </Stack>
        // <Card 
        //     sx={{
        //         width:'100%'
        //     }}
        // >
        //     <CardMedia
        //         component="img"
        //         height="280"
        //         image={props.razon.imagen}
        //         alt="green iguana"
        //     />
        //     <CardContent spacing={1}>
        //         <Stack>
        //             <Typography variant="h5" component="div">
        //                 {props.razon.titulo}
        //             </Typography>
        //             <Grid container spacing={1}>
        //                 <Grid item xs={7}>
        //                     <Stack direction="column"
        //                         justifyContent="center"
        //                         alignItems="flex-start"
        //                     >
        //                         <Typography  variant="body2" color="text.secondary">
        //                             { props.razon.desc }
        //                         </Typography>                                
        //                     </Stack>
        //                 </Grid>
        //             </Grid>
        //         </Stack>
        //     </CardContent>
        // </Card>
    );
}

export { RazonesItem };