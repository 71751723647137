import { Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { InlineWidget } from "react-calendly";

const MyCalendlyComponent = () => {
    return(
        <Box>
            <Container>
                <Box
                    sx={{
                        width:'100%',
                        textAlign:'center',
                        color:'#7c4c1c',
                        mt:4,
                        mb:1,
                    }}
                >
                    <Typography variant="h3" sx={{ fontFamily:'Montserrat', fontSize:{ xs:28, sm:10, md:45 } }}>
                        <b>¡Nosotros te ayudamos!</b>
                    </Typography>
                    <Typography variant="h1" sx={{ fontFamily:'Montserrat', fontSize:{ xs:28, sm:10, md:50 } }}>
                        <b>Agenda cita con nosotros</b>
                    </Typography>
                </Box>
                {/* <Box
                    sx={{
                        width:'100%',
                        // textAlign:'center',
                        // mt:4,
                        // mb:1,
                        color:'#615c51',
                    }}
                >
                    <Typography variant="body1">
                        <b>Ubicacion dfdfdf dgffg fg</b>
                    </Typography>
                </Box> */}
                <Box
                    sx={{
                        // backgroundColor:'red',
                        width:'100%',
                    }}
                >
                    <InlineWidget url="https://calendly.com/casas-pecsa/bosquesdelaslomas?hide_landing_page_details=1&hide_gdpr_banner=1&text_color=2a1a09&primary_color=a66626" styles={{ width:'100%', height:800, }} />
                </Box>
            </Container>
        </Box>
    );
}

export { MyCalendlyComponent }