import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Card, CardActionArea, CardContent, CardMedia, Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ApartmentSharpIcon from '@mui/icons-material/ApartmentSharp';


const MenuAmenidades = ({ fraccionamiento }) => {
    let navigate = useNavigate();
    const location = useLocation();
    // console.log(props.modelo);

    // const [fachadas, setFachadas] = useState(props.modelo.fachadas);

    return(
        <Container sx={{ backgroundColor:'white' }}>
             
            <Box sx={{  }}>                
                <h2>Amenidades</h2>                                 
            </Box>
            <Box sx={{  }}>                
                <Grid container 
                    direction="row" 
                    spacing={{ xs: 2, md: 3 }} 
                    columns={{ xs: 12, sm: 12, md: 12, lg:12 }}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12} sm={4} md={3}>
                        <Card>
                            <CardActionArea onClick={()=>{ navigate('addAmenidad', {state:{fraccionamiento:fraccionamiento}} )}}>
                                <CardContent>
                                    <AddCircleOutlineIcon
                                        sx={{
                                            fontSize:100,
                                            color: "text.secondary"
                                        }}
                                    />
                                    <Typography color="text.secondary" gutterBottom variant="h5" component="div">
                                        Agregar Amenidad
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    {
                        fraccionamiento.amenidades.map((amenidad)=>(
                            <Grid item xs={12} sm={4} md={3}>
                                <Card>
                                    <CardActionArea onClick={()=>{  }}>
                                        <CardContent>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: '100%', height:200 }}
                                                image={amenidad.foto}
                                                alt="Live from space album cover"
                                            />
                                            <Typography color="text.secondary" gutterBottom variant="h5" component="div">
                                                {amenidad.nombre}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))
                    }
                    {/* {
                        fachadas.map((fachada) => ( 
                            <Grid item xs={12} sm={4} md={6}>
                                <Card sx={{ display: 'flex' }}>
                                    {
                                        fachada.fotos.length > 0 ? (
                                            // <h1>{fachada.nombre + 'hey'}</h1>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 300 }}
                                                image={fachada.fotos[0].url}
                                                alt="Live from space album cover"
                                            />
                                        ) : (
                                            // <h1>{fachada.fotos[0].url}</h1>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 300 }}
                                                image={''}
                                                alt="Live from space album cover"
                                            />
                                        )
                                    }
                                            
                                    <CardActionArea onClick={() => { navigate(`${fachada.id}`) }}>
                                        <CardContent>
                                            <Typography color="text.secondary" gutterBottom variant="h4" component="div">
                                                Fachada: { fachada.nombre}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))
                    } */}
                    
                </Grid>                                
            </Box>
        </Container>
    );
}

export { MenuAmenidades }