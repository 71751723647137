import * as React from "react";
import { useRef, useEffect, useState } from "react";
import { Box, height } from "@mui/system";
import Container from '@mui/material/Container';

import { ModeloItem } from "./ModelosItem";

//import Carousel from "react-multi-carousel";
//import './styles.css';
import { Button, Grid, Stack, Fab, Skeleton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { motion } from "framer-motion";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
};



const ModelosCarrusel = ({ modelos }) => {
  // console.log('ModelosCarrusel', props.modelos);

  //const [modelos, setModelos] = useState(props.modelos);

  const constraintsRef = useRef(null);
    const containerRef = useRef(null);

    const constraintsRefMovil = useRef(null);
    const containerRefMovil = useRef(null);

    //const Contenedor = containerRef.current.clientWidth == null || containerRef.current.clientWidth == undefined ? 0 : containerRef.current.clientWidth;
    // const DivVisible = constraintsRef.current.clientWidth;
    // const Diferencia = Contenedor - DivVisible;

    const [Xside, setXside] = React.useState(0);
    const [XIside, setXIside] = React.useState(0);

    const [constraintsWidth, setConstraintsWidth] = React.useState(0);
    const [containerWidth, setContainerWidth] = React.useState(0);

    const atras = (Xside) => {
      // console.log(XIside);
      // console.log((XIside + 395.96));
      // setXside(Xside+395.96);
      // setXIside((XIside - 395.96) < constraintsWidth ? constraintsWidth : (XIside - 395.96))
      console.log(XIside);
      console.log((XIside + 384));
      console.log('Antes:::',Xside);
      setXside(Xside+384);
      setXIside((XIside - 384) < constraintsWidth ? constraintsWidth : (XIside - 384))
      console.log('Despues:::',Xside);
      // console.log(XIside);
      // console.log(constraintsRef.current.clientWidth);
      // console.log(containerRef.current.clientWidth);
      
    };
    const adelante = (Xside) => {
      // console.log(XIside);
      // console.log((XIside - 395.96));
      // setXside(Xside-395.96);      
      // setXIside((XIside + 395.96) > containerWidth ? containerWidth : (XIside + 395.96))
      console.log(XIside);
      console.log((XIside - 384));
      console.log('Antes:::',Xside);
      setXside(Xside-384);      
      setXIside((XIside + 384) > containerWidth ? containerWidth : (XIside + 384))
      console.log('Despues:::',Xside);
      // console.log(XIside);
      // console.log(containerRef.current.clientWidth);
    };
    
    const checarXIside = (Xside) => {
       
    }

    useEffect(() => {
      setXIside(constraintsRef.current.clientWidth);
      setConstraintsWidth(constraintsRef.current.clientWidth)
      setContainerWidth(containerRef.current.clientWidth)
      console.log('constraintsRef:::::', constraintsRef.current.clientWidth)
      console.log('containerRef:::::', containerRef.current.clientWidth)
      console.log('Xside:::::', Xside)
      console.log('XIside:::::', XIside)
      //
    }, [setXIside, setConstraintsWidth, setContainerWidth]);
    return(
        <Container sx={{ backgroundColor:'white' }}>
            <Box sx={{ color:'#f5323f' /*backgroundColor:'yellow'*/ }}>
                <h1>Conoce Nuestros Modelos</h1>                
            </Box>
                    
            <Box
              component={motion.div}
              ref={constraintsRef}
              sx={{
                backgroundColor:'white', 
                display: { xs: 'none', md: 'flex' },
                placeContent: 'start',
                placeItems: 'center',
                overflow: 'hidden',
                position:'relative',
                marginTop:2,
              }}
            >
              <Stack
                ref={containerRef}
                direction="row"
                justifyContent="center"
                alignItems="center"
                //spacing={2}
                component={motion.div}
                animate={{ x:Xside }}
                sx={{ 
                  // backgroundColor:'yellow',
                  // pl:0.5,
                  // pr:0.7,
                  // width:'100%'
                  pb:1
                }}

              >
                {
                  // modelos && (
                    modelos.length > 0 ? (
                      modelos.map((modelo) => {if(modelo.fachadas.length > 0){return(
                        <Box
                          component={motion.div}
                          sx={{ 
                            width:{ xs: '185px', sm:'185px', md:'360px',  },
                            ml:1.5,
                            mr:1.5,
                          }}                          
                        >
                          <ModeloItem modelo={modelo}/>
                        </Box>
                      )}})
                    ) 
                    : (
                      <React.Fragment>
                        <Box>
                          <Stack spacing={1}>      
                            <Skeleton variant="rectangular" width={380} height={360} />
                            <Skeleton variant="text" width={250} />
                            <Skeleton variant="text" width={110} />
                          </Stack>
                        </Box>
                        <Box>
                          <Stack spacing={1}>      
                            <Skeleton variant="rectangular" width={380} height={360} />
                            <Skeleton variant="text" width={250} />
                            <Skeleton variant="text" width={110} />
                          </Stack>
                        </Box>
                        <Box>
                          <Stack spacing={1}>      
                            <Skeleton variant="rectangular" width={380} height={360} />
                            <Skeleton variant="text" width={250} />
                            <Skeleton variant="text" width={110} />
                          </Stack>
                        </Box>
                      </React.Fragment>
                    )
                  // )
                }
                {/* <Box
                  component={motion.div}
                  sx={{ 
                    // width: '375px'
                    // width: '185px'
                    width:{ xs: '185px', sm:'185px', md:'375px', lg:'375px' }
                    
                  }}
                  
                >
                  <ModeloItem/>  
                </Box>
                <Box
                  component={motion.div}
                  sx={{ 
                    // width: '375px'
                    // width: '185px'
                    width:{ xs: '185px', sm:'185px', md:'375px', lg:'375px' }
                    
                  }}
                  
                >
                  <ModeloItem/>  
                </Box>
                <Box
                  component={motion.div}
                  sx={{ 
                    // width: '375px'
                    // width: '185px'
                    width:{ xs: '185px', sm:'185px', md:'185px', lg:'375px' }
                    
                  }}
                  
                >
                  <ModeloItem/>  
                </Box>
                <Box
                  component={motion.div}
                  sx={{ 
                    // width: '375px'
                    // width: '185px'
                    width:{ xs: '185px', sm:'185px', md:'375px', lg:'375px' }
                    
                  }}
                  
                >
                  <ModeloItem/>  
                </Box>  */}
                               
              </Stack>
              {
                XIside !== constraintsWidth && (
                  <Box 
                component="Button" 
                sx={{ 
                  pt: 3, pb:3, pl:0, pr:0,
                  backgroundColor:'white', 
                  border:'none',
                  color:'#f5323f',
                  zIndex:1, 
                  position:'absolute',
                  left:0,
                  borderColor:'red',
                }}
                variant="contained"
                onClick={()=> atras(Xside)}
              >
                  <ChevronLeftIcon />
                </Box>
                )
              }
              {
                XIside !== containerWidth && (
                  <Box 
                component="Button" 
                sx={{ 
                  pt: 3, pb:3, pl:0, pr:0,
                  backgroundColor:'white', 
                  border:'none',
                  color:'#f5323f',
                  zIndex:1, 
                  position:'absolute',
                  right:0,
                  // borderColor:'red',
                }}
                variant="contained"
                onClick={()=> adelante(Xside)}
              >
                  <ChevronRightIcon />
                </Box>
                )
              }
                
            </Box>

            <Box
              component={motion.div}
              ref={constraintsRefMovil}
              sx={{
                backgroundColor:'white', 
                display: { xs: 'flex', md: 'none' },
                placeContent: 'start',
                placeItems: 'center',
                overflow: 'hidden',
                position:'relative',
                marginTop:2
              }}
            >
              <Stack
                ref={containerRefMovil}
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                component={motion.div}
                animate={{ x:Xside }}
                drag='x' 
                dragConstraints={constraintsRefMovil}
                sx={{ pr:4, }}
              >
                {
                  modelos.length > 0 ? (
                    modelos.map((modelo) => (
                      <Box
                        component={motion.div}
                        sx={{ 
                          // width: '375px'
                          // width: '185px'
                          width:{ xs: '250px', sm:'285px', md:'375px', lg:'375px' }
                          
                        }}
                        
                      >
                        <ModeloItem modelo={modelo}/>
                      </Box>
                    ))
                  ) : (
                    <Box>
                      <Stack spacing={1}>      
                        <Skeleton variant="rectangular" width={380} height={360} />
                        <Skeleton variant="text" width={250} />
                        <Skeleton variant="text" width={110} />
                      </Stack>
                    </Box>
                  )
                }
                {/* <Box
                  component={motion.div}
                  sx={{ 
                    // width: '375px'
                    // width: '185px'
                    width:{ xs: '250px', sm:'285px', md:'375px', lg:'375px' }
                    
                  }}
                  
                >
                  <ModeloItem/>  
                </Box>
                <Box
                  component={motion.div}
                  sx={{ 
                    // width: '375px'
                    // width: '185px'
                    width:{ xs: '250px', sm:'285px', md:'375px', lg:'375px' }
                    
                  }}
                  
                >
                  <ModeloItem/>  
                </Box> 
                <Box
                  component={motion.div}
                  sx={{ 
                    // width: '375px'
                    // width: '185px'
                    width:{ xs: '250px', sm:'285px', md:'375px', lg:'375px' }
                    
                  }}
                  
                >
                  <ModeloItem/>  
                </Box>
                <Box
                  component={motion.div}
                  sx={{ 
                    // width: '375px'
                    // width: '185px'
                    width:{ xs: '250px', sm:'285px', md:'375px', lg:'375px' }
                    
                  }}
                  
                >
                  <ModeloItem/>  
                </Box>  */}

              </Stack>                
            </Box>
           
                

        </Container>
    );
};

export { ModelosCarrusel };