import React, { useEffect, useState } from "react";
import axios from "axios";
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation, Link, NavLink, Navigate } from "react-router-dom";
import { Autocomplete, Box, Button, ButtonBase, Checkbox, Chip, Container, Divider, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import ClearIcon from '@mui/icons-material/Clear';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const elementosPlantas = [
    '2 Baños Completos',
    '3 Recamaras',
    'Sala',
    'Comedor'

]

const AddEmpresa = () => {
    let navigate = useNavigate();

    const [ciudades, setCiudades] = useState([]);
    const [selectedCiudad, setSelectedCiudad] = useState(null);
    const [ciudadDeNotaria, setCiudadDeNotaria] = useState(null);
    const [ciudadDeRegistro, setCiudadDeRegistro] = useState(null);
    const [ciudadDondeSeLevantaElActa, setCiudadDondeSeLevantaElActa] = useState(null);
    const [ciudadDondeSeInscribio, setCiudadDondeSeInscribio] = useState(null);

    const [imageLogo, setImageLogo] = useState(null);
    const [selectedImageLogo, setSelectedImageLogo] = useState(null);

    const [selectedImagePlantaBaja, setSelectedImagePlantaBaja] = useState(null);
    const [imagePlantaBajaUrl, setImagePlantaBajaUrll] = useState(null);
    const [selectedImagePlantaAlta, setSelectedImagePlantaAlta] = useState(null);
    const [imagePlantaAltaUrl, setImagePlantaAltaUrll] = useState(null);

    const [selectedElementosPlantaBaja, setSelectedElementosPlantaBaja] = useState(null);
    const [selectedElementosPlantaAlta, setSelectedElementosPlantaAlta] = useState(null);

    const [storageImages, setStorageImages] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [imagesUrl, setImagesUrl] = useState([]);

    const [principal, setPrincipal] = useState(0);

    const add = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');

        // console.log(data.get('precio')); return;

        data.set('idMpio', selectedCiudad.id);
        data.set('ciudadNotaria', ciudadDeNotaria.id);
        data.set('ciudadRegistro', ciudadDeRegistro.id);
        data.set('ciudadDondeInscribo', ciudadDondeSeInscribio.id);
        data.set('ciudadDondeSeLevantaElActa', ciudadDondeSeLevantaElActa.id);
        data.set('folioCreateBy', folio);

        axios.post(`${process.env.REACT_APP_API_URL}/api/empresa/`, 
            // // {
            // //     nombre: data.get('nombre'),
            // //     idMpio: selectedCiudad.id,
            // //     logoImg: selectedImage,
            // //     fotosImg: storageImages,
            // //     folioCreateBy: folio,
            // // },
            data,
            {
              headers: { 
                Authorization: `Bearer ${token}`,   
                'Content-Type': 'multipart/form-data',               
                //ContentType: 'multipart/form-data'
              }              
        })
        .then((response) => {
            // handle success            
            console.log(response);
            console.log('success axio');
            // navigate(-1, { replace: true });
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sabe');
        });

    };
    const removeImagen = (index) => {
        storageImages.splice(index, 1);
        imagesUrl.splice(index, 1);
        setStorageImages(storageImages);
        setImagesUrl(imagesUrl);
    }
    const asignarImagenes = (files) => {
        setSelectedImages(files)
        setImagesUrl([...imagesUrl, ...selectedImages]);
    }
    useEffect(() => {
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        
        if (selectedImageLogo) {
            setImageLogo(URL.createObjectURL(selectedImageLogo[0]));
        }
        // if (selectedImagePlantaBaja) {
        //     setImagePlantaBajaUrll(URL.createObjectURL(selectedImagePlantaBaja[0]));
        // }
        // if (selectedImagePlantaAlta) {
        //     setImagePlantaAltaUrll(URL.createObjectURL(selectedImagePlantaAlta[0]));
        // }

        // if (selectedImages.length > 0) {
        //     console.log(selectedImages.length);
        //     // console.log('imagenes seleccionsadoas: ', selectedImages);
        //     for (let i = 0; i < selectedImages.length; i++) {
        //         if(!storageImages.find( element => element === selectedImages[i])){
        //             storageImages.push(selectedImages[i]);
        //             imagesUrl.push(URL.createObjectURL(selectedImages[i]))                    
        //         }                
        //     }
        // }


        //setPrincipal(Math.random(10) *100);
        axios.get(`${process.env.REACT_APP_API_URL}/api/empresa/createview`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((response) => {
            // handle success
            console.log(response);
            setCiudades(response.data.ciudades);
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
        });
    }, [setCiudades, selectedImageLogo]);
    return(
        <Container sx={{ backgroundColor:'white' }}>
            <Box component="form" onSubmit={add}>
                <h2>Nueva Empresa</h2>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" label="Nombre" variant="outlined" name="nombre" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        {/* <TextField id="outlined-basic" label="Nombre" variant="outlined" name="nombre" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/> */}
                        <Autocomplete
                            id="grouped-demo"
                            options={ciudades}
                            groupBy={(ciudad) => ciudad.estado.nombre}
                            getOptionLabel={(ciudad) => ciudad.nombre}
                            value={selectedCiudad}
                            onChange={(event, newCiudad)=>{setSelectedCiudad(newCiudad);}}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Ciudad" name="ciudad" required/>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" label="Direccion" variant="outlined" name="calle" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid> 
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" label="Colonia" variant="outlined" name="colonia" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid> 
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" label="Numero Exterior" variant="outlined" name="numeroExterior" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid> 
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" label="Numero Interior" variant="outlined" name="numeroInterior" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" label="Codigo Postal" variant="outlined" name="codigoPostal" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid>                    
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" label="Razon Social" variant="outlined" name="razonSocial" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid> 
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" label="RFC" variant="outlined" name="rfc" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid> 
                    <Grid item xs={12} sm={6} md={6}>
                        <label htmlFor="contained-button-file-estadodecuenta">
                            <Input accept="image/*, .pdf" id="contained-button-file-estadodecuenta" name="archivoEstadoCuentaDoc" type="file" sx={{ display:'none' }} />
                            <Button variant="contained" component="span" endIcon={<UploadFileIcon />}>
                                Cargar Estado de cuenta
                            </Button>
                        </label>
                    </Grid>  
                    <Grid item xs={12} sm={6} md={4}>
                        <label htmlFor="contained-button-logo">                            
                            <input accept="image/*" id="contained-button-logo" type="file" name="archivoLogoImg" style={{ display: 'none' }} onChange={e => setSelectedImageLogo([e.target.files[0]])}/>
                            <ButtonBase
                                component="span"
                                sx={{
                                    m:1,
                                    width: '100%',
                                }}
                            >
                                {
                                    imageLogo ? (
                                        <Box
                                            component='img'
                                            src={imageLogo}
                                            sx={{
                                                m:1,
                                                width: '100%',
                                                height: 180,
                                                borderStyle: 'dotted'
                                            }}
                                        />
                                    ) 
                                    : (                                                
                                        <Box
                                            component='div'
                                            sx={{
                                                m:1,
                                                width: '100%',
                                                height: 180,
                                                borderStyle: 'dotted'
                                            }}
                                        >
                                            <AddAPhotoIcon/>
                                            <Typography variant="h5" gutterBottom component="div">
                                                Clic para cargar Logo
                                            </Typography>
                                        </Box>
                                    )
                                }
                                {/* <Box
                                    component='div'
                                    sx={{
                                        m:1,
                                        width: '100%',
                                        height: 400,
                                        borderStyle: 'dotted'
                                    }}
                                >
                                    <AddAPhotoIcon/>
                                    <Typography variant="h5" gutterBottom component="div">
                                        Clic para cargar Foto Planta Baja
                                    </Typography>
                                </Box> */}
                                {/* <Box
                                    component='img'
                                    src={imagePlantaBajaUrl}
                                    sx={{
                                        m:1,
                                        width: '100%',
                                        height: 400,
                                        borderStyle: 'dotted'
                                    }}
                                /> */}
                            </ButtonBase>
                        </label>
                    </Grid>                    
                </Grid>
                <Divider />
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" label="Representante Legal" variant="outlined" name="representanteLegal" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid> 
                    <Grid item xs={12} sm={6} md={6}>
                        <label htmlFor="contained-button-file-firma">
                            <Input accept="image/*, .pdf" id="contained-button-file-firma" name="archivoFirmarRepresentanteLegalDoc" type="file" sx={{ display:'none' }} />
                            <Button variant="contained" component="span" endIcon={<UploadFileIcon />}>
                                Adjuntar Firma
                            </Button>
                        </label>
                    </Grid>  
                </Grid>
                <Divider />
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" label="Numero de Escritura" variant="outlined" name="numeroEscritura" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" label="Titular de Notaria" variant="outlined" name="titularNotaria" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" label="Numero de Notaria" variant="outlined" name="numeroNotaria" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        {/* <TextField id="outlined-basic" label="Nombre" variant="outlined" name="nombre" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/> */}
                        <Autocomplete
                            id="grouped-demo"
                            options={ciudades}
                            groupBy={(ciudad) => ciudad.estado.nombre}
                            getOptionLabel={(ciudad) => ciudad.nombre}
                            value={ciudadDeNotaria}
                            onChange={(event, newCiudad)=>{setCiudadDeNotaria(newCiudad);}}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Ciudad de Notaria" name="ciudadNotaria" required/>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Autocomplete
                            id="grouped-demo"
                            options={ciudades}
                            groupBy={(ciudad) => ciudad.estado.nombre}
                            getOptionLabel={(ciudad) => ciudad.nombre}
                            value={ciudadDeRegistro}
                            onChange={(event, newCiudad)=>{setCiudadDeRegistro(newCiudad);}}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Ciudad de Registro" name="ciudadRegistro" required/>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" label="Folio Mercantil" variant="outlined" name="folioMercantil" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" label="Numero de Libro" variant="outlined" name="numeroLibro" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" label="Numero de Seccion" variant="outlined" name="numeroSeccion" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" label="Fecha en que se otorgó el poder legal" type="date" variant="outlined" name="fechaOtorgamientoDelPoderLegal" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid> 
                </Grid>
                <Divider />
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" label="Número de Escritura" variant="outlined" name="numeroEscrituraActaConstitutiva" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" label="Titular de Notaría" variant="outlined" name="titularEscrituraActaConstitutiva" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" label="Número de Notaría" variant="outlined" name="numeroNotariaActaConstitutiva" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        {/* <TextField id="outlined-basic" label="Nombre" variant="outlined" name="nombre" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/> */}
                        <Autocomplete
                            id="grouped-demo"
                            options={ciudades}
                            groupBy={(ciudad) => ciudad.estado.nombre}
                            getOptionLabel={(ciudad) => ciudad.nombre}
                            value={ciudadDondeSeLevantaElActa}
                            onChange={(event, newCiudad)=>{setCiudadDondeSeLevantaElActa(newCiudad);}}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Ciudad Donde se Levanta el Acta" name="ciudadDondeSeLevantaElActa" required/>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Autocomplete
                            id="grouped-demo"
                            options={ciudades}
                            groupBy={(ciudad) => ciudad.estado.nombre}
                            getOptionLabel={(ciudad) => ciudad.nombre}
                            value={ciudadDondeSeInscribio}
                            onChange={(event, newCiudad)=>{setCiudadDondeSeInscribio(newCiudad);}}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Ciudad Donde se Inscribio" name="ciudadDondeInscribo" required/>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" label="Número de Folio" variant="outlined" name="numeroFolioActaConstitutiva" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" label="Número de Libro" variant="outlined" name="numeroLibroActaConstitutiva" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" label="Número de Sección" variant="outlined" name="numeroSeccionActaConstitutiva" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" label="Fecha en que se levanta el acta" type="date" variant="outlined" name="fechaLevantamientoActaConstitutiva" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid> 
                </Grid>
                <Divider /> 
        
                <Button variant="contained" type="submit">Guardar</Button>
            </Box>
        </Container>
    );
}

export { AddEmpresa }