


import { Box, List, ListItem, } from '@mui/material';
import { ItemExperiencia } from './ItemExperiencia';

import EditIcon from '@mui/icons-material/Edit';

const ListaExperiencia = ({ experiencia }) => {
    return(
        <Box
            sx={{
                // backgroundColor:'red',
            }}
        >                
            <List
                sx={{ width:'100%', }}
            >
                {
                    experiencia.map((trabajo)=>(
                        <ListItem sx={{ width:'100%', }}>
                            <ItemExperiencia trabajo={trabajo} />
                        </ListItem>
                    ))
                }                
            </List>
        </Box>
    );
}

export { ListaExperiencia }