import { Avatar, Backdrop, Card, CardActions, CardContent, CardHeader, CardMedia, CircularProgress, IconButton, ImageList, ImageListItem, Menu, MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import asesorPerfil2 from "../../../../assets/asesores/asesorPerfil2.png";
import FavoriteIcon from '@mui/icons-material/Favorite';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from "react";
import Carousel from "react-material-ui-carousel";
import moment from "moment";

function srcset(image, size, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${
            size * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
}

const ItemPublicacionesAsesores = ({ post, asesor, }) => {
    // const [post, setPost] = useState([]);
    console.log(post, asesor);

    const [fotosSelect, setFotosSelect] = useState([]);
    const getFotoSelect = (fotos) => {
        setFotosSelect(oldFotos=>[...oldFotos, ...fotos]);
    };

    const [openFotos, setOpenFotos] = useState(false);
    
    const handleCloseFotos = () => {
        setOpenFotos(false);
    };
    const handleToggleFotos = (foto) => {
        getFotoSelect(foto);
        setOpenFotos(!openFotos);
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    // const [selectedValue, setSelectedValue] = useState(emails[1]);

    const getFormatoVariasImagenes = (fotos) => {
        console.log(fotos);
        if(fotos.length === 2){
            return [
                {
                    img: fotos[0].url,
                    title: 'Breakfast',
                    rows: 4,
                    cols: 2,
                },
                {
                    img: fotos[1].url,
                    title: 'Burger',
                    rows: 4,
                    cols: 2,
                },
            ];
        }
        if(fotos.length === 3){
            return [
                {
                    img: fotos[0].url,
                    title: 'Breakfast',
                    rows: 2,
                    cols: 4,
                },
                {
                    img: fotos[1].url,
                    title: 'Burger',
                    rows: 2,
                    cols: 2,
                },
                {
                    img: fotos[2].url,
                    title: 'Burger',
                    rows: 2,
                    cols: 2,
                },
            ];
        }
        if(fotos.length >= 4){
            return [
                {
                    img: fotos[0].url,
                    title: 'Breakfast',
                    rows: 2,
                    cols: 2,
                },
                {
                    img: fotos[1].url,
                    title: 'Burger',
                    rows: 2,
                    cols: 2,
                },
                {
                    img: fotos[2].url,
                    title: 'Burger',
                    rows: 2,
                    cols: 2,
                },
                {
                    img: fotos[3].url,
                    title: 'Burger',
                    rows: 2,
                    cols: 2,
                },
            ];
        }
    }

    return(
        <Box
            sx={{
                width:'100%',
            }}
        >
            <Card 
                sx={{
                    borderRadius:'12px',
                    boxShadow:2,
                }}
            >
                <CardHeader
                    avatar={
                        <Avatar 
                            sx={{ bgcolor: '#707070' }} 
                            aria-label="recipe" 
                            variant="rounded" 
                            src={asesor.foto}>
                            R
                        </Avatar>
                    }                    
                    title={asesor.nombre}
                    subheader={moment(post.fecha, 'YYYY-MM-DD').locale('es').fromNow()}
                />
                {
                    (post.fotos.length > 0) && (
                        post.fotos.length > 1 ? (
                            <Box
                                sx={{
                                    // backgroundColor:'yellow',
                                    width:'100%',
                                }}
                            >
                                <ImageList
                                    sx={{ width: '100%', height: { xs:200, sm:200, md:500 } }}
                                    variant="quilted"
                                    cols={4}
                                    rowHeight={121}
                                    onClick={()=>{handleToggleFotos(post.fotos)}}
                                >
                                    {
                                        getFormatoVariasImagenes(post.fotos).map((item) => (
                                            <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1} >
                                                <img
                                                    {...srcset(item.img, 121, item.rows, item.cols)}
                                                    alt={item.title}
                                                    loading="lazy"
                                                    // onClick={()=>{handleToggle(item.img)}}
                                                />
                                            </ImageListItem>
                                        ))
                                    }
                                </ImageList>
                            </Box>
                        ) : (
                            <CardMedia
                                component="img"
                                // height="194"
                                image={post.fotos[0].url}
                                alt="Paella dish"
                                sx={{
                                    minHeight:'194',
                                }}
                                onClick={()=>{handleToggleFotos(post.fotos)}}
                            />
                        )
                    )
                }
                {
                    post.texto && (
                        <CardContent>
                            <Typography variant="body2" color="text.secondary">
                                { post.texto }
                            </Typography>
                        </CardContent>
                    )
                }                                            
                {/* <CardActions disableSpacing>
                    <IconButton aria-label="add to favorites">
                        <FavoriteIcon />
                    </IconButton>
                </CardActions> */}
            </Card>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openFotos}
                onClick={handleCloseFotos}
            >
                <Box sx={{ width:'100%', }}>
                    {/* {
                        fotoSelect ? (
                            <Box
                                component='img'
                                src={fotoSelect}
                                sx={{
                                    maxWidth:'100%',
                                    maxHeight:1000,
                                }}
                            />
                        ) : (
                            <CircularProgress color="inherit" />
                        )
                    } */}
                    {
                        fotosSelect.length > 0 ? (
                            <Carousel
                                indicators={false}
                                autoPlay={false}
                                sx={{ 
                                    width:'100%', 
                                    // height:1000, 
                                    
                                }}
                            >
                                {
                                    fotosSelect.map((foto)=>(
                                        <Box
                                            sx={{
                                                width:'100%',
                                                display:'flex', 
                                                alignItems:'center', 
                                                alignContent:'center',
                                            }}
                                        >
                                            <Box
                                                component='img'
                                                src={foto.url}
                                                sx={{
                                                    m:'0 auto',
                                                    maxWidth:'100%',
                                                    maxHeight:900,
                                                }}
                                            />
                                        </Box>
                                    ))
                                }
                            </Carousel>
                        ) : (
                            <CircularProgress color="inherit" />
                        )
                    }
                            
                        
                </Box>
                {/* <CircularProgress color="inherit" /> */}
            </Backdrop>
        </Box>
    );
}

export { ItemPublicacionesAsesores }