import { IconButton } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import axios from "axios";
import { useParams } from "react-router-dom";

const DeleteExperiencia = ({ idTrabajo }) => {
    const { folio } = useParams();

    const borrar = () => {
        
        
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        // const prueba = {
        //     nombre: data.get('nombre'),
        //     puesto: data.get('puesto'),
        //     fechaInicio: fechaInicio.format('yyyy-MM-DD HH:mm'),
        //     fechaFinal: fechaFinal.format('yyyy-MM-DD HH:mm'),
        // };
        // console.log(lugarDeTrabajo);
        // console.log(puesto); return;
        axios.post(`${process.env.REACT_APP_API_URL}/api/asesores/profile/${folio}/informacion/experiencia/${idTrabajo}`,
            {
                activo: false,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
        .then((response)=>{
            console.log(response);
            // setInformacion(response.data)
            // updateDatos(lugarDeTrabajo, puesto, fechaInicio, fechaFinal);
            // getEditar();
        })
        .catch((error)=>{
            console.log(error);
        });
    }

    return(
        <IconButton aria-label="delete" onClick={borrar}>
            <ClearIcon />
        </IconButton>
    );
}

export { DeleteExperiencia }