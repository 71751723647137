import { Box, Breadcrumbs, Button, Divider, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { PostMasPopulares } from "../Blog/PostMasPopulares";
import { RedesSociales } from "../Blog/RedesSociales";

import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';

import pruebadecoracion from '../../assets/prueba/pruebadecoracion.jpg'
import pruebapost from '../../assets/prueba/pruebapost.png'
import React from "react";
import { useState } from "react";
import { RWebShare } from "react-web-share";
import { PromoMes } from "../Blog/PromoMes";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Helmet } from "react-helmet";
import { ChargingStationOutlined } from "@mui/icons-material";

const BlogPublicacion = () => {
    let  { idPost } = useParams();
    // console.log(params);
    
    const [post, setPost] = useState(null);

    const [like, setLike] = useState(false);
    const [likesCount, setLikesCount] = useState(0);

    const [view, setView] = useState(false);
    const [viewsCount, setViewsCount] = useState(0);

    const [shared, setShared] = useState(false);
    const [sharedsCount, setSharedsCount] = useState(0);

    const getLike = () => {
        setLike(true);
        setLikesCount(likesCount+1);

        axios.post(`${process.env.REACT_APP_API_URL}/api/blog/like`, {
            idPost:idPost,
            fecha:moment().format('YYYY-MM-DD'),
            hora:moment().format('YYYY-MM-DD h:mm:ss'),
        })
        .then((response) => {
            // handle success
            console.log(response);
        })
        .catch((error) => {
            // handle success
            console.log(error);
        })
        .then(() => {
            // handle success
            //console.log('sabea');
        });
        
    }

    const getView = () => {
        setView(true);
        setViewsCount(viewsCount+1);

        axios.post(`${process.env.REACT_APP_API_URL}/api/blog/view`, {
            idPost:idPost,
            fecha:moment().format('YYYY-MM-DD'),
            hora:moment().format('YYYY-MM-DD h:mm:ss'),
        })
        .then((response) => {
            // handle success
            console.log(response);
        })
        .catch((error) => {
            // handle success
            console.log(error);
        })
        .then(() => {
            // handle success
            //console.log('sabea');
        });
        
    }

    const getShare = () => {
        setShared(true);
        setSharedsCount(sharedsCount+1);

        axios.post(`${process.env.REACT_APP_API_URL}/api/blog/shared`, {
            idPost:idPost,
            fecha:moment().format('YYYY-MM-DD'),
            hora:moment().format('YYYY-MM-DD h:mm:ss'),
        })
        .then((response) => {
            // handle success
            console.log(response);
        })
        .catch((error) => {
            // handle success
            console.log(error);
        })
        .then(() => {
            // handle success
            //console.log('sabea');
        });
        
    }

    const [masPopulares, setMasPopulares] = useState([]);

    const [Atexto, setATexto] = useState([]);

    const convertTexto = (texto) => {
        setATexto(texto.split('\r\n'));
    }

    const [promoActual, setPromoActual] = useState(null);

    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_API_URL}/api/blog/${idPost}`)
        .then((response) => {
            // handle success
            console.log(response);
            setPost(response.data.post);
            setLikesCount(response.data.post.likesCount);
            setViewsCount(response.data.post.viewsCount);
            setSharedsCount(response.data.post.sharedCount);
            // getCountPosts(response.data.count);
            setMasPopulares(response.data.masPopulares);
            setPromoActual(response.data.promoActual);
            convertTexto(response.data.post.texto);
            getView();
        })
        .catch((error) => {
            // handle success
            console.log(error);
        })
        .then(() => {
            // handle success
            //console.log('sabea');
        });
    },[idPost]);
    return(
        <Container>
            <Helmet>
                {/* <title>Blog Pecsa | {post ? (post.titulo) : (`cargando...`)}</title>
                <meta name="description" key="description" content={post ? (`${post.texto.slice(0, 100)}...`) : (`cargando...`)} />
                <meta name="title" key="title" content={`Blog Pecsa | ${post ? (post.titulo) : (`cargando...`)}`} />
                <meta property="og:title" key="og:title" content={`Blog Pecsa | ${post ? (post.titulo) : (`cargando...`)}`} />
                <meta property="og:type" key="og:type" content="website" />
                <meta
                    property="og:description"
                    key="og:description"
                    content={post ? (`${post.texto.slice(0, 100)}...`) : (`cargando...`)}
                />
                <meta
                    property="og:image"
                    key="og:image"
                    content={post ? post.imagen : pruebadecoracion}
                />   */}
                <title>Blog Pecsa | {post ? (post.titulo) : (`cargando...`)}</title>
                <meta name="description" content={post ? (`${post.texto.slice(0, 100)}...`) : (`cargando...`)} />
                <link rel="canonical" href={post ? `${process.env.REACT_APP_URL}/blog/${post.id}/${post.titulo}` : ''} />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={`Blog Pecsa | ${post ? (post.titulo) : `cargando...`}`} />
                <meta property="og:description" content={post ? (`${post.texto.slice(0, 100)}...`) : (`cargando...`)} />
                <meta property="og:url" content={post ? `${process.env.REACT_APP_URL}/blog/${post.id}/${post.titulo}` : ''} />
                <meta property="og:site_name" content="Casas Pecsa" />
                <meta property="article:published_time" content="2022-12-14T19:10:29+00:00" />
                <meta property="article:modified_time" content="2022-12-14T19:08:15+00:00" />
                <meta property="og:image" content={post ? post.imagen : pruebadecoracion} />
                <meta property="og:image:width" content="729" />
                <meta property="og:image:height" content="483" />
                <meta property="og:image:type" content="image/png" />
                <meta name="author" content="Casas Pecsa" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:label1" content="Written by" />
                <meta name="twitter:data1" content="Casas Pecsa" />
                <meta name="twitter:label2" content="Est. reading time" />
                <meta name="twitter:data2" content="4 minutes" />
            </Helmet>
            <Box sx={{ mt:12, mb:10, }}>
                <Box
                    sx={{
                        // backgroundColor:'red',
                        pb:1,
                        pt:0.6,
                        pl:1,
                        color:'#707070',
                        
                    }}
                >
                    <Breadcrumbs aria-label="breadcrumb">
                        {/* <Link underline="hover" color="inherit">
                            Inicio
                        </Link> */}
                        <Link to={'/'} style={{ color:'#707070', }}>
                            Inicio
                        </Link>
                        <Link to={'/blog'} style={{ color:'#707070', }}>
                            Blog
                        </Link>
                        <Typography sx={{ color:'#F5323F', }}>
                            Titulo
                        </Typography>
                    </Breadcrumbs>
                </Box>
                <Box>
                    <Box
                        sx={{
                            // backgroundColor:'red',
                            width:'100%',
                            height:{xs:250, sm:400, md:500},
                            display:'flex',
                            alignItems:'center',
                            alignContent:'center',
                        }}
                    >
                        {
                            post && (
                                <Box
                                    sx={{
                                        backgroundColor:'green',
                                        width:'97%',
                                        height:'95%',
                                        m:'0 auto',
                                        borderRadius:'15px',
                                        overflow:'hidden',
                                    }}
                                >
                                    <Box
                                        component='img'
                                        src={post.imagen}
                                        sx={{
                                            minWidth:'100%',
                                            minHeight:'100%',
                                            maxWidth:'100%',
                                            // maxHeight:'110%',
                                        }}
                                    />
                                </Box>
                            )
                        }                        
                    </Box>
                </Box>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={8} md={8}>
                            {
                                post && (
                                    <Box>
                                        <Box>
                                            <Box
                                                sx={{
                                                    color:'#DE4647',
                                                    textAlign:{ xs:'center', sm:'center', md:'left' },
                                                    pl: { xs:2, md:2, },
                                                    mt:{ xs:2, md:2, },
                                                    //mb:{ xs:8, md:0.5, }, 
                                                }}
                                            >
                                                <Typography variant="h4" sx={{ fontFamily:'Montserrat', }}>
                                                    <b>{post.titulo}</b>
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Grid container>
                                                <Grid item>
                                                    <Button variant="text" size="large" color="error" 
                                                        sx={{ 
                                                            fontSize:{ xs:'8px', sm:'8px', md:'8px' }, 
                                                            m:2, 
                                                            p:1, 
                                                            color:'#707070' 
                                                        }}
                                                        onClick={getLike}
                                                        startIcon={
                                                            like ? <ThumbUpIcon sx={{ color: '#F5323F' }}/> : <ThumbUpOffAltIcon sx={{ color: '#F5323F' }}/>
                                                        }                                        
                                                    >
                                                            {likesCount} Likes
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button variant="text" size="large" color="inherit" 
                                                        sx={{ 
                                                            fontSize:{ xs:'8px', sm:'8px', md:'8px' }, 
                                                            m:2, 
                                                            p:1, 
                                                            color:'#707070' 
                                                        }}
                                                        startIcon={<RemoveRedEyeOutlinedIcon sx={{ color: '#F5323F' }}/>}  
                                                    >
                                                            {viewsCount} vistas
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <RWebShare
                                                        data={{
                                                            text: `${post.texto.slice(0, 100)}...`,
                                                            url: `${process.env.REACT_APP_URL}/blog/${idPost}/${post.titulo}`,
                                                            title: `Blog Pecsa | ${post.titulo}`
                                                        }}
                                                        onClick={getShare}
                                                    >
                                                        <Button variant="text" size="large" color="error" 
                                                            sx={{ 
                                                                fontSize:{ xs:'8px', sm:'8px', md:'8px' }, 
                                                                m:2, 
                                                                p:1, 
                                                                color:'#707070' 
                                                            }}
                                                            startIcon={
                                                                <ReplyOutlinedIcon 
                                                                    sx={{ color: '#F5323F' }}
                                                                />
                                                            }                                        
                                                        >
                                                                {sharedsCount} compartida
                                                        </Button>
                                                    </RWebShare>                                        
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box
                                            sx={{
                                                width:'100%',
                                                display:'flex',
                                                alignItems:'center',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width:'95%',
                                                    // height:350,
                                                    // backgroundColor:'yellow',
                                                    // borderRadius:'10px',
                                                    // overflow: 'hidden',
                                                    m:'0 auto',
                                                    // boxShadow:1,
                                                    // // display:'block'
                                                }}
                                            >
                                                {
                                                    Atexto.map((parrafo) => {
                                                        if(parrafo != ''){
                                                            return(
                                                                <Typography variant="subtitle1" sx={{ fontFamily:'Montserrat', }} paragraph>
                                                                    {parrafo}
                                                                </Typography>
                                                            );
                                                        }
                                                    })
                                                }
                                            </Box>
                                        </Box>
                                    </Box>
                                )
                            }                            
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Box
                                sx={{
                                    width:'100%',
                                    // backgroundColor:'yellow',
                                }}
                            >
                                {/* <RedesSociales/> */}
                                {/* <Divider variant="middle"/> */}
                                <Box
                                    sx={{
                                        mt:2,
                                        mb:1,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            pl:1,
                                        }}
                                    >
                                        <Typography variant="h5" sx={{ color:'#DE4647', fontFamily:'Montserrat', }}>
                                            <b>Mas Populares</b>
                                        </Typography>
                                    </Box>
                                    <Box>
                                        {
                                            masPopulares.map((post) => (
                                                <React.Fragment>
                                                    <PostMasPopulares post={post} />
                                                    <Divider variant="middle"/>
                                                </React.Fragment>
                                            ))
                                        }
                                        {/* <PostMasPopulares/>
                                        <Divider variant="middle"/> */}
                                    </Box>
                                    
                                </Box>
                                <Box
                                    sx={{
                                        mt:2,
                                        mb:1,
                                        // backgroundColor:'green',
                                        display:'flex',
                                        alignItems:'center',
                                        alignContent:'center',
                                    }}
                                >
                                    {
                                        promoActual && (
                                            <PromoMes promo={promoActual}/>
                                        )
                                    }
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}

export { BlogPublicacion }