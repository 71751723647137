import * as React from "react";
import { useRef, useEffect, useState } from "react";
import { Box, height } from "@mui/system";
import Container from '@mui/material/Container';

import { FraccionamientosItem } from "./FraccionamientosItem";

//import Carousel from "react-multi-carousel";
//import './styles.css';
import { Button, Grid, Stack, Fab, Skeleton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { motion } from "framer-motion";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const FraccionamientosCarrusel = (props) => {
    // console.log(props.fraccionamientos);
    const [fraccionamientos, setFraccionamientos] = useState(props.fraccionamientos);

    const constraintsRef = useRef(null);
    const containerRef = useRef(null);

    const constraintsRefMovil = useRef(null);
    const containerRefMovil = useRef(null);

    const [Xside, setXside] = React.useState(0);
    const [XIside, setXIside] = React.useState(0);

    const [constraintsWidth, setConstraintsWidth] = React.useState(0);
    const [containerWidth, setContainerWidth] = React.useState(0);

    const atras = (Xside) => {
        setXside(Xside+576);
        setXIside((XIside - 576) < constraintsWidth ? constraintsWidth : (XIside - 576))
    };
    const adelante = (Xside) => {
        setXside(Xside-576);
        setXIside((XIside + 576) > containerWidth ? containerWidth : (XIside + 576))
    };

    useEffect(() => {
        setXIside(constraintsRef.current.clientWidth);
        setConstraintsWidth(constraintsRef.current.clientWidth)
        setContainerWidth(containerRef.current.clientWidth)
    }, [setXIside, setConstraintsWidth, setContainerWidth]);
    return (
        <Container sx={{ backgroundColor:'white' }}>
            <Box sx={{  color: '#f5323f', /*backgroundColor:'yellow'*/ }}>
                <h1>Visita nuestros fraccionamientos y Enamórate</h1>                
            </Box>
            <Box
              component={motion.div}
              ref={constraintsRef}
              sx={{
                backgroundColor:'white', 
                display: { xs: 'none', md: 'flex' },
                placeContent: 'start',
                placeItems: 'center',
                overflow: 'hidden',
                position:'relative'
              }}
            >
                <Stack
                    ref={containerRef}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    //spacing={2}
                    component={motion.div}
                    animate={{ x:Xside }}
                    sx={{
                        //backgroundColor:'yellow',
                        pt:1,
                        pb:2,
                    }}
                >
                    {
                        fraccionamientos.length > 0 ? (
                            fraccionamientos.map((fraccionamiento)=>(
                                <Box
                                    component={motion.div}
                                    sx={{ 
                                        width:{ xs: '185px', sm:'185px', md:'560px', },
                                        ml:1,
                                        mr:1
                                    }}                  
                                >
                                    <FraccionamientosItem fraccionamiento={fraccionamiento}/>
                                </Box>
                            ))
                        ) 
                        : (
                            <React.Fragment>
                                <Box>
                                    <Stack spacing={1}>      
                                        <Skeleton variant="rectangular" width={560} height={360} />
                                        <Skeleton variant="text" width={250} />
                                        <Skeleton variant="text" width={110} />
                                    </Stack>
                                </Box>
                                <Box>
                                    <Stack spacing={1}>      
                                        <Skeleton variant="rectangular" width={560} height={360} />
                                        <Skeleton variant="text" width={250} />
                                        <Skeleton variant="text" width={110} />
                                    </Stack>
                                </Box>                                
                            </React.Fragment>
                        )
                    }                    
                    {/* <Box
                        component={motion.div}
                        sx={{ 
                            width:{ xs: '185px', sm:'185px', md:'565px', lg:'565px' }
                            
                        }}                  
                    >
                        <FraccionamientosItem/>
                    </Box>
                    <Box
                        component={motion.div}
                        sx={{ 
                            width:{ xs: '185px', sm:'185px', md:'565px', lg:'565px' }
                            
                        }}                  
                    >
                        <FraccionamientosItem/>
                    </Box> */}
                </Stack>
                {
                    XIside !== constraintsWidth && (
                        <Box 
                            component="Button" 
                            sx={{ 
                            pt: 3, pb:3, pl:0, pr:0,
                            backgroundColor:'white', 
                            border:'none',
                            color:'#f5323f',
                            zIndex:1, 
                            position:'absolute',
                            left:0,
                            }}
                            variant="contained"
                            onClick={()=> atras(Xside)}
                        >
                        <ChevronLeftIcon />
                        </Box>
                    )
                }
                {
                    XIside !== containerWidth && (
                        <Box 
                            component="Button" 
                            sx={{ 
                            pt: 3, pb:3, pl:0, pr:0,
                            backgroundColor:'white', 
                            border:'none',
                            color:'#f5323f',
                            zIndex:1, 
                            position:'absolute',
                            right:0,
                            }}
                            variant="contained"
                            onClick={()=> adelante(Xside)}
                        >
                        <ChevronRightIcon />
                        </Box>
                    )
                }                        
            </Box>

            <Box
              component={motion.div}
              ref={constraintsRefMovil}
              sx={{
                backgroundColor:'white', 
                display: { xs: 'flex', md: 'none' },
                placeContent: 'start',
                placeItems: 'center',
                overflow: 'hidden',
                position:'relative',
                marginTop:2
              }}
            >
                <Stack
                    ref={containerRefMovil}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    //spacing={2}
                    component={motion.div}
                    animate={{ x:Xside }}
                    drag='x' 
                    dragConstraints={constraintsRefMovil}
                    sx={{ pr:2, }}
                >
                    {
                        fraccionamientos.length > 0 ? (
                            fraccionamientos.map((fraccionamiento)=>(
                                <Box
                                    component={motion.div}
                                    sx={{ 
                                        width:{ xs: '290px', sm:'565px', md:'565px', lg:'565px' },
                                        ml:1.4,
                                        mr:1.4,
                                    }}                  
                                >
                                    <FraccionamientosItem fraccionamiento={fraccionamiento}/>
                                </Box>
                            ))
                        )
                        :(
                            <Box>
                                <Stack spacing={1}>      
                                    <Skeleton variant="rectangular" width={350} height={360} />
                                    <Skeleton variant="text" width={250} />
                                    <Skeleton variant="text" width={110} />
                                </Stack>
                            </Box>
                        )
                    }
                    {/* <Box
                        component={motion.div}
                        sx={{ 
                            width:{ xs: '290px', sm:'565px', md:'565px', lg:'565px' }
                            
                        }}                  
                    >
                        <FraccionamientosItem/>
                    </Box>
                    <Box
                        component={motion.div}
                        sx={{ 
                            width:{ xs: '290px', sm:'565px', md:'565px', lg:'565px' }
                            
                        }}                  
                    >
                        <FraccionamientosItem/>
                    </Box> */}
                </Stack>
            </Box>
        </Container>
    )
};

export { FraccionamientosCarrusel };