import { Box } from '@mui/system';
import * as PANOLENS from 'panolens';
import { useEffect } from 'react';


const PanoramaSencillo = ({ linkTourVirtual }) => {
    // const Mostrar = () => {
    //     return()
    // }
    
    
    useEffect(()=>{
        
        // const panorama = new PANOLENS.ImagePanorama('https://d2kg7bu049hzg.cloudfront.net/mapa/GS__4166.JPG');
        const panorama = new PANOLENS.ImagePanorama(linkTourVirtual);
        // const panorama2 = new PANOLENS.ImagePanorama('https://d2kg7bu049hzg.cloudfront.net/mapa/GS__4167.JPG');
        const viewer = new PANOLENS.Viewer({ 
            // output: "console",
            container:document.getElementById('pano')
        });
        // const infospot = new PANOLENS.Infospot( 500, PANOLENS.DataImage.Arrow );
        // // infospot.position.set( -1000, 500, 5000 );
        // // infospot.position.set( 3000, -500, 1000 );
        // infospot.position.set( -3000, -200, 10000 );
        // // infospot.addHoverText( 'Modelo T-106', 50 );
        // infospot.addEventListener( 'click', function(){
        //     viewer.setPanorama( panorama2 );
        // } );
        // panorama.add( infospot );

        // const infospot2 = new PANOLENS.Infospot( 500, PANOLENS.DataImage.Arrow );
        // infospot2.position.set( 3000, -3000, -9000 );
        // //infospot2.addHoverText( 'Modelo T-106', 50 );
        // infospot2.addEventListener( 'click', function(){
        //     viewer.setPanorama( panorama );
        // } );
        // panorama2.add( infospot2 );

        // viewer.add(panorama, panorama2);
        viewer.add(panorama);
    },[]);
    return(
        <Box 
            component='div' 
            id='pano'
            sx={{
                width:'100%',
                height:'100%',
            }}
        >
        </Box>
    );
}

export { PanoramaSencillo }