import { Button, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useState } from "react";

import EditIcon from '@mui/icons-material/Edit';
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import moment from "moment";
import axios from "axios";

const EditEducacion = ({ getEditar, escuela, updateDatos, }) => {
    const { folio } = useParams();

    const [instituto, setInstituto] = useState(escuela.escuela);
    const getInstituto = (e) => {
        setInstituto(e.target.value);
    }

    const [titulo, setTitulo] = useState(escuela.titulo);
    const getTitulo = (e) => {
        setTitulo(e.target.value);
    }

    const [fechaInicio, setFechaInicio] = useState(moment(escuela.fechaInicio));
    const [fechaFinal, setFechaFinal] = useState(moment(escuela.fechaFinal));

    const edit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        const prueba = {
            escuela: data.get('escuela'),
            titulo: data.get('titulo'),
            fechaInicio: fechaInicio.format('yyyy-MM-DD HH:mm'),
            fechaFinal: fechaFinal.format('yyyy-MM-DD HH:mm'),
        };
        // console.log(escuela.id);
        // console.log(prueba); return;
        axios.post(`${process.env.REACT_APP_API_URL}/api/asesores/profile/${folio}/informacion/educacion/${escuela.id}`,
            {
                escuela: data.get('escuela'),
                titulo: data.get('titulo'),
                fechaInicio: fechaInicio.format('yyyy-MM-DD HH:mm'),
                fechaFinal: fechaFinal.format('yyyy-MM-DD HH:mm'),
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
        .then((response)=>{
            console.log(response);
            // setInformacion(response.data)
            updateDatos(instituto, titulo, fechaInicio, fechaFinal);
            getEditar();
        })
        .catch((error)=>{
            console.log(error);
        });
    }
    useEffect(()=>{
        setInstituto(escuela.escuela);
        setTitulo(escuela.titulo);
        setFechaInicio(moment(escuela.fechaInicio));
        setFechaFinal(moment(escuela.fechaFinal));
    },[escuela]);

    return(
        <Box>
            <Box component="form" onSubmit={edit}>
                <Grid
                    container
                    spacing={1}
                >
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" label="Lugar de Trabajo" variant="outlined" name="escuela" value={instituto} onChange={getInstituto} sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" label="Puesto" variant="outlined" name="titulo" value={titulo} onChange={getTitulo} sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        {/* <TextField id="outlined-basic" label="Nombre" variant="outlined" name="nombre" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/> */}
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="Fecha de Inicio"
                                value={fechaInicio}
                                onChange={(newValue) => {
                                    setFechaInicio(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="Fecha de Fin"
                                value={fechaFinal}
                                onChange={(newValue) => {
                                    setFechaFinal(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box
                            sx={{
                                mt:1,
                                // mb:1,
                                // backgroundColor:'red',
                                width:'100%',
                                // textAlign:'right',
                            }}
                        >
                            <Button 
                                variant="contained" 
                                endIcon={<EditIcon />}
                                sx={{
                                    backgroundColor:'#707070',
                                    textTransform:'none',
                                    borderRadius:'10px',
                                    width:{ xs:'100%', sm:'100%', md:'40%' },
                                    mr:0.5,
                                }}
                                onClick={getEditar}
                            >
                                <b>Cancelar</b>
                            </Button>
                            <Button 
                                variant="contained" 
                                endIcon={<EditIcon />}
                                sx={{
                                    backgroundColor:'#707070',
                                    textTransform:'none',
                                    borderRadius:'10px',
                                    width:{ xs:'100%', sm:'100%', md:'40%' }
                                }}
                                type="submit"
                            >
                                <b>Guardar</b>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export { EditEducacion }