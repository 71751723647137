import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { Autocomplete, Avatar, Button, Container, Divider, Grid, Stack, TextField } from "@mui/material"
import { Box } from "@mui/system";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

const EditManzana = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const parametros = useParams();
    console.log(parametros);
    const [manzana, setManzana] = useState([]);
    // const [etapas, setEtapas] = useState([]);
    // const [selectedEtapa, setSelectedEtapa] = useState(null);

    const edit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');        

        // console.log(manzana); return;
        axios.post(`${process.env.REACT_APP_API_URL}/api/manzana/update`, 
            {
                // nombre: data.get('nombre'),                
                // idEtapa: etapa.id,
                id: manzana.id,
                nombre: manzana.nombre,                
                idEtapa: manzana.idEtapa,

                folioUpdatedBy: folio,
            },
            {
              headers: {
                  Authorization: `Bearer ${token}`
              }              
        })
        .then((response) => {
            // handle success            
            console.log(response);
            console.log('success axio');
            //navigate(-1, { replace: true });
            //etapa.manzanas.push(response.data);
            // navigate(`/cargas/inventario/${etapa.folio.toString()}`, {state:{etapa:etapa}});
            navigate(-1, { replace: true });
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sabe');
        });
    }

    useEffect(() => {
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        
        axios.get(`${process.env.REACT_APP_API_URL}/api/manzana/updateview/${parametros.manzana}`, {
              headers: {
                  Authorization: `Bearer ${token}`
              }
        })
        .then((response) => {
            // handle success
            console.log(response);
            setManzana(response.data.manzana);
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
        });
    }, [setManzana]);
    return(
        <Container sx={{ backgroundColor:'white' }}>
            <Box component="form" onSubmit={edit}> 
                <h2>Editar Manzana</h2>
                <Grid container spacing={2}>
                    <Grid item lg={7} divider={<Divider orientation="vertical" flexItem />}>
                        <Stack spacing={3}>
                            { 
                                manzana.nombre ? <TextField id="outlined-basic" label="Nombre" variant="outlined" name="nombre" defaultValue={manzana.nombre} onChange={(e) => setManzana({...manzana, nombre:e.target.value})} required/>
                                :<TextField id="outlined-basic" label="Nombre" variant="outlined" name="nombre" onChange={(e) => setManzana({...manzana, nombre:e.target.value})} required/>
                            }
                            {/* <Autocomplete
                                id="grouped-demo"
                                options={etapas}
                                getOptionLabel={(etapa) => etapa.nombre}
                                value={selectedEtapa}
                                onChange={(event, newEtapa)=>{setSelectedEtapa(newEtapa);}}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Etapa" name="etapa" required/>}
                            /> */}
                        </Stack>                        
                    </Grid>
                    {/* <Divider orientation="vertical" flexItem variant="middle"/> */}
                    {/* <Grid item lg={4}>
                        <Avatar sx={{ width: 200, height: 200 }}>
                            <AddAPhotoIcon />
                        </Avatar>
                    </Grid> */}
                </Grid>                                               
                <Button variant="contained" type="submit">Guardar</Button>
            </Box>
        </Container>
    );
}

export { EditManzana }