import React, { useState } from "react";
import PropTypes from "prop-types";

/**
 * Componente BaseIcon, provee la base de estilos y lógica a un ícono.
 *
 * @component
 * @example
 * // Ejemplo de uso:
 * <BaseIcon>
 *   <path d="M0 0h24v24H0z" fill="none" />
 * </BaseIcon>
 *
 * @param {string} [color='#DDD'] - Color del ícono.
 * @param {function} [onClick] - Función manejadora del clic.
 * @param {ReactNode} children - Contenido del ícono.
 * @returns {JSX.Element} Componente BaseIcon.
 *
 * @notes
 * - Actualmente, el efecto de hover es un desplazamiento hacia arriba de 5px.
 * - En futuras actualizaciones, se podría considerar permitir la personalización del efecto de hover.
 */
const BaseIcon = ({ color, onClick, children, title }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const baseStyle = {
    fill: color || "#DDD", // Usa un color proporcionado o '#DDD' como predeterminado
    cursor: onClick ? "pointer" : "default", // Establece el cursor solo si onClick está presente
    transition: "transform 0.3s ease-out",
    transform: isHovered ? "translateY(-5px)" : "translateY(0)",
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      style={baseStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
    >
      {children}
    </svg>
  );
};

BaseIcon.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
};

export { BaseIcon };
