import React, { useEffect, useState } from "react";
import { Box, Container, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography, SvgIcon, Button, IconButton, MobileStepper, Modal } from "@mui/material";
import { motion } from "framer-motion";
import Carousel from 'react-material-ui-carousel'
// import { ReactComponent as banio } from '../../assets/banio_icon.svg'
// import { ReactComponent as terreno } from '../../assets/terreno_icon.svg'
// import { ReactComponent as construccion } from '../../assets/construccion_icon.svg'
// import { ReactComponent as habitaciones } from '../../assets/habitaciones_icon.svg'
// import { ReactComponent as casita } from '../../assets/casita.svg'

import elrocal from '../../assets/fraccrenders/elrocal.png';
import { useRef } from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import HouseIcon from '@mui/icons-material/House';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from "react-router-dom";

import CloseIcon from '@mui/icons-material/Close';
import { PanoramaSencillo } from "../../components/Panoramas/PanoramaSencillo";

const Principal = ({ fraccionamiento }) => {    
    // const [fraccionamiento, setFraccionamiento] = useState(props.fraccionamiento || {});
    let navigate = useNavigate();
    console.log(fraccionamiento);
    const [fotoPrincipal, setFotoPrincipal] = useState(fraccionamiento.fotos[0].url || null);
    const [fotos, setFotos] = useState(fraccionamiento.fotos || []);

    const [lotesDisponibles, setLotesDisponibles] = useState(0);

    const contarLotesDisponibles = (fraccionamiento) => {
        const LotesDisponibles = fraccionamiento.etapas.reduce((count, current) => { return count + current.manzanas.reduce((count, current) => { return count + current.lotes.length }, 0) }, 0 )
        return LotesDisponibles;
    }
    
    const reedirigir = () =>{
        window.open(fraccionamiento.linkTourVirtual)
    }
    const reedirigirMapa = () =>{
        window.open(fraccionamiento.LinkUbicacion)
    }
    const reedirigirCalendly = () =>{
        window.open('https://calendly.com/casas-pecsa')
    }
    const reedirigirATienda = (fraccionamiento) =>{
        
        if(fraccionamiento){
            navigate(`/tienda?modelofilter=&fraccionamientofilter=${fraccionamiento.nombre?fraccionamiento.nombre.replace(/ /g, "+"):''}`)
        }
        // else{
        //     navigate(`/tienda?modelofilter=${modelo.nombre.replace(/ /g, "+")}&fraccionamientofilter=${''}`)
        // }
        // navigate('/tienda')
    }
    const constraintsRef = useRef(null);
    const containerRef = useRef(null);

    const [Xside, setXside] = React.useState(0);
    const atras = (Xside) => {
      setXside(Xside+395.96);
      console.log(constraintsRef.current.clientWidth);
      console.log(containerRef.current.clientWidth);
    };
    const adelante = (Xside) => {
      setXside(Xside-395.96);
      console.log(constraintsRef.current.clientWidth);
    console.log(containerRef.current.clientWidth);
    };

    const [promocion, setPromocion] = useState(null);

    const [openModalRecorridoVirtual, setOpenModalRecorridoVirtual] = useState(false);
    const handleOpenModalRecorridoVirtual = () => setOpenModalRecorridoVirtual(true);
    const handleCloseModalRecorridoVirtual = () => setOpenModalRecorridoVirtual(false);

    useEffect(()=>{
        setLotesDisponibles(contarLotesDisponibles(fraccionamiento));
        setFotoPrincipal(fraccionamiento.fotos[0].url);
        setFotos(fraccionamiento.fotos);
        setPromocion(fraccionamiento.promocion)
    }, [fraccionamiento])
    return(
        <Container 
            sx={{ 
                backgroundColor:'white',
                height:{ xs:'none', sm:420, md:570 }
            }}
        >
            <Grid 
                container
                direction={{ xs:"row", sm:"row-reverse", md:"row-reverse" }}
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <Grid item xs={12} sm={7} md={7} sx={{ display: { xs:'none', sm:'flex', md:'flex' } }} >
                    <Stack
                        direction="column"
                        justifyContent="center"
                        //alignItems="flex-start"  
                        sx={{
                            width:'100%',
                            
                        }}                      
                    >
                        {
                            fotoPrincipal && (
                                <Box
                                    sx={{
                                        //backgroundColor:'red',
                                        width: { sm:'100%', md:'95%' },
                                        height: { xs:250, sm:300, md:445 },
                                        m:1,
                                        mt:{sm:2.5, md:1},
                                        display:'flex',
                                        alignItems:'center',
                                    }}
                                >
                                    <Box
                                        component="img"
                                        src={fotoPrincipal}
                                        sx={{
                                            maxWidth: { sm:'100%', md:'90%' },
                                            maxHeight: { xs:250, sm:300, md:445 },
                                            m:'0 auto',
                                            // mt:{sm:2.5, md:1}
                                            
                                        }}
                                        
                                    />
                                </Box>
                            )
                        }                        
                        <Box
                            component={motion.div}
                            ref={constraintsRef}
                            sx={{
                                backgroundColor:'white', 
                                display: 'flex',
                                placeContent: 'start',
                                placeItems: 'center',
                                overflow: 'hidden',
                                position:'relative',
                                ml:1
                            }}
                        >
                            <Stack
                                    ref={containerRef}
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}
                                    component={motion.div}
                                    animate={{ x:Xside }}
                                    sx={{
                                        pl:4,
                                        pr:3,
                                    }}
                            >
                                {
                                    fotos && (
                                        fotos.map((foto) => (
                                            <Box
                                                component="img"
                                                onClick={()=>{ setFotoPrincipal(foto.url) }}
                                                sx={{ 
                                                    // width: '375px'
                                                    // width: '185px'
                                                    width:{ xs: '55px', sm:'70px', md:'120px' },
                                                    height:{ xs: '55px', sm:'50px', md:80 },
                                                    //pointerEvents: 'none',
                                                    
                                                                                        
                                                }}
                                                src={foto.url}
                                            />
                                        ))
                                    )
                                }
                            </Stack>

                            {/* <Box 
                                component="Button" 
                                sx={{ 
                                    pt: 3, pb:3, pl:0, pr:0,
                                    backgroundColor:'white', 
                                    border:'none',
                                    color:'red',
                                    zIndex:1, 
                                    position:'absolute',
                                    left:0,
                                }}
                                variant="contained"
                                onClick={()=> atras(Xside)}
                            >
                                <ChevronLeftIcon />
                            </Box>

                            <Box 
                                component="Button" 
                                sx={{ 
                                    pt: 3, pb:3, pl:0, pr:0,
                                    backgroundColor:'white', 
                                    border:'none',
                                    color:'red',
                                    zIndex:1, 
                                    position:'absolute',
                                    right:0,
                                }}
                                variant="contained"
                                onClick={()=> adelante(Xside)}
                            >
                                <ChevronRightIcon />
                            </Box> */}
                        </Box>                        
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={7} md={7} sx={{ display: { xs:'flex', sm:'none', md:'none' } }}>
                    <Carousel sx={{ width:'100%' }}>
                        {
                            fotos.map((foto) => (
                                // <Box
                                //     component="img"
                                //     src={foto.url}

                                //     sx={{
                                //         width:'100%',
                                //         height:280,
                                    
                                //     }}
                                // />
                                <Box
                                    sx={{
                                        backgroundColor:'#F0F0F0',
                                        width:'100%',
                                        height:280,
                                        display:'flex',
                                        alignItems:'center',
                                        overflow:'hidden',
                                    }}
                                >
                                    <Box
                                        component="img"
                                        src={foto.url}

                                        sx={{
                                            maxWidth:'100%',
                                            maxHeight:'100%',
                                            // height:280,
                                            m:'0 auto',
                                        }}
                                    />
                                </Box>
                            ))
                        }
                    </Carousel>
                </Grid> 
                <Grid item xs={12} sm={5} md={5}>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        //spacing={0.5}
                        sx={{
                            paddingTop: { xs:1, sm:3, md:4 },
                            color:'#707070',
                        }}
                    >
                        <Typography variant="h4" component="div" sx={{ display: { xs:'none', sm:'none', md:'block'}, fontFamily:'Montserrat', color:'#F5323F', }}>
                            {/* El Rocal */}
                            <b>{fraccionamiento.nombre}</b>
                        </Typography>
                        <Typography variant="h5" component="div" sx={{ display: { xs:'block', sm:'block', md:'none' }, fontFamily:'Montserrat' }}>
                            {/* El Rocal */}
                            <b>{fraccionamiento.nombre}</b>
                        </Typography>
                        <Typography variant="body2" component="div" sx={{ mt: { xs:1, sm:1, md:3 }, fontFamily:'Montserrat' }}>
                            <FmdGoodIcon fontSize="2"/> {fraccionamiento.municipio.nombre}
                        </Typography>
                        <Box
                        component='div'
                            sx={{
                                display:'inline',
                            }}
                        >
                            <Typography variant="body1" component="div" sx={{ display:'inline', ml:3, fontFamily:'Montserrat' }} >
                                {fraccionamiento.descripcionUbicacion}
                            </Typography> 
                            <Button variant="text" size="medium" color="error" sx={{ fontSize:'10px', fontFamily:'Montserrat', color:'#F5323F', }} onClick={reedirigirMapa}>Ver Mapa</Button>
                        </Box>
                        <Typography variant="body1" component="div" sx={{ mb:{ xs: 1, sm: 1, md: 1 }, fontFamily:'Montserrat' }}>
                            Inventario Disponible: { lotesDisponibles } casas
                        </Typography>
                        
                        <Box
                            component="div"
                            sx={{
                                textAlign:'left',
                                height:{ xs: 100, sm: 70, md: 110 },
                                width:'100%',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                mt: { xs:1, sm:0, md:2 }
                            }}
                        >
                            <Typography variant="body2" component="div" sx={{  fontSize: { xs: 12, sm: 13, md: 13 }, fontFamily:'Montserrat' }}>
                                {fraccionamiento.descripcion}                                
                            </Typography>
                        </Box>
                        {
                            promocion && (
                                <Box
                                    component='img'
                                    src={promocion.logo}
                                    // src='https://casaspecsa-bucket.s3.us-east-2.amazonaws.com/Promociones/Kermesse+Septiembre/logo/logo_mes_septiembre_2.svg'
                                    sx={{
                                        width:100
                                    }}
                                />
                                // <img class="MuiBox-root css-1d4jc9f" src="https://casaspecsa-bucket.s3.us-east-2.amazonaws.com/Promociones/Gran%20kermesse%20Pecsa/logo/logo_mes_septiembre.svg" />
                            )
                        }
                        <Grid 
                            container 
                            spacing={1}
                            sx={{ 
                                mt: { xs:2, sm:2, md:3 },
                                mb: { xs:2, sm:'none', md:'none' } 
                            }}
                        >
                            <Grid item xs={12} sm={12} md={6}>
                                <Button variant="outlined" size="small" color="error"                                     
                                    sx={{
                                        /*fontSize:'10px',*/ 
                                        width: { xs:'95%', sm:'90%', md:'100%' }, 
                                        p:{ xs:'none', sm:'none', md:1 },
                                        borderColor:'#F5323F',
                                        color:'#F5323F',
                                        fontFamily:'Montserrat' 
                                    }}
                                    onClick={handleOpenModalRecorridoVirtual}
                                    disabled={fraccionamiento.linkTourVirtual ? false : true}
                                >
                                    <b>RECORRIDO VIRTUAL</b>
                                </Button>
                            </Grid>                            
                            <Grid item xs={12} sm={12} md={6}>
                                <Button variant="outlined" size="small" color="error" 
                                    // onClick={reedirigirCalendly}
                                    onClick={()=>{ navigate('/citas'); }}
                                    sx={{ 
                                        /*fontSize:'10px',*/ 
                                       width: { xs:'95%', sm:'90%', md:'100%' }, 
                                        p:{ xs:'none', sm:'none', md:1 },
                                        borderColor:'#F5323F',
                                        color:'#F5323F',
                                        fontFamily:'Montserrat' 
                                    }}
                                >
                                    <b>AGENDAR CITA</b>
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                {
                                    lotesDisponibles === 0 ? (
                                        <Button variant="outlined" size="small" color="error" 
                                    // onClick={()=>{ navigate('/tienda') }}
                                    //onClick={()=>{ reedirigirATienda(fraccionamiento) }}
                                    sx={{  
                                        width: { xs:'95%', sm:'90%', md:'100%' }, 
                                        p:{ xs:1, sm:1, md:1 },
                                        borderColor:'#F5323F',
                                        color:'#F5323F',
                                        fontFamily:'Montserrat',
                                    }}
                                    disabled
                                >
                                    <b>próximamente</b>
                                </Button>
                                    ) : (
                                        <Button variant="outlined" size="small" color="error" 
                                    // onClick={()=>{ navigate('/tienda') }}
                                    onClick={()=>{ reedirigirATienda(fraccionamiento) }}
                                    sx={{  
                                        width: { xs:'95%', sm:'90%', md:'100%' }, 
                                        p:{ xs:1, sm:1, md:1 },
                                        borderColor:'#F5323F',
                                        color:'#F5323F',
                                        fontFamily:'Montserrat',
                                    }}
                                >
                                    <b>SEPARAR CASA</b>
                                </Button>
                                    )
                                }
                                {/* <Button variant="outlined" size="small" color="error" 
                                    // onClick={()=>{ navigate('/tienda') }}
                                    onClick={()=>{ reedirigirATienda(fraccionamiento) }}
                                    sx={{  
                                        width: { xs:'95%', sm:'90%', md:'100%' }, 
                                        p:{ xs:1, sm:1, md:1 },
                                        borderColor:'#F5323F',
                                        color:'#F5323F',
                                        fontFamily:'Montserrat',
                                    }}
                                >
                                    <b>VER CASA</b>
                                </Button> */}
                            </Grid>
                        </Grid>
                        {/* <Typography variant={{ xs: 'p', sm: 'p', md: 'h6' }} component="div" >
                            Otros Fraccionamientos:
                        </Typography>
                        <FormControl sx={{  minWidth: { xs:'100%', sm:'100%', md:280 }, ml:{ xs:'none', sm:'none', md:4 }, mt:{ xs:1, sm:1, md:1 } }} size="small">                                            
                            <Select                                                
                                id="demo-select-small"
                            >
                                <MenuItem value={10}>Lomas del Morillo</MenuItem>
                                <MenuItem value={20}>El Rocal</MenuItem>
                            </Select>
                        </FormControl> */}
                    </Stack>
                </Grid>
                               
            </Grid>
            <Modal
                open={openModalRecorridoVirtual}
                onClose={handleCloseModalRecorridoVirtual}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: {xs:'95%', sm:'95%', md:'99%', lg:'94%' },
                    bgcolor: 'background.paper',
                    borderRadius:{xs:'10px', sm:'12px', md:'12px'},
                    //border: '2px solid #000',
                    boxShadow: 24,
                    //p: 4,
                    color:'#707070',
                }}>
                    <Box>
                        <Box
                            sx={{                                
                                // display:'flex',
                                // backgroundColor:'green',
                            }}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item xs={10} sm={11} md={11}>
                                    <Box
                                        sx={{
                                            pl:{xs:1, sm:4, md:4},
                                            pt:4,
                                            // pb:2,
                                            // backgroundColor:'yellow',
                                        }}
                                    >
                                        <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ color:'#F5323F', }}>
                                            <b>Mira a tu alrededor.</b>
                                        </Typography>                                        
                                    </Box>
                                </Grid>
                                <Grid item xs={2} sm={1} md={1}>
                                    <Box
                                        sx={{
                                            textAlign:'right',
                                            // backgroundColor:'green',
                                        }}
                                    >
                                        <IconButton aria-label="delete" onClick={handleCloseModalRecorridoVirtual}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>   
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Box
                                        sx={{
                                            pl:{xs:1, sm:4, md:4},
                                            pb:2,
                                        }}
                                    >
                                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                            Nunca habia sido tan facil visitar tu nueva casa Pecsa.
                                        </Typography>
                                    </Box>   
                                </Grid>
                            </Grid>                            
                        </Box>
                        
                        <Box
                            sx={{
                                backgroundColor:'green',
                                width:{xs:'100%', sm:'100%', md:'100%', lg:'100%' },
                                height:{xs:500, sm:400, md:600 },
                                m:'0 auto'
                            }}
                        >
                            <PanoramaSencillo linkTourVirtual={fraccionamiento.linkTourVirtual} />
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Container>
    );
}

export { Principal }