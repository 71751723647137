import axios from "axios";
import React, { useEffect, useState } from "react";
import {PaymentElement,  useElements, useStripe} from '@stripe/react-stripe-js';
import { Alert, Button } from "@mui/material";

import LockIcon from '@mui/icons-material/Lock';
import LoadingButton from '@mui/lab/LoadingButton';
import DoneIcon from '@mui/icons-material/Done';
import { useNavigate, useSearchParams } from "react-router-dom";
import TagManager from "react-gtm-module";

const PayFormAll = ({lote, transaccion, promocion}) => {
    let navigate = useNavigate();
    const [searchParams] = useSearchParams();
    // console.log('Chequemos Variable INVERSION:::::',(searchParams.get('inversion') === 'true' ? true : false));
    //const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');

    const stripe = useStripe();
    const elements = useElements();

    const [loading, setLoading] = useState(false);
    const [completed, setCompleted] = useState(false);

    const [mssgError, setMssgError] = useState(null);

    const addTagManager = (datosTransaccion) => {
        console.log(datosTransaccion);
        TagManager.dataLayer({
            dataLayer: {
                event:'eventoTransaccionExitoso',
                activar:true,
                datos:{
                    transaccion: {
                        folio: datosTransaccion.folio,
                        folioPasarela: datosTransaccion.idMedioPago,
                        estatus:'Exitosa',
                    },                        
                    lote:{
                        lote:lote.numeroDeLote,
                        manzana:lote.manzana.nombre,
                        fraccionamiento: lote.manzana.etapa.fraccionamiento.nombre,                        
                        modelo:lote.fachada.modelo.nombre,
                        fachada:lote.fachada.nombre,
                        folio:lote.folio,
                    },                                                
                    datos_persona: {
                        nombre:datosTransaccion.cliente.datos.primerNombre,
                        apellidoPaterno:datosTransaccion.cliente.datos.primerApellido,
                        apellidoMaterno:datosTransaccion.cliente.datos.segundoApellido,
                        email:datosTransaccion.cliente.autenticacion.email,
                        telefono:datosTransaccion.cliente.autenticacion.telefono,
                    },
                }
            }
        })
    }

    const checkOut = async (e) => {
        e.preventDefault();
        setLoading(true);

        

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        try{
            const dispo = await axios.post(`${process.env.REACT_APP_API_URL}/api/venta/checkAvailable`, 
            {
                idLote: lote.id,
            }
            // {
            //     headers: { 
            //         Authorization: `Bearer ${token}`,   
            //     }
            // }
            );

        } catch(e){
            console.log('no hay');
            navigate(-1);
            return;
        }
        
        
        const { error, paymentIntent } = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: '',
            },
            redirect: "if_required",
        });
        
        if(!error){
            console.log(paymentIntent);
            
            console.log({ paymentMethod: paymentIntent, datosTransaccion: { idTransaccion: transaccion.id, idLote: lote.id } });
            
            axios.post(`${process.env.REACT_APP_API_URL}/api/venta/checkout`, 
            { 
                paymentMethod: paymentIntent, 
                datosTransaccion: {
                    //description: `Separacion Lote ${ lote.numeroDeLote }`, 
                    idTransaccion: transaccion.id, 
                    idLote: lote.id,
                    idPromocion: promocion ? promocion.id : null,
                    // inversion: searchParams.get('inversion') ? searchParams.get('inversion') : false,
                    inversion: searchParams.get('inversion') === 'true' ? true : false,
                },
            } 
            // {
            //     headers: { 
            //         Authorization: `Bearer ${token}`,   
            //     }
            // }
            )
            .then((response) => {
                // handle success
                console.log(response);
                setLoading(false);
                setCompleted(true);                
                navigate(`/pagoSuccess/${response.data.transaccion.folio}`);
                addTagManager(response.data.transaccion);
            })
            .catch((error) => {
                // handle success
                console.log(error);
            })
            .then(() => {
                // handle success
                //console.log('sabe');
            });
        }else{
            setLoading(false);
            setMssgError(error.message)
            // console.log(error.message);
        }
    }

    return(
        <form onSubmit={checkOut}>
            <PaymentElement />
            {   mssgError && (
                    <Alert
                        onClose={ ()=>{ setMssgError(null) } }
                        severity="error"
                        sx={{ mt:1 }}
                    >
                        { mssgError }
                    </Alert>
                ) 
            }
            <LoadingButton
                type="submit" 
                //onClick={handleClick}
                endIcon={ completed ? <DoneIcon /> : <LockIcon />}
                loading={loading}
                disabled={completed ? true : loading}
                // loadingPosition="end"
                variant="outlined"
                sx={{ 
                    width: '100%',
                    mt:{ xs:1, sm:1, md:2 },
                    p:{ xs:1, sm:1, md:1 },
                    borderColor: completed ? '#5cd65c': '#F5323F',
                    color: completed ? '#5cd65c': '#F5323F',
                    borderWidth:3,
                    //backgroundColor:!stripe ? '#80ff80': '',
                }}
                color="inherit"
            >
                {completed ? (<b>Pagado</b>) : (<b>Pagar</b>)}
            </LoadingButton>
            
            {/* <Button 
                type="submit" 
                variant="contained" 
                sx={{ width: '100%' }} 
                endIcon={<LockIcon />}
                disabled={!stripe}
            >
                <b>Pagar</b>
            </Button> */}
        </form>
    );
}

export { PayFormAll }