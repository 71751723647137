import { Box, Button, Grid, Typography } from "@mui/material";
import { ItemCharacteristics } from "./ItemCharacteristics";
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import BathtubOutlinedIcon from '@mui/icons-material/BathtubOutlined';
import { TableCharacteristics } from "./TableCharacteristics";
import { ItemTableCharacteristics } from "./TableCharacteristics/ItemTableCharacteristics";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ButtonVerMas } from "./ButtonVerMas";
import { useNavigate } from "react-router-dom";
import { openNewTab } from "../../../../../functions/openNewTab";

const Characteristics = ({ lote }) => {
	// console.log('llegamos a Characteristics:', lote);
	let navigate = useNavigate();

	return(
		<Box
			sx={{
				// backgroundColor:'#E0FFFF',
			}}
		>
			<Grid
				container
				direction="row"
				justifyContent="flex-start"
				alignItems="flex-start"
				sx={{
					gap:1
				}}
			>
				<Grid item xs={12} sm={12} md={12}>
					{/* <Box
						sx={{
							backgroundColor:'#5F9EA0',
							mb:0.5,
						}}
					>
						<Typography sx={{ fontSize:'1rem' }}>
							106 m² de construcción
						</Typography>
					</Box> */}
					<ItemCharacteristics texto={`${lote.fachada.modelo.superficieDeConstruccion} m² de construcción`} />
				</Grid>
				<Grid item xs={12} sm={12} md={12}>
					<ItemCharacteristics texto={`${lote.superficieDeTerreno} m² de terreno`} />
				</Grid>
				<Grid item xs={12} sm={12} md={12}>
					<TableCharacteristics>
						<ItemTableCharacteristics first={true} icon={<BedOutlinedIcon sx={{ fontSize:'0.9rem' }}/>} name={`${lote.caracteristicas[0].recamaras} Habitaciones`} />
						<ItemTableCharacteristics icon={<BathtubOutlinedIcon sx={{ fontSize:'0.9rem' }}/>} name={`${lote.caracteristicas[0].banios} Baños`} />
					</TableCharacteristics>
				</Grid>
				<Grid item xs={12} sm={12} md={12}>
					<ButtonVerMas handleClick={()=>openNewTab(`/tienda/${lote.sku.replace(/ /g, "_")}`)}/>
				</Grid>
			</Grid>
		</Box>
	);
}

export { Characteristics }