import { Box, Button, Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
    let navigate = useNavigate();

    const reedirigirHome = () =>{
        navigate('/');
        // console.log('holoa')
    }

    return(
        <Box
            sx={{
                mt:12,
            }}
        >
            <Helmet>
                <title>Casas Pecsa | 404- Not Found</title>
                <meta name="description" content="Pagina no disponible" />
            </Helmet>
            <Container>
                <Box
                    sx={{
                        width:'100%',
                        color:'#F5323F',
                        minHeight:500,
                        display:'flex',
                        alignItems:'center',
                    }}
                >
                    <Box
                        sx={{
                            width:'90%',
                            textAlign:'center',
                            // color:'#F5323F',
                            m:'0 auto',
                        }}
                    >
                        <Typography variant="h3">
                            <b>Pagina no encontrada.</b>
                        </Typography>
                        <Typography variant="h2">
                            <b>404</b>
                        </Typography>
                        <Box
                            sx={{
                                mt:4,
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="inherit"
                                sx={{
                                    color:'#F5323F',
                                    textTransform:'none',
                                    borderWidth:2,
                                    borderRadius:'10px',
                                }}
                                onClick={reedirigirHome}
                            >
                                <b><a>Ir a Home</a></b>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}

export { NotFound }