import { Box, Container, Divider, IconButton, InputBase, Paper, Typography, } from "@mui/material";
import { ListaSeparaciones } from "../../components/ListasClientes/ListaSeparaciones";
import { ListaVendidos } from "../../components/ListasClientes/ListaVendidos";
import { ListaCancelados } from "../../components/ListasClientes/ListaCancelados";
import SearchIcon from '@mui/icons-material/Search';
import { useState } from "react";

const ListasClientes = () => {
    const [filtro, setFiltro] = useState(null);
    const getFiltro = (event) => {
        setFiltro(event.target.value);
        // console.log(event.target.value);
    }

    const filtroListaClientes = (lista) => {
        const getNombre = (element) => {
            const primerNombre = element.cliente.datos.primerNombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
            const segundoNombre = (element.cliente.datos.segundoNombre ? element.cliente.datos.segundoNombre : '').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
            const primerApellido = element.cliente.datos.primerApellido.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
            const segundoApellido = element.cliente.datos.segundoApellido.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
            //console.log(`${primerNombre} ${segundoNombre} ${primerApellido} ${segundoApellido}`);
            return `${primerNombre} ${segundoNombre} ${primerApellido} ${segundoApellido}`
        }
        
        const getLote = (element) => {
            const sku = element.lote.sku.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
            //console.log(`${primerNombre} ${segundoNombre} ${primerApellido} ${segundoApellido}`);
            return `${sku}`
        }

        const primerFiltro = lista.filter((element) => !filtro ? element : getNombre(element).includes(filtro.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()) )
        const segundoFiltro = lista.filter((element) => !filtro ? element : getLote(element).includes(filtro.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()) )
        const preResult = [...primerFiltro, ...segundoFiltro];

        let hash = {};
        const result = preResult.filter(o => hash[o.id] ? false : hash[o.id] = true);        

        return result;
        // console.log(result);
    }
    return(
        <Box
            sx={{
                // backgroundColor:'greenyellow',
                minHeight:800,
                mt:12,
            }}
        >
            <Container>
                <Box
                    sx={{
                        // backgroundColor:'pink',
                        width:'100%',
                        minHeight:{ xs:80, sm:150, md:150 },
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center',
                        justifyContent:'center',
                    }}
                >
                    <Paper
                        sx={{ 
                            p: '2px 4px', 
                            display: 'flex', 
                            alignItems: 'center', 
                            width: { xs:'100%', sm:500, md:500 } 
                        }}
                    >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Buscar por Cliente, Lote, Manzana ó Fraccionamiento..."
                            inputProps={{ 'aria-label': 'search google maps' }}
                            onChange={getFiltro}
                        />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </Box>
                <Box>
                    <ListaSeparaciones handleFilter={filtroListaClientes}/>
                    <ListaVendidos handleFilter={filtroListaClientes}/>
                    <ListaCancelados handleFilter={filtroListaClientes}/>
                </Box>
            </Container>
        </Box>
    );
}

export { ListasClientes }