import { Outlet } from "react-router-dom";

const CargasModelos = () => {
    return(
        <div>            
            <Outlet/>
        </div>
    );
}

export { CargasModelos }