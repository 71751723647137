import { Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";

// import ubicaciondentrodelfraccionamiento from '../../assets/ubicaciondentroprueba.png'

const UbicacionDentroDelFraccionamiento = ({ lote }) => {
    return(
        <Container>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={12} sm={12} md={12} sx={{ textAlign:'left', color:'#707070', }}>
                    <Typography variant="h5" gutterBottom component="div" sx={{ color:'#F5323F', }}>
                        <b>UBICACIÓN</b> DENTRO DEL FRACCIONAMIENTO
                    </Typography>
                    <Typography variant="body2" gutterBottom component="div">
                        La distribución del fraccionamiento está diseñada para tu total comodidad. Elige la mejor ubicación para ti.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Box
                        sx={{
                            // backgroundColor:'red',
                            width: { xs:'100%', sm:'100%', md:'100%' },
                            display:'flex',

                        }}
                    >
                        <Box
                            component='img'
                            src={lote.archivoUbicacionDentroDelFraccionamiento}
                            sx={{
                                borderRadius:'15px',
                                maxWidth: { xs:'100%', sm:'95%', md:'95%' },
                                m:'0 auto',
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}

export { UbicacionDentroDelFraccionamiento }