import { Alert, Autocomplete, Button, Card, CardContent, CardMedia, CircularProgress, Collapse, Divider, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Skeleton, Stack, TextField, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';

import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import { ResetTvRounded } from "@mui/icons-material";

const VerificacionDeDatos = () => {
    
    let location = useLocation();
    let navigate = useNavigate();

    const [ciudades, setCiudades] = useState([]);
    const [selectedCiudad, setSelectedCiudad] = useState(null);

    const [lote, setLote] = useState(null);
    const [cliente, setCliente] = useState(null);
    const [idLote, setIdLote] = useState(location.state.idLote);

    const [loteDisponible, setLoteDisponible] = useState(true);

    const [tipoCredito, setTipoCredito] = useState(null);
    
    const getTipoCredito = (event) => {
        setTipoCredito(event.target.value);
    }

    console.log(location);

    const getFormatoMoneda = (cantidad) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD', //MXN
            minimumFractionDigits: 0
          })
      
          var value = 10000
      
          return formatter.format(cantidad)
    }

    const Check = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');

        // console.log(data.get('tipoCompra')); return;
        const newDatosCliente = {};
        
        if(!cliente.datos || cliente.datos.primerNombre !== data.get('primerNombre')){
            newDatosCliente.primerNombre = data.get('primerNombre');
        }
        if(!cliente.datos || cliente.datos.segundoNombre !== data.get('segundoNombre')){
            newDatosCliente.segundoNombre = data.get('segundoNombre');
        }
        if(!cliente.datos || cliente.datos.primerApellido !== data.get('primerApellido')){
            newDatosCliente.primerApellido = data.get('primerApellido');
        }
        if(!cliente.datos || cliente.datos.segundoApellido !== data.get('segundoApellido')){
            newDatosCliente.segundoApellido = data.get('segundoApellido');
        }
        if(cliente.autenticacion.email !== data.get('email')){
            newDatosCliente.email = data.get('email');
        }
        if(cliente.autenticacion.telefono !== data.get('telefono')){
            newDatosCliente.telefono = data.get('telefono');
        }
        // console.log({ idCliente: cliente.id, idLote: lote.id, ...newDatosCliente}); return;
        
        axios.post(`${process.env.REACT_APP_API_URL}/api/validarDatosCliente`, 
            {
                idCliente: cliente.id,
                idLote: lote.id,

                ...newDatosCliente
                // primerNombre: data.get('primerNombre'),
                // segundoNombre: data.get('segundoNombre'),
                // primerApellido: data.get('primerApellido'),
                // segundoApellido: data.get('segundoApellido'),
                // email: data.get('email'),
                // telefono: data.get('telefono'),

                //tipoCredito: data.get('tipoCompra') === 1 ? 'CREDITO' : 'CONTADO',
                // curp: data.get('curp'),
                // salarioMensual: data.get('salarioMensual'),
                // calle: data.get('calle'),
                // noExterior: data.get('noExterior'),
                // noInterior: data.get('noInterior'),
                // codigoPostal: data.get('codigoPostal'),
                // colonia: data.get('colonia'),
                // ciudad: data.get('ciudad'),
                // idMpio: selectedCiudad.id,
            },
            // data,
            {
              headers: { 
                Authorization: `Bearer ${token}`,
              }              
        })
        .then((response) => {
            // handle success            
            console.log(response);
            console.log('success axio');
            if(response.data.result === true){
                console.log('autorizado');
                navigate('/pago', {state:{ idCliente: cliente.id, idLote: lote.id, numeroLote: lote.numeroDeLote }});
            }
            else {
                console.log('No autorizado');
            }
            // navigate('', {state:{ idCliente: cliente.id, idLote: lote.id, }});
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sabe');
        });
    }

    const reedirigirTienda = () =>{
        // window.open('http://localhost:3000/tienda')
        window.location.href = `${process.env.REACT_APP_URL}/tienda`;
    }

    const reedirigirMoffin = () =>{
        window.open('https://pecsa.moffin.co/solicitud/hipotecario/comenzar')
    }

    const [promocion, setPromocion] = useState(null);
    const [precioFinal, setPrecioFinal] = useState(null)

    const findPromocion = (lote) => {
        
        if(lote){    
            if(lote.promocion){
                setPromocion(lote.promocion);
            }else{
                if(lote.manzana.promocion){
                    setPromocion(lote.manzana.promocion);
                }else{
                    if(lote.manzana.etapa.promocion){
                        setPromocion(lote.manzana.etapa.promocion);
                    }else{
                        if(lote.manzana.etapa.fraccionamiento.promocion){
                            setPromocion(lote.manzana.etapa.fraccionamiento.promocion);
                        }else{
                            if(lote.fachada.modelo.promocion){
                                setPromocion(lote.fachada.modelo.promocion);
                            }
                        }
                    }
                }
            }
        }
    }
    const aplicaPromocion = (lote, promocion) => {
        if(promocion.cantidadADescontar){
            setPrecioFinal(lote.precio - promocion.cantidadADescontar);
        }
        if(promocion.porcentajeADescontar){
            setPrecioFinal(lote.precio - (lote.precio * (promocion.porcentajeADescontar/100)));
        }

    }

    useEffect(()=>{
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');
    
        axios.post(`${process.env.REACT_APP_API_URL}/api/venta/checkData/`,{
            idLote: idLote,
            folio: folio
        },{
            headers: { 
              Authorization: `Bearer ${token}`,
            }              
        })
        .then((response) => {
            // handle success
            console.log(response.data);
            if(!response.data.lote){
                setLoteDisponible(false);
                return;
            }
            setLote(response.data.lote);
            findPromocion(response.data.lote);
            setCliente(response.data.cliente);
            if(response.data.cliente.datos){   
                if(response.data.cliente.datos.idMunicipio){
                    setSelectedCiudad(response.data.ciudades.filter((ciudad) => { return ciudad.id === response.data.cliente.datos.idMunicipio })[0] || null);
                }
            }
            setCiudades(response.data.ciudades);
        })
        .catch((error) => {
            // handle success
            console.log(error);
            if(error.response.status === 401 || error.response.statusText === 'Unauthorized'){
                if(location.state){
                    if(location.state.origen){
                        navigate('/login', {state:{origen:location.state.origen}});
                    }else{
                        navigate('/login');
                    }
                }
            }else{
                setLoteDisponible(false);
            }
                        
                      
        })
        .then(() => {
          // handle success
          console.log('sabe');
        });
    }, [setLote, setCliente]);

    useEffect(()=>{
        if(promocion){
            aplicaPromocion(lote, promocion)
        }
    }, [promocion]);
    return(
        <Container sx={{ mt:{ xs:9, sm:12, md:12 }, minHeight:{ xs:850, sm:1040, md:850 } }}>
            {
                loteDisponible ? (
                    <Box component="form" onSubmit={Check}>
                        <Typography variant="h5" gutterBottom component="div" sx={{ color:'#F5323F' }}>
                            <b>Confirmar Datos de Compra</b>
                        </Typography>
                        <Grid 
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ mb:2 }}
                        >
                            <Grid item xs={12} sm={10} md={10}>
                                <Card sx={{ display: { xs:'block', sm:'flex', md:'flex' } }}>
                                    {
                                        lote ? (
                                            <React.Fragment>
                                                <CardMedia
                                                    component="img"
                                                    sx={{ width: { xs:'100%', sm:200, md:200 } }}
                                                    image={lote.fachada.fotos[0].url}
                                                    alt="Live from space album cover"
                                                />
                                                <Box sx={{ display: 'flex', flexDirection: 'column', color:'#707070' }}>
                                                    <CardContent sx={{ flex: '1 0 auto', }}>
                                                        <Typography component="div" variant="h5" sx={{ color:'#F5323F' }}>
                                                            Modelo <b>{ lote.fachada.modelo.nombre }</b>
                                                        </Typography>
                                                        {
                                                            promocion && (
                                                                <Typography variant="subtitle2" component="div">
                                                                    <del>{`${getFormatoMoneda(lote.precio)}`}</del>
                                                                    {
                                                                        promocion.cantidadADescontar ? ` -${getFormatoMoneda(promocion.cantidadADescontar)}`
                                                                        : ` -${promocion.porcentajeADescontar}%`
                                                                    }
                                                                    <Box
                                                                        component='img'
                                                                        src={promocion.logo}
                                                                        sx={{ width:20 }}
                                                                    />
                                                                </Typography>
                                                            )
                                                        }
                                                        {/* <Typography variant="subtitle1" component="div">
                                                            {getFormatoMoneda(lote.precio)}
                                                        </Typography> */}
                                                        <Typography variant="subtitle1" component="div">
                                                            {
                                                                !promocion ? 
                                                                (
                                                                    getFormatoMoneda(lote.precio)
                                                                ) : (
                                                                    `${getFormatoMoneda(precioFinal)} `
                                                                ) 
                                                            }
                                                        </Typography>
                                                        <Typography variant="subtitle2" component="div">
                                                            Fraccionamiento: <b>{ lote.manzana.etapa.fraccionamiento.nombre }</b>
                                                        </Typography>
                                                        <Typography variant="subtitle2" component="div">
                                                            Manzana: <b>{ lote.manzana.nombre }</b>
                                                        </Typography>
                                                        <Typography variant="subtitle2" component="div">
                                                            Lote: <b>{ lote.numeroDeLote }</b>
                                                        </Typography>
                                                        <Typography variant="subtitle2" component="div">
                                                            Fachada: <b>{ lote.fachada.nombre }</b>
                                                        </Typography>
                                                    </CardContent>
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                                        <Typography component="div" variant="body1" sx={{ color:'#707070' }}>
                                                            A PAGAR:
                                                        </Typography>
                                                        <Typography component="div" variant="h5" sx={{ color:'#F5323F' }}>
                                                            $5,000.00
                                                        </Typography>
                                                    </CardContent>                                                                   
                                                </Box>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <Skeleton variant="rectangular" width={ 200 } height={200} />
                                                {/* <Skeleton variant="text" /> */}
                                                <Box sx={{ display: 'flex', flexDirection: 'column', color:'#707070' }}>
                                                    <Skeleton variant="rectangular" width={ 250 } height={30} sx={{ m:1 }}/>
                                                    <Skeleton variant="rectangular" width={ 250 } height={15} sx={{ m:1 }}/>
                                                    <Skeleton variant="rectangular" width={ 250 } height={10} sx={{ m:1 }}/>
                                                    <Skeleton variant="rectangular" width={ 250 } height={10} sx={{ m:1 }}/>
                                                    <Skeleton variant="rectangular" width={ 250 } height={10} sx={{ m:1 }}/>
                                                    <Skeleton variant="rectangular" width={ 250 } height={10} sx={{ m:1 }}/>
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', color:'#707070' }}>
                                                    <Skeleton variant="rectangular" width={ 250 } height={20} sx={{ m:1 }}/>
                                                    <Skeleton variant="rectangular" width={ 250 } height={30} sx={{ m:1 }}/>
                                                </Box>
                                            </React.Fragment>
                                        )
                                    }
                                    {/* {
                                        lote && (
                                            <CardMedia
                                                component="img"
                                                sx={{ width: { xs:'100%', sm:200, md:200 } }}
                                                image={lote.fachada.fotos[0].url}
                                                alt="Live from space album cover"
                                            />        
                                        )
                                    } */}
                                    
                                    {/* <Box sx={{ display: 'flex', flexDirection: 'column', color:'#707070' }}>
                                        {
                                            lote && (
                                                <CardContent sx={{ flex: '1 0 auto', }}>
                                                    <Typography component="div" variant="h5" sx={{ color:'#F5323F' }}>
                                                        Modelo <b>{ lote.fachada.modelo.nombre }</b>
                                                    </Typography>
                                                    {
                                                        promocion && (
                                                            <Typography variant="subtitle2" component="div">
                                                                <del>{`${getFormatoMoneda(lote.precio)}`}</del>
                                                                {
                                                                    promocion.cantidadADescontar ? ` -${getFormatoMoneda(promocion.cantidadADescontar)}`
                                                                    : ` -${promocion.porcentajeADescontar}%`
                                                                }
                                                                <Box
                                                                    component='img'
                                                                    src={promocion.logo}
                                                                    sx={{ width:20 }}
                                                                />
                                                            </Typography>
                                                        )
                                                    }
                                                    <Typography variant="subtitle1" component="div">
                                                        {
                                                            !promocion ? 
                                                            (
                                                                getFormatoMoneda(lote.precio)
                                                            ) : (
                                                                `${getFormatoMoneda(precioFinal)} `
                                                            ) 
                                                        }
                                                    </Typography>
                                                    <Typography variant="subtitle2" component="div">
                                                        Fraccionamiento: <b>{ lote.manzana.etapa.fraccionamiento.nombre }</b>
                                                    </Typography>
                                                    <Typography variant="subtitle2" component="div">
                                                        Manzana: <b>{ lote.manzana.nombre }</b>
                                                    </Typography>
                                                    <Typography variant="subtitle2" component="div">
                                                        Lote: <b>{ lote.numeroDeLote }</b>
                                                    </Typography>
                                                    <Typography variant="subtitle2" component="div">
                                                        Fachada: <b>{ lote.fachada.nombre }</b>
                                                    </Typography>
                                                </CardContent>
                                            )

                                        }                                        
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <CardContent sx={{ flex: '1 0 auto' }}>
                                            <Typography component="div" variant="body1" sx={{ color:'#707070' }}>
                                                A PAGAR:
                                            </Typography>
                                            <Typography component="div" variant="h5" sx={{ color:'#F5323F' }}>
                                                $5,000.00
                                            </Typography>
                                        </CardContent>                                                                   
                                    </Box> */}
                                </Card>
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid 
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={{ xs:2, sm: 1, md:2 }}
                            sx={{ m:1, color:'#707070' }}
                        >
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography variant="h5" gutterBottom component="div" sx={{ color:'#F5323F' }}>
                                    <b>Datos de Usuario</b>
                                </Typography>
                            </Grid>
                            {
                                cliente ? (
                                    <React.Fragment>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <TextField id="outlined-basic" label="Primer Nombre" defaultValue={cliente.datos !== null ? (cliente.datos.primerNombre) : '' } variant="outlined" name="primerNombre" sx={{ width:'100%' }} required/>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <TextField id="outlined-basic" label="Segundo Nombre" defaultValue={cliente.datos !== null ? (cliente.datos.segundoNombre) : null } variant="outlined" name="segundoNombre" sx={{ width:'100%' }}/>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <TextField id="outlined-basic" label="Primer Apellido" defaultValue={cliente.datos !== null ? (cliente.datos.primerApellido) : '' } variant="outlined" name="primerApellido" sx={{ width:'100%' }} required/>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <TextField id="outlined-basic" label="Segundo Apellido" defaultValue={cliente.datos !== null ? (cliente.datos.segundoApellido) : '' } variant="outlined" name="segundoApellido" sx={{ width:'100%' }} required/>
                                        </Grid>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>                                        
                                        <Grid item xs={12} sm={6} md={3}>
                                            <Skeleton variant="rectangular" width={ '100%' } height={60} sx={{ m:1 }}/>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <Skeleton variant="rectangular" width={ '100%' } height={60} sx={{ m:1 }}/>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <Skeleton variant="rectangular" width={ '100%' } height={60} sx={{ m:1 }}/>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <Skeleton variant="rectangular" width={ '100%' } height={60} sx={{ m:1 }}/>
                                        </Grid>
                                    </React.Fragment>
                                )
                            }
                            {
                                cliente ? (
                                    <React.Fragment>                                
                                        <Grid item xs={12} sm={6} md={6}>
                                            <TextField id="outlined-basic" label="Correo Electrónico" defaultValue={cliente.autenticacion !== null && cliente.autenticacion.email} variant="outlined" name="email" sx={{ width:'100%' }} required/>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <TextField id="outlined-basic" label="Número Celular" defaultValue={cliente.autenticacion !== null && cliente.autenticacion.telefono} variant="outlined" name="telefono" sx={{ width:'100%' }} required/>
                                        </Grid>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Skeleton variant="rectangular" width={ '100%' } height={60} sx={{ m:1 }}/>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Skeleton variant="rectangular" width={ '100%' } height={60} sx={{ m:1 }}/>
                                        </Grid>
                                    </React.Fragment>
                                )
                            }
                            

                            {/* <Grid item xs={12} sm={12} md={12}>
                                <Typography variant="h5" gutterBottom component="div" sx={{ color:'#F5323F' }}>
                                    <b>Datos Generales</b>
                                </Typography>
                            </Grid>
                            {
                                cliente && (
                                    <React.Fragment>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <TextField id="outlined-basic" label="CURP" defaultValue={cliente.datos !== null ? cliente.datos.curp : '' } variant="outlined" name="curp" sx={{ width:'100%' }} required/>
                                        </Grid>
                                        <Grid item xs={12} sm={1} md={3}>
                                            CURP    
                                        </Grid>
                                        <Grid item xs={12} sm={5} md={3}>
                                            <TextField id="outlined-basic" label="Salario Mensual" defaultValue={cliente.datos !== null ? cliente.datos.salarioMensual : '' } variant="outlined" name="salarioMensual" sx={{ width:'100%' }} required/>
                                        </Grid>
                                        <Grid item xs={12} sm={7} md={3}>
                                            
                                        </Grid>
                                    </React.Fragment>
                                )
                            }
                            

                            <Grid item xs={12} sm={12} md={12}>
                                <Typography variant="h5" gutterBottom component="div" sx={{ color:'#F5323F' }}>
                                    <b>Datos de Domicilio</b>
                                </Typography>
                            </Grid>
                            {
                                cliente && (
                                    <React.Fragment>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <TextField id="outlined-basic" label="Calle" defaultValue={cliente.datos !== null ? cliente.datos.calle : '' } variant="outlined" name="calle" sx={{ width:'100%' }} required/>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <TextField id="outlined-basic" label="Número Exterior" defaultValue={cliente.datos !== null ? cliente.datos.noExterior : '' } variant="outlined" name="noExterior" sx={{ width:'100%' }} required/>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <TextField id="outlined-basic" label="Número Interior" defaultValue={cliente.datos !== null ? cliente.datos.noInterior : '' } variant="outlined" name="noInterior" sx={{ width:'100%' }}/>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <TextField id="outlined-basic" label="Código Postal" defaultValue={cliente.datos !== null ? cliente.datos.codigoPostal : '' } variant="outlined" name="codigoPostal" sx={{ width:'100%' }} required/>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={5}>
                                            <TextField id="outlined-basic" label="Colonia" defaultValue={cliente.datos !== null ? cliente.datos.colonia : '' } variant="outlined" name="colonia" sx={{ width:'100%' }} required/>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Autocomplete
                                                id="grouped-demo"
                                                options={ciudades}
                                                groupBy={(ciudad) => ciudad.estado.nombre}
                                                getOptionLabel={(ciudad) => ciudad.nombre}
                                                value={selectedCiudad}
                                                onChange={(event, newCiudad)=>{setSelectedCiudad(newCiudad);}}
                                                sx={{ width:'100%' }}
                                                renderInput={(params) => <TextField {...params} label="Ciudad" name="ciudad" required/>}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                )
                            }
                            */}
                            
                            <Grid item xs={12} sm={7} md={12}>
                                
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid 
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={{ xs:2, sm: 1, md:2 }}
                            sx={{ m:1, color:'#707070' }}
                        >
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography variant="h5" gutterBottom component="div" sx={{ color:'#F5323F' }}>
                                    <b>Datos de credito</b>
                                </Typography>
                            </Grid>
                            {
                                cliente && lote ? (
                                    <React.Fragment>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Typography variant="body1" gutterBottom component="div" sx={{ color:'#707070' }}>
                                                Por que medio deseas pagar el monto total de tu vivienda:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={7} md={5}>
                                            <Box sx={{ minWidth: 120 }}>
                                                <FormControl required fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Tipo de Compra</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={tipoCredito}
                                                        label="Tipo de Compra"
                                                        onChange={getTipoCredito}
                                                        name='tipoCompra'
                                                        
                                                    >
                                                        <MenuItem value={1}>Contado</MenuItem>
                                                        <MenuItem value={2}>Credito</MenuItem>
                                                        
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Skeleton variant="rectangular" width={ 415 } height={20} sx={{ m:1 }}/>
                                        </Grid>
                                        <Grid item xs={12} sm={7} md={5}>
                                            <Skeleton variant="rectangular" width={ '100%' } height={60} sx={{ m:1 }}/>
                                        </Grid>
                                    </React.Fragment>
                                )
                            }
                            
                            {/* <Grid item xs={12} sm={12} md={12}>
                                <Typography variant="body1" gutterBottom component="div" sx={{ color:'#707070' }}>
                                    Por que medio deseas pagar el monto total de tu vivienda:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={7} md={5}>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl required fullWidth>
                                        <InputLabel id="demo-simple-select-label">Tipo de Compra</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={tipoCredito}
                                            label="Tipo de Compra"
                                            onChange={getTipoCredito}
                                            name='tipoCompra'
                                            
                                        >
                                            <MenuItem value={1}>Contado</MenuItem>
                                            <MenuItem value={2}>Credito</MenuItem>
                                            
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid> */}
                            <Grid item xs={12} sm={12} md={12}>
                                <Collapse in={tipoCredito === 2 ? true : false}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Alert severity="info">
                                            <Typography variant="body1" gutterBottom component="div" sx={{ color:'#707070' }}>
                                                Si la compra de tu casa sera a credito te recomendamos hacer una pre autorizacion antes de separar, para poder apoyarte mejor en tu credito.
                                            </Typography>
                                            {/* Si la compra de tu casa sera a credito te recomendamos hacer una preautorizacion antes de separar.<br/> */}
                                            <Button 
                                                variant="outlined"
                                                onClick={reedirigirMoffin}
                                            >
                                                Pre Autorizar
                                            </Button>
                                        </Alert>
                                    </Grid>
                                </Collapse>
                            </Grid>                                
                            {/* {
                                tipoCredito === 2 && (
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Alert severity="info">
                                            <Typography variant="body1" gutterBottom component="div" sx={{ color:'#707070' }}>
                                                Si la compra de tu casa sera a credito te recomendamos hacer una pre autorizacion antes de separar, para poder apoyarte mejor en tu credito.
                                            </Typography>
                                            <Button 
                                                variant="outlined"
                                                onClick={reedirigirMoffin}
                                            >
                                                Pre Autorizar
                                            </Button>
                                        </Alert>
                                    </Grid>
                                )
                            } */}
                        </Grid>
                        <Divider/>
                        {/* <Grid 
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ mt:1, mb:4 }}
                            spacing={2}
                        >
                            <Grid item xs={12} sm={7} md={12}>
                                <Typography>
                                    Forma de pago de la casa
                                </Typography>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="1" control={<Radio />} label="Credito" />
                                        <FormControlLabel value="0" control={<Radio />} label="Contado o otro" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={7} md={12}>
                                <Typography>
                                    {`¿Cuentas con algun crédito?(ejemplos...)`}
                                </Typography>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="1" control={<Radio />} label="Si" />
                                        <FormControlLabel value="0" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={7} md={12}>
                                <Typography>
                                    En PECSA te ofrecemos distintas opciones de crédito de manera que puedas elegir cual se adapta 
                                    más a tus necesidades y si necesitas ayuda ¡Nosotros tramitamos tu crédito!

                                    ¿Deseas precalificarte y poder tramitar cualquier tipo de crédito para adquirir una vivienda en alguno de nuestros fraccionamientos?:
                                </Typography>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="1" control={<Radio />} label="Si" />
                                        <FormControlLabel value="0" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid> */}
                        <Grid 
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ mt:1, mb:4 }}
                            spacing={2}
                        >
                            {
                                cliente && lote ? (
                                    <Grid item xs={12} sm={7} md={3}>
                                        <Button variant="outlined" type="submit" color="error" size="large" 
                                            sx={{ 
                                                width:'100%',
                                                p:{ xs:'none', sm:'none', md:1 },
                                                borderColor:'#F5323F',
                                                color:'#F5323F',
                                            }}
                                            disabled={cliente && lote ? false : true}
                                        >
                                            Confirmar
                                        </Button>
                                    </Grid>
                                ) : (
                                    <Grid item xs={12} sm={7} md={3}>
                                        <Skeleton variant="rectangular" width={ '100%' } height={50} sx={{ m:1 }}/>
                                    </Grid>
                                )
                            }                            
                        </Grid>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            textAlign:'center',
                        }}
                    >
                        <Box
                            sx={{
                                mt:12,
                                textAlign:'center',
                                color: '#f5323f'
                            }}
                        >
                            <ProductionQuantityLimitsIcon sx={{ fontSize:400 }}/>
                        </Box>
                        <Typography variant="h4" gutterBottom sx={{ color:'#707070' }}>
                            Este Lote ya no esta disponible.
                        </Typography>
                        <Grid 
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ mt:1, mb:4 }}
                            spacing={2}
                        >
                            <Grid item xs={12} sm={7} md={3}>
                                <Button variant="outlined" color="error" size="large" 
                                    sx={{ 
                                        width:'100%',
                                        p:{ xs:'none', sm:'none', md:1 },
                                        borderColor:'#F5323F',
                                        color:'#F5323F',
                                    }}
                                    onClick={reedirigirTienda}
                                >
                                    Volver a la tienda.
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                )
            }
                    
        </Container>
    );
}

export { VerificacionDeDatos }