import React from "react";
import { useRef, useState } from "react";
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import anuncio from '../../assets/slideanuncios/anuncio.png';
import anuncio2 from '../../assets/slideanuncios/anuncio2.png';
import anuncio3 from '../../assets/slideanuncios/anuncio3.png';
import anuncioEstrenaton from '../../assets/slideanuncios/estrenaton2022.png';
import anuncioEstrenatonMovil from '../../assets/slideanuncios/estrenaton2022Movil.jpg';

import casaCompracasa from '../../assets/casa_compracasa.svg';
import ubicacionCompracasa from '../../assets/foto2.svg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { Grid } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { useNavigate } from "react-router-dom";

const SlideCompraEnUnSoloDiaItem = ({ datos }) => {
  let navigate = useNavigate();

    return (
      <Box
        sx={{
          //backgroundColor: 'yellow',
          borderRadius: { xs:'10px', sm:'10px', md:'0px' },
          boxShadow: { xs:2, sm:3, md:0 },
          mt:2,
          p:2,
          ml:0.2,
          mr:0.2,
          height:{ xs:370, sm:460, md:500},
        }}
      >
        <Grid 
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm={12} md={12}>
            <Box sx={{ color:'#f5323f', }}>
              <h1><b>Compra una casa Pecsa</b> en un solo día</h1>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box sx={{ display:{ xs:'none', sm:'block' } }}>
              { datos.textoMD }
              <Button variant="text" color="inherit" endIcon={<ArrowForwardIcon />} sx={{ color:'#f5323f' }} onClick={()=>{ navigate('/tienda') }}>
                <b>VER MÁS</b>
              </Button>
            </Box>
            <Box sx={{ display:{ xs:'flex', sm:'none', md:'none' }, color:'#707070' }}>
              { datos.textoXS }                 
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}
            sx={{ 
              pl:{xs: 15, sm:5, md:5},
            }}
          >
            <Box
                component="img"
                sx={{
                  width: { xs:200, sm:350, md:450 }
                    // display: 'block',
                    // minHeight: '150px',
                    // overflow: 'hidden',
                    // marginTop: 2,
                    // borderRadius: '6px'
                }}
                // src={anuncios[0].imagen}
                // alt={anuncios[0].titulo}
                src={datos.imagen}
                alt="Estrenaton 2022"
            />
          </Grid>
        </Grid>
      </Box>
    );
}

export { SlideCompraEnUnSoloDiaItem };