import { Box, Container } from "@mui/system";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Alert, Button, Chip, CircularProgress, Link, Snackbar, TextField, Typography } from "@mui/material";
import { CustomInputPassword } from "../../components/CustomInputPassword";
import axios from "axios";
import { MuiOtpInput } from "mui-one-time-password-input";
import CheckIcon from '@mui/icons-material/Check';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const stylePecsaInputs = {
    "& label.Mui-focused": {
        color: "#f5323f"
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#d9d9d9"
    },
    "& .MuiOutlinedInput-root": {
        // '& fieldset': {
        //   borderColor: 'black',
        // },
        "&:hover fieldset": {
            borderColor: "#d9d9d9"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#d9d9d9"
        }
    }
}

const EmailInput = ({ handleChange, handleClick }) => {
    const [email, setEmail] = useState(null);
    const getEmail = (newEmail) => {
        setEmail(newEmail)
    }

    return(
        <Box
            sx={{
                // backgroundColor:'pink',
                width:'100%',
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
                justifyContent:'center',
            }}
        >
            <Box
                sx={{
                    // backgroundColor:'yellowgreen',
                    width:'90%',
                }}
            >                
                {/* <Typography sx={{ color: '#707070' }}>
                    Ingresa tu correo electrónico con el que te registraste o con el que realizaste tu separación para validar tu información.
                </Typography> */}
                <Typography sx={{ color: '#707070' }}>
                    Ingresa tu correo electrónico con el que realizaste tu separación para validar tu información.
                </Typography>
            </Box>
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Correo Electronico"
                name="email"
                autoComplete="email"
                autoFocus
                sx={{...stylePecsaInputs}}
                onChange={(e)=>getEmail(e.target.value)}
            />
            <Button
                type="submit"
                fullWidth
                variant="outlined" 
                color="inherit" 
                endIcon={<ArrowForwardIcon/>}
                sx={{ /*fontSize:'11px',*/ 
                    mt: 3,
                    mb: 2,
                    //width: { xs:'95%', sm:'90%', md:'100%' }, 
                    p:{ xs:1, sm:1, md:1 },
                    borderColor:'#F5323F',
                    color:'#F5323F',
                    borderWidth:3,
                }}
                disabled={email ? email.length <= 0 ? true : false : true}
                onClick={()=>handleClick(email)}
            >
                <b>Siguiente</b>
            </Button>
        </Box>
    );
}
const PasswordInput = ({ cliente, password, handleEmailChange, handleChange, handleClick }) => {
    // const [password, setPassword] = useState(null);
    // const getPassword = (newPassword) => {
    //     setPassword(newPassword)
    // }
    return(
        <Box
            sx={{
                // backgroundColor:'pink',
                width:'100%',
                textAlign:'center',
            }}
        >
            {/* <Chip
                label={email}
                sx={{
                    fontSize:21,
                }}
                onClick={()=>handleEmailChange(null)}
                onDelete={()=>handleEmailChange(null)}
            /> */}
            {/* <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
                sx={{...stylePecsaInputs}}
                onChange={(e)=>handleChange(e.target.value)}
            /> */}
            <Typography sx={{ color: '#707070' }}>
                Hola, <b>{ `${cliente.datos.primerNombre} ${cliente.datos.primerApellido}` }</b>,
            </Typography>
            <Typography sx={{ color: '#707070' }}>
                A continuacion ingresa tu contraseña para acceder a tu perfil
            </Typography>
            <CustomInputPassword required handleChange={handleChange} autoFocus/>
            <Box
                sx={{
                    // backgroundColor:'pink',
                    textAlign:'left',
                    
                }}
            >
                <Typography sx={{ color: '#707070', }}>
                    Si olvidaste su contraseña has clic <Link href="/olvide-password">aquí</Link>
                </Typography>
            </Box>
            <Button
                type="submit"
                fullWidth
                variant="outlined" 
                color="inherit" 
                sx={{ /*fontSize:'11px',*/ 
                    mt: 3,
                    mb: 2,
                    //width: { xs:'95%', sm:'90%', md:'100%' }, 
                    p:{ xs:1, sm:1, md:1 },
                    borderColor:'#F5323F',
                    color:'#F5323F',
                    borderWidth:3,
                }}
                onClick={handleClick}
            >
                <b>Ingresar</b>
            </Button>
        </Box>
    );
}
const CreatePasswordInput = ({ cliente, handleEmailChange, handleChange, handleClick }) => {
    const [password, setPassword] = useState(null);
    const getPassword = (newPassword) => {
        setPassword(newPassword)
    }
    const [confirmPassword, setConfirmPassword] = useState(null);
    const getConfirmPassword = (newConfirmPassword) => {
        setConfirmPassword(newConfirmPassword)
    }

    const [passwordCorrect, setPasswordCorrect] = useState(null);
    const getPasswordCorrect = (newPasswordCorrect) => {
        setPasswordCorrect(newPasswordCorrect)
    }

    const checkPasswords = () => {
        if(password === null || confirmPassword === null){
            return false;
        }
        if(password === '' || confirmPassword === ''){
            return false;
        }
        if(password.length < 8 || confirmPassword.length < 8){
            return false;
        }

        return password === confirmPassword ? true : false;
    }
    useEffect(()=>{
        if(checkPasswords){
            handleChange(password);
        }
    },[password, confirmPassword]);
    return(
        <Box
            sx={{
                // backgroundColor:'pink',
                width:'100%',
                textAlign:'center',
            }}
        >
            {/* <Chip
                label={email}
                sx={{
                    fontSize:21,
                }}
                onClick={()=>handleEmailChange(null)}
                onDelete={()=>handleEmailChange(null)}
            /> */}
            {/* <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
                sx={{...stylePecsaInputs}}
                onChange={(e)=>handleChange(e.target.value)}
            /> */}
            <Typography sx={{ color: '#707070' }}>
                Hola, <b>{ `${cliente.datos.primerNombre} ${cliente.datos.primerApellido}` }</b>,
            </Typography>
            <Typography sx={{ color: '#707070' }}>
                A continuación ingresa tu contraseña y confírmala para acceder a tu perfil.
            </Typography>
            <CustomInputPassword required handleChange={getPassword} endAdornment={checkPasswords() === true && <CheckIcon color="success"/> }/>
            <CustomInputPassword required handleChange={getConfirmPassword} label="Confirma Contraseña" endAdornment={checkPasswords() === true && <CheckIcon color="success"/> }/>
            <Box
                sx={{
                    width:'100%',
                    textAlign:'left',
                }}
            >
                <Typography variant="caption" sx={{ color: '#707070' }}>
                    Ingresa una contraseña de al menos 8 caracteres
                </Typography>
            </Box>
            <Button
                type="submit"
                fullWidth
                variant="outlined" 
                color="inherit" 
                sx={{ /*fontSize:'11px',*/ 
                    mt: 3,
                    mb: 2,
                    //width: { xs:'95%', sm:'90%', md:'100%' }, 
                    p:{ xs:1, sm:1, md:1 },
                    borderColor:'#F5323F',
                    color:'#F5323F',
                    borderWidth:3,
                }}
                disabled={!checkPasswords()}
                onClick={handleClick}
            >
                <b>Guardar</b>
            </Button>
        </Box>
    );
}
const CodeInput = ({ cliente, codigo, handleChange, handleClick }) => {
    const [code, setCode] = useState("");
    const getCode = (newCode) => {
        setCode(newCode)
    }
    
    const matchIsNumeric = (text) => {
        const isNumber = typeof text === "number";
        return isNumber || !isNaN(Number(text));
    };
    
    const validateChar = (value, index) => {
        return matchIsNumeric(value);
    };

    const sendNewCode = () => {
        getEnviando(true);
        axios.get(`${process.env.REACT_APP_API_URL}/api/clientes/send-code/${cliente.autenticacion.email}`)
        .then((response)=>{
            console.log(response.data);
            handleAlert('success', 'Codigo enviado con exito!');
            getEnviando(false);
        })
        .catch((error)=>{
            console.log(error);
            handleAlert('error', 'Error al enviar el código');
            getEnviando(false);
        });
    }

    const validarCode = (idCliente, code) => {
        if(code.length === 4 ){
            console.log('enviar validacion');
            getValidando(true);
            // getFocus(false);
            axios.post(`${process.env.REACT_APP_API_URL}/api/clientes/send-code/check`,{
                idCliente:idCliente,
                code:code
            })
            .then((response)=>{
                console.log(response.data);
                getValidando(false);
                getIsCorrect(true);
            })
            .catch((error)=>{
                console.log(error);
                handleAlert('error', (<Box><b>Error</b> Codigo incorrecto.</Box>))
                // getAlert();
                getValidando(false);
                getCode("");
                // getFocus(true);
            });
        }
    }

    const [enviando, setEnviando] = useState(true);
    const getEnviando = (value) => {
        setEnviando(value);
    }

    const [validando, setValidando] = useState(false);
    const getValidando = (value) => {
        setValidando(value);
    }

    const [isCorrect, setIsCorrect] = useState(false);
    const getIsCorrect = (value) => {
        setIsCorrect(value);
    }

    const [alertSeverity, setAlertSeverity] = useState('error');
    const [alertMessage, setAlertMessage] = useState('');
    const [alert, setAlert] = useState(false);
    const getAlert = () => {
        setAlert(!alert);
    }

    const handleAlert = (severity = 'error', message = '') => {
        setAlertSeverity(severity);
        setAlertMessage(message);
        setAlert(!alert);
    }

    const MyAlert = ({ open = false, handleClose, severity = 'error', message = ''}) => {
        return(
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                // key={vertical + horizontal}
                sx={{ 
                    width: '100%',
                    mb:{ xs:10, sm:5, md:20 }
                }}
            >
                 <Alert severity={severity}>{message}</Alert>
            </Snackbar>
        )
    }

    useEffect(()=>{
        console.log('intento de validacion');
        validarCode(cliente.id, code)
    },[code])

    useEffect(()=>{
        sendNewCode();
    },[])
    return(
        <Box
            sx={{
                // backgroundColor:'pink',
                width:'100%',
                display:'flex',
                flexDirection:'column',
                alignItems:'center',

            }}
        >            
            <Typography sx={{ color: '#707070' }}>
                Antes de ingresar, por seguridad te enviaremos un código por email, por favor revisa en tu email e ingresa el código.
            </Typography>
            <Box
                sx={{
                    // backgroundColor:'greenyellow',
                    mt:{ xs:2, sm:2, md:1 },
                    mb:{ xs:1, sm:2, md:1 },
                    width:{ xs:'100%', sm:'100%', md:'70%' },
                }}
            >
                <MuiOtpInput
                    value={code}
                    autoFocus={true}
                    length={4}
                    onChange={getCode}
                    validateChar={validateChar}
                    sx={{ ...stylePecsaInputs }}
                />
            </Box>
            {
                enviando === true ? (
                    <Typography sx={{ color: '#707070', display:'flex', alignItems:'center', }}>
                        Enviando Codigo <CircularProgress color="inherit" size="1.3rem" sx={{ ml:1 }} />
                    </Typography>
                ) : (
                    <Typography sx={{ color: '#707070' }}>
                        Puede tardar unos minutos en recibir el email, si no has recibido nada te reenviaremos otro codigo dando clic <Link sx={{ cursor:'pointer' }} onClick={sendNewCode}>aqui.</Link>
                    </Typography>
                )
            }
            <Button
                type="submit"
                fullWidth
                variant="outlined" 
                color="inherit" 
                endIcon={validando ? <CircularProgress color="inherit" size="1.3rem" /> : isCorrect ? <CheckIcon/> : false}
                sx={{ /*fontSize:'11px',*/ 
                    mt: 3,
                    mb: 2,
                    //width: { xs:'95%', sm:'90%', md:'100%' }, 
                    p:{ xs:1, sm:1, md:1 },
                    borderColor:'#F5323F',
                    color:'#F5323F',
                    borderWidth:3,
                }}
                onClick={()=>handleClick(true)}
                disabled={!isCorrect}
            >
                <b>{validando ? 'Validando' : isCorrect ? 'Verificado' : 'Verificar'}</b>
            </Button>
            <MyAlert open={alert} handleClose={getAlert} severity={alertSeverity} message={alertMessage} /*message={<Box><b>Error</b> Codigo incorrecto.</Box>}*/ />
            {/* <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={alert}
                autoHideDuration={7000}
                onClose={getAlert}
                // key={vertical + horizontal}
                sx={{ 
                    width: '100%',
                    mb:{ xs:10, sm:5, md:20 }
                }}
            >
                 <Alert severity="error"><b>Error</b> Codigo incorrecto.</Alert>
            </Snackbar> */}
        </Box>
    );
}
const ButtonPrincipal = ({ handleEmailChange }) => {
    const getEmail = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        
        handleEmailChange(data.get('email'))
    }
    return(
        <Box
            component="form" 
            onSubmit={getEmail}
            sx={{
                // backgroundColor:'pink',
                width:'100%',
            }}
        >
            <Button
                type="submit"
                fullWidth
                variant="outlined" 
                color="inherit" 
                endIcon={<ArrowForwardIcon/>}
                sx={{ /*fontSize:'11px',*/ 
                    mt: 3,
                    mb: 2,
                    //width: { xs:'95%', sm:'90%', md:'100%' }, 
                    p:{ xs:1, sm:1, md:1 },
                    borderColor:'#F5323F',
                    color:'#F5323F',
                    borderWidth:3,
                }}
            >
                <b>Siguiente</b>
            </Button>
        </Box>
    );
}

const Login = () => {
    const { state } = useLocation();
    const location = useLocation();
    let navigate = useNavigate();

    console.log('Tiburon',location);
    
    const [email, setEmail] = useState(null);
    const getEmail = (value) => {
        setEmail(value);
    }

    const [password, setPassword] = useState(null);
    const getPassword = (value) => {
        setPassword(value);
    }

    const [code, setCode] = useState(false);
    const getCode = (value) => {
        setCode(value);
    }

    const [cliente, setCliente] = useState(null);

    const getCliente = (value) => {
        setCliente(value);
    }

    const [alert, setAlert] = useState(false);
    const getAlert = () => {
        setAlert(!alert);
    }

    const getEmailIfExist = (email) => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/clientes/find-email-exist/${email}`)
        .then((response)=>{
            getEmail(email);
            getCliente(response.data);
            // console.log(response.data);
        })
        .catch((error)=>{
            console.log(error);
            getAlert();
        });
    }
    // let { state } = useLocation();
    // let location = useLocation();
    // console.log(location);

    // const [email, setEmail] = useState(null);
    // const getEmail = (newEmail) => {
    //     setEmail(newEmail)
    // }
    // const [password, setPassword] = useState(null);
    // const [clientExist, setClientExist] = useState(false);
    // const getClientExist = (email) => {
    //     axios.get(`${process.env.REACT_APP_API_URL}/api/clientes/find-email-exist/${email}`)
    //     .then((response)=>{
    //         setClientExist(response.data);
    //         console.log(response.data);
    //     })
    //     .catch((error)=>{
    //         console.log(error);
    //     });
    // }
    const login = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/clientes/login`,{
            email,
            password,
        })
        .then((response) => {
            // console.log('AQUIIIIIIII:::::::::', response.data);
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('id', response.data.client.folio);
            localStorage.setItem('type', 'cliente');
            if(response.data.client.datos && response.data.client.autenticacion){
                const nombre = response.data.client.datos.primerNombre ? response.data.client.datos.primerNombre : '';
                const segundoNombre = response.data.client.datos.segundoNombre ? response.data.client.datos.segundoNombre : '';
                const primerApellido = response.data.client.datos.primerApellido ? response.data.client.datos.primerApellido : '';
                const segundoApellido = response.data.client.datos.segundoApellido ? response.data.client.datos.segundoApellido : '';
                const emailClient = response.data.client.autenticacion.email;
                localStorage.setItem('userClient', JSON.stringify({nombre:`${nombre} ${segundoNombre} ${primerApellido} ${segundoApellido}`, email:emailClient}));
            }
            navigate(state.pathname);
        })
        .catch((error) => {
            console.log(error);
            getAlert(true)
        })
    }

    const updatePassword = () => {
        // console.log('actualizar cliente con los siguientes datos',cliente.id, email, password,);
        axios.post(`${process.env.REACT_APP_API_URL}/api/clientes/usuario-nuevo`,{
            idCliente: cliente.id,
            email,
            password,
        })
        .then((response) => {
            console.log(response);
            login();
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const volver = () => {
        if(email){
            if(password){
                getPassword(null)
            }
            if(code){
                getCode(false);
            }else{
                getEmail(null);
            }            
        }
    }

    useEffect(()=>{
        if(state){
            if(state.cliente){
                if(state.cliente.email){
                    getEmailIfExist(state.cliente.email);
                    // console.log('si hay state', state);
                }
            }
        }
    },[state]);

    return(
        <Container maxWidth="sm" sx={{ mt: { xs:8, md:15 }, minHeight:830, }}>
            <Box
                sx={{
                    // backgroundColor:'pink',
                    width:'100%',
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center',
                    justifyContent:'center',                    
                    mb:{ xs:1, sm:1, md:1 },
                }}
            >
                <Box
                    component='img'
                    src="https://storage.casaspecsa.com/otros/logo.svg"
                    sx={{
                        mb:{ xs:2, sm:2, md:2 }
                    }}
                />
                <Typography component="h1" variant="h5" sx={{ color: '#707070', }}>
                    Iniciar Sesion
                </Typography>
                {/* <Typography sx={{ color: '#707070' }}>
                    Bienvenida/o a tu cuenta, por favor
                </Typography> */}
            </Box>
            {
                email ? (
                    cliente && (
                        cliente.autenticacion.nuevo ? (
                            code ? (
                                <CreatePasswordInput cliente={cliente} handleChange={getPassword} handleClick={updatePassword}/>
                            ) : (
                                <CodeInput cliente={cliente} codigo={code} handleClick={getCode}/>
                            )
                        ) : (
                            <PasswordInput cliente={cliente} password={password} handleChange={getPassword} handleClick={login}/>
                        )
                    )
                ) : (<EmailInput handleClick={getEmailIfExist}/>)
            }            
            {
                email && (
                    <Button
                        type="submit"
                        fullWidth
                        variant="text" 
                        color="inherit"
                        startIcon={<ArrowBackIcon/>}
                        sx={{ /*fontSize:'11px',*/ 
                            mt: 0,
                            mb: 2,
                            //width: { xs:'95%', sm:'90%', md:'100%' }, 
                            p:{ xs:1, sm:1, md:1 },
                            borderColor:'#F5323F',
                            color:'#707070',
                            borderWidth:3,
                        }}
                        onClick={volver}
                    >
                        <b>volver</b>
                    </Button>
                )
            }
            {/* <EmailInput/>
            <PasswordInput/>
            <CreatePasswordInput/>
            <CodeInput/> */}
            {/* {
                !clientExist ? (
                    <EmailInput handleChange={getEmail} handleClick={getClientExist}/>
                ) : (
                    !password && (
                        // <PasswordInput handleChange={getPassword}/>
                        <h1>Password</h1>
                    )
                )
            } */}
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={alert}
                autoHideDuration={6000}
                onClose={getAlert}
                // key={vertical + horizontal}
                sx={{ 
                    width: '100%',
                    mb:{ xs:10, sm:5, md:20 }
                }}
            >
                 <Alert severity="error"><b>Error</b> No pudimos encontrar este usuario.</Alert>
            </Snackbar>
        </Container>
    );
}

export { Login }