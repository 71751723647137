import { Box, Container, Grid } from "@mui/material"
import { useRef } from "react"
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const ProcesosConstructivos = ({ modelo }) => {
    const imgRef = useRef(null);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    if(modelo.imagenProcesosConstructivosMovil && modelo.imagenProcesosConstructivosDesktop){
        return(
            <Container>
                <Box sx={{ color:'#f5323f', textAlign:'left', marginTop:2 }}>
                <h1>Procesos Constructivos</h1>
                </Box>
                <Box>
                    <Box
                        sx={{
                            // backgroundColor:'red',
                            width:'100%',
                        }}
                    >
                        <Box
                            ref={imgRef}
                            component="img"
                            src={ fullScreen ? modelo.imagenProcesosConstructivosMovil : modelo.imagenProcesosConstructivosDesktop }
                            sx={{
                                width:'100%',
                            }}
                        />
                    </Box>
                </Box>
            </Container>
        )
    }
}

export { ProcesosConstructivos }