import { useState } from "react";
import { useIdLotesToCompare } from "./useIdLotesToCompare";
import { useEffect } from "react";
import axios from "axios";

export const useLotesToCompare = (inversionInicial) => {
	const [lotes, setLotes] = useState([]);
	const addLote = (newLote) => {
		setLotes((oldLote)=>[...oldLote, newLote]);
	}
	const getLotes = () => {
		console.log('jajaja');
		setLotes([]);
	}

	const idLotes = useIdLotesToCompare();
	
	let count = 0;
	
	useEffect(()=>{
		const queryInversionInicial = inversionInicial ? `?inversionInicial=${inversionInicial}` : ``;
		console.log('EMPEZOOOOOO', inversionInicial);
		getLotes();
		while (count < idLotes.length) {
			axios.get(`${process.env.REACT_APP_API_URL}/api/lotes-inversion/simulacion/${idLotes[count]}${queryInversionInicial}`)
			.then((response)=>{
				console.log('llego a la api');
				addLote(response.data)
			})
			.catch((error)=>{
				addLote(null)
			});
			count++;
		}
			
	},[idLotes, inversionInicial]);
	
	return lotes
}