import { Avatar, Button, Card, CardActionArea, CardHeader, CardMedia, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import asesorPerfil2 from "../../assets/asesores/asesorPerfil2.png";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ClearIcon from '@mui/icons-material/Clear';

const EditarPost = ({ onClose, selectedValue, open, postId }) => {
    // const { onClose, selectedValue, open } = props;
    const { folio } = useParams();

    const [post, setPost] = useState(null);
    const [texto, setTexto] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const getTexto = (event) => {
        setTexto(event.target.value);
    };

    const [storageImages, setStorageImages] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [imagesUrl, setImagesUrl] = useState([]);

    const quitarImage = (index) => {
        // const arr = storageImages.map((imagen, i)=>{
        //     if(i !== index){
        //         return URL.createObjectURL(imagen)
        //     }
        // })
        // console.log('image', image);
        console.log('storageImages', index);
        // console.log('image', arr);
    }

    const [imagesUrlOld, setImagesUrlOld] = useState([]);

    const [addFotos, setAddFotos] = useState(false);
    const getAddFotos = () => {
        setAddFotos(!addFotos);
    }
    // const [post, setPost] = useState(null);
    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };
    const edit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);        
        if(data.get('texto') === '' && storageImages.length === 0){
            console.log('nada');
            return;
        }
        setButtonDisabled(true);
        
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');

        data.delete('prefotosImg');
        storageImages.map((foto) => {
            data.append('fotosImg', foto);
        })        

        axios.post(`${process.env.REACT_APP_API_URL}/api/asesores/profile/${folio}/posts/${postId}`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
        .then((response)=>{
            console.log(response); 
            setButtonDisabled(false);
            window.location.reload();
        })
        .catch((error)=>{
            console.log(error);
        });
    }
    useEffect(()=>{
        console.log('selectedImages');
        if (selectedImages.length > 0) {
            console.log('imagenes seleccionsadoas: ', selectedImages);
            for (let i = 0; i < selectedImages.length; i++) {
                if(!storageImages.find( element => element === selectedImages[i])){
                    // storageImages.push(selectedImages[i]);
                    setStorageImages((oldStorageImages)=>[...oldStorageImages, selectedImages[i]]);
                    // imagesUrl.push(URL.createObjectURL(selectedImages[i]))
                    setImagesUrl((oldImagesURL)=>[...oldImagesURL, URL.createObjectURL(selectedImages[i])]);
                }                
            }
        }
    },[selectedImages])

    useEffect(()=>{
        // console.log('nuevo id:::::',postId);
        if(postId){
            const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');

            axios.get(`${process.env.REACT_APP_API_URL}/api/asesores/profile/${folio}/post/${postId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then((response)=>{
                console.log(response);
                setPost(response.data);
                setTexto(response.data.texto);
                if(response.data.fotos.length > 0){
                    setImagesUrlOld(response.data.fotos)
                    getAddFotos();
                }
            })
            .catch((error)=>{
                console.log(error);
            });
        }
    },[postId]);
    return(
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth='lg'
        >
            <DialogTitle>Editar Post</DialogTitle>
            <DialogContent>
                <Box sx={{ width:'100%' }}>
                    <Box component="form" onSubmit={edit} encType="multipart/form-data">
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12}>
                                <Box
                                    sx={{
                                        // backgroundColor:'greenyellow',
                                        width:'100%',
                                        display:'flex',
                                        alignItems:'center',
                                        alignContent:'center'
                                    }}
                                >
                                    <TextField
                                        id="outlined-multiline-static"
                                        // label="Multiline"
                                        multiline
                                        rows={2}
                                        // defaultValue="Default Value"
                                        value={texto}
                                        onChange={getTexto}
                                        name="texto"
                                        placeholder="Escribe tu publicacion..."
                                        helperText="Maximo de 3000 caracteres."
                                        fullWidth
                                        
                                    />
                                </Box>
                                {
                                    addFotos && (
                                        <Box
                                            sx={{
                                                mt:1,
                                                mb:1,
                                            }}
                                        >
                                            <Grid container spacing={1}>
                                                <Grid item xs={4} sm={2} md={2}>
                                                    <Card 
                                                        sx={{ 
                                                            height: 80                                            
                                                        }}
                                                    >
                                                        <label htmlFor="contained-button-file">                            
                                                            <input accept="image/*" id="contained-button-file" type="file" name="prefotosImg" multiple style={{ display: 'none' }} onChange={e => setSelectedImages(e.target.files)}/>
                                                            <CardActionArea
                                                                sx={{
                                                                    width:'100%',
                                                                    height:'100%',
                                                                }}
                                                                component="span"
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        backgroundColor:'#f2f2f2',
                                                                        width:'100%',
                                                                        height:'100%',
                                                                        display:'flex',
                                                                        alignItems:'center',
                                                                        alignContent:'center',
                                                                    }}
                                                                    // component="span"
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            m:'0 auto',
                                                                            textAlign:'center',
                                                                            color:'#707070',
                                                                        }}
                                                                    >
                                                                        <AddPhotoAlternateIcon/>
                                                                        <Typography variant="body2">
                                                                            <b>Agregar</b>
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </CardActionArea>                                                        
                                                        </label>
                                                    </Card>
                                                </Grid>
                                                {
                                                    imagesUrlOld.map((imagen, index)=>(
                                                        <Grid item xs={4} sm={2} md={2}>
                                                            <Box
                                                                sx={{
                                                                    // backgroundColor:'red',
                                                                    width:'100%',
                                                                }}
                                                            >
                                                                <Card
                                                                    sx={{
                                                                        position:'relative'
                                                                    }}
                                                                >
                                                                    <CardHeader                                                                    
                                                                        // action={
                                                                        // <IconButton 
                                                                        //     aria-label="settings" 
                                                                        //     sx={{ mt:1, backgroundColor:'rgba(0, 0, 0, 0.2)' }}
                                                                        //     // onClick={()=>{ quitarImage(index) }}
                                                                        // >
                                                                        //     <ClearIcon sx={{ color:'#ffffff', }}/>
                                                                        // </IconButton>
                                                                        // }
                                                                        sx={{ position:'absolute', width:'42%', height:'100%', }}
                                                                    />
                                                                    <CardMedia
                                                                        sx={{ height: 80, }}
                                                                        image={imagen.url}
                                                                        title="green iguana"
                                                                    />
                                                                </Card>
                                                            </Box>
                                                        </Grid>
                                                    ))
                                                }
                                                {
                                                    imagesUrl.map((imagen, index)=>(
                                                        <Grid item xs={4} sm={2} md={2}>
                                                            <Box
                                                                sx={{
                                                                    // backgroundColor:'red',
                                                                    width:'100%',
                                                                }}
                                                            >
                                                                <Card
                                                                    sx={{
                                                                        position:'relative'
                                                                    }}
                                                                >
                                                                    <CardHeader                                                                    
                                                                        action={
                                                                        <IconButton 
                                                                        aria-label="settings" 
                                                                        sx={{ mt:1, backgroundColor:'rgba(0, 0, 0, 0.2)' }}
                                                                        onClick={()=>{ quitarImage(index) }}
                                                                        >
                                                                            <ClearIcon sx={{ color:'#ffffff', }}/>
                                                                        </IconButton>
                                                                        }
                                                                        sx={{ position:'absolute', width:'42%', height:'100%', }}
                                                                    />
                                                                    <CardMedia
                                                                        sx={{ height: 80 }}
                                                                        image={imagen}
                                                                        title="green iguana"
                                                                    />
                                                                </Card>
                                                            </Box>
                                                        </Grid>
                                                    ))
                                                }
                                            </Grid>
                                        </Box>
                                    )
                                }
                                <Box
                                    sx={{
                                        // backgroundColor:'greenyellow',
                                        width:'100%',
                                    }}
                                >                            
                                    <Grid 
                                        container
                                        direction="row"
                                    >
                                        <Grid item xs={12} sm={8} md={8}>
                                            <Box
                                                sx={{
                                                    // backgroundColor:'red',
                                                    display:'flex',
                                                }}
                                            >
                                                <IconButton aria-label="delete" onClick={getAddFotos} >
                                                    <AddPhotoAlternateIcon />
                                                </IconButton>
                                                <IconButton aria-label="delete">
                                                    <InsertDriveFileIcon />
                                                </IconButton>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={3} md={4}>
                                            <Box
                                                sx={{
                                                    // backgroundColor:'red',
                                                    width:'100%',
                                                    textAlign:'right',
                                                    pt:0.5,
                                                }}
                                            >
                                                <Button 
                                                    variant="contained"
                                                    sx={{
                                                        width:{ xs:'100%', sm:'40%', md:'40%'},
                                                        textTransform:'none',

                                                    }}
                                                    type="submit"
                                                    disabled={buttonDisabled}
                                                >
                                                    Editar
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export { EditarPost }