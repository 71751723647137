import { Button, Collapse, List, ListItem, ListItemText, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { useState } from "react";

const Educacion = ({ educacion }) => {
    const [abrirCollapse, setAbrirCollapse] = useState(false);
    return(
        <Box>
            <Collapse in={abrirCollapse} collapsedSize={150}>
                <List>
                    {
                        educacion.map((escuela) => (
                            <ListItem>
                                <ListItemText
                                    primary={<b>{ escuela.escuela }</b>}
                                    secondary={
                                        <Box>
                                            <Typography>
                                                { escuela.titulo }
                                            </Typography>
                                            <Typography>
                                            {`${moment(escuela.fechaInicio).format('YYYY')} - ${moment(escuela.fechaInicio).format('YYYY') === moment().format('YYYY') ? 'Actual' : moment(escuela.fechaInicio).format('YYYY')}`}
                                            </Typography>
                                        </Box>
                                    }
                                />
                            </ListItem>
                        ))
                    }

                </List>
            </Collapse>
            {
                educacion.length > 1 && (
                    <Button 
                        variant="text" 
                        onClick={()=>{ setAbrirCollapse(!abrirCollapse) }}
                        sx={{
                            // backgroundColor:'red',
                            width:'100%',
                            textTransform:'none',
                            color:'#F5323F',
                            '&:hover':{
                                backgroundColor:'#f2f2f2',

                            }
                        }}
                    >
                        <b>Ver Más</b>
                    </Button>
                )
            }
        </Box> 
    );
}

export { Educacion }