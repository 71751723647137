import { Breadcrumbs, Button, Grid, List, ListItem, ListItemText, Skeleton, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";

import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useState } from "react";
import { AcercaDeMi } from "./AcercaDeMi";
import { Experiencia } from "./Experiencia";
import { Educacion } from "./Educacion";
import { Idiomas } from "./Idiomas";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const EspacioAsesorInformacion = () => {
    let navigate = useNavigate();
    const { folio } = useParams();

    const [informacion, setInformacion] = useState(null);

    useEffect(()=>{
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');

        axios.get(`${process.env.REACT_APP_API_URL}/api/asesores/profile/${folio}/informacion`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
        .then((response)=>{
            console.log(response);
            setInformacion(response.data)            
        })
        .catch((error)=>{
            console.log(error);
        });
    }, [folio]);
    return(
        <Box
            sx={{
                // backgroundColor:'red',
                width:'100%',
            }}
        >
            <Box>
                <Breadcrumbs aria-label="breadcrumb">
                    {/* <Link underline="hover" color="inherit">
                        Inicio
                    </Link> */}
                    <Link to={'/'} style={{ color:'#707070', }}>
                        Espacio Asesor
                    </Link>
                    <Typography sx={{ color:'#F5323F', }}>Informacion</Typography>
                </Breadcrumbs>
            </Box>
            <List>
                <ListItem disablePadding divider>
                    <ListItemText primary={
                        informacion ? (
                            <AcercaDeMi acercademi={informacion.acercademi}/>
                        ) : (
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        )                        
                    } />
                </ListItem>
                <ListItem disablePadding divider>
                    <ListItemText primary={                        
                        informacion ? (
                            <Experiencia experiencia={informacion.experiencia}/>
                        ) : (
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        ) 
                    }/>
                </ListItem>
                <ListItem disablePadding divider>
                    <ListItemText primary={
                        informacion ? (
                            <Educacion educacion={informacion.educacion}/>
                        ) : (
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        )
                    }/>
                </ListItem>
                <ListItem disablePadding divider>
                    <ListItemText primary={
                        informacion ? (
                            <Idiomas idiomas={informacion.idiomas}/>
                        ) : (
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        )
                    }/>
                </ListItem>
            </List>
        </Box>
    );
}

export { EspacioAsesorInformacion }