import { Box, Grid } from "@mui/material";

const ListComparator = ({ children }) => {
    return(
        <Box>
            <Grid
				container
				direction="row"
				justifyContent="center"
				alignItems="flex-start"
			>
                { children } 
            </Grid>            
        </Box>
    );
}

export { ListComparator }