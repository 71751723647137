import { Avatar, Breadcrumbs, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Collapse, Dialog, Divider, Grid, IconButton, ImageList, ImageListItem, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Rating, Tab, Tabs, TextField, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { useState } from "react";
import { InlineWidget, PopupWidget } from "react-calendly";

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import VideocamIcon from '@mui/icons-material/Videocam';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PropTypes from 'prop-types';

import StarRoundedIcon from '@mui/icons-material/StarRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';

import fotoAsesor from "../../assets/asesores/cesar.png";
import sinFotoAsesor from "../../assets/asesores/sinFotoAsesorPrueba.png";
import asesorPerfil from "../../assets/asesores/asesorPerfil.png";
import asesorPerfil2 from "../../assets/asesores/asesorPerfil2.png";
import imagenPromo from "../../assets/prueba/imagenPromo.png";
import { useEffect } from "react";
import axios from "axios";
import { CalendarioIndividualAsesor } from "./CalendarioIndividualAsesor";
import { useNavigate, Link } from "react-router-dom";
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import { Helmet } from "react-helmet";
import PersonIcon from '@mui/icons-material/Person';

const Asesores = () => {
    let navigate = useNavigate();
    const [asesores, setAsesores] = useState([]);

    const getPromedio = (opiniones) => {
        const puntuaciones = opiniones.map((opinion)=> opinion.puntuacion );
        const total = puntuaciones.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        return total / puntuaciones.length;
    }

    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_API_URL}/api/asesores`)
        .then((response) => {
            // handle success
            // console.log(response);
            setAsesores(response.data);
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            // console.log('sae');
        });
    },[]);
    return(
        <Box sx={{ mt:{ xs:8, sm:9, md:12 } }}>
            <Helmet>
                <title>Casas Pecsa | Asesores </title>
                <meta name="description" content="Conoce Las Increíbles Casas que tenemos Para Ti. Contamos con un recorrido virtual 100% En Línea y Un Sistema de Separado en Línea. También puedes agendar tu cita para visitar Nuestras Casas Hoy mismo." />
            </Helmet>   
            <Container>
                <Box>
                    <Box>
                        <Breadcrumbs aria-label="breadcrumb">
                            {/* <Link underline="hover" color="inherit">
                                Inicio
                            </Link> */}
                            <Link to={'/'} style={{ color:'#707070', }}>
                                Inicio
                            </Link>
                            <Typography sx={{ color:'#F5323F', }}>Asesores</Typography>
                        </Breadcrumbs>
                    </Box>
                    <Box
                        sx={{
                            // backgroundColor:'red',
                            width:{ xs:'100%', sm:'70%', md:'100%'},
                            minHeight:700,
                        }}
                    >
                        <List
                            disablePadding
                            sx={{
                                // backgroundColor:'yellow',
                                width:'100%',
                            }}
                            // dense={true}
                        >
                            {
                                asesores.length > 0 ? (
                                    asesores.map((asesor) => (
                                        <ListItem 
                                            disablePadding 
                                            disableGutters={true}
                                            // dense={true}
                                            divider
                                            sx={{
                                                // backgroundColor:'green',
                                                width:'100%',
                                                minHeight:300,
                                                // p:0,
                                            }}                                        
                                        >
                                            <ListItemButton disableGutters>
                                                <ListItemText primary={
                                                    <Box sx={{ width:'100%', }}>
                                                        <Card sx={{ display: { xs:'block', sm:'flex', md:'flex', }, width:'100%', borderRadius: '16px', }}>
                                                            {
                                                                asesor.foto ? (
                                                                    <CardMedia
                                                                        component="img"
                                                                        sx={{ width: { xs:'100%', sm:300, md:300, },  }}
                                                                        image={asesor.foto}
                                                                        alt="Live from space album cover"
                                                                    />
                                                                ) : (
                                                                    <Box
                                                                        sx={{ width: { xs:'100%', sm:300, md:300, },  }}
                                                                    >
                                                                        <PersonIcon sx={{ fontSize: { xs:'100%', sm:290, md:290, }, color:'#707070', }}/>
                                                                    </Box>
                                                                )
                                                            }
                                                            
                                                            <CardContent sx={{ /*flex: '1 0 auto'*/ }}>
                                                                <Grid container>
                                                                    <Grid item xs={12} sm={7} md={7} onClick={()=>navigate(`${asesor.usuario.folio}`)}>
                                                                        <Box>                                                            
                                                                            <Typography component="div" variant="h4">
                                                                                <b>{ asesor.nombre }</b>
                                                                            </Typography>
                                                                            <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                                Vendedor
                                                                            </Typography>
                                                                            <Box
                                                                                sx={{
                                                                                    display:'flex',
                                                                                    alignItems:'center',
                                                                                    // mt:1,
                                                                                }}
                                                                            >
                                                                                <Rating name="size-large" value={getPromedio(asesor.opiniones)} size="large" readOnly />
                                                                                <Typography sx={{ color:'#707070', }}>
                                                                                    <u>Ver opiniones</u>
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box
                                                                                sx={{
                                                                                    display:{ xs:'none', sm:'block', md:'block', },
                                                                                    alignItems:'center',
                                                                                    // mt:1,
                                                                                }}
                                                                            >
                                                                                <Typography variant="body1" sx={{ mt:2, color:'#707070', }}>
                                                                                    { asesor.datos.descripcion }
                                                                                </Typography>
                                                                            </Box>
                                                                            
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={5} md={5}>
                                                                        <Box>
                                                                            <CalendarioIndividualAsesor asesor={asesor}/>
                                                                        </Box>
                                                                        {/* <Box
                                                                            sx={{
                                                                                // backgroundColor:'red',
                                                                                width:'100%',
                                                                                height:'100%',
                                                                                textAlign:'center'
                                                                            }}
                                                                        >
                                                                            <Typography variant="body1" sx={{ mt:2, color:'#707070', }}>
                                                                                Agendar cita en:
                                                                            </Typography>
                                                                            <Button 
                                                                                variant="contained"
                                                                                sx={{
                                                                                    width:'90%',
                                                                                    textTransform:'none',
                                                                                    mt:1,
                                                                                    borderRadius: '10px',
                                                                                }}
                                                                                disabled
                                                                            >
                                                                                <b>Altavista Residencial</b>(proximamente)
                                                                            </Button>
                                                                            <Button 
                                                                                variant="contained"
                                                                                sx={{
                                                                                    width:'90%',
                                                                                    textTransform:'none',
                                                                                    mt:1,
                                                                                    borderRadius: '10px',
                                                                                }}
                                                                            >
                                                                                <b>Bosques de las Lomas Residencial</b>
                                                                            </Button>
                                                                            <Button 
                                                                                variant="contained"
                                                                                sx={{
                                                                                    width:'90%',
                                                                                    textTransform:'none',
                                                                                    mt:1,
                                                                                    borderRadius: '10px',
                                                                                }}
                                                                            >
                                                                                <b>El Rocal Residencial</b>
                                                                            </Button>
                                                                            <Button 
                                                                                variant="contained"
                                                                                sx={{
                                                                                    width:'90%',
                                                                                    textTransform:'none',
                                                                                    mt:1,
                                                                                    borderRadius: '10px',
                                                                                }}
                                                                            >
                                                                                <b>Lomas del Morillo Residencial</b>
                                                                            </Button>
                                                                        </Box> */}
                                                                    </Grid>
                                                                </Grid>
                                                                                                                    
                                                            </CardContent>                                                
                                                        </Card>
                                                    </Box>
                                                } />
                                            </ListItemButton>
                                        </ListItem>
                                    ))
                                ) : (
                                    <Box
                                        sx={{
                                            width:'100%',
                                            // backgroundColor:'red',
                                            textAlign:'center',
                                            color:'#F5323F',
                                        }}
                                    >
                                        <ContentPasteOffIcon sx={{ fontSize:{ xs:150, sm:200, md:200 } }} />
                                        <Typography variant="h4">
                                            <b>Lista de Asesores no disponible.</b>
                                        </Typography>
                                    </Box>
                                )
                            }                                    
                        </List>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}

export { Asesores }