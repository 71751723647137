import { Grid, Rating } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import m106 from '../../assets/casas/m106.png';

import d from '../../assets/razonespecsa/uno.png'

const OpinionesItem = ({razon}) => {

    return (
        <Box
            sx={{
                height:{ xs:360, sm:360, md:360 }, 
            }}
        >
            <Box
                sx={{
                    height:{ xs:'10%', sm:'10%', md:'15%' },
                    // backgroundColor: 'red',
                    textAlign:'center'
                }}
            >
                <Rating name="read-only" value={razon.puntuacion} readOnly />
            </Box>
            <Box
                sx={{
                    height:{ xs:'75%', sm:'75%', md:'70%' },
                    // backgroundColor: 'green',
                    whiteSpace: 'normal',
                    overflow: 'hidden',
                }}
            >
                {/* <Typography variant="p" component="div" sx={{ ml: { xs: 7, sm: 15, md: 25 }, mr: { xs: 7, sm: 15, md: 25 }, fontSize: { xs: 12, sm: 15, md: 18 }, textAlign:'center', mt:2, mb:2 }}>
                    { razon.desc }
                </Typography> */}
                <Typography 
                    variant={ razon.desc.length > 192 ? "body1" : "h5" } 
                    component="div" 
                    sx={{ 
                        ml: { xs: 'none', sm: 10, md: 10, }, 
                        mr: { xs: 'none', sm: 10, md: 10, }, 
                        //fontSize: { xs: 12, sm: 15, md: 18 }, 
                        textAlign:'center', 
                        textOverflow: 'ellipsis',
                        // overflow: 'hidden',
                        // mt:2, 
                        // mb:2 
                    }}
                >
                    { razon.desc }
                </Typography>
            </Box>
            <Box
                sx={{
                    height:{ xs:'15%', sm:'15%', md:'15%' },
                    // backgroundColor: 'orange',
                    textAlign:'center'
                }}
            >
                <Typography variant="body1" component="div">
                    <b>{ razon.nombre }</b>
                </Typography>
            </Box>
        </Box>
        // <Stack
        //     direction="column"
        //     justifyContent="flex-start"
        //     alignItems="center"
        //     sx={{
        //         pt:4,
        //         pb:2,
        //         height:{ xs:320, sm:220, md:'100%' },
        //         color: '#f5323f', //'#DE4647'
        //     }}
        // >
        //     <Rating name="read-only" value={razon.puntuacion} readOnly />           
        //     <Typography variant="p" component="div" sx={{ ml: { xs: 7, sm: 15, md: 25 }, mr: { xs: 7, sm: 15, md: 25 }, fontSize: { xs: 12, sm: 15, md: 18 }, textAlign:'center', mt:2, mb:2 }}>
        //         { razon.desc }
        //     </Typography>
        //     <Typography variant="body1" component="div" sx={{ m:1 }}>
        //         <b>{ razon.nombre }</b>
        //     </Typography>
        // </Stack>
        // <Card 
        //     sx={{
        //         width:'100%'
        //     }}
        // >
        //     <CardMedia
        //         component="img"
        //         height="280"
        //         image={props.razon.imagen}
        //         alt="green iguana"
        //     />
        //     <CardContent spacing={1}>
        //         <Stack>
        //             <Typography variant="h5" component="div">
        //                 {props.razon.titulo}
        //             </Typography>
        //             <Grid container spacing={1}>
        //                 <Grid item xs={7}>
        //                     <Stack direction="column"
        //                         justifyContent="center"
        //                         alignItems="flex-start"
        //                     >
        //                         <Typography  variant="body2" color="text.secondary">
        //                             { props.razon.desc }
        //                         </Typography>                                
        //                     </Stack>
        //                 </Grid>
        //             </Grid>
        //         </Stack>
        //     </CardContent>
        // </Card>
    );
}

export { OpinionesItem };