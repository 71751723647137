import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";

// import videoPortada from '../../../assets/landingPages/morillo/PORTADA.mp4';
import logo from '../../../assets/landingPages/morillo/logo.png';
import { Formulario } from "./Formulario";

const Portada = ({ getOpen }) => {
    return(
        <Box
            sx={{
                position:'relative',
                width:'100%',
                minHeight:{xs:970, sm:1000, md:900 },
                display:'flex',
                alignItems:'flex-start',
                alignContent:'center',
                overflow:'hidden',
            }} 
        >
            <video 
                src="https://d3ihta9hbp4hol.cloudfront.net/landingPages/morillo/PORTADA.mp4"
                autoPlay={true} 
                loop={true} 
                type='video/mp4' 
                muted 
                style={{
                    // position:'relative',
                    position:'absolute',
                    m:'0 auto',
                    minWidth:'100%',
                }}    
            />
            <Box
                sx={{
                    // position:'relative',
                    position:'absolute',
                    backgroundColor:'rgba(16, 112, 53, 0.5)',
                    width:'100%',
                    height:'100%',
                    display:'flex',
                    alignItems:'center',
                    alignContent:'center',
                }}
            >
                <Box
                    sx={{
                        width:{ xs:'100%', sm:'100%', md:'90%', },
                        // backgroundColor:'yellowgreen',
                        m:'0 auto',
                        color:'white',
                        textAlign:'center',
                    }}
                >
                    <Box
                        sx={{
                            width:{ xs:'100%', sm:'100%', md:'100%', },
                            height:{ xs:150, sm:250, md:250, },
                            // backgroundColor:'yellowgreen',
                            display:'flex',
                            alignItems:'center',
                            alignContent:'center',
                            mb:{ xs:1, md:4 },
                        }}
                    >
                        <Box
                            component="img"
                            src={logo}
                            sx={{
                                m:'0 auto',
                                maxHeight:'100%',
                                maxWidth:'80%'
                            }}
                        />
                    </Box>
                    {/* <Typography variant="h1" 
                    sx={{ 
                        // WebkitTextStroke:'1px #21452f',
                        // textShadow:'-1px 0px 0px red, 1px 0px 0px red, 0px -1px 0px red, 0px 1px 0px red',
                        fontSize:{ xs:50, sm:80, md:100 },
                        textShadow:'#21452f 1px 0 10px',
                    }}>
                        <b>Vive la tranquilidad del sur</b>
                    </Typography> */}
                    <Typography variant="h1" component="h2"
                        sx={{ 
                            // WebkitTextStroke:'1px #21452f',
                            // textShadow:'-1px 0px 0px red, 1px 0px 0px red, 0px -1px 0px red, 0px 1px 0px red',
                            fontSize:{ xs:40, sm:60, md:80 },
                            textShadow:'#21452f 1px 0 10px',
                            color:'#FFDE6C',
                        }}
                    >
                        <b>¡Últimas Casas!</b>
                    </Typography>
                    <Formulario getOpen={getOpen} />
                    {/* <Box
                        sx={{
                            // backgroundColor:'red',
                            width:'100%',
                            textAlign:'center',
                            mt:8,
                        }}
                    >
                        <Button
                            variant="contained"
                            color="inherit"
                            sx={{
                                width:{ xs:'90%', sm:'90%', md:'20%' },
                                borderRadius:'9px',
                                // textTransform:'none',
                                backgroundColor:'#FFFFFF',
                                color:'#148c44',
                                '&:hover':{
                                    backgroundColor:'#e6e6e6',
                                    color:'#148c44',
                                },
                                pt:1.5,
                                pb:1.5,
                                boxShadow:5,
                            }}
                            onClick={getOpen}
                        >
                            <Typography variant="h5">
                                <b>Agendar Cita</b>
                            </Typography>
                        </Button>
                    </Box> */}
                </Box>
            </Box>
        </Box>
    );
}

export { Portada }