import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import PropTypes from "prop-types";

import theme from "../../App/theme";

import { IMaskInput } from "react-imask";
import { NumericFormat } from "react-number-format";

/**
 * Componente personalizado de un input (Text Field).
 */

const MAX_LENGTH = 15; // Define la longitud máxima para el campo de entrada menos los caracteres de adornos
const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
      allowMulti={true}
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      suffix=".00"
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function CustomTextField({
  handleChangueProp,
  initialValue = 0,
  disabled = false,
}) {
  const [values, setValues] = React.useState(
    initialValue === 0 ? {} : { numberformat: initialValue }
  );

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    handleChangueProp(event.target.value);
  };

  useEffect(() => {
    handleChangueProp(initialValue);
  }, []);

  return (
    <TextField
      value={values.numberformat}
      onChange={handleChange}
      name="numberformat"
      id="formatted-numberformat-input"
      sx={{
        width: "100%",
        "& .MuiInputBase-root": {
          "& fieldset": {
            border: `2px solid ${theme.palette.grey[500]}`,
            borderRadius: "15px",
            height: "47px",
          },
          "&:hover fieldset": {
            borderColor: theme.palette.grey[600],
          },
          "&.Mui-focused fieldset": {
            borderColor: theme.palette.info.main,
          },
        },
        input: {
          color: theme.palette.common.black,
          // bgcolor: "white",
          height: "13px",
        },
      }}
      inputProps={{ maxLength: MAX_LENGTH }}
      InputProps={{
        inputComponent: NumericFormatCustom,
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        endAdornment: <InputAdornment position="end">MXN</InputAdornment>,
      }}
      disabled={disabled}
    />
  );
}

export { CustomTextField };
