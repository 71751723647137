import { CheckBox } from "@mui/icons-material";
import { Alert, Autocomplete, Button, Checkbox, createFilterOptions, Divider, FormControl, FormControlLabel, FormGroup, Grid, InputAdornment, InputLabel, Link, MenuItem, Select, Snackbar, Step, StepButton, StepLabel, Stepper, styled, TextField, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import todos from '../../assets/preautorizar/todos.png';
import gracias from '../../assets/preautorizar/gracias.png';
import { Helmet } from "react-helmet";

import banorte from '../../assets/solicitacredito/banorte.svg';
import bbva from '../../assets/solicitacredito/bbva.svg';
import fobissste from '../../assets/solicitacredito/fobissste.svg';
import HSBC from '../../assets/solicitacredito/HSBC.svg';
import infonavit from '../../assets/solicitacredito/infonavit.svg';
import scotiabank from '../../assets/solicitacredito/scotiabank.svg';
import { MetaPixel } from "../../components/MetaPixel";

const bancosPreautorizan = [
    { nombre:'Banorte', imagen:banorte, },
    { nombre:'BBVA', imagen:bbva, },
    // { nombre:'Fobissste', imagen:fobissste, },
    { nombre:'HSBC', imagen:HSBC, },
    // { nombre:'Infonavit', imagen:infonavit, },
    { nombre:'ScotiaBank', imagen:scotiabank, },
]
const steps = ['Comienza tu solicitud', 'General', 'Confirmar'];
const estados = [
    { abrevia:"AGU", nombre:'Aguascalientes' },
    { abrevia:"BCN", nombre:'Baja California' },
    { abrevia:"BCS", nombre:'Baja California Sur' },
    { abrevia:"CAM", nombre:'Campeche' },
    { abrevia:"CHP", nombre:'Ciudad de México' },
    { abrevia:"CHH", nombre:'Chiapas' },
    { abrevia:"CMX", nombre:'Chihuahua' },
    { abrevia:"COA", nombre:'Coahuila de Zaragoza' },
    { abrevia:"COL", nombre:'Colima' },
    { abrevia:"DUR", nombre:'Durango' },
    { abrevia:"GUA",nombre:'Estado de México' },
    { abrevia:"GRO", nombre:'Guanajuato' },
    { abrevia:"HID", nombre:'Guerrero' },
    { abrevia:"JAL", nombre:'Hidalgo' },
    { abrevia:"MEX", nombre:'Jalisco' },
    { abrevia:"MIC", nombre:'Michoacán de Ocampo' },
    { abrevia:"MOR", nombre:'Morelos' },
    { abrevia:"NAY", nombre:'Nayarit' },
    { abrevia:"NLE", nombre:'Nuevo León' },
    { abrevia:"OAX", nombre:'Oaxaca' },
    { abrevia:"PUE", nombre:'Puebla' },
    { abrevia:"QUE", nombre:'Querétaro' },
    { abrevia:"ROO",nombre:'Quintana Roo' },
    { abrevia:"SLP", nombre:'San Luis Potosí' },
    { abrevia:"SIN", nombre:'Sinaloa' },
    { abrevia:"SON", nombre:'Sonora' },
    { abrevia:"TAB", nombre:'Tabasco' },
    { abrevia:"TAM", nombre:'Tamaulipas' },
    { abrevia:"TLA", nombre:'Tlaxcala' },
    { abrevia:"VER", nombre:'Veracruz de Ignacio de la Llave' },
    { abrevia:"YUC", nombre:'Yucatán' },
    { abrevia:"ZAC", nombre:'Zacatecas' },
];
const filter = createFilterOptions();

const PreAutorizacion = () => {
    let navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());

    const [valueColonia, setValueColonia] = useState(null);

    const [colonias, setColonias] = useState([]);

    const [primerNombre, setPrimerNombre] = useState(null);
    const [segundoNombre, setSegundoNombre] = useState(null);
    const [primerApellido, setPrimerApellido] = useState(null);
    const [segundoApellido, setSegundoApellido] = useState(null);
    const [email, setEmail] = useState(null);
    const [telefono, setTelefono] = useState(null);

    const [datosPrincipales, setDatosPrincipales] = useState({
        accountType: "PF",
        email: null,
        firstName: null,
        phone: null,
    });
    const checkDatosPersonales = () => {
        if(primerNombre && primerApellido && segundoApellido && email && telefono){
            console.log('hey');
            return true;
        }
        else{
            return false
        }
    }
    const [montoDelPrestamo, setMontoDelPrestamo] = useState(null);
    const [CURP, setCURP] = useState(null);
    const [RFC, setRFC] = useState(null);
    const [birthdate, setBirthdate] = useState(null);
    const [ingresoMensual, setIngresoMensual] = useState(null);
    const [calle, setCalle] = useState(null);
    const [numeroExterior, setNumeroExterior] = useState(null);
    const [numeroInterior, setNumeroInterior] = useState(null);
    const [codigoPostal, setCodigoPostal] = useState(null);
    const [colonia, setColonia] = useState(null);
    const [estado, setEstado] = useState('');    
    const [municipio, setMunicipio] = useState('muni');

    const [estadoSelect, setEstadoSelect] = useState(null);

    const [datosGenerales, setDatosGenerales] = useState({
        monthlyIncome: null,
        loanAmount: null,
        curp: null,
        rfc: null,
        birthdate: null,
        address: null,
        city: null,
        state: null,
        zipCode: null,
        exteriorNumber: null,
        interiorNumber: null,
        neighborhood: null,
        country: "MX",
        nationality: "MX",
    });
    const checkDatosGenerales = () => {
        if(montoDelPrestamo && CURP && ingresoMensual && calle && numeroExterior && codigoPostal && colonia && estado && municipio){
            console.log(colonia);
            console.log(montoDelPrestamo +'-'+ CURP +'-'+ ingresoMensual +'-'+ calle +'-'+ numeroExterior +'-'+ codigoPostal +'-'+ colonia +'-'+ estado +'-'+ municipio);
            return true;
        }
        else{
            console.log(colonia);
            console.log(montoDelPrestamo +'-'+ CURP +'-'+ ingresoMensual +'-'+ calle +'-'+ numeroExterior +'-'+ codigoPostal +'-'+ colonia +'-'+ estado +'-'+ municipio);
            return false
        }
    }
    const getRFC = (CURP) => {
        if(CURP){
            if(CURP.length > 10){
                setRFC(CURP.substring(0, 10));
                console.log(RFC);
            }
        }
    }
    const getBirthdate = (CURP) => {
        if(CURP){
            if(CURP.length > 10){
                const CURPCortado = CURP.substring(4, 10);
                let preBirthdate = '';
                for (let index = 0; index < CURPCortado.length; index++) {
                    if(index % 2 === 1){
                        if(index === CURPCortado.length - 1 ){
                            preBirthdate += CURPCortado[index];
                        }else{
                            preBirthdate += CURPCortado[index] + "-";
                        }
                        // preBirthdate += CURPCortado[index] + "-";
                    }else{
                        preBirthdate += CURPCortado[index];
                    }
    
                }
                const d = new Date(preBirthdate)
                //console.log(d.getFullYear() + '-' + d.toLocaleString("default", { month: "2-digit" }) + '-' + d.toLocaleString("default", { day: "2-digit" }));
                setBirthdate(d.getFullYear() + '-' + d.toLocaleString("default", { month: "2-digit" }) + '-' + d.toLocaleString("default", { day: "2-digit" }));
                console.log(birthdate);
            }
        }
    }
    const getColonias = (codigoPostalV) => {
        axios.get(`${process.env.REACT_APP_MOFFIN_API_URL}/postal-codes/${codigoPostalV}`,
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_MOFFIN_API_KEY}`,
                //ContentType: 'multipart/form-data'
              }
        })
        .then((response) => {
            // handle success
            //console.log(response);
            setColonias(response.data);
            // getEstadoYMunicipio(response.data)
            //console.log('success axioColonias');
            // navigate(-1, { replace: true });
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sabe');
        });
    }
    const getEstadoYMunicipio = (coloniasV) => {
        //console.log('hey estados y municipios');
        let edo = '';
        let mpio = '';
        coloniasV.map((colonia) => {
            if(edo !== colonia.state){
                edo = colonia.state;
            }
            if(mpio !== colonia.city){
                mpio = colonia.city;
            }
        })
        setMunicipio(mpio);
        //setEstado(edo);
        setEstadoSelect(estados.find((estado)=>estado.nombre === edo));
        
        console.log(estadoSelect);
    }

    const updateForm = () => {
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        // const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');
        // console.log(formId); return;
        console.log({
            monthlyIncome: ingresoMensual,
            loanAmount: montoDelPrestamo,
            curp: CURP,
            rfc: RFC,
            birthdate: birthdate,
            address: calle,
            city: municipio,
            state: estado,
            zipCode: codigoPostal,
            exteriorNumber: numeroExterior,
            interiorNumber: numeroInterior,
            neighborhood: colonia,
            country: "MX",
            nationality: "MX",
        });
        //handleNext();
        // return;
        axios.patch(`${process.env.REACT_APP_MOFFIN_API_URL}/form/${formId}`,
        {
            monthlyIncome: ingresoMensual,
            loanAmount: montoDelPrestamo,
            curp: CURP,
            rfc: RFC,
            birthdate: birthdate,
            address: calle,
            city: municipio,
            state: estado,
            zipCode: codigoPostal,
            exteriorNumber: numeroExterior,
            interiorNumber: numeroInterior,
            neighborhood: colonia,
            country: "MX",
            nationality: "MX",
        },
        {
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_MOFFIN_API_KEY}`,
            }
        })
        .then((response) => {
            // handle success
            console.log(response);
            console.log('success axio');
            handleNext();
            // navigate(-1, { replace: true });
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sabe');
        });
    }
    const [formCreado, setFormCreado] = useState(false);
    const [nipValidate, setNipValidate] = useState(false);

    const [formId, setFormId] = useState(null);

    const [NIP, setNIP] = useState(null);

    const checkNIP = () => {
        if(!NIP){
            return false;
        }
        if(NIP.length !== 4){
            return false;
        }
        return true
    }
    const sendNIP = (formId) => {
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        // const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');

        // console.log(datosPrincipales);
        // //handleNext();
        // return;
        axios.post(`${process.env.REACT_APP_MOFFIN_API_URL}/form/nip/send-sms/${formId}`,
            {
                organizationName: "Moffin"
            },
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_MOFFIN_API_KEY}`,
              }
        })
        .then((response) => {
            // handle success
            console.log(response);
            console.log('success axio');
            // navigate(-1, { replace: true });
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sabe');
        });
        axios.post(`${process.env.REACT_APP_MOFFIN_API_URL}/form/nip/send-email/${formId}`,
            {
                organizationName: "Moffin"
            },
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_MOFFIN_API_KEY}`,
              }
        })
        .then((response) => {
            // handle success
            console.log(response);
            console.log('success axio');
            // navigate(-1, { replace: true });
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sabe');
        });
    }
    const validarNIP = (nip) => {
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        // const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');

        // console.log(datosPrincipales);
        // //handleNext();
        // return;
        let adelante = false;
        axios.post(`${process.env.REACT_APP_MOFFIN_API_URL}/form/nip/validate/${formId}`,
            {
                nip: nip,
            },
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_MOFFIN_API_KEY}`,
              }
        })

        .then((response)=>{
            adelante = true;
            handleNext();
            //handleStep(1)
            //setActiveStep(activeStep + 1 );
        })
        .catch((error) => {
            // handle success
            console.log(error);
            handleClickAlert();
            //adelante = false;
            // navigate('/usuarios/login');
        });
        //return adelante;
    }
    const [checked, setChecked] = useState(false);
    const [loadingChecked, setLoadingChecked] = useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    const handleChangeEstados = (event) => {
        //setEstadoSelect(estados.find(element => element.abrevia === event.target.value))
        setEstado(event.target.value.abrevia);
        //console.log(estadoSelect, estado);
    };
    const submitForm = () => {
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        // const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');

        // console.log(datosPrincipales);
        // //handleNext();
        // return;
        setLoadingChecked(true);
        axios.post(`${process.env.REACT_APP_MOFFIN_API_URL}/form/submit/${formId}`,
            {
                validateNIPAuthentication: true,
            },
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_MOFFIN_API_KEY}`,
              }
        })
        .then((response) => {
            // handle success
            console.log(response);
            console.log('success axio');
            handleNext();
            // navigate(-1, { replace: true });
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sabe');
        });
    }

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        if (activeStep === 0){
            // validarNIP(NIP);
            // if(!nipValidate){
            //     return;
            // }
        }
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    const handleStep = (step) => () => {
        setActiveStep(step);
      };
    const SBFormTextField = styled(TextField)({
        "& label.Mui-focused": {
            color: "#f5323f"
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#d9d9d9"
        },
        "& .MuiOutlinedInput-root": {
            // '& fieldset': {
            //   borderColor: 'black',
            // },
            "&:hover fieldset": {
                borderColor: "#d9d9d9"
            },
            "&.Mui-focused fieldset": {
                borderColor: "#d9d9d9"
            }
        }
    })
    const MyFormTextField = ({ props }) => {
        return(
            <TextField
                id="outlined-basic"
                variant="outlined"
                { ...props.textfield }
                sx={{
                    ...props.style,
                    "& label.Mui-focused": {
                        color: "#f5323f"
                    },
                    "& .MuiInput-underline:after": {
                        borderBottomColor: "#d9d9d9"
                    },
                    "& .MuiOutlinedInput-root": {
                        // '& fieldset': {
                        //   borderColor: 'black',
                        // },
                        "&:hover fieldset": {
                            borderColor: "#d9d9d9"
                        },
                        "&.Mui-focused fieldset": {
                            borderColor: "#d9d9d9"
                        }
                    }
                }}
                defaultValue={props.defaultValue}
                onChange={props.onChange}
            />
        )
    }
    const stylePecsaMoffin = {
        "& label.Mui-focused": {
            color: "#f5323f"
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#d9d9d9"
        },
        "& .MuiOutlinedInput-root": {
            // '& fieldset': {
            //   borderColor: 'black',
            // },
            "&:hover fieldset": {
                borderColor: "#d9d9d9"
            },
            "&.Mui-focused fieldset": {
                borderColor: "#d9d9d9"
            }
        }
    }
    const reedirigirCURP = () =>{
        window.open('https://www.gob.mx/curp/')
    }
    const crearForm = () => {
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        // const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');

        // console.log(datosPrincipales);
        // //handleNext();
        // return;
        axios.post(`${process.env.REACT_APP_MOFFIN_API_URL}/form/${process.env.REACT_APP_MOFFIN_API_FORM}`,
            datosPrincipales,
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_MOFFIN_API_KEY}`,
              }
        })
        .then((response) => {
            // handle success
            console.log(response);
            console.log('success axio');
            console.log(response.data.formId);
            setFormId(response.data.formId);
            setFormCreado(true);
            sendNIP(response.data.formId);
            // navigate(-1, { replace: true });
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sabe');
        });
    }

    const [openError, setOpenError] = useState(false);

    const handleClickAlert = () => {
        setOpenError(true);
    };

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpenError(false);
    };

    useEffect(()=>{
        setDatosPrincipales({
            accountType: "PF",
            email: email,
            firstName: primerNombre,
            middleName: segundoNombre,
            firstLastName: primerApellido,
            secondLastName: segundoApellido,
            phone: `+52${telefono}`
        });
    },[primerNombre, segundoNombre, primerApellido, segundoApellido, email, telefono]);
    useEffect(()=>{
        //console.log('cambio curp');
        getRFC(CURP);
        getBirthdate(CURP);
    },[CURP]);
    useEffect(()=>{
        getColonias(codigoPostal);
        //getEstadoYMunicipio(colonias);
    },[codigoPostal]);
    useEffect(()=>{
        if(valueColonia){
            setColonia(valueColonia.neighborhood);
        }
    },[valueColonia]);
    useEffect(()=>{
        getEstadoYMunicipio(colonias);
    },[colonias]);
    useEffect(()=>{
        if(estadoSelect){
            console.log('Soy el estado select',estadoSelect);
            setEstado(estadoSelect.abrevia);
            console.log('Soy el estado',estado);
        }
    },[estadoSelect]);
    useEffect(()=>{
        console.log(ingresoMensual);
        setDatosGenerales({
            monthlyIncome: ingresoMensual,
            loanAmount: montoDelPrestamo,
            curp: CURP,
            rfc: RFC,
            birthdate: birthdate,
            address: calle,
            city: municipio,
            state: estado,
            zipCode: codigoPostal,
            exteriorNumber: numeroExterior,
            interiorNumber: numeroInterior,
            neighborhood: colonia,
            //...datosGenerales
        });
    },[montoDelPrestamo, CURP, RFC, birthdate, ingresoMensual, calle, numeroExterior, numeroInterior, codigoPostal, colonia, estado, municipio]);
    return(
        <Container>
            <Helmet>
                <title>Casas Pecsa | Pre Autorizacion</title>
                <meta name="description" content="Compra Fácil y A tu Ritmo. Llena Un pequeño Formulario y Conoce Hoy mismo A Que Créditos Puedes Acceder Tu Casa. Nosotros Te Ayudamos" />
            </Helmet>
            <MetaPixel/>
            <Box
                sx={{
                    mt:{xs:12, sm:12, md:15, },
                }}
            >
                <Box>
                    <Box
                        sx={{
                            //backgroundColor:'red',
                            width:{xs:'100%', sm:'50%', md:'50%', },
                            m:'0 auto'
                        }}
                    >
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                if (isStepOptional(index)) {
                                    labelProps.optional = (
                                        <Typography variant="caption">Optional</Typography>
                                    );
                                }
                                if (isStepSkipped(index)) {
                                    stepProps.completed = false;
                                }
                                return (
                                    <Step 
                                        key={label} 
                                        {...stepProps}
                                        sx={{
                                            '& .MuiStepLabel-root .Mui-completed': {
                                                color: '#f5323f', // circle color (COMPLETED)
                                            },
                                            '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
                                                    color: 'green', // Just text label (COMPLETED)
                                            },
                                            '& .MuiStepLabel-root .Mui-active': {
                                                color: '#F5323F', // circle color (ACTIVE)
                                            },
                                            '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
                                                color: 'f5323f', // Just text label (ACTIVE)
                                            },
                                            '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                                fill: 'white', // circle's number (ACTIVE)
                                            },
                                        }}
                                    >
                                        {/* <StepLabel {...labelProps}>{label}</StepLabel> */}
                                        <StepButton color="inherit" onClick={handleStep(index)}>
                                            {label}
                                        </StepButton>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Box>
                </Box>
                <Box
                    sx={{
                        //backgroundColor:'yellow',
                        mt:3,
                        minHeight:500,
                    }}
                >
                    {
                        activeStep === 0 && (

                            !formCreado ? (
                                <Grid container>
                                    <Grid item xs={12} sm={8} md={7}>
                                        <Box
                                            sx={{
                                                minHeight:{ xs:580, sm: 400, md:400 },
                                                color:'#707070',
                                            }}
                                        >
                                            <Grid container spacing={{ xs:2, sm: 2, md:2 }}>
                                                <Grid item xs={12} sm={12} md={12} >
                                                    <Typography variant="h6" sx={{ color:'#f5323f' }}>
                                                        <b>Comienza tu solicitud</b>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6} >
                                                    <TextField id="outlined-basic" label="Primer Nombre" variant="outlined" name="primerNombre" sx={{ width:'100%', ...stylePecsaMoffin }} defaultValue={primerNombre} onChange={(e)=>setPrimerNombre(e.target.value)} required/>
                                                    {/* <MyFormTextField
                                                        props={{
                                                            textfield:{
                                                                label:"Primer Nombre",
                                                                name:"primerNombre",
                                                                required:true,
                                                            },
                                                            style:{
                                                                width:'100%',
                                                            },
                                                            controller:{
                                                                defaultValue:primerNombre,
                                                                onChange:(e)=>setPrimerNombre(e.target.value)
                                                            }
                                                        }}
                                                    /> */}
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6} >
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="Segundo Nombre"
                                                        variant="outlined"
                                                        name="segundoNombre"
                                                        sx={{
                                                            width:'100%',
                                                            ...stylePecsaMoffin
                                                        }}
                                                        defaultValue={segundoNombre} onChange={(e)=>setSegundoNombre(e.target.value)}
                                                    />
                                                    {/* <TextField id="outlined-basic" label="Primer Nombre" variant="outlined" name="primerNombre" sx={{ width:'100%', ...stylePecsaMoffin }} defaultValue={primerNombre} onChange={(e)=>setPrimerNombre(e.target.value)} required/> */}
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6} >
                                                    <TextField id="outlined-basic" label="Primer Apellido" variant="outlined" name="primerApellido" sx={{ width:'100%', ...stylePecsaMoffin }} defaultValue={primerApellido} onChange={(e)=>setPrimerApellido(e.target.value)} required/>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6} >
                                                    <TextField id="outlined-basic" label="Segundo Apellido" variant="outlined" name="segundoApellido" sx={{ width:'100%', ...stylePecsaMoffin }} defaultValue={segundoApellido} onChange={(e)=>setSegundoApellido(e.target.value)} required/>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} >
                                                    <TextField id="outlined-basic" type='email' label="Correo Electrónico" variant="outlined" name="email" sx={{ width:'100%', ...stylePecsaMoffin }} defaultValue={email} onChange={(e)=>setEmail(e.target.value)} required/>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} >
                                                    <TextField
                                                        id="outlined-start-adornment"
                                                        label="Número Celular"
                                                        variant="outlined"
                                                        name="telefono"
                                                        sx={{ width:'100%', ...stylePecsaMoffin }}
                                                        defaultValue={telefono} onChange={(e)=>setTelefono(e.target.value)}
                                                        required
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">+52 </InputAdornment>,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} >
                                                    <Box>
                                                        <Button
                                                            variant="outlined"
                                                            onClick={crearForm}
                                                            sx={{
                                                                width:'100%',
                                                                p:{ xs:1, sm:1, md:1 },
                                                                borderColor: '#F5323F',
                                                                color: '#F5323F',
                                                                borderWidth:3,
                                                                mt:2,
                                                            }}
                                                            color="inherit"
                                                            disabled={checkDatosPersonales() === true ? false : true}
                                                        >
                                                            <b>Continuar</b>
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={5} sx={{ display:{ xs:'none', sm: 'block', md:'block' } }}>
                                        <Box>
                                            <Grid container spacing={{ xs:2, sm: 1, md:2 }}>
                                                <Grid item md={12}>
                                                    <Box
                                                        sx={{
                                                            pl:2,
                                                            pr:2,
                                                        }}
                                                    >
                                                        <Typography variant="body1" sx={{ color:'#f5323f' }}>
                                                            <b>Acerca de tu solicitud</b>
                                                        </Typography>
                                                        <Divider  />
                                                        <Typography sx={{ color:'#707070' }}>
                                                            Duración 5 minutos
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item md={12}>
                                                    <Box
                                                        sx={{
                                                            // backgroundColor:'red',
                                                            width:'100%',
                                                            display:'flex',
                                                            alignItems:'center',
                                                        }}
                                                    >
                                                        <Box
                                                            component='img'
                                                            src={todos}
                                                            sx={{
                                                                width:'78%',
                                                                m:'0 auto'
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                            ) : (
                                formCreado && !nipValidate && (
                                    <Grid container>
                                        <Grid item xs={12} sm={6} md={7}>
                                            <Box
                                                sx={{
                                                    minHeight:400,
                                                    color:'#707070',
                                                }}
                                            >

                                                <Grid container spacing={{ xs:2, sm: 1, md:2 }}>
                                                    <Grid item xs={12} sm={12} md={12} >
                                                        <Typography variant="h6" sx={{ color:'#f5323f' }}>
                                                            <b>Confirma tu código de registro</b>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} sx={{ mb:2 }}>
                                                        <Typography variant="bosy2" sx={{ color:'#707070', }}>
                                                            Te hemos enviado un código a tu correo y teléfono
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={5} sm={5} md={4} >
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="NIP"
                                                            placeholder="- - - -"
                                                            variant="outlined"
                                                            name="primerNombre"
                                                            sx={{
                                                                width:'100%',
                                                                ...stylePecsaMoffin
                                                            }}
                                                            inputProps={{

                                                                // maxLength:4,
                                                                style: {
                                                                    fontSize: 20,

                                                                },

                                                            }}
                                                            InputLabelProps={{
                                                                style: {
                                                                    fontSize: 20,

                                                                }
                                                            }}
                                                            type='number'

                                                            defaultValue={NIP} onChange={(e)=>setNIP(e.target.value)}

                                                        />
                                                    </Grid>
                                                    <Grid item xs={7} sm={7} md={8} >
                                                        <Box
                                                            sx={{
                                                                display:'flex',
                                                                alignItems:'center'
                                                            }}
                                                        >

                                                            <Button
                                                                variant="text"
                                                                sx={{
                                                                    color:'#f5323f',
                                                                    p:1.5,
                                                                }}
                                                                onClick={()=>sendNIP(formId)}
                                                            >
                                                                Volver a enviar código
                                                            </Button>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} >
                                                        <Box>
                                                            <Button
                                                                variant="outlined"
                                                                onClick={()=>validarNIP(NIP)}
                                                                sx={{
                                                                    width:'100%',
                                                                    p:{ xs:1, sm:1, md:1 },
                                                                    borderColor: '#F5323F',
                                                                    color: '#F5323F',
                                                                    borderWidth:3,
                                                                    mt:2,
                                                                }}
                                                                color="inherit"
                                                                disabled={checkNIP() === true ? false : true}
                                                            >
                                                                <b>Validar NIP</b>
                                                            </Button>
                                                        </Box>
                                                    </Grid>
                                                    {
                                                        openError && (
                                                            <Grid item xs={12} sm={12} md={12} >
                                                                <Box>
                                                                    <Alert onClose={handleCloseAlert} severity="error" >
                                                                        NIP incorrecto!
                                                                    </Alert>
                                                                </Box>
                                                            </Grid>
                                                        )
                                                    }                                                    
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={5} sx={{ display:{ xs:'none', sm: 'block', md:'block' } }}>
                                            <Box>
                                                <Grid container spacing={{ xs:2, sm: 1, md:2 }}>
                                                    <Grid item md={12}>
                                                        <Box
                                                            sx={{
                                                                pl:2,
                                                                pr:2,
                                                            }}
                                                        >
                                                            <Typography variant="body1" sx={{ color:'#f5323f' }}>
                                                                <b>¿Por que confirmar tu código de registro?</b>
                                                            </Typography>
                                                            <Divider  />
                                                            <Typography sx={{ color:'#707070' }}>
                                                                Confirma tu código nos ayuda a poder validar tu identidad.
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item md={12}>
                                                        <Box
                                                            sx={{
                                                                // backgroundColor:'red',
                                                                width:'100%',
                                                                display:'flex',
                                                                alignItems:'center',
                                                            }}
                                                        >
                                                            <Box
                                                                component='img'
                                                                src={todos}
                                                                sx={{
                                                                    width:'78%',
                                                                    m:'0 auto'
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )
                            )


                        )
                    }                    
                    {
                        activeStep === 1 && (
                            <Grid container>
                                <Grid item xs={12} sm={8} md={8}>
                                    <Box
                                        sx={{
                                            minHeight:{xs:1010, sm:900, md:680},
                                            color:'#707070',
                                        }}
                                    >

                                        <Grid container spacing={{ xs:2, sm: 2, md:2 }}>
                                            <Grid item xs={12} sm={12} md={12} >
                                                <Typography variant="h6" sx={{ color:'#f5323f' }}>
                                                    <b>Información del prestamo</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} >
                                                <TextField id="outlined-basic" type='number' label="Monto del prestamo" variant="outlined" name="montoDelPrestamo" sx={{ width:'100%', ...stylePecsaMoffin }} defaultValue={montoDelPrestamo} onChange={(e)=>setMontoDelPrestamo(e.target.value)} required/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} >
                                                <Typography variant="h6" sx={{ color:'#f5323f' }}>
                                                    <b>Datos generales</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} >
                                                <TextField id="outlined-basic" label="CURP" variant="outlined" name="CURP" sx={{ width:'100%', ...stylePecsaMoffin }} defaultValue={CURP} onChange={(e)=>setCURP((e.target.value).toUpperCase())} required/>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} >
                                                <Box
                                                    sx={{
                                                        display:'flex',
                                                        alignItems:'center'
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            //m:'0 auto',
                                                            display:'flex'
                                                        }}
                                                    >
                                                        <Typography variant="subtitle1">
                                                            ¿No conoces tu CURP?
                                                        </Typography>
                                                        <Link variant="subtitle1" onClick={reedirigirCURP} sx={{ color:'#f5323f' }}>
                                                            Consultalo aqui
                                                        </Link>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} >
                                                <TextField id="outlined-basic" label="Ingreso Mensual" variant="outlined" name="ingresoMensual" sx={{ width:'100%', ...stylePecsaMoffin }} defaultValue={ingresoMensual} onChange={(e)=>setIngresoMensual(e.target.value)} required/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} >
                                                <Typography variant="h6" sx={{ color:'#f5323f' }}>
                                                    <b>Domicilio</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} >
                                                <TextField id="outlined-basic" label="Calle" variant="outlined" name="calle" sx={{ width:'100%', ...stylePecsaMoffin }} defaultValue={calle} onChange={(e)=>setCalle(e.target.value)} required/>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={3} >
                                                <TextField id="outlined-basic" label="Número exterior" variant="outlined" name="numeroExterior" sx={{ width:'100%', ...stylePecsaMoffin }} defaultValue={numeroExterior} onChange={(e)=>setNumeroExterior(e.target.value)} required/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3} >
                                                <TextField id="outlined-basic" label="Número interior" variant="outlined" name="numeroInterior" sx={{ width:'100%', ...stylePecsaMoffin }} defaultValue={numeroInterior} onChange={(e)=>setNumeroInterior(e.target.value)} />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} >
                                                <TextField id="outlined-basic" label="Código postal" variant="outlined" name="codigoPostal" sx={{ width:'100%', ...stylePecsaMoffin }} defaultValue={codigoPostal} onChange={(e)=>setCodigoPostal(e.target.value)} required/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} >
                                                <Autocomplete
                                                    name="colonia"
                                                    value={valueColonia}
                                                    onChange={(event, newValue) => {
                                                        if (typeof newValue === 'string') {
                                                            setValueColonia({
                                                                neighborhood: newValue,
                                                            });
                                                        }
                                                        else if (newValue && newValue.inputValue) {
                                                            // Create a new value from the user input
                                                            setValueColonia({
                                                                neighborhood: newValue.inputValue,
                                                            });
                                                        }
                                                        else {
                                                            console.log('newValue', newValue);
                                                            setValueColonia(newValue);
                                                        }
                                                    }}
                                                    filterOptions={(options, params) => {
                                                        const filtered = filter(options, params);

                                                        const { inputValue } = params;
                                                        // Suggest the creation of a new value
                                                        const isExisting = options.some((option) => inputValue === option.neighborhood);
                                                        if (inputValue !== '' && !isExisting) {
                                                            filtered.push({
                                                                inputValue,
                                                                neighborhood: `Agregar "${inputValue}"`,
                                                            });
                                                        }

                                                        return filtered;
                                                    }}
                                                    selectOnFocus
                                                    clearOnBlur
                                                    handleHomeEndKeys
                                                    id="free-solo-with-text-demo"
                                                    options={colonias}
                                                    getOptionLabel={(option) => {
                                                        // Value selected with enter, right from the input
                                                        if (typeof option === 'string') {
                                                            return option;
                                                        }
                                                        // Add "xxx" option created dynamically
                                                        if (option.inputValue) {
                                                            return option.inputValue;
                                                        }
                                                        // Regular option
                                                        return option.neighborhood;
                                                    }}
                                                    renderOption={(props, option) => <li {...props}>{option.neighborhood}</li>}
                                                    sx={{
                                                        width: '100%',
                                                        "& label.Mui-focused": {
                                                            color: "#f5323f"
                                                        },
                                                        "& .MuiInput-underline:after": {
                                                            borderBottomColor: "#d9d9d9"
                                                        },
                                                        "& .MuiOutlinedInput-root": {
                                                            // '& fieldset': {
                                                            //   borderColor: 'black',
                                                            // },
                                                            "&:hover fieldset": {
                                                                borderColor: "#d9d9d9"
                                                            },
                                                            "&.Mui-focused fieldset": {
                                                                borderColor: "#d9d9d9"
                                                            }
                                                        }
                                                    }}
                                                    freeSolo
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Seleccionar o agregar colonia" required/>
                                                    )}
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} >
                                                {/* <TextField id="outlined-basic" label="Estado" variant="outlined" name="estado" sx={{ width:'100%', ...stylePecsaMoffin }} value={estado} onChange={(e)=>setEstado(e.target.value)} required/> */}
                                                <FormControl fullWidth 
                                                    sx={{
                                                        "& label.Mui-focused": {
                                                            color: "#f5323f"
                                                        },
                                                        "& .MuiInput-underline:after": {
                                                            borderBottomColor: "#d9d9d9"
                                                        },
                                                        "& .MuiOutlinedInput-root": {
                                                            // '& fieldset': {
                                                            //   borderColor: 'black',
                                                            // },
                                                            "&:hover fieldset": {
                                                                borderColor: "#d9d9d9"
                                                            },
                                                            "&.Mui-focused fieldset": {
                                                                borderColor: "#d9d9d9"
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={estado}
                                                        label="Estado"
                                                        onChange={handleChangeEstados}
                                                        sx={{
                                                            
                                                            
                                                        }}
                                                    >
                                                        {
                                                            estados.map((estado)=>(
                                                                <MenuItem value={estado.abrevia}>{estado.nombre}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} >
                                                <TextField id="outlined-basic" label="Delegación/Municipio" variant="outlined" name="municipio" sx={{ width:'100%', ...stylePecsaMoffin }} value={municipio} onChange={(e)=>setMunicipio(e.target.value)} required/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} >
                                                <Box>
                                                    <Button
                                                        variant="outlined"
                                                        onClick={updateForm}
                                                        sx={{
                                                            width:'100%',
                                                            p:{ xs:1, sm:1, md:1 },
                                                            borderColor: '#F5323F',
                                                            color: '#F5323F',
                                                            borderWidth:3,
                                                            mt:2,
                                                        }}
                                                        color="inherit"
                                                        disabled={checkDatosGenerales() === true ? false : true}
                                                    >
                                                        <b>Continuar</b>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} sx={{ display:{ xs:'none', sm: 'block', md:'block' } }}>
                                    <Box>
                                        <Grid container spacing={{ xs:2, sm: 1, md:2 }}>
                                            <Grid item md={12}>
                                                <Box
                                                    sx={{
                                                        pl:2,
                                                        pr:2,
                                                    }}
                                                >
                                                    <Typography variant="body1" sx={{ color:'#f5323f' }}>
                                                        <b>¿Por que pedimos esta información?</b>
                                                    </Typography>
                                                    <Divider  />
                                                    <Typography sx={{ color:'#707070' }}>
                                                        Usaremos esta informacion para evaluar tu aplicación y consultar tu historial crediticio a Buro de Crédito.
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item md={12}>
                                                <Box
                                                    sx={{
                                                        // backgroundColor:'red',
                                                        width:'100%',
                                                        display:'flex',
                                                        alignItems:'center',
                                                    }}
                                                >
                                                    <Box
                                                        component='img'
                                                        src={todos}
                                                        sx={{
                                                            width:'78%',
                                                            m:'0 auto'
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        )
                    }
                    {
                        activeStep === 2 && (
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Box
                                        sx={{
                                            minHeight:{ xs:1000, sm:580, md:400 },
                                            color:'#707070',
                                        }}
                                    >

                                        <Grid container spacing={{ xs:2, sm: 1, md:2 }}>
                                            <Grid item xs={12} sm={12} md={12} >
                                                <Typography variant="h6" sx={{ color:'#f5323f' }}>
                                                    <b>Autorización de consulta de historial crediticio</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} >
                                                <Typography variant="subtitle1" sx={{ color:'#707070' }}>
                                                    Por este conducto autorizo expresamente a PROYECTOS Y EDIFICACIONES CIVILES PECSA SA DE CV, para que por conducto de sus funcionarios facultados lleve a cabo Investigaciones, sobre mi comportamiento crediticio o el de la Empresa que represento en Trans Union de México, S. A. SIC y/o Dun & Bradstreet, S.A. SIC
                                                </Typography>
                                                <Typography variant="subtitle1" sx={{ color:'#707070' }}>
                                                    Así mismo, declaro que conozco la naturaleza y alcance de la información que se solicitará, del uso que PROYECTOS Y EDIFICACIONES CIVILES PECSA SA DE CV, hará de tal información y de que ésta podrá realizar consultas periódicas sobre mi historial o el de la empresa que represento, consintiendo que esta autorización se encuentre vigente por un período de 3 años contados a partir de su expedición y en todo caso durante el tiempo que se mantenga la relación jurídica.
                                                </Typography>
                                                <Typography variant="subtitle1" sx={{ color:'#707070' }}>
                                                    En caso de que la solicitante sea una Persona Moral, declaro bajo protesta de decir verdad Ser Representante Legal de la empresa mencionada en esta autorización; manifestando que a la fecha de firma de la presente autorización los poderes no me han sido revocados, limitados, ni modificados en forma alguna.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} >
                                                <Box>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={checked}
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    sx={{
                                                                        color:'#f5323f',
                                                                        '&.Mui-checked': {
                                                                            color:'#f5323f'
                                                                        },
                                                                    }}
                                                                />
                                                            }
                                                            label="Si esta de acuerdo, seleccione la casilla" />
                                                    </FormGroup>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} >

                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} >
                                                <Box>
                                                    <Button
                                                        variant="outlined"
                                                        onClick={submitForm}
                                                        sx={{
                                                            width:'100%',
                                                            p:{ xs:1, sm:1, md:1 },
                                                            borderColor: '#F5323F',
                                                            color: '#F5323F',
                                                            borderWidth:3,
                                                            mt:2,
                                                        }}
                                                        color="inherit"
                                                        disabled={checked === true ? loadingChecked === false ? false : true : true }
                                                    >
                                                        {
                                                            loadingChecked ? (
                                                                <b>Cargando...</b>
                                                            ) : (
                                                                <b>Continuar</b>
                                                            )
                                                        }
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        )
                    }
                    {
                        activeStep === 3 && (
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item xs={12} sm={6} md={12}>
                                    <Box
                                        sx={{
                                            minHeight:400,
                                            color:'#707070',
                                            textAlign:'center',

                                        }}
                                    >

                                        <Grid container spacing={{ xs:2, sm: 1, md:2 }}>
                                            <Grid item xs={12} sm={12} md={12} >
                                                <Typography variant="h6" sx={{ color:'#f5323f' }}>
                                                    <b>Muchas gracias por completar tu solicitud</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} >
                                                <Typography variant="subtitle1" sx={{ color:'#707070' }}>
                                                    Muy pronto te confirmaremos las oportunidades de financiamiento que tenemos para tí
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} >
                                                <Box
                                                    sx={{
                                                        //backgroundColor:'red',
                                                        width:'100%',
                                                        display:'flex',
                                                        alignItems:'center',
                                                    }}
                                                >
                                                    <Box
                                                        component='img'
                                                        src={gracias}
                                                        sx={{
                                                            width:{xs:'50%', sm:'40%', md:'40%'},
                                                            m:'0 auto'
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                            {/* <Grid item xs={12} sm={12} md={12} >
                                                <Typography variant="subtitle1" sx={{ color:'#f5323f' }}>
                                                    Agregar boton
                                                </Typography>
                                            </Grid> */}

                                            <Grid item xs={12} sm={12} md={12} >
                                                <Box>
                                                    <Button
                                                        variant="outlined"
                                                        onClick={()=>{ navigate('/'); }}
                                                        sx={{
                                                            width:{ xs:'100%', sm:'100%', md:'50%' },
                                                            p:{ xs:1, sm:1, md:1 },
                                                            borderColor: '#999999',
                                                            color: '#999999',
                                                            borderWidth:3,
                                                            mt:2,
                                                        }}
                                                        color="inherit"
                                                    >
                                                        <b>Volver a Home</b>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        )
                    }
                    {/* <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseAlert}>
                        <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
                            This is a success message!
                        </Alert>
                    </Snackbar> */}
                    <Box
                        sx={{
                            textAlign:'center',
                            mt:2,
                        }}
                    >
                        <Typography variant="body1" sx={{ color:'#F5323F' }}>
                            <b>Bancos con los que puedes Pre Autorizarte</b>
                        </Typography>
                        <Box>
                            <Grid 
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                {
                                    bancosPreautorizan.map((banco) => (
                                        <Grid item xs={4} sm={2} md={2} key={banco.nombre}>
                                            <Box
                                                sx={{
                                                    // backgroundColor:'red',
                                                    width:'100%',
                                                    height:100,
                                                    display:'flex',
                                                    alignItems:'center',
                                                    alignContent:'center',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        // backgroundColor:'yellow',
                                                        width:'80%',
                                                        maxHeight:'80%',
                                                        m:'0 auto',
                                                    }}
                                                >
                                                    <Box
                                                        component='img'
                                                        src={banco.imagen}
                                                        sx={{
                                                            maxHeight:80
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                        </Grid>
                                    ))
                                }
                                
                            </Grid>
                        </Box>
                    </Box>
                </Box>
                
            </Box>
        </Container>
    );
}

export { PreAutorizacion }