import { Box } from "@mui/material";
import { CustomTypography } from "../../../CustomTypography";

const TitleComparator = ({ children }) => {
    return(
    <Box
        sx={{
            backgroundColor:'#D9D9D9',
            width:'100%',
            height: { xs:'44px', sm:'40px', md:'54px' },
            borderRadius:'10px 10px 0 0',
            textAlign:'center',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            // fontSize: { xs:'1.6rem', sm:'1.6rem', md:'1.7rem' },
        }}
    >
        <CustomTypography variant="subheadingSecondary">
            { children }
        </CustomTypography>        
    </Box>
    );
}

export { TitleComparator }