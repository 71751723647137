import React, { useState } from "react";
import { Box } from "@mui/material";
import { CustomTypography } from "../CustomTypography";
import theme from "../../App/theme";

const Benefit = ({ title, description, img, direction }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleHoverLeave = () => {
    setIsHovered(false);
  };

  const commonBoxStyles = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: {
      xs: "10px 10px 0 0",
      md: `${direction ? "0 10px 10px 0" : "10px 0 0 10px"}`,
    },
    transition: "all .5s ease",
    transform: isHovered ? "scale(1.1)" : "scale(1)",
  };

  const overlayStyles = {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    borderRadius: {
      xs: "10px 10px 0 0",
      md: `${direction ? "0 10px 10px 0" : "10px 0 0 10px"}`,
    },
    background: {
      xs: "linear-gradient(360deg, rgba(250, 250, 250, 1) 10%, rgba(250, 250, 250, 0) 55%)",
      md: `${
        direction
          ? "linear-gradient(90deg, rgba(250, 250, 250, 1) 10%, rgba(250, 250, 250, 0) 55%)"
          : "linear-gradient(270deg, rgba(250, 250, 250, 1) 10%, rgba(250, 250, 250, 0) 55%)"
      }`,
    },
  };

  const boxStyles = {
    ...commonBoxStyles,
    position: "relative",
  };

  const imgContainerStyles = {
    position: "relative",
    overflow: "hidden",
    sx: { width: { xs: "100%", md: "60%" } },
    borderRadius: commonBoxStyles.borderRadius,
  };

  const textContainerStyles = {
    width: { xs: "100%", md: "40%" },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: { xs: 1.25, md: 2.5 },
    bgcolor: theme.palette.common.white,
    borderRadius: {
      xs: "0 0 10px 10px",
      md: `${direction ? "10px 0 0 10px" : "0 10px 10px 0"}`,
    },
  };

  return (
    <Box
      onMouseEnter={handleHover}
      onMouseLeave={handleHoverLeave}
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          md: `${direction ? "row-reverse" : "row"}`,
        },
        maxHeight: { md: "420px" },
        borderRadius: "10px",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.35)",
      }}
    >
      <Box {...imgContainerStyles}>
        <Box sx={boxStyles} component="div">
          <Box
            sx={{
              ...boxStyles,
              ...overlayStyles,
            }}
          />
          <img
            src={img}
            alt={`Imagen de beneficio: ${title}`}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: imgContainerStyles.borderRadius,
            }}
          />
        </Box>
      </Box>

      <Box sx={textContainerStyles}>
        <Box sx={{ padding: { xs: "20px 10px 0 10px", md: "0 20px" } }}>
          <CustomTypography
            variant="subheadingSecondary"
            txtColor={theme.palette.common.black}
          >
            {title}
          </CustomTypography>
        </Box>
        <Box sx={{ padding: { xs: "0 10px 20px 10px", md: "0 20px" } }}>
          <CustomTypography
            variant="textPrimary"
            txtColor={theme.palette.common.black}
          >
            {description}
          </CustomTypography>
        </Box>
      </Box>
    </Box>
  );
};

export { Benefit };
