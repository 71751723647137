import { Box, Grid, Typography } from "@mui/material";
import { getFormatoMoneda } from "../../../../../functions/getFormatoMoneda";
import { CustomButton } from "../../../../CustomButton";
import { CustomTypography } from "../../../../CustomTypography";
import { useNavigate } from "react-router-dom";
import { openNewTab } from "../../../../../functions/openNewTab";
import { FotosComponent } from "../../../../Fotos/FotosComponent";

const getUrlLote = (sku) =>{
	return sku.replace(/ /g, "_");
}

// const openNewTab = (url) =>{
// 	window.open(url, '_blank');
// }
const Summary = ({ nombre, precio, modelo, sku, fotos }) => {
	let navigate = useNavigate();

	return(
		<Box
			sx={{
				// backgroundColor:'#E0FFFF',
				
			}}
		>
			<Grid
				container
				direction="row"
				justifyContent="space-between"
				alignItems="flex-end"
				sx={{
					minHeight:{ xs:220, sm:150, md:150 },
					gap:{ xs:2, md:2 }
				}}
			>
				<Grid item xs={12} sm={12} md={12}>
					<Box
						sx={{
							// backgroundColor:'purple',
							width:'100%',
							height:{ xs:100, md:250}
						}}
					>
						<FotosComponent fotos={fotos} gallery={false}/>
					</Box>
				</Grid>
				<Grid item xs={12} sm={6} md={6}>
					<Box
						sx={{
							// backgroundColor:'#5F9EA0',
							minHeight:{ xs:55, sm:65, md:65 },
							display:'flex',
							alignItems:'flex-end'
						}}
					>
						<CustomTypography variant="textPrimary">
							{ nombre }
						</CustomTypography>
					</Box>
				</Grid>
				<Grid item xs={12} sm={5} md={5}>
					<Box
						sx={{
							// backgroundColor:'#5F9EA0',
						}}
					>
						<CustomTypography variant="textPrimary">
							Modelo {modelo}
						</CustomTypography>
					</Box>
				</Grid>
				<Grid item xs={12} sm={12} md={12}>
					<Box
						sx={{
							// backgroundColor:'#5F9EA0',
							textAlign:'center',
						}}
					>
						<CustomTypography variant="subheadingSecondary">
							{/* <b>$1,543,258<span style={{ fontSize:'0.9rem' }}>MXN</span></b> */}							
							{ getFormatoMoneda(precio) }<span><CustomTypography variant="textTertiaryBold">MXN</CustomTypography></span>
						</CustomTypography>
					</Box>
				</Grid>				
				<Grid item xs={12} sm={12} md={12}>
					<Box
						sx={{
							// backgroundColor:'#5F9EA0',
						}}
					>
						<CustomButton 
							text={"Quiero Invertir"} 
							variant="contained" 
							color="primary" 
							size="small"
							onClick={()=>openNewTab(`/tienda/${getUrlLote(sku)}`)}
						/>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

export { Summary }