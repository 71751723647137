import axios from "axios";
import downloadFile from "./downloadFile";
import { handleDownload } from "./downloadPDF";

const consumirApiDeDescarga = (apiUrl, fileName, handleLoading) => {
  axios.get(apiUrl,{
    responseType: 'blob' // Indica a Axios que esperas un blob (archivo) como respuesta
  })
  .then((response)=>{
    handleLoading(false);
    const contentType = response.headers['content-type'];

    if (contentType === 'text/plain; charset=utf-8') {
      response.data.text().then((jsonData) => {
        const parsedData = JSON.parse(jsonData);
        handleDownload(parsedData[0].url, fileName);
      }).catch((error) => {
        console.error('Error al procesar datos JSON:', error);
      });
    } else if (contentType === 'application/pdf') {
      downloadFile(response.data, fileName);
    } else {
      console.log('Tipo de archivo no compatible:', contentType);
    }
  })
  .catch((error)=>{
    handleLoading(false);
    return { error }
  });
}

export default consumirApiDeDescarga;