import { CardActionArea, Grid, Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

//import m106 from '../assets/casas/m106.png';
import m106 from '../../assets/casas/m106.png';
import m122_a from '../../assets/casas/m122_a.png';
import m122_b from '../../assets/casas/m122_b.png';
import m146_1 from '../../assets/casas/m146_1.png';
import m146_6 from '../../assets/casas/m146_6.png';
import { getLoteConPrecioMasBajo } from "../../functions/getLoteConPrecioMasBajo";



const ModeloItem = ({ modelo }) => {
    // console.log('ModeloItem', modelo);
    //const [modelo, setModelo] = useState(props.modelo);
    let navigate = useNavigate();
    const getFormatoMoneda = (cantidad) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD', //MXN
            minimumFractionDigits: 0
          })
      
          var value = 10000
      
          return formatter.format(cantidad)
    }
    return(
        <React.Fragment>
            <Card
                sx={{
                    width:'100%',
                    display: { xs: 'none', md: 'block' },
                    // m:1,
                    borderRadius: '16px',
                    //boxShadow: 3,
                    // boxShadow: '0 10px 8px 0 rgba(0, 0, 0, 0.1), 0 2px 20px 0 rgba(0, 0, 0, 0.19)',
                    // boxShadow: '0 15px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 20px 0 rgba(0, 0, 0, 0.19)',
                    // boxShadow:' 2px 7px 11px 0px rgba(0, 0, 0, 0.1), 2px 7px 11px 0px rgba(0, 0, 0, 0.19)',
                    // boxShadow:' 2px 7px 11px 0px rgba(0, 0, 0, 0.1), 2px 7px 11px 0px rgba(0, 0, 0, 0.19)',
                    boxShadow: '0px 5px 12px 0px rgb(0, 0, 0, 0.19)',
                    mt:5,
                    mb:5,
                }}
            >
                <CardActionArea
                    onClick={() => navigate(`/modelo/${modelo.nombre}`, {state:{modelo:modelo}})}
                >
                    {
                        modelo.fachadas.length > 0 ? (
                            // <h1>hay fachadas</h1>
                            modelo.fachadas[0].fotos.length > 0 ? (
                                <CardMedia
                                    component="img"
                                    height="360"
                                    image={modelo.fachadas[0].fotos[0].url}
                                    alt="casas pecsa"
                                />
                            ) : (
                                <Skeleton variant="rectangular" width={'100%'} height={360} />    
                            )
                        ) : (
                            <Skeleton variant="rectangular" width={'100%'} height={360} />
                        )
                    }
                    <CardContent>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                            //spacing={2}
                            sx={{
                                color:'#707070'
                            }}
                        >
                            <Typography gutterBottom variant="h5" component="div">
                                Modelo <b>{modelo.nombre}</b>
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid item sm={12} md={12}>
                                    <Stack direction="column"
                                        justifyContent="center"
                                        alignItems="flex-start"
                                        sx={{
                                            // marginLeft:1
                                        }}
                                    >
                                        <Typography  variant="body1">
                                            {modelo.superficieDeConstruccion} m² de construcción
                                        </Typography>
                                        <Typography variant="caption">
                                            Desde:
                                        </Typography>
                                        <Typography variant="body1">
                                            {/* <b>{getFormatoMoneda(modelo.precio)}</b> */}
                                            <b>
                                            {
                                                getFormatoMoneda(getLoteConPrecioMasBajo(modelo.fachadas
                                                    .map((fachada) => fachada.lotes)
                                                    .flat()
                                                ).precio)
                                            }
                                            </b>
                                        </Typography>                                        
                                    </Stack>
                                </Grid>
                                {/* <Grid item sm={4} md={4}>
                                    <Button size="small" endIcon={<ArrowForwardIcon />} sx={{ color:'#f5323f', fontSize:10 }}>
                                        VER MÁS
                                    </Button>
                                </Grid> */}
                                <Grid item xs={12}>
                                    <Typography variant="caption">
                                        *Los precios pueden variar por fraccionamiento
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Stack>
                    </CardContent>
                    <CardActions>
                        <Box
                            sx={{ 
                                // backgroundColor:'green',
                                width:'100%',
                                textAlign:'center',
                            }}
                        >
                            <Button 
                                size="small" 
                                endIcon={<ArrowForwardIcon />} 
                                sx={{ color:'#f5323f', textTransform: 'none'  }}
                                //onClick={() => navigate(`/modelo/${modelo.nombre}`, {state:{modelo:modelo}})}
                            >
                                <b>Ver más</b>
                            </Button>
                        </Box>
                    </CardActions>
                </CardActionArea>                    
            </Card>
            <Card
                sx={{
                    width:'100%',
                    display: { xs: 'flex', md: 'none' },
                    m:1,
                    borderRadius: '16px',
                    // boxShadow: 8,
                    // boxShadow: '0 10px 5px 0 rgba(0, 0, 0, 0.12), 0 4px 10px 0 rgba(0, 0, 0, 0.19)',
                    boxShadow: '0px 5px 12px 0px rgb(0, 0, 0, 0.19)',
                    mt:2,
                    ml:2,
                    mb:3,
                }}
            >
                <CardActionArea
                    onClick={() => navigate(`/modelo/${modelo.nombre}`, {state:{modelo:modelo}})}
                >
                    {
                        modelo.fachadas.length > 0 ? (
                            // <h1>hay fachadas</h1>
                            modelo.fachadas[0].fotos.length > 0 ? (
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image={modelo.fachadas[0].fotos[0].url}
                                    alt="casas pecsa"
                                />
                            ) : (
                                <Skeleton variant="rectangular" width={'100%'} height={200} />    
                            )
                        ) : (
                            <Skeleton variant="rectangular" width={'100%'} height={200} />
                        )
                    }
                    {/* <CardMedia
                        component="img"
                        height="200"
                        image={m106}
                        alt="casas pecsa"
                    /> */}
                    <CardContent>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                            //spacing={2}
                            sx={{
                                color:'#707070'
                            }}
                        >
                            <Typography gutterBottom variant="h6" component="div">
                                {modelo.nombre}
                            </Typography>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Stack direction="column"
                                        justifyContent="center"
                                        alignItems="flex-start"
                                        sx={{
                                            marginLeft:1
                                        }}
                                    >
                                        <Typography  variant="body1">
                                            {modelo.superficieDeConstruccion} m² de construcción
                                        </Typography>
                                        <Typography variant="caption">
                                            Desde:
                                        </Typography>
                                        <Typography variant="body1">
                                            {/* {getFormatoMoneda(modelo.precio)} */}
                                            {
                                                getFormatoMoneda(getLoteConPrecioMasBajo(modelo.fachadas
                                                    .map((fachada) => fachada.lotes)
                                                    .flat()
                                                ).precio)
                                            }
                                        </Typography>
                                        <Typography variant="caption">
                                            *Los precios pueden variar por fraccionamiento
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    </CardContent>
                </CardActionArea>
            </Card>
        </React.Fragment>
    );
};

export { ModeloItem };