import { useNavigate } from "react-router-dom";
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Container, FormControl, FormControlLabel, Grid, InputAdornment, InputLabel, MenuItem, Select, Switch, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useEffect, useState } from "react";
import axios from "axios";

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const MenuLotes = ({ manzana }) => {
    let navigate = useNavigate();
    console.log({ manzana });
    const [listaEstatus, setListaEstatus] = useState([]);
    const [listaPromociones, setListaPromociones] = useState([]);

    const disabledLote = (e) => {
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id'); 

        axios.post(`${process.env.REACT_APP_API_URL}/api/lote/update`, 
            {
                id: e.target.value,
                disponible: e.target.checked,
            },
            {
                headers: { 
                    Authorization: `Bearer ${token}`,
                }              
            }
        )
        .then((response) => {
            // handle success            
            console.log(response);
            console.log('success axio');
            //navigate(-1, { replace: true });
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sabe');
        });
    }

    const changeEstatusLote = (e, idLote) => {
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id'); 

        axios.post(`${process.env.REACT_APP_API_URL}/api/lote/update`, 
            {
                id: idLote,
                idEstatus: e.target.value,
            },
            {
                headers: { 
                    Authorization: `Bearer ${token}`,
                }              
            }
        )
        .then((response) => {
            // handle success            
            console.log(response);
            console.log('success axio');
            //navigate(-1, { replace: true });
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sabe');
        });
    }

    const changePromocionLote = (e, idLote) => {
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id'); 

        axios.post(`${process.env.REACT_APP_API_URL}/api/lote/update`, 
            {
                id: idLote,
                idPromocion: e.target.value,
            },
            {
                headers: { 
                    Authorization: `Bearer ${token}`,
                }              
            }
        )
        .then((response) => {
            // handle success            
            console.log(response);
            console.log('success axio');
            //navigate(-1, { replace: true });
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sabe');
        });
    }

    const changePrecioLote = (value, idLote) => {
        // console.log(value, idLote);
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id'); 

        axios.post(`${process.env.REACT_APP_API_URL}/api/lote/update`, 
            {
                id: idLote,
                precio: value,
            },
            {
                headers: { 
                    Authorization: `Bearer ${token}`,
                }              
            }
        )
        .then((response) => {
            // handle success            
            console.log(response);
            console.log('success axio');
            //navigate(-1, { replace: true });
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sabe');
        });
    }

    const [isChecked, setIsChecked] = useState(() =>
        manzana.lotes.map((lote) => lote.disponible)
    );
    const [estatusLote, setEstatusLote] = useState(() =>
        manzana.lotes.map((lote) => lote.idEstatus)
    );
    const [promocionLote, setPromocionLote] = useState(() =>
        manzana.lotes.map((lote) => lote.idPromocion)
    );
    const [precioLote, setPrecioLote] = useState(() =>
        manzana.lotes.map((lote) => lote.precio)
    );
    const handleChange = (lote) => (event) => {
        console.log(lote.id, event.target.value);
    }
    const changeEstatusLoteFront = (index, e, lote) => {
        //console.log(index, e.target, lote); return;
        setEstatusLote((estatus) => {
          return estatus.map((c, i) => {
            if (i === index) return e.target.value;
            return c;
          });
        });
        changeEstatusLote(e, lote.id);
        // if(e.target.checked){
        //     console.log('se mostrara disponible', e.target.value);
        // }
        // else{
        //     console.log('se mostrara No disponible', e.target.value);
        // }
    };
    const isCheckboxChecked = (index, e) => {
        setIsChecked((isChecked) => {
          return isChecked.map((c, i) => {
            if (i === index) return e.target.checked;
            return c;
          });
        });
        disabledLote(e)
        if(e.target.checked){
            console.log('se mostrara disponible', e.target.value);
        }
        else{
            console.log('se mostrara No disponible', e.target.value);
        }
    };
    const changePromocionLoteFront = (index, e, lote) => {
        //console.log(index, e.target, lote); return;
        setPromocionLote((estatus) => {
          return estatus.map((c, i) => {
            if (i === index) return e.target.value;
            return c;
          });
        });
        changePromocionLote(e, lote.id);
        // if(e.target.checked){
        //     console.log('se mostrara disponible', e.target.value);
        // }
        // else{
        //     console.log('se mostrara No disponible', e.target.value);
        // }
    };
    const changePrecioLoteFront = (index, e) => {
        // console.log('PRECIO A CAMBIAR',index, e.target, lote); return;
        setPrecioLote((estatus) => {
          return estatus.map((c, i) => {
            if (i === index) return e.target.value;
            return c;
          });
        });
        // changePromocionLote(e, lote.id);
        // if(e.target.checked){
        //     console.log('se mostrara disponible', e.target.value);
        // }
        // else{
        //     console.log('se mostrara No disponible', e.target.value);
        // }
    };
    useEffect(() => {
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        

        axios.get(`${process.env.REACT_APP_API_URL}/api/estatus`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((response) => {
            // handle success
            console.log(response);
            setListaEstatus(response.data);
        })
        .catch((error) => {
            // handle success
            console.log(error);
            navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sae');
        });
        axios.get(`${process.env.REACT_APP_API_URL}/api/promocion/only-active`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((response) => {
            // handle success
            console.log(response);
            setListaPromociones(response.data);
        })
        .catch((error) => {
            // handle success
            console.log(error);
            navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sae');
        });
    }, []);
    return(
        <Container sx={{ backgroundColor:'white' }}>
             
            <Box sx={{ /*backgroundColor:'yellow'*/ }}>                
                <h1>Lotes</h1>                                 
            </Box>
            <Box sx={{ /*backgroundColor:'yellow'*/ }}>                
                <Grid container 
                    direction="row" 
                    spacing={{ xs: 2, md: 3 }} 
                    columns={{ xs: 12, sm: 12, md: 12, lg:12 }}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                        <Card>
                            <CardActionArea onClick={()=>{ navigate('add', {state:{manzana:manzana}}) }}>
                                <CardContent>
                                    <AddCircleOutlineIcon
                                        sx={{
                                            fontSize:100,
                                            color: "text.secondary"
                                        }}
                                    />
                                    <Typography color="text.secondary" gutterBottom variant="h5" component="div">
                                        Agregar Lote
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    {
                        manzana.lotes.map((lote, index) => ( 
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <Card>
                                    {/* <CardActionArea onClick={() => { navigate(`/${lote.folio}`) }}> */}
                                    <CardActionArea onClick={() => { navigate(`${lote.folio}`) }}>
                                        <CardContent>
                                            {
                                                lote.fotos.length > 0 ? (
                                                    
                                                    <CardMedia
                                                        component="img"
                                                        height="240"
                                                        image={lote.fotos[0].url}
                                                        alt="green iguana"
                                                        // src={lote.fotos[0].url}
                                                    />
                                                ) : (
                                                    <CardMedia
                                                        component="img"
                                                        height="240"
                                                        image="/static/images/cards/contemplative-reptile.jpg"
                                                        alt="green iguana"
                                                    />
                                                )
                                            }                                            
                                            <Typography gutterBottom variant="body1" component="div">
                                                Lote:
                                            </Typography>
                                            <Typography gutterBottom variant="h5" component="div">
                                                { lote.numeroDeLote}
                                            </Typography>                                            
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <Box
                                            sx={{ width:'100%', }}
                                        >
                                            <Grid 
                                                container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >                                                
                                                <Grid item md={6}>
                                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                                        <InputLabel id="demo-select-small">Estatus</InputLabel>
                                                        <Select
                                                            labelId="demo-select-small"
                                                            id="demo-select-small"
                                                            // value={lote.idEstatus}
                                                            value={estatusLote[index]}
                                                            label="Estatus"
                                                            onChange={(e)=>{ changeEstatusLoteFront(index, e, lote); }}
                                                        >
                                                            {/* <MenuItem value="">
                                                            <em>None</em>
                                                            </MenuItem> */}
                                                            {
                                                                listaEstatus.map((itemEstatus)=> (
                                                                    <MenuItem value={itemEstatus.id}>{itemEstatus.nombre}</MenuItem>        
                                                                ))
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item md={6}>
                                                    <FormControlLabel
                                                        // value="start"
                                                        control={
                                                            <Switch 
                                                                color="success" 
                                                                {...label} 
                                                                checked={isChecked[index]}
                                                                value={lote.id}
                                                                onChange={(e)=>{ isCheckboxChecked(index, e); }}
                                                            />
                                                        }
                                                        label="Disponible"
                                                        labelPlacement="start"
                                                    />
                                                    {/* <Switch 
                                                        color="success" 
                                                        {...label} 
                                                        checked={isChecked[index]}
                                                        value={lote.id}
                                                        onChange={(e)=>{ isCheckboxChecked(index, e); }}
                                                    /> */}
                                                </Grid>
                                                <Grid item md={12}>
                                                    <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
                                                        <InputLabel id="demo-select-small">Promocion</InputLabel>
                                                        <Select
                                                            labelId="demo-select-small"
                                                            id="demo-select-small"
                                                            // value={lote.idEstatus}
                                                            value={promocionLote[index]}
                                                            label="Promocion"
                                                            onChange={(e)=>{ changePromocionLoteFront(index, e, lote); }}
                                                        >
                                                            {/* <MenuItem value="">
                                                            <em>None</em>
                                                            </MenuItem> */}
                                                            {
                                                                listaPromociones.map((itemPromocion)=> (
                                                                    <MenuItem value={itemPromocion.id}>{itemPromocion.nombre}</MenuItem>        
                                                                ))
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item md={12}>
                                                    <Box
                                                        sx={{
                                                            // backgroundColor:'pink',
                                                            display:"flex",
                                                            alignItems:'center',
                                                            height:60,
                                                        }}
                                                    >
                                                        <TextField
                                                            label="Precio"
                                                            id="outlined-start-adornment"
                                                            // sx={{ mt: 1, /*width: '25ch'*/ }}
                                                            type='number'
                                                            size="small"
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                                // endAdornment: tipoDescuento === 2 ? (<InputAdornment position="end">%</InputAdornment>) : null,
                                                            }}
                                                            defaultValue={precioLote[index]}
                                                            onChange={(e) => changePrecioLoteFront(index, e)}
                                                            name="descuento"
                                                        />
                                                        <Button 
                                                            variant="contained" 
                                                            size="small"
                                                            sx={{
                                                                textTransform:'none',
                                                                height:'80%',
                                                                ml:1,
                                                            }}
                                                            onClick={()=>changePrecioLote(precioLote[index], lote.id)}
                                                        >
                                                            Cambiar <br/>precio
                                                        </Button>
                                                    </Box>
                                                    
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </CardActions>
                                    
                                </Card>
                            </Grid>
                        ))
                    }
                    
                </Grid>                                
            </Box>
            {/* {
                productos.length < 1 ? <div><h1>nada</h1></div>:
                productos.map((producto) => ( 
                    <h2>{producto.name}</h2>
                ))
            } */}
        </Container>
    );
}

export { MenuLotes }