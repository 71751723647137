import * as React from 'react';
import { useEffect, useState } from "react";
import { Avatar, Button, Container, Divider, Grid, Stack } from "@mui/material";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import m146 from '../../assets/bosques_logo.png';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useNavigate } from 'react-router-dom';

const DatosModelo = (props) => {
    let navigate = useNavigate();
    const[fraccionamiento, setFraccionamiento] = useState(props.datos);
    //const fraccionamiento = props.datos;
    //console.log(fraccionamiento);
    useEffect(() => {
        setFraccionamiento(props.datos);
    }, [setFraccionamiento]);
    return(
        <Container sx={{ backgroundColor:'white' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" variant="middle" flexItem />}
                        spacing={2}
                        
                    >
                        <Box
                            component="img"
                            src={fraccionamiento.logo}
                            sx={{ 
                                width: 500,
                                backgroundColor: 'white',
                                borderRadius: '16px',
                                m:3,
                                boxShadow: 3
                            }}
                        />
                        <Box
                            sx={{ 
                                backgroundColor: 'white',
                                p:3,
                                display: 'block'
                            }}
                        >
                            <Typography gutterBottom variant="h4" component="div" align='left'>
                                {fraccionamiento.nombre}
                            </Typography>
                            <Typography variant="h6" component="div" color="text.secondary" align='left'>
                                Folio: {fraccionamiento.folio}
                            </Typography>
                            <Typography variant="h6" component="div" color="text.secondary" align='left'>
                                <LocationOnIcon/> Saltillo, Coahuila
                            </Typography>
                            <Box>
                                <Button 
                                    variant="outlined" 
                                    startIcon={<EditIcon />}
                                    sx={{ 
                                        width: '100%',
                                        marginTop: 2
                                    }}
                                    onClick={ () => { navigate('edit') } }
                                >
                                    Editar
                                </Button>
                                <Button 
                                    variant="outlined" 
                                    startIcon={<DeleteIcon />}
                                    sx={{ 
                                        width: '100%',
                                        marginTop: 1
                                    }}
                                >
                                    Borrar
                                </Button>
                            </Box>                            
                        </Box>                        
                    </Stack>
                </Grid>
            </Grid>
        </Container>
    );
}

export { DatosModelo }