import { Avatar, Backdrop, Box, Button, Chip, Grid, IconButton, List, ListItem, TextField, Typography } from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { InlineWidget, PopupModal, PopupWidget, useCalendlyEventListener } from "react-calendly";
import moment from 'moment';
import { useEffect, useState } from "react";
import axios from "axios";
// import 'moment/locale/es';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// const fraccionamientos = [
//     {
//         id:1,
//         nombre:'Lomas del Morillo Residencial',
//         color: '#098D00',
//         hover: '#076600',
//         urlName: 'fracc-lomas-del-morillo',
//     },
//     {
//         id:2,
//         nombre:'El Rocal Residencial',
//         color: '#ABA598',
//         hover: '#989081',
//         urlName: 'fracc-rocal-residencial',
//     },
//     {
//         id:1007,
//         nombre:'Bosques De Las Lomas Residencia',
//         color: '#996633',
//         hover: '#734d26',
//         urlName: 'bosquesdelaslomas',
//     },
//     //fracc-altavista-residencial
// ];
const horas = [
    {
        mostrar:'11:00',
        interno:{
        	horaInicio:'11:00:00',
        	horaFinal:'12:00:00',
        },
    }, 
    {
        mostrar:'12:00',
        interno:{
        	horaInicio:'12:00:00',
        	horaFinal:'13:00:00',
        },
    }, 
    {
        mostrar:'13:00',
        interno:{
        	horaInicio:'13:00:00',
        	horaFinal:'14:00:00',
        },
    }, 
    {
        mostrar:'14:00',
        interno:{
        	horaInicio:'14:00:00',
        	horaFinal:'15:00:00',
        },
    }, 
    {
        mostrar:'15:00',
        interno:{
        	horaInicio:'15:00:00',
        	horaFinal:'16:00:00',
        },
    }, 
    {
        mostrar:'16:00',
        interno:{
        	horaInicio:'16:00:00',
        	horaFinal:'17:00:00',
        },
    }, 
    {
        mostrar:'17:00',
        interno:{
        	horaInicio:'17:00:00',
        	horaFinal:'18:00:00',
        },
    }, 
    {
        mostrar:'18:00',
        interno:{
        	horaInicio:'18:00:00',
        	horaFinal:'19:00:00',
        },
    }, 
    {
        mostrar:'19:00',
        interno:{
        	horaInicio:'19:00:00',
        	horaFinal:'20:00:00'
        },
    },
]

const CalendarioDesktop = ({ asesor }) => {
    // console.log('asesor::', asesor.citas);
    const [fraccionamientos, setFraccionamientos] = useState([]);
    
    const [fecha, setFecha] = useState(null);
    const [horaSelected, setHoraSelected] = useState(null);
    const [fraccionamientoSelected, setFraccionamientoSelected] = useState(null);

    const mnostar = () => {
        // console.log(moment().format('DD-MM-YYYY HH:mm:ss'));
        console.log('fecha', fecha.format('DD-MM-YYYY'));
        console.log('fraccionamientoSelected', fraccionamientoSelected);
        console.log('horaSelected', horaSelected);
    }

    const getHoraSelected = (hora) => {        
        setHoraSelected(hora);
    }

    const [verHorario, setVerHorario] = useState(false);

    const getVerHorario = (fraccionamiento) => {
        setVerHorario(!verHorario);
        setFraccionamientoSelected(fraccionamiento)
    }

    const [llenarForm, setLlenarForm] = useState(false);

    const getLlenarForm = () => {
        setLlenarForm(!llenarForm);
    }

    const renderAlgo = (date, selectedDates, pickersDayProps) => {
        // console.log(date)
        const dia = asesor.horario.find((element)=>date.format('DD-MM-YYYY') === moment(element.fecha, 'YYYY-MM-DD').format('DD-MM-YYYY') && moment(element.fecha, 'YYYY-MM-DD').format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') );
        if(dia){
            if(moment(dia.fecha, 'YYYY-MM-DD').format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')){
                console.log(moment(dia.fecha, 'YYYY-MM-DD').format('YYYY-MM-DD'), ' ---- ', moment().format('YYYY-MM-DD'))
            }
            else{
                console.log(moment(dia.fecha, 'YYYY-MM-DD').format('YYYY-MM-DD'), ' --c-- ', moment().format('YYYY-MM-DD'))
            }
            if(fraccionamientos.length > 0){
                const fraccionamiento = fraccionamientos.find((fracc) => fracc.idFraccionamiento === dia.fraccionamiento.id);

                return (
                    <PickersDay
                        color="error" 
                        sx={{ 
                            backgroundColor:fraccionamiento.color,
                            fontWeight:'bold',
                            // color:'#262626',
                            color:'white',
                            '&:hover, &:focus': {
                                backgroundColor: fraccionamiento.hover,
                                color:'white',
                            },              
                        }} 
                        onClick={()=>getVerHorario(fraccionamiento)}
                        {...pickersDayProps} 
                    />
                ); 
            }           
        }
        return (
            <PickersDay 
                sx={{ 
                    color:'red', 
                }} 
                {...pickersDayProps} 
            />
        );
        
    }
    const addCita = () => {
        // const pruaba = {
        //     idAsesor:asesor.id,
        //     fecha: fecha.format('YYYY-MM-DD'),
        //     idFraccionamiento: fraccionamientoSelected.idFraccionamiento,
        //     ...horaSelected
        // }
        axios.post(`${process.env.REACT_APP_API_URL}/api/asesores/create-cita`,
            {
                idAsesor:asesor.id,
                fecha: fecha.format('YYYY-MM-DD'),
                idFraccionamiento: fraccionamientoSelected.idFraccionamiento,
                ...horaSelected
            }
        )
        .then((response) => {
            // handle success
            console.log(response);
            console.log('..::Cita creada con exito::..');
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            // console.log('sae');
        });
    }
    const restarAgendar = () => {
        setFecha(null);
        setHoraSelected(null);
        setFraccionamientoSelected(null);
    }
    useCalendlyEventListener({
        onProfilePageViewed: () => console.log("onProfilePageViewed"),
        onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
        // onEventTypeViewed: () => console.log("onEventTypeViewed"),
        onEventTypeViewed: () => {
            // getVerHorario();
            // getLlenarForm();
            restarAgendar();
        },
        onEventScheduled: (e) => {
            console.log('a veeeeer::___::::')
            addCita();
            console.log('a veeeeer::___::::', e.data.payload)
        }
    });
    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_API_URL}/api/fraccionamiento/datos-extra`)
        .then((response) => {
            // handle success
            // console.log(response);
            setFraccionamientos(response.data);
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            // console.log('sae');
        });
    },[asesor]);
    return(
        <Box
            sx={{
                // backgroundColor:'yellow',
                width:{ xs:'30%', sm:'30%', md:'30%'},
                height:500,
                position:'sticky',
                top: 87,
                display:{xs:'none', sm:'block', md:'block',},
                pt:2,
                zIndex:2,
            }}
        >
            {
                !fecha ? (
                    <Box>
                        <Box
                            sx={{
                                textAlign:'center',
                                color:'#707070'
                            }}
                        >
                            <Typography variant="body1">
                                <b>Selecciona el día y el fraccionamiento al que deseas acudir..</b>
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                // backgroundColor:'red',
                                width:'100%',
                            }}
                        >
                            {
                                fraccionamientos.length > 0 && (
                                    fraccionamientos.map((fraccionamiento) => {
                                        if(asesor.horario.find((guardia)=>guardia.fraccionamiento.id === fraccionamiento.idFraccionamiento)){
                                            return(
                                                <Chip
                                                    avatar={<Avatar alt={fraccionamiento.fraccionamiento.nombre} src={fraccionamiento.fraccionamiento.logo} />}
                                                    label={<b>{ fraccionamiento.fraccionamiento.nombre }</b>}
                                                    variant="outlined"
                                                    sx={{
                                                        mt:0.5,
                                                        color: fraccionamiento.color,
                                                        borderColor:fraccionamiento.color,
                                                        '&:hover':{
                                                            color: fraccionamiento.hover,
                                                            borderColor:fraccionamiento.hover,
                                                        }
                                                    }}
                                                />
                                            )
                                        }
                                    })
                                )
                            }                            
                        </Box>
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
                            <StaticDatePicker
                                displayStaticWrapperAs="desktop"
                                openTo="day"
                                // views={['day', 'month']}        
                                showDaysOutsideCurrentMonth                
                                disablePast
                                value={fecha}
                                onChange={(newValue) => {
                                    setFecha(newValue);
                                }}
                                shouldDisableDate={(day) =>{
                                    // console.log(day.format('DD-MM-YYYY'));
                                    // console.log(horario.find((element)=>
                                    //   day.format('DD-MM-YYYY')===moment(element.fecha).format('DD-MM-YYYY') && element.idFraccionamiento === 1
                                    // ));
                                    // console.log(horario.map((element)=>element.fecha));
                                    // if(horario.find((element)=>day.format('DD-MM-YYYY') === moment(element.fecha).format('DD-MM-YYYY') && element.idFraccionamiento === 1)){
                                    // const hres = horario.find((element)=>day.format('DD-MM-YYYY') === moment(element.fecha).format('DD-MM-YYYY'))
                                    const hres = asesor.horario.find((element)=>{
                                        if(day.format('DD-MM-YYYY') === moment(element.fecha, 'YYYY-MM-DD').format('DD-MM-YYYY')){
                                            // console.log('dia',day.format('DD-MM-YYYY'))
                                            // console.log('element',moment(element.fecha).format('DD-MM-YYYY'))
                                            return element
                                        }                        
                                    })
                                    if(hres){
                                        // console.log('',hres)
                                        return false;
                                    }else{
                                        return true;
                                    }                    
                                }}
                                renderDay={renderAlgo}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Box>
                ) : (
                    <Box>
                        {
                            !horaSelected ? (
                                <Box>
                                    <Box
                                        sx={{
                                            // backgroundColor:'red',
                                            // textAlign:'center',
                                            color:'#707070',
                                            display:'flex',
                                        }}
                                    >
                                        <Grid container>
                                            <Grid item xs={2} sm={2} md={1}>
                                                <IconButton aria-label="delete" onClick={restarAgendar}>
                                                    <ArrowBackIcon />
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={10} sm={10} md={10}>
                                                <Box
                                                    sx={{
                                                        // textAlign:'center',
                                                        display:'flex',
                                                        alignItems:'center',
                                                        alignContent:'center',
                                                    }}
                                                >
                                                    <Typography variant="body1" sx={{ m:'0 auto', mt:1, }}>
                                                        <b>Seleccione el Horario</b>
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                                
                                                
                                    </Box>
                                    <List>
                                        {
                                            horas.map((hora)=>{
                                                console.log('time::', fecha.format('DD-MM-YYYY'));
                                                // if(!asesor.citas.find((cita) => moment(cita.fecha).format('DD-MM-YYYY') === fecha.format('DD-MM-YYYY') && (cita.horaInicio === moment(hora.interno.horaInicio, 'hh:mm:ss').format('hh:mm:ss') || cita.horaFinal === moment(hora.interno.horaInicio, 'hh:mm:ss').format('hh:mm:ss')))){
                                                if(!asesor.citas.find((cita) => moment(cita.fecha, 'YYYY-MM-DD').format('DD-MM-YYYY') === fecha.format('DD-MM-YYYY') && (cita.horaInicio === hora.interno.horaInicio || cita.horaFinal === hora.interno.horaInicio) )){
                                                    return(

                                                    
                                                        <ListItem>
                                                            <Box
                                                                sx={{
                                                                    // backgroundColor:'red',
                                                                    width:'100%',
                                                                    textAlign:'center'
                                                                }}
                                                            >
                                                                <Button
                                                                    variant="outlined"
                                                                    color="inherit"
                                                                    sx={{
                                                                        // backgroundColor:'red',
                                                                        width:'95%',
                                                                        borderColor:'#F5323F',
                                                                        // borderColor:'#F5323F',
                                                                        color:'#F5323F',
                                                                        borderWidth:2,
                                                                        borderRadius:'6px',
                                                                        '&:hover': {
                                                                            backgroundColor: '#db0a18',
                                                                            borderColor:'#db0a18',
                                                                            color: 'white',
                                                                        },
                                                                        textTransform: 'none',
                                                                    }}
                                                                    onClick={()=>getHoraSelected(hora.interno)}
                                                                >
                                                                    <b>{ hora.mostrar }</b>
                                                                </Button>
                                                            </Box>
                                                        </ListItem>
                                                    )
                                                }else{
                                                    // console.log('time::no', hora);
                                                    
                                                }
                                            })
                                        }
                                        {/* {
                                            horas.map((hora)=>(
                                                <ListItem>
                                                    <Box
                                                        sx={{
                                                            // backgroundColor:'red',
                                                            width:'100%',
                                                            textAlign:'center'
                                                        }}
                                                    >
                                                        <Button
                                                            variant="outlined"
                                                            sx={{
                                                                // backgroundColor:'red',
                                                                width:'90%',
                                                            }}
                                                            onClick={getLlenarForm}
                                                        >
                                                            { hora }
                                                        </Button>
                                                    </Box>
                                                </ListItem>
                                            ))
                                        }                                         */}
                                    </List>
                                </Box>
                            ) : (
                                <Box>
                                    <Backdrop
                                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, }}
                                        open={true}
                                        onClick={getLlenarForm}
                                    >
                                        <Box
                                            sx={{
                                                // backgroundColor:'red',
                                                width:'100%',
                                                // height:'910px'
                                                // zIndex:2,
                                            }}
                                        >
                                            <InlineWidget
                                                // url="https://calendly.com/casas-pecsa/fracc-altavista-residencial/2023-02-15T12:00:00-06:00?back=1&month=2023-02&date=2023-02-15"
                                                url={`https://calendly.com/casas-pecsa/${fraccionamientoSelected.urlName}/${fecha.format('YYYY-MM-DD')}T${horaSelected.horaInicio}-06:00`}
                                                styles={{
                                                    height: '900px',
                                                    // zIndex:2
                                                }}
                                            />
                                        </Box>
                                    </Backdrop>                                    
                                </Box>
                            )
                        }
                    </Box>
                        
                )
            }
                    
                    
                
            
        </Box>
    );
}

export { CalendarioDesktop }