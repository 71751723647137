import { Box, Container } from "@mui/material";
import { CustomTypography } from "../../../components/CustomTypography";
import theme from "../../../App/theme";

const BannerSection = () => {
  return (
    <Box
      sx={{
        position: "relative",
        backgroundImage: `url('https://storage.casaspecsa.com/invierte-renta/banners/principal-inversion-desktop.png')`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        overflow: "hidden",
        height: { xs: 177, sm: 216, md: 216 },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <CustomTypography
          variant="subheadingSecondary"
          txtColor={theme.palette.common.white}
        >
          Compara tu Inversión en Casas Pecsa
        </CustomTypography>
      </Container>
    </Box>
  );
};

export { BannerSection };
