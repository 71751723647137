import { Button, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import { useState } from "react";
import { useEffect } from "react";
import { AddAcercaDeMi } from "./AddAcercaDeMi";
import { EditAcercaDeMi } from "./EditAcercaDeMi";

const AcercaDeMi = ({ acercademi }) => {
    console.log(acercademi);
    const [agregar, setAgregar] = useState(acercademi ? false : true);
    const getAgregar = () => {
        setAgregar(true);
    }
    const [editar, setEditar] = useState(false);
    const getEditar = () => {
        setEditar(true);
    }
    const cancelarAgregar = () => {
        setAgregar(false);   
    }
    const cancelarEditar = () => {
        setEditar(false);
               
    }

    const [texto, setTexto] = useState(acercademi.map(element=>element.texto).join(''));
    
    const updateTexto = (NewTexto) => {
        setTexto(NewTexto);
    }

    return(
        <Box
            sx={{
                minHeight:100,
            }}
        >
            <Typography variant="h5">
                Acerca de mi
            </Typography>
            <Box
                sx={{
                    width:'100%',
                }}
            >
                {
                    texto ? (
                        <Box>
                            {
                                !editar ? (
                                    <Box>
                                        <p style={{ "white-space": "pre-wrap" }}>{ texto }</p>
                                    </Box>
                                ) : (
                                    <EditAcercaDeMi texto={texto} updateTexto={updateTexto} cancelar={cancelarEditar} />
                                )
                            }
                            
                        </Box>
                    ) : (
                        <Box>
                            {
                                !agregar ? (
                                    <Box>
                                        <Typography variant="h6" sx={{ color:'#b3b3b3' }}>
                                            No hay texto
                                        </Typography>
                                    </Box>
                                ) : (
                                    <AddAcercaDeMi updateTexto={updateTexto} cancelar={cancelarAgregar}/>
                                )
                            }
                            
                        </Box>
                    )
                }
                {
                    (!editar && acercademi.length > 0) && (
                        <Box
                            sx={{
                                mt:1,
                                mb:1,
                                // backgroundColor:'red',
                                width:'100%',
                                textAlign:'right',
                            }}
                        >
                            <Button 
                                variant="contained" 
                                endIcon={<EditIcon />}
                                sx={{
                                    backgroundColor:'#707070',
                                    textTransform:'none',
                                    borderRadius:'10px',
                                    width:{ xs:'100%', sm:'100%', md:'15%' }
                                }}
                                onClick={getEditar}
                            >
                                <b>Editar</b>
                            </Button>
                        </Box>
                    )
                }
                {
                    (!agregar && acercademi.length < 1) && (
                        <Box
                            sx={{
                                mt:1,
                                mb:1,
                                // backgroundColor:'red',
                                width:'100%',
                                textAlign:'right',
                            }}
                        >
                            <Button 
                                variant="contained" 
                                endIcon={<EditIcon />}
                                sx={{
                                    backgroundColor:'#707070',
                                    textTransform:'none',
                                    borderRadius:'10px',
                                    width:{ xs:'100%', sm:'100%', md:'15%' }
                                }}
                                onClick={getAgregar}
                            >
                                <b>Agregar</b>
                            </Button>
                        </Box>
                    )
                }
                {/* {
                    texto ? (
                        <Box>
                            <p style={{ "white-space": "pre-wrap" }}>{ texto }</p>
                        </Box>
                    ) : (
                        <Box>
                            <Typography variant="h6" sx={{ color:'#b3b3b3' }}>
                                No hay texto
                            </Typography>
                            <Box
                                sx={{
                                    mt:1,
                                    mb:1,
                                    // backgroundColor:'red',
                                    width:'100%',
                                    textAlign:'right',
                                }}
                            >
                                <Button 
                                    variant="contained" 
                                    endIcon={<EditIcon />}
                                    sx={{
                                        backgroundColor:'#707070',
                                        textTransform:'none',
                                        borderRadius:'10px',
                                        width:{ xs:'100%', sm:'100%', md:'15%' }
                                    }}
                                    onClick={getEditar}
                                >
                                    <b>Agregar</b>
                                </Button>
                            </Box>
                        </Box>
                    )
                }

                {
                    !editar ? (
                        <Box>
                            {
                                texto ? (
                                    <Typography variant="body1">
                                        { texto }
                                    </Typography>
                                ) : (
                                    <Typography variant="h6" sx={{ color:'#b3b3b3' }}>
                                        No hay texto
                                    </Typography>
                                )
                            }
                        </Box>
                    ) : (
                        <AddAcercaDeMi texto={texto}/>
                    )
                }                
                {
                    !editar && (
                        <Box
                            sx={{
                                mt:1,
                                mb:1,
                                // backgroundColor:'red',
                                width:'100%',
                                textAlign:'right',
                            }}
                        >
                            <Button 
                                variant="contained" 
                                endIcon={<EditIcon />}
                                sx={{
                                    backgroundColor:'#707070',
                                    textTransform:'none',
                                    borderRadius:'10px',
                                    width:{ xs:'100%', sm:'100%', md:'15%' }
                                }}
                                onClick={getEditar}
                            >
                                <b>Editar</b>
                            </Button>
                        </Box>
                    )
                }
                {
                    editar && (
                        <Box
                            sx={{
                                mt:1,
                                mb:1,
                                // backgroundColor:'red',
                                width:'100%',
                                textAlign:'right',
                            }}
                        >
                            
                            <Button 
                                variant="contained" 
                                endIcon={<ClearIcon />}
                                sx={{
                                    backgroundColor:'#707070',
                                    textTransform:'none',
                                    borderRadius:'10px',
                                    width:{ xs:'100%', sm:'100%', md:'15%' },
                                    mr:1
                                }}
                                onClick={cancelar}
                            >
                                <b>Cancelar</b>
                            </Button>
                        </Box>
                    )
                } */}
                        
            </Box>
        </Box>
    );
}

export { AcercaDeMi }