import React, { useEffect, useState } from "react";
import axios from "axios";
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation, Link, NavLink, Navigate } from "react-router-dom";
import { Autocomplete, Box, Button, ButtonBase, Checkbox, Chip, Container, Divider, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import ClearIcon from '@mui/icons-material/Clear';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const elementosPlantas = [
    '2 Baños Completos',
    '3 Recamaras',
    'Sala',
    'Comedor'

]

const AddUsuario = () => {
    let navigate = useNavigate();

    const [perfiles, setPerfiles] = useState([]);
    const [selectedPerfil, setSelectedPerfil] = useState(null);
    const [ciudadDeNotaria, setCiudadDeNotaria] = useState(null);
    const [ciudadDeRegistro, setCiudadDeRegistro] = useState(null);
    const [ciudadDondeSeLevantaElActa, setCiudadDondeSeLevantaElActa] = useState(null);
    const [ciudadDondeSeInscribio, setCiudadDondeSeInscribio] = useState(null);

    const [imageLogo, setImageLogo] = useState(null);
    const [selectedImageLogo, setSelectedImageLogo] = useState(null);

    const [selectedImagePlantaBaja, setSelectedImagePlantaBaja] = useState(null);
    const [imagePlantaBajaUrl, setImagePlantaBajaUrll] = useState(null);
    const [selectedImagePlantaAlta, setSelectedImagePlantaAlta] = useState(null);
    const [imagePlantaAltaUrl, setImagePlantaAltaUrll] = useState(null);

    const [selectedElementosPlantaBaja, setSelectedElementosPlantaBaja] = useState(null);
    const [selectedElementosPlantaAlta, setSelectedElementosPlantaAlta] = useState(null);

    const [storageImages, setStorageImages] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [imagesUrl, setImagesUrl] = useState([]);

    const [principal, setPrincipal] = useState(0);

    const add = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');
        // const prub = {
        //     nombre: data.get('nombre'),
        //     idPerfil: data.get('perfil'),
        //     email: data.get('email'),
        //     telefono: data.get('telefono'),
        //     password: data.get('password'),
        //     folioCreateBy: folio
        // }
        // console.log(prub); return;

        data.set('folioCreateBy', folio);

        axios.post(`${process.env.REACT_APP_API_URL}/api/usuarios/`, 
            {
                nombre: data.get('nombre'),
                idPerfil: data.get('perfil'),
                email: data.get('email'),
                telefono: data.get('telefono'),
                password: data.get('password'),
                folioCreateBy: folio
            },
            {
                headers: { 
                    Authorization: `Bearer ${token}`,   
                    // 'Content-Type': 'multipart/form-data',               
                    //ContentType: 'multipart/form-data'
                }              
        })
        .then((response) => {
            // handle success            
            console.log(response);
            console.log('success axio');
            navigate(-1);
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sabe');
        });

    };
    const removeImagen = (index) => {
        storageImages.splice(index, 1);
        imagesUrl.splice(index, 1);
        setStorageImages(storageImages);
        setImagesUrl(imagesUrl);
    }
    const asignarImagenes = (files) => {
        setSelectedImages(files)
        setImagesUrl([...imagesUrl, ...selectedImages]);
    }
    useEffect(() => {
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        
        if (selectedImageLogo) {
            setImageLogo(URL.createObjectURL(selectedImageLogo[0]));
        }
        // if (selectedImagePlantaBaja) {
        //     setImagePlantaBajaUrll(URL.createObjectURL(selectedImagePlantaBaja[0]));
        // }
        // if (selectedImagePlantaAlta) {
        //     setImagePlantaAltaUrll(URL.createObjectURL(selectedImagePlantaAlta[0]));
        // }

        // if (selectedImages.length > 0) {
        //     console.log(selectedImages.length);
        //     // console.log('imagenes seleccionsadoas: ', selectedImages);
        //     for (let i = 0; i < selectedImages.length; i++) {
        //         if(!storageImages.find( element => element === selectedImages[i])){
        //             storageImages.push(selectedImages[i]);
        //             imagesUrl.push(URL.createObjectURL(selectedImages[i]))                    
        //         }                
        //     }
        // }


        //setPrincipal(Math.random(10) *100);
        axios.get(`${process.env.REACT_APP_API_URL}/api/usuarios/createview`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((response) => {
            // handle success
            console.log(response);
            setPerfiles(response.data.perfiles);
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
        });
    }, [setPerfiles, selectedImageLogo]);
    return(
        <Container sx={{ backgroundColor:'white', }}>
            <Box component="form" onSubmit={add} sx={{ mt:12, mb:8, }}>
                <h2>Nuevo Usuario</h2>
                <Box
                    sx={{ mb:2, }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField id="outlined-basic" label="Nombre" variant="outlined" name="nombre" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            {/* <TextField id="outlined-basic" label="Nombre" variant="outlined" name="nombre" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/> */}
                            {/* <Autocomplete
                                id="grouped-demo"
                                options={perfiles}
                                groupBy={(perfil) => perfil.nombre}
                                getOptionLabel={(perfil) => perfil.nombre}
                                value={selectedPerfil}
                                onChange={(event, newPerfil)=>{setSelectedPerfil(newPerfil);}}
                                sx={{ width: 400 }}
                                renderInput={(params) => <TextField {...params} label="Perfil" name="perfil" required/>}
                            /> */}
                            <FormControl sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }}>
                                <InputLabel id="demo-simple-select-label">Perfil</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedPerfil}
                                    label="Perfil"
                                    name="perfil"
                                    onChange={(event)=>{ setSelectedPerfil(event.target.value); }}                                
                                >
                                    {
                                        perfiles.map((perfil)=>(
                                            <MenuItem value={perfil.id}>{perfil.nombre}</MenuItem>        
                                        ))
                                    }
                                    {/* <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem> */}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField id="outlined-basic" label="Correo Electronico" variant="outlined" name="email" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                        </Grid> 
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField id="outlined-basic" label="telefono" variant="outlined" name="telefono" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                        </Grid> 
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField id="outlined-basic" label="Password" variant="outlined" name="password" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                        </Grid> 
                        <Grid item xs={12} sm={6} md={6}>
                            {/* <label htmlFor="contained-button-file-firma">
                                <Input accept="image/*, .pdf" id="contained-button-file-firma" name="archivoFirmarRepresentanteLegalDoc" type="file" sx={{ display:'none' }} />
                                <Button variant="contained" component="span" endIcon={<UploadFileIcon />}>
                                    Adjuntar Firma
                                </Button>
                            </label> */}
                        </Grid>
                                        
                    </Grid>
                </Box>
                <Divider />
                <Box
                    sx={{
                        mt:2,
                    }}
                >
                    <Button variant="contained" type="submit">Guardar</Button>
                </Box>
                
                            
            </Box>
        </Container>
    );
}

export { AddUsuario }