import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { InlineWidget, PopupWidget } from "react-calendly";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { ManyChatPixel } from "../../components/ManyChatPixel";

const CitasSuccess = () => {
    return(
        <Box
            sx={{
                mt:12,
                mb:12,                
            }}
        >
            <ManyChatPixel/>
            <Container>
                <Box
                    sx={{
                        textAlign:'center'
                    }}
                >
                    <Typography variant="h3" sx={{ color:'#f5325f' }}>
                        <b>¡Felicidades, tu cita se ha reservado con éxito!</b>
                    </Typography>
                    <EventAvailableIcon
                        sx={{
                            fontSize:300,
                            color:'#f5325f'
                        }}
                    />
                </Box>
            </Container>
        </Box>
    );
}

export { CitasSuccess }