import { Box } from "@mui/material";
import { Advantage } from "../Advantage";

const wrapperStyles = {
  position: "relative",
  display: "grid",
  gridTemplateColumns: { md: "1fr 1fr" },
  gap: { xs: "20px", md: "40px" },
  padding: { md: "80px 30px" },
  borderRadius: { md: "20px" },
  backgroundImage: {
    md: `url("https://storage.casaspecsa.com/invierte-renta/ventajas/ventajas-img-fondo.webp")`,
  },
  backgroundSize: "100% 100%",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: { md: "20px" },
    background: { md: "rgba(255, 255, 255, 0.7)" },
  },
};

const AdvantagesContainer = ({ advantagesList, isDesktop }) => {
  return (
    <Box sx={wrapperStyles}>
      {advantagesList.map((advantage, index) => (
        <Advantage key={index} advantage={advantage} isDesktop={isDesktop} />
      ))}
    </Box>
  );
};

export { AdvantagesContainer };
