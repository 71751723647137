import { Badge, Box, Breadcrumbs, Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { Helmet } from "react-helmet";

const CentroDeRespuestas = () => {
    let navigate = useNavigate();
    const { tokenInvitado }= useParams();
    const redirigirResponder = (lote) => {
        navigate(`/${lote}`);
    }
    const [lotes, setLotes] = useState([]);
    
    const getRespondidas = (comentarios) => {
        let count = 0;
        // for (const key in comentarios) {
        //     if (comentarios[key].respuestas.length > 0) {
        //         count = count + 1;                
        //     }
        // }
        return comentarios.filter((comentario) => comentario.respuestas.length > 0 ).length;
    }
    const getPendientes = (comentarios) => {
        let count = 0;
        // comentarios.map((comentario) => {
        //     if(comentario.respuestas.length < 1){
        //         count++;
        //     }
        // })
        return comentarios.filter((comentario) => comentario.respuestas.length === 0 ).length;
    }
    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_API_URL}/api/comentarios/lotes/${tokenInvitado}/all-comments/`)
        .then((response)=>{
            console.log(response)
            setLotes(response.data);
        })
        .catch((error) => {
            // handle success
            console.log(error);
        })
        .then(() => {
            // handle success
            //console.log('sabe');
        });
    },[])
    return(
        <Container>
            {/* <Helmet>
                <title>Casas Pecsa | Centro de Respuestas</title>
                <meta name="description" content="Centro de Respuestas para usuarios Pecsa" />
            </Helmet> */}
            <Box
                sx={{
                    mt:12,
                    minHeight:600,
                }}
            >
                <Box
                    sx={{
                        // backgroundColor:'red',
                        pb:1,
                        pt:0.6,
                        pl:1,
                        color:'#707070',
                        
                    }}
                >
                    <Breadcrumbs aria-label="breadcrumb">
                        {/* <Link underline="hover" color="inherit">
                            Inicio
                        </Link> */}
                        <Link to={'/'} style={{ color:'#707070', }}>
                            Inicio
                        </Link>
                        {/* <Link to={`/centro-de-respuestas/${tokenInvitado}`} style={{ color:'#F5323F', }}>
                            Centro de Respuestas
                        </Link> */}
                        <Typography sx={{ color:'#F5323F', }}>
                            Centro de Respuestas
                        </Typography>
                    </Breadcrumbs>
                </Box>
                <Box>
                    {
                        lotes.length === 0 && (
                            <Box
                                sx={{
                                    width:'100%',
                                    textAlign:'center',
                                }}
                            >
                                <QuestionAnswerIcon sx={{ fontSize:200, color:'#F5323F', }} />
                                <Typography variant="h4" sx={{ color:'#F5323F', }}>
                                    <b>No hay comentarios ni preguntas aun.</b>
                                </Typography>
                            </Box>
                        )
                    }
                </Box>
                <Box>
                    <Grid container spacing={2}>                        
                        {
                            lotes.map((lote) => (
                                <Grid item xs={12} sm={6} md={4}>
                                    <Badge 
                                        color="error" 
                                        badgeContent={ lote ? getPendientes(lote.comentarios) : 0 } 
                                        invisible={ lote ? getPendientes(lote.comentarios) > 0 ? false : true : true }
                                    >
                                        <Card
                                            onClick={()=>{ navigate(`/centro-de-respuestas/${tokenInvitado}/${lote.id}`); }}
                                            sx={{
                                                borderRadius:'15px',
                                            }}
                                        >
                                            <CardActionArea>
                                                <CardMedia
                                                    component="img"
                                                    height="300"
                                                    image={ lote.fotos ? lote.fotos[0].url : '' }
                                                    alt="green iguana"
                                                />
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="div">
                                                        Lote:{ lote.numeroDeLote }
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Manzana: { lote.manzana.nombre }
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Fraccionamiento: { lote.manzana.etapa.fraccionamiento.nombre }
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Modelo: { lote.fachada.modelo.nombre } Fachada: { lote.fachada.nombre }
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            width:'100%',
                                                            display:'flex',
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                width:'50%',
                                                                textAlign:'center',
                                                                color:'green',
                                                            }}
                                                        >
                                                            <Typography variant="h5">
                                                                { lote ? getRespondidas(lote.comentarios) : 0 }
                                                            </Typography>
                                                            <Typography variant="body2">
                                                                Respondidas
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                width:'50%',
                                                                textAlign:'center',
                                                                color:'red',
                                                            }}
                                                        >
                                                            <Typography variant="h5">
                                                                { lote ? getPendientes(lote.comentarios) : 0 }
                                                            </Typography>
                                                            <Typography variant="body2">
                                                                Pendientes
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Badge>
                                    
                                </Grid>
                            ))
                        }
                        
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}
export { CentroDeRespuestas }