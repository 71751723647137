import { Box, Container } from "@mui/material";
import { CustomButton } from "../../../components/CustomButton";
import { CustomTypography } from "../../../components/CustomTypography";
import theme from "../../../App/theme";
import { useIdLotesToCompare } from "../../../hooks/investment/comparator/useIdLotesToCompare";
import { useState } from "react";
import consumirApiDeDescarga from "../../../functions/consumirApiDeDescarga";
import { useNavigate } from "react-router";

const CTASection = ({ inversionInicial = 0 }) => {
  let navigate = useNavigate();
  const idLotes = useIdLotesToCompare();
  const [loading, setLoading] = useState(false);
  
  const getLoading = (newValue) => {
    setLoading(newValue)
  }

  const getPDF = (idLotes) => {
    getLoading(true)

    let queryInversionInicial = `inversionInicial=${inversionInicial ? inversionInicial : 0}`;
    let query = '';

    for (let index = 0; index < idLotes.length; index++) {
      query = query + `lote${index + 1}=${idLotes[index]}&`;
    }

    const queryFinal = `https://qfjy7kh5ic.execute-api.us-east-2.amazonaws.com/comparador-inversion?${query}${queryInversionInicial}`;
    console.log(queryFinal);
    const fileName = 'comparador-casas-para-inversion-Pecsa.pdf';
    
    const { error } = consumirApiDeDescarga(queryFinal, fileName, getLoading);

    if(error){
      console.log('error')
    }
  }
  return (
    <Box
      component="section"
      sx={{
        backgroundColor: theme.palette.common.white,
        py: "1.25rem",
        position: "sticky",
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <Container
        sx={{
          display: "flex",
          textAlign: "center",
          flexDirection: "column",
          gap: 3.75,
        }}
      >
        <Box>
          <CustomTypography variant="subheadingSecondary">
              Descarga tu comparación
          </CustomTypography>
        </Box>
        <Box>
          <CustomButton
            text={loading ? "Descargando..." : "Descargar"}
            // text="Ver más opciones"
            variant="contained"
            size="small"
            disabled={loading}
            onClick={() => getPDF(idLotes)}
            // onClick={() => navigate("/invierte/listado")}
          />        
        </Box>
      </Container>
    </Box>
  );
};

export { CTASection };
