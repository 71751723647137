import { Box, Container, Grid, Skeleton } from "@mui/material";

const PortaSkeleton = ({ children, alignItems = 'center', justifyContent = 'center' }) => {
    return(
        <Box
            sx={{
                // backgroundColor:'pink',
                width:'100%',
                minHeight:{ xs:350, sm:350, md:430 },
                display:'flex',
                flexDirection:'column',
                alignItems:alignItems,
                justifyContent:justifyContent,
                gap:1,
            }}
        >
            { children }
        </Box>
    );
}
const LoadingLote = () => {
    return(
        <Container >
            <Grid container>
                <Grid item xs={12} sm={6} md={6}>
                    <PortaSkeleton>
                        <Skeleton 
                            variant="rounded" 
                            sx={{ 
                                width:'90%',
                                height:{ xs:320, sm:320, md:400 },
                            }}
                        />
                    </PortaSkeleton>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <PortaSkeleton
                        alignItems="flex-start"
                    >
                        <Skeleton 
                            variant="rounded" 
                            sx={{ 
                                width:'30%',
                                height:45,
                            }}
                        />
                        <Skeleton 
                            variant="rounded" 
                            sx={{ 
                                width:'50%',
                                height:25,
                            }}
                        />
                        <Skeleton 
                            variant="rounded" 
                            sx={{ 
                                width:'30%',
                                height:45,
                            }}
                        />
                        <Skeleton 
                            variant="rounded" 
                            sx={{ 
                                width:'60%',
                                height:150,
                            }}
                        />
                        <Skeleton 
                            variant="rounded" 
                            sx={{ 
                                width:'90%',
                                height:100,
                            }}
                        />
                    </PortaSkeleton>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <PortaSkeleton justifyContent={{ xs:'center', sm:'center', md:"flex-start" }}>
                        <Skeleton 
                            variant="rounded" 
                            sx={{ 
                                width:'95%',
                                height:280,
                            }}
                        />
                    </PortaSkeleton>
                </Grid>
            </Grid>
        </Container>
    );
}

export { LoadingLote }