import { Breadcrumbs, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { useState } from "react";

import { useEffect } from "react";
import axios from "axios";
import { Encabezado } from "./Encabezado";
import { Body } from "./Body";
import { CalendarioMovil } from "./Calendario/CalendarioMovil";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const Asesor = () => {
    const { folio } = useParams();
    const [asesor, setAsesor] = useState(null);

    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    // };

    useEffect(()=>{
        console.log(folio);
        axios.get(`${process.env.REACT_APP_API_URL}/api/asesores/${folio}`)
        .then((response) => {
            // handle success
            console.log(response);
            setAsesor(response.data);
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            // console.log('sae');
        });
    },[]);
    return(
        <Box
            sx={{
                pt:{ xs:8, sm:11, md:11 },
            }}
        >
            <Helmet>
                <title>Casas Pecsa | Asesor - { asesor ? asesor.nombre : `cargando...` } </title>
                <meta name="description" content="Conoce Las Increíbles Casas que tenemos Para Ti. Contamos con un recorrido virtual 100% En Línea y Un Sistema de Separado en Línea. También puedes agendar tu cita para visitar Nuestras Casas Hoy mismo." />
            </Helmet> 
            <Box
                sx={{
                    // backgroundColor:'#f2f2f2',
                    minHeight:{ xs:400, sm:500, md:500 },
                }}
            >
                <Container sx={{ backgroundColor:'white' }}>
                    <Box>
                        <Breadcrumbs aria-label="breadcrumb">
                            {/* <Link underline="hover" color="inherit">
                                Inicio
                            </Link> */}
                            <Link to={'/'} style={{ color:'#707070', }}>
                                Inicio
                            </Link>
                            <Link to={'/asesores'} style={{ color:'#707070', }}>
                                Asesores
                            </Link>
                            <Typography sx={{ color:'#F5323F', }}>{ asesor ? asesor.nombre : `cargando...` }</Typography>
                        </Breadcrumbs>
                    </Box>
                    {
                        asesor && (
                            <Encabezado asesor={asesor}/>
                        )
                    }
                </Container>
            </Box>
            <Box
                sx={{
                    // backgroundColor:'#f2f2f2',
                }}
            >
                <Container sx={{ backgroundColor:'white' }}>
                    {
                        asesor && (
                            <Body asesor={asesor}/>
                        ) 
                    }
                    {
                        asesor && (
                            <CalendarioMovil asesor={asesor}/>
                        )
                    }                    
                </Container>
            </Box>
        </Box>
    );
}

export { Asesor }