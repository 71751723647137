import React, { useEffect, useState } from "react";

const MapaSVG = ({ lotes, svgAPI, handleClick, handleClickClose }) => {
    const [viewBox, setViewBox] = useState(null);
    const [style, setStyle] = useState(null);
    const [otrosStyle, setOtrosStyle] = useState(null);
    const [coloresLotes, setColoresLotes] = useState(null);
    const [textoLotes, setTextoLotes] = useState(null);
    const [loDemas, setLoDemas] = useState(null);
    // console.log('looootes:', svgAPI);
    const PathLote = ({element}) => (<path
        id={element.attributes.id}
        onClick={ lotes.find((lote) => `${lote.LoteDisponible}` == element.attributes.id) ? handleClick('top', lotes.find((lote) => `${lote.LoteDisponible}` == element.attributes.id)) : handleClick('top', null) }        
        className={element.attributes.className}
        d={`${element.attributes.d}`}
        // style={{ fill: lotes.find((lote) => `prefix__${lote.folio}` == element.attributes.id) ? '#0e7a3dff' : '#cd2027' }}
        style={{ 
            fill: lotes.find((lote) => `${lote.LoteDisponible}` == element.attributes.id) ? '#00ba65' : '#ff5757', 
            cursor:'pointer' 
        }}
    />)
    const Path= ({element}) => (<path
        {...element.attributes}
    />)
    const Text= ({element}) => (<text
        {...element.attributes}
    >
        { getElementosLoDemas(element.children) }
    </text>)
    const TSpan= ({element}) => (<tspan
        {...element.attributes}
    >
        { getElementosLoDemas(element.children) }
    </tspan>)
    const GroupG= ({element}) => (<g
        {...element.attributes}
    >
        { getElementosLoDemas(element.children) }
    </g>)
    const Circle= ({element}) => (<circle
        {...element.attributes}
    >
        { getElementosLoDemas(element.children) }
    </circle>)
    const Image= ({element}) => (<image
        {...element.attributes}
    >
        { getElementosLoDemas(element.children) }
    </image>)
    const Rect= ({element}) => (<rect
        {...element.attributes}
    >
        { getElementosLoDemas(element.children) }
    </rect>)
    const ClipPath= ({element}) => (<clipPath 
        {...element.attributes}
    >
        { getElementosLoDemas(element.children) }
    </clipPath>)
    const Polygon= ({element}) => (<polygon 
        {...element.attributes}
    />)
    const getElementos = (svgAPI) => {
        //console.log('svgAPIsss',svgAPI);
        const preViewBox = svgAPI.attributes ? svgAPI.attributes.viewBox ? svgAPI.attributes.viewBox : null : null;
        if(preViewBox){
            setViewBox(preViewBox);
        }
        // const estilos = svgAPI.children.length > 0 ? 
        //     svgAPI.children[0].name === 'defs' ? 
        //         svgAPI.children[0].children.length === 1 ? 
        //             svgAPI.children[0].children[0].name === 'style' ? 
        //                 svgAPI.children[0].children[0].children.length === 1 ? 
        //                     svgAPI.children[0].children[0].children[0].type === 'text' ? 
        //                         svgAPI.children[0].children[0].children[0].value 
        //                     : '' 
        //                 : '' 
        //             : '' 
        //         : '' 
        //     : ''
        // : '';
        // console.log('estilooooooo1',estilos);
        let estilos = '';
        const unoEstilos = svgAPI.children.find((element)=>element.name === 'defs');
        // console.log(unoEstilos);
        if(unoEstilos){
            const dosEstilos = unoEstilos.children.find((element)=>element.name === 'style');
            if(dosEstilos){
                const tresEstilos = dosEstilos.children.find((element)=>element.type === 'text')
                if(tresEstilos){
                    estilos = tresEstilos.value;
                }                
            }
        }
        if(estilos !== ''){
            const preestilos = estilos.slice(estilos.indexOf('"') + 1);
            const estilofinal = preestilos.slice(0, preestilos.indexOf('"'))
            setStyle(estilofinal);
            // console.log('estilooooooo',style);
        }else{
            setStyle(estilos);
        }
        let otrosEstilos = '';
        const unoOtrosEstilos = svgAPI.children.find((element)=>element.name === 'defs');
        // console.log(unoOtrosEstilos);
        if(unoOtrosEstilos){
            const dosOtrosEstilos = unoOtrosEstilos.children.find((element)=>element.name === 'clipPath');
            if(dosOtrosEstilos){
                //setOtrosStyle(getElementosOtrosEstilos(dosOtrosEstilos));
                setOtrosStyle(<clipPath {...dosOtrosEstilos.attributes} > { getElementosOtrosEstilos(dosOtrosEstilos.children) } </clipPath>);
            }
        }
        const colores = svgAPI.children.find((element) => element.name === 'g' && element.attributes.id === 'prefix__colores_lotes');
        if(colores){
            // console.log('colores.children', colores.children);
            setColoresLotes(<g id="prefix__colores_lotes"> { getElementosColores(colores.children) } </g>);
        }
        const letras = svgAPI.children.find((element) => element.name === 'g' && element.attributes.id === 'prefix__texto');
        if(letras){
            // console.log('letras', letras.children);
            setTextoLotes(<g id="prefix__texto"> { getElementosLoDemas(letras.children) } </g>);
        }
        
        const lodemas = svgAPI.children.find((element) => element.name === 'g' && element.attributes.id === 'prefix__lo_demas');
        if(lodemas){
            // console.log('SI g lodemas', lodemas);
            setLoDemas(<g id="prefix__lo_demas"> { getElementosLoDemas(lodemas.children) } </g>);
        }
        else{
            // console.log('NO g lodemas', svgAPI.children.find((element) => element.attributes.id === 'prefix__lo_demas'));
            //lodemas = svgAPI.children.find((element) => element.attributes.id === 'prefix__lo_demas');
            //setLoDemas(<Image {...lodemas.attributes} />);
        }
    }
    const getElementosColores = (colores) => {
        const elementosColores = [];
    
        for (let element of colores) {
            // console.log('otropath', element);
            elementosColores.push(<PathLote element={element}/>)
            //setKeys([...keys, <Path element={element}/>])
        }
        return elementosColores;
    }
    const getElementosLoDemas = (lodemas) => {
        const elementosLoDemas = [];
    
        for (let element of lodemas) {
            elementosLoDemas.push(getTipoElemento(element))
            //setKeys([...keys, <Path element={element}/>])
        }
        return elementosLoDemas;
    }
    const getElementosOtrosEstilos = (otrosestilos) => {
        const elementosotrosestilos = [];
    
        for (let element of otrosestilos) {
            elementosotrosestilos.push(getTipoElemento(element))
            //setKeys([...keys, <Path element={element}/>])
        }
        return elementosotrosestilos;
    }

    const getTipoElemento = (element) => {
        if(element.type === 'element'){
            if(element.name === 'path'){
                return (<Path element={element}/>)
            }
            if(element.name === 'text'){
                return (<Text element={element}/>)
            }
            if(element.name === 'tspan'){
                return (<TSpan element={element}/>)
            }
            if(element.name === 'g'){
                return (<GroupG element={element}/>)
            }
            if(element.name === 'circle'){
                return (<Circle element={element}/>)
            }
            if(element.name === 'image'){
                return (<Image element={element}/>)
            }
            if(element.name === 'rect'){
                return (<Rect element={element}/>)
            }
            if(element.name === 'clipPath'){
                return (<ClipPath element={element}/>)
            }
            
        }
        if(element.type === 'text'){
            if(element.value.indexOf('\"') !== -1){
                const preresultado = element.value.slice(element.value.indexOf('\"') + 1);
                const resultado = preresultado.slice(0, preresultado.indexOf('\"'));
                // console.log('si tiene', resultado);
                return resultado;
            }else{
                return element.value
            }            
        }
    }
    useEffect(()=>{
        getElementos(svgAPI);
    }, [svgAPI])
    return(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            // viewBox="0 0 933.87 712.97"
            viewBox={viewBox}
            style={{ width:'100%', }}
        >
            <defs>
                <style>
                    {
                        // ".prefix__cls-3{fill:#00ba65}.prefix__cls-4{fill:#ff5757}.prefix__cls-5{fill:#fff}.prefix__cls-7{fill:#515151}.prefix__cls-8{fill:#545454;font-size:11.79px;font-family:Montserrat-Bold,Montserrat}.prefix__cls-12,.prefix__cls-13{stroke-width:2px;fill:none;stroke:#515151;stroke-linecap:round;stroke-linejoin:round}.prefix__cls-13{opacity:.5;stroke-width:.75px}"
                        style
                    }
                </style>
                {
                    otrosStyle
                }
            </defs>
            {
                textoLotes ? (
                    <React.Fragment>
                        {
                            loDemas
                        }
                        
                        {
                            coloresLotes
                        }
                        {
                            textoLotes
                        }
                    </React.Fragment>
                ) : (
                    <React.Fragment>                                                
                        {
                            coloresLotes
                        }
                        {
                            loDemas
                        }
                    </React.Fragment>
                )
            }

            {/* {
                loDemas
            }
            
            {
                coloresLotes
            }
            {
                textoLotes
            } */}
        </svg>
    );
}

export { MapaSVG }