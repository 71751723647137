import { Alert, Box, Breadcrumbs, Button, Grid, List, ListItem, ListItemText, Snackbar, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import SendIcon from '@mui/icons-material/Send';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Helmet } from "react-helmet";

const CentroDeRespuestasResponder = () => {
    let navigate = useNavigate();
    const { tokenInvitado, idLote } = useParams();

    const redirigirResponder = (lote) => {
        navigate(`/${lote}`);
    }
    const [lote, setLote] = useState(null);
    const stylePecsaMoffin = {
        "& label.Mui-focused": {
            color: "#f5323f"
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#d9d9d9"
        },
        "& .MuiOutlinedInput-root": {
            // '& fieldset': {
            //   borderColor: 'black',
            // },
            "&:hover fieldset": {
                borderColor: "#d9d9d9"
            },
            "&.Mui-focused fieldset": {
                borderColor: "#d9d9d9"
            }
        }
    }
    const [respuestas, setRespuestas] = useState([]);
    const getRespuestas = (index, texto) => {
        setRespuestas((respuesta) => {
            return respuesta.map((c, i) => {
                if (i === index) return texto;
                return c;
            });
        });
    }
    const sendRespuesta = (index, idComentarioLote) => {
        // console.log(
        //     {
        //         idComentarioLote: idComentarioLote,
        //         nombre: 'Asesor Pecsa Online',
        //         texto: respuestas[index],
        //         activo: true,
        //     }
        // );
        axios.post(`${process.env.REACT_APP_API_URL}/api/comentarios/lotes/${tokenInvitado}/responder`,
        {
            idComentarioLote: idComentarioLote,
            nombre: 'Asesor Pecsa Online',
            texto: respuestas[index],
            activo: true,
        })
        .then((response)=>{
            console.log(response)
            handleClickAlert();
        })
        .catch((error) => {
            // handle success
            console.log(error);
        })
        .then(() => {
            // handle success
            //console.log('sabe');
        });
    }
    const sendUpdateRespuesta = (index, idRespuesta) => {
        
        console.log(
            {
                idRespuesta,                
                nombre: 'Asesor Pecsa Online',
                texto: respuestas[index],
                activo: true,
            }
        );
        axios.post(`${process.env.REACT_APP_API_URL}/api/comentarios/lotes/${tokenInvitado}/responder/update/${idRespuesta}`,
        {
            nombre: 'Asesor Pecsa Online',
            texto: respuestas[index],
            activo: true,
        })
        .then((response)=>{
            console.log(response)
            handleClickAlert();
        })
        .catch((error) => {
            // handle success
            console.log(error);
        })
        .then(() => {
            // handle success
            //console.log('sabe');
        });
    }

    const [visibility, setVisibility] = useState([]);
    const getVisibility = (index, visto, idComentario) => {
        setVisibility((respuesta) => {
            return respuesta.map((c, i) => {
                if (i === index) return visto;
                return c;
            });
        });
        bloquearRespuesta(index, visto, idComentario);
    }
    const bloquearRespuesta = (index, visto, idComentario) => {
        
        console.log(
            {
                idComentario,
                activo: visto,
            }
        );
        axios.post(`${process.env.REACT_APP_API_URL}/api/comentarios/lotes/${tokenInvitado}/update/${idComentario}`,
        {
            activo: visto,
        })
        .then((response)=>{
            console.log(response)
            
        })
        .catch((error) => {
            // handle success
            console.log(error);
        })
        .then(() => {
            // handle success
            //console.log('sabe');
        });
    }
    const reedirigirLoteOtraPestaña = () =>{
        window.open(`${process.env.REACT_APP_URL}/tienda/${lote.sku.replace(/ /g, "_")}`)
    }
    const [openAlert, setOpenAlert] = useState(false);

    const handleClickAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpenAlert(false);
    };
    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_API_URL}/api/comentarios/lotes/${tokenInvitado}/get/${idLote}`)
        .then((response)=>{
            console.log(response)
            setLote(response.data);
            setRespuestas(response.data.comentarios.map((comentario) => comentario.respuestas.length > 0 ? comentario.respuestas[0].texto : ''));
            setVisibility(response.data.comentarios.map((comentario) => comentario.activo ));
        })
        .catch((error) => {
            // handle success
            console.log(error);
        })
        .then(() => {
            // handle success
            //console.log('sabe');
        });
    },[])
    return(
        <Container>
            {/* <Helmet>
                <title>Casas Pecsa | Centro de Respuestas - Responder</title>
                <meta name="description" content="Centro de Respuestas para usuarios Pecsa" />
            </Helmet> */}
            <Box
                sx={{
                    mt:12,
                }}
            >
                <Box
                    sx={{
                        // backgroundColor:'red',
                        pb:1,
                        pt:0.6,
                        pl:1,
                        color:'#707070',
                        
                    }}
                >
                    <Breadcrumbs aria-label="breadcrumb">
                        {/* <Link underline="hover" color="inherit">
                            Inicio
                        </Link> */}
                        <Link to={'/'} style={{ color:'#707070', }}>
                            Inicio
                        </Link>
                        <Link to={`/centro-de-respuestas/${tokenInvitado}`} style={{ color:'#707070', }}>
                            Centro de Respuestas
                        </Link>
                        <Typography sx={{ color:'#F5323F', }}>
                            { `Lote: ${lote && lote.numeroDeLote}` }
                            {/* { `${lote.manzana.etapa.fraccionamiento.nombre}` } */}
                        </Typography>
                    </Breadcrumbs>
                </Box>
                {/* <Box>
                    <Typography variant="h4" sx={{ color:'#f5323f', }}>
                        <b>Lote</b>
                    </Typography>
                </Box> */}
                <Box>
                    <Box>
                        <Grid container>
                            <Grid item xs={12} sm={6} md={6}>
                                <Box
                                    sx={{
                                        width:'100%',
                                        // backgroundColor:'red',
                                        display:'flex',
                                        alignContent:'center',
                                        alignContent:'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width:'95%',
                                            // backgroundColor:'yellow',
                                            m:'0 auto',
                                            display:'flex',
                                            alignContent:'center',
                                            alignContent:'center',
                                        }}
                                    >
                                        <Box
                                            component='img'
                                            src={lote ? lote.fotos[0].url : ''}
                                            sx={{
                                                maxWidth:'100%',
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Box
                                    sx={{
                                        color:'#707070',
                                        pl:{ xs:0, sm:2, md:2, },
                                    }}
                                >
                                    <Typography variant="h4">
                                        Lote: <b>{ lote && lote.numeroDeLote }</b>
                                    </Typography>
                                    <Typography variant="h6">
                                        Manzana: <b>{ lote && lote.manzana.nombre }</b>
                                    </Typography>
                                    <Typography variant="h6">
                                        Etapa: <b>{ lote && lote.manzana.etapa.nombre }</b>
                                    </Typography>
                                    <Typography variant="h6">
                                        Fraccionamiento: <b>{ lote && lote.manzana.etapa.fraccionamiento.nombre }</b>
                                    </Typography>
                                    <Typography variant="h6">
                                        Modelo: <b>{ lote && lote.fachada.modelo.nombre }</b>
                                    </Typography>
                                    <Typography variant="h6">
                                        Fachada: <b>{ lote && lote.fachada.nombre }</b>
                                    </Typography>
                                    <Box
                                        sx={{
                                            // backgroundColor:'yellow',
                                            width:'100%',
                                            
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                // backgroundColor:'brown',
                                                width:'100%',
                                                height:50,
                                                textAlign:'center',
                                                display:'flex',
                                                alignItems:'center',
                                                alignContent:'center',
                                            }}
                                        >
                                            <Button
                                                variant="outlined"
                                                color="inherit"
                                                // endIcon={<SendIcon />}
                                                sx={{
                                                    width:{xs:'100%', sm:'100%', md:'30%'},
                                                    height:40,
                                                    m:'0 auto',
                                                    backgroundColor:'#F5323F',
                                                    // borderColor:'#F5323F',
                                                    color:'white',
                                                    borderWidth:0,
                                                    borderRadius:'7px',
                                                    '&:hover': {
                                                        backgroundColor: '#db0a18',
                                                        // color: 'white',
                                                    },
                                                    textTransform: 'none',
                                                }}
                                                onClick={reedirigirLoteOtraPestaña}
                                            >
                                                <b>Ver lote</b>
                                            </Button>
                                        </Box>
                                        <Box
                                            sx={{
                                                // backgroundColor:'green',
                                                width:'100%',
                                                height:50,
                                                textAlign:'center',
                                                display:'flex',
                                                alignItems:'center',
                                                alignContent:'center',
                                                
                                            }}
                                        >
                                            <Button
                                                variant="outlined"
                                                color="inherit"
                                                // endIcon={<SendIcon />}
                                                sx={{
                                                    width:{xs:'100%', sm:'100%', md:'30%'},
                                                    height:40,
                                                    m:'0 auto',
                                                    backgroundColor:'#bfbfbf',
                                                    // borderColor:'#F5323F',
                                                    color:'white',
                                                    borderWidth:0,
                                                    borderRadius:'7px',
                                                    '&:hover': {
                                                        backgroundColor: '#a6a6a6',
                                                        // color: 'white',
                                                    },
                                                    textTransform: 'none',
                                                }}
                                                onClick={()=>navigate(-1)}
                                            >
                                                <b>Volver</b>
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Box>
                    <Box
                        sx={{
                            // mt:2
                        }}
                    >
                        <Box sx={{ color:'#F5323F' }}>
                            <h1>Comentarios y preguntas</h1>
                        </Box>
                        <Typography variant="body1" sx={{ color:'#707070', }}>
                            Aqui puedes responder a las preguntas que realizaron los usuarios del sitio web.        
                        </Typography>
                    </Box>
                    
                    <Box>
                        <Box
                            sx={{
                                mt:2
                            }}
                        >
                            <Typography variant="body1" sx={{ color:'#F5323F', }}>
                                <b>Comentarios y preguntas realizados:</b>
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                color:'#595959',
                            }}
                        >
                            <List>
                                {
                                    lote && (
                                        lote.comentarios.length > 0 ? (
                                            lote.comentarios.map((coment, index) => (
                                                <ListItem>
                                                    <ListItemText 
                                                        primary={
                                                            <Typography variant="body1" sx={{ color: visibility[index] ? '#707070':'#999999' }}>
                                                                <b>{ coment.texto }</b>
                                                            </Typography>
                                                        } 
                                                        secondary={
                                                            coment.respuestas.length === 0 ? 
                                                                <Box
                                                                    sx={{
                                                                        // backgroundColor:'yellowgreen',
                                                                        width:'100%',
                                                                        pl:{xs:0, sm:2,md:2},
                                                                    }}
                                                                >
                                                                    <Typography variant="caption" sx={{ color: visibility[index] ? '#F5323F':'#707070' }}>
                                                                        Respuesta:
                                                                    </Typography>
                                                                    <Box>
                                                                        <Grid 
                                                                            container
                                                                        >
                                                                            <Grid item xs={12} sm={10} md={6}>
                                                                                <Box
                                                                                    sx={{
                                                                                        // backgroundColor:'yellow',
                                                                                        width:'100%',
                                                                                        display:'flex',
                                                                                        alignItems:'center',
                                                                                        alignContent:'center',
                                                                                    }}
                                                                                >
                                                                                    <TextField 
                                                                                        id="outlined-basic" 
                                                                                        placeholder="Escribe una respuesta..." 
                                                                                        multiline 
                                                                                        rows={2} 
                                                                                        variant="outlined" 
                                                                                        sx={{
                                                                                            backgroundColor:'white',
                                                                                            width: {xs:'100%', sm:'95%', md:'95%'},
                                                                                            m:'0 auto',
                                                                                            ...stylePecsaMoffin,
                                                                                        }}
                                                                                        value={respuestas[index]}
                                                                                        onChange={(e)=>getRespuestas(index, e.target.value)}
                                                                                        disabled={!visibility[index]}
                                                                                    />
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={2} md={2}>
                                                                                <Box
                                                                                    sx={{
                                                                                        // backgroundColor:'yellow',
                                                                                        width:'100%',
                                                                                        height:60,
                                                                                        // display:'flex',
                                                                                        // alignItems:'center',
                                                                                        // alignContent:'center',
                                                                                    }}
                                                                                >
                                                                                    <Button 
                                                                                        variant="outlined"
                                                                                        color="inherit"
                                                                                        endIcon={<SendIcon />}
                                                                                        sx={{
                                                                                            width:{xs:'100%', sm:'90%', md:'80%'},
                                                                                            height:40,
                                                                                            backgroundColor:'#F5323F',
                                                                                            // borderColor:'#F5323F',
                                                                                            color:'white',
                                                                                            // borderWidth:2,
                                                                                            borderRadius:'7px',
                                                                                            '&:hover': {
                                                                                                backgroundColor: '#db0a18',
                                                                                                // color: 'white',
                                                                                            },
                                                                                            textTransform: 'none',
                                                                                        }}
                                                                                        onClick={()=>{sendRespuesta(index, coment.id)}}
                                                                                        disabled={!visibility[index]}
                                                                                    >
                                                                                        <b>Responder</b>
                                                                                    </Button>
                                                                                    <Button 
                                                                                        variant="outlined"
                                                                                        color="inherit"
                                                                                        endIcon={ visibility[index] ? <VisibilityOffIcon/> : <RemoveRedEyeOutlinedIcon /> }
                                                                                        sx={{
                                                                                            width:{xs:'100%', sm:'90%', md:'80%'},
                                                                                            height:40,
                                                                                            backgroundColor:'#33ccff',
                                                                                            // borderColor:'#F5323F',
                                                                                            color:'white',
                                                                                            // borderWidth:2,
                                                                                            borderRadius:'7px',
                                                                                            '&:hover': {
                                                                                                backgroundColor: '#00ace6',
                                                                                                // color: 'white',
                                                                                            },
                                                                                            textTransform: 'none',
                                                                                        }}
                                                                                        onClick={()=>{getVisibility(index, !visibility[index], coment.id)}}
                                                                                    >
                                                                                        <b>{ visibility[index] ? 'Ocultar' : 'Visible' }</b>
                                                                                    </Button>
                                                                                </Box>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Box>
                                                            : (                                                            
                                                                <Box
                                                                    sx={{
                                                                        // backgroundColor:'yellowgreen',
                                                                        width:'100%',
                                                                        pl:{xs:0, sm:2,md:2},
                                                                    }}
                                                                >
                                                                    <Typography variant="caption" sx={{ color: visibility[index] ? '#F5323F':'#707070' }}>
                                                                        Respuesta: 
                                                                    </Typography>
                                                                    <Box>
                                                                        <Grid 
                                                                            container
                                                                        >
                                                                            <Grid item xs={12} sm={10} md={6}>
                                                                                <Box
                                                                                    sx={{
                                                                                        // backgroundColor:'yellow',
                                                                                        width:'100%',
                                                                                        display:'flex',
                                                                                        alignItems:'center',
                                                                                        alignContent:'center',
                                                                                    }}
                                                                                >
                                                                                    <TextField 
                                                                                        id="outlined-basic" 
                                                                                        placeholder="Escribe una respuesta..." 
                                                                                        multiline 
                                                                                        rows={2} 
                                                                                        variant="outlined" 
                                                                                        sx={{
                                                                                            backgroundColor:'white',
                                                                                            width: {xs:'100%', sm:'95%', md:'95%'},
                                                                                            m:'0 auto',
                                                                                            ...stylePecsaMoffin,
                                                                                        }}
                                                                                        value={respuestas[index]}
                                                                                        onChange={(e)=>getRespuestas(index, e.target.value)}
                                                                                        disabled={!visibility[index]}
                                                                                    />
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={2} md={2}>
                                                                                <Box
                                                                                    sx={{
                                                                                        // backgroundColor:'yellow',
                                                                                        width:'100%',
                                                                                        height:60,
                                                                                        // display:'flex',
                                                                                        // alignItems:'center',
                                                                                        // alignContent:'center',
                                                                                    }}
                                                                                >
                                                                                    <Button 
                                                                                        variant="outlined"
                                                                                        color="inherit"
                                                                                        endIcon={<AutoFixNormalIcon />}
                                                                                        sx={{
                                                                                            width:{xs:'100%', sm:'90%', md:'80%'},
                                                                                            height:40,
                                                                                            backgroundColor:'#f7555f',
                                                                                            // borderColor:'#F5323F',
                                                                                            color:'white',
                                                                                            // borderWidth:2,
                                                                                            borderRadius:'7px',
                                                                                            '&:hover': {
                                                                                                backgroundColor: '#db0a18',
                                                                                                // color: 'white',
                                                                                            },
                                                                                            textTransform: 'none',
                                                                                        }}
                                                                                        onClick={()=>{sendUpdateRespuesta(index, coment.id)}}
                                                                                        disabled={!visibility[index]}
                                                                                    >
                                                                                        <b>Actualizar</b>
                                                                                    </Button>
                                                                                    <Button 
                                                                                        variant="outlined"
                                                                                        color="inherit"
                                                                                        endIcon={ visibility[index] ? <VisibilityOffIcon/> : <RemoveRedEyeOutlinedIcon /> }
                                                                                        sx={{
                                                                                            width:{xs:'100%', sm:'90%', md:'80%'},
                                                                                            height:40,
                                                                                            backgroundColor:'#33ccff',
                                                                                            // borderColor:'#F5323F',
                                                                                            color:'white',
                                                                                            // borderWidth:2,
                                                                                            borderRadius:'7px',
                                                                                            '&:hover': {
                                                                                                backgroundColor: '#00ace6',
                                                                                                // color: 'white',
                                                                                            },
                                                                                            textTransform: 'none',
                                                                                        }}
                                                                                        onClick={()=>{getVisibility(index, !visibility[index], coment.id)}}
                                                                                    >
                                                                                        <b>{ visibility[index] ? 'Ocultar' : 'Visible' }</b>
                                                                                    </Button>
                                                                                </Box>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Box>
                                                            )
                                                        }
                                                    />
                                                </ListItem>
                                            ))
                                        ) : (
                                            <ListItem>
                                                <Box
                                                    sx={{
                                                        // backgroundColor:'yellow',
                                                        width:'70%',
                                                        textAlign:'center',
                                                    }}
                                                >
                                                    <QuestionAnswerIcon sx={{ fontSize:80, color:'#707070', }} />
                                                    <Typography variant="h6" sx={{ color:'#707070', }}>
                                                        <b>No hay comentarios ni preguntas aun.</b>
                                                    </Typography>
                                                </Box>
                                            </ListItem>
                                        )
                                    )
                                }
                                {/* <ListItem>
                                    <ListItemText 
                                        primary={
                                            <Typography variant="body1">
                                                <b>En dónde están ubicados para iR y donde tienen casas en saltillo o en Ramos?</b>
                                            </Typography>
                                        } 
                                        secondary={
                                            <Box
                                                sx={{
                                                    // backgroundColor:'yellowgreen',
                                                    width:'100%',
                                                    pl:2,
                                                }}
                                            >
                                                <Typography variant="caption" sx={{ color:'#F5323F' }}>
                                                    Respuesta:
                                                </Typography>
                                                <Typography variant="body2" sx={{  }}>
                                                    Hola! contamos con casas al sur, oriente y poniente de Saltillo, te enviamos info por inbox
                                                </Typography>
                                            </Box>
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText 
                                        primary={
                                            <Typography variant="body1">
                                                <b>¿Donde puedo pedir informes?</b>
                                            </Typography>
                                        } 
                                        secondary={
                                            <Box
                                                sx={{
                                                    // backgroundColor:'yellowgreen',
                                                    width:'100%',
                                                    pl:2,
                                                }}
                                            >
                                                <Typography variant="caption" sx={{ color:'#F5323F' }}>
                                                    Respuesta:
                                                </Typography>
                                                <Box>
                                                    <Grid 
                                                        container
                                                    >
                                                        <Grid item xs={12} sm={10} md={6}>
                                                            <Box
                                                                sx={{
                                                                    // backgroundColor:'yellow',
                                                                    width:'100%',
                                                                    display:'flex',
                                                                    alignItems:'center',
                                                                    alignContent:'center',
                                                                }}
                                                            >
                                                                <TextField 
                                                                    id="outlined-basic" 
                                                                    placeholder="Escribe una respuesta..." 
                                                                    multiline 
                                                                    rows={2} 
                                                                    variant="outlined" 
                                                                    sx={{
                                                                        backgroundColor:'white',
                                                                        width: {xs:'100%', sm:'95%', md:'95%'},
                                                                        m:'0 auto',
                                                                        ...stylePecsaMoffin,
                                                                    }}
                                                                    value={comentario}
                                                                    onChange={(e)=>setComentario(e.target.value)}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} sm={2} md={2}>
                                                            <Box
                                                                sx={{
                                                                    // backgroundColor:'yellow',
                                                                    width:'100%',
                                                                    height:60,
                                                                    display:'flex',
                                                                    alignItems:'center',
                                                                    alignContent:'center',
                                                                }}
                                                            >
                                                                <Button 
                                                                    variant="outlined"
                                                                    color="inherit"
                                                                    endIcon={<SendIcon />}
                                                                    sx={{
                                                                        width:{xs:'100%', sm:'90%', md:'80%'},
                                                                        height:40,
                                                                        backgroundColor:'#F5323F',
                                                                        // borderColor:'#F5323F',
                                                                        color:'white',
                                                                        // borderWidth:2,
                                                                        borderRadius:'7px',
                                                                        '&:hover': {
                                                                            backgroundColor: '#db0a18',
                                                                            // color: 'white',
                                                                        },
                                                                        textTransform: 'none',
                                                                    }}
                                                                    onClick={()=>{getComentarios(comentario)}}
                                                                >
                                                                    <b>Responder</b>
                                                                </Button>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        }
                                    />
                                </ListItem> */}
                            </List>
                            <Box
                                sx={{
                                    // backgroundColor:'green',
                                    width:'100%',
                                    height:{xs:70, sm:50, md:50},
                                    textAlign: {xs:'center', sm:'center', md:'right', },
                                    // display:'flex',
                                    // alignItems:'center',
                                    // alignContent:'center',
                                    
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    color="inherit"
                                    // endIcon={<SendIcon />}
                                    sx={{
                                        width:{xs:'100%', sm:'30%', md:'10%'},
                                        height:40,
                                        m:'0 auto',
                                        mt:{ xs:2, sm:0, md:0, },
                                        backgroundColor:'#bfbfbf',
                                        // borderColor:'#F5323F',
                                        color:'white',
                                        borderWidth:0,
                                        borderRadius:'7px',
                                        '&:hover': {
                                            backgroundColor: '#a6a6a6',
                                            // color: 'white',
                                        },
                                        textTransform: 'none',
                                    }}
                                    onClick={()=>navigate(-1)}
                                >
                                    <b>Volver</b>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity="success" sx={{ width: '100%' }}>
                    El Comentario ah sido respondido!
                </Alert>
            </Snackbar>
        </Container>
    );
}
export { CentroDeRespuestasResponder }