import { Box, Grid } from "@mui/material";

const ListItemComparator = ({ children }) => {
    return(
        <Grid item xs={6} sm={4} md={4}>
            <Box
                sx={{
                    // backgroundColor:'pink',
                    width:'100%',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                }}
            >
                <Box
                    sx={{
                        // backgroundColor:'greenyellow',
                        width:'90%',
                        minHeight: { xs:90, sm:90, md:90 },
                        // mt:2,
						// mb:1,
                    }}
                >
                    { children }
                </Box>
            </Box>
        </Grid>
    );
}

export { ListItemComparator }