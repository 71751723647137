import { BenefitsSection } from "./BenefitsSection";
import { AdvantagesSection } from "./AdvantagesSection";
import { CTASection } from "./CTASection";
import { HeroSection } from "./HeroSection";
import { ListingSection } from "./ListingSection";

import { Box, ThemeProvider } from "@mui/material";
import theme from "../../App/theme";
import { Helmet } from "react-helmet";
import { StepsSection } from "./StepsSection";
import { FAQSection } from "./FAQSection";
import { ScrollToTop } from "../../components/ScrollToTop";

const RentalHome = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ mt: { xs: 7, sm: 8, md: 10 } }}>
        <Helmet>
          <title>Renta una Casa Pecsa | Casas Pecsa</title>
          <meta name="description" />
        </Helmet>
        <HeroSection />
        <ScrollToTop />
        <BenefitsSection />
        <ListingSection />
        <StepsSection />
        <AdvantagesSection />
        <FAQSection />
        <CTASection />
      </Box>
    </ThemeProvider>
  );
};

export { RentalHome };
