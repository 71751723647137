import { Avatar, Breadcrumbs, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, CardMedia, Grid, IconButton, List, ListItem, ListItemText, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";

import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useState } from "react";

import asesorPerfil2 from "../../assets/asesores/asesorPerfil2.png";
import imagenPromo from "../../assets/prueba/imagenPromo.png";
import { PublicarPost } from "./PublicarPost";
import { ListaPosts } from "./ListaPosts";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

const EspacioAsesorPublicaciones = () => {
    const { folio } = useParams();

    const [posts, setPosts] = useState([]);

    useEffect(()=>{
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');

        axios.get(`${process.env.REACT_APP_API_URL}/api/asesores/profile/${folio}/posts`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
        .then((response)=>{
            console.log(response);
            setPosts(response.data);
        })
        .catch((error)=>{
            console.log(error);
        });
    },[folio]);
    return(
        <Box
            sx={{
                // backgroundColor:'red',
                width:'100%',
            }}
        >
            <Box>
                <Breadcrumbs aria-label="breadcrumb">
                    {/* <Link underline="hover" color="inherit">
                        Inicio
                    </Link> */}
                    <Link to={'/'} style={{ color:'#707070', }}>
                        Espacio Asesor
                    </Link>
                    <Typography sx={{ color:'#F5323F', }}>Publicaciones</Typography>
                </Breadcrumbs>
            </Box>
            <PublicarPost/>
            <ListaPosts posts={posts}/>
        </Box>
    );
}

export { EspacioAsesorPublicaciones }