import { Backdrop, ImageList, ImageListItem } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";

function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
}

const FotosAsesores = ({ posts }) => {
  console.log(posts);
  const [fotoSelected, setFotoSelected] = useState(null);
  const getFotoSelected = (foto) => {
    setFotoSelected(foto)
  }
  const postFotos = posts.filter((post)=>post.fotos.length > 0);
  const fotos = postFotos.flatMap((foto) => foto.fotos);
  console.log(fotos);
    return(
        <Box>
            <ImageList
                sx={{ width: '100%',  }}
                variant="quilted"
                cols={4}
                rowHeight={121}
            >
              {
                fotos.map((foto, index) => (
                  <ImageListItem
                      key={foto.url} 
                      cols={2} 
                      rows={2}
                      onClick={()=>{ getFotoSelected(foto.url); }}
                  >
                      <img
                          {...srcset(foto.url, 121, 2, 2)}
                          alt={foto.url}
                          loading="lazy"
                      />
                  </ImageListItem>
                ))
              }
                {/* {
                    itemData.map((item) => (
                        <ImageListItem
                            key={item.img} 
                            cols={item.cols || 1} 
                            rows={item.rows || 1}
                            onClick={()=>{ alert('heehehe'); }}
                        >
                            <img
                                {...srcset(item.img, 121, item.rows, item.cols)}
                                alt={item.title}
                                loading="lazy"
                            />
                        </ImageListItem>
                    ))
                } */}
            </ImageList>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={fotoSelected}
              onClick={()=>getFotoSelected(null)}
            >
              <Box
                  sx={{
                      width:'100%',
                      display:'flex', 
                      alignItems:'center', 
                      alignContent:'center',
                  }}
              >
                  <Box
                      component='img'
                      src={fotoSelected}
                      sx={{
                          m:'0 auto',
                          maxWidth:'100%',
                          maxHeight:900,
                      }}
                  />
              </Box>
            </Backdrop>
        </Box>
    );
}

export { FotosAsesores }

const itemData = [
    {
      img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
      title: 'Breakfast',
      rows: 2,
      cols: 2,
    },
    {
      img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
      title: 'Burger',
    },
    {
      img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
      title: 'Camera',
    },
    {
      img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
      title: 'Coffee',
      cols: 2,
    },
    {
      img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
      title: 'Hats',
      cols: 2,
    },
    {
      img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
      title: 'Honey',
      author: '@arwinneil',
      rows: 2,
      cols: 2,
    },
    {
      img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
      title: 'Basketball',
    },
    {
      img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
      title: 'Fern',
    },
    {
      img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
      title: 'Mushrooms',
      rows: 2,
      cols: 2,
    },
    {
      img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
      title: 'Tomato basil',
    },
    {
      img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
      title: 'Sea star',
    },
    {
      img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
      title: 'Bike',
      cols: 2,
    },
  ];