import { Box, Container } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { ModelosCarrusel } from "../ModelosCarrusel";

const ModelosPorFraccionamiento = ({ fraccionamiento }) => {
    const [modelos, setModelos] = useState([]);
    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_API_URL}/api/fraccionamiento/modelos/${fraccionamiento.id}`)
        .then((response)=>{
            setModelos(response.data)
        })
        .catch((error)=>{
            console.log(error);
        });
    },[fraccionamiento]);
    return(
        <Box sx={{ display: { xs: 'flex', md: 'flex' }, width:'100%', }}>
            <ModelosCarrusel modelos={modelos}/>
        </Box>
    );
}

export { ModelosPorFraccionamiento }