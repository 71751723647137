import React from "react";
import { Box, Typography, Link as MuiLink } from "@mui/material";
import { Link } from "react-router-dom";

/**
 * Componente BottomContent representa la sección de abajo del footer del sitio web.
 * Incluye enlaces a términos y condiciones, aviso de privacidad y la página de inicio de la empresa.
 *
 * @component
 * @example
 * // Ejemplo de uso del componente BottomContent
 * <BottomContent />
 */

// Estilos
const colors = {
  background: "#1f1f1f",
  primary: "#F5323F",
};

const commonStyles = {
  link: {
    textDecoration: "none",
    cursor: "pointer",
    color: colors.background,
    transition: `color 0.2s ease`,
    "&:hover": {
      color: colors.primary,
    },
  },
  footerText: {
    color: colors.primary,
  },
};

const containerStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  gap: 2.5,
};

const linksContainerStyles = {
  display: "flex",
  gap: 1.5,
  flexDirection: { xs: "column", sm: "row" },
};

const BottomContent = ({ PDFlinks, footerCopy }) => {
  // Función para obtener el año actual para el pie de página
  const currentYear = new Date().getFullYear();

  return (
    <Box sx={containerStyles}>
      {/* Renderiza enlaces */}
      <Box sx={linksContainerStyles}>
        {PDFlinks.map((link, index) => (
          <MuiLink
            key={index}
            href={link.href}
            target="_blank"
            rel="noopener noreferrer"
            underline="none"
            sx={{ ...commonStyles.link, textAlign: "center" }}
          >
            <Typography fontFamily="Montserrat">{link.text}</Typography>
          </MuiLink>
        ))}
      </Box>

      {/* Renderiza el título de la empresa con un enlace a la página de inicio */}
      <Typography variant="body2" sx={commonStyles.footerText}>
        <b>
          {" © "}
          <Link to={"/"} style={{ color: colors.primary }}>
            {footerCopy}
          </Link>{" "}
          {currentYear}
          {"."}
        </b>
      </Typography>
    </Box>
  );
};

export { BottomContent };
