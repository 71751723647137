import { Helmet } from "react-helmet";
import { Box, Button, Card, CardContent, CardMedia, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, Grid, InputLabel, List, ListItem, ListItemText, MenuItem, Radio, RadioGroup, Select, Step, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";

import { Elements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import { PayFormAll } from "../Pago/PayFormAll";

import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';

import ms1 from '../../assets/ms1.png';
import hotsale from '../../assets/hotsale.svg';
import React, { useEffect, useState } from "react";
import { PortaPayFormAll } from "../Pago/PortaPayFormAll";
import axios from "axios";
import TagManager from "react-gtm-module";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

// import fotoAsesor from "../../assets/asesores/cesar.png";
// import sinFotoAsesor from "../../assets/asesores/sinFotoAsesorPrueba.png";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`)

const steps = ['Datos Personales', 'Tipo de Compra', 'Realizar Pago'];

const RealizarCheckout = ({ lote }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const getSearchParams = () => {
        setSearchParams({ inversion: !(searchParams.get('inversion') === 'true' ? true : false) });
    }
    // console.log('Realizar checkout',searchParams.get('inversion'), !searchParams.get('inversion'), (searchParams.get('inversion') === 'true' ? true : false) );
    // console.log('Realizar checkout',location);
    const [clientSecret, setClientSecret] = useState(null);

    const [primerNombre, setPrimerNombre] = useState(null);
    const [segundoNombre, setSegundoNombre] = useState(null);
    const [primerApellido, setPrimerApellido] = useState(null);
    const [segundoApellido, setSegundoApellido] = useState(null);
    const [email, setEmail] = useState(null);
    const [telefono, setTelefono] = useState(null);

    const [datosPersonales, setDatosPersonales] = useState({
        primerNombre: primerNombre,
        segundoNombre: segundoNombre,
        primerApellido: primerApellido,
        segundoApellido: segundoApellido,
        email: email,
        telefono: telefono,
    });

    const checkDatosPersonales = () => {
        if(primerNombre && primerApellido && segundoApellido && email && telefono){            
            return true;
        }
        else{
            return false
        }
    }
    
    const [newCliente, setNewCliente] = useState(null);
    // const [datosPersonales, setDatosPersonales] = useState();

    // const [clientSecret, setClientSecret] = useState(null);
    
    const options = {
        // passing the client secret obtained in step 2
        clientSecret: 'pi_3LT7R5FmRKo9q8Cv0Aj645v0_secret_ku6boDGacHHuGXCwrJDVRqF9h',
        // clientSecret: clientSecret,
        // Fully customizable with appearance API.
        // appearance: {/*...*/},
    };

    const [tipoCredito, setTipoCredito] = useState(null);
    
    const getTipoCredito = (event) => {
        setTipoCredito(event.target.value);
    }

    const [fueAtendido, setFueAtendido] = useState(false);
    const getFueAtendido = () => {
        setFueAtendido(!fueAtendido);
    }
    const [asesores, setAsesores] = useState([]);
    const getAsesores = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/asesores/to-checkout`)
        .then((response)=>{
            console.log(response)
            setAsesores(response.data);
        })
        .catch((error) => {
            // handle success
            console.log(error);
        })
        .then(() => {
            // handle success
            //console.log('sabe');
        });
    }
    const [asesor, setAsesor] = useState(null);
    const getAsesor = (event) => {
        setAsesor(event.target.value);
    }

    const checkTipoDeCompra = () => {
        if(tipoCredito && ((fueAtendido && asesor) || (!fueAtendido))){
            return true;
        }
        else{
            return false
        }
    }

    const addDatosSeparacion = () => {
        console.log('Aquipondremos datos del asesor');
        // TagManager.dataLayer({
        //     dataLayer: {
        //         event:'Separacion_DatosPersonales',
        //         activar:true,
        //         datos:{
        //             lote:lote.numeroDeLote,
        //             manzana:lote.manzana.nombre,
        //             fraccionamiento: lote.manzana.etapa.fraccionamiento.nombre,                        
        //             modelo:lote.fachada.modelo.nombre,
        //             fachada:lote.fachada.nombre,
        //             folio:lote.folio,
        //             datos_persona: {
        //                 nombre:newCliente.datos.primerNombre,
        //                 apellidoPaterno:newCliente.datos.primerApellido,
        //                 apellidoMaterno:newCliente.datos.segundoApellido,
        //                 email:newCliente.autenticacion.email,
        //                 telefono:newCliente.autenticacion.telefono,
        //             },
        //         }
        //     }
        // })
    }
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };
    const addCliente = () => {
        console.log('guardarCliente');
        axios.post(`${process.env.REACT_APP_API_URL}/api/venta/checkClient/`,
        datosPersonales
        // {
        //     headers: { 
        //     Authorization: `Bearer ${token}`,
        //     }              
        // }
        )
        .then((response) => {
            // handle success
            console.log(response);
            setNewCliente(response.data);            
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigation(-1);
            //setLoteDisponible(false);
        })
        .then(() => {
            // handle success
            //console.log('sabe');
        });
    }
    const handleNext = () => {
        if(activeStep === 0){
            addCliente();
        }
        if(activeStep === 1){
            addDatosSeparacion();
        }
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this, 
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const [promocion, setPromocion] = useState(null);
    const [precioFinal, setPrecioFinal] = useState(null)

    const findPromocion = (lote) => {
        const promocionResult = {};
        if(lote.promocion){
            setPromocion(lote.promocion);
        }else{
            if(lote.manzana.promocion){
                setPromocion(lote.manzana.promocion);
            }else{
                if(lote.manzana.etapa.promocion){
                    setPromocion(lote.manzana.etapa.promocion);
                }else{
                    if(lote.manzana.etapa.fraccionamiento.promocion){
                        setPromocion(lote.manzana.etapa.fraccionamiento.promocion);
                    }else{
                        if(lote.fachada.modelo.promocion){
                            setPromocion(lote.fachada.modelo.promocion);
                        }
                    }
                }
            }
        }
    }
    const aplicaPromocion = (lote, promocion) => {
        if(promocion.cantidadADescontar){
            setPrecioFinal(lote.precio - promocion.cantidadADescontar);
        }
        if(promocion.porcentajeADescontar){
            setPrecioFinal(lote.precio - (lote.precio * (promocion.porcentajeADescontar/100)));
        }

    }

    const getFormatoMoneda = (cantidad) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD', //MXN
            minimumFractionDigits: 0
          })
      
          var value = 10000
      
          return formatter.format(cantidad)
    }
    useEffect(()=>{
        findPromocion(lote);
        // if(promocion){
        //     console.log('haaaaa');
        //     aplicaPromocion(lote, promocion)
        // }
        getAsesores();
    }, [lote]);
    useEffect(()=>{
        if(promocion){
            aplicaPromocion(lote, promocion)
        }
    }, [promocion]);
    useEffect(()=>{
        setDatosPersonales({
            primerNombre: primerNombre,
            segundoNombre: segundoNombre,
            primerApellido: primerApellido,
            segundoApellido: segundoApellido,
            email: email,
            telefono: telefono,
        });
    },[primerNombre, segundoNombre, primerApellido, segundoApellido, email, telefono]);
    return(
        <Container sx={{ mt:{ xs:9, sm:9, md:12, } }}>
            <Helmet>
                <title>Casas Pecsa | Realizar Pago</title>
                <meta name="description" content="Casas Pecsa" />
            </Helmet>
            <Box>
                <Typography variant="h5" gutterBottom component="div" sx={{ color:'#F5323F' }}>
                    <b>Realizar Pago</b>
                </Typography>
                <Grid 
                    container
                    spacing={2}
                >
                    <Grid item sm={6} md={6} >
                    {/* <Typography variant="h6" gutterBottom component="div" sx={{ color:'#F5323F' }}>
                    <b>Resumen de compra</b>
                </Typography> */}
                        <Box
                            sx={{
                                textAlign:'center',
                                p:1,
                                pt:{ xs:2, sm:2, md:4, },
                                pb:{ xs:2, sm:1, md:3, },
                                color:'#F5323F',
                            }}
                        >
                            <Typography variant="h6">
                                <b>Detalles de compra</b>
                            </Typography>
                        </Box>
                        <Card
                            sx={{
                                borderRadius: { xs:'10px', sm:'16px', md:'16px', },
                                boxShadow: { 
                                    xs:0, 
                                    sm:'0px 5px 12px 0px rgb(0, 0, 0, 0.19)', 
                                    md:'0px 5px 12px 0px rgb(0, 0, 0, 0.19)', 
                                },
                                color:'#707070',
                            }}
                        >
                            <Box>
                                {/* <Box
                                    sx={{
                                        textAlign:'center',
                                        p:1,
                                        pt:{ xs:2, sm:2, md:4, },
                                        pb:{ xs:1, sm:1, md:2, },
                                        color:'#707070',
                                    }}
                                >
                                    <Typography variant="h5">
                                        <b>Resumen de compra</b>
                                    </Typography>
                                </Box> */}
                                {/* <Divider variant="middle" /> */}
                                <List disablePadding>
                                    <ListItem>
                                        <ListItemText primary='Detalle' />
                                        <Typography variant="body2">$</Typography>
                                    </ListItem>
                                    <Divider variant="middle" component="li" />
                                    <ListItem>
                                        <ListItemText 
                                            primary='Separacion' 
                                            secondary={
                                                <Grid 
                                                    container
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <Grid item xs={3} sm={3} md={3} sx={{  }}>
                                                        <CardMedia
                                                            component="img"
                                                            sx={{ 
                                                                width: { xs:65, sm:60, md:100 },
                                                                height: { xs:70, sm:65, md:100 },
                                                            }}
                                                            image={lote.fotos[0].url}
                                                            alt="Live from space album cover"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={9} sm={9} md={9} sx={{  }}>
                                                        <Typography component="div" variant="subtitle2" sx={{ color:'#F5323F' }}>
                                                            Modelo <b>{ lote.fachada.modelo.nombre }</b>
                                                        </Typography>
                                                        {
                                                            promocion && (
                                                                !promocion.otro ? (
                                                                    <Typography variant="caption" component="div">
                                                                        <del>{`${getFormatoMoneda(lote.precio)}`}</del>
                                                                        {
                                                                            // ` -${getFormatoMoneda(promocion.cantidadADescontar)}`
                                                                            promocion.cantidadADescontar ? ` -${getFormatoMoneda(promocion.cantidadADescontar)}`
                                                                            : ` -${promocion.porcentajeADescontar}%`
                                                                        }
                                                                        <Box
                                                                            component='img'
                                                                            src={promocion.logo}
                                                                            sx={{ width:35 }}
                                                                        />
                                                                    </Typography>
                                                                ) : (
                                                                    <Typography variant="caption" component="div">
                                                                        {/* <del>{`${getFormatoMoneda(lote.precio)}`}</del> */}
                                                                        {
                                                                            `${promocion.otro}`
                                                                        }
                                                                        <Box
                                                                            component='img'
                                                                            src={promocion.logo}
                                                                            sx={{ width:35 }}
                                                                        />
                                                                    </Typography>
                                                                )
                                                                
                                                            )
                                                        }
                                                        {/* <Typography variant="caption" component="div">
                                                            <del>{`${getFormatoMoneda(2000000)}`}</del>
                                                            {
                                                                ` -${getFormatoMoneda(100000)}`                                            
                                                            }
                                                            <Box
                                                                component='img'
                                                                src={hotsale}
                                                                sx={{ width:15 }}
                                                            />
                                                        </Typography> */}
                                                                            
                                                        <Typography variant="caption" component="div">
                                                            <b>
                                                                {
                                                                    !promocion ? 
                                                                    (
                                                                        getFormatoMoneda(lote.precio)
                                                                    ) : (
                                                                        promocion.otro ? (
                                                                            getFormatoMoneda(lote.precio)
                                                                        ) : (
                                                                            `${getFormatoMoneda(precioFinal)} `
                                                                        )
                                                                        
                                                                    ) 
                                                                }
                                                            </b>
                                                        </Typography>
                                                        <Typography variant="caption" component="div">
                                                            Fraccionamiento: <b>{ lote.manzana.etapa.fraccionamiento.nombre }</b>
                                                        </Typography>
                                                        <Typography variant="caption" component="div">
                                                            Manzana: <b>{ lote.manzana.nombre }</b>
                                                        </Typography>
                                                        <Typography variant="caption" component="div">
                                                            Lote: <b>{ lote.numeroDeLote }</b>
                                                        </Typography>
                                                        <Typography variant="caption" component="div">
                                                            Fachada: <b>{ lote.fachada.nombre }</b>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                
                                            } 
                                        />
                                        <Typography variant="body2"><b>{getFormatoMoneda(7000)}</b></Typography>
                                    </ListItem>
                                    <Divider variant="middle" component="li" />
                                    <ListItem>
                                        <ListItemText primary='Descuento' />
                                        <Typography variant="body2"><b>{`- ${getFormatoMoneda(0)}`}</b></Typography>
                                    </ListItem>
                                    <Divider variant="middle" component="li" />
                                    <ListItem>
                                        <ListItemText primary={<Typography variant="body1"><b>Total</b></Typography>} />
                                        <Typography variant="body1"><b>{getFormatoMoneda(7000)}</b></Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Box sx={{ width:'100%',  display:'block', alignItems:'center', alignContent:'center' }}>
                                            <Box sx={{ width:{ xs:'100%', sm:'90%', md:'80%'}, textAlign:'left', m:'0 auto', }}>
                                                <Typography variant="caption">
                                                    <b>-La separación NO es reembolsable bajo ninguna circunstancia.</b>
                                                </Typography>
                                            </Box>
                                            <Box sx={{ width:{ xs:'100%', sm:'90%', md:'80%'}, textAlign:'left', m:'0 auto', }}>
                                                <Typography variant="caption">
                                                    <b>-Se cuentan con 3 días hábiles para entrega de papelería completa</b>
                                                </Typography>
                                            </Box>
                                            <Box sx={{ width:{ xs:'100%', sm:'90%', md:'80%'}, textAlign:'left', m:'0 auto', }}>
                                                <Typography variant="caption">
                                                    <b>-De no cumplir el plazo de entrega de papelería se perderá la ubicación</b>
                                                </Typography>
                                            </Box>
                                            <Box sx={{ width:{ xs:'100%', sm:'90%', md:'80%'}, textAlign:'left', m:'0 auto', }}>
                                                <Typography variant="caption">
                                                    <b>-En cambio de ubicación ya no será válida la promoción(en caso de aplicar)</b>
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </ListItem>
                                </List>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item sm={6} md={6} >
                        <Box
                            sx={{
                                textAlign:'center',
                                p:1,
                                pt:{ xs:2, sm:2, md:4, },
                                pb:{ xs:2, sm:1, md:3, },
                                color:'#F5323F',
                            }}
                        >
                            <Typography variant="h6">
                                <b>Datos de compra</b>
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                minHeight:450,
                            }}
                        >
                            <Stepper activeStep={activeStep}>
                                {
                                    steps.map((label, index) => {
                                        const stepProps = {};
                                        const labelProps = {};
                                        // if (isStepOptional(index)) {
                                        //     labelProps.optional = (
                                        //         <Typography variant="caption">Optional</Typography>
                                        //     );
                                        // }
                                        if (isStepSkipped(index)) {
                                            stepProps.completed = false;
                                        }
                                        return (
                                            <Step key={label} {...stepProps}
                                            sx={{
                                                '& .MuiStepLabel-root .Mui-completed': {
                                                  color: '#F5323F', // circle color (COMPLETED)
                                                },
                                                '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                                                  {
                                                    color: 'green', // Just text label (COMPLETED)
                                                  },
                                                '& .MuiStepLabel-root .Mui-active': {
                                                  color: '#F5323F', // circle color (ACTIVE)
                                                },
                                                '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                                                  {
                                                    color: 'orange', // Just text label (ACTIVE)
                                                  },
                                                '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                                  fill: 'white', // circle's number (ACTIVE)
                                                },
                                              }}
                                            >
                                                <StepLabel {...labelProps} color="error">{label}</StepLabel>
                                            </Step>
                                        );
                                    })
                                }
                            </Stepper>
                            {
                                activeStep === 0 && (
                                    <Box
                                        sx={{
                                            minHeight:400,
                                            mt:5
                                        }}
                                    >
                                        <Grid container spacing={{ xs:2, sm: 1, md:2 }}>
                                            <Grid item xs={12} sm={6} md={6} >
                                                <TextField id="outlined-basic" label="Primer Nombre" variant="outlined" name="primerNombre" sx={{ width:'100%' }} defaultValue={primerNombre} onChange={(e)=>setPrimerNombre(e.target.value)} required/>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} >
                                                <TextField id="outlined-basic" label="Segundo Nombre" variant="outlined" name="segundoNombre" sx={{ width:'100%' }} defaultValue={segundoNombre} onChange={(e)=>setSegundoNombre(e.target.value)} />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} >
                                                <TextField id="outlined-basic" label="Primer Apellido" variant="outlined" name="primerApellido" sx={{ width:'100%' }} defaultValue={primerApellido} onChange={(e)=>setPrimerApellido(e.target.value)} required/>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} >
                                                <TextField id="outlined-basic" label="Segundo Apellido" variant="outlined" name="segundoApellido" sx={{ width:'100%' }} defaultValue={segundoApellido} onChange={(e)=>setSegundoApellido(e.target.value)} required/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} >
                                                <TextField id="outlined-basic" label="Correo Electrónico" variant="outlined" name="email" sx={{ width:'100%' }} defaultValue={email} onChange={(e)=>setEmail(e.target.value)} required/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} >
                                                <TextField id="outlined-basic" label="Número Celular" variant="outlined" name="telefono" sx={{ width:'100%' }} defaultValue={telefono} onChange={(e)=>setTelefono(e.target.value)} required/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )
                            }
                            {
                                activeStep === 1 && (
                                    <Box
                                        sx={{
                                            minHeight:400,
                                            mt:5
                                        }}
                                    >
                                        {
                                            newCliente ? (
                                                <Grid container spacing={{ xs:2, sm: 1, md:2 }}>
                                                    <Grid item sm={12} md={9} >
                                                        <Typography variant="body1" gutterBottom component="div" sx={{ color:'#707070' }}>
                                                            Por que medio deseas pagar el monto total de tu vivienda:
                                                        </Typography>
                                                        <Box sx={{ minWidth: 120 }}>
                                                            <FormControl required fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Tipo de Compra</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={tipoCredito}
                                                                    label="Tipo de Compra"
                                                                    onChange={getTipoCredito}
                                                                    name='tipoCompra'
                                                                    
                                                                >
                                                                    <MenuItem value={1}>Contado</MenuItem>
                                                                    <MenuItem value={2}>Credito</MenuItem>
                                                                    
                                                                </Select>
                                                            </FormControl>
                                                        </Box>
                                                        <Divider sx={{ mt:2, }}/>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={9} >
                                                        <Typography variant="body1" gutterBottom component="div" sx={{ color:'#707070' }}>
                                                            ¿Te gustaría poner en renta tu nueva casa Pecsa?
                                                        </Typography>
                                                        <Typography variant="body2" gutterBottom component="div" sx={{ color:'#707070' }}>
                                                            Esto podría brindarte ingresos adicionales a largo plazo al tiempo que nosotros nos encargamos de encontrar inquilinos adecuados. ¡Hablemos de cómo hacer que tu inversión sea aún más valiosa!
                                                        </Typography>
                                                        <Box sx={{ minWidth: 120 }}>
                                                            <FormControlLabel                                                                
                                                                control={
                                                                    <Checkbox 
                                                                        checked={searchParams.get('inversion') === 'true' ? true : false}
                                                                        onChange={getSearchParams}
                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                    />
                                                                }
                                                                label="Separar para invertir"                                                                
                                                            />
                                                        </Box>
                                                        <Divider/>
                                                    </Grid>                                                    
                                                    <Grid item xs={12} sm={12} md={9} >
                                                        <Typography variant="body1" gutterBottom component="div" sx={{ color:'#707070' }}>
                                                            ¿Fue atendido por algun asesor de Pecsa?
                                                        </Typography>
                                                        <Box sx={{ minWidth: 120 }}>
                                                            <FormControl required fullWidth>
                                                                <RadioGroup
                                                                    row
                                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                                    name="row-radio-buttons-group"
                                                                    value={fueAtendido}
                                                                    onChange={getFueAtendido}
                                                                >
                                                                    <FormControlLabel value={true} control={<Radio />} label="Si" />
                                                                    <FormControlLabel value={false} control={<Radio />} label="No" />                                                                    
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Box>
                                                    </Grid>                                                    
                                                    <Grid item xs={12} sm={12} md={9} >
                                                        {
                                                            fueAtendido && (
                                                                <Box sx={{ minWidth: 120, }}>
                                                                    <FormControl required fullWidth>
                                                                        <InputLabel id="demo-simple-select-label">Elige al Asesor</InputLabel>
                                                                        <Select
                                                                            labelId="demo-simple-select-label"
                                                                            id="demo-simple-select"
                                                                            value={asesor}
                                                                            label="Elige al Asesor"
                                                                            onChange={getAsesor}
                                                                            name='asesorQueTeAtendio'
                                                                            
                                                                        >
                                                                            {/* <MenuItem value={0}>
                                                                                <Box
                                                                                    sx={{ width:'100%', }}
                                                                                >
                                                                                    <Typography variant="body1">
                                                                                        <b>Otro</b>
                                                                                    </Typography>
                                                                                </Box>
                                                                            </MenuItem> */}
                                                                            {
                                                                                asesores.map((asesor) => (
                                                                                    <MenuItem value={asesor.id}>
                                                                                        <Box
                                                                                            sx={{
                                                                                                // backgroundColor:'yellow',
                                                                                                width:'100%',
                                                                                                height:150,
                                                                                                display:'flex'
                                                                                            }}
                                                                                        >
                                                                                            <Box
                                                                                                sx={{
                                                                                                    // backgroundColor:'green',
                                                                                                    width:'30%',
                                                                                                    height:150,
                                                                                                    display:'flex',
                                                                                                    alignItems:'center',
                                                                                                    alignContent:'center',
                                                                                                    overflow:'hidden',
                                                                                                    borderRadius:'10px',
                                                                                                }}
                                                                                            >
                                                                                                <Box
                                                                                                    component='img'
                                                                                                    src={asesor.foto}
                                                                                                    sx={{
                                                                                                        maxHeight:'100%',
                                                                                                        m:'0 auto',
                                                                                                    }}
                                                                                                />
                                                                                            </Box>
                                                                                            <Box
                                                                                                sx={{
                                                                                                    // backgroundColor:'yellowgreen',
                                                                                                    width:'70%',
                                                                                                    display:'flex',
                                                                                                    alignItems:'center',
                                                                                                    alignContent:'center',
                                                                                                    pl:1,
                                                                                                }}
                                                                                            >
                                                                                                <Typography variant="body1">
                                                                                                    <b>{ asesor.nombre }</b>
                                                                                                </Typography>
                                                                                            </Box>                                                                            
                                                                                        </Box>
                                                                                    </MenuItem>
                                                                                ))
                                                                            }                                                                            
                                                                        </Select>
                                                                    </FormControl>
                                                                </Box>
                                                            )
                                                        }
                                                                

                                                    </Grid>
                                                </Grid>
                                            ) : (
                                                <Box
                                                    sx={{
                                                        // backgroundColor:'pink',
                                                        width:'100%',
                                                        display:'flex',
                                                        flexDirection:'column',
                                                        alignItems:'center',
                                                        justifyContent:'center',
                                                        color:'#707070'
                                                    }}
                                                >
                                                    <CircularProgress sx={{ color:'#F5323F' }} />
                                                    <Typography>
                                                        Cargando...
                                                    </Typography>
                                                </Box>
                                            )
                                        }
                                    </Box>
                                )
                            }
                            {
                                activeStep === 2 && (
                                    <Box
                                        sx={{
                                            minHeight:400,
                                            mt:5
                                        }}
                                    >
                                        <Grid container spacing={{ xs:2, sm: 1, md:2 }}>
                                        <Grid item xs={12}  sm={12} md={12} >
                                            <Box sx={{ fontSize: 9, mt:1, color:'#707070' }}>
                                                <PortaPayFormAll lote={lote} cliente={newCliente} asesor={asesor} />
                                                {/* <Elements stripe={stripePromise} options={options}>
                                                    <PayFormAll lote={{}} transaccion={{}} promocion={{}}/>
                                                </Elements> */}
                                            </Box>
                                        </Grid>
                                        </Grid>
                                    </Box>
                                )
                            }
                            <Box 
                                sx={{
                                    // backgroundColor:'red',
                                    height:40,
                                    display:'flex', 
                                    flexDirection: 'row', 
                                    pt: 2 
                                }}>
                                <Button
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1, color: '#F5323F', }}
                                >
                                    <b>Atras</b>
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />
                                {/* {
                                    isStepOptional(activeStep) && (
                                        <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                            Skip
                                        </Button>
                                    )
                                } */}
                                {/* <Button onClick={handleNext}>
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                </Button> */}
                                {/* <Button disabled={activeStep === steps.length -1 ? true : checkDatosPersonales() ? false : true } onClick={handleNext} sx={{ color: '#F5323F', }}>
                                    <b>Siguiente</b>
                                </Button> */}
                                <Button 
                                    disabled={
                                        activeStep === steps.length -1 ? true 
                                        : (activeStep === 0 && !checkDatosPersonales()) ? true 
                                            : (activeStep === 1 && !checkTipoDeCompra()) ? true 
                                                : false 
                                    } 
                                    onClick={handleNext} 
                                    sx={{ color: '#F5323F', }}
                                >
                                    <b>Siguiente</b>
                                </Button>
                                {/* {
                                    activeStep < steps.length -1 && (
                                        <Button onClick={handleNext} sx={{ color: '#F5323F', }}>
                                            <b>Siguiente</b>
                                        </Button>
                                    )
                                } */}
                            </Box>
                        </Box>
                        {/* <Grid container spacing={{ xs:2, sm: 1, md:2 }}>
                            <Grid item sm={6} md={6} >
                                <TextField id="outlined-basic" label="Primer Nombre" variant="outlined" name="primerNombre" sx={{ width:'100%' }} required/>
                            </Grid>
                            <Grid item sm={6} md={6} >
                                <TextField id="outlined-basic" label="Segundo Nombre" variant="outlined" name="segundoNombre" sx={{ width:'100%' }}/>
                            </Grid>
                            <Grid item sm={6} md={6} >
                                <TextField id="outlined-basic" label="Primer Apellido" variant="outlined" name="primerApellido" sx={{ width:'100%' }} required/>
                            </Grid>
                            <Grid item sm={6} md={6} >
                                <TextField id="outlined-basic" label="Segundo Apellido" variant="outlined" name="segundoApellido" sx={{ width:'100%' }} required/>
                            </Grid>
                            <Grid item sm={12} md={12} >
                                <TextField id="outlined-basic" label="email Electrónico" variant="outlined" name="email" sx={{ width:'100%' }} required/>
                            </Grid>
                            <Grid item sm={12} md={12} >
                                <TextField id="outlined-basic" label="Número Celular" variant="outlined" name="telefono" sx={{ width:'100%' }} required/>
                            </Grid>                            
                            <Grid item sm={12} md={9} >
                                <Typography variant="body1" gutterBottom component="div" sx={{ color:'#707070' }}>
                                    Por que medio deseas pagar el monto total de tu vivienda:
                                </Typography>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl required fullWidth>
                                        <InputLabel id="demo-simple-select-label">Tipo de Compra</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={tipoCredito}
                                            label="Tipo de Compra"
                                            onChange={getTipoCredito}
                                            name='tipoCompra'
                                            
                                        >
                                            <MenuItem value={1}>Contado</MenuItem>
                                            <MenuItem value={2}>Credito</MenuItem>
                                            
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item sm={12} md={12} >
                                <Box sx={{ fontSize: 9, mt:1, color:'#707070' }}>
                                    <Elements stripe={stripePromise} options={options}>
                                        <PayFormAll lote={{}} transaccion={{}} promocion={{}}/>
                                    </Elements>
                                </Box>
                            </Grid>
                            <Grid item sm={12} md={12} >
                                
                            </Grid>
                            <Grid item sm={12} md={12} >
                                
                            </Grid>
                            <Grid item sm={12} md={12} >
                                
                            </Grid>
                        </Grid> */}
                    </Grid>
                </Grid>
            </Box>            
        </Container>
    );
}

export { RealizarCheckout }