import React, { useCallback, useEffect, useState } from "react";
import Carousel from 'react-material-ui-carousel'
import { Box, Container, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography, SvgIcon, Button, IconButton, CardMedia, Modal, CircularProgress, Chip, Divider } from "@mui/material";
import { motion } from "framer-motion";

import { ReactComponent as banio } from '../../assets/banio_icon.svg'
import { ReactComponent as terreno } from '../../assets/terreno_icon.svg'
import { ReactComponent as construccion } from '../../assets/construccion_icon.svg'
import { ReactComponent as habitaciones } from '../../assets/habitaciones_icon.svg'
import { ReactComponent as casita } from '../../assets/casita.svg'

import m146 from '../../assets/casas/m146_1.png';
import { useRef } from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import { useNavigate, useLocation, Link } from "react-router-dom";

import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { PanoramaSencillo } from "../../components/Panoramas/PanoramaSencillo";

import CloseIcon from '@mui/icons-material/Close';
import { textAlign } from "@mui/system";

import moment from 'moment';
import axios from "axios";

import StarRoundedIcon from '@mui/icons-material/StarRounded';

import { RWebShare } from "react-web-share";
import { openNewTab } from "../../functions/openNewTab";

const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Principal = ({ lote, masVisitado }) => {
    let navigate = useNavigate();
    let { pathname } = useLocation();
    console.log(pathname);
    const d = new Date().toISOString().slice(0, 19).replace('T', ' ');
    const dd = new Date();
    console.log('borrame ehh:::',lote);
    const [like, setLike] = useState(false);
    const [likesCount, setLikesCount] = useState(0);

    const getLike = () => {
        setLike(true);
        setLikesCount(likesCount+1);

        axios.post(`${process.env.REACT_APP_API_URL}/api/socialNetwork/like`, {
            idLote:lote.id,
            fecha:moment().format('YYYY-MM-DD'),
            hora:moment().format('h:mm:ss'),
        })
        .then((response) => {
            // handle success
            console.log(response);
        })
        .catch((error) => {
            // handle success
            console.log(error);
        })
        .then(() => {
            // handle success
            //console.log('sabea');
        });
        
    }
    const [views, setViews] = useState(0);

    const getView = useCallback(() => {
        // setLike(true);
        // setLikesCount(likesCount+1);
        setViews(views+1);

        axios.post(`${process.env.REACT_APP_API_URL}/api/socialNetwork/view`, {
            idLote:lote.id,
            fecha:moment().format('YYYY-MM-DD'),
            hora:moment().format('h:mm:ss'),
        })
        .then((response) => {
            // handle success
            console.log(response);
        })
        .catch((error) => {
            // handle success
            console.log(error);
        })
        .then(() => {
            // handle success
            //console.log('sabea');
        });
        
    }, [lote]);
    const [share, setShare] = useState(false);
    // const [sharesCount, setSharesCount] = useState(lote.likes.length || 0);

    const getShare = () => {
        //setShare(true);
        // setLikesCount(likesCount+1);

        axios.post(`${process.env.REACT_APP_API_URL}/api/socialNetwork/share`, {
            idLote:lote.id,
            fecha:moment().format('YYYY-MM-DD'),
            hora:moment().format('h:mm:ss'),
        })
        .then((response) => {
            // handle success
            console.log(response);
        })
        .catch((error) => {
            // handle success
            console.log(error);
        })
        .then(() => {
            // handle success
            //console.log('sabea');
        });
        
    }

    const reedirigir = () =>{
        console.log('sss');
        window.open(lote.fachada.modelo.linkTourVirtual)
    }
    const reedirigirCalendly = () =>{
        window.open('https://calendly.com/casas-pecsa')
    }

    const reedirigirTerminosYcondiciones = (terminosYcondiciones) =>{
        console.log('abrir', terminosYcondiciones);
        window.open(terminosYcondiciones)
    }

    const [fotoPrincipal, setFotoPrincipal] = useState(lote.fotos[0].url || null);
    const [fotos, setFotos] = useState(lote.fotos || []);

    const constraintsRef = useRef(null);
    const containerRef = useRef(null);

    const [Xside, setXside] = React.useState(0);
    const atras = (Xside) => {
      setXside(Xside+395.96);
      console.log(constraintsRef.current.clientWidth);
      console.log(containerRef.current.clientWidth);
    };
    const adelante = (Xside) => {
      setXside(Xside-395.96);
      console.log(constraintsRef.current.clientWidth);
    console.log(containerRef.current.clientWidth);
    };

    const [promocion, setPromocion] = useState(null);
    const [precioFinal, setPrecioFinal] = useState(null)

    const findPromocion = (lote) => {
        const promocionResult = {};
        if(lote.promocion){
            setPromocion(lote.promocion);
        }else{
            if(lote.manzana.promocion){
                setPromocion(lote.manzana.promocion);
            }else{
                if(lote.manzana.etapa.promocion){
                    setPromocion(lote.manzana.etapa.promocion);
                }else{
                    if(lote.manzana.etapa.fraccionamiento.promocion){
                        setPromocion(lote.manzana.etapa.fraccionamiento.promocion);
                    }else{
                        if(lote.fachada.modelo.promocion){
                            setPromocion(lote.fachada.modelo.promocion);
                        }
                    }
                }
            }
        }
    }
    const aplicaPromocion = (lote, promocion) => {
        if(promocion.cantidadADescontar){
            setPrecioFinal(lote.precio - promocion.cantidadADescontar);
        }
        if(promocion.porcentajeADescontar){
            setPrecioFinal(lote.precio - (lote.precio * (promocion.porcentajeADescontar/100)));
        }
        if(promocion.otro){
            setPrecioFinal(lote.precio);
        }

    }

    const getFormatoMoneda = (cantidad) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD', //MXN
            minimumFractionDigits: 0
          })
      
          var value = 10000
      
          return formatter.format(cantidad)
    }
    
    const [openModalRecorridoVirtual, setOpenModalRecorridoVirtual] = useState(false);
    const handleOpenModalRecorridoVirtual = () => setOpenModalRecorridoVirtual(true);
    const handleCloseModalRecorridoVirtual = () => setOpenModalRecorridoVirtual(false);

    // const [recorridoVirtualLoading, setRecorridoVirtualLoading] = useState(false);

    // const handleMostrarModalRecorridoVirtual = () => {
    //     if(lote.linkTourVirtual){
    //         setRecorridoVirtualLoading(true);
    //         setTimeout(() => {
    //             setRecorridoVirtualLoading(false);
    //           }, 4000);
    //     }else{

    //     }
    // };

    // useEffect(()=>{
    //     if(!lote.linkTourVirtual){
    //         setRecorridoVirtualLoading(0);
    //         const timer = setInterval(() => {
    //             setRecorridoVirtualLoading((prevProgress) => prevProgress + 10);
    //           }, 2000);
    //           return () => {
    //             clearInterval(timer);
    //           };
    //     }else{
    //         setRecorridoVirtualLoading(0);
    //     }
    // }, [lote])
    // useEffect(()=>{
    //     if(openModalRecorridoVirtual){
    //         handleMostrarModalRecorridoVirtual();
    //     }
    // },[openModalRecorridoVirtual])

    useEffect(()=>{
        // setViews(views+1);
        // getView();
    },[])
    useEffect(()=>{
        findPromocion(lote);
        // if(promocion){
        //     console.log('haaaaa');
        //     aplicaPromocion(lote, promocion)
        // }
        console.log(d);
        console.log(dd);
        console.log(moment().format('YYYY-MM-DD'));
        console.log(moment().format('h:mm:ss'));
        getView();
        if(lote.likes){
            setLikesCount(lote.likes.length);
        }
        if(lote.views){
            setViews(lote.views.length);
        }        
    }, [lote]);
    useEffect(()=>{
        if(promocion){
            aplicaPromocion(lote, promocion)
        }
    }, [promocion]);
    return(
        <Container sx={{ backgroundColor:'white' }}>
            <Grid 
                container 
                // columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 2 }}
                
            >
                <Grid item xs={12} sm={7} md={7} sx={{ display: { xs:'none', sm:'flex', md:'flex' } }}>
                    <Box
                        sx={{
                            // backgroundColor:'red',
                            width:'100%',
                            height: { sm:510, md:610, }
                        }}
                    >
                        <Box
                            sx={{
                                // backgroundColor:'green',
                                width:'100%',
                                height: {  sm:380, md:450 },
                                display:'flex',
                                justifyContent:'center',
                            }}
                        >
                            {
                                fotoPrincipal && (
                                    <Box
                                        component="img"
                                        src={fotoPrincipal}

                                        sx={{
                                            //width:{  sm:'95%', md:'95%' },
                                            m:1,
                                            maxWidth: {  sm:'95%', md:'95%' },
                                            maxHeight: {  sm:380, md:450 }
                                            
                                        }}
                                    />
                                )
                            }
                        </Box>
                        <Box
                            component={motion.div}
                            ref={constraintsRef}
                            sx={{
                                backgroundColor:'white', 
                                display: 'flex',
                                placeContent: 'start',
                                placeItems: 'center',
                                overflow: 'hidden',
                                position:'relative'
                            }}
                        >
                            <Stack
                                    ref={containerRef}
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}
                                    component={motion.div}
                                    animate={{ x:Xside }}
                                    
                            >
                                {
                                    fotos && (
                                        fotos.map((foto) => (
                                            <CardMedia
                                                component="img"
                                                // height={100}
                                                // width={100}
                                                image={foto.url}
                                                alt="green iguana"
                                                onClick={()=>{ setFotoPrincipal(foto.url) }}
                                                sx={{
                                                    width:{  sm:70, md:100 },
                                                    mexHeight: {  sm:65, md:95 },
                                                    mr:2,
                                                    mb:1,
                                                    mt:1,
                                                    // height: {  sm:380, md:500 },
                                                    //pointerEvents: 'pointer',
                                                }}
                                            />
                                            // <Box
                                            //     component="img"
                                            //     onClick={()=>{ setFotoPrincipal(foto.url) }}
                                            //     sx={{ 
                                            //         // width: '375px'
                                            //         // width: '185px'
                                            //         width:{ xs: '55px', sm:'100px', md:'140px', lg:'140px' },
                                            //         pointerEvents: 'none',
                                                    
                                                                                        
                                            //     }}
                                            //     src={foto.url}
                                            // />
                                        ))
                                    )
                                }
                                                                
                            </Stack>                            
                        </Box>
                        
                            {
                                fotos.length > 5 && (
                                    <Box
                                        sx={{
                                            // backgroundColor:'green',
                                            height:{  sm:40, md:40 }

                                        }}
                                    >
                                        <Grid 
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                        >
                                                <Grid item md={1}>
                                                    <IconButton aria-label="delete" size="small" onClick={()=> atras(Xside)}>
                                                        <ChevronLeftIcon />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item md={1}>
                                                    <IconButton aria-label="delete" size="small" onClick={()=> adelante(Xside)}>
                                                        <ChevronRightIcon />
                                                    </IconButton>
                                                </Grid>
                                        </Grid>
                                    </Box>
                                )
                            }                                    
                    </Box>
                    {/* <Stack
                        direction="column"
                        justifyContent="center"
                        //alignItems="flex-start"                        
                    >
                        {
                            fotoPrincipal && (
                                <Box
                                    component="img"
                                    src={fotoPrincipal}

                                    sx={{
                                        width:{  sm:'95%', md:'95%' },
                                        m:1,
                                        height: {  sm:380, md:500 }
                                        
                                    }}
                                />
                            )
                        }
                        <Box
                            sx={{
                                backgroundColor:'red',
                                width:'100%'
                            }}
                        >
                            {
                                fotoPrincipal && (
                                    <CardMedia
                                        component="img"
                                        // height={{ sm:380, md:500, }}
                                        image={fotoPrincipal}
                                        alt="green iguana"
                                        sx={{
                                            height: {  sm:380, md:500 },
                                        }}
                                    />
                                )
                            }
                        </Box>
                           
                            <Box
                                component={motion.div}
                                ref={constraintsRef}
                                sx={{
                                    backgroundColor:'white', 
                                    display: 'flex',
                                    placeContent: 'start',
                                    placeItems: 'center',
                                    overflow: 'hidden',
                                    position:'relative'
                                }}
                            >
                                <Stack
                                        ref={containerRef}
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={2}
                                        component={motion.div}
                                        animate={{ x:Xside }}
                                        
                                >
                                    {
                                        fotos && (
                                            fotos.map((foto) => (
                                                <Box
                                                    component="img"
                                                    onClick={()=>{ setFotoPrincipal(foto.url) }}
                                                    sx={{ 
                                                        // width: '375px'
                                                        // width: '185px'
                                                        width:{ xs: '55px', sm:'100px', md:'140px', lg:'140px' },
                                                        pointerEvents: 'none',
                                                        
                                                                                            
                                                    }}
                                                    src={foto.url}
                                                />
                                            ))
                                        )
                                    }
                                                                 
                                </Stack>
                            </Box>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                            sx={{
                                paddingRight:3
                            }}
                        >
                            <IconButton aria-label="delete" size="small" onClick={()=> atras(Xside)}>
                                <ChevronLeftIcon />
                            </IconButton>
                            <IconButton aria-label="delete" size="small" onClick={()=> adelante(Xside)}>
                                <ChevronRightIcon />
                            </IconButton>
                        </Stack>
                    </Stack> */}
                </Grid>
                <Grid item xs={12} sm={7} md={12} sx={{ display: { xs:'flex', sm:'none', md:'none' } }}>
                <Carousel sx={{ width:'100%' }}>
                        {
                            fotos &&(
                                fotos.map((foto) => (
                                    <Box
                                        sx={{
                                            width:'100%',
                                            height:380,
                                            display:'flex',
                                            justifyContent:'center'
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            src={foto.url}

                                            sx={{
                                                //width:'80%',
                                                //idth: '100%',
                                                maxHeight:400,
                                            
                                            }}
                                        />
                                    </Box>                                    
                                ))
                            )
                            
                        }
                    </Carousel>
                </Grid>
                <Grid item xs={12} sm={5} md={5}>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={0.5}
                        sx={{
                            paddingTop:{ sm:1, md:2 },
                            color:'#707070'
                        }}
                    >
                        <Typography variant="h4" component="div" sx={{ color:'#F5323F' }}>
                            MODELO <b>{lote.fachada.modelo.nombre}</b>
                        </Typography>
                        {
                            masVisitado && (
                                <Chip 
                                    icon={<StarRoundedIcon sx={{ color:'#F1C40F', }}/>} 
                                    size="small" 
                                    label="Mas Visitado" 
                                    variant="outlined" 
                                    sx={{
                                        color:'#F1C40F',
                                        borderColor:'#F1C40F',
                                    }}
                                    color='warning'
                                />
                            )
                        }
                        <Typography variant="body1" component="div">
                            {/* <b>{`${lote.superficieDeTerreno}m² de construcción`} </b> */}
                            <b>{`${lote.fachada.modelo.superficieDeConstruccion}m² de construcción`} </b>
                        </Typography>
                        {
                            !lote.datosDeInversion ? (
                                <>
                                    {
                                        promocion && (
                                            !promocion.otro ? (
                                                <Typography variant="h5" component="div" sx={{ color:'#CDCDCD', display:'flex' }}>
                                                    <del>{`${getFormatoMoneda(lote.precio)}`}</del>&nbsp;
                                                    <Typography variant="h6" component="div" sx={{ color:'#CDCDCD' }}>
                                                        {
                                                            promocion.cantidadADescontar ? ` -${getFormatoMoneda(promocion.cantidadADescontar)}`
                                                            : ` -${promocion.porcentajeADescontar}%`
                                                        }
                                                    </Typography>
                                                        {/* {
                                                            promocion.cantidadADescontar ? ` -${getFormatoMoneda(promocion.cantidadADescontar)}`
                                                            : ` -${promocion.porcentajeADescontar}%`
                                                        } */}
                                                    <Box
                                                        component='img'
                                                        src={promocion.logo}
                                                        // sx={{ width:70 }}
                                                        sx={{ maxHeight:40 }}
                                                    />
                                                </Typography>
                                            ) : (
                                                <Typography variant="h5" component="div" sx={{ color:'#CDCDCD', display:'flex' }}>
                                                    {/* <del>{`${getFormatoMoneda(lote.precio)}`}</del>&nbsp; */}
                                                    <Typography variant="h6" component="div" sx={{ color:'#CDCDCD' }}>
                                                        {
                                                            `${promocion.otro} `
                                                        }
                                                    </Typography>
                                                        {/* {
                                                            promocion.cantidadADescontar ? ` -${getFormatoMoneda(promocion.cantidadADescontar)}`
                                                            : ` -${promocion.porcentajeADescontar}%`
                                                        } */}
                                                    <Box
                                                        component='img'
                                                        src={promocion.logo}
                                                        // sx={{ width:70 }}
                                                        sx={{ maxHeight:40 }}
                                                    />
                                                </Typography>
                                            )
                                        )
                                    }
                                    <Typography variant="h4" component="div">
                                    {
                                            !promocion ? 
                                            (
                                                getFormatoMoneda(lote.precio)
                                            ) : (
                                                `${getFormatoMoneda(precioFinal)} `
                                            ) 
                                        }
                                    </Typography>
                                    {
                                        promocion && (
                                            // <Typography variant="caption" display="block" gutterBottom onClick={()=>{ alert('hey')}}>
                                            //     <ArticleOutlinedIcon sx={{ fontSize:15 }}/> Terminos y condiciones de la promocion. 
                                            // </Typography>
                                            <Button variant="text" size="small" color="inherit" startIcon={<ArticleOutlinedIcon/>} sx={{ color:'#F5323F', fontSize:9 }} onClick={()=>{reedirigirTerminosYcondiciones(promocion.terminosYCondiciones)}}>
                                                Terminos y condiciones de la promocion.
                                            </Button>
                                        )
                                    }
                                </>
                            ) : (
                                <>
                                    {
                                        lote.datosDeInversion.valorRenta ? (
                                            <>
                                                <Typography variant="body2">
                                                    <b>Renta Mensual</b>
                                                </Typography>
                                                <Typography variant="h4">
                                                    <b>{ getFormatoMoneda(lote.datosDeInversion.valorRenta) }</b>
                                                </Typography>
                                            </>
                                        ) : (
                                            <>
                                                <Typography variant="body2">
                                                    <b>Renta Mensual Aproximada</b>
                                                </Typography>
                                                <Typography variant="h4">
                                                    <b>{ lote.caracteristicas[0].valorRenta }</b>
                                                </Typography>
                                            </>
                                        )
                                    }                                    
                                </>
                            )
                        }                        
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            sx={{ 
                                textAlign:'left',
                                pt:{ xs:1, sm:1, md:2},
                                pb:{ xs:2, sm:1, md:5}
                            }}
                        >
                            <Grid item xs={12} sm={12} md={12} >
                                <Typography variant="body1" component="div">
                                    <b>CARACTERISTICAS</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={7} sm={7} md={6}>
                                <Typography variant="body2" component="div">
                                    <b>Fachada</b>
                                </Typography>
                                <Typography variant="body1" component="div">
                                    {lote.fachada.nombre}
                                </Typography>
                            </Grid>
                            <Grid item xs={5} sm={5} md={6}>
                                <Typography variant="body2" component="div">
                                    <b>Terreno</b>
                                </Typography>
                                <Typography variant="body1" component="div">
                                    {`${lote.superficieDeTerreno}m²`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography variant="body2" component="div">
                                    <b>Fraccionamiento</b>
                                </Typography>
                                <Typography variant="body1" component="div">
                                    {lote.manzana.etapa.fraccionamiento.nombre}
                                </Typography>
                            </Grid>                            
                            {/* <Grid item xs={5} sm={4} md={6}>
                                <Typography variant="body2" component="div">
                                    <b>Promoción</b>
                                </Typography>
                                <Typography variant="body1" component="div">
                                    {
                                        !promocion ? (
                                            'N/A'
                                        ) : (
                                            promocion.cantidadADescontar ? (
                                                `-${getFormatoMoneda(promocion.cantidadADescontar)} `
                                            ) : (
                                                promocion.porcentajeADescontar && ( `${promocion.porcentajeADescontar}% ` )
                                            )
                                        )
                                    }
                                    {
                                        promocion && (
                                            <Box
                                                component='img'
                                                src={promocion.logo}
                                                sx={{ width:20 }}
                                            />
                                        )
                                    }
                                </Typography>
                            </Grid> */}
                            <Grid item xs={7} sm={4} md={6}>
                                <Typography variant="body2" component="div">
                                    <b>Manzana</b>
                                </Typography>
                                <Typography variant="body1" component="div">
                                    {lote.manzana.nombre}
                                </Typography>
                            </Grid>
                            <Grid item xs={5} sm={4} md={6}>
                                <Typography variant="body2" component="div">
                                    <b>Lote</b>
                                </Typography>
                                <Typography variant="body1" component="div">
                                    {lote.numeroDeLote}
                                </Typography>
                            </Grid>
                            {/* <Grid item xs={12} sm={12} md={12}>
                                <Typography variant="body1" component="div" sx={{ color:'#F5323F', mt:1, }}>
                                    <b>Lote Terminado listo para entrega</b>
                                </Typography>
                            </Grid> */}
                        </Grid>
                        <Grid 
                            container 
                            sx={{
                                marginTop:2
                            }}
                            spacing={1}
                        >
                            <Grid item xs={12} sm={12} md={6}>
                                <Button variant="outlined" size="medium" color="error" 
                                    sx={{ 
                                        // fontSize:'8px', 
                                        //m:2, 
                                        width: { xs:'95%', sm:'90%', md:'100%' }, 
                                        p:{ xs:'none', sm:'none', md:1 },
                                        borderColor:'#F5323F',
                                        color:'#F5323F', 
                                    }}
                                    // onClick={reedirigir}
                                    onClick={handleOpenModalRecorridoVirtual}
                                    disabled={lote.linkTourVirtual ? false : true}
                                >
                                        <b>RECORRIDO VIRTUAL</b>
                                </Button>
                            </Grid>
                            {
                                !lote.datosDeInversion && (
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Button variant="outlined" size="medium" color="error" 
                                            sx={{
                                                // fontSize:'8px', 
                                                // m:2, 
                                                width: { xs:'95%', sm:'90%', md:'100%' }, 
                                                p:{ xs:'none', sm:'none', md:1 },
                                                borderColor:'#F5323F',
                                                color:'#F5323F', 
                                            }}
                                            // onClick={reedirigirCalendly}
                                            onClick={()=>{ navigate('/citas'); }}
                                        >
                                            <b>AGENDAR CITA</b>
                                        </Button>
                                    </Grid>
                                )
                            }
                            {
                                !lote.datosDeInversion && (
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Button variant="outlined" size="medium" color="error" 
                                            sx={{ 
                                                // fontSize:'8px', 
                                                //m:2, 
                                                width: { xs:'95%', sm:'90%', md:'100%' }, 
                                                p:{ xs:'none', sm:1, md:1 },
                                                borderColor:'#F5323F',
                                                color:'#F5323F',
                                            }} 
                                            // onClick={()=>{ navigate('/checkData', {state:{idLote:lote.id, origen:location.pathname }}) }}
                                            onClick={()=>{ navigate(`/checkout/${lote.sku.replace(/ /g, "_")}`) }}
                                            disabled={!lote.disponible}
                                        >
                                            {
                                                lote.disponible ? (
                                                    <b>SEPARAR CASA</b>
                                                ) : (
                                                    <b>{`SEPARAR CASA (PRÓXIMAMENTE)`}</b>
                                                )
                                            }                                    
                                        </Button>
                                    </Grid>
                                )
                            }
                            {
                                lote.datosDeInversion && (
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Button variant="outlined" size="medium" color="error" 
                                            sx={{
                                                // fontSize:'8px', 
                                                // m:2, 
                                                width: { xs:'95%', sm:'90%', md:'100%' }, 
                                                p:{ xs:'none', sm:'none', md:1 },
                                                borderColor:'#F5323F',
                                                color:'#F5323F', 
                                            }}
                                            // onClick={reedirigirCalendly}
                                            onClick={()=>{ navigate(`/renta/contacto?lote=${lote.datosDeInversion.id}`); }}
                                        >
                                            <b>Contactar</b>
                                        </Button>
                                    </Grid>
                                )
                            }
                            {
                                !lote.datosDeInversion && (
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Box>
                                            <Divider>ó</Divider>
                                        </Box>
                                    </Grid>
                                )
                            }
                             {
                                !lote.datosDeInversion && (
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Typography>
                                            ¿Te gustaria invertir en una casa Pecsa? Pon en renta tu nueva casa Pecsa, nosotros te ayudamos <Link to={'/invierte'}>Mas informacion</Link>
                                        </Typography>
                                    </Grid>
                                )
                            }
                            {
                                !lote.datosDeInversion && (
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Button variant="outlined" size="medium" color="error" 
                                            sx={{ 
                                                // fontSize:'8px', 
                                                //m:2, 
                                                width: { xs:'95%', sm:'90%', md:'100%' }, 
                                                p:{ xs:'none', sm:1, md:1 },
                                                borderColor:'#F5323F',
                                                color:'#F5323F',
                                            }} 
                                            // onClick={()=>{ navigate('/checkData', {state:{idLote:lote.id, origen:location.pathname }}) }}
                                            onClick={()=>{ navigate(`/checkout/${lote.sku.replace(/ /g, "_")}?inversion=true`) }}
                                            disabled={!lote.disponible}
                                        >
                                            <b>Invertir</b>                                   
                                        </Button>
                                    </Grid>
                                )
                            }
                            {
                                !lote.datosDeInversion && (
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Button variant="outlined" size="medium" color="error" 
                                            sx={{ 
                                                // fontSize:'8px', 
                                                //m:2, 
                                                width: { xs:'95%', sm:'90%', md:'100%' }, 
                                                p:{ xs:'none', sm:1, md:1 },
                                                borderColor:'#F5323F',
                                                color:'#F5323F',
                                            }} 
                                            // onClick={()=>{ navigate('/checkData', {state:{idLote:lote.id, origen:location.pathname }}) }}
                                            onClick={()=>{ openNewTab(`/invierte/simulador?lote1=${lote.id}`) }}
                                            disabled={!lote.disponible}
                                        >
                                            <b>Simular inversion</b>                                   
                                        </Button>
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid 
                            container 
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            sx={{
                                marginTop:2,
                                color: '#F5323F'
                            }}
                        >
                            <Grid item xs={4} sm={4} md={4}>
                                <Button variant="text" size="large" color="error" 
                                    sx={{ 
                                        fontSize:{ xs:'8px', sm:'8px', md:'8px' }, 
                                        m:2, 
                                        p:1, 
                                        color:'#707070' 
                                    }}
                                    onClick={getLike}
                                    startIcon={
                                        like ? <FavoriteOutlinedIcon sx={{ color: '#F5323F' }}/> : <FavoriteBorderOutlinedIcon sx={{ color: '#F5323F' }}/>
                                    }                                        
                                >
                                        {likesCount} Likes
                                </Button>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}>
                                <Button variant="text" size="large" color="error" sx={{ fontSize:{ xs:'8px', sm:'8px', md:'8px' }, m:2, p:1, color:'#707070' }} startIcon={<RemoveRedEyeOutlinedIcon sx={{ color: '#F5323F' }}/>}>
                                    {views} views
                                </Button>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}>
                                <RWebShare
                                    data={{
                                        text: "¿Tienes Alguna duda sobre tu casa? Nosotros Te Ayudamos. Separa Tu Casa En Línea. Puedes Ver Tu Casa 100% En Línea a Detalle y Conocer tu Fraccionamiento 100% En Línea.",
                                        // url: `${process.env.REACT_APP_URL}/tienda/${lote.sku.replace(/ /g, "_")}`,
                                        url: pathname,
                                        title: !lote.datosDeInversion ? `Casas Pecsa | Tienda - Modelo ${lote.fachada.modelo.nombre}` : `Casas Pecsa | Rentar - Modelo ${lote.fachada.modelo.nombre} ${lote.manzana.etapa.fraccionamiento.nombre}`
                                    }}
                                    onClick={getShare}
                                >
                                    <Button 
                                        variant="text" 
                                        size="large" 
                                        color="error" 
                                        sx={{ 
                                            fontSize:{ xs:'8px', sm:'8px', md:'8px' }, 
                                            m:2, 
                                            p:1, 
                                            color:'#707070' 
                                        }} 
                                        startIcon={
                                            <ReplyOutlinedIcon 
                                                sx={{ color: '#F5323F' }}
                                            />
                                        }
                                    >
                                        Compartir
                                    </Button>
                                </RWebShare>                                
                            </Grid>
                        </Grid>

                    </Stack>
                </Grid>
            </Grid>
            <Modal
                open={openModalRecorridoVirtual}
                onClose={handleCloseModalRecorridoVirtual}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: {xs:'95%', sm:'95%', md:'99%', lg:'94%' },
                    bgcolor: 'background.paper',
                    borderRadius:{xs:'10px', sm:'12px', md:'12px'},
                    //border: '2px solid #000',
                    boxShadow: 24,
                    //p: 4,
                    color:'#707070',
                }}>
                    <Box>
                        <Box
                            sx={{                                
                                // display:'flex',
                                // backgroundColor:'green',
                            }}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item xs={10} sm={11} md={11}>
                                    <Box
                                        sx={{
                                            pl:{xs:1, sm:4, md:4},
                                            pt:4,
                                            // pb:2,
                                            // backgroundColor:'yellow',
                                        }}
                                    >
                                        <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ color:'#F5323F', }}>
                                            <b>Mira a tu alrededor.</b>
                                        </Typography>                                        
                                    </Box>
                                </Grid>
                                <Grid item xs={2} sm={1} md={1}>
                                    <Box
                                        sx={{
                                            textAlign:'right',
                                            // backgroundColor:'green',
                                        }}
                                    >
                                        <IconButton aria-label="delete" onClick={handleCloseModalRecorridoVirtual}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>   
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Box
                                        sx={{
                                            pl:{xs:1, sm:4, md:4},
                                            pb:2,
                                        }}
                                    >
                                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                            Nunca habia sido tan facil visitar tu nueva casa Pecsa.
                                        </Typography>
                                    </Box>   
                                </Grid>
                            </Grid>                            
                        </Box>
                        
                        <Box
                            sx={{
                                backgroundColor:'green',
                                width:{xs:'100%', sm:'100%', md:'100%', lg:'100%' },
                                height:{xs:500, sm:400, md:600 },
                                m:'0 auto'
                            }}
                        >
                            <PanoramaSencillo linkTourVirtual={lote.linkTourVirtual} />
                        </Box>
                           
                        {/* <Box
                            sx={{
                                backgroundColor:'#f2f2f2',
                                width:{xs:'100%', sm:'100%', md:'100%', lg:'100%' },
                                height:{xs:400, sm:400, md:600 },
                                //m:'0 auto',
                                textAlign:'center',
                                mb:7,

                            }}
                        >
                            <Box
                                sx={{
                                    // mt:10,
                                }}
                            >
                                <CircularProgress variant="determinate" value={50} color='error' sx={{ mt:{xs:15, sm:15, md:25, }, color:'#F5323F', }}/>
                                <CircularProgress sx={{ mt:{xs:15, sm:15, md:25, }, color:'#F5323F', }} />
                                <h1>Cargando visualizacion de lote...</h1>
                            </Box>                            
                        </Box> */}
                    </Box>
                </Box>
            </Modal>
        </Container>
    );
}

export { Principal }