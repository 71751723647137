import { Button, Chip, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Box, Container, display } from "@mui/system";
import axios from "axios";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/material.css'

import recomiendaPrueba from '../../assets/prueba/recomiendaPrueba.jpg';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { useNavigate } from "react-router-dom";

const Formulario = ({ fraccionamientos, modelos }) => {
    let navigate = useNavigate();

    const stylePecsaMoffin = {
        "& label.Mui-focused": {
            color: "#f5323f"
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#d9d9d9"
        },
        "& .MuiOutlinedInput-root": {
            // '& fieldset': {
            //   borderColor: 'black',
            // },
            "&:hover fieldset": {
                borderColor: "#d9d9d9"
            },
            "&.Mui-focused fieldset": {
                borderColor: "#d9d9d9"
            }
        }
    }
    const [coloniasQuienRecomienda, setColoniasQuienRecomienda] = useState([]);
    const [estadoQuienRecomienda, setEstadoQuienRecomienda] = useState('');    
    const [municipioQuienRecomienda, setMunicipioQuienRecomienda] = useState('');

    const getColoniasQuienRecomienda = (codigoPostalV) => {
        axios.get(`${process.env.REACT_APP_MOFFIN_API_URL}/postal-codes/${codigoPostalV}`,
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_MOFFIN_API_KEY}`,
                //ContentType: 'multipart/form-data'
              }
        })
        .then((response) => {
            // handle success
            // console.log(response.data);
            setColoniasQuienRecomienda(response.data);

        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sabe');
        });
    }
    const [colonias, setColonias] = useState([]);
    const [estado, setEstado] = useState('');    
    const [municipio, setMunicipio] = useState('');

    const [enviado, setEnviado] = useState(false);
    const [loadingEnviado, setLoadingEnviado] = useState(false);

    const getEnviado = () => {
        setEnviado(!enviado);
    }
    const getLoadingEnviado = () => {
        setLoadingEnviado(!loadingEnviado);
    }

    const getColonias = (codigoPostalV) => {
        axios.get(`${process.env.REACT_APP_MOFFIN_API_URL}/postal-codes/${codigoPostalV}`,
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_MOFFIN_API_KEY}`,
                //ContentType: 'multipart/form-data'
              }
        })
        .then((response) => {
            // handle success
            // console.log(response.data);
            setColonias(response.data);

        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sabe');
        });
    }

    const add = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        console.log(data.get('nombre'));
        getLoadingEnviado();
        axios.post(`${process.env.REACT_APP_API_URL}/api/recomienda-y-gana`,{
            nombreQueRecomienda: data.get('nombreQueRecomienda'),
            emailQueRecomienda: data.get('emailQueRecomienda'),
            telefonoQueRecomienda: data.get('telefonoQueRecomienda'),
            calleYNumeroQueRecomienda: data.get('calleYNumeroQueRecomienda'),
            codigoPostalQueRecomienda: data.get('codigoPostalQueRecomienda'),
            fraccionamientoQueRecomienda: data.get('fraccionamientoQueRecomienda'),
            estadoQueRecomienda: data.get('estadoQueRecomienda'),
            municipioQueRecomienda: data.get('municipioQueRecomienda'),
            notasQueRecomienda: data.get('notasQueRecomienda'),
            nombre: data.get('nombre'),
            email: data.get('email'),
            telefono: data.get('telefono'),
            calleYNumero: data.get('calleYNumero'),
            codigoPostal: data.get('codigoPostal'),
            fraccionamiento: data.get('fraccionamiento'),
            estado: data.get('estado'),
            municipio: data.get('municipio'),
            idFraccionamientoDeInteres: data.get('fraccionamientoDeInteres'),
            idModeloDeInteres: data.get('modeloDeInteres'),
            //nombre: data.get('nombre'),
        })
        .then((response)=>{
            console.log(response);
            getEnviado();
            setLoadingEnviado(false);
        })
        .catch((error) => {
            // handle success
            console.log(error);
        })
        .then(() => {
            // handle success
            //console.log('sabe');
        });
    }
    useEffect(()=>{
        if(coloniasQuienRecomienda.length > 0){
            console.log('vamos a llenar estados y municipios', coloniasQuienRecomienda);
            let hash = {};
            const hey = coloniasQuienRecomienda.filter((colonia)=> hash[colonia.city] ? false : hash[colonia.city] = true);
            setMunicipioQuienRecomienda(hey.length > 1 ? '' : hey[0].city);
            setEstadoQuienRecomienda(hey.length > 1 ? '' : hey[0].state);
        }
    }, [coloniasQuienRecomienda])
    useEffect(()=>{
        if(colonias.length > 0){
            console.log('vamos a llenar estados y municipios', colonias);
            let hash = {};
            const hey = colonias.filter((colonia)=> hash[colonia.city] ? false : hash[colonia.city] = true);
            setMunicipio(hey.length > 1 ? '' : hey[0].city);
            setEstado(hey.length > 1 ? '' : hey[0].state);
        }
    }, [colonias])
    return(
        <Container>
            <Box>
                <Typography variant="h4" sx={{ color:'#F5323F' }}>
                    <b>Recomienda y gana</b>
                </Typography>
                <Typography variant="h6" sx={{ color:'#707070', }}>
                    Llena el siguiente formulario con tus datos y los de la persona que recomiendas.
                </Typography>
            </Box>
            {
                enviado ? (
                    <Box
                        sx={{
                            // backgroundColor:'yellow',
                            width:'100%',
                            // display:{ xs:'block', sm:'flex', md:'flex' },
                            // pt:1,
                            // pb:1,
                            
                        }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"                    
                        >
                            <Grid item xs={12} sm={7} md={7}>
                                <Box
                                    sx={{
                                        // backgroundColor:'greenyellow',
                                        width:'100%',
                                        textAlign:'center',  
                                        color:'#F5323F',
                                        //mt:2,
                                        minHeight:675,
                                        alignItems:'center',
                                        alignContent:'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            m:'0 auto',
                                            pt:4,
                                        }}
                                    >
                                        <HowToRegIcon sx={{ fontSize:180, color:'#F5323F', }}/>
                                        <Typography variant="h5">
                                            <b>Has registrado con exito a tu recomendado!</b>
                                        </Typography>
                                        <Box>
                                            <Box
                                                sx={{
                                                    // backgroundColor:'brown',
                                                    width:'100%',
                                                    textAlign:'center',
                                                    pt:8,
                                                    pb:0.5,
                                                }}
                                            >                                            
                                                <Button 
                                                    variant="contained"
                                                    sx={{
                                                        width:'40%',
                                                        backgroundColor:'#F5323F',
                                                        // borderColor:'#F5323F',
                                                        color:'white',
                                                        borderWidth:2,
                                                        borderRadius:'7px',
                                                        '&:hover': {
                                                            backgroundColor: '#db0a18',
                                                            // color: 'white',
                                                        },
                                                        textTransform: 'none',
                                                    }}
                                                    onClick={getEnviado}
                                                >
                                                    Recomendar a alguien mas
                                                </Button>
                                            </Box>
                                            <Box
                                                sx={{
                                                    // backgroundColor:'brown',
                                                    width:'100%',
                                                    textAlign:'center',
                                                    pt:0.5,
                                                    pb:1,
                                                }}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    color="inherit"
                                                    sx={{
                                                        width:'40%',
                                                        backgroundColor:'white',
                                                        borderColor:'#F5323F',
                                                        color:'#F5323F',
                                                        borderWidth:2,
                                                        borderRadius:'7px',
                                                        '&:hover': {
                                                            borderColor: '#db0a18',
                                                            color: '#db0a18',
                                                        },
                                                        textTransform: 'none',
                                                    }}
                                                    onClick={()=>{ navigate('/') }}
                                                >
                                                    Volver a Inicio
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            // backgroundColor:'yellow',
                            width:'100%',
                            display:{ xs:'block', sm:'flex', md:'flex' },
                            pt:1,
                            pb:1,
                        }}
                        component="form" 
                        onSubmit={add}
                    >                                       
                        <Box
                            sx={{
                                // backgroundColor:'green',
                                width:{ xs:'100%', sm:'50%', md:'50%' },
                                display:'flex',
                                alignItems:'flex-start',
                                alignContent:'center',
                            }}
                        >
                            <Box
                                sx={{
                                    // backgroundColor:'yellow',
                                    width:{ xs:'90%', sm:'85%', md:'85%' },
                                    minHeight:200,
                                    m:'0 auto',
                                }}
                            >
                                <Box>
                                    <Typography variant="h6" sx={{ color:'#F5323F', mb:2, }}>
                                        <b>Datos personales</b>
                                    </Typography>
                                </Box>
                                <Box>
                                    <Grid container spacing={{ xs:2, sm: 1, md:2 }}>
                                        <Grid item xs={12} sm={12} md={12} >
                                            <TextField id="outlined-basic" label="Nombre Completo" variant="outlined" name="nombreQueRecomienda" sx={{ ...stylePecsaMoffin, width:'100%',  }} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={11} >
                                            <TextField id="outlined-basic" label="Correo Electrónico" variant="outlined" name="emailQueRecomienda" sx={{ ...stylePecsaMoffin, width:'100%', }} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={10} >
                                            <PhoneInput
                                                country={'mx'}
                                                inputProps={{
                                                    name: 'telefonoQueRecomienda',
                                                    required: true,
                                                    autoFocus: true
                                                }}
                                                specialLabel="Telefono"
                                                inputStyle={{
                                                    ...stylePecsaMoffin,
                                                    width:'100%',
                                                }}
                                                styl
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={11} >
                                            <TextField id="outlined-basic" label="Calle y numero" variant="outlined" name="calleYNumeroQueRecomienda" sx={{ ...stylePecsaMoffin, width:'100%', }} />
                                        </Grid>
                                        {/* <Grid item xs={12} sm={12} md={3} >
                                            <TextField id="outlined-basic" label="No. Exterior" variant="outlined" name="email" sx={{ ...stylePecsaMoffin, width:'100%', }} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3} >
                                            <TextField id="outlined-basic" label="No. Interior" variant="outlined" name="email" sx={{ ...stylePecsaMoffin, width:'100%', }} />
                                        </Grid> */}
                                        <Grid item xs={12} sm={12} md={4} >
                                            <TextField id="outlined-basic" label="Código postal" variant="outlined" name="codigoPostalQueRecomienda" sx={{ ...stylePecsaMoffin, width:'100%', }} onChange={(e)=>getColoniasQuienRecomienda(e.target.value)} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8} >
                                            <TextField id="outlined-basic" label="Fraccionamiento" variant="outlined" name="fraccionamientoQueRecomienda" sx={{ ...stylePecsaMoffin, width:'100%', }} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} >
                                            <TextField id="outlined-basic" label="Estado" variant="outlined" name="estadoQueRecomienda" sx={{ ...stylePecsaMoffin, width:'100%', }} value={estadoQuienRecomienda} onChange={(e)=>setEstadoQuienRecomienda(e.target.value)} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} >
                                            <TextField id="outlined-basic" label="Municipio" variant="outlined" name="municipioQueRecomienda" sx={{ ...stylePecsaMoffin, width:'100%', }} value={municipioQuienRecomienda} onChange={(e)=>setMunicipioQuienRecomienda(e.target.value)} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} >
                                            <TextField id="outlined-multiline-static" label="Notas" variant="outlined" name="notasQueRecomienda" multiline rows={4} sx={{ ...stylePecsaMoffin, width:'100%', }} />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Box>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Box
                            sx={{
                                // backgroundColor:'green',
                                width:{ xs:'100%', sm:'50%', md:'50%' },
                                display:'flex',
                                alignItems:'flex-start',
                                alignContent:'center',
                            }}
                        >
                            <Box
                                sx={{
                                    // backgroundColor:'yellow',
                                    width:{ xs:'90%', sm:'85%', md:'85%' },
                                    minHeight:200,
                                    m:'0 auto',
                                }}
                            >
                                <Box>
                                    <Typography variant="h6" sx={{ color:'#F5323F', mb:2, }}>
                                        <b>Datos del Recomendado</b>
                                    </Typography>
                                </Box>
                                <Box>
                                    <Grid container spacing={{ xs:2, sm: 1, md:2 }}>
                                        <Grid item xs={12} sm={12} md={12} >
                                            <TextField id="outlined-basic" label="Nombre Completo" variant="outlined" name="nombre" sx={{ ...stylePecsaMoffin, width:'100%',  }} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={11} >
                                            <TextField id="outlined-basic" label="Correo Electrónico" variant="outlined" name="email" sx={{ ...stylePecsaMoffin, width:'100%', }} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={10} >
                                            <PhoneInput
                                                country={'mx'}
                                                inputProps={{
                                                    name: 'telefono',
                                                    required: true,
                                                    autoFocus: true
                                                }}
                                                specialLabel="Telefono"
                                                inputStyle={{
                                                    ...stylePecsaMoffin,
                                                    width:'100%',
                                                }}
                                                styl
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} >
                                            <TextField id="outlined-basic" label="Calle y numero" variant="outlined" name="calleYNumero" sx={{ ...stylePecsaMoffin, width:'100%', }} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={5} >
                                            <TextField id="outlined-basic" label="Código postal" variant="outlined" name="codigoPostal" sx={{ ...stylePecsaMoffin, width:'100%', }} onChange={(e)=>getColonias(e.target.value)} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={7} >
                                            <TextField id="outlined-basic" label="Fraccionamiento" variant="outlined" name="fraccionamiento" sx={{ ...stylePecsaMoffin, width:'100%', }} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} >
                                            <TextField id="outlined-basic" label="Estado" variant="outlined" name="estado" sx={{ ...stylePecsaMoffin, width:'100%', }} value={estado} onChange={(e)=>setEstado(e.target.value)} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} >
                                            <TextField id="outlined-basic" label="Municipio" variant="outlined" name="municipio" sx={{ ...stylePecsaMoffin, width:'100%', }} value={municipio} onChange={(e)=>setMunicipio(e.target.value)} />
                                        </Grid>
                                        {/* <Grid item xs={12} sm={12} md={6} >
                                            <FormControl 
                                                fullWidth 
                                                sx={{
                                                    ...stylePecsaMoffin
                                                }}
                                            >
                                                <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    // value={age}
                                                    label="Estado"
                                                    // onChange={handleChange}
                                                    
                                                >
                                                    <MenuItem value={10}>T-106</MenuItem>
                                                    <MenuItem value={11}>T-146</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} >
                                            <TextField id="outlined-basic" label="Municipio" variant="outlined" name="telefono" sx={{ ...stylePecsaMoffin, width:'100%', }} />
                                        </Grid> */}
                                        <Grid item xs={12} sm={12} md={12} >
                                            <FormControl 
                                                fullWidth 
                                                sx={{
                                                    ...stylePecsaMoffin
                                                }}
                                            >
                                                <InputLabel id="demo-simple-select-label">Fraccionamiento de interes</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    // value={age}
                                                    label="Fraccionamiento de interes"
                                                    // onChange={handleChange}
                                                    name="fraccionamientoDeInteres"
                                                >
                                                    {
                                                        fraccionamientos.map((fraccionamiento) => (
                                                            <MenuItem value={fraccionamiento.id}>
                                                                <Box
                                                                    sx={{
                                                                        display:'flex',
                                                                        alignItems:'center',
                                                                        alignContent:'center',
                                                                    }}
                                                                >
                                                                    <Box
                                                                        component='img'
                                                                        src={fraccionamiento.logo}
                                                                        sx={{
                                                                            width:30
                                                                        }}
                                                                    />
                                                                    { fraccionamiento.nombre }
                                                                    {
                                                                        fraccionamiento.promocion && (
                                                                            <Box
                                                                                sx={{
                                                                                    ml:1,
                                                                                }}
                                                                            >
                                                                                <Chip label="Promo" variant="outlined" size="small" sx={{ borderColor:'red', color:'red', }} />
                                                                            </Box>
                                                                        )
                                                                    }
                                                                </Box>
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} >
                                            <FormControl 
                                                fullWidth 
                                                sx={{
                                                    ...stylePecsaMoffin
                                                }}
                                            >
                                                <InputLabel id="demo-simple-select-label">Modelo</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    // value={age}
                                                    label="Modelo"
                                                    // onChange={handleChange}
                                                    name="modeloDeInteres"
                                                >
                                                    {
                                                        modelos.map((modelo) => (
                                                            <MenuItem value={modelo.id}>
                                                                { modelo.nombre }
                                                            </MenuItem>        
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} >
                                            <Box
                                                sx={{
                                                    // backgroundColor:'green',
                                                    width:'100%',
                                                    textAlign:'center'
                                                }}
                                            >
                                                <Button 
                                                    variant="contained"
                                                    color='inherit'
                                                    sx={{
                                                        backgroundColor:'#F5323F',
                                                        width:{ xs:'90%', sm:'80%', md:'50%' },
                                                        color:'white',
                                                        borderRadius:'7px',
                                                        '&:hover': {
                                                            backgroundColor: '#db0a18',
                                                        },
                                                        mb:3,
                                                        textTransform: 'none',
                                                    }}
                                                    type="submit"
                                                    disabled={loadingEnviado}
                                                >
                                                    Enviar
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Box>                        
                    </Box>
                )
            }
        </Container>
    );
}

export { Formulario }