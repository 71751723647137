import { Route, Routes, useNavigate } from "react-router-dom";
import { Button, Card, CardActionArea, CardContent, CardMedia, Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';




const MenuUsuarios = ({ usuarios }) => {
    let navigate = useNavigate();
    //console.log('menu', props);

    
    return(
        <Container sx={{ backgroundColor:'white' }}>
             
            <Box sx={{ /*backgroundColor:'yellow'*/ mt:12, }}>                
                <h1>Usuarios</h1>                                 
            </Box>
            <Box sx={{ /*backgroundColor:'yellow'*/ mb:8, }}>                
                <Grid container 
                    direction="row" 
                    spacing={{ xs: 2, md: 3 }} 
                    columns={{ xs: 12, sm: 12, md: 12, lg:12 }}
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Card>
                            <CardActionArea 
                                onClick={()=>{ navigate('add')}}
                                sx={{
                                    height:300
                                }}
                            >
                                <CardContent>
                                    <AddCircleOutlineIcon/>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Agregar Usuario
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    {
                        usuarios.map((usuario) => (
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <Card>
                                    {/* <CardActionArea onClick={() => { navigate(`${usuario.folio}`, {state:{fraccionamiento:usuario}}) }}> */}
                                    <CardActionArea 
                                        onClick={() => { navigate(`${usuario.id}`) }}
                                        sx={{
                                            height:300
                                        }}
                                    >
                                        <CardContent>
                                            <CardMedia
                                                component="img"
                                                // height="140"
                                                image={usuario.archivoLogo || ''}
                                                alt="green iguana"
                                                sx={{
                                                    minHeight:140,
                                                }}
                                            />
                                            <Typography gutterBottom variant="h5" component="div">
                                                { usuario.nombre}
                                            </Typography>
                                            <Typography gutterBottom variant="caption">
                                                { usuario.perfil.nombre}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))
                    }
                    
                </Grid>                                
            </Box>
            {/* {
                props.productos.length < 1 ? <div><h1>nada</h1></div>:
                props.productos.map((producto) => ( 
                    <h2>{producto.name}</h2>
                ))
            } */}
        </Container>
    );
}
export { MenuUsuarios }