export const getFormatoMoneda = (cantidad) => {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD', //MXN
		minimumFractionDigits: 0
	  })
  
	  var value = 10000
  
	  return formatter.format(cantidad)
}