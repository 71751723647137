import React, {useState,useEffect} from 'react';
import axios from "axios";
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation, Link, NavLink, Navigate, useParams } from "react-router-dom";
import { Autocomplete, Button, ButtonBase, Checkbox, Container, Divider, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';

import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import sinlogo from '../../assets/sinlogo.png';

import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import ClearIcon from '@mui/icons-material/Clear';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const AddFachada = () => {
    let navigate = useNavigate();
    let parametros = useParams();
    const location = useLocation();
    console.log(parametros);
    //const [personaje, setPersonaje] = useState(location.state.personaje);

    const [ciudades, setCiudades] = useState([]);
    const [selectedCiudad, setSelectedCiudad] = useState(null);

    // const [selectedImage, setSelectedImage] = useState(null);
    // const [imageUrl, setImageUrl] = useState(null);
    const [selectedImage, setSelectedImage] = useState([]);
    const [imageUrl, setImageUrl] = useState(null);

    const [storageImages, setStorageImages] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [imagesUrl, setImagesUrl] = useState([]);

    const [principal, setPrincipal] = useState(0);

    const add = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // // // const data = new FormData();
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');
        //var formData = new FormData();
        // // // data.append('idMpio', selectedCiudad.id);
        data.set('idModelo', parametros.modelo);
        // data.set('principal', principal);
        
        data.set('folioCreateBy', folio);
        // let reader = new FileReader();
        // reader.readAsDataURL(selectedImage);
        //console.log('prueba::::', prueba); return;
        // console.log('data::::', data.get('fotosImg')); return;

        // for(const pair of data.entries()) {
        //     console.log(`${pair[0]}, ${pair[1]}`);
        // }
        // return;
        axios.post(`${process.env.REACT_APP_API_URL}/api/fachada/`, 
            // // {
            // //     nombre: data.get('nombre'),
            // //     idMpio: selectedCiudad.id,
            // //     logoImg: selectedImage,
            // //     fotosImg: storageImages,
            // //     folioCreateBy: folio,
            // // },
            data,
            {
              headers: { 
                Authorization: `Bearer ${token}`,   
                'Content-Type': 'multipart/form-data',               
                //ContentType: 'multipart/form-data'
              }              
        })
        .then((response) => {
            // handle success            
            console.log(response);
            console.log('success axio');
            navigate(-1, { replace: true });
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sabe');
        });
    }
    const removeImagen = (index) => {
        storageImages.splice(index, 1);
        imagesUrl.splice(index, 1);
        setStorageImages(storageImages);
        setImagesUrl(imagesUrl);
    }
    useEffect(() => {
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        
        if (selectedImage.length > 0) {
            setImageUrl(URL.createObjectURL(selectedImage[0]));
        }
        // if (selectedImages.length > 0) {
        //     for (let i = 0; i < selectedImages.length; i++) {
        //         if(!storageImages.find( element => element === selectedImages[i])){
        //             storageImages.push(selectedImages[i]);
        //             imagesUrl.push(URL.createObjectURL(selectedImages[i]))
        //         }                
        //     }
        // }

        axios.get(`${process.env.REACT_APP_API_URL}/api/producto/createview`, {
              headers: {
                  Authorization: `Bearer ${token}`
              }
          })
          .then((response) => {
            // handle success
            console.log(response);
            //setCiudades(response.data.ciudades);
          })
          .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
          })
          .then(() => {
            // handle success
          });
    }, [setCiudades, selectedImage /*, selectedImages, imagesUrl*/]);
    return(
        <Container>
            <Box component="form" onSubmit={add} encType="multipart/form-data" >
                <h2>Nueva Fachada</h2>
                <Grid 
                    container 
                    spacing={3}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    
                >
                    <Grid item xs={12} sm={12} md={7} sx={{ p:1 }}>
                        <Stack spacing={3}>
                            <TextField id="outlined-basic" label="Nombre" variant="outlined" name="nombre" required/>
                        </Stack>                        
                    </Grid>
                    <Grid item xs={12} sm={12} md={7}>
                        <label htmlFor="contained-button-file-fotofachada">                            
                            <input accept="image/*" id="contained-button-file-fotofachada" type="file" name="fotofachadaImg" style={{ display: 'none' }} onChange={e => setSelectedImage([e.target.files[0]])}/>
                            <ButtonBase component="span" sx={{ width:'80%' }}>
                                {
                                     imageUrl ? selectedImage ? (
                                        <Box
                                            component='img'
                                            src={imageUrl}
                                            sx={{
                                                width:'100%',
                                                height: 450,
                                                borderRadius: '16px',
                                                boxShadow: 3
                                            }}
                                        />
                                     ) : (
                                        <Box
                                            component='div'
                                            sx={{
                                                width:'100%',
                                                height: 450,
                                                border: '2px dashed',
                                                borderRadius: '16px',
                                            }}
                                        >
                                            <AddAPhotoIcon/>
                                            <Typography variant="h5" gutterBottom component="div">
                                                Clic para agregar Fotografia
                                            </Typography>
                                        </Box>
                                     ) :(
                                        <Box
                                            component='div'
                                            sx={{
                                                width:'100%',
                                                height: 450,
                                                border: '2px dashed',
                                                borderRadius: '16px',
                                            }}
                                        >
                                            <AddAPhotoIcon/>
                                            <Typography variant="h5" gutterBottom component="div">
                                                Clic para agregar Fotografia
                                            </Typography>
                                        </Box>
                                     )
                                }
                            </ButtonBase>
                        </label>
                        
                        
                    </Grid>

                </Grid>
                <Button variant="contained" type="submit">Guardar</Button>
                <Divider variant="middle" />
            </Box>
        </Container>
    );
}

export { AddFachada }