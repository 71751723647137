import { Button, Container, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Amenidades } from "./Amenidades";
import { Modelo } from "./Modelo";
import { Portada } from "./Portada";

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { InlineWidget } from "react-calendly";
import { useState } from "react";
import { Experiencia } from "./Experiencia";
import { Helmet } from "react-helmet";
import { Ubicacion } from "./Ubicacion";
import { MyCalendlyComponent } from "./MyCalendlyComponent";
import { Formulario } from "./Formulario";
import { OchoRazones } from "../../../components/OchoRazones";
import { Garantias } from "../../../components/Garantias";
import TagManager from "react-gtm-module";
import banorte from '../../../assets/solicitacredito/banorte.svg';
import bbva from '../../../assets/solicitacredito/bbva.svg';
import fobissste from '../../../assets/solicitacredito/fobissste.svg';
import HSBC from '../../../assets/solicitacredito/HSBC.svg';
import infonavit from '../../../assets/solicitacredito/infonavit.svg';
import scotiabank from '../../../assets/solicitacredito/scotiabank.svg';
import { PopupFoto } from "../../../components/Popups/PopupFoto";
import { BannerAnuncio } from "./BannerAnuncio";

const bancosPreautorizan = [
    { nombre:'Banorte', imagen:banorte, },
    { nombre:'BBVA', imagen:bbva, },
    // { nombre:'Fobissste', imagen:fobissste, },
    { nombre:'HSBC', imagen:HSBC, },
    // { nombre:'Infonavit', imagen:infonavit, },
    { nombre:'ScotiaBank', imagen:scotiabank, },
]

const otrosPreautorizan = [
    { nombre:'Fobissste', imagen:fobissste, },
    { nombre:'Infonavit', imagen:infonavit, },
    { nombre:'Cofinavit', imagen:'https://www.torreasesores.com/wp-content/uploads/2015/06/Cofinavit-vector-logo-e1434046485409.png', },
    { nombre:'Unamos Créditos', imagen:'https://revistaespacios.mx/wp-content/uploads/2020/07/imagen.png', },
    { nombre:'Apoyo Infonavit', imagen:'https://seeklogo.com/images/A/apoyo-infonavit-logo-8C486CA90D-seeklogo.com.png', },
    // { nombre:'ScotiaBank', imagen:scotiabank, },
]

const tagManagerArgs = {
    // gtmId: 'G-WB7D5X8E87',
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ALTAVISTA
    // dataLayer: {
    //     userId: '001',
    //     userProject: 'project'
    // }
}

TagManager.initialize(tagManagerArgs)
const fraccionamiento = { 
    modelos:[
        {
            nombre:'T-88',
            precio: '$1,369,520.00',
            foto:'https://d3ihta9hbp4hol.cloudfront.net/landingPages/altavista/MODELOS/T-88-12072023.png',
            plantas:[
                'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-102/Plantas/plantabaja.png',
                'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-102/Plantas/plantaalta.png',
            ],
            caracteristicas: [
                '88 m² de construcción',
                'Cochera',
                '2 Habitaciones',
                '2 Baños',
            ],
            interiores: {
                total:1060,
                items: [],
            }
        },
        {
            nombre:'T-106',
            precio: '$1,583,640.00',
            foto:'https://d3ihta9hbp4hol.cloudfront.net/landingPages/altavista/MODELOS/T-10612072023.png',
            plantas:[
                'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-102/Plantas/plantabaja.png',
                'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-102/Plantas/plantaalta.png',
            ],
            caracteristicas: [
                '106 m² de construcción',
                'Cochera',
                '3 Habitaciones',
                '2 Baños',
            ],
            interiores: {
                total:1420,
                items: [
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-106/Interiores/LomasDelMorillo1-2.jpg',
                        title: 'Breakfast',
                        rows: 2,
                        cols: 2,
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-106/Interiores/LomasDelMorillo11.jpg',
                        title: 'Burger',
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-106/Interiores/LomasDelMorillo12.jpg',
                        title: 'Camera',
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-106/Interiores/LomasDelMorillo13.jpg',
                        title: 'Coffee',
                        cols: 2,
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-106/Interiores/LomasDelMorillo14.jpg',
                        title: 'Hats',
                        cols: 2,
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-106/Interiores/LomasDelMorillo15.jpg',
                        title: 'Honey',
                        rows: 2,
                        cols: 2,
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-106/Interiores/LomasDelMorillo17.jpg',
                        title: 'Burger',
                    },
                    {
                        img: 'https://d3ihta9hbp4hol.cloudfront.net/Modelos/T-106/Interiores/LomasDelMorillo19.jpg',
                        title: 'Camera',
                    },
                    // {
                    //     img: interior1069,
                    //     title: 'Coffee',
                    //     cols: 2,
                    // },
                    // {
                    //     img: interior10610,
                    //     title: 'Hats',
                    //     cols: 2,
                    // },
                ]
            }
        },
        
    ],
    amenidades:[
        {
            nombre:'Área de juegos infantiles',
            texto:'La seguridad de los niños es nuestra prioridad número uno, por lo que todos nuestros juegos y equipos están diseñados y construidos con materiales de alta calidad y cumplen con los estándares de seguridad requeridos.',
            foto:'https://d3ihta9hbp4hol.cloudfront.net/Amenidades/5/%C3%81rea%20de%20juegos%20infantiles/A1.jpeg',
        },
        {
            nombre:'Plaza',
            texto:'Nuestra plaza está diseñada para que puedas disfrutar de una experiencia única de caminar rodeado de áreas verdes, con tus seres queridos. También contamos con una amplia variedad de bancas y áreas de descanso donde puedes sentarte y disfrutar de la vista.',
            foto:'https://d3ihta9hbp4hol.cloudfront.net/Amenidades/5/Plaza/A4.jpeg',
        },
        {
            nombre:'Palapa con asador',
            texto:'Pasarás momentos increibles en tus reuniones junto con tus amigos y familia con espacios amplios y agradables equipados con todo lo necesario para hacer tus eventos.',
            foto:'https://d3ihta9hbp4hol.cloudfront.net/Amenidades/5/Palapa%20con%20asador/A7.jpeg',
        },
    ]
}

const AltavistaLandingPage = () => {
    const [open, setOpen] = useState(false);
    const getOpen = () => {
        setOpen(!open)
    }
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    
    return(
        <Box
            sx={{
                mt:{ xs:7, sm:8, md:10 }
            }}
        >
            <Helmet>
                <title>Altavista Residencial | Casas Pecsa</title>
                <meta name="description" content=" Una ubicación inigualable que llega a marcar tendencia, estilo y grandeza. Un gran desarrollo privado con grandes amenidades y diseño atemporal.Cuenta con seguridad las 24 horas del día, para que puedas vivir con total tranquilidad y disfrutar al máximo de tu hogar." />                
            </Helmet>
            <Portada getOpen={getOpen} />
            {/* <BannerAnuncio/> */}
            {/* <Formulario getOpen={getOpen} /> */}
            <Container>
                <Box
                    sx={{
                        width:'100%',
                        textAlign:'center',
                        color:'#69542c',
                        mt:4,
                        mb:2,
                        
                    }}
                >
                    <Typography variant="h3" sx={{ fontFamily:'Montserrat', }}>
                        <b>¡Modelos ya disponibles para habitar!</b>
                    </Typography>
                </Box>
            </Container>
            {
                fraccionamiento.modelos.map((modelo) => (
                    <Modelo modelo={modelo} getOpen={getOpen} />
                ))
            }            
            {/* <Experiencia/> */}
            <Container>
                <Box
                    sx={{
                        width:'100%',
                        textAlign:'center',
                        color:'#69542C',
                        mt:4,
                        mb:2,
                    }}
                >
                    <OchoRazones titulo="¿Porque comprar una Casa Pecsa?" color="#69542C"/>
                </Box>
            </Container>
            <Box
                sx={{
                    backgroundColor:'#C7B497',
                    width:'100%',
                    minHeight:400,
                    textAlign:'center',
                    color:'#69542C',
                    mt:4,
                    mb:2,
                }}
            >
                <Container>
                    <Box
                        sx={{
                            width:'100%',
                            textAlign:'center',
                            color:'#69542C',
                            mt:4,
                            mb:2,
                        }}
                    >
                        <Garantias color="#69542C" align="center" />
                    </Box>
                </Container>
            </Box>
            <Box>
                <Box
                    sx={{
                        textAlign:'center',
                        color:'#69542c',
                    }}
                >
                    <Typography variant="h3" sx={{ fontFamily:'Montserrat', }}>
                        <b>Te ayudamos a encontrar la mejor opción de crédito para ti</b>
                    </Typography>
                </Box>
                <Box
                    sx={{
                        // mb:2,
                    }}
                >
                    <Box
                        sx={{
                            width:'100%',
                            textAlign:'center',
                            color:'#69542c',
                            mt:4,
                            mb:2,
                            
                        }}
                    >
                        <Typography variant="h5" sx={{ fontFamily:'Montserrat', }}>
                            <b>Créditos bancarios que puedes utilizar</b>
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            mb:2,
                        }}
                    >
                        <Grid 
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {
                                bancosPreautorizan.map((banco) => (
                                    <Grid item xs={4} sm={2} md={2} key={banco.nombre}>
                                        <Box
                                            sx={{
                                                // backgroundColor:'red',
                                                width:'100%',
                                                height:100,
                                                display:'flex',
                                                alignItems:'center',
                                                alignContent:'center',
                                                mb:2,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    // backgroundColor:'yellow',
                                                    width:'70%',
                                                    maxHeight:'70%',
                                                    m:'0 auto',
                                                }}
                                            >
                                                <Box
                                                    component='img'
                                                    src={banco.imagen}
                                                    sx={{
                                                        maxWidth:'100%',
                                                        maxHeight:70
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))
                            }
                            
                        </Grid>
                    </Box>
                </Box>
                <Box
                    sx={{
                        // mb:2,
                    }}
                >
                    <Box
                        sx={{
                            width:'100%',
                            textAlign:'center',
                            color:'#69542c',
                            mt:4,
                            mb:2,
                            
                        }}
                    >
                        <Typography variant="h5" sx={{ fontFamily:'Montserrat', }}>
                            <b>Otros tipos de créditos</b>
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            mb:2,
                        }}
                    >
                        <Grid 
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {
                                otrosPreautorizan.map((banco) => (
                                    <Grid item xs={4} sm={2} md={2} key={banco.nombre}>
                                        <Box
                                            sx={{
                                                // backgroundColor:'red',
                                                width:'100%',
                                                height:100,
                                                display:'flex',
                                                alignItems:'center',
                                                alignContent:'center',
                                                mb:2,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    // backgroundColor:'yellow',
                                                    width:'70%',
                                                    maxHeight:'70%',
                                                    m:'0 auto',
                                                }}
                                            >
                                                <Box
                                                    component='img'
                                                    src={banco.imagen}
                                                    sx={{
                                                        maxWidth:'100%',
                                                        maxHeight:70
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))
                            }
                            
                        </Grid>
                    </Box>
                </Box>
            </Box>
            <MyCalendlyComponent/>
            <Ubicacion/>            
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth="lg"
                fullScreen={fullScreen}
                sx={{
                    
                }}
                onClose={getOpen}
            >                
                <Box
                    sx={{
                        // backgroundColor:'red',
                        width:'100%',
                        height:{ xs:900, sm:900, md:810 }
                    }}
                >
                    <InlineWidget url="https://calendly.com/casas-pecsa/fracc-altavista-residencial?hide_landing_page_details=1&hide_gdpr_banner=1&text_color=745f3e&primary_color=ac8c5c" styles={{ width:'100%', height:800, }} />
                </Box>
                <DialogActions>
                    <Button 
                        autoFocus 
                        sx={{
                            // textTransform:'none',
                            color:'#ac8c5c',
                            '&:hover':{
                                backgroundColor:'#f4e1bb',

                            }
                        }}
                        onClick={getOpen}
                    >
                        <b>Cancelar</b>
                    </Button>
                </DialogActions>                
            </Dialog>
            {/* <PopupFoto/> */}
        </Box>
    );
}

export { AltavistaLandingPage }