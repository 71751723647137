import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";

import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const RedesSociales = () => {
    const reedirigirFacebook = () =>{
        window.open('https://es-la.facebook.com/casaspecsa1/')
    }
    const reedirigirYoutube = () =>{
        window.open('https://www.youtube.com/@casaspecsa1706')
    }
    const reedirigirInstagram = () =>{
        window.open('https://www.instagram.com/casaspecsa/')
    }
    const reedirigirChatWhatsapp = () =>{
        window.open(`https://api.whatsapp.com/send?phone=528441630722&text=Hola,%20buen%20dia!`)
        // window.location.href = 'https://api.whatsapp.com/send?phone=528443492981&text=mensajedeprueba';
    }
    const reedirigirLinkedin = () =>{
        window.open('https://mx.linkedin.com/company/casas-pecsa')
    }
    return(
        <Box
            sx={{
                mb:2,
            }}
        >
            <Grid 
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={12} sm={12} md={12}>
                    <Box                        
                        sx={{
                            color:'#DE4647',
                            textAlign:{ xs:'center', sm:'center', md:'center' },
                            mt:{ xs:2, md:2, },
                            mb:{ xs:1,sm:1, md:1, },
                            // backgroundColor:'yellow',
                        }}
                        
                    >
                        <Typography variant="h6" sx={{ fontFamily:'Montserrat', }}>
                            <b>Redes Sociales</b>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Box                        
                        sx={{
                            color:'#DE4647',
                            textAlign:{ xs:'center', sm:'center', md:'center' },
                            // mt:{ xs:2, md:2, },
                            //mb:{ xs:8, md:2, },
                            // backgroundColor:'yellow',
                        }}
                        
                    >
                        <WhatsAppIcon sx={{ ml:1, mr:1, cursor:'pointer', }} onClick={reedirigirChatWhatsapp} />
                        <FacebookIcon sx={{ ml:1, mr:1, cursor:'pointer', }} onClick={reedirigirFacebook} />
                        <YouTubeIcon sx={{ ml:1, mr:1, cursor:'pointer', }} onClick={reedirigirYoutube} />
                        <InstagramIcon sx={{ ml:1, mr:1, cursor:'pointer', }} onClick={reedirigirInstagram} />
                        <LinkedInIcon sx={{ ml:1, mr:1, cursor:'pointer', }} onClick={reedirigirLinkedin} />
                    </Box>
                </Grid>
                {/* <Grid item sx={12} sm={3} md={1}>ddd</Grid> */}
            </Grid>
        </Box>
    );
}

export { RedesSociales }