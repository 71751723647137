import * as React from "react";
import { useRef, useEffect, useState } from "react";
import { Box, height } from "@mui/system";
import { Button, Stack, Skeleton } from "@mui/material";
import { motion } from "framer-motion";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import theme from "../../App/theme";

const Slider = ({ children }) => {
  const constraintsRef = useRef(null);
  const containerRef = useRef(null);

  const constraintsRefMovil = useRef(null);
  const containerRefMovil = useRef(null);

  const [Xside, setXside] = React.useState(0);
  const [XIside, setXIside] = React.useState(0);

  const [constraintsWidth, setConstraintsWidth] = React.useState(0);
  const [containerWidth, setContainerWidth] = React.useState(0);

  const [count, setCount] = React.useState(0);

  const atras = (Xside) => {
    setXside(Xside + constraintsWidth / 3);
    setXIside(
      XIside - constraintsWidth / 3 < constraintsWidth
        ? constraintsWidth
        : XIside - constraintsWidth / 3
    );
  };
  const adelante = (Xside) => {
    setXside(Xside - constraintsWidth / 3);
    setXIside(
      XIside + constraintsWidth / 3 > containerWidth
        ? containerWidth
        : XIside + constraintsWidth / 3
    );
    // console.log(constraintsWidth, containerWidth)
  };

  useEffect(() => {
    // setXside(0);
    setCount(count + 1);
    // console.log('first', constraintsRefMovil.current.clientWidth,containerRefMovil.current.clientWidth, Xside)
    // }, [constraintsRefMovil, containerRefMovil])
  }, [children]);

  useEffect(() => {
    setXside(0);
    setXIside(constraintsRef.current.clientWidth);
    // console.log(constraintsWidth,containerWidth)
  }, [constraintsWidth, containerWidth]);

  useEffect(() => {
    setXIside(constraintsRef.current.clientWidth);
    setConstraintsWidth(constraintsRef.current.clientWidth);
    setContainerWidth(containerRef.current.clientWidth);
    // console.log(constraintsWidth, containerWidth);
  }, [children]);

  return (
    <Box sx={{ backgroundColor: "transparent" }}>
      <Box
        component={motion.div}
        ref={constraintsRef}
        sx={{
          backgroundColor: "transparent",
          display: { xs: "none", md: "flex" },
          placeContent: "start",
          placeItems: "center",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Stack
          ref={containerRef}
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap={2}
          component={motion.div}
          animate={{ x: Xside }}
          sx={{
            pt: 1,
            pb: 2,
          }}
        >
          {children}
        </Stack>
        {XIside !== constraintsWidth && (
          <Box
            component="Button"
            sx={{
              pt: 3,
              pb: 3,
              pl: 0,
              pr: 0,
              backgroundColor: "white",
              border: "none",
              color: "#f5323f",
              zIndex: 1,
              position: "absolute",
              left: 0,
              bgcolor: theme.palette.common.white,
              transition: "all .2s ease",
              "&:hover": {
                cursor: "pointer",
                bgcolor: theme.palette.common.white2,
                boxShadow: "0px 5px 12px 0px rgba(0, 0, 0, 0.19)",
              },
            }}
            variant="contained"
            onClick={() => atras(Xside)}
          >
            <ChevronLeftIcon />
          </Box>
        )}
        {XIside < containerWidth && (
          <Box
            component="Button"
            sx={{
              pt: 3,
              pb: 3,
              pl: 0,
              pr: 0,
              backgroundColor: "white",
              border: "none",
              color: "#f5323f",
              zIndex: 1,
              position: "absolute",
              right: 0,
              bgcolor: theme.palette.common.white,
              transition: "all .2s ease",
              "&:hover": {
                cursor: "pointer",
                bgcolor: theme.palette.common.white2,
                boxShadow: "0px 5px 12px 0px rgba(0, 0, 0, 0.19)",
              },
            }}
            variant="contained"
            onClick={() => adelante(Xside)}
          >
            <ChevronRightIcon />
          </Box>
        )}
      </Box>

      <Box
        key={count}
        component={motion.div}
        ref={constraintsRefMovil}
        sx={{
          backgroundColor: "transparent",
          display: { xs: "flex", md: "none" },
          placeContent: "start",
          placeItems: "center",
          overflow: "hidden",
          position: "relative",
          marginTop: 2,
        }}
      >
        <Stack
          ref={containerRefMovil}
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap={1.5} // Modifica el valor de gap según tus preferencias
          component={motion.div}
          animate={{ x: Xside }}
          drag="x"
          dragConstraints={constraintsRefMovil}
          sx={{
            pr: 2,
          }}
        >
          {children}
        </Stack>
      </Box>
    </Box>
  );
};

export { Slider };
