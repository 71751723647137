import { Box } from "@mui/material";
import { Container } from "@mui/system";
import { InlineWidget, PopupWidget } from "react-calendly";
import banneragendarcita from "../../assets/prueba/banneragendarcita.png";
import { ScrollToTop } from "../../components/ScrollToTop";

const Citas = () => {
  return (
    <Box
      sx={{
        mt: 12,
        mb: 12,
      }}
    >
      <ScrollToTop />
      <Container>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            borderRadius: "10px",
            boxShadow: 3,
            overflow: "hidden",
            mb: { xs: 2, sm: 2, md: 0 },
          }}
        >
          <Box
            component="img"
            src={
              "https://d3ihta9hbp4hol.cloudfront.net/Citas/Banner/NuevoBannerCitas.png"
            }
            sx={{
              m: "0 auto",
              minWidth: "100%",
            }}
          />
        </Box>
      </Container>
      <InlineWidget
        url="https://calendly.com/casas-pecsa?hide_landing_page_details=1&hide_gdpr_banner=1&text_color=707070&primary_color=f5323f"
        styles={{
          height: "800px",
        }}
      />
    </Box>
  );
};

export { Citas };
