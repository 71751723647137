import { Avatar, Box, Button, Grid, List, ListItem, ListItemText, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";

import FolderIcon from '@mui/icons-material/Folder';
import PersonIcon from '@mui/icons-material/Person';
import SendIcon from '@mui/icons-material/Send';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

import { useEffect, useState } from "react";
import axios from "axios";

const ComentariosLote = ({ lote, allComentarios }) => {
    const [comentarios, setComentarios] = useState([]);
    const [comentario, setComentario] = useState('');

    const [texto, setTexto] = useState('');

    const getComentarios = (comentario) => {
        // setComentarios((prev)=> [comentario, ...prev]);
        axios.post(`${process.env.REACT_APP_API_URL}/api/comentarios/lotes/`,
        {
            idLote:lote.id,
            texto: comentario,
            activo: true,
        })
        .then((response)=>{
            console.log(response)
            
        })
        .catch((error) => {
            // handle success
            console.log(error);
        })
        .then(() => {
            // handle success
            //console.log('sabe');
        });
        setComentarios([{texto:comentario, respuestas: [] }, ...comentarios]);
        console.log(comentarios);
    }
    const getComentario = (e) => {
        // setComentarios((prev)=> [comentario, ...prev]);
        // setComentario({
        //     texto: e.target.value,
        //     respuestas: []
        // });
        setComentario(e.target.value)
    }
    const stylePecsaMoffin = {
        "& label.Mui-focused": {
            color: "#f5323f"
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#d9d9d9"
        },
        "& .MuiOutlinedInput-root": {
            // '& fieldset': {
            //   borderColor: 'black',
            // },
            "&:hover fieldset": {
                borderColor: "#d9d9d9"
            },
            "&.Mui-focused fieldset": {
                borderColor: "#d9d9d9"
            }
        }
    }
    useEffect(()=>{
        setComentarios(allComentarios);
    }, [allComentarios])
    return(
        <Container>
            <Box>
                <Box
                    sx={{
                        // mt:2
                    }}
                >
                    {/* <Typography variant="h5" sx={{ color:'#F5323F', }}>
                        <b>Comentarios y preguntas:</b>
                    </Typography> */}
                    <Box sx={{ color:'#F5323F' }}>
                        <h1>Comentarios y preguntas</h1>
                    </Box>
                    <Typography variant="body1" sx={{ color:'#707070', }}>
                        Aqui puedes realizar tus comentarios y preguntas y nuestros asesores online te atendera.        
                    </Typography>
                </Box>
                <Box>
                    <Box
                        sx={{
                            // backgroundColor:'green',
                            width:'100%',
                            mt:2,
                            
                        }}
                    >
                        <Grid 
                            container
                                                  
                        >
                            <Grid item xs={12} sm={10} md={7}>
                                <Box
                                    sx={{
                                        // backgroundColor:'yellow',
                                        width:'100%',
                                        display:'flex',
                                        alignItems:'center',
                                        alignContent:'center',
                                    }}
                                >
                                    <TextField 
                                        id="outlined-basic" 
                                        placeholder="Escribe una pregunta o comentario..." 
                                        multiline 
                                        rows={2} 
                                        variant="outlined" 
                                        sx={{
                                            backgroundColor:'white',
                                            width: {xs:'100%', sm:'95%', md:'95%'},
                                            m:'0 auto',
                                            ...stylePecsaMoffin,
                                        }}
                                        value={comentario}
                                        // onChange={(e)=>setComentario(e.target.value)}
                                        onChange={getComentario}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={2} md={2}>
                                <Box
                                    sx={{
                                        // backgroundColor:'yellow',
                                        width:'100%',
                                        height:70,
                                        display:'flex',
                                        alignItems:'center',
                                        alignContent:'center',
                                    }}
                                >
                                    <Button 
                                        variant="outlined"
                                        color="inherit"
                                        endIcon={<SendIcon />}
                                        sx={{
                                            width:{xs:'100%', sm:'90%', md:'90%'},
                                            height:50,
                                            backgroundColor:'#F5323F',
                                            // borderColor:'#F5323F',
                                            color:'white',
                                            // borderWidth:2,
                                            borderRadius:'7px',
                                            '&:hover': {
                                                backgroundColor: '#db0a18',
                                                // color: 'white',
                                            },
                                            textTransform: 'none',
                                        }}
                                        onClick={()=>{getComentarios(comentario)}}
                                    >
                                        <b>Enviar</b>
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                        {/* <TextField id="outlined-basic" placeholder="PRegunta" variant="outlined" /> */}
                        {/* <Button variant="outlined" endIcon={<SendIcon />}>
                            Enviar
                        </Button> */}
                    </Box>
                </Box>
                <Box>
                    <Box
                        sx={{
                            mt:2
                        }}
                    >
                        <Typography variant="body1" sx={{ color:'#F5323F', }}>
                            <b>Comentarios y preguntas realizados:</b>
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            color:'#595959',
                        }}
                    >
                        <List>
                            {/* <ListItem>
                                <ListItemText 
                                    primary={
                                        <Typography variant="body1">
                                            <b>En dónde están ubicados para iR y donde tienen casas en saltillo o en Ramos?</b>
                                        </Typography>
                                    } 
                                    // secondary="Hola! contamos con casas al sur, oriente y poniente de Saltillo, te enviamos info por inbox "
                                    secondary={
                                        <Box
                                            sx={{
                                                // backgroundColor:'yellowgreen',
                                                width:'100%',
                                                pl:2,
                                                display:'flex',
                                            }}
                                        >
                                            <Typography variant="caption" sx={{ color:'#F5323F' }}>
                                                Respuesta:
                                            </Typography>
                                            <Typography variant="body2" sx={{ ml:0.5, }}>
                                                Hola! contamos con casas al sur, oriente y poniente de Saltillo, te enviamos info por inbox
                                            </Typography>
                                        </Box>
                                    }
                                />
                            </ListItem> */}
                            {
                                comentarios.length > 0 ? (
                                    comentarios.map((coment) => (
                                        <ListItem>
                                            <ListItemText 
                                                primary={
                                                    <Typography variant="body1">
                                                        <b>{ coment.texto }</b>
                                                    </Typography>
                                                } 
                                                secondary={
                                                    coment.respuestas.length > 0 ? 
                                                    <Box
                                                        sx={{
                                                            // backgroundColor:'yellowgreen',
                                                            width:'100%',
                                                            pl:2,
                                                        }}
                                                    >
                                                        <Typography variant="caption" sx={{ color:'#F5323F' }}>
                                                            Respuesta:
                                                        </Typography>
                                                        <Typography variant="body2" sx={{  }}>
                                                            { coment.respuestas[0].texto }
                                                        </Typography>
                                                    </Box>
                                                    : false
                                                }
                                            />
                                        </ListItem>
                                    ))
                                ) : (
                                    <ListItem>
                                        <Box
                                            sx={{
                                                // backgroundColor:'yellow',
                                                width:'70%',
                                                textAlign:'center',
                                            }}
                                        >
                                            <QuestionAnswerIcon sx={{ fontSize:80, color:'#707070', }} />
                                            <Typography variant="h6" sx={{ color:'#707070', }}>
                                                <b>No hay comentarios ni preguntas aun.</b>
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                )
                            }
                            {/* <ListItem>
                                <ListItemText 
                                    primary={
                                        <Typography variant="body1">
                                            <b>En dónde están ubicados para iR y donde tienen casas en saltillo o en Ramos?</b>
                                        </Typography>
                                    } 
                                    secondary={
                                        <Box
                                            sx={{
                                                // backgroundColor:'yellowgreen',
                                                width:'100%',
                                                pl:2,
                                            }}
                                        >
                                            <Typography variant="caption" sx={{ color:'#F5323F' }}>
                                                Respuesta:
                                            </Typography>
                                            <Typography variant="body2" sx={{  }}>
                                                Hola! contamos con casas al sur, oriente y poniente de Saltillo, te enviamos info por inbox
                                            </Typography>
                                        </Box>
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText 
                                    primary={
                                        <Typography variant="body1">
                                            <b>¿Donde puedo pedir informes?</b>
                                        </Typography>
                                    } 
                                    secondary={
                                        <Box
                                            sx={{
                                                // backgroundColor:'yellowgreen',
                                                width:'100%',
                                                pl:2,
                                            }}
                                        >
                                            <Typography variant="caption" sx={{ color:'#F5323F' }}>
                                                Respuesta:
                                            </Typography>
                                            <Box>
                                                <Grid 
                                                    container
                                                >
                                                    <Grid item xs={12} sm={10} md={6}>
                                                        <Box
                                                            sx={{
                                                                // backgroundColor:'yellow',
                                                                width:'100%',
                                                                display:'flex',
                                                                alignItems:'center',
                                                                alignContent:'center',
                                                            }}
                                                        >
                                                            <TextField 
                                                                id="outlined-basic" 
                                                                placeholder="Escribe una respuesta..." 
                                                                multiline 
                                                                rows={2} 
                                                                variant="outlined" 
                                                                sx={{
                                                                    backgroundColor:'white',
                                                                    width: {xs:'100%', sm:'95%', md:'95%'},
                                                                    m:'0 auto',
                                                                    ...stylePecsaMoffin,
                                                                }}
                                                                value={comentario}
                                                                onChange={(e)=>setComentario(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={2} md={2}>
                                                        <Box
                                                            sx={{
                                                                // backgroundColor:'yellow',
                                                                width:'100%',
                                                                height:60,
                                                                display:'flex',
                                                                alignItems:'center',
                                                                alignContent:'center',
                                                            }}
                                                        >
                                                            <Button 
                                                                variant="outlined"
                                                                color="inherit"
                                                                endIcon={<SendIcon />}
                                                                sx={{
                                                                    width:{xs:'100%', sm:'90%', md:'80%'},
                                                                    height:40,
                                                                    backgroundColor:'#F5323F',
                                                                    // borderColor:'#F5323F',
                                                                    color:'white',
                                                                    // borderWidth:2,
                                                                    borderRadius:'7px',
                                                                    '&:hover': {
                                                                        backgroundColor: '#db0a18',
                                                                        // color: 'white',
                                                                    },
                                                                    textTransform: 'none',
                                                                }}
                                                                onClick={()=>{getComentarios(comentario)}}
                                                            >
                                                                <b>Responder</b>
                                                            </Button>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Box>
                                    }
                                />
                            </ListItem> */}
                        </List>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
}

export { ComentariosLote }