import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { cancelarSeparacion, concluirSeparacion, realizarSeparacion, tramitarSeparacion } from "../../../functions/ListasClientes";

const SelectEstatus = ({ transaccion, estatus }) => {
    const [estatusSelected, setEstatusSelected] = useState(estatus);
    const getEstatusSelected = (event) => {
        setEstatusSelected(event.target.value);
        seleccionarEstatus(event.target.value);
    }

    const [listaEstatus, setListaEstatus] = useState([]);

    const seleccionarEstatus = (estatus) => {
        if(estatus === 2){
            realizarSeparacion(transaccion);
        }
        if(estatus === 3){
            cancelarSeparacion(transaccion);
        }
        if(estatus === 4){
            tramitarSeparacion(transaccion);
        }
        if(estatus === 5){
            concluirSeparacion(transaccion);
        }
    }

    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_API_URL}/api/estatus-compra`)
        .then((response)=>{
            setListaEstatus(response.data)
        })
        .catch((error)=>{
            console.log('hola');
        });
    },[]);

    return(
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Estatus</InputLabel>
            <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={estatusSelected}
                label="Estatus"
                onChange={getEstatusSelected}
            >
                {
                    listaEstatus.map((element)=>(
                        <MenuItem value={element.id}>{ element.nombre }</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    );
}

export { SelectEstatus }