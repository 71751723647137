import { Box } from "@mui/material";
import { useState } from "react";
import { CustomTypography } from "../../CustomTypography";
import { CustomTextField } from "../../CustomTextField";
import { CustomButton } from "../../CustomButton";

const InputButtonCombo = ({
  initialValue,
  handleClick,
  handleChange,
  label,
  variantLabel,
  buttonText,
  variantButton,
  colorButton,
  disabled = false,
  disabledButton = false,
  disabledTextField = false,
  columnLayout = false,
}) => {
  const [valor, setValor] = useState(null);
  const getValor = (newValue) => {
    setValor(newValue);
    if (handleChange) {
      handleChange(newValue);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2.5,
      }}
    >
      <Box>
        <CustomTypography variant={variantLabel ? variantLabel : "textPrimary"}>
          {label}
        </CustomTypography>
      </Box>

      <Box
        sx={{
          width: "100%",
          maxWidth: columnLayout ? "420px" : { xs: "420px", md: "100%" },
          display: "flex",
          flexDirection: columnLayout ? "column" : { xs: "column", md: "row" },
          alignSelf: "center",
          justifyContent: "center",
          gap: columnLayout ? 2.5 : 1.25,
        }}
      >
        <CustomTextField
          sx={{ height: "37px" }}
          handleChangueProp={getValor}
          initialValue={initialValue}
          disabled={disabled ? disabled : disabledTextField}
        />

        <CustomButton
          text={buttonText}
          variant={variantButton ? variantButton : "contained"}
          color={colorButton ? colorButton : "primary"}
          size="small"
          disabled={disabled ? disabled : disabledButton}
          onClick={() => handleClick(valor)}
        />
      </Box>
    </Box>
  );
};

export { InputButtonCombo };
