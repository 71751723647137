import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Box, ThemeProvider } from "@mui/material";
import { HeroSection } from "./HeroSection";
import { BenefitsSection } from "./BenefitsSection";
import { ListingSection } from "./ListingSection";
import { AdvantagesSection } from "./AdvantagesSection";
import { CTASection } from "./CTASection";
import { StepsSection } from "./StepsSection";
import { FAQSection } from "./FAQSection";
import { SimulatorSection } from "./SimulatorSection";
import theme from "../../App/theme";
import { ScrollToTop } from "../../components/ScrollToTop";

const InvestmentHome = () => {
  const [items, setItems] = useState([]);
  const [inversionInicial, setInversionInicial] = useState(null);
  const [loading, setLoading] = useState(true);

  const getInversionInicial = (newValue) => {
    setInversionInicial(newValue);
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/lotes-inversion`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setItems(data.lotesParaInversion);
        setLoading(false);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ mt: { xs: 7, sm: 8, md: 10 } }}>
        <Helmet>
          <title>Invierte en Casas Pecsa | Casas Pecsa</title>
          <meta name="description" />
        </Helmet>
        <ScrollToTop />
        <HeroSection />
        <SimulatorSection
          items={items}
          getInversionInicial={getInversionInicial}
          inversionInicial={inversionInicial}
        />
        <ListingSection loading={loading} />
        <BenefitsSection />
        <StepsSection />
        <AdvantagesSection />
        <FAQSection />
        <CTASection />
      </Box>
    </ThemeProvider>
  );
};

export { InvestmentHome };
