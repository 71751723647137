import React, { useState, useEffect } from "react";
import { Box, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FullHomeCard } from "../../../components/FullHomeCard";
import { CustomTypography } from "../../../components/CustomTypography";
import { CustomButton } from "../../../components/CustomButton";
import { CircularLoadingAnimation } from "../../../components/CircularLoadingAnimation";
import theme from "../../../App/theme";

const ListingSection = ({
	isCTAVisible,
	toggleCTAVisibility,
	activeButtonsCount,
	setActiveButtonsCount,
	addLotes,
	inversionInicial,
	getInversionInicial
}) => {
	const [items, setItems] = useState(null);
	const [loading, setLoading] = useState(true);

	let navigate = useNavigate();

  const navigateList = () => {
    getInversionInicial(null);
  }

	useEffect(() => {
		setLoading(true);
		let parametro = inversionInicial ? `?inversionInicial=${inversionInicial}` : "";
		fetch(`${process.env.REACT_APP_API_URL}/api/lotes-inversion${parametro}`)
		.then(response => response.json())
		.then(data => {
			setItems(data.lotesParaInversion)
			setLoading(false)
		})
  }, [inversionInicial])

	return (
		<Box
			component="section"
			sx={{
				backgroundColor: theme.palette.grey[200],
				pt: "1.25rem",
				pb: "2.5rem",
				[theme.breakpoints.up("sm")]: {
					pt: "2.5rem",
					pb: "6.25rem",
				},
			}}
		>
			<Container
				sx={{
					display: "flex",
					alignItems: "center",
					flexDirection: "column",
					gap: 1.875
				}}
			>
				{
					loading ?
					(
						<Box>
							<CircularLoadingAnimation />
						</Box>
					) : (
						items?.length <= 0 ? (
							<>
							<Box
								sx={{
									backgroundColor:theme.palette.warning.light,
									border: 2,
									borderColor:theme.palette.warning.main,
									width:'99%',
									borderRadius:'10px',
									display:'flex',
									alignItems:'center',
									justifyContent:'center',
									py:'15px'
								}}
							>
								<Container
									sx={{
										display: "flex",
										textAlign: "center",
										flexDirection: "column",
										gap: 3.75
									}}
								>
									<Box>
										<CustomTypography
											variant="textPrimary"
										>
												La cantidad que has ingresado no es suficiente para apartar tu casa ideal. Te invitamos a ingresar un monto mayor para asegurar tu inversión.
										</CustomTypography>
									</Box>
									</Container>
							</Box>
							<Box>
								<CustomButton
									variant="text"
									text="Regresa al listado &rarr;"
									onClick={navigateList}
									></CustomButton>
							</Box>
							

							</>
						): ( 
						
							items?.map(item => (
								<FullHomeCard 
									key={item.id}
									homeData={item}
									isCTAVisible={isCTAVisible}
									toggleCTAVisibility={toggleCTAVisibility}
									activeButtonsCount={activeButtonsCount}
									setActiveButtonsCount={setActiveButtonsCount}
									addLotes={addLotes}
								/>
							))
						
					)
					) 
				}
			</Container>
		</Box>
	)
}

export { ListingSection }