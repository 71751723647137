import { Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";

const CaracteristicasLote = ({ caracteristicas }) => {
    console.log(caracteristicas);
    return(
        <Container>
            <Box sx={{ textAlign:'left', marginTop:2, color:'#F5323F' }}>
                
                <h1>CARACTERISTICAS</h1>
               
           </Box>
            <Box>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{ textAlign:'left', color:'#707070', pb:5 }}
                    spacing={1}
                >
                    <Grid item xs={6} sm={6} md={6} >
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            sx={{ textAlign:'left' }}
                        >
                            <Grid item xs={12} sm={6} md={6} >
                                <Typography variant="body1" component="div">
                                    <b>Recámaras:</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} >
                                <Typography variant="body1" component="div">
                                    { caracteristicas.recamaras }
                                </Typography>                                
                            </Grid>
                        </Grid>                        
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                    <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            sx={{ textAlign:'left' }}
                        >
                            <Grid item xs={12} sm={6} md={6} >
                                <Typography variant="body1" component="div">
                                    <b>Valor de Renta:</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} >
                                <Typography variant="body1" component="div">
                                    {caracteristicas.valorRenta === 'null' ? 'N/A': `${caracteristicas.valorRenta} por mes`}
                                </Typography>                              
                            </Grid>
                        </Grid>
                        

                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            sx={{ textAlign:'left' }}
                        >
                            <Grid item xs={12} sm={6} md={6} > 
                                <Typography variant="body1" component="div">
                                    <b>Baños:</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} >
                                <Typography variant="body1" component="div">
                                    {`${caracteristicas.banios} ${caracteristicas.medioBanios === 'null' ? '' : caracteristicas.medioBanios }`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            sx={{ textAlign:'left' }}
                        >
                            <Grid item xs={12} sm={6} md={6} >
                                <Typography variant="body1" component="div">
                                    <b>Tipó y estilo:</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} >
                                <Typography variant="body1" component="div">
                                    { caracteristicas.tipoYEstilo !== 'null' && (caracteristicas.tipoYEstilo) }
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            sx={{ textAlign:'left' }}
                        >
                            <Grid item xs={12} sm={6} md={6} >
                                <Typography variant="body1" component="div">
                                    <b>Piso:</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} >
                                <Typography variant="body1" component="div">
                                    { caracteristicas.piso !== 'null' && (caracteristicas.piso) }
                                </Typography>
                            </Grid>
                        </Grid>                            
                    </Grid>
                    {/* <Grid item xs={6} sm={6} md={6}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            sx={{ textAlign:'left' }}
                        >
                            <Grid item xs={12} sm={6} md={6} >
                                <Typography variant="body1" component="div">
                                    <b>Sistema de Construcción:</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} >
                                <Typography variant="body1" component="div">
                                    { caracteristicas.sistemaDeConstruccion }
                                </Typography>
                            </Grid>
                        </Grid> 
                    </Grid> */}
                    <Grid item xs={6} sm={6} md={6}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            sx={{ textAlign:'left' }}
                        >
                            <Grid item xs={12} sm={6} md={6} >
                                <Typography variant="body1" component="div">
                                    <b>Estacionamiento:</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} >
                                <Typography variant="body1" component="div">
                                    { caracteristicas.estacionamiento !== null && (caracteristicas.estacionamiento) }
                                </Typography>
                            </Grid>
                        </Grid>                        
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            sx={{ textAlign:'left' }}
                        >
                            <Grid item xs={12} sm={6} md={6} >
                                <Typography variant="body1" component="div">
                                    <b>Voltaje de Luz:</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} >
                                <Typography variant="body1" component="div">
                                    { caracteristicas.voltajeDeLuz !== 'null' ? (caracteristicas.voltajeDeLuz) : '' }
                                </Typography>
                            </Grid>
                        </Grid>                        
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            sx={{ textAlign:'left' }}
                        >
                            <Grid item xs={12} sm={6} md={6} >
                                <Typography variant="body1" component="div">
                                    <b>Tipo de Gas:</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} >
                                <Typography variant="body1" component="div">
                                    { caracteristicas.tipoDeGas !== 'null' ? (caracteristicas.tipoDeGas) : '' }
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={6} sm={6} md={6}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            sx={{ textAlign:'left' }}
                        >
                            <Grid item xs={12} sm={6} md={6} >
                                <Typography variant="body1" component="div">
                                    <b>Superficie Total Habitable</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} >
                                <Typography variant="body1" component="div">
                                    146 m2
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid> */}
                    <Grid item xs={6} sm={6} md={6}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            sx={{ textAlign:'left' }}
                        >
                            <Grid item xs={12} sm={6} md={6} >
                                <Typography variant="body1" component="div">
                                    <b>Preparación para Minisplit:</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} >
                                <Typography variant="body1" component="div">
                                    { caracteristicas.minisplit !== 'null' && (caracteristicas.minisplit) }
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

export { CaracteristicasLote }