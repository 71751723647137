import { Button, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";

import EditIcon from '@mui/icons-material/Edit';
import { useParams } from "react-router-dom";
import axios from "axios";

const EditIdioma = ({ getEditar, idioma, updateDatos, }) => {
    const { folio } = useParams();
    // console.log(trabajo);
    const [lengua, setLengua] = useState(idioma.idioma);
    const getLengua = (e) => {
        setLengua(e.target.value);
    }

    const [nivel, setNivel] = useState(idioma.nivel);
    const getNivel = (e) => {
        setNivel(e.target.value);
    }

    const edit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        // const prueba = {
        //     idioma: data.get('idioma'),
        //     nivel: data.get('nivel'),
        // };
        // console.log(lugarDeTrabajo);
        // console.log(nivel); return;
        axios.post(`${process.env.REACT_APP_API_URL}/api/asesores/profile/${folio}/informacion/idiomas/${idioma.id}`,
            {
                idioma: data.get('idioma'),
                // nivel: data.get('nivel'),

            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
        .then((response)=>{
            console.log(response);
            // setInformacion(response.data)
            updateDatos(lengua, nivel);
            getEditar();
        })
        .catch((error)=>{
            console.log(error);
        });
    }
    useEffect(()=>{
        setLengua(idioma.idioma);
        setNivel(idioma.nivel);
    },[idioma]);

    return(
        <Box>
            <Box component="form" onSubmit={edit}>
                <Grid
                    container
                    spacing={1}
                >
                    <Grid item xs={12} sm={4} md={4}>
                        <TextField id="outlined-basic" label="Idioma" variant="outlined" name="idioma" value={lengua} onChange={getLengua} sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid>
                    {/* <Grid item xs={12} sm={4} md={4}>
                        <TextField id="outlined-basic" label="Nivel" variant="outlined" name="nivel" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid>                 */}
                    <Grid item xs={12} sm={6} md={4}>
                        <Box
                            sx={{
                                mt:1,
                                // mb:1,
                                // backgroundColor:'red',
                                width:'100%',
                                // textAlign:'right',
                            }}
                        >
                            <Button 
                                variant="contained" 
                                endIcon={<EditIcon />}
                                sx={{
                                    backgroundColor:'#707070',
                                    textTransform:'none',
                                    borderRadius:'10px',
                                    width:{ xs:'100%', sm:'100%', md:'40%' },
                                    mr:0.5,
                                }}
                                onClick={getEditar}
                            >
                                <b>Cancelar</b>
                            </Button>
                            <Button 
                                variant="contained" 
                                endIcon={<EditIcon />}
                                sx={{
                                    backgroundColor:'#707070',
                                    textTransform:'none',
                                    borderRadius:'10px',
                                    width:{ xs:'100%', sm:'100%', md:'40%' }
                                }}
                                type="submit"
                            >
                                <b>Guardar</b>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export { EditIdioma }