import { Button, Collapse, List, ListItem, ListItemText } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";

const Idiomas = ({ idiomas }) => {
    const [abrirCollapse, setAbrirCollapse] = useState(false);
    return(
        <Box>
            <Collapse in={abrirCollapse} collapsedSize={100}>
                <List>
                    {
                        idiomas.map((idioma) => (
                            <ListItem>
                                <ListItemText
                                    primary={idioma.idioma}
                                />
                            </ListItem>
                        ))
                    }
                </List>
            </Collapse>
            {
                idiomas.length > 3 && (
                    <Button 
                        variant="text" 
                        onClick={()=>{ setAbrirCollapse(!abrirCollapse) }}
                        sx={{
                            // backgroundColor:'red',
                            width:'100%',
                            textTransform:'none',
                            color:'#F5323F',
                            '&:hover':{
                                backgroundColor:'#f2f2f2',

                            }
                        }}
                    >
                        <b>Ver Más</b>
                    </Button>
                )
            }
        </Box>
    );
}

export { Idiomas }