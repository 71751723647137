import { Box, Container, Typography, Button } from "@mui/material";
import { Helmet } from "react-helmet";
import { YoutubeEmbed } from "../../functions/YoutubeEmbed";
import { handleDownload } from "../../functions/downloadPDF";
import { CalendlyModal } from "../../functions/CalendlyVideoCall";

import bg from "../../assets/tuCasaEnMexico/hero-small-tu-casa-en-mexico.jpg";
import logo from "../../assets/tuCasaEnMexico/logo-tu-casa-en-mexico.svg";
import { ScrollToTop } from "../../components/ScrollToTop";
// import ExamplePdf from "./folleto-tu-casa-en-mexico.pdf";

const reedirigirAChatWhatsapp = () => {
  window.open(
    `https://api.whatsapp.com/send?phone=528441630722&text=Hola!,%20vi%20tu%20pagina%20web%20quiero%20mas%20información`
  );
};

const TuCasaEnMexico = ({ onClick }) => {
  return (
    <Box
      sx={{
        mt: { xs: 7, sm: 8, md: 10 },
        backgroundColor: "#F5f5f5",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Helmet>
        <title>Tu Casa en México | Casas Pecsa</title>
        <meta name="description" content="a?" />
      </Helmet>
      <ScrollToTop />
      <Box variant="section" className="hero">
        <Box className="hero-wrapper hero__img">
          <Box component="img" src={bg} className="hero__background-image" />
          <Box className="hero__logo-box">
            <Box component="img" src={logo} className="hero__logo" />
          </Box>
        </Box>

        <Box variant="div" className="hero-wrapper hero__text-box">
          <Container
            className="hero__text-container"
            sx={{ width: "100%", mt: 4, mb: 2 }}
          >
            <Typography
              variant="h2"
              className="heading-primary"
              sx={{
                fontFamily: "Montserrat",
                fontSize: "clamp(3rem, 5vw + 1rem, 6rem)",
                fontWeight: "400",
                lineHeight: "1.1",
                width: "100%",
              }}
            >
              <b>
                Tu Casa en <span className="hero__span">México</span>
              </b>
            </Typography>
            <Typography
              variant="p"
              sx={{
                fontFamily: "Montserrat",
                fontSize: "clamp(1rem, 5vw + 0.5rem, 1.5rem)",
                textAlign: "left",
              }}
            >
              <p>
                Los mexicanos que viven y trabajan en Estados Unidos pueden
                adquirir una vivienda en México y pagarla en Estados Unidos, sin
                necesidad de trasladarse a México para realizar el trámite de
                formalización del crédito.
              </p>
            </Typography>
          </Container>

          <Container
            className="header__buttons"
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              textAlign: "center",
              gap: "10px",
            }}
          >
            <Box variant="div" sx={{ width: "100%" }}>
              <CalendlyModal>
                <Button
                  onClick={onClick}
                  className="btn container__btn"
                  sx={{
                    color: "#fff",
                    bgcolor: "#eb3235",
                    fontSize: "1rem",
                    textTransform: "capitalize",
                    flex: "1",
                    padding: "10px",
                    borderRadius: "10px",
                    fontWeight: "600",
                    mr: 2,
                    "&:hover": { bgcolor: "#ff3c49" },
                  }}
                  variant="contained"
                >
                  Agendar Videollamada
                </Button>
              </CalendlyModal>
            </Box>
            <Box variant="div" sx={{ width: "100%" }}>
              <Button
                onClick={reedirigirAChatWhatsapp}
                className="btn container__btn"
                sx={{
                  color: "#222",
                  borderColor: "#eb3235",
                  fontSize: "1rem",
                  textTransform: "capitalize",
                  flex: "1",
                  padding: "10px",
                  borderRadius: "10px",
                  fontWeight: "600",
                  "&:hover": {
                    bgcolor: "#ff3c49",
                    color: "#fff",
                    border: "1px solid #ff3c49",
                  },
                }}
                variant="outlined"
              >
                Enviar Mensaje por Whatsapp
              </Button>
            </Box>
          </Container>
        </Box>
      </Box>

      <Box varian="div" className="container">
        <Box variant="div" className="container-wrapper container__video">
          <YoutubeEmbed embedId="uz2YyeBJv3I" />
        </Box>

        <Container className="container-wrapper container__requirements">
          <Box sx={{ mb: 3 }}>
            <Typography
              variant="h3"
              sx={{
                fontFamily: "Montserrat",
                fontSize: "clamp(2rem, 5vw + 0.5rem, 3rem)",
              }}
              className="heading-tertiary"
            >
              <b>Requisitos</b>
            </Typography>
          </Box>

          <Box>
            <ul className="container__list">
              <li className="container__item">
                <span>
                  Ser mexicano y contar con una identificación oficial
                  (pasaporte, INE o matrícula consular).
                </span>
              </li>
              <li className="container__item">
                <span>Tener una edad mínima de 18 años.</span>
              </li>
              <li className="container__item">
                <span>
                  El plazo de tu crédito debe concluir antes de que cumplas 85
                  años.
                </span>
              </li>
              <li className="container__item">
                <span>Comprobar solvencia económica con ingresos lícitos.</span>
              </li>
              <li className="container__item">
                <span>
                  Tener un buen historial de crédito en Estados Unidos o México.
                </span>
              </li>
              <li className="container__item">
                <span>
                  Comprobar una antigüedad mínima de un año en tu empleo actual.
                </span>
              </li>
              <li className="container__item">
                <span>
                  Residir en el mismo domicilio desde hace al menos 6 meses, ya
                  sea en Estados Unidos o en México.
                </span>
              </li>
              <li className="container__item">
                <span>Contar con un familiar que te represente en México.</span>
              </li>
              <li className="container__item">
                <span>El monto mínimo de crédito es de $50,000.</span>
              </li>
              <li className="container__item">
                <span>
                  El costo de la vivienda debe ser de al menos $180,000.
                </span>
              </li>
            </ul>
          </Box>
        </Container>

        <Container
          className="container-wrapper container__details"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box sx={{ mb: 3 }}>
            <Typography
              variant="h3"
              sx={{
                fontFamily: "Montserrat",
                fontSize: "clamp(2rem, 5vw + 0.5rem, 3rem)",
              }}
              className="heading-tertiary"
            >
              <b>Conoce todos los detalles</b>
            </Typography>
          </Box>

          <Box variant="div" className="container__buttons">
            <CalendlyModal>
              <Button
                onClick={onClick}
                className="btn container__btn"
                sx={{
                  color: "#fff",
                  bgcolor: "#eb3235",
                  fontSize: "1rem",
                  textTransform: "capitalize",
                  flex: "1",
                  padding: "10px",
                  borderRadius: "10px",
                  fontWeight: "600",
                  mr: 2,
                  "&:hover": { bgcolor: "#ff3c49" },
                }}
                variant="contained"
              >
                Agendar Videollamada
              </Button>
            </CalendlyModal>
            <Button
              onClick={reedirigirAChatWhatsapp}
              className="btn container__btn"
              sx={{
                color: "#222",
                borderColor: "#eb3235",
                fontSize: "1rem",
                textTransform: "capitalize",
                flex: "1",
                padding: "10px",
                borderRadius: "10px",
                fontWeight: "600",
                "&:hover": {
                  bgcolor: "#ff3c49",
                  color: "#fff",
                  border: "1px solid #ff3c49",
                },
              }}
              variant="outlined"
            >
              Enviar Mensaje por Whatsapp
            </Button>
            <Button
              onClick={() =>
                handleDownload(
                  "https://storage.casaspecsa.com/Documentos/folleto-tu-casa-en-mexico.pdf",
                  "Tu Casa en México"
                )
              }
              className="btn container__btn"
              sx={{
                color: "#222",
                borderColor: "#eb3235",
                fontSize: "1rem",
                textTransform: "capitalize",
                flex: "1",
                padding: "10px",
                borderRadius: "10px",
                fontWeight: "600",
                "&:hover": {
                  bgcolor: "#ff3c49",
                  color: "#fff",
                  border: "1px solid #ff3c49",
                },
              }}
              variant="outlined"
            >
              Ver Folleto
            </Button>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export { TuCasaEnMexico };
