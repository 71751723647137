import { Box } from "@mui/material";

const BoxComparator = ({ children }) => {
	return(
		<Box
			sx={{
				// backgroundColor:'aquamarine',
				mb:2,
				display:'flex',
				flexDirection:'column',
				gap:2
			}}
		>
			{ children }
		</Box>
	);
}

export { BoxComparator }