import { IconButton, ImageListItem, ImageListItemBar } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import YouTubeIcon from '@mui/icons-material/YouTube';

const VideosModeloItem = ({ video, getVideo }) => {
    //console.log('videoItem', video);
    const reedirigirYouTube = (videoId) =>{
        window.open(`https://www.youtube.com/watch?v=${videoId}`)
      }
    return(
        <ImageListItem
            onClick={()=>{ getVideo(video.id.videoId) }}
            sx={{ cursor:'pointer' }}
        >
            <img
                src={`${video.snippet.thumbnails.high.url}?w=248&fit=crop&auto=format`}
                srcSet={`${video.snippet.thumbnails.high.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={video.snippet.description}
                loading="lazy"
            />
            <ImageListItemBar
                title={video.snippet.title || ''}
                subtitle={video.snippet.description || ' Visita: Casas Pecsa'}
                actionIcon={
                <IconButton
                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                    aria-label={`info about ${video.snippet.description}`}
                >
                    <YouTubeIcon />
                </IconButton>
                }
            />
        </ImageListItem>
    );
}

export { VideosModeloItem }