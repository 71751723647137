import { Box, Container } from "@mui/material";
import { CustomTypography } from "../../../components/CustomTypography";
import { InputButtonCombo } from "../../../components/Investment/InputButtonCombo";
import theme from "../../../App/theme";

const InvestmentAmountSection = ({ inversionInicial, getInversionInicial }) => {
  return (
    <Box
      component="section"
      sx={{
        backgroundColor: theme.palette.grey[200],
        pt: "1rem",
        pb: "1.25rem",
        [theme.breakpoints.up("sm")]: {
          pb: "2.5rem",
        },
      }}
    >
      <Container
        sx={{
          display: "flex",
          textAlign: "center",
          flexDirection: "column",
          gap: 3.75,
        }}
      >
        <InputButtonCombo
          variantLabel="textPrimary"
          label="¿De cuánto es tu inversión inicial?"
          buttonText="Buscar"
          initialValue={inversionInicial}
          handleClick={getInversionInicial}
        />
      </Container>
    </Box>
  );
};

export { InvestmentAmountSection };
