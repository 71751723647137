import { Box, Grid, Typography } from "@mui/material";

import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import { CustomTypography } from "../../../../../CustomTypography";

const ItemTableCharacteristics = ({ first, icon, name }) => {
    return(
        <Grid item xs={12} sm={6} md={6}>
            <Box
                sx={{
                    borderTop:{
                        xs: first ? '1px solid #CCCCCC' : '0px solid #CCCCCC',
                        sm: '0px solid #CCCCCC',
                        md: '1px solid #CCCCCC',
                    },
                    // borderTop:'1px solid #CCCCCC',
                    ...first && { 
                        borderRight: {
                            xs: '0px solid #CCCCCC',
                            sm: '0px solid #CCCCCC',
                            md: '1px solid #CCCCCC',
                        } 
                    },
                    borderBottom:'1px solid #CCCCCC',
                    textAlign:'center',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    height:40,
                    fontSize:'0.9rem'
                }}
            >
                { icon }                
                <CustomTypography variant="textTertiaryLight">
                    { name }
                </CustomTypography>
            </Box>
        </Grid>
    );
}

export { ItemTableCharacteristics }