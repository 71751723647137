import * as React from "react";
import { Navigate, NavLink, useLocation, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Collapse from "@mui/material/Collapse";

import logo from "../../assets/logo.svg";

import { ReactComponent as logo2 } from "../../assets/logo.svg";
// import {ReactComponent as menu} from '.../assets/Menu.svg';
import { ReactComponent as menu } from "../../assets/Menu.svg";
import { ReactComponent as palito } from "../../assets/palito.svg";
import { ReactComponent as search } from "../../assets/Search.svg";
import { ReactComponent as carrito } from "../../assets/shopping-cart.svg";
import { ReactComponent as userpecsa } from "../../assets/User.svg";
import {
  Card,
  CardMedia,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  SvgIcon,
} from "@mui/material";
import AlignHorizontalRightIcon from "@mui/icons-material/AlignHorizontalRight";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import LocalGroceryStoreOutlinedIcon from "@mui/icons-material/LocalGroceryStoreOutlined";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { getLoteConPrecioMasBajo } from "../../functions/getLoteConPrecioMasBajo";

const drawerWidth = 240;

//const pages = ['MODELOS', 'FRACCIONAMIENTOS', 'AGENDAR CITA', 'COMPRAR TU CASA', 'PREAUTORIZAR CREDITO'];
const pages = [
  { url: "/modelo/:modelo", name: "MODELOS", nameGA: "BotonModelosHeader" },
  {
    url: "/login",
    name: "FRACCIONAMIENTOS",
    nameGA: "BotonFraccionamientosHeader",
  },
  { url: "/", name: "AGENDAR CITA", nameGA: "BotonAgendarCitaHeader" },
  { url: "/", name: "COMPRAR TU CASA", nameGA: "BotonTiendaHeader" },
  { url: "/", name: "PREAUTORIZAR CREDITO", nameGA: "BotonPreAutorizarHeader" },
];
//const settings = ['BLOG', 'VLOG', 'GUIA DE COMPRA EN LINEA', 'POST VENTA', 'Usuarios Pecsa'];
const settings = [
  { url: "/blog", name: "Blog" },
  { url: "/recomienda", name: "¡Recomienda y Gana!" },
  { url: "/revista-decora", name: "Revista DECORA" },
  { url: "/videoblog", name: "Video Blog Pecsa" },
  { url: "/asesores", name: "Asesores" },
  { url: "/tu-casa-en-mexico", name: "Tu Casa en México" },
  { url: "/invierte", name: "Invierte en Casas Pecsa" },
  { url: "/renta", name: "Renta una Casa Pecsa" },
  {
    url: "https://storage.casaspecsa.com/Documentos/catálogo-equipamiento-casas-pecsa.pdf",
    name: "Equipa tu Casa Pecsa",
  },
  // {url:'/modelo', name:'VLOG'},
  // {url:'/modelo', name:'GUIA DE COMPRA EN LINEA'},
  // {url:'/modelo', name:'POST VENTA'},
  // {url:'/usuarios', name:'Usuarios Pecsa'},
  // {url:'/cargas/fraccionamientos', name:'Carga de Inventario'},
];

const MyHeader = ({ modelos, fraccionamientos, cliente }) => {
  let navigate = useNavigate();
  let location = useLocation();
  // console.log('header',location);
  // console.log('-----',fraccionamientos);

  const [modelo, setModelo] = React.useState(null);
  const [fraccionamiento, setFraccionamiento] = React.useState(null);

  const [checkedModelo, setCheckedModelo] = React.useState(false);
  const [checkedFraccionamiento, setCheckedFraccionamiento] =
    React.useState(false);

  const [fraccionamientosDisponibles, setFraccionamientosDisponibles] =
    React.useState([]);

  const getFaccionamientosDisponibles = (modelo) => {
    let prefraccionamiento = [];
    const lotesdis = modelo.fachadas.map((fachada) => fachada.lotes);
    const pre = lotesdis.flat();
    const prepre = pre.map((item) => item.manzana.etapa.fraccionamiento);
    // const frafinal = prepre.filter((item, index) =>{
    //   return prepre.indexOf(item) === index;
    // })
    // const frafinal = new Set(prepre);
    // prepre.forEach(element => {
    //   if(!prepre.find((pr) => pr === element) ){
    //     return element;
    //   }
    // });
    let hash = {};
    const frafinal = prepre.filter((current) => {
      var exists = !hash[current.id];
      hash[current.id] = true;
      return exists;
    });
    setFraccionamientosDisponibles(frafinal);
    console.log(fraccionamientosDisponibles);
  };

  const [lotesDisponibles, setLotesDisponibles] = React.useState(0);

  const contarLotesDisponibles = (fraccionamiento) => {
    const LotesDisponibles = fraccionamiento.etapas.reduce((count, current) => {
      return (
        count +
        current.manzanas.reduce((count, current) => {
          return count + current.lotes.length;
        }, 0)
      );
    }, 0);
    return LotesDisponibles;
  };

  const getFormatoMoneda = (cantidad) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD", //MXN
      minimumFractionDigits: 0,
    });

    var value = 10000;

    return formatter.format(cantidad);
  };

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElSettings, setAnchorElSettings] = React.useState(null);

  const handleChange = () => {
    setCheckedModelo((prev) => !prev);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenSettingsMenu = (event) => {
    setAnchorElSettings(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseSettingsMenu = () => {
    setAnchorElSettings(null);
  };

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const open = Boolean(anchorElUser);
  const handleClickUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  //const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [openModelosNav, setOpenModelosNav] = React.useState(false);

  const handleClickModelosNav = () => {
    setOpenModelosNav(!openModelosNav);
  };

  const [openFraccionamientosNav, setOpenFraccionamientosNav] =
    React.useState(false);

  const handleClickFraccionamientosNav = () => {
    setOpenFraccionamientosNav(!openFraccionamientosNav);
  };

  const reedirigirCalendly = () => {
    window.open("https://calendly.com/casas-pecsa");
  };
  const reedirigirMoffin = () => {
    // window.open('https://pecsa.moffin.co/solicitud/hipotecario/comenzar');
    navigate("/preautorizacion");
  };
  const logOut = () => {
    localStorage.removeItem("id");
    localStorage.removeItem("type");
    localStorage.removeItem("token");
    localStorage.removeItem("userClient");
    localStorage.clear();
    navigate("/");
  };
  const drawer = (
    <Box
      /*onClick={handleDrawerToggle}*/ sx={{
        color: "#F5323F",
        textAlign: "center",
      }}
    >
      {/* <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography> */}
      {/* <Avatar> */}
      <PersonOutlineOutlinedIcon
        sx={{ mt: 2, fontSize: 50, color: "#707070" }}
      />
      {cliente ? (
        <React.Fragment>
          {/* <Typography variant="body1">
            {cliente === "" ? "Bienvenido!" : cliente}
          </Typography> */}
          <Typography
            variant="body2"
            sx={{ mb: 1, color: "#707070" }}
            onClick={() => {
              navigate("/profile/datos");
              handleDrawerToggle();
            }}
          >
            Mi cuenta
          </Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography
            variant="body1"
            onClick={() => {
              navigate("/login");
              handleDrawerToggle();
            }}
          >
            Iniciar Sesion
          </Typography>
          {/* <Typography
            variant="body1"
            onClick={() => {
              navigate("/registrarse");
              handleDrawerToggle();
            }}
          >
            Registrarse
          </Typography> */}
        </React.Fragment>
      )}

      {/* </Avatar>        */}
      {/* <SvgIcon component={userpecsa} /> */}

      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "left" }}
            onClick={handleClickModelosNav}
            className="BotonModelosHeader"
          >
            <ListItemText primary="MODELOS" />
            {openModelosNav ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openModelosNav} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {modelos.map((modelo) => {
              if (modelo.fachadas.length > 0) {
                return (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      navigate(`/modelo/${modelo.nombre.replace(/ /g, "_")}`);
                      handleDrawerToggle();
                    }}
                  >
                    <ListItemText primary={modelo.nombre} />
                  </ListItemButton>
                );
              }
            })}
          </List>
        </Collapse>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "left" }}
            onClick={handleClickFraccionamientosNav}
          >
            <ListItemText primary="FRACCIONAMIENTOS" />
            {openFraccionamientosNav ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openFraccionamientosNav} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {fraccionamientos.map((fraccionamiento) => (
              <ListItemButton
                className="BotonFraccionamientosHeader"
                sx={{ pl: 4 }}
                onClick={() => {
                  navigate(
                    `/fraccionamiento/${fraccionamiento.nombre.replace(
                      / /g,
                      "_"
                    )}`
                  );
                  handleDrawerToggle();
                }}
              >
                <ListItemText primary={fraccionamiento.nombre} />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
        <ListItem disablePadding>
          {/* <ListItemButton sx={{ textAlign: 'left' }} onClick={reedirigirCalendly} className="BotonAgendarCitaHeader"> */}
          <ListItemButton
            sx={{ textAlign: "left" }}
            onClick={() => {
              navigate("/citas");
            }}
            className="BotonAgendarCitaHeader"
          >
            <ListItemText primary="AGENDAR CITA" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "left" }}
            onClick={() => {
              navigate("/tienda");
              handleDrawerToggle();
            }}
            className="BotonTiendaHeader"
          >
            <ListItemText primary="COMPRAR TU CASA" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding divider>
          <ListItemButton
            sx={{ textAlign: "left" }}
            className="BotonPreAutorizarHeader"
          >
            <ListItemText
              primary="PREAUTORIZAR CREDITO"
              onClick={reedirigirMoffin}
            />
          </ListItemButton>
        </ListItem>
        {settings.map((item) => (
          <ListItem disablePadding>
            <ListItemButton
              sx={{ textAlign: "left" }}
              onClick={() => {
                if (item.url.startsWith("http")) {
                  // Si la URL comienza con "http", se considera un enlace externo
                  window.open(item.url, "_blank");
                } else {
                  navigate(item.url);
                }
                handleCloseSettingsMenu();
              }}
            >
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
        {/* {pages.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'left' }}>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))} */}
      </List>
    </Box>
  );
  const blacklist = ["/pruebaPDF", "/estrenaton"];
  const container = undefined;
  // if(location.pathname !== '/pruebaPDF'){
  if (!blacklist.includes(location.pathname)) {
    return (
      <div>
        <AppBar style={{ background: "#ffffff" }}>
          <Container maxWidth="lg">
            <Toolbar disableGutters>
              {/*logo1 pc*/}
              {/* <NavLink to="/"> */}
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
                color="red"
                onClick={() => {
                  navigate("/");
                }}
              >
                <img
                  src={logo}
                  alt="logo1"
                  style={{ width: 167, cursor: "pointer" }}
                />
              </Typography>
              {/* </NavLink> */}
              {/*icono bar*/}
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleDrawerToggle}
                  color="error"
                >
                  <MenuIcon sx={{ color: "red" }} />
                </IconButton>

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                    fontFamily: "Montserrat",
                  }}
                >
                  {pages.map((page) => (
                    // <NavLink to={page.url}>
                    <MenuItem
                      key={page.name}
                      onClick={handleCloseNavMenu}
                      className={page.nameGA}
                    >
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                    // </NavLink>
                  ))}
                </Menu>
              </Box>
              {/* fin-icono bar*/}

              <Box
                onClick={() => {
                  navigate("/");
                }}
                sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
              >
                <img src={logo} alt="logo" style={{ width: 107 }} />
              </Box>
              {/* pages-lg */}
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                  minHeight: 80,
                }}
              >
                {pages.map((page) => {
                  if (page.name === "MODELOS") {
                    return (
                      <Button
                        key={page.name}
                        // onMouseOver={handleChange}
                        onMouseOver={() => setCheckedModelo(true)}
                        // onMouseOut={handleChange}
                        onMouseOut={() => setCheckedModelo(false)}
                        sx={{ my: 2, color: "red", display: "block" }}
                        className="BotonModelosHeader"
                      >
                        {page.name}
                      </Button>
                    );
                  } else {
                    if (page.name === "FRACCIONAMIENTOS") {
                      return (
                        <Button
                          key={page.name}
                          // onMouseOver={handleChange}
                          onMouseOver={() => setCheckedFraccionamiento(true)}
                          // onMouseOut={handleChange}
                          onMouseOut={() => setCheckedFraccionamiento(false)}
                          sx={{ my: 2, color: "red", display: "block" }}
                          // className="BotonFraccionamientosHeader"
                        >
                          {page.name}
                        </Button>
                      );
                    } else {
                      if (page.name === "COMPRAR TU CASA") {
                        return (
                          <Button
                            key={page.name}
                            sx={{ my: 2, color: "red", display: "block" }}
                            onClick={() => {
                              navigate("/tienda");
                            }}
                            className="BotonTiendaHeader"
                          >
                            {page.name}
                          </Button>
                        );
                      } else {
                        if (page.name === "AGENDAR CITA") {
                          return (
                            <Button
                              key={page.name}
                              sx={{ my: 2, color: "red", display: "block" }}
                              // onClick={reedirigirCalendly}
                              onClick={() => {
                                navigate("/citas");
                              }}
                              className="BotonAgendarCitaHeader"
                            >
                              {page.name}
                            </Button>
                          );
                        } else {
                          if (page.name === "PREAUTORIZAR CREDITO") {
                            return (
                              <Button
                                key={page.name}
                                sx={{ my: 2, color: "red", display: "block" }}
                                onClick={reedirigirMoffin}
                                className="BotonPreAutorizarHeader"
                              >
                                {page.name}
                              </Button>
                            );
                          } else {
                            return (
                              <Button
                                key={page.name}
                                sx={{ my: 2, color: "red", display: "block" }}
                                onClick={() => {}}
                              >
                                {page.name}
                              </Button>
                            );
                          }
                        }
                      }
                    }
                  }
                })}
                {/* {pages.map((page) => (
                  // <NavLink to={page.url}>
                  <Button
                    key={page.name}
                    // onMouseOver={handleChange}
                    onMouseOver={()=>setCheckedModelo(true)}
                    // onMouseOut={handleChange}
                    onMouseOut={()=>setCheckedModelo(false)}
                    sx={{ my: 2, color: 'red', display: 'block', }}
                  >
                    {page.name}
                  </Button>
                  // </NavLink>
                ))} */}
              </Box>
              {/* fin-pages-lg */}
              {/* iconos*/}
              {/* icono-lg */}
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <IconButton
                  onClick={handleOpenSettingsMenu}
                  size="small"
                  color="inherit"
                >
                  <SvgIcon component={menu} />
                </IconButton>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElSettings}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElSettings)}
                  onClose={handleCloseSettingsMenu}
                >
                  {settings.map((setting) => (
                    // <NavLink to={setting.url}>
                    <MenuItem
                      key={setting.name}
                      onClick={() => {
                        if (setting.url.startsWith("http")) {
                          // Si la URL comienza con "http", se considera un enlace externo
                          window.open(setting.url, "_blank");
                        } else {
                          navigate(setting.url);
                        }
                        handleCloseSettingsMenu();
                      }}
                    >
                      <Typography textAlign="center">{setting.name}</Typography>
                    </MenuItem>
                    // </NavLink>
                  ))}
                </Menu>
                <SvgIcon component={palito} />
                {/* <IconButton 
                  size="small"
                  color="inherit"
                >
                  <Badge badgeContent={2} color="error">
                  <SvgIcon component={carrito} />
                  </Badge>
                </IconButton> */}
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={handleClickUserMenu}
                >
                  <SvgIcon component={userpecsa} />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElUser}
                  open={open}
                  onClose={handleCloseUserMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      navigate("/profile/datos");
                      handleCloseUserMenu();
                    }}
                  >
                    Mi cuenta
                  </MenuItem>
                  {localStorage.getItem("userClient") && (
                    <MenuItem
                      onClick={() => {
                        logOut();
                        handleCloseUserMenu();
                      }}
                    >
                      Logout
                    </MenuItem>
                  )}
                </Menu>
              </Box>
              {/* fin-icono-lg */}
              {/* icono-md */}
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={handleClickUserMenu}
                >
                  <SvgIcon component={userpecsa} />
                </IconButton>
                {/* <IconButton 
                  size="small"
                  color="inherit"
                >
                  <Badge badgeContent={2} color="error">
                  <SvgIcon component={carrito} />
                  </Badge>
                </IconButton> */}
              </Box>
              {/* fin-icono-md */}
              {/* fin-iconos*/}
            </Toolbar>
            <Collapse in={checkedModelo}>
              <Box
                sx={{ minHeight: 290, color: "red" }}
                onMouseOut={() => setCheckedModelo(false)}
                onMouseOver={() => setCheckedModelo(true)}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Grid item xs={8} sm={8} md={4}>
                    <Typography variant="body1" gutterBottom>
                      <b>MODELOS</b>
                    </Typography>
                    <List sx={{ width: "80%" }}>
                      {modelos.length > 0 ? (
                        modelos.map((modelo) => {
                          if (modelo.fachadas.length > 0) {
                            return (
                              <ListItem disablePadding>
                                {/* <NavLink to={`/modelo/${modelo.nombre.replace(/ /g, "_")}`}> */}
                                <ListItemButton
                                  key={modelo.id}
                                  onMouseOver={() => {
                                    setModelo(modelo);
                                    getFaccionamientosDisponibles(modelo);
                                  }}
                                  onClick={() => {
                                    /*setCheckedModelo(false);*/ navigate(
                                      `/modelo/${modelo.nombre.replace(
                                        / /g,
                                        "_"
                                      )}`
                                    );
                                  }}
                                  // onClick={() => navigate(`/`)}
                                >
                                  {/* <ListItemIcon>
                                    <InboxIcon />
                                  </ListItemIcon> */}
                                  <ListItemText primary={modelo.nombre} />
                                </ListItemButton>
                                {/* </NavLink> */}
                              </ListItem>
                            );
                          }
                        })
                      ) : (
                        <h1>No hay modelos disponibles</h1>
                      )}
                    </List>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    {modelo ? (
                      <Box sx={{}}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          sx={{
                            // backgroundColor:'yellow',
                            height: 280,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            /*setCheckedModelo(false);*/ navigate(
                              `/modelo/${modelo.nombre.replace(/ /g, "_")}`
                            );
                          }}
                        >
                          <Grid item md={4}>
                            <Box
                              sx={{
                                // backgroundColor:'red',
                                width: "100%",
                                height: 245,
                                // borderRadius:'16px',
                                display: "flex",
                                flexFlow: "row nowrap",
                                alignItems: "center",
                              }}
                            >
                              <CardMedia
                                component="img"
                                //height="360"
                                image={modelo.fachadas[0].fotos[0].url}
                                alt="green iguana"
                                sx={{
                                  borderRadius: "16px",
                                  boxShadow:
                                    "0px 5px 12px 0px rgb(0, 0, 0, 0.19)",
                                  width: "95%",
                                  height: "95%",
                                  ml: 0.8,
                                }}
                              />
                              {/* <Box
                                  component='img'
                                  src={modelo.fachadas[0].fotos[0].url}
                                  sx={{
                                    maxWidth:'100%',
                                    //maxHeight: '100%',
                                    // borderRadius: '16px',
                                  }}
                                /> */}
                            </Box>
                          </Grid>
                          <Grid item md={4}>
                            <Box
                              sx={{
                                // backgroundColor:'orange',
                                width: "100%",
                                pt: 2,
                                pl: 2,
                              }}
                            >
                              <Typography
                                variant="h5"
                                sx={{ color: "#F5323F" }}
                              >
                                Modelo <b>{modelo.nombre}</b>
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{ color: "#707070" }}
                              >
                                {`${modelo.superficieDeConstruccion}m² de construcción`}
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ color: "#707070" }}
                              >
                                Baños: <b>{modelo.banios}</b>
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ color: "#707070" }}
                              >
                                Habitaciones: <b>{modelo.habitaciones}</b>
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{ color: "#707070" }}
                              >
                                Desde:
                              </Typography>
                              <Typography
                                variant="h5"
                                sx={{ color: "#707070" }}
                              >
                                {/* <b>{getFormatoMoneda(modelo.precio)}</b> */}
                                <b>
                                  {getFormatoMoneda(
                                    getLoteConPrecioMasBajo(
                                      modelo.fachadas
                                        .map((fachada) => fachada.lotes)
                                        .flat()
                                    ).precio
                                  )}
                                </b>
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item md={4}>
                            <Box
                              sx={{
                                // backgroundColor:'greenyellow',
                                width: "100%",
                              }}
                            >
                              <Typography
                                variant="body1"
                                sx={{ color: "#707070" }}
                              >
                                Este modelo se encuentar disponible en:
                              </Typography>
                              <Box
                                sx={{
                                  //backgroundColor:'red',
                                  width: "100%",
                                }}
                              >
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="center"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  {fraccionamientosDisponibles.map(
                                    (fraccionamientoDisponible, index) => (
                                      <Grid item md={6}>
                                        <Box
                                          sx={{
                                            width: "100%",
                                            height: 60,
                                            // backgroundColor:'red',
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Box
                                            component="img"
                                            src={fraccionamientoDisponible.logo}
                                            sx={{
                                              maxWidth: "90%",
                                              maxHeight: "100%",
                                              m: "0 auto",
                                            }}
                                          />
                                          {/* <Card
                                              sx={{
                                                width:'90%',
                                                height:70,
                                                borderRadius:'9px',
                                                boxShadow: '0px 5px 12px 0px rgb(0, 0, 0, 0.19)',
                                              }}
                                            >
                                              <CardMedia
                                                component="img"
                                                image={fraccionamientoDisponible.logo}
                                                height="80%"
                                                // sx={{
                                                //   width:'100%',
                                                // }}
                                              />
                                            </Card> */}
                                        </Box>
                                      </Grid>
                                    )
                                  )}
                                  {fraccionamientosDisponibles.length > 3 && (
                                    <Grid item md={6}>
                                      <Box
                                        sx={{
                                          width: "100%",
                                        }}
                                      >
                                        <Card
                                          sx={{
                                            width: "90%",
                                            height: 70,
                                            borderRadius: "9px",
                                          }}
                                        >
                                          Ver mas
                                        </Card>
                                      </Box>
                                    </Grid>
                                  )}
                                </Grid>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item md={12}>
                            <Box
                              sx={{
                                // backgroundColor:'green',
                                textAlign: "center",
                              }}
                            >
                              {/* <Button variant="text"
                                  color='error'
                                  sx={{
                                    borderColor:'#F5323F',
                                    color:'#F5323F',
                                    // m:1
                                  }}
                                  onClick={()=>{ navigate(`/modelo/${modelo.nombre.replace(/ /g, "_")}`) }}
                                >
                                  Ver mas
                                </Button> */}
                              <Button
                                size="small"
                                endIcon={<ArrowForwardIcon />}
                                sx={{ color: "#f5323f", textTransform: "none" }}
                                //onClick={() => navigate(`/modelo/${modelo.nombre}`, {state:{modelo:modelo}})}
                              >
                                <b>Ver más</b>
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    ) : (
                      <h1></h1>
                    )}

                    {/* {
                      modelo ? (
                        <Box>
                          <Box
                            component='img'
                            src={modelo.fachadas[0].fotos[0].url}
                            sx={{
                              width: 180,
                              height: 180,
                              borderRadius: '16px',
                            }}
                          />
                          <Typography variant="body1" color="text.secondary">
                            <b>{modelo.nombre}</b>
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {modelo.superficieDeConstruccion}m² de construcción
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            ${modelo.precio}
                          </Typography>
                          <Button variant="outlined"
                            color='error'
                            sx={{
                              borderColor:'#F5323F',
                              color:'#F5323F',
                              m:1
                            }}
                            onClick={()=>{ navigate(`/modelo/${modelo.nombre.replace(/ /g, "_")}`) }}
                          >Ver mas</Button>
                        </Box>
                      ) : (
                        <h1></h1>
                      )
                    } */}
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
            <Collapse in={checkedFraccionamiento}>
              <Box
                sx={{ minHeight: 290, color: "red" }}
                onMouseOut={() => setCheckedFraccionamiento(false)}
                onMouseOver={() => setCheckedFraccionamiento(true)}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Grid item xs={8} sm={8} md={4}>
                    <Typography variant="body1" gutterBottom>
                      <b>FRACCIONAMIENTOS</b>
                    </Typography>
                    <List>
                      {fraccionamientos.length > 0 ? (
                        fraccionamientos.map((fraccionamiento) => (
                          <ListItem disablePadding>
                            <ListItemButton
                              onMouseOver={() => {
                                setFraccionamiento(fraccionamiento);
                                setLotesDisponibles(
                                  contarLotesDisponibles(fraccionamiento)
                                );
                              }}
                              onClick={() => {
                                setCheckedFraccionamiento(false);
                                navigate(
                                  `/fraccionamiento/${fraccionamiento.nombre.replace(
                                    / /g,
                                    "_"
                                  )}`
                                );
                              }}
                              className="BotonFraccionamientosHeader"
                            >
                              {/* <ListItemIcon>
                                  <InboxIcon />
                                </ListItemIcon>*/}
                              <ListItemText primary={fraccionamiento.nombre} />
                            </ListItemButton>
                          </ListItem>
                        ))
                      ) : (
                        <h1>No hay Fraccionamientos disponibles</h1>
                      )}
                    </List>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    {fraccionamiento ? (
                      <Box
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setCheckedFraccionamiento(false);
                          navigate(
                            `/fraccionamiento/${fraccionamiento.nombre.replace(
                              / /g,
                              "_"
                            )}`
                          );
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <Grid item md={6}>
                            <Box
                              sx={{
                                // backgroundColor:'red',
                                width: "100%",
                                height: 245,
                                // borderRadius:'16px',
                                display: "flex",
                                flexFlow: "row nowrap",
                                alignItems: "center",
                              }}
                            >
                              {/* <CardMedia
                                    component="img"
                                    //height="360"
                                    image={fraccionamiento.fotos[0].url}
                                    alt="green iguana"
                                    sx={{
                                      borderRadius: '16px',
                                      boxShadow: '0px 5px 12px 0px rgb(0, 0, 0, 0.19)',
                                      width:'95%',
                                      height:'95%',
                                      ml:0.8,
                                    }}
                                /> */}
                              <Box
                                sx={{
                                  // backgroundColor:'pink',
                                  borderRadius: "16px",
                                  boxShadow:
                                    "0px 5px 12px 0px rgb(0, 0, 0, 0.19)",
                                  width: "95%",
                                  height: "95%",
                                  ml: 0.8,
                                  display: "flex",
                                  alignItems: "flex-end",
                                  overflow: "hidden",
                                }}
                              >
                                <Box
                                  component="img"
                                  src={fraccionamiento.fotos[0].url}
                                  sx={{
                                    width: "100%",
                                    minHeight: "100%",
                                    // height:'95%',
                                    // bottom:0,
                                  }}
                                />
                              </Box>
                              {/* <Box
                                  component="img"
                                  src={fraccionamiento.fotos[0].url}
                                  sx={{
                                    borderRadius: '16px',
                                    boxShadow: '0px 5px 12px 0px rgb(0, 0, 0, 0.19)',
                                    width:'95%',
                                    height:'95%',
                                    ml:0.8,
                                  }}
                                /> */}
                              {/* <Box
                                  component='img'
                                  src={modelo.fachadas[0].fotos[0].url}
                                  sx={{
                                    maxWidth:'100%',
                                    //maxHeight: '100%',
                                    // borderRadius: '16px',
                                  }}
                                /> */}
                            </Box>
                          </Grid>
                          <Grid item md={6}>
                            <Box
                              sx={{
                                // backgroundColor:'orange',
                                width: "100%",
                                pt: 2,
                                pl: 0.5,
                              }}
                            >
                              <Box
                                sx={{
                                  // backgroundColor:'green',
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  sx={{ color: "#F5323F" }}
                                >
                                  <b>{fraccionamiento.nombre}</b>
                                </Typography>
                                <Box
                                  component="img"
                                  src={fraccionamiento.logo}
                                  sx={{
                                    width: 85,
                                  }}
                                />
                              </Box>
                              <Typography
                                variant="body2"
                                sx={{ color: "#707070" }}
                              >
                                {fraccionamiento.descripcionUbicacion}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{ color: "#707070" }}
                              >
                                {fraccionamiento.municipio.nombre}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{ mt: 0.5, color: "#707070" }}
                              >
                                Lotes disponibles:
                                <b>{` ${lotesDisponibles}`}</b>
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{ mt: 0.5, color: "#707070" }}
                              >
                                {fraccionamiento.descripcion}
                              </Typography>

                              <Typography
                                variant="h5"
                                sx={{ color: "#707070" }}
                              >
                                {/* <b>{getFormatoMoneda(modelo.precio)}</b> */}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item md={12}>
                            <Box
                              sx={{
                                // backgroundColor:'green',
                                textAlign: "center",
                              }}
                            >
                              {/* <Button variant="text"
                                  color='error'
                                  sx={{
                                    borderColor:'#F5323F',
                                    color:'#F5323F',
                                    // m:1
                                  }}
                                  onClick={()=>{ navigate(`/modelo/${modelo.nombre.replace(/ /g, "_")}`) }}
                                >
                                  Ver mas
                                </Button> */}
                              <Button
                                size="small"
                                endIcon={<ArrowForwardIcon />}
                                sx={{ color: "#f5323f", textTransform: "none" }}
                                //onClick={() => navigate(`/modelo/${modelo.nombre}`, {state:{modelo:modelo}})}
                              >
                                <b>Ver más</b>
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    ) : (
                      <h1></h1>
                    )}
                    {/* {
                      fraccionamiento ? (
                        <Box>
                          <Box
                            component='img'
                            src={fraccionamiento.fotos[0].url}
                            sx={{
                              width: 220,
                              height: 120
                            }}
                          />
                          <Typography variant="body2" color="text.secondary">
                            {fraccionamiento.descripcionUbicacion}
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                            <b>{fraccionamiento.municipio.nombre}</b>
                          </Typography>
                          
                          <Typography variant="body2" color="text.secondary">
                            {fraccionamiento.descripcion}
                          </Typography>
                        </Box>
                      ) : (
                        <h1></h1>
                      )
                    } */}
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
          </Container>
        </AppBar>
        <Box component="nav">
          <Drawer
            // container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>

        {/* <Collapse in={checked}>
          <Box>hey hey hey</Box>
        </Collapse>       */}
      </div>
    );
  }
};

export { MyHeader };
