import { Button, Collapse, Divider, Grid, List, ListItem, ListItemText } from "@mui/material";
import { Box } from "@mui/system";
import { Educacion } from "./Educacion";
import { Experiencia } from "./Experiencia";
import { Idiomas } from "./Idiomas";
import { Texto } from "./Texto";

const AcercaDeMiAsesores = ({ asesor }) =>{
    return(
        <Box>
            <Grid 
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={12} sm={12} md={12}>
                    <Box sx={{ color:'#F5323F' }}>
                        <List>
                            <ListItem>
                                <ListItemText
                                    primary={
                                        <b>Sobre mí</b>
                                    } 
                                    secondary={
                                        <Texto acercademi={asesor.acercademi} />                                                               
                                    } 
                                />
                            </ListItem>
                            <Divider/>
                            <ListItem>
                                <ListItemText 
                                    primary={
                                        <b>Experiencia</b>
                                    } 
                                    secondary={
                                        <Experiencia experiencia={asesor.experiencia} />                                                                
                                    } 
                                />
                            </ListItem>
                            <Divider/>
                            <ListItem>
                                <ListItemText 
                                    primary={
                                        <b>Educacion</b>
                                    } 
                                    secondary={
                                        <Educacion educacion={asesor.educacion}/>                                                               
                                    } 
                                />
                            </ListItem>
                            <Divider/>
                            <ListItem>
                                <ListItemText 
                                    primary={
                                        <b>Idioma</b>
                                    } 
                                    secondary={
                                        <Idiomas idiomas={asesor.idiomas}/>                                                                
                                    } 
                                />
                            </ListItem>
                            <Divider/>
                        </List>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export { AcercaDeMiAsesores }