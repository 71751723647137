import React from "react";
import { Box } from "@mui/material";
import { Container, keyframes } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import { CustomTypography } from "../../../components/CustomTypography";
import { CustomButton } from "../../../components/CustomButton";
import theme from "../../../App/theme";

const HeroSection = () => {
  let navigate = useNavigate();

  const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

  const slideUp = keyframes`
	from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

  return (
    <Box
      component="section"
      sx={{
        pt: "8vh",
        pb: "10vh",
        backgroundImage: `url('https://storage.casaspecsa.com/invierte-renta/banners/principal-renta-desktop.png')`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        animation: `${fadeIn} 1s ease-in`,
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center",
          height: "calc(70vh - 80px)",
        }}
      >
        <Box>
          <Box
            sx={{
              animation: `${fadeIn} 1.5s ease-in`,
            }}
          >
            <img
              src="https://storage.casaspecsa.com/generales/casaspecsadotcom.png"
              alt="imagen de casaspecsa.com"
              width={"150px"}
            />
          </Box>

          <Box
            sx={{
              animation: `${slideUp} 1.5s ease-in-out`,
            }}
          >
            <CustomTypography
              variant="headingSecondary"
              txtColor={theme.palette.common.white}
            >
              Renta una casa que se adapte a tu estilo de vida
            </CustomTypography>
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 3.75,
            animation: `${slideUp} 1.5s ease-in-out`,
          }}
        >
          <Box width="100%">
            <Link to="/renta#listing">
              <CustomButton
                text="Ver Casas Disponibles"
                variant="contained"
                onClick={() => navigate("listado")}
                color="primary"
              />
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export { HeroSection };
