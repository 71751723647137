import { Button, Grid, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";

import recomiendaPrueba from '../../assets/prueba/recomiendaPrueba.jpg';

const Principal = ({ moverteFormulario }) => {
    // const moverteFormulario = () => {
    //     window.scroll(0,900);
    // }
    return(       
        <Container>
            <Box
                sx={{
                    // backgroundColor:'red',
                    height:700,
                }}
            >
                <Grid container>
                    <Grid item xs={12} sm={6} md={7}>
                        <Box
                            sx={{
                                backgroundColor:'yellow',
                                width:{ xs:'100%', sm:'100%', md:'100%', },
                                display:'flex',
                                alignItems:'center',
                                alignContent:'center',
                            }}
                        >
                            <Box
                                sx={{
                                    backgroundColor:'green',
                                    width:{ xs:'100%', sm:'100%', md:'100%', },
                                    display:'flex',
                                    alignItems:'center',
                                    alignContent:'center',
                                    m:'0 auto',
                                }}
                            >
                                <Box
                                    component='img'
                                    src={recomiendaPrueba}
                                    sx={{
                                        width:{ xs:'100%', sm:'100%', md:'100%', },
                                        m:'0 auto',
                                    }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={5}>
                        <Box
                            sx={{
                                // backgroundColor:'green',
                                width:{ xs:'100%', sm:'100%', md:'100%', },
                                height:{ xs:'100%', sm:'100%', md:'100%', },
                                display:'flex',
                                alignItems:'center',
                                alignContent:'center',
                            }}
                        >
                            <Box
                                sx={{
                                    // backgroundColor:'yellow',
                                    width:{ xs:'100%', sm:'100%', md:'100%', },
                                    m:'0 auto',
                                }}
                            >
                                <Box
                                    sx={{
                                        textAlign:'center',
                                        color:'#707070',
                                        mb:2
                                    }}
                                >
                                    <Typography variant="h4" sx={{ color:'#F5323F' }}>
                                        <b>Recomienda y Gana</b>
                                    </Typography>
                                    <Typography variant="h3" sx={{ color:'#F5323F' }}>
                                        <b>Hasta $5000</b>
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        // textAlign:'center',
                                        ml:2,
                                        mr:1,
                                        mb:3,
                                        color:'#707070',
                                    }}
                                >
                                    {/* <Typography variant="body2">
                                        La promoción tiene como objetivo invitar a todos nuestros clientes a que nos recomienden a un conocido, familiar o amigo que este buscando casa y a cambio de su recomendación, y en caso de que esta persona lleva a cabo una operación, se pagara un bono de $4,000 pesos en efectivo, mas posteriormente un bono de $1,000 por cada recomendado que firme la persona que el recomendó y compro casa inicialmente.
                                    </Typography> */}
                                    <Typography variant="body2">
                                        Si eres cliente Pecsa, recomienda a un familiar, amigo, conocido, a quien tu quieras y podrás ganar hasta $5000 pesos en efectivo.
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        // backgroundColor:'brown',
                                        width:'100%',
                                        textAlign:'center',
                                        pt:1,
                                        pb:0.5,
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        color="inherit"
                                        sx={{
                                            width:'60%',
                                            backgroundColor:'white',
                                            borderColor:'#F5323F',
                                            color:'#F5323F',
                                            borderWidth:2,
                                            borderRadius:'7px',
                                            '&:hover': {
                                                borderColor: '#db0a18',
                                                color: '#db0a18',
                                            },
                                            textTransform: 'none',
                                        }}
                                    >
                                        Términos y condiciones
                                    </Button>
                                </Box>
                                <Box
                                    sx={{
                                        // backgroundColor:'brown',
                                        width:'100%',
                                        textAlign:'center',
                                        pt:0.5,
                                        pb:1,
                                    }}
                                >
                                    <Button 
                                        variant="contained"
                                        sx={{
                                            width:'60%',
                                            backgroundColor:'#F5323F',
                                            // borderColor:'#F5323F',
                                            color:'white',
                                            borderWidth:2,
                                            borderRadius:'7px',
                                            '&:hover': {
                                                backgroundColor: '#db0a18',
                                                // color: 'white',
                                            },
                                            textTransform: 'none',
                                        }}
                                        onClick={moverteFormulario}
                                    >
                                        Recomienda a alguien aqui
                                    </Button>
                                </Box>  
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

export { Principal }