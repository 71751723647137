import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Box, Breadcrumbs, Container, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { Principal } from "./Principal";
import { OchoRazones } from "../../components/OchoRazones";
import { EspecificacionesModelo } from "../../components/EspecificacionesModelo";
import { PlantaArquitectonica } from "../../components/PlantaArquitectonica";
// import { Disponibilidad } from "../../components/Disponibilidad";
// import { ComparaMensualidades } from "../../components/Banners/ComparaMensualidades";
// import { ProcesosConstructivos } from "../../components/ProcesosConstructivos";
import { Garantias } from "../../components/Garantias";
// import { FeedbackModelo } from "../../components/FeedbackModelo";
// import { QueremosSaberTuOpinion } from "../../components/QueremosSaberTuOpinion";
// import { SlideAnuncios } from "../../components/SlideAnuncios";
import { CaracteristicasLote } from "../../components/CaracteristicasLote";
import { OrientcionDelSol } from "../../components/OrientacionDelSol";
import { UbicacionDentroDelFraccionamiento } from "../../components/UbicacionDentroDelFraccionamiento";
import { UbicacionDelFraccionamiento } from "../../components/UbicacionDelFraccionamiento";
import { ServiciosDisponibles } from "../../components/ServiciosDisponibles";
import { LugaresCercanos } from "../../components/LugaresCercanos";
import { BannerAsesores } from "../../components/BannerAsesores";
import { LotesCarrusel } from "../../components/LotesCarrusel";
import { ProcesosConstructivos } from "../../components/ProcesosConstructivos";
import { SimuladorDeCredito } from "../../components/SimuladorDeCredito";
import { ComparaMensualidades } from "../../components/Banners/ComparaMensualidades";
import { BannerOpciones } from "../../components/BannerOpciones";
import { Link } from "react-router-dom";
import { ComentariosLote } from "../../components/ComentariosLote";
import { MetaPixel } from "../../components/MetaPixel";


const Lote = ({ lote, lotes, simulador }) => {
    console.log(lote);
    const [loteBreadCrumb, setLoteBreadCrumb] = useState(null);

    // const [windowSize, setWindowSize] = useState(null);

    // const getWindowSize = () => {
    //     const {innerWidth, innerHeight} = window;
    //     console.log({innerWidth, innerHeight});
    //     return {innerWidth, innerHeight};
    // }
    useEffect(()=>{
        window.scroll(0,0);
        //setWindowSize(getWindowSize());
        setLoteBreadCrumb(window.innerWidth > 591 ? `Lote:${lote.numeroDeLote} - Mzn:${lote.manzana.nombre} - ${lote.manzana.etapa.fraccionamiento.nombre}`: `Lote:${lote.numeroDeLote} - Mzn:${lote.manzana.nombre}...`);
    }, [lote]);
    return(
        <div>
            <Helmet>
                <title>Casas Pecsa | Tienda - Modelo {lote.fachada.modelo.nombre}</title>
                <meta name="description" content="¿Tienes Alguna duda sobre tu casa? Nosotros Te Ayudamos. Separa Tu Casa En Línea. Puedes Ver Tu Casa 100% En Línea a Detalle y Conocer tu Fraccionamiento 100% En Línea." />
            </Helmet>
            <MetaPixel/>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, mt: { xs:7, sm:9, md: 11 } }}>
                <Container>
                    <Box
                        sx={{
                            // backgroundColor:'red',
                            pb:1,
                            pt:0.6,
                            pl:1,
                            color:'#707070',
                            
                        }}
                    >
                        <Breadcrumbs aria-label="breadcrumb">
                            {/* <Link underline="hover" color="inherit">
                                Inicio
                            </Link> */}
                            <Link to={'/'} style={{ color:'#707070', }}>
                                Inicio
                            </Link>
                            <Link to={'/tienda'} style={{ color:'#707070', }}>
                                Tienda
                            </Link>
                            <Typography sx={{ color:'#F5323F', }}>
                                { loteBreadCrumb }
                                {/* { `${lote.manzana.etapa.fraccionamiento.nombre}` } */}
                            </Typography>
                        </Breadcrumbs>
                    </Box>
                </Container>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, /*mt: { xs:7, sm:9, md:11 }*/ }}>
                <Principal lote={lote} masVisitado={
                                        lote.views.length === Math.max(...lotes.map((lote) => lote.views.length))
                                        ? true
                                        : false
                                    }/>
            </Box>
            {
                lote.fachada && (
                    lote.fachada.modelo && (
                        lote.fachada.modelo.plantas && (
                            <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                                <PlantaArquitectonica plantas= {lote.fachada.modelo.plantas}/>
                            </Box>
                        )
                    )                                                
                )
            } 
            {
                lote.caracteristicas && (
                    <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'#F4F4F4' }}>
                        <CaracteristicasLote caracteristicas={lote.caracteristicas[0]}/>
                    </Box>
                )
            }
              

            {/* <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                <Disponibilidad/>
            </Box> */}
            
            
            {
                lote.archivoOrientacionDelSol && (
                    <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                        <OrientcionDelSol lote={lote}/>
                    </Box>
                )
            }
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'#F4F4F4' }}>
                <UbicacionDelFraccionamiento fraccionamiento={lote.manzana.etapa.fraccionamiento}/>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                <UbicacionDentroDelFraccionamiento lote={lote}/>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'#F4F4F4' }}>
                <LugaresCercanos lugares={lote.manzana.etapa.fraccionamiento.lugaresCerca}/>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                <SimuladorDeCredito precio={lote.precio} simulador={simulador}/>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'#F4F4F4' }}>
                <ServiciosDisponibles fraccionamiento={lote.manzana.etapa.fraccionamiento}/>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                <ProcesosConstructivos modelo={lote.fachada.modelo}/>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'#F4F4F4' }}>
                <Garantias/>
            </Box>
            
            <Box sx={{ display: { xs: 'flex', md: 'flex' },  }}>
                <OchoRazones/>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'#F4F4F4' }}>
                <ComentariosLote lote={lote} allComentarios={lote.comentarios}/>
            </Box>
            {/* <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                <BannerAsesores/>
            </Box> */}
            {
                lotes && lote && (
                    <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                        <LotesCarrusel lotes={ lotes.filter((loteL) => { return loteL !== lote && ( loteL.fachada.modelo === lote.fachada.modelo || loteL.manzana.etapa.fraccionamiento.nombre === lote.manzana.etapa.fraccionamiento.nombre ) }) } />
                    </Box>
                )
            }
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'#F4F4F4' }}>
                <BannerOpciones />
            </Box>
        </div>
    );
}

export { Lote };