import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { Autocomplete, Avatar, Button, ButtonBase, Container, Divider, Grid, Stack, TextareaAutosize, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

const AddAmenidad = () => {
    let navigate = useNavigate();
    const location = useLocation();
    console.log(location.state);
    const parametros = useParams();
    const [fraccionamiento, setFraccionamiento] = useState(location.state.fraccionamiento);
    // const [etapas, setEtapas] = useState([]);
    // const [selectedEtapa, setSelectedEtapa] = useState(null);

    const [selectedImage, setSelectedImage] = useState([]);
    const [imageUrl, setImageUrl] = useState(null);

    const [storageImages, setStorageImages] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [imagesUrl, setImagesUrl] = useState([]);

    const add = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');

        data.set('idFraccionamiento', fraccionamiento.id);
        // data.set('folioCreateBy', folio);

        axios.post(`${process.env.REACT_APP_API_URL}/api/fraccionamiento/amenidad`, 
            // // {
            // //     nombre: data.get('nombre'),
            // //     idMpio: selectedCiudad.id,
            // //     logoImg: selectedImage,
            // //     fotosImg: storageImages,
            // //     folioCreateBy: folio,
            // // },
            data,
            {
              headers: { 
                Authorization: `Bearer ${token}`,   
                'Content-Type': 'multipart/form-data',               
                //ContentType: 'multipart/form-data'
              }              
        })
        .then((response) => {
            // handle success            
            console.log(response);
            console.log('success axio');
            navigate(-1, { replace: true });
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sabe');
        });
    }
    useEffect(()=>{
        if(selectedImage.length > 0){
            setImageUrl(URL.createObjectURL(selectedImage[0]));
        }
    },[selectedImage]);
    // useEffect(() => {
    //     const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        
    //     axios.get(`${process.env.REACT_APP_API_URL}/api/manzana/createview`, {
    //           headers: {
    //               Authorization: `Bearer ${token}`
    //           }
    //       })
    //       .then((response) => {
    //         // handle success
    //         console.log(response);
    //         //setEtapas(response.data.etapas);
    //       })
    //       .catch((error) => {
    //         // handle success
    //         console.log(error);
    //         // navigate('/usuarios/login');
    //       })
    //       .then(() => {
    //         // handle success
    //       });
    // }, []);
    return(
        <Container sx={{ backgroundColor:'white', mt:12, }}>
            <Box component="form" onSubmit={add}> 
                <h2>Nueva Amenidad</h2>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Stack spacing={3}>
                            <TextField id="outlined-basic" label="Nombre" variant="outlined" name="nombre" required/>
                            <TextareaAutosize
                                aria-label="minimum height"
                                minRows={8}
                                placeholder="Descripcion de la amenidad (Maximo 3000 caracteres)"
                                style={{ width: '99%' }}
                                name='descripcion'
                                //onChange={(e)=>{ setAmenidad({...amenidad, descripcion: e.target.value }) }}
                            />
                        </Stack>                        
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <label htmlFor="contained-button-file-fotoamenidad">                            
                            <input accept="image/*" id="contained-button-file-fotoamenidad" type="file" name="fotoamenidadImg" style={{ display: 'none' }} onChange={e => setSelectedImage([e.target.files[0]])}/>
                            <ButtonBase component="span" sx={{ width:'80%' }}>
                                {
                                     imageUrl ? selectedImage ? (
                                        <Box
                                            component='img'
                                            src={imageUrl}
                                            sx={{
                                                width:'100%',
                                                height: 450,
                                                borderRadius: '16px',
                                                boxShadow: 3
                                            }}
                                        />
                                     ) : (
                                        <Box
                                            component='div'
                                            sx={{
                                                width:'100%',
                                                height: 450,
                                                border: '2px dashed',
                                                borderRadius: '16px',
                                            }}
                                        >
                                            <AddAPhotoIcon/>
                                            <Typography variant="h5" gutterBottom component="div">
                                                Clic para agregar Fotografia
                                            </Typography>
                                        </Box>
                                     ) :(
                                        <Box
                                            component='div'
                                            sx={{
                                                width:'100%',
                                                height: 450,
                                                border: '2px dashed',
                                                borderRadius: '16px',
                                            }}
                                        >
                                            <AddAPhotoIcon/>
                                            <Typography variant="h5" gutterBottom component="div">
                                                Clic para agregar Fotografia
                                            </Typography>
                                        </Box>
                                     )
                                }
                            </ButtonBase>
                        </label>
                        
                        
                    </Grid>
                    {/* <Divider orientation="vertical" flexItem variant="middle"/> */}
                    {/* <Grid item lg={4}>
                        <Avatar sx={{ width: 200, height: 200 }}>
                            <AddAPhotoIcon />
                        </Avatar>
                    </Grid> */}
                </Grid>                                               
                <Button variant="contained" type="submit">Guardar</Button>
            </Box>
        </Container>
    );
}

export { AddAmenidad }