import { Box, Button } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const ButtonVerMas = ({ handleClick }) => {
    return(
        <Box
            sx={{
                // backgroundColor:'#5F9EA0',
                mb:0.5,
                textAlign:'center',                
            }}
        >
            <Button 
                variant="text"
                color="inherit" 
                endIcon={<ArrowForwardIcon sx={{ width:'0.8rem' }} />}
                sx={{
                    width:'100%',
                    fontSize:'0.8rem',
                    color:'#58A7C6',
                    textTransform:'none',
                }}
                onClick={handleClick}
            >
                Ver lote
            </Button>
        </Box>
    );
}

export { ButtonVerMas }