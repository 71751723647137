import { useEffect, useState } from "react";
import axios from "axios";
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation, Link, NavLink, Navigate } from "react-router-dom";

import { Autocomplete, Avatar, Button, Container, Divider, Grid, Input, Stack, TextField } from "@mui/material"
import { Box } from "@mui/system";
import UploadFileIcon from '@mui/icons-material/UploadFile';

const AddEtapa = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [fraccionamiento, setFraccionamiento] = useState(location.state.fraccionamiento);
    //console.log(location.state);

    const [ciudades, setCiudades] = useState([]);
    const [selectedCiudadNotaria, setSelectedCiudadNotaria] = useState(null);
    const [selectedCiudadRegistro, setSelectedCiudadRegistro] = useState(null);

    // const [fraccionamientos, setFraccionamientos] = useState([]);
    // const [selectedFraccionamiento, setSelectedFraccionamiento] = useState(null);

    const [empresas, setEmpresas] = useState([]);
    const [selectedEmpresa, setSelectedEmpresa] = useState(null);

    const add = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');
        // console.log('archivoLicenciaContruccion:::', data.get('archivoLicenciaContruccion'));
        // console.log('archivoFactibilidadAgua:::', data.get('archivoFactibilidadAgua'));
        // console.log('archivoFactibilidadLuz:::', data.get('archivoFactibilidadLuz'));
        // console.log('archivoEscrituras:::', data.get('archivoEscrituras'));
        // return;
        data.set('idFraccionamiento', fraccionamiento.id);
        data.set('idEmpresa', selectedEmpresa.id);
        data.set('ciudadNotaria', selectedCiudadNotaria.id);
        data.set('ciudadRegistro', selectedCiudadRegistro.id);
        data.set('folioCreateBy', folio);

        axios.post(`${process.env.REACT_APP_API_URL}/api/etapa/`, 
            // {
            //     nombre: data.get('nombre'),
            //     codigoPostal: data.get('codigoPostal'),
            //     idFraccionamiento: fraccionamiento.id,
            //     idEmpresa: selectedEmpresa.id,
            //     numeroLicencia: data.get('numeroLicencia'),
            //     licenciaOtorgadaPor: data.get('licenciaOtorgadaPor'),
            //     fechaOtorgamientoLicencia: data.get('fechaOtorgamientoLicencia'),
            //     // archivoLicenciaContruccion: 
            //     // archivoFactibilidadAgua: 
            //     // archivoFactibilidadLuz: 
            //     numeroEscritura: data.get('numeroEscritura'),
            //     notaria: data.get('notaria'),
            //     titularNotaria: data.get('titularNotaria'),
            //     ciudadNotaria: selectedCiudadNotaria.id,
            //     ciudadRegistro: selectedCiudadRegistro.id,
            //     numeroPredio: data.get('numeroPredio'),
            //     fechaEscrituracion: data.get('fechaEscrituracion'),
            //     // archivoEscrituras: 
            //     folioCreateBy: folio,
            // },
            data,
            {
              headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'multipart/form-data', 
              }              
        })
        .then((response) => {
            // handle success            
            console.log(response);
            console.log('success axio');            
            fraccionamiento.etapas.push(response.data);
            //navigate(`/cargas/inventario/${fraccionamiento.folio.toString()}`, {state:{fraccionamiento:fraccionamiento}});
            //navigate(-1, { replace: true });
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sabe');
        });
    }

    useEffect(() => {
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        
        axios.get(`${process.env.REACT_APP_API_URL}/api/etapa/createview`, {
              headers: {
                  Authorization: `Bearer ${token}`
              }
          })
          .then((response) => {
            // handle success
            console.log(response);
            setCiudades(response.data.ciudades);
            setEmpresas(response.data.empresas);
          })
          .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
          })
          .then(() => {
            // handle success
          });
    }, [setCiudades]);

    return(
        <Container sx={{ backgroundColor:'white' }}>
            <Box component="form" onSubmit={add}> 
                <h2>Nueva Etapa</h2>
                <Grid container spacing={2}>                    
                    <Grid item lg={12}>                    
                        <Stack spacing={3}>
                            <TextField id="outlined-basic" label="Nombre" variant="outlined" name="nombre" required/>
                            <TextField id="outlined-basic" label="Codigo Postal" variant="outlined" name="codigoPostal" />
                            {/* <Autocomplete
                                id="grouped-demo"
                                options={fraccionamientos}
                                getOptionLabel={(fraccionamiento) => fraccionamiento.nombre}
                                value={selectedFraccionamiento}
                                onChange={(event, newFraccionamiento)=>{setSelectedFraccionamiento(newFraccionamiento);}}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Fraccionamiento" name="fraccionamiento" />}
                            /> */}
                            <Autocomplete
                                id="grouped-demo"
                                options={empresas}
                                getOptionLabel={(empresa) => empresa.nombre}
                                value={selectedEmpresa}
                                onChange={(event, newEmpresa)=>{setSelectedEmpresa(newEmpresa);}}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Empresa" name="empresa" />}
                            />
                            <TextField id="outlined-basic" label="Numero de Licencia" variant="outlined" name="numeroLicencia" />
                            <TextField id="outlined-basic" label="Licencia Otorgada Por" variant="outlined" name="licenciaOtorgadaPor" />
                            <TextField id="outlined-basic" label="Fecha de Otorgamiento de Licencia" type="date" variant="outlined" name="fechaOtorgamientoLicencia" />
                            <label htmlFor="contained-button-file-licencia-de-construccion">
                                <Input accept="image/*,.pdf" id="contained-button-file-licencia-de-construccion" name="archivoLicenciaContruccionDoc" type="file" sx={{ display:'none' }} />
                                <Button variant="contained" component="span" endIcon={<UploadFileIcon />}>
                                    Licencia de Construcción  
                                </Button>
                            </label>
                            <label htmlFor="contained-button-file-factibilidad-de-agua">
                                <Input accept="image/*, .pdf" id="contained-button-file-factibilidad-de-agua" name="archivoFactibilidadAguaDoc" type="file" sx={{ display:'none' }} />
                                <Button variant="contained" component="span" endIcon={<UploadFileIcon />}>
                                    Factibilidad de Agua  
                                </Button>
                            </label>
                            <label htmlFor="contained-button-file-factibilidad-de-luz">
                                <Input accept="image/*, .pdf" id="contained-button-file-factibilidad-de-luz" name="archivoFactibilidadLuzDoc" type="file" sx={{ display:'none' }} />
                                <Button variant="contained" component="span" endIcon={<UploadFileIcon />}>
                                    Licencia de Luz  
                                </Button>
                            </label>
                            <TextField id="outlined-basic" label="Numero de Escritura" variant="outlined" name="numeroEscritura" />
                            <TextField id="outlined-basic" label="Notaria" variant="outlined" name="notaria" />
                            <TextField id="outlined-basic" label="Titular Notaria" variant="outlined" name="titularNotaria" />
                            <Autocomplete
                                id="grouped-demo"
                                options={ciudades}
                                groupBy={(ciudad) => ciudad.estado.nombre}
                                getOptionLabel={(ciudad) => ciudad.nombre}
                                value={selectedCiudadNotaria}
                                onChange={(event, newCiudad)=>{setSelectedCiudadNotaria(newCiudad);}}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Ciudad Notaria" name="ciudadNotaria" />}
                            />
                            <Autocomplete
                                id="grouped-demo"
                                options={ciudades}
                                groupBy={(ciudad) => ciudad.estado.nombre}
                                getOptionLabel={(ciudad) => ciudad.nombre}
                                value={selectedCiudadRegistro}
                                onChange={(event, newCiudad)=>{setSelectedCiudadRegistro(newCiudad);}}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Ciudad Registro" name="ciudadRegistro" />}
                            />
                            <TextField id="outlined-basic" label="Numero de Predio" variant="outlined" name="numeroPredio" />
                            <TextField id="outlined-basic" label="Fecha de Escrituracion" type="date" variant="outlined" name="fechaEscrituracion" />
                            <label htmlFor="contained-button-file-escrituras">
                                <Input accept="image/*, .pdf" id="contained-button-file-escrituras" name="archivoEscriturasDoc" type="file" sx={{ display:'none' }} />
                                <Button variant="contained" component="span" endIcon={<UploadFileIcon />}>
                                    Licencia de Luz  
                                </Button>
                            </label>
                        </Stack>                        
                    </Grid>
                </Grid>                                               
                <Button variant="contained" type="submit">Guardar</Button>
            </Box>
        </Container>
    );
}

export { AddEtapa }