import {
  Box,
  Container,
  Link,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import theme from "../../../App/theme";
import { CustomTypography } from "../../../components/CustomTypography";
import { MyAccordion } from "../../../components/MyAccordion";

const faqs = [
  {
    title: "¿Qué es Invierte en Casas Pecsa?",
    body: (
      <Box>
        Es una nueva funcion de{" "}
        <CustomTypography variant="textPrimaryBold" txtColor="primary">
          Casas Pecsa
        </CustomTypography>{" "}
        donde al separar tu casa tendras la opcion de ponerla en renta, nosotros
        nos encargaremos de promocionar tu casa en nuestro sitio web y llegar a
        las personas interesadas
      </Box>
    ),
  },
  {
    title: "¿Cómo empiezo?",
    body: (
      <Box>
        Ingresa <Link href="invierte/listado">aquí</Link>, donde
        podrás ver, comparar, simular entre todo nuestro listado de casas y
        selecciona la casa que más te guste, realiza la separación para poder
        empezar tu proceso de compra de casa, al finalizar tu casa estará
        disponible para rentar en nuestra plataforma.{" "}
      </Box>
    ),
  },
  {
    title: "¿Cómo pongo en renta mi nueva casa Pecsa?",
    body: (
      <Box>
        Al finalizar tu proceso de compra de casa se te habilitará una sección
        en{" "}
        <CustomTypography variant="textPrimaryBold" txtColor="primary">
          Mi Cuenta
        </CustomTypography>{" "}
        para que tú puedas elegir el precio de renta, alquilar o dejar de
        alquilar tu casa, así como un listado de personas interesadas en rentar
        tu casa.
      </Box>
    ),
  },
  {
    title:
      "¿Cómo puedo ponerme en contacto con las personas interesadas en rentar mi casa?",
    body: (
      <Box>
        En{" "}
        <CustomTypography variant="textPrimaryBold" txtColor="primary">
          Mi Cuenta
        </CustomTypography>{" "}
        tendrás un listado de las personas interesadas y podrás ponerte en
        contacto con ellas para llegar a un acuerdo y así rentar tu casa.
      </Box>
    ),
  },
  {
    title: "¿Qué ventajas tiene?",
    body: "Te ahorramos tiempo y esfuerzo en tomar fotos, promocionar tu casa, conseguir interesados, elegir precio de renta, tú solo te encargas de ponerte de acuerdo con las personas interesadas.",
  },
  {
    title:
      "¿Puedo cambiar el precio de renta después de haber publicado mi propiedad?",
    body: (
      <Box>
        Por supuesto, desde el portal de{" "}
        <CustomTypography variant="textPrimaryBold" txtColor="primary">
          Mi Cuenta
        </CustomTypography>{" "}
        puedes cambiar el precio de renta las veces que quieras.
      </Box>
    ),
  },
  {
    title: "¿Puedo dejar de publicar mi casa en renta?",
    body: (
      <Box>
        ¡Claro!, en{" "}
        <CustomTypography variant="textPrimaryBold" txtColor="primary">
          Mi Cuenta
        </CustomTypography>{" "}
        puedes habilitar y deshabilitar, mostrar tu casa en nuestro portal las
        veces que quieras.
      </Box>
    ),
  },
  {
    title: "¿Como ponerme en contacto con un asesor para mas informacion?",
    body: (
      <Box>
        <Box>Puede ser de las siguientes dos formas:</Box>
        <Box>
          <List dense={true}>
            <ListItem>
              <ListItemText
                primary={
                  <Box sx={{ fontFamily: "Montserrat" }}>
                    Puedes agendar una cita con un asesor, ya sea presencial o
                    virtual, <Link href="/citas">aquí</Link>.
                  </Box>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Box sx={{ fontFamily: "Montserrat" }}>
                    Envíanos un Whatsapp al{" "}
                    <Link
                      href="https://api.whatsapp.com/send?phone=528441630722&text=Hola!,%20me%20gustaria%20rentar%20una%20casa%20Pecsa"
                      target="_blank"
                    >
                      +52 844 163 0722
                    </Link>{" "}
                    o dando clic{" "}
                    <Link
                      href="https://api.whatsapp.com/send?phone=528441630722&text=Hola!,%20me%20gustaria%20rentar%20una%20casa%20Pecsa"
                      target="_blank"
                    >
                      aquí
                    </Link>
                    .
                  </Box>
                }
              />
            </ListItem>
          </List>
        </Box>
      </Box>
    ),
  },
  // {
  //     title:"¿Esto es una prueba?",
  //     body:( <Box>Entra a <Link href="http://localhost:3000/">casaspecsa.com</Link></Box> )
  // }
];
const FAQSection = () => {
  return (
    <Box
      component="section"
      sx={{
        backgroundColor: theme.palette.grey[100],
        py: "2.5rem",
        [theme.breakpoints.up("sm")]: {
          py: "5rem",
        },
      }}
    >
      <Container
        sx={{
          display: "flex",
          textAlign: "center",
          flexDirection: "column",
          // gap: 1
        }}
      >
        <Box>
          <CustomTypography variant="subheadingPrimary" txtColor="primary">
            Preguntas Frecuentes
          </CustomTypography>
        </Box>
        {faqs.map((faq) => (
          <MyAccordion title={faq.title} body={faq.body} />
        ))}
        {/* <MyAccordion title="¿Como comprar una casa Pecsa?" body="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500" />
                <MyAccordion title="¿Como puedo poner mi casa en renta?" body="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen." /> */}
      </Container>
    </Box>
  );
};

export { FAQSection };
