import { Box, Grid, Typography } from "@mui/material";
import { getFormatoMoneda } from "../../../../../functions/getFormatoMoneda";

const Income = ({ children }) => {
	return(
		<Box
			sx={{
				// backgroundColor:'#E0FFFF',
			}}
		>
			<Grid 
				container
				direction="row"
				justifyContent="flex-start"
				alignItems="flex-start"
				sx={{
					gap:2,
				}}
			>
				{/* <Grid item xs={12} sm={12} md={12}>
					<Box
						sx={{
							// backgroundColor:'#5F9EA0',
							mb:0.5,
						}}
					>
						<Typography sx={{ fontSize:'1rem' }}>
							<b>¿Cuanto ganaria por renta?</b>
						</Typography>
						<Typography sx={{ fontSize:'1rem' }}>
							Al mes 28 cada inversionista podria recibir una renta mensual por:
						</Typography>
						<Typography sx={{ fontSize:'1rem' }}>
							<b>$7,000</b>MXN
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={12} sm={12} md={12}>
					<Box
						sx={{
							// backgroundColor:'#5F9EA0',
							mb:0.5,
						}}
					>
						<Typography sx={{ fontSize:'1rem' }}>
							<b>Total renta mensual:</b>
						</Typography>
						<Typography sx={{ fontSize:'1rem' }}>
							<b>$10,000</b>MXN
						</Typography>
					</Box>
				</Grid> */}
				{ children }
			</Grid>
		</Box>
	);
}

export { Income }