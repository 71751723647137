import { Box, Container, Divider, Grid } from "@mui/material";
import { CustomTypography } from "../../../components/CustomTypography";
import theme from "../../../App/theme";
import { CustomButton } from "../../../components/CustomButton";
import { getFormatoMoneda } from "../../../functions/getFormatoMoneda";
import { useNavigate } from "react-router-dom";

const ContainerPropio = ({ title, children }) => {
  return (
    <Grid
      container
      sx={{
        // backgroundColor:'greenyellow',
        gap: 0.3,
      }}
    >
      <Grid item xs={12} sm={12} md={12}>
        <CustomTypography variant="textTertiaryBold">{title}</CustomTypography>
      </Grid>
      {children}
    </Grid>
  );
};
const ItemPropio = ({ type, principal, secondary, leyenda }) => {
  return (
    <Grid item xs={12} sm={12} md={12}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {type === "normal" && (
          <>
            <Grid item xs={5} sm={5} md={5}>
              <Box
                sx={{
                  // backgroundColor:'lavender',
                  width: "100%",
                  textAlign: "left",
                }}
              >
                <CustomTypography variant="textTertiary">
                  {principal}
                </CustomTypography>
              </Box>
            </Grid>
            <Grid item xs={5} sm={5} md={5}>
              <Box
                sx={{
                  // backgroundColor:'lavender',
                  width: "100%",
                  textAlign: "right",
                }}
              >
                <CustomTypography
                  variant="textTertiaryBold"
                  txtColor={secondary.color}
                >
                  {secondary &&
                    (secondary.currently ? (
                      <>
                        {getFormatoMoneda(secondary.price)}
                        <CustomTypography variant="textTertiaryLight">
                          {secondary.currently}
                        </CustomTypography>
                      </>
                    ) : (
                      <>{secondary}</>
                    ))}
                </CustomTypography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <CustomTypography variant="textTertiaryLight">
                {leyenda}
              </CustomTypography>
            </Grid>
          </>
        )}
        {type === "divider" && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <Divider />
            </Grid>
          </>
        )}
        {type === "libre" && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <CustomTypography variant="textTertiaryLight">
                {principal}
              </CustomTypography>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};
const Mensaje = ({ restanteDeInversionInicial, lote }) => {
  let navigate = useNavigate();
  const Falta = ({ restanteDeInversionInicial }) => {
    return (
      <Box
        sx={{
          // backgroundColor:'#DCFF00',
          width: "100%",
        }}
      >
        <Box
          sx={{
            // backgroundColor:'#F0FF91',
            width: "100%",
          }}
        >
          <CustomTypography variant="textPrimaryBold">
            Te faltan{" "}
            <CustomTypography
              variant="textPrimaryBold"
              txtColor={theme.palette.warning.light}
            >
              {" "}
              {getFormatoMoneda(restanteDeInversionInicial * -1)} MXN{" "}
            </CustomTypography>
            para el enganche del Modelo T-88.
          </CustomTypography>
        </Box>
        <Box
          sx={{
            // backgroundColor:'#99A35E',
            width: "100%",
            height: 85,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CustomButton
            text="Ver otras casas"
            variant="contained"
            color="primary"
            size="medium"
            onClick={() => navigate("/invierte/listado")}
          />
        </Box>
      </Box>
    );
  };
  const Fantastico = () => {
    return (
      <Box
        sx={{
          // backgroundColor:'#DCFF00',
          width: "100%",
        }}
      >
        <Box
          sx={{
            // backgroundColor:'#F0FF91',
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <CustomTypography
            variant="textPrimaryBold"
            txtColor={theme.palette.success.main}
          >
            ¡Fantástico!
          </CustomTypography>
          <CustomTypography variant="textPrimary">
            El monto de inversión inicial cubre el enganche
          </CustomTypography>
        </Box>
        <Box
          sx={{
            // backgroundColor:'#99A35E',
            width: "100%",
            height: 85,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CustomButton
            text="Quiero Invertir"
            variant="contained"
            color="primary"
            size="medium"
            onClick={() =>
              navigate(
                `/checkout/${lote.sku.replace(/ /g, "_")}?inversion=true`
              )
            }
          />
        </Box>
      </Box>
    );
  };
  if (restanteDeInversionInicial >= 0) {
    return <Fantastico />;
  }
  if (restanteDeInversionInicial < 0) {
    return <Falta restanteDeInversionInicial={restanteDeInversionInicial} />;
  }
};
const SimulationSection = ({ datosLote }) => {
  if (!datosLote) {
    return <h1>Cargando</h1>;
  }
  return (
    <Box>
      <Container
        maxWidth="sm"
        sx={{ p: { xs: "0 16px 0 16px", sm: 0, md: 0 } }}
      >
        <Box
          sx={{
            // backgroundColor:'pink',
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 1,
            pt: 3,
          }}
        >
          <Mensaje
            restanteDeInversionInicial={datosLote.restanteDeInversionInicial}
            lote={datosLote.lote}
          />
          <Box>
            <Grid container sx={{ gap: 2 }}>
              <Grid item xs={12} sm={12} md={12}>
                <ContainerPropio>
                  <ItemPropio
                    type="libre"
                    principal={
                      <CustomTypography variant="textSecondaryBold">
                        Datos de inversión en Modelo{" "}
                        {datosLote.lote.fachada.modelo.nombre}
                      </CustomTypography>
                    }
                  />
                </ContainerPropio>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <ContainerPropio title="¿Cuánto debes invertir?">
                  <ItemPropio
                    type="normal"
                    principal="Apartado:"
                    secondary={{ price: datosLote.apartado, currently: "MXN" }}
                  />
                  <ItemPropio
                    type="normal"
                    principal="Enganche:"
                    secondary={{ price: datosLote.enganche, currently: "MXN" }}
                  />
                  <ItemPropio
                    type="normal"
                    principal="Gastos:"
                    secondary={{ price: datosLote.gastos, currently: "MXN" }}
                  />
                  <ItemPropio type="divider" />
                  <ItemPropio
                    type="normal"
                    principal="Inversión minima:"
                    secondary={{
                      price: datosLote.inversionMinima,
                      currently: "MXN",
                    }}
                  />
                  <ItemPropio
                    type="normal"
                    principal="Inversión inicial:"
                    secondary={{
                      price: datosLote.inversionInicial,
                      currently: "MXN",
                      color: theme.palette.success.main,
                    }}
                  />
                  <ItemPropio type="divider" />
                  <ItemPropio
                    type="normal"
                    principal="Restante de inversión inicial:"
                    secondary={{
                      price: datosLote.restanteDeInversionInicial,
                      currently: "MXN",
                    }}
                  />
                  <ItemPropio
                    type="normal"
                    principal="Restante a pagar:"
                    secondary={{
                      price: datosLote.restanteAPagar,
                      currently: "MXN",
                    }}
                  />
                </ContainerPropio>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <ContainerPropio title="Tu ingreso por renta">
                  <ItemPropio
                    type="normal"
                    principal="Ingreso renta mensual:"
                    secondary={{
                      price: datosLote.ingresoRentaMensualAproximada,
                      currently: "MXN",
                    }}
                  />
                  <ItemPropio
                    type="normal"
                    principal="Costo de servicio:"
                    secondary={{
                      price: datosLote.costoServicio,
                      currently: "MXN",
                    }}
                  />
                  <ItemPropio type="divider" />
                  <ItemPropio
                    type="normal"
                    principal="Valor neto renta al mes:"
                    secondary={{
                      price: datosLote.rentaMensualAproximada,
                      currently: "MXN",
                    }}
                  />
                  <ItemPropio
                    type="normal"
                    principal="Valor neto renta anual:"
                    secondary={{
                      price: datosLote.rentaNetoAnual,
                      currently: "MXN",
                    }}
                  />
                </ContainerPropio>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <ContainerPropio title="¿La renta la pagaría el crédito?">
                  <ItemPropio
                    type="normal"
                    principal="Ingreso neto renta mensual:"
                    secondary={{
                      price: datosLote.rentaMensualAproximada,
                      currently: "MXN",
                    }}
                  />
                  <ItemPropio
                    type="normal"
                    principal="Pago mensual crédito hipotecario:"
                    secondary={{
                      price:
                        datosLote.mensualidadesCreditoHipotecarioAproximadas,
                      currently: "MXN",
                    }}
                  />
                  <ItemPropio type="divider" />
                  {/* <ItemPropio type="normal" principal="Te quedan al mes aproximado:" secondary={{ price:"+$536,210", currently:'MXN' }} /> */}
                  {datosLote.restanteAlMes <= 0 && (
                    <ItemPropio
                      type="normal"
                      principal="Pago mensual neto crédito hipotecario:"
                      secondary={{
                        price: datosLote.restanteAlMes * -1,
                        currently: "MXN",
                        color: theme.palette.success.main,
                      }}
                    />
                  )}
                  {datosLote.restanteAlMes > 0 && (
                    <ItemPropio
                      type="normal"
                      principal="Te quedan al mes aproximado:"
                      secondary={{
                        price: datosLote.restanteAlMes,
                        currently: "MXN",
                        color: theme.palette.success.main,
                      }}
                    />
                  )}
                </ContainerPropio>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <ContainerPropio>
                  <ItemPropio
                    type="libre"
                    principal={
                      <Box
                        sx={{
                          backgroundColor: theme.palette.success.light,
                          border: 2,
                          borderColor: theme.palette.success.main,
                          width: "99%",
                          height: "50px",
                          borderRadius: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: "85%",
                          }}
                        >
                          <CustomTypography
                            variant="textTertiaryBold"
                            txtColor={theme.palette.text.primary}
                          >
                            {datosLote.restanteAlMes <= 0 &&
                              `La renta cubre una parte del crédito hipotecario y en total pagarias ${getFormatoMoneda(
                                datosLote.restanteAlMes * -1
                              )} MXN al mes`}
                            {datosLote.restanteAlMes > 0 &&
                              (datosLote.credito <= 0
                                ? `Tu inversion te daria aproximadamente ${getFormatoMoneda(
                                    datosLote.restanteAlMes
                                  )} MXN al mes`
                                : `La renta pagaría el crédito hipotecario y te sobran aproximadamente ${getFormatoMoneda(
                                    datosLote.restanteAlMes
                                  )} MXN al mes`)}
                          </CustomTypography>
                        </Box>
                      </Box>
                    }
                  />
                  <ItemPropio
                    type="libre"
                    principal="Esta es una simulación de un crédito a 20 años no representa valores reales de la cuota del crédito."
                  />
                </ContainerPropio>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export { SimulationSection };
