import { Box, Button, Card, CardContent, CardMedia, Divider, FormControl, Grid, InputLabel, List, ListItem, ListItemText, MenuItem, Select, Step, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";

import foto360 from '../../assets/prueba/GS__4166.JPG';
import React, { useEffect, useState } from "react";
import axios from "axios";

import * as PANOLENS from 'panolens';

import { PortaPano } from "./PortaPano";
// import { PortaPano2 } from "./PortaPano2";

const Prueba360 = ({ lote }) => {
    //const fotos = [{ id:1, url:'https://pchen66.github.io/Panolens/examples/asset/textures/equirectangular/tunnel.jpg' }]

    //console.log('Realizar checkout',lote);
    const [clientSecret, setClientSecret] = useState(null);

    const addCliente = () => {
        //console.log('guardarClienate', fotos[0]);
        // axios.post(`${process.env.REACT_APP_API_URL}/api/venta/checkClient/`,
        // datosPersonales
        // // {
        // //     headers: { 
        // //     Authorization: `Bearer ${token}`,
        // //     }              
        // // }
        // )
        // .then((response) => {
        //     // handle success
        //     console.log(response);
        //     setNewCliente(response.data);
        // })
        // .catch((error) => {
        //     // handle success
        //     console.log(error);
        //     // navigation(-1);
        //     //setLoteDisponible(false);
        // })
        // .then(() => {
        //     // handle success
        //     //console.log('sabe');
        // });
    }
    // const Mostrar = () => {
    //     const porta = document.getElementById("porta");
    //     const panorama = new PANOLENS.ImagePanorama(foto360);
    //     const viewer = new PANOLENS.Viewer({ 
    //         // output: "console" 
    //         container:porta
    //     });

    //     viewer.add(panorama);
    //     return(<div id="porta"></div>);
    // }
    // // const Mostrar = () => {
    // //     return(<div></div>);
    // // }

    // // useEffect(()=>{
    // //     const panorama = new PANOLENS.ImagePanorama(foto360);
    // //     const viewer = new PANOLENS.Viewer({ 
    // //         // output: "console" 
    // //         container:<Mostrar/>
    // //     });

    // //     viewer.add(panorama);
    // // },[]);
    // // return(
    // //     <Container sx={{ mt:{ xs:9, sm:9, md:12, } }}>
    // //         <Box
    // //             sx={{
    // //                 height:800,
    // //                 pt:1
    // //             }}
    // //         >
    // //             <Mostrar/>
    // //         </Box>
    // //     </Container>
    // // );
    return(
        <Container sx={{ mt:{ xs:9, sm:9, md:12, } }}>
            <Box
                sx={{
                    backgroundColor:'red',
                    height:1000,
                    pt:1
                }}
            >
                <Box
                    sx={{
                        backgroundColor:'green',
                        width:'80%',
                        height:500,
                        m:'0 auto'
                    }}
                >
                    <PortaPano  />
                </Box>
                <h1>hahaha</h1>
                {/* <Box
                    component='img'
                    src='https://casaspecsa-bucket.s3.us-east-2.amazonaws.com/pruebaMapa/GS__4166.JPG'
                    sx={{
                        width:300,
                        height:100,
                    }}
                /> */}
                {/* <Box
                    component='img'
                    src='https://bucketdepruebas.s3.us-east-2.amazonaws.com/fracionamiento/master_uno_HOLA.svg'
                    sx={{
                        width:550,
                        //height:100,
                    }}
                />
                <Box
                    component='img'
                    src='https://d2kg7bu049hzg.cloudfront.net/fracionamiento/master_uno_HOLA.svg'
                    sx={{
                        width:550,
                        //height:100,
                    }}
                /> */}
                
            </Box>
        </Container>
    );
}

export { Prueba360 }