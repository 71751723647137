import React, {useState,useEffect} from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation, useSearchParams, Link, NavLink, Navigate } from "react-router-dom";
import { Container } from "@mui/system";
import { MenuFraccionamientos } from "../../components/MenuFraccionamientos";
import { Skeleton } from '@mui/material';
import { MenuModelos } from '../../components/MenuModelos';
import { MenuAsesores } from '../../components/MenuAsesores';

const ListaAsesores = () => {
    let navigate = useNavigate();
    const location = useLocation();
    //console.log(location.state.inventario);

    //const [inventario, setInventario] = useState(location.state.inventario);
    const [asesores, setAsesores] = useState([]);

    useEffect(() => {
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
          
        axios.get(`${process.env.REACT_APP_API_URL}/api/asesores/get-all`, {
              headers: {
                  Authorization: `Bearer ${token}`
              }
        })
        .then((response) => {
            // handle success
            console.log(response);
            setAsesores(response.data) 
            console.log('se valido Inventario');
        })
        .catch((error) => {
            // handle success
            console.log(error);
            navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sae');
        });
    }, [setAsesores]);

    return(
        <div>
            <Container>
                Asesores
                {
                    !asesores ? 
                    (<Skeleton variant="rectangular" width={210} height={118} />) : 
                    (<MenuAsesores asesores={asesores} />)
                }                
            </Container>
        </div>
    );
}

export { ListaAsesores }