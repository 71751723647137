import axios from "axios";

const realizarSeparacion = (transaccion) => {
    const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
    axios.post(`${process.env.REACT_APP_API_URL}/api/venta/update-separacion`,
    {
        id: transaccion.id,
        idCliente: transaccion.idCliente,
        idLote: transaccion.idLote,
        inversion: transaccion.separado.inversion,
        transaccion:{
            idEstatus: 2,
        },
        lote:{
            idEstatus: 2,
            disponible: false,
        },
        cliente:{
            idPerfil:  3
        },
    },
    {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then((response)=>{
        console.log(response.data);
    })
    .catch((error)=>{

    });
}

const cancelarSeparacion = (transaccion) => {
    const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
    axios.post(`${process.env.REACT_APP_API_URL}/api/venta/update-separacion`,    
    {
        id: transaccion.id,
        idCliente: transaccion.idCliente,
        idLote: transaccion.idLote,
        inversion: transaccion.separado.inversion,
        transaccion:{
            idEstatus: 3,
        },
        lote:{
            idEstatus: 1,
            disponible: true,
        },
        cliente:{
            idPerfil:  2
        },
    },
    {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then((response)=>{
        console.log(response.data);
    })
    .catch((error)=>{

    });
}

const tramitarSeparacion = (transaccion) => {
    const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
    axios.post(`${process.env.REACT_APP_API_URL}/api/venta/update-separacion`,
    {
        id: transaccion.id,
        idCliente: transaccion.idCliente,
        idLote: transaccion.idLote,
        inversion: transaccion.separado.inversion,
        transaccion:{
            idEstatus: 4,
        },
        lote:{
            idEstatus: 2,
            disponible: false,
        },
        cliente:{
            idPerfil:  4
        },
    },
    {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then((response)=>{
        console.log(response.data);
    })
    .catch((error)=>{

    });
}

const concluirSeparacion = (transaccion) => {
    const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
    axios.post(`${process.env.REACT_APP_API_URL}/api/venta/update-separacion`,
    {
        id: transaccion.id,
        idCliente: transaccion.idCliente,
        idLote: transaccion.idLote,
        inversion: transaccion.separado.inversion,
        transaccion:{
            idEstatus: 5,
        },
        lote:{
            idEstatus: 3,
            disponible: false,
        },
        cliente:{
            idPerfil:  5
        },
    },
    {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then((response)=>{
        console.log(response.data);
    })
    .catch((error)=>{

    });
}

const updateInversion = (id, estatus) => {
    const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
    axios.post(`${process.env.REACT_APP_API_URL}/api/venta/update-estatus-inversion/${id}`,    
    {
        inversion: estatus,
    },
    {
        headers: {
            Authorization: `Bearer ${token}`
        }
    },)
    .then((response)=>{
        console.log(response.data);
    })
    .catch((error)=>{

    });
}
const updateInversionCliente = (id, estatus, handleSuccess) => {
    const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
    axios.post(`${process.env.REACT_APP_API_URL}/api/venta/update-estatus-inversion-cliente/${id}`,    
    {
        inversion: estatus,
    },
    {
        headers: {
            Authorization: `Bearer ${token}`
        }
    },)
    .then((response)=>{
        console.log(response.data);
        handleSuccess(response.data)
    })
    .catch((error)=>{

    });
}

export { realizarSeparacion, cancelarSeparacion, tramitarSeparacion, concluirSeparacion, updateInversion, updateInversionCliente }