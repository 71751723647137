import * as React from "react";
import { useRef, useEffect } from "react";
import { Box, height } from "@mui/system";
import Container from '@mui/material/Container';

import { LotesCarruselItem } from "./LotesCarruselItem";

//import Carousel from "react-multi-carousel";
//import './styles.css';
import { Button, Grid, Stack, Fab } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { motion } from "framer-motion";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';




const LotesCarrusel = ({ lotes }) => {
  // console.log('carruselLotes', lotes);
  const constraintsRef = useRef(null);
    const containerRef = useRef(null);

    const constraintsRefMovil = useRef(null);
    const containerRefMovil = useRef(null);

    //const Contenedor = containerRef.current.clientWidth == null || containerRef.current.clientWidth == undefined ? 0 : containerRef.current.clientWidth;
    // const DivVisible = constraintsRef.current.clientWidth;
    // const Diferencia = Contenedor - DivVisible;

    const [Xside, setXside] = React.useState(0);
    const [XIside, setXIside] = React.useState(0);

    const [constraintsWidth, setConstraintsWidth] = React.useState(0);
    const [containerWidth, setContainerWidth] = React.useState(0);

    const [distancia, setDistancia] = React.useState(0);

    const atras = (Xside) => {
      // console.log(XIside);
      // console.log((XIside + 395.96));
      // setXside(Xside+395.96);
      // setXIside((XIside - 395.96) < constraintsWidth ? constraintsWidth : (XIside - 395.96))
      setXside(Xside + distancia);
      setXIside((XIside - distancia) < constraintsWidth ? constraintsWidth : (XIside - distancia))
      // console.log(XIside);
      // console.log(constraintsRef.current.clientWidth);
      // console.log(containerRef.current.clientWidth);
      
    };
    const adelante = (Xside) => {
      // console.log(XIside);
      // console.log((XIside - 395.96));
      // setXside(Xside-395.96);      
      // setXIside((XIside + 395.96) > containerWidth ? containerWidth : (XIside + 395.96))
      setXside(Xside - distancia);      
      setXIside((XIside + distancia) > containerWidth ? containerWidth : (XIside + distancia))
      // console.log(XIside);
      // console.log(containerRef.current.clientWidth);
    };
    
    const checarXIside = (Xside) => {
       
    }

    useEffect(() => {
      setXIside(constraintsRef.current.clientWidth);
      setConstraintsWidth(constraintsRef.current.clientWidth)
      setContainerWidth(containerRef.current.clientWidth)
      setDistancia((constraintsRef.current.clientWidth / 3))
    }, [setXIside, setConstraintsWidth, setContainerWidth]);
    return(
        <Container sx={{ backgroundColor:'white' }}>
            <Box sx={{ textAlign:'left', marginTop:2, color: '#F5323F' }}>
              <h1>TE PUEDE INTERESAR</h1>
            </Box>
                    
            <Box
              component={motion.div}
              ref={constraintsRef}
              sx={{
                backgroundColor:'white', 
                display: { xs: 'none', md: 'flex' },
                placeContent: 'start',
                placeItems: 'center',
                overflow: 'hidden',
                position:'relative',
                marginTop:2
              }}
            >
              <Stack
                ref={containerRef}
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                component={motion.div}
                animate={{ x:Xside }}

              >
                {
                  lotes.map((lote) => (
                    <Box
                      component={motion.div}
                      sx={{ 
                        // width: '375px'
                        // width: '185px'
                        width:{ xs: '185px', sm:'185px', md:'360px',  },
                        ml:1.5,
                        mr:1.5,
                        
                      }}
                      
                    >
                      <LotesCarruselItem lote={lote}/>  
                    </Box>
                  ))
                }
                
                               
              </Stack>
              {
                XIside !== constraintsWidth && (
                  <Box 
                component="Button" 
                sx={{ 
                  pt: 3, pb:3, pl:0, pr:0,
                  backgroundColor:'white', 
                  border:'none',
                  color:'red',
                  zIndex:1, 
                  position:'absolute',
                  left:0,
                }}
                variant="contained"
                onClick={()=> atras(Xside)}
              >
                  <ChevronLeftIcon />
                </Box>
                )
              }
              {
                XIside !== containerWidth && (
                  <Box 
                component="Button" 
                sx={{ 
                  pt: 3, pb:3, pl:0, pr:0,
                  backgroundColor:'white', 
                  border:'none',
                  color:'red',
                  zIndex:1, 
                  position:'absolute',
                  right:0,
                }}
                variant="contained"
                onClick={()=> adelante(Xside)}
              >
                  <ChevronRightIcon />
                </Box>
                )
              }
                
            </Box>

            <Box
              component={motion.div}
              ref={constraintsRefMovil}
              sx={{
                backgroundColor:'white', 
                display: { xs: 'flex', md: 'none' },
                placeContent: 'start',
                placeItems: 'center',
                overflow: 'hidden',
                position:'relative',
                marginTop:2
              }}
            >
              <Stack
                ref={containerRefMovil}
                direction="row"
                justifyContent="center"
                alignItems="center"
                //spacing={2}
                component={motion.div}
                animate={{ x:Xside }}
                drag='x' 
                dragConstraints={constraintsRefMovil}
                sx={{ pr:4, }}
              >
                {
                  lotes.map((lote) => (
                    <Box
                      component={motion.div}
                      sx={{ 
                        // width: '375px'
                        // width: '185px'
                        width:{ xs: '250px', sm:'285px', md:'375px', lg:'375px' },
                        ml:1.4,
                        mr:1.4,
                      }}
                      
                    >
                      <LotesCarruselItem lote={lote}/>  
                    </Box>
                  ))
                }
                

              </Stack>                
            </Box>
           
                

        </Container>
    );
};

export { LotesCarrusel };