import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CustomTypography } from "../../CustomTypography";
import { CustomButton } from "../../CustomButton";

const CardContactInvestment = () => {
  let navigate = useNavigate();

  const reedirigirAChatWhatsapp = () => {
    window.open(
      `https://api.whatsapp.com/send?phone=528441630722&text=Hola!,%20me%20gustaria%20invertir,%20quiero%20mas%20informaci%C3%B3n`
    );
    // window.location.href = 'https://api.whatsapp.com/send?phone=528443492981&text=mensajedeprueba';
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "12px",
        boxShadow: { xs: 0, md: "0px 10px 15px -3px rgba(0,0,0,0.1)" },
        border: { xs: "1px solid #EEEEEE", md: "1px solid #f5f5f5" },
        pt: 2,
        pb: 3,
      }}
    >
      <Box
        sx={{
          width: { xs: "95%", md: "90%" },
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <CustomTypography variant="textTertiary">
          Si quieres mas información o conocer una de nuestras casas, agenda una
          cita con un asesor.
        </CustomTypography>
        <CustomButton
          text="Agendar cita"
          variant="contained"
          color="primary"
          // txtColor="green"
          size="small"
          onClick={() => navigate("/citas")}
        />
        <CustomButton
          text="Chatear por WhatsApp"
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => reedirigirAChatWhatsapp()}
        />
      </Box>
    </Box>
  );
};

export { CardContactInvestment };
