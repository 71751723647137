import { Box, Container, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { InputButtonCombo } from "../../../components/Investment/InputButtonCombo";
import { CustomTypography } from "../../../components/CustomTypography";
import theme from "../../../App/theme";

const InvestmentInputContainer = ({
  inversionInicial,
  getInversionInicial,
  getShowMessage,
  items,
}) => {
  const [nuevosItems, setNuevosItems] = useState(items);
  let navigate = useNavigate();

  useEffect(() => {
    setNuevosItems(
      items.filter((item) => item.inversionMinima <= inversionInicial)
    );
  }, [inversionInicial]);

  useEffect(() => {
    if (inversionInicial) {
      if (nuevosItems.length <= 0) {
        getShowMessage(true);
      } else {
        getShowMessage(false);
      }
    }
  }, [nuevosItems]);

  return (
    <InputButtonCombo
      variantLabel="textPrimary"
      label="¿Cuánto deseas invertir inicialmente en tu nueva propiedad?"
      buttonText={
        nuevosItems.length <= 0
          ? "Ingresa una Cantidad"
          : `Ver ${nuevosItems.length} Casas`
      }
      handleChange={getInversionInicial}
      handleClick={() =>
        navigate(`listado?inversionInicial=${inversionInicial}`)
      }
      disabledButton={nuevosItems.length <= 0 ? true : false}
      columnLayout="true"
      variantButton="outlined"
    />
  );
};

const InvestmentMessage = ({ showMessage }) => {
  if (!showMessage) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.warning.light,
        border: 2,
        borderColor: theme.palette.warning.main,
        width: "100%",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        py: "15px",
      }}
    >
      <Container
        sx={{
          display: "flex",
          textAlign: "center",
          flexDirection: "column",
          gap: 3.75,
        }}
      >
        <Box>
          <CustomTypography variant="textPrimary">
            La cantidad que has ingresado no es suficiente para apartar tu casa
            ideal. Te invitamos a ingresar un monto mayor para asegurar tu
            inversión.
          </CustomTypography>
        </Box>
      </Container>
    </Box>
  );
};

const SimulatorSection = ({ inversionInicial, getInversionInicial, items }) => {
  const [showMessage, setShowMessage] = useState(false);

  const getShowMessage = (newValue) => {
    setShowMessage(newValue);
  };

  return (
    <Box
      component="section"
      sx={{
        backgroundColor: theme.palette.grey[100],
        py: "2.5rem",
        [theme.breakpoints.up("sm")]: {
          py: "5rem",
        },
      }}
    >
      <Container
        sx={{
          display: "flex",
          textAlign: "center",
          flexDirection: "column",
          gap: 3.75,
        }}
      >
        <CustomTypography variant="subheadingPrimary" txtColor="primary">
          Simula tu Inversión
        </CustomTypography>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 3.75,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3.75,
              alignContent: "flex-end",
              alignItems: "flex-end",
              justifyContent: "center",
              maxWidth: { xs: "100%", md: "45%" },
            }}
          >
            <Box>
              <CustomTypography
                variant="subheadingSecondary"
                txtColor={theme.palette.common.black}
              >
                <CustomTypography
                  variant="subheadingSecondaryBold"
                  txtColor="primary"
                >
                  Invierte
                </CustomTypography>{" "}
                fácilmente y gana más del 10% de plusvalía más rentas.
              </CustomTypography>
            </Box>

            <Box>
              <CustomTypography
                variant="textPrimary"
                txtColor={theme.palette.common.black}
              >
                Nosotros te ayudamos a invertir en alguno de nuestros
                desarrollos para que{" "}
                <CustomTypography variant="textPrimaryBold">
                  crezcas tu patrimonio.
                </CustomTypography>
              </CustomTypography>
            </Box>
          </Box>

          <Box
            sx={{
              p: { xs: "30px 10px", md: "30px 20px" },
              display: "flex",
              flexDirection: "column",
              gap: 2.5,
              bgcolor: theme.palette.common.white,
              borderRadius: "15px",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.35)",
            }}
          >
            <Box>
              <CustomTypography variant="subheadingSecondaryBold">
                Descubre tu próxima inversión:
              </CustomTypography>
            </Box>

            <Box>
              <InvestmentInputContainer
                inversionInicial={inversionInicial}
                getInversionInicial={getInversionInicial}
                items={items}
                getShowMessage={getShowMessage}
              />
            </Box>

            <InvestmentMessage showMessage={showMessage} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export { SimulatorSection };
