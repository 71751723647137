import PropTypes from 'prop-types';
import { Avatar, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Collapse, Divider, Grid, IconButton, ImageList, ImageListItem, List, ListItem, ListItemAvatar, ListItemText, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { InlineWidget } from "react-calendly";
import { AcercaDeMiAsesores } from "./AcercaDeMiAsesores";
import { FotosAsesores } from "./FotosAsesores";
import { OpinionesAsesores } from "./OpinionesAsesores";
import { PublicacionesAsesores } from "./PublicacionesAsesores";
import { CalendarioDesktop } from '../Calendario/CalendarioDesktop';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {
                value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )
            }
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

const sxTab = {
    fontWeight:'bold',
    '&:hover': {
        color:'#F5323F',
    },
    '&.Mui-selected': {
        color:'#F5323F',
    },
}
const Body = ({ asesor }) => {    
    const [value, setValue] = useState(3);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return(
        <Box
            sx={{
                // backgroundColor:'green',
                width:'100%',
                minHeight:2500,
                display:{xs:'block', sm:'flex', md:'flex',},
            }}
        >
            <Box
                sx={{
                    // backgroundColor:'yellow',
                    width:{ xs:'100%', sm:'70%', md:'70%'},
                    minHeight:800,
                }}
            >
                <Box
                    sx={{ 
                        backgroundColor:'white',
                        maxWidth: { xs: '100%', sm: '100%' }, 
                        // bgcolor: 'background.paper',
                        position:'sticky',
                        top: { xs:56, sm:78, md: 87, },
                        zIndex:1,
                    }}
                >
                    <Tabs
                        value={value} 
                        onChange={handleChange} 
                        aria-label="basic tabs example"
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        sx={{
                            '& .MuiTabs-indicator': {
                              backgroundColor: '#F5323F',
                              color:'#F5323F'
                            },
                        }}
                    >
                        <Tab 
                            label="Publicaciones" 
                            {...a11yProps(0)}
                            sx={{ ...sxTab }}
                        />
                        <Tab 
                            label="Acerca de mi" 
                            {...a11yProps(3)}
                            sx={{ ...sxTab }}
                        />
                        <Tab 
                            label="Fotos" 
                            {...a11yProps(1)}
                            sx={{ ...sxTab }}
                        />
                        {/* <Tab label="Videos" {...a11yProps(2)} /> */}
                        <Tab 
                            label="Opiniones" 
                            {...a11yProps(4)}
                            sx={{ ...sxTab }}
                        />
                        {/* <Tab label="Contacto" {...a11yProps(5)} /> */}
                    </Tabs>
                </Box>
                <Box>
                    <TabPanel value={value} index={0}>
                        <PublicacionesAsesores posts={asesor.posts} asesor={asesor}/>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <AcercaDeMiAsesores asesor={asesor} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <FotosAsesores posts={asesor.posts}/>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <OpinionesAsesores opiniones={asesor.opiniones}/>
                    </TabPanel>
                </Box>
            </Box>
            <CalendarioDesktop asesor={asesor}/>
        </Box>
    );
}

export { Body }