import { AppBar, Box, Container, Dialog, Grid, IconButton, Slide, Toolbar } from "@mui/material";
import { forwardRef, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { CustomTypography } from "../../../CustomTypography";
import { PhotosViewer } from "./PhotosViewer";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const GalleryPhotos = ({ fotos, open, handleOpen }) => {
    const [openPhotosViewer, setOpenPhotosViewer] = useState(false);
    const [indexImage, setIndexImage] = useState(0);
    const handleOpenPhotosViewer = (index) => {
        setIndexImage(index);
        setOpenPhotosViewer(!openPhotosViewer);
    }
    const handleClosePhotosViewer = () => {
        setOpenPhotosViewer(false);
    }
    const Foto = ({ foto, handleClick }) => {
        return(
            <Box
                sx={{
                    // backgroundColor:'yellowgreen',
                    width:'100%',
                    height:{ xs: 150, md:300 },
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    overflow:'hidden',
                }}
            >
                <Box
                    component="img"
                    src={foto.url}
                    sx={{
                        minWidth:'100%',
                        maxWidth:'120%',
                        minHeight:'100%',
                        maxHeight:{ xs:'180%', md:'120%'},
                        cursor:'pointer',
                    }}
                    onClick={handleClick}
                />
            </Box>
        );
    }

    return(
        <Dialog
            fullScreen
            open={open}
            onClose={handleOpen}
            TransitionComponent={Transition}
        >
            <AppBar color="transparent" sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleOpen}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <CustomTypography variant="textPrimary">
                        Volver
                    </CustomTypography>
                    {/* <Button autoFocus color="inherit" onClick={handleClose}>
                        save
                    </Button> */}
                </Toolbar>
            </AppBar>
            <Box
                sx={{
                    mt:2,
                }}
            >
                <Container>
                    <Grid container spacing={1}>
                        {
                            fotos.map((foto, index) => {
                                if(index % 3 === 0){
                                    return(
                                        <Grid item xs={12} sm={4} md={4}>
                                            <Foto foto={foto} handleClick={()=>handleOpenPhotosViewer(index)}/>
                                        </Grid>
                                    )
                                }else{
                                    return(
                                        <Grid item xs={6} sm={4} md={4}>
                                            <Foto foto={foto} handleClick={()=>handleOpenPhotosViewer(index)}/>
                                        </Grid>
                                    )
                                }
                            })
                        }
                    </Grid>
                </Container>
            </Box>
            <PhotosViewer fotos={fotos} indexImage={indexImage} open={openPhotosViewer} handleClose={handleClosePhotosViewer}/>
        </Dialog>
    );
}

export { GalleryPhotos }