import { Box, Stack, Typography } from "@mui/material";

const LugarCercano = ({lugar}) => {
    
    return(
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            sx={{
                color:'#707070',
            }}
        >
            <Box
                sx={{
                    width: { xs: '100%', sm: '100%', md: '100%' },
                    height: { xs: 150, sm: 150, md: 200 },                    
                    display:'flex',
                    alignItems:'center'
                }}
            >
                <Box
                    component="img"
                    src={lugar.foto}
                    sx={{
                        borderRadius:'16px',
                        boxShadow: '0px 5px 12px 0px rgb(0, 0, 0, 0.19)',
                        m:'0 auto',
                        width:'100%',
                        maxHeight:{ xs: 150, sm: 150, md: 200 },
                        minHeight:{ xs: 150, sm: 150, md: 200 },
                    }}
                />
            </Box>
            <Box
                sx={{
                    textAlign:'left'
                }}
            >
                <Typography variant="p" component="div" sx={{ ml:1, mr:1, mb:1, mt:1, height: { xs:'55px', sm:'35px', md:'35px' } }}>
                    <b>{ lugar.nombre }</b>
                </Typography>
                <Typography variant="p" component="div" sx={{ ml: { xs: 1, sm: 1, md: 1 }, mr: { xs: 1, sm: 2, md: 6 }, fontSize: { xs: 11, sm: 11, md: 14 } }}>
                    { `${lugar.distanciaMin} min (${lugar.distanciaKM} km)` }
                </Typography>
                <Typography variant="P" component="div" sx={{ mb:1, ml: { xs: 1, sm: 1, md: 1 }, mr: { xs: 1, sm: 2, md: 6 }, fontSize: { xs: 11, sm: 11, md: 11 } }}>
                    { lugar.tipo }
                </Typography>
                <Typography variant="P" component="div" sx={{ mb:1, ml: { xs: 1, sm: 1, md: 1 }, mr: { xs: 1, sm: 2, md: 6 }, fontSize: { xs: 8, sm: 8, md: 8 } }}>
                    *Imagen demostrativa
                </Typography>
            </Box>
        </Stack>
    );
}

export { LugarCercano }