import { Fab, Popover, Typography } from "@mui/material";
import { Box } from "@mui/system";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useState } from "react";
import { useLocation } from "react-router-dom";

const WhatsappFAB = () => {
    let location = useLocation();
    // console.log('soy el boton',location);

    // const [anchorEl, setAnchorEl] = useState(null);

    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };

    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    // const open = Boolean(anchorEl);
    // const id = open ? 'simple-popover' : undefined;
    const blackList = ['/pruebaPDF', /*'agendar-cita',*/ 'asesores', 'tu-casa-en-mexico', 'invierte/simulador']
    const reedirigirAChatWhatsapp = () =>{
        window.open(`https://api.whatsapp.com/send?phone=528441630722&text=Hola!,%20vi%20tu%20pagina%20web%20quiero%20mas%20información`)
        // window.location.href = 'https://api.whatsapp.com/send?phone=528443492981&text=mensajedeprueba';        
    }
    // if(location.pathname !== '/pruebaPDF' || location.pathname.includes('agendar-cita')){
    if(!blackList.find((element) => location.pathname.includes(element) )){
        return(
            <Box
                sx={{ 
                    margin: 0,
                    top: 'auto',
                    right: { xs:25, sm:25, md:20, },
                    bottom: { xs:95, sm:160, md:130, },
                    left: 'auto',
                    position: 'fixed',
                    display:{ xs:'block', sm:'block', md:'block' },
                    zIndex:2,
                }}
            >
                <Fab 
                    color="error" 
                    aria-label="add" 
                    sx={{ 
                        backgroundColor:'#25D366' 
                    }} 
                    onClick={reedirigirAChatWhatsapp}
                    // onMouseMove={handleClick}
                    className='BotonWhatsapp'
                    
                >
                    <WhatsAppIcon/>
                </Fab>
                {/* <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                >
                    <Typography sx={{ p: 2 }}>¿Te podemos ayudar en algo?</Typography>
                </Popover> */}
            </Box>
        );
    }
    
}

export { WhatsappFAB }