


import { Box, List, ListItem, } from '@mui/material';
import { ItemIdiomas } from './ItemIdiomas';

import EditIcon from '@mui/icons-material/Edit';

const ListaIdiomas = ({ idiomas }) => {
    return(
        <Box
            sx={{
                // backgroundColor:'red',
            }}
        >
            <List
                sx={{ width:'100%', }}
            >
                {
                    idiomas.map((idioma)=>(
                        <ListItem sx={{ width:'100%', }}>
                            <ItemIdiomas idioma={idioma} />
                        </ListItem>
                    ))
                } 
            </List>
        </Box>
    );
}

export { ListaIdiomas }