import * as React from "react";
import { useRef } from "react";
import { Box, height } from "@mui/system";
import Container from '@mui/material/Container';

//import Carousel from "react-multi-carousel";
//import './styles.css';
import { Button, Grid, Stack, Fab, Typography, Skeleton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { motion } from "framer-motion";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { LugarCercano } from "./LugarCercano";

import facultadcienciasdelacomunicacion from '../../assets/lugarescercanos/facultadcienciasdelacomunicacion.png'
import heb from '../../assets/lugarescercanos/heb.png'
import sendero from '../../assets/lugarescercanos/sendero.png'
import { useEffect } from "react";


// const lugares = [
//   { name: 'Facultad de Ciencias de la Comunicación U.A. de C.', distancia: '7 min (4.9 km)', tipo: 'Educación', img: facultadcienciasdelacomunicacion },
//   { name: 'H-E-B Plaza Nogalera', distancia: '7 min (4.0 km)', tipo: 'Centro Comercial', img: heb },
//   { name: 'Plaza Sendero Sur', distancia: '16 min (10.1 km)', tipo: 'Centro Comercial', img: sendero },
// ]

const LugaresCercanos = ({ lugares }) => {
    const constraintsRef = useRef(null);
    const containerRef = useRef(null);

    const constraintsRefMovil = useRef(null);
    const containerRefMovil = useRef(null);

    console.log('lugares', lugares);
    //const Contenedor = containerRef.current.clientWidth == null || containerRef.current.clientWidth == undefined ? 0 : containerRef.current.clientWidth;
    // const DivVisible = constraintsRef.current.clientWidth;
    // const Diferencia = Contenedor - DivVisible;

    const [Xside, setXside] = React.useState(0);
    const [XIside, setXIside] = React.useState(0);

    const [constraintsWidth, setConstraintsWidth] = React.useState(0);
    const [containerWidth, setContainerWidth] = React.useState(0);

    const atras = (Xside) => {
      setXside(Xside+384);
      setXIside((XIside - 384) < constraintsWidth ? constraintsWidth : (XIside - 384))
      console.log(constraintsRef.current.clientWidth);
      console.log(containerRef.current.clientWidth);
    };
    const adelante = (Xside) => {
      setXside(Xside-384);
      setXIside((XIside + 384) > containerWidth ? containerWidth : (XIside + 384))
      console.log(constraintsRef.current.clientWidth);
      console.log(containerRef.current.clientWidth);
    };

    useEffect(() => {
      setXIside(constraintsRef.current.clientWidth);
      setConstraintsWidth(constraintsRef.current.clientWidth)
      setContainerWidth(containerRef.current.clientWidth)
      console.log('constraintsRef:::::', constraintsRef.current.clientWidth)
      console.log('containerRef:::::', containerRef.current.clientWidth)
      console.log('Xside:::::', Xside)
      console.log('XIside:::::', XIside)
      //
    }, [setXIside, setConstraintsWidth, setContainerWidth]);
    return(
        <Container sx={{  }}>
            <Box sx={{ textAlign:'left', marginTop:2, color:'#F5323F', }}>
              <h1>Lugares Cercanos</h1>
            </Box>
                    
            {/* <Box
              component={motion.div}
              ref={constraintsRef}
              sx={{
                //backgroundColor:'white', 
                display: 'flex',
                placeContent: 'start',
                placeItems: 'center',
                overflow: 'hidden',
                position:'relative',
                marginTop: 5,
                marginBottom: 9
              }}
            >
              <Stack
              ref={containerRef}
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                //spacing={2}
                component={motion.div}
                animate={{ x:Xside }}
                drag='x' 
                dragConstraints={constraintsRef}
              >
                {
                  lugares.map((lugar) => (
                    <Box
                      component={motion.div}
                      sx={{ 
                        width:{ xs: '185px', sm:'245px', md:'360px' },
                        ml:1.5,
                        mr:1.5,
                      }}                  
                    >
                      <LugarCercano lugar={lugar}/>  
                    </Box>
                  ))
                }                                               
              </Stack>
              <Box 
                component="Button" 
                sx={{ 
                  pt: 3, pb:3, pl:0, pr:0,
                  backgroundColor:'white', 
                  border:'none',
                  color:'red',
                  zIndex:1, 
                  position:'absolute',
                  left:0,
                }}
                variant="contained"
                onClick={()=> atras(Xside)}
              >
                  <ChevronLeftIcon />
                </Box>
                <Box 
                component="Button" 
                sx={{ 
                  pt: 3, pb:3, pl:0, pr:0,
                  backgroundColor:'white', 
                  border:'none',
                  color:'red',
                  zIndex:1, 
                  position:'absolute',
                  right:0,
                }}
                variant="contained"
                onClick={()=> adelante(Xside)}
              >
                  <ChevronRightIcon />
                </Box>
            </Box> */}

            <Box
              component={motion.div}
              ref={constraintsRef}
              sx={{
                // backgroundColor:'white', 
                display: { xs: 'none', md: 'flex' },
                placeContent: 'start',
                placeItems: 'center',
                overflow: 'hidden',
                position:'relative',
                marginTop:2,
                mb:2,
              }}
            >
              <Stack
                ref={containerRef}
                direction="row"
                justifyContent="center"
                alignItems="center"
                //spacing={2}
                component={motion.div}
                animate={{ x:Xside }}
                sx={{ 
                  // backgroundColor:'yellow',
                  // pl:0.5,
                  // pr:0.7,
                  // width:'100%'
                  pb:1
                }}

              >
                {
                  // modelos && (
                    lugares.length > 0 ? (
                      lugares.map((lugar) => (
                        <Box
                          component={motion.div}
                          sx={{ 
                            width:{ xs: '185px', sm:'245px', md:'360px' },
                            ml:1.5,
                            mr:1.5,
                          }}                  
                        >
                          <LugarCercano lugar={lugar}/>  
                        </Box>
                      ))
                    ) 
                    : (
                      <React.Fragment>
                        <Box sx={{ ml:1 }}>
                          <Stack spacing={1}>      
                            <Skeleton variant="rectangular" width={375} height={360} />
                            <Skeleton variant="text" width={250} />
                            <Skeleton variant="text" width={110} />
                          </Stack>
                        </Box>
                        <Box sx={{ ml:1 }}>
                          <Stack spacing={1}>      
                            <Skeleton variant="rectangular" width={375} height={360} />
                            <Skeleton variant="text" width={250} />
                            <Skeleton variant="text" width={110} />
                          </Stack>
                        </Box>
                        <Box sx={{ ml:1 }}>
                          <Stack spacing={1}>      
                            <Skeleton variant="rectangular" width={375} height={360} />
                            <Skeleton variant="text" width={250} />
                            <Skeleton variant="text" width={110} />
                          </Stack>
                        </Box>
                      </React.Fragment>
                    )
                  // )
                }
                {/* <Box
                  component={motion.div}
                  sx={{ 
                    // width: '375px'
                    // width: '185px'
                    width:{ xs: '185px', sm:'185px', md:'375px', lg:'375px' }
                    
                  }}
                  
                >
                  <ModeloItem/>  
                </Box>
                <Box
                  component={motion.div}
                  sx={{ 
                    // width: '375px'
                    // width: '185px'
                    width:{ xs: '185px', sm:'185px', md:'375px', lg:'375px' }
                    
                  }}
                  
                >
                  <ModeloItem/>  
                </Box>
                <Box
                  component={motion.div}
                  sx={{ 
                    // width: '375px'
                    // width: '185px'
                    width:{ xs: '185px', sm:'185px', md:'185px', lg:'375px' }
                    
                  }}
                  
                >
                  <ModeloItem/>  
                </Box>
                <Box
                  component={motion.div}
                  sx={{ 
                    // width: '375px'
                    // width: '185px'
                    width:{ xs: '185px', sm:'185px', md:'375px', lg:'375px' }
                    
                  }}
                  
                >
                  <ModeloItem/>  
                </Box>  */}
                               
              </Stack>
              {
                XIside !== constraintsWidth && (
                  <Box 
                component="Button" 
                sx={{ 
                  pt: 3, pb:3, pl:0, pr:0,
                  backgroundColor:'white', 
                  border:'none',
                  color:'#f5323f',
                  zIndex:1, 
                  position:'absolute',
                  left:0,
                  borderColor:'red',
                }}
                variant="contained"
                onClick={()=> atras(Xside)}
              >
                  <ChevronLeftIcon />
                </Box>
                )
              }
              {
                XIside !== containerWidth && (
                  <Box 
                component="Button" 
                sx={{ 
                  pt: 3, pb:3, pl:0, pr:0,
                  backgroundColor:'white', 
                  border:'none',
                  color:'#f5323f',
                  zIndex:1, 
                  position:'absolute',
                  right:0,
                  // borderColor:'red',
                }}
                variant="contained"
                onClick={()=> adelante(Xside)}
              >
                  <ChevronRightIcon />
                </Box>
                )
              }
                
            </Box>

            <Box
              component={motion.div}
              ref={constraintsRefMovil}
              sx={{
                // backgroundColor:'white', 
                display: { xs: 'flex', md: 'none' },
                placeContent: 'start',
                placeItems: 'center',
                overflow: 'hidden',
                position:'relative',
                marginTop:2,
                mb:2,
              }}
            >
              <Stack
                ref={containerRefMovil}
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                component={motion.div}
                animate={{ x:Xside }}
                drag='x' 
                dragConstraints={constraintsRefMovil}
                sx={{ pr:4, }}
              >
                {
                  lugares.length > 0 ? (
                    // modelos.map((modelo) => (
                    //   <Box
                    //     component={motion.div}
                    //     sx={{ 
                    //       // width: '375px'
                    //       // width: '185px'
                    //       width:{ xs: '250px', sm:'285px', md:'375px', lg:'375px' }
                          
                    //     }}
                        
                    //   >
                    //     <ModeloItem modelo={modelo}/>
                    //   </Box>
                    // ))
                    lugares.map((lugar) => (
                      <Box
                        component={motion.div}
                        sx={{
                          width:{ xs: '250px', sm:'285px', md:'375px', lg:'375px' },
                          // width:{ xs: '185px', sm:'245px', md:'360px' },
                          ml:1.5,
                          mr:1.5,
                        }}                  
                      >
                        <LugarCercano lugar={lugar}/>  
                      </Box>
                    ))
                  ) : (
                    <React.Fragment>
                      <Box sx={{ ml:1, }}>
                        <Stack spacing={1}>      
                          <Skeleton variant="rectangular" width={375} height={360} />
                          <Skeleton variant="text" width={250} />
                          <Skeleton variant="text" width={110} />
                        </Stack>
                      </Box>
                      <Box sx={{ ml:1, }}>
                        <Stack spacing={1}>      
                          <Skeleton variant="rectangular" width={375} height={360} />
                          <Skeleton variant="text" width={250} />
                          <Skeleton variant="text" width={110} />
                        </Stack>
                      </Box>
                    </React.Fragment>
                  )
                }
                {/* <Box
                  component={motion.div}
                  sx={{ 
                    // width: '375px'
                    // width: '185px'
                    width:{ xs: '250px', sm:'285px', md:'375px', lg:'375px' }
                    
                  }}
                  
                >
                  <ModeloItem/>  
                </Box>
                <Box
                  component={motion.div}
                  sx={{ 
                    // width: '375px'
                    // width: '185px'
                    width:{ xs: '250px', sm:'285px', md:'375px', lg:'375px' }
                    
                  }}
                  
                >
                  <ModeloItem/>  
                </Box> 
                <Box
                  component={motion.div}
                  sx={{ 
                    // width: '375px'
                    // width: '185px'
                    width:{ xs: '250px', sm:'285px', md:'375px', lg:'375px' }
                    
                  }}
                  
                >
                  <ModeloItem/>  
                </Box>
                <Box
                  component={motion.div}
                  sx={{ 
                    // width: '375px'
                    // width: '185px'
                    width:{ xs: '250px', sm:'285px', md:'375px', lg:'375px' }
                    
                  }}
                  
                >
                  <ModeloItem/>  
                </Box>  */}

              </Stack>                
            </Box>

                

        </Container>
    );
}

export { LugaresCercanos }