import React, {useState,useEffect} from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation, useSearchParams, Link, NavLink, Navigate } from "react-router-dom";
import { Container } from "@mui/system";
import { MenuFraccionamientos } from "../../components/MenuFraccionamientos";
import { Skeleton } from '@mui/material';
import { MenuModelos } from '../../components/MenuModelos';

const ListaModelos = () => {
    let navigate = useNavigate();
    const location = useLocation();
    //console.log(location.state.inventario);

    //const [inventario, setInventario] = useState(location.state.inventario);
    const [modelos, setModelos] = useState([]);

    useEffect(() => {
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
          
        axios.get(`${process.env.REACT_APP_API_URL}/api/modelo`, {
              headers: {
                  Authorization: `Bearer ${token}`
              }
        })
        .then((response) => {
            // handle success
            console.log(response);
            setModelos(response.data) 
            console.log('se valido Inventario');
        })
        .catch((error) => {
            // handle success
            console.log(error);
            navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sae');
        });
    }, [setModelos]);

    return(
        <div>
            <Container>
                {
                    !modelos ? 
                    (<Skeleton variant="rectangular" width={210} height={118} />) : 
                    (<MenuModelos modelos={modelos} />)
                }                
            </Container>
        </div>
    );
}

export { ListaModelos }