import { CardActionArea, CardHeader, Grid, Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


import elrocal from '../../assets/elrocal_principal.png';
import bosquesdelomas from '../../assets/bosquesdelomas_principal.png';


const FraccionamientosItem = ({ fraccionamiento }) => {
    // console.log('ice ice baby',fraccionamiento);
    let navigate = useNavigate();

    const [lotesDisponibles, setLotesDisponibles] = useState(0);

    const contarLotesDisponibles = (fraccionamiento) => {
        const LotesDisponibles = fraccionamiento.etapas.reduce((count, current) => { return count + current.manzanas.reduce((count, current) => { return count + current.lotes.length }, 0) }, 0 )
        return LotesDisponibles;
    }

    // const [fraccionamiento, setFraccionamiento] = useState(props.fraccionamiento);
    useEffect(()=>{
        setLotesDisponibles(contarLotesDisponibles(fraccionamiento));
    },[fraccionamiento]);
    return (
        <React.Fragment>
            <Card 
                sx={{
                    width:'100%',
                    display: { xs: 'none', md: 'flex' },
                    // m:0.5,
                    color:'#707070',
                    borderRadius: '16px',
                    boxShadow: 8,
                    // boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                    boxShadow: '0px 5px 12px 0px rgb(0, 0, 0, 0.19)',
                    mt:1,
                    mb:1,
                }}
            >
                <CardActionArea
                    onClick={() => navigate(`/fraccionamiento/${fraccionamiento.nombre.replace(/ /g, "_")}`, {state:{fraccionamiento:fraccionamiento}})}
                >
                    {
                        fraccionamiento.fotos.length > 0 ? (
                            // <CardMedia
                            //     component="img"
                            //     height="360"
                            //     image={fraccionamiento.fotos[0].url}
                            //     alt="casas pecsa"
                            // />
                            <Box
                                sx={{
                                    backgroundColor:'pink',
                                    width:'100%',
                                    height:360,
                                    display:'flex',
                                    alignItems:'flex-end',
                                    overflow:'hidden',
                                }}
                            >
                                <Box
                                    component="img"
                                    src={fraccionamiento.fotos[0].url}
                                    sx={{
                                        width:'100%',
                                        minHeight:'100%',
                                    }}
                                />
                            </Box>
                        ) : (
                            <Skeleton variant="rectangular" width={'100%'} height={360} />
                        )
                    }                    
                    
                    <CardContent spacing={1}>
                        <Box>
                            <Grid 
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <Grid item md={12}>
                                    <Typography variant="h5" component="div">
                                        <b>{fraccionamiento.nombre}</b>
                                    </Typography>
                                </Grid>
                                <Grid item md={12}>
                                    <Typography  variant="body2">
                                        {fraccionamiento.municipio.nombre}
                                    </Typography>
                                </Grid>
                                <Grid item md={12}>
                                    <Typography variant="body2">
                                        {fraccionamiento.descripcionUbicacion}
                                    </Typography>
                                </Grid>
                                <Grid item md={12}>
                                    <Typography variant="body2">
                                        Inventario disponible: <b>{lotesDisponibles} casas</b>
                                    </Typography>
                                </Grid>
                                <Grid item md={12}></Grid>
                            </Grid>
                        </Box>
                        {/* <Stack>
                            <Typography variant="h5" component="div">
                                {fraccionamiento.nombre}
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid item xs={7}>
                                    <Stack direction="column"
                                        justifyContent="center"
                                        alignItems="flex-start"
                                    >
                                        <Typography  variant="body2">
                                            {fraccionamiento.municipio.nombre}
                                        </Typography>
                                        <Typography variant="body2">
                                            Al poniente de la ciudad
                                        </Typography>
                                        <Typography variant="body2">
                                            Inventario disponible: 30 casas
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button size="small" endIcon={<ArrowForwardIcon />} sx={{ color:'#f5323f' }}>
                                        VER MÁS
                                    </Button>
                                </Grid>
                            </Grid>
                        </Stack> */}
                    </CardContent>
                    <CardActions>
                        <Box
                            sx={{ 
                                // backgroundColor:'green',
                                width:'100%',
                                textAlign:'center',
                            }}
                        >
                            <Button 
                                size="small" 
                                endIcon={<ArrowForwardIcon />} 
                                sx={{ color:'#f5323f', textTransform: 'none' }}
                                // onClick={() => navigate(`/fraccionamiento/${fraccionamiento.nombre.replace(/ /g, "_")}`, {state:{fraccionamiento:fraccionamiento}})}
                            >
                                <b>Ver más</b>
                            </Button>
                        </Box>
                    </CardActions>
                </CardActionArea>
            </Card>
            <Card  
                sx={{
                    width:'100%',
                    display: { xs: 'flex', md: 'none' },
                    // m:0.5,
                    // color: '#707070'
                    borderRadius: '16px',
                    // boxShadow: 8,
                    // boxShadow: '0 10px 5px 0 rgba(0, 0, 0, 0.12), 0 4px 10px 0 rgba(0, 0, 0, 0.19)',
                    boxShadow: '0px 5px 12px 0px rgb(0, 0, 0, 0.19)',
                    mt:2,
                    ml:2,
                    mb:3,
                }}
            >
                <CardActionArea
                    onClick={() => navigate(`/fraccionamiento/${fraccionamiento.nombre.replace(/ /g, "_")}`, {state:{fraccionamiento:fraccionamiento}})}
                >
                    {
                        fraccionamiento.fotos.length > 0 ? (
                            <CardMedia
                                component="img"
                                height="260"
                                image={fraccionamiento.fotos[0].url}
                                alt="casas pecsa"
                            />
                        ) : (
                            <Skeleton variant="rectangular" width={'100%'} height={260} />
                        )
                    }                      
                    <CardContent spacing={1}>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                        >
                            <Typography variant="h5" component="div">
                                {fraccionamiento.nombre}
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Stack direction="column"
                                        justifyContent="center"
                                        alignItems="flex-start"
                                    >
                                        <Typography  variant="body2" color="text.secondary">
                                            {fraccionamiento.municipio.nombre}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Al poniente de la ciudad
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Inventario disponible: 30 casas
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    </CardContent>
                    <CardActions>
                        <Box
                            sx={{ 
                                // backgroundColor:'green',
                                width:'100%',
                                textAlign:'center',
                            }}
                        >
                            <Button 
                                size="small" 
                                endIcon={<ArrowForwardIcon />} 
                                sx={{ color:'#f5323f', textTransform: 'none' }}
                                // onClick={() => navigate(`/fraccionamiento/${fraccionamiento.nombre.replace(/ /g, "_")}`, {state:{fraccionamiento:fraccionamiento}})}
                            >
                                <b>Ver más</b>
                            </Button>
                        </Box>
                    </CardActions>
                </CardActionArea>
            </Card>
        </React.Fragment>
    );
}

export { FraccionamientosItem };