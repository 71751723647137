import * as React from "react";

import { Box, height } from "@mui/system";
import Container from '@mui/material/Container';
import { Grid, Stack, SvgIcon, Typography, Button } from "@mui/material";

import solicita from '../../../assets/solicita.png';
import bancos from '../../../assets/bancos.png';

import banorte from '../../../assets/solicitacredito/banorte.svg';
import bbva from '../../../assets/solicitacredito/bbva.svg';
import fobissste from '../../../assets/solicitacredito/fobissste.svg';
import HSBC from '../../../assets/solicitacredito/HSBC.svg';
import infonavit from '../../../assets/solicitacredito/infonavit.svg';
import scotiabank from '../../../assets/solicitacredito/scotiabank.svg';

const SolicitaCredito = () => {

    const reedirigirMoffin = () =>{
        window.open('https://pecsa.moffin.co/solicitud/hipotecario/comenzar')
    }

    return(
        <Container sx={{ backgroundColor:'#F5F5F5' }}>
            <Box>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    // spacing={2}                
                >
                    <Grid item xs={12} sm={6} md={6}>
                        <Box>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                // spacing={2}                
                            >
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography variant="h4" component="div" sx={{ color:'#7e7e7e', mt:{ xs:4, sm:2, md:0, } }}>
                                        <b>Solicita tu crédito</b> en 5 minutos
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography variant="p" component="div" sx={{ color:'#7e7e7e', mt:2, mb:2 }}>
                                        Trabajamos con todos los bancos, ademas de Infonavit y Fovissste
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Box
                                        sx={{
                                            // backgroundColor:'red',
                                            width:'100%',
                                        }}
                                    >
                                    <Box
                                        sx={{
                                            // backgroundColor:'yellow',
                                            width:'70%',
                                            m:'0 auto'
                                            // display:'flex',
                                            // alignItems:'center',
                                        }}
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            // spacing={2}                
                                        >
                                            <Grid item xs={4} sm={4} md={4}>
                                                <Box
                                                    sx={{
                                                        // backgroundColor:'gray',
                                                        width:'100%',
                                                        
                                                    }}
                                                >
                                                    <Box
                                                        component='img'
                                                        src={HSBC}
                                                        sx={{
                                                            width:'100%',
                                                            maxHeight:35
                                                        }}
                                                        alt="Banco Participante"
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={4}>
                                                <Box
                                                    sx={{
                                                        // backgroundColor:'gray',
                                                        width:'100%',
                                                    }}
                                                >
                                                    <Box
                                                        component='img'
                                                        src={bbva}
                                                        sx={{
                                                            width:'100%',
                                                            maxHeight:35
                                                        }}
                                                        alt="Banco Participante"
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={4}>
                                                <Box
                                                    sx={{
                                                        // backgroundColor:'gray',
                                                        width:'100%',
                                                    }}
                                                >
                                                    <Box
                                                        component='img'
                                                        src={scotiabank}
                                                        sx={{
                                                            width:'100%',
                                                            maxHeight:35
                                                        }}
                                                        alt="Banco Participante"
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={4}>
                                                <Box
                                                    sx={{
                                                        // backgroundColor:'gray',
                                                        width:'100%',
                                                    }}
                                                >
                                                    <Box
                                                        component='img'
                                                        src={banorte}
                                                        sx={{
                                                            width:'100%',
                                                            maxHeight:35
                                                        }}
                                                        alt="Banco Participante"
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={4}>
                                                <Box
                                                    sx={{
                                                        // backgroundColor:'gray',
                                                        width:'100%',
                                                    }}
                                                >
                                                    <Box
                                                        component='img'
                                                        src={infonavit}
                                                        sx={{
                                                            width:'100%',
                                                            maxHeight:35
                                                        }}
                                                        alt="Banco Participante"
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={4}>
                                                <Box
                                                    sx={{
                                                        // backgroundColor:'gray',
                                                        width:'100%',
                                                    }}
                                                >
                                                    <Box
                                                        component='img'
                                                        src={fobissste}
                                                        sx={{
                                                            width:'100%',
                                                            maxHeight:35
                                                        }}
                                                        alt="Banco Participante"
                                                    />
                                                </Box>
                                            </Grid>                                            
                                        </Grid>
                                    </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Box
                                        sx={{
                                            // backgroundColor:'green',
                                            textAlign:'center',
                                            mt:3,
                                            pb:{xs:5, sm:2, md:0},
                                        }}
                                    >
                                        <Button 
                                            variant="outlined" 
                                            color="inherit"
                                            sx={{ 
                                                // backgroundColor:'#FFFFFF', 
                                                color: '#7e7e7e',
                                                borderColor: '#7e7e7e'
                                            }} 
                                            onClick={reedirigirMoffin}
                                        >
                                            <b>Preautorizar</b>
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={{ display: { xs:'none', sm:'block', md:'block', } }}>
                        <Box
                            sx={{
                                // backgroundColor:'green',
                                width:'100%',
                            }}
                        >
                            <Box
                                component="img"
                                src={ solicita } 
                                sx={{ 
                                    width: { xs:'90%', sm:'100%', md:'90%' }, 
                                    marginTop:{ xs:1, sm:2, md:4 },
                                    marginLeft:{ xs:1, sm:0, md:6 }
                                }}
                                alt="Solicita"
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
           
        </Container>
    );
};


export { SolicitaCredito };
