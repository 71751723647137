import { Button, Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";


const UbicacionDelFraccionamiento = ({ fraccionamiento }) => {
    // console.log('ubi',fraccionamiento);
    return(
        <Container>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                    mt:4,
                    mb:3,
                    color:'#707070',
                }}
            >
                <Grid item xs={12} sm={5} md={6} sx={{ textAlign:'left' }}>
                    <Typography variant="h5" gutterBottom component="div" sx={{ color:'#F5323F', }}>
                        <b>UBICACIÓN DEL FRACCIONAMIENTO</b>
                    </Typography>
                    <Typography variant="body2" gutterBottom component="div">
                        {
                            fraccionamiento.descripcion
                        }                        
                    </Typography>
                    {/* <Button variant="outlined">¿COMO LLEGAR?</Button> */}
                </Grid>
                <Grid item xs={12} sm={7} md={6} >
                    <Box
                        component='iframe'
                        // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3605.1351665418374!2d-100.99917498454889!3d25.366786031023114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8688735573f6d5ef%3A0xcd22a9738ffcab8d!2sBosques%20de%20las%20Lomas!5e0!3m2!1ses-419!2smx!4v1654558827826!5m2!1ses-419!2smx"
                        src={fraccionamiento.gmIframe}
                        sx={{
                            width:{ xs:"100%", sm:"95%", md:"95%" },
                            height:{ xs:300, sm:250, md:300 },
                            borderRadius:'15px',
                        }}                        
                    />
                </Grid>
            </Grid>
        </Container>
    );
}

export { UbicacionDelFraccionamiento }