import React, { useState } from "react";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Box,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CustomTypography } from "../CustomTypography";
import { CustomButton } from "../CustomButton";
import { getFormatoMoneda } from "../../functions/getFormatoMoneda";
import theme from "../../App/theme";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const FullHomeCard = ({
  homeData,
  toggleCTAVisibility,
  activeButtonsCount,
  setActiveButtonsCount,
  addLotes,
}) => {
  let navigate = useNavigate();

  const navigateToPage = (url) => {
    navigate(url);
  };

  const {
    fotos,
    fachada,
    numeroDeLote,
    superficieDeTerreno,
    caracteristicas,
    precio,
    sku,
    manzana,
  } = homeData;

  const [isPressed, setIsPressed] = useState(false);

  const compareButton = () => {
    if (isPressed) {
      setActiveButtonsCount(activeButtonsCount - 1);
    } else if (activeButtonsCount < 3) {
      // Limitar a tres botones activos
      setActiveButtonsCount(activeButtonsCount + 1);
    }
    setIsPressed(!isPressed);
    toggleCTAVisibility();
    addLotes(homeData.id);
  };

  const isDisabled = activeButtonsCount >= 3 && !isPressed;

  return (
    <Card
      sx={{
        minWidth: 310,
        maxWidth: { xs: 420, md: 1200 },
        border: `1px solid ${theme.palette.grey[200]}`,
        borderRadius: "10px",
      }}
    >
      <Grid container>
        <Grid item xs={12} md={4}>
          <CardMedia
            component="img"
            alt={`Casa Modelo:${fachada.modelo.nombre} Lote:${numeroDeLote} Fraccionamiento:${homeData.manzana.etapa.fraccionamiento.nombre}`}
            height="100%"
            image={fotos[0].url}
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box pb="10px">
                  <CustomTypography variant="textPrimary">
                    {manzana.etapa.fraccionamiento.nombre}
                  </CustomTypography>
                </Box>
                <Grid container spacing={2} paddingBottom="20px">
                  <Grid item xs={6}>
                    <CustomTypography variant="textPrimaryBold">
                      Modelo {fachada.modelo.nombre}
                    </CustomTypography>
                  </Grid>

                  <Grid item xs={6} textAlign="right">
                    <CustomTypography variant="textTertiaryLight">
                      {fachada.modelo.superficieDeConstruccion} m<sup>2</sup> de
                      construcción
                    </CustomTypography>
                  </Grid>

                  <Grid item xs={6}>
                    <CustomTypography variant="textPrimaryLight">
                      Lote {numeroDeLote}
                    </CustomTypography>
                  </Grid>

                  <Grid item xs={6} textAlign="right">
                    <CustomTypography variant="textTertiaryLight">
                      {superficieDeTerreno} m<sup>2</sup> de terreno
                    </CustomTypography>
                  </Grid>
                </Grid>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderTop: `2px solid ${theme.palette.grey[200]}`,
                    borderBottom: `2px solid ${theme.palette.grey[200]}`,
                    marginLeft: "-10px",
                    marginRight: "-10px",
                    padding: "20px 0",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                      width: "calc(50% - 2px)", // Ocupar el 50% del espacio disponible - 2px para la línea divisoria
                    }}
                  >
                    <BedOutlinedIcon sx={{ fontSize: "0.9rem" }} />
                    {caracteristicas[0].recamaras} Habitaciones
                  </Box>

                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ bgcolor: theme.palette.grey[200] }}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                      width: "calc(50% - 2px)", // Ocupar el 50% del espacio disponible - 2px para la línea divisoria
                    }}
                  >
                    <BathtubOutlinedIcon sx={{ fontSize: "0.9rem" }} />
                    {caracteristicas[0].banios} Baños
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "16px",
                  }}
                >
                  <CustomTypography variant="subheadingSecondary">
                    {getFormatoMoneda(precio)}
                    <CustomTypography variant="textTertiaryBold">
                      MXN
                    </CustomTypography>
                  </CustomTypography>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    borderTop: `2px solid ${theme.palette.grey[200]}`,
                    marginLeft: "-10px",
                    marginRight: "-10px",
                    paddingTop: "20px",
                    pr: "10px",
                    pl: "10px",
                  }}
                >
                  <CustomTypography variant="textSecondaryBold">
                    Lo que inviertes:
                  </CustomTypography>
                  <Grid container spacing={2} pt="10px" pb="20px">
                    <Grid item xs={6}>
                      <CustomTypography variant="textSecondaryLight">
                        Apartado:
                      </CustomTypography>
                    </Grid>

                    <Grid item xs={6} textAlign="right">
                      <CustomTypography variant="textSecondaryBold">
                        {getFormatoMoneda(homeData.apartado)}
                      </CustomTypography>
                      <CustomTypography variant="textTertiaryLight">
                        MXN
                      </CustomTypography>
                    </Grid>

                    <Grid item xs={6}>
                      <CustomTypography variant="textSecondaryLight">
                        Enganche:
                      </CustomTypography>
                    </Grid>

                    <Grid item xs={6} textAlign="right">
                      <CustomTypography variant="textSecondaryBold">
                        {getFormatoMoneda(homeData.enganche)}
                      </CustomTypography>
                      <CustomTypography variant="textTertiaryLight">
                        MXN
                      </CustomTypography>
                    </Grid>

                    <Grid item xs={6}>
                      <CustomTypography variant="textSecondaryLight">
                        Gastos:
                      </CustomTypography>
                    </Grid>

                    <Grid item xs={6} textAlign="right">
                      <CustomTypography variant="textSecondaryBold">
                        {getFormatoMoneda(homeData.gastos)}
                      </CustomTypography>
                      <CustomTypography variant="textTertiaryLight">
                        MXN
                      </CustomTypography>
                    </Grid>

                    <Grid item xs={6}>
                      <CustomTypography variant="textSecondaryLight">
                        Monto de crédito:
                      </CustomTypography>
                    </Grid>

                    <Grid item xs={6} textAlign="right">
                      <CustomTypography variant="textSecondaryBold">
                        {getFormatoMoneda(
                          homeData.montoDeCredito < 0
                            ? homeData.montoDeCredito * -1
                            : homeData.montoDeCredito
                        )}
                      </CustomTypography>
                      <CustomTypography variant="textTertiaryLight">
                        MXN
                      </CustomTypography>
                    </Grid>
                  </Grid>

                  <CustomTypography variant="textSecondaryBold">
                    Ingreso renta mensual aproximado:
                  </CustomTypography>
                  <Grid container spacing={2} pt="10px" pb="20px">
                    <Grid item xs={7}>
                      <CustomTypography variant="textSecondaryLight">
                        Renta mensual aproximado:
                      </CustomTypography>
                    </Grid>

                    <Grid item xs={5} textAlign="right">
                      <CustomTypography variant="textSecondaryBold">
                        {caracteristicas[0].valorRenta}
                      </CustomTypography>
                      <CustomTypography variant="textTertiaryLight">
                        MXN
                      </CustomTypography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </CardContent>

          {/* Botones en la parte de abajo ocupando la mitad del espacio disponible en tamaño de escritorio */}
          <Grid container alignItems="flex-end">
            <Grid item xs={12} md={6}>
              <Box>
                <CustomButton
                  text="Comparar"
                  variant={isPressed ? "contained" : "outlined"}
                  size="medium"
                  onClick={compareButton}
                  color="success"
                  txtColor={theme.palette.common.black}
                  disabled={isDisabled}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <CustomButton
                text="Conocer Casa"
                endIcon={<ArrowForwardIcon />}
                variant="contained"
                size="medium"
                onClick={() =>
                  navigateToPage(`/tienda/${sku.replace(/ /g, "_")}`)
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export { FullHomeCard };
