import { Box, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Skeleton, Typography } from "@mui/material";
import { Container } from "@mui/system";

import PostAddIcon from '@mui/icons-material/PostAdd';
import BarChartIcon from '@mui/icons-material/BarChart';
import InfoIcon from '@mui/icons-material/Info';
import PersonIcon from '@mui/icons-material/Person';
import CollectionsIcon from '@mui/icons-material/Collections';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ImageNotSupportedOutlinedIcon from '@mui/icons-material/ImageNotSupportedOutlined';
import TodayIcon from '@mui/icons-material/Today';

import asesorPerfil2 from "../../assets/asesores/asesorPerfil2.png";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";

const EspacioAsesor = () => {
    let navigate = useNavigate();
    const { folio } = useParams();
    console.log(folio);
    const [asesor, setAsesor] = useState(null);
    useEffect(()=>{
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');

        axios.get(`${process.env.REACT_APP_API_URL}/api/asesores/profile/${folio}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
        .then((response)=>{
            console.log(response);
            setAsesor(response.data)            
        })
        .catch((error)=>{
            console.log(error);
            navigate('/usuarios/login')
        });
    }, [folio]);
    return(
        <Container>
            <Box
                sx={{
                    mt:12,
                    mb:8,
                }}
            >            
                <Grid container>
                    <Grid item xs={12} sm={3} md={3}>
                        <Box
                            sx={{
                                // backgroundColor:'red',
                                width:'100%',
                            }}
                        >
                            <Box>
                                <Box>
                                    {
                                        asesor ? (
                                            <Box
                                                sx={{
                                                    // backgroundColor:'red',
                                                    width:'100%',
                                                    minHeight:250,
                                                    display:'flex',
                                                    alignItems:'center',
                                                    alignContent:'center',
                                                }}
                                            >
                                                {
                                                    asesor.foto ? (
                                                        <Box
                                                            sx={{
                                                                // backgroundColor:'yellow',
                                                                width:'80%',
                                                                m:'0 auto',
                                                                borderRadius:'12px',
                                                                overflow:'hidden',
                                                            }}
                                                        >
                                                            <Box
                                                                component='img'
                                                                src={asesor.foto}
                                                                sx={{
                                                                    width:'100%',
                                                                }}
                                                            />
                                                        </Box>
                                                    ) : (
                                                        <Box
                                                            sx={{
                                                                // backgroundColor:'yellow',
                                                                width:'80%',
                                                                m:'0 auto',
                                                                textAlign:'center',
                                                            }}
                                                        >
                                                            <ImageNotSupportedOutlinedIcon
                                                                sx={{
                                                                    fontSize:180,
                                                                    color:'#707070',
                                                                }}
                                                            />
                                                        </Box>
                                                    )
                                                }
                                                
                                                        
                                            </Box>
                                        ) : (
                                            
                                            <Skeleton variant="rounded" width={280} height={250} />
                                        )
                                    }                                            
                                    <Box
                                        sx={{
                                            width:'100%',
                                            textAlign:'center',
                                            // backgroundColor:'red',
                                        }}
                                    >
                                        {
                                            asesor ? (
                                                <Typography variant="body1" gutterBottom>
                                                    <b>{ asesor.nombre }</b>
                                                </Typography>
                                            ) : (
                                                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                            )
                                        }
                                                
                                        <Typography  variant="subtitle2" gutterBottom>
                                            Vendedor
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <List>
                                    <ListItem disablePadding divider>
                                        <ListItemButton onClick={()=> { navigate(`posts`) }}>
                                            <ListItemIcon>
                                                <PostAddIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Publicaciones" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding divider>
                                        <ListItemButton onClick={()=> { navigate(`estadisticas`) }} disabled>
                                            <ListItemIcon>
                                                <BarChartIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Estadisticas" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding divider>
                                        <ListItemButton onClick={()=> { navigate(`informacion`) }}>
                                            <ListItemIcon>
                                                <InfoIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Informacion" />
                                        </ListItemButton>
                                    </ListItem>
                                    {/* <ListItem disablePadding divider>
                                        <ListItemButton onClick={()=> { navigate(`fotos`) }}>
                                            <ListItemIcon>
                                                <CollectionsIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Fotos" />
                                        </ListItemButton>
                                    </ListItem> */}
                                    <ListItem disablePadding divider>
                                        <ListItemButton onClick={()=> { navigate(`agenda`) }}>
                                            <ListItemIcon>
                                                <TodayIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Agenda" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={()=> { navigate(`perfil`) }}>
                                            <ListItemIcon>
                                                <PersonIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Perfil" />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={9} md={9}>
                        <Outlet/>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

export { EspacioAsesor }