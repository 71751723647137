import WhitePecsaLogo from "../Icons/WhitePecsaLogo";
import { Countdown } from "../Countdown";

const textItems = [
  "FERIA DEL CREDITO EN LOMAS DEL MORILLO - PREMIOS SORPRESAS -  ESTRENA CASA ESTE FIN.",
  <WhitePecsaLogo />,
].map((text, index) => ({
  id: index,
  text,
}));

export const extendedTextItems = [
  ...textItems,
  ...textItems,
  ...textItems,
  ...textItems,
  ...textItems,
  ...textItems,
];
