import { Backdrop, Box, Button, CircularProgress, Link, TextField, Typography, styled } from "@mui/material";
import { useState } from "react";
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import moment from "moment/moment";
import axios from "axios";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const regalos = [
    { id: 1, regalo: 'TV', video: 'https://storage.casaspecsa.com/Promociones/La+magia+de+comprar+tu+casa+Pecsa/premios/TV+C.mp4', videoMovil: 'https://storage.casaspecsa.com/Promociones/La+magia+de+comprar+tu+casa+Pecsa/premios/TV+C+(1).mp4'},
    { id: 2, regalo: 'Minisplit', video: 'https://storage.casaspecsa.com/Promociones/La+magia+de+comprar+tu+casa+Pecsa/premios/Mini+C.mp4', videoMovil: 'https://storage.casaspecsa.com/Promociones/La+magia+de+comprar+tu+casa+Pecsa/premios/Mini+C+(1).mp4'},
    { id: 3, regalo: 'Descuento', video: 'https://storage.casaspecsa.com/Promociones/La+magia+de+comprar+tu+casa+Pecsa/premios/Descuento+C.mp4', videoMovil: 'https://storage.casaspecsa.com/Promociones/La+magia+de+comprar+tu+casa+Pecsa/premios/Descuento+C+(1).mp4'}
]
const BotonParaPromo = styled(Button)({
    backgroundColor:'#5adcc9',
    color:'#000',
    m:2,
    borderRadius:'12px',
    textTransform:'none',
    '&:hover':{
        backgroundColor:'#5adcc9',

    }
});
const stylePecsaMoffin = {
    "& label.Mui-focused": {
        color: "#5adcc9",
        // backgroundColor:'rgba(5, 5, 5, 0.2)'
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#5adcc9",
        
    },
    "& .MuiOutlinedInput-root": {
        // '& fieldset': {
        //   borderColor: 'black',
        // },
        backgroundColor:'#fff',
        "&:hover fieldset": {
            borderColor: "#5adcc9"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#5adcc9"
        }
    }
}

const PopUpPromocion = () => {
    const [open, setOpen] = useState(true);
    const getOpen = () => {
        setOpen(!open);
        reestaurarValores();
    }
    const [jugar, setJugar] = useState(false);
    const [mostrarCanjear, setMostrarCanjear] = useState(false);
    const [gano, setGano] = useState(false);
    const [buttonGanoText, setButtonGanoText] = useState('Enviar');
    const [buttonGanoDisabled, setButtonGanoDisabled] = useState(false);

    const reestaurarValores = () => {
        setJugar(false);
        setMostrarCanjear(false);
        setGano(false);
        setButtonGanoText('Enviar');
    }

    const generarAleatorio = (inicio, fin) => {
        // Generar un número decimal aleatorio entre 0 y 1
        const numeroAleatorio = Math.random();

        // Escalar y desplazar el número para que esté dentro del rango
        const numeroEnRango = numeroAleatorio * (fin - inicio + 1) + inicio;

        // Redondear el número al entero más cercano (opcional)
        // Si no deseas redondear, simplemente devuelve `numeroEnRango`.
        return Math.floor(numeroEnRango);
    }
    const [numeroAleatorio, setNumeroAleatorio] = useState(generarAleatorio(0, 2));

    const [isLoading, setIsLoading] = useState(true);
    const handleVideoLoaded = () => {
        // console.log(isLoading);
        setIsLoading(false);
        // console.log(isLoading);
    };

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const sendEmail = (event) => {
        event.preventDefault();
        setButtonGanoText('Enviando...')
        setButtonGanoDisabled(true);
        const data = new FormData(event.currentTarget);

        axios.post(`${process.env.REACT_APP_API_URL}/api/email/sendPromo`, {
            nombre: data.get('nombre'),
            email: data.get('email'),
            fecha: moment().format('DD MMMM YYYY, h:mm:ss a'),
            regalo: numeroAleatorio,
        })
        .then((response) => {
            console.log(response.data)
            getOpen();
            reestaurarValores();
            setButtonGanoDisabled(false);
        })
        .catch((error) => {
            console.log(error)
            setButtonGanoText('Enviar');
            setButtonGanoDisabled(false);
        })
    }
    return(
        <Backdrop
            open={open}
        >
            <Box>
                {
                    jugar ? (
                        gano ? (
                            <Box
                                sx={{
                                    // backgroundColor:'greenyellow',
                                    width:{ xs:340, md:900 },
                                    minHeight:{ xs:550, md:610 },
                                    position:'relative',
                                    display:'flex',
                                    alignItems:'center',
                                    overflow:'hidden',
                                }}
                            >
                                <Box
                                    component="video"
                                    src="https://storage.casaspecsa.com/Promociones/La+magia+de+comprar+tu+casa+Pecsa/otros/fondo+Cc.mp4" 
                                    autoPlay={true} 
                                    loop={true} 
                                    type='video/mp4' 
                                    muted 
                                    sx={{
                                        // position:'relative',
                                        position:'absolute',
                                        m:'0 auto',
                                        width:{ xs:'300%', md:'155%'},
                                        minHeight:'100%',
                                    }}    
                                />
                                <Box
                                    sx={{
                                        width:'100%',
                                        height:'100%',
                                        position:'absolute',
                                        m:'0 auto',
                                        textAlign:'center',
                                        color:'#fff',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            // backgroundColor:'ghostwhite',
                                            width:'100%',
                                            alignItems:'center'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                // backgroundColor:'goldenrod',
                                                width:{ xs:'100%', sm:'70%', md:'70%' },
                                                m:'0 auto'
                                            }}
                                        >
                                            <Box
                                                component="img"
                                                src="https://storage.casaspecsa.com/Promociones/La+magia+de+comprar+tu+casa+Pecsa/otros/logopromoagosto.svg"
                                                sx={{
                                                    width:'100%'
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            // backgroundColor:'ghostwhite',
                                            width:'100%',
                                            display: 'flex',
                                            alignItems:'center',
                                        }}
                                    >
                                        <Box
                                            component="form"
                                            onSubmit={sendEmail}
                                            sx={{
                                                // // backgroundColor:'greenyellow',
                                                background: 'rgba( 255, 255, 255, 0.25 )',
                                                boxShadow:' 0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
                                                backdropFilter: 'blur( 4px )',
                                                webkitBackdropFilter: 'blur( 4px )',
                                                width:{ xs:'95%', sm:'80%', md:'80%'},
                                                m:'0 auto',
                                                alignItems:'center',
                                                borderRadius:'12px',
                                            }}
                                        >
                                            <Typography sx={{ fontSize:{ md:23 }, mb:{xs:2, sm:1, md:1}, mt:3, textShadow: '0px 0px 5px rgba(90,220,201,1)' }}>
                                                <b>Escribe tu correo electronico para que recibas los pasos para reclamar tu premio.</b>
                                            </Typography>
                                            <TextField 
                                                label="Nombre"
                                                name="nombre"
                                                required
                                                sx={{
                                                    width:{ xs:'90%', sm:'70%', md:'70%' },
                                                    ...stylePecsaMoffin,
                                                    mb:{ xs:2, sm:0, md:1 }
                                                }}
                                            />
                                            <TextField 
                                                label="Correo Electronico"
                                                name="email"
                                                required
                                                sx={{
                                                    width:{ xs:'90%', sm:'70%', md:'70%' },
                                                    ...stylePecsaMoffin,
                                                    mb:{ xs:2, sm:0, md:1 }
                                                }}
                                            />
                                            <Typography>
                                                Al hacer click en el botón enviar aceptas <Link 
                                                    href="#"
                                                    color="inherit"
                                                    onClick={()=>{
                                                        window.open('https://storage.casaspecsa.com/Promociones/La+magia+de+comprar+tu+casa+Pecsa/terminosYCondiciones/Terminos+y+condiciones+agosto-compressed_2.pdf','_blank');
                                                    }}
                                                    sx={{ color:'#5adcc9' }}
                                                >
                                                    términos y condiciones de la promoción
                                                </Link>
                                            </Typography>
                                            <BotonParaPromo
                                                sx={{
                                                    width:{ xs:'90%', sm:'40%', md:'40%' },
                                                    fontSize:{ xs:25, sm:35, md:35 },
                                                    mt:1,
                                                    mb:2,
                                                }}
                                                // onClick={()=>setOpen(!open)}
                                                type="submit"
                                                disabled={buttonGanoDisabled}
                                            >
                                                <b>{ buttonGanoText }</b>
                                            </BotonParaPromo>
                                        </Box>                                        
                                    </Box>
                                    
                                </Box>                                    
                            </Box>
                        ) : (
                            <Box
                                sx={{
                                    // backgroundColor:'red',
                                    width:{ xs:340, md:900 },
                                    minHeight:200,
                                    position:'relative',
                                }}
                            >
                                { 
                                    isLoading && (
                                        <Box
                                            sx={{
                                                // backgroundColor:'pink',
                                                width:'100%',
                                                height:500,
                                                display:'flex',
                                                alignItems:'center',
                                            }}
                                        >
                                            <CircularProgress sx={{ m:'0 auto', color:'#5adcc9' }} />
                                        </Box>
                                    )
                                }                                
                                <Box
                                    component="video"
                                    src={ fullScreen ? regalos[numeroAleatorio].videoMovil : regalos[numeroAleatorio].video}
                                    autoPlay={true} 
                                    //   loop={true} 
                                    type='video/mp4' 
                                    muted 
                                    sx={{
                                        // position:'relative',
                                        // position:'absolute',
                                        // m:'0 auto',
                                        width:'100%',
                                        // minHeight:'100%',
                                    }}
                                    onLoadedData={handleVideoLoaded}
                                    onEnded={()=>setMostrarCanjear(true)}
                                />
                                {/* <Box
                                    component="img"
                                    src="https://storage.casaspecsa.com/Portadas/pruebas/terminos.png"
                                    sx={{
                                        width:'100%',
                                        position:'absolute',
                                    }}
                                /> */}
                                <Box
                                    sx={{
                                        width:'100%',
                                        height:110,
                                        textAlign:'center',
                                    }}
                                >
                                    {
                                        mostrarCanjear && (
                                            <BotonParaPromo
                                                color="inherit"
                                                sx={{
                                                    mt:2,
                                                    width:{ xs:'80%', sm:'40%', md:'40%' },
                                                    fontSize:{ xs:38, sm:35, md:35 },
                                                }}
                                                onClick={()=>setGano(true)}
                                            >
                                                <b>Canjear</b>
                                            </BotonParaPromo>
                                        )
                                    }                                
                                </Box>
                            </Box>
                        )
                    ) : (
                       <Box
                            sx={{
                                // backgroundColor:'greenyellow',
                                width:{ xs:340, md:900 },
                                minHeight:{ xs:340, md:500 },
                                position:'relative',
                                display:'flex',
                                alignItems:'center',
                                overflow:'hidden',
                            }}
                        >
                            <Box
                                component="video"
                                src="https://storage.casaspecsa.com/Promociones/La+magia+de+comprar+tu+casa+Pecsa/otros/fondo+Cc.mp4" 
                                autoPlay={true} 
                                loop={true} 
                                type='video/mp4' 
                                muted 
                                sx={{
                                    // position:'relative',
                                    position:'absolute',
                                    m:'0 auto',
                                    width:{ xs:'180%', md:'100%'},
                                    minHeight:'100%',
                                }}    
                            />
                            <Box
                                sx={{
                                    width:'100%',
                                    height:'100%',
                                    position:'absolute',
                                    m:'0 auto',
                                }}
                            >
                                <Box
                                    sx={{
                                        // backgroundColor:'ghostwhite',
                                        width:'100%',
                                        alignItems:'center'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            // backgroundColor:'goldenrod',
                                            width:{ xs:'100%', sm:'80%', md:'80%' },
                                            m:'0 auto'
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            src="https://storage.casaspecsa.com/Promociones/La+magia+de+comprar+tu+casa+Pecsa/otros/logopromoagosto.svg"
                                            sx={{
                                                width:'100%'
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            // backgroundColor:'goldenrod',
                                            width:{ xs:'100%', sm:'80%', md:'80%' },
                                            m:'0 auto'
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            src="https://storage.casaspecsa.com/Promociones/La+magia+de+comprar+tu+casa+Pecsa/otros/texto.png"
                                            sx={{
                                                width:'100%'
                                            }}
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        width:'100%',
                                        textAlign:'center',
                                    }}
                                >
                                    <BotonParaPromo
                                        color="inherit"
                                        sx={{
                                            width:{ xs:'80%', sm:'40%', md:'40%' },
                                            fontSize:{ xs:38, sm:35, md:35 },
                                        }}
                                        // sx={{
                                        //     backgroundColor:'#5adcc9',
                                        //     color:'#000',
                                        //     // border:4,
                                        //     width:{ xs:'80%', sm:'40%', md:'40%' },
                                        //     fontSize:{ xs:38, sm:35, md:35 },
                                        //     m:2,
                                        //     borderRadius:'12px',
                                        //     textTransform:'none',
                                        //     '&:hover':{
                                        //         backgroundColor:'#5adcc9',

                                        //     }
                                        // }}
                                        onClick={()=>setJugar(true)}
                                    >
                                        <b>Jugar</b>
                                    </BotonParaPromo>
                                </Box>
                            </Box>    
                        </Box>
                    )
                }                
            </Box>
            <ClearRoundedIcon
                sx={{
                    position:'absolute',
                    color:'white',
                    fontSize:70,
                    top:100,
                    right:20,
                }}
                onClick={getOpen}
            />
        </Backdrop>
    );
}

export { PopUpPromocion }