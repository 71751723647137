import { Box, Breadcrumbs, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { ColeccionShorts } from "./ColeccionShorts";
import { ColeccionTodosVideosMiniatura } from "./ColeccionTodosVideosMiniatura";
import { ColeccionVideosMiniatura } from "./ColeccionVideosMiniatura";
import { VideoMiniatura } from "./VideoMiniatura";
import HomeIcon from '@mui/icons-material/Home';
import moment from "moment";
import { Helmet } from "react-helmet";

const VideoBlog = () => {
    const urlPRueba = 'https://www.youtube.com/watch?v=BGGjLG0LAWg';
    const videoPrueba = {
        "kind": "youtube#searchResult",
        "etag": "q8PkD03KX3byP8dQiTxRZLhC08k",
        "id": {
          "kind": "youtube#video",
          "videoId": "qQyyDikNF_I"
        },
        "snippet": {
          "publishedAt": "2022-10-18T14:50:52Z",
          "channelId": "UCFZ7CNDNTWNLewVeTbaDEMg",
          "title": "Comprar casa nunca volverá a ser igual.",
          "description": "El juego cambió, y comprar casa ya no volverá a ser igual! ¿Habías imaginado que la compra de tu casa fuera tan fácil y 100% ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/qQyyDikNF_I/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/qQyyDikNF_I/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/qQyyDikNF_I/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Casas PECSA",
          "liveBroadcastContent": "none",
          "publishTime": "2022-10-18T14:50:52Z"
        }
    }
    const [videos, setVideos] = useState([]);
    const [playlists, setPlaylists] = useState([]);
    const [shorts, setShorts] = useState(null);
    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_API_URL}/api/video-blog/`)
        .then((response) => {
            // handle success            
            console.log(response);
            console.log('success axio');
            setVideos(response.data.videos);
            setPlaylists(response.data.playlists);
            setShorts(response.data.shorts);
        })
        .catch((error) => {
            // handle success
            console.log(error);
        })
        .then(() => {
            // handle success
            console.log('sabe');
        });
    },[setPlaylists, setShorts])
    return(
        <div>
            <Helmet>
                <title>Casas Pecsa | Video Blog</title>
                <meta name="description" content="Un videoblog con todo lo que necesitas saber para la compra más importante de tu vida: tu nueva casa. Encuentra lo mejor en asesoría online, enamórate de nuestros modelos y fraccionamientos a primera vista; además recetas, tips de decoración y mucho más! ¡Esperamos lo disfrutes mucho!" />
            </Helmet>
            <Container>
                <Box sx={{ mt:12 }}>
                    <Box
                        sx={{
                            // backgroundColor:'red',
                            pb:1,
                            pt:0.6,
                            pl:1,
                            color:'#707070',
                            
                        }}
                    >
                        <Breadcrumbs aria-label="breadcrumb">
                            {/* <Link underline="hover" color="inherit">
                                Inicio
                            </Link> */}
                            <Link to={'/'} style={{ color:'#707070', }}>
                                {/* Inicio */}
                                {/* <HomeIcon fontSize="small"/> */}
                                {
                                    window.innerWidth > 591 ? (`Inicio`) : (<HomeIcon fontSize="small" sx={{ color:'#707070' }}/>)
                                }
                            </Link>
                            <Typography sx={{ color:'#F5323F', }}>
                                Video Blog
                            </Typography>
                            
                        </Breadcrumbs>
                    </Box>
                    <Box>
                        <Box
                            sx={{
                                textAlign:'center',
                            }}
                        >
                            <Typography variant="h3" sx={{ color:'#F5323F', }}>
                                <b>Video Blog</b>
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                // backgroundColor:'red',
                                width:'100%',
                                mb:5,
                            }}
                        >
                            <Box
                                component="iframe"
                                src={`https://www.youtube.com/embed/${videoPrueba.id.videoId}?&autoplay=1`}
                                // width='100%'
                                // height={{ xs: 300, sm:400, md:560 }}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowfullscreen={true}
                                sx={{
                                    width:{ xs: '100%', sm:'99.6%', md:'99.6%' },
                                    height:{ xs: 260, sm:400, md:600 },
                                    borderRadius:{ xs:'10px', sm:'12px', md:'16px' },
                                }}
                            />
                            <Box
                                sx={{
                                    // backgroundColor:'yellow',
                                    width:'100%',
                                    display:'flex',
                                    alignItems:'center',
                                    alignContent:'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        // backgroundColor:'white',
                                        width:'99.6%',
                                        // height:139,
                                        m:'0 auto',
                                        color:'#707070',
                                    }}
                                >
                                    <Typography 
                                        variant="h5" 
                                        sx={{
                                            // backgroundColor:'yellow',
                                            width:'100%', 
                                            maxHeight:60, 
                                            textOverflow: 'ellipsis',
                                            overflow:'hidden',
                                            display:'-webkit-box !important',
                                            WebkitLineClamp:2,
                                            WebkitBoxOrient:'vertical',
                                            whiteSpace:'normal',
                                            color:'#F5323F',
                                        }}
                                    >
                                        <b>{ videoPrueba.snippet.title }</b>
                                    </Typography>
                                    <Typography variant="body1">
                                        {/* Hace 2 semanas */}
                                        { moment(videoPrueba.snippet.publishedAt).fromNow() }
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    {/* <Box>
                        <ColeccionVideosMiniatura titulo={'Todos los videos'}videoPrueba={videoPrueba} />
                    </Box> */}
                    {
                        videos && (
                            <ColeccionTodosVideosMiniatura playlist={videos} />
                        )
                    }
                    {
                        shorts && (
                            <ColeccionShorts shorts={shorts}/>
                        )
                    }
                    {
                        playlists && (
                            playlists.map((playlist) => (
                                <Box>
                                    <ColeccionVideosMiniatura titulo={'Creditos'} playlist={playlist} />
                                </Box>
                            ))
                        )
                    }
                    
                </Box>
            </Container>
        </div>
    );
}

export { VideoBlog }