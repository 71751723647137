import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Lista } from "../Lista";

const ListaVendidos = ({ handleFilter }) => {
    const [transacciones, setTransacciones] = useState([]);

    const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');

    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_API_URL}/api/clientes/clientes-vendidos`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((response)=>{
            setTransacciones(response.data.transacciones)
        })
        .catch((error)=>{

        });
    },[]);
    return(
        <Box 
            sx={{
                // backgroundColor:'pink',
                // height:1004,
                overflow:'hidden',
                pb:2
            }}
        >
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography sx={{ fontSize: { xs:25, sm:25, md:25 } }}>
                        Clientes Vendidos
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Lista transacciones={handleFilter(transacciones)} />
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}

export { ListaVendidos }