import { Avatar, Card, CardActions, CardContent, CardHeader, CardMedia, IconButton, ImageList, ImageListItem, List, ListItem, Typography } from "@mui/material";
import { Box } from "@mui/system";

import FavoriteIcon from '@mui/icons-material/Favorite';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import asesorPerfil2 from "../../assets/asesores/asesorPerfil2.png";
import imagenPromo from "../../assets/prueba/imagenPromo.png";
import { useState } from "react";
import { useEffect } from "react";
import { ItemListaPosts } from "./ItemListaPosts";

function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }
const ListaPosts = ({ posts }) => {
    console.log(posts);
    const [fotos, setFotos] = useState([]);

    const getFormatoVariasImagenes = (fotos) => {
        console.log(fotos);
        if(fotos.length === 2){
            return [
                {
                    img: fotos[0].url,
                    title: 'Breakfast',
                    rows: 4,
                    cols: 2,
                },
                {
                    img: fotos[1].url,
                    title: 'Burger',
                    rows: 4,
                    cols: 2,
                },
            ];
        }
        if(fotos.length === 3){
            return [
                {
                    img: fotos[0].url,
                    title: 'Breakfast',
                    rows: 2,
                    cols: 4,
                },
                {
                    img: fotos[1].url,
                    title: 'Burger',
                    rows: 2,
                    cols: 2,
                },
                {
                    img: fotos[2].url,
                    title: 'Burger',
                    rows: 2,
                    cols: 2,
                },
            ];
        }
        if(fotos.length >= 4){
            return [
                {
                    img: fotos[0].url,
                    title: 'Breakfast',
                    rows: 2,
                    cols: 2,
                },
                {
                    img: fotos[1].url,
                    title: 'Burger',
                    rows: 2,
                    cols: 2,
                },
                {
                    img: fotos[2].url,
                    title: 'Burger',
                    rows: 2,
                    cols: 2,
                },
                {
                    img: fotos[3].url,
                    title: 'Burger',
                    rows: 2,
                    cols: 2,
                },
            ];
        }
    }
    // useEffect(()=>{
    //     getFormatoVariasImagenes(posts.fotos)
    // },[posts]);
    return(
        <Box>
            {
                posts.length > 0 ? (
                    <List>
                        {
                            posts.map((post)=>(
                                <ListItem>
                                    <ItemListaPosts post={post} />
                                </ListItem>
                            ))
                        }
                {/* <ListItem>
                    <Box 
                        sx={{ 

                        }}
                    >
                        <Card 
                            sx={{
                                borderRadius:'12px',
                            }}
                        >
                            <CardHeader
                                avatar={
                                    <Avatar 
                                        sx={{ bgcolor: '#707070' }} 
                                        aria-label="recipe" 
                                        variant="rounded" 
                                        src={asesorPerfil2}>
                                        R
                                    </Avatar>
                                }
                                action={
                                    <IconButton aria-label="settings">
                                        <MoreVertIcon />
                                    </IconButton>
                                }
                                title="Nombre del Vendedor"
                                subheader="September 14, 2016"
                            />
                            <CardMedia
                                component="img"
                                // height="194"
                                image={imagenPromo}
                                alt="Paella dish"
                                sx={{
                                    minHeight:'194',
                                }}
                            />
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    This impressive paella is a perfect party dish and a fun meal to cook
                                    together with your guests. Add 1 cup of frozen peas along with the mussels,
                                    if you like.
                                </Typography>
                            </CardContent>
                            <CardActions disableSpacing>
                                <IconButton aria-label="add to favorites">
                                    <FavoriteIcon />
                                </IconButton>
                            </CardActions>
                        </Card>
                    </Box>
                </ListItem>
                <ListItem>
                    <Box 
                        sx={{ 

                        }}
                    >
                        <Card>
                            <CardHeader
                                avatar={
                                    <Avatar 
                                        sx={{ bgcolor: '#707070' }} 
                                        aria-label="recipe" 
                                        variant="rounded" 
                                        src={asesorPerfil2}>
                                        R
                                    </Avatar>
                                }
                                action={
                                <IconButton aria-label="settings">
                                    <MoreVertIcon />
                                </IconButton>
                                }
                                title="Nombre del Vendedor"
                                subheader="September 14, 2016"
                            />
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    This impressive paella is a perfect party dish and a fun meal to cook
                                    together with your guests. Add 1 cup of frozen peas along with the mussels,
                                    if you like.
                                </Typography>
                            </CardContent>
                            <CardActions disableSpacing>
                                <IconButton aria-label="add to favorites">
                                    <FavoriteIcon />
                                </IconButton>
                            </CardActions>
                        </Card>
                    </Box>
                </ListItem>
                <ListItem>
                    <Box 
                        sx={{ 

                        }}
                    >
                        <Card>
                            <CardHeader
                                avatar={
                                    <Avatar 
                                        sx={{ bgcolor: '#707070' }} 
                                        aria-label="recipe" 
                                        variant="rounded" 
                                        src={asesorPerfil2}>
                                        R
                                    </Avatar>
                                }
                                action={
                                <IconButton aria-label="settings">
                                    <MoreVertIcon />
                                </IconButton>
                                }
                                title="Nombre del Vendedor"
                                subheader="September 14, 2016"
                            />
                            <Box
                                sx={{
                                    backgroundColor:'yellow',
                                    width:'100%',
                                }}
                            >
                                <ImageList
                                    sx={{ width: '100%', height: 500 }}
                                    variant="quilted"
                                    cols={4}
                                    rowHeight={121}
                                >
                                    {
                                        itemData.map((item) => (
                                            <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                                                <img
                                                    {...srcset(item.img, 121, item.rows, item.cols)}
                                                    alt={item.title}
                                                    loading="lazy"
                                                />
                                            </ImageListItem>
                                        ))
                                    }
                                </ImageList>
                            </Box>
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    This impressive paella is a perfect party dish and a fun meal to cook
                                    together with your guests. Add 1 cup of frozen peas along with the mussels,
                                    if you like.
                                </Typography>
                            </CardContent>
                            <CardActions disableSpacing>
                                <IconButton aria-label="add to favorites">
                                    <FavoriteIcon />
                                </IconButton>
                            </CardActions>
                        </Card>
                    </Box>
                </ListItem> */}
                    </List>
                ) : (
                    <Box
                        sx={{
                            // backgroundColor:'red',
                            width:'100%',
                            mt:2,
                            textAlign:'center',
                        }}
                    >
                        <Typography variant="h5" sx={{ color:'#F5323F', }}>
                            <b>No hay publicaciones</b>
                        </Typography>
                    </Box>
                )
            }
            
        </Box>
    );
}

export { ListaPosts }

const itemData2 = [
    //2
    // {
    //     img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    //     title: 'Breakfast',
    //     rows: 4,
    //     cols: 2,
    // },
    // {
    //     img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    //     title: 'Burger',
    //     rows: 4,
    //     cols: 2,
    // },
]
const itemData3 = [
    //3
    // {
    //     img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    //     title: 'Breakfast',
    //     rows: 2,
    //     cols: 4,
    // },
    // {
    //     img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    //     title: 'Burger',
    //     rows: 2,
    //     cols: 2,
    // },
    // {
    //     img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    //     title: 'Camera',
    //     rows: 2,
    //     cols: 2,
    // },    
];
const itemData4 = [    
    // > 4
    {
        img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
        title: 'Breakfast',
        rows: 2,
        cols: 2,
    },
    {
        img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
        title: 'Burger',
        rows: 2,
        cols: 2,
    },
    {
        img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
        title: 'Camera',
        rows: 2,
        cols: 2,
    },
    {
        img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
        title: 'Camera',
        rows: 2,
        cols: 2,
    },
];
const itemData4Mas = [    
    // > 4
    {
        img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
        title: 'Breakfast',
        rows: 2,
        cols: 2,
    },
    {
        img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
        title: 'Burger',
        rows: 2,
        cols: 2,
    },
    {
        img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
        title: 'Camera',
        rows: 2,
        cols: 2,
    },
    {
        img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
        title: 'Camera',
        rows: 2,
        cols: 2,
    },
];