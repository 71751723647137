import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { TiendaItem } from "./TiendaItem";
import { TiendaItemList } from "./TiendaItemList";

const TiendaItems = ({ lotes }) => {
    console.log('Sobres views lotes',lotes);
    return(
        <Grid container spacing={2} columnSpacing={{ xs:'none', sm: 2, md: 3, }} >
                {
                    lotes && (
                        lotes.map((lote) => (
                            <Grid item xs={12} sm={4} md={4} >
                                <TiendaItem 
                                    lote={lote} 
                                    masVisitado={
                                        lote.views.length === Math.max(...lotes.map((lote) => lote.views.length))
                                        ? true
                                        : false
                                    }
                                />
                            </Grid>            
                        ))
                    )
                }
            </Grid>
        // <React.Fragment>
            
        //     <Grid container spacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3, }} sx={{ display: { xs:'block', md:'none' } }}>
        //         <Grid item xs={12} md={4}>
        //             <TiendaItemList lotes={lotes}/>
        //         </Grid>
        //     </Grid>
        // </React.Fragment>        
    );
}
export { TiendaItems }