import { Button, Collapse, List, ListItem, ListItemText } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { useState } from "react";

const Experiencia = ({ experiencia }) => {
    const [abrirCollapse, setAbrirCollapse] = useState(false);
    return(
        <Box>
            <Collapse in={abrirCollapse} collapsedSize={150}>
                <List>
                    {
                        experiencia.map((trabajo)=>(
                            <ListItem>
                                <ListItemText
                                    primary={<b>{ trabajo.nombre }</b>}
                                    secondary={`${moment(trabajo.fechaInicio).format('YYYY')} - ${moment(trabajo.fechaInicio).format('YYYY') === moment().format('YYYY') ? 'Actual' : moment(trabajo.fechaInicio).format('YYYY')}`}
                                />
                            </ListItem>
                        ))
                    }
                    {/* <ListItem>
                        <ListItemText
                            primary={<b>Casas Pecsa</b>}
                            secondary="2018 - Actual | 5 años"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={<b>Antiguo Trabajo</b>}
                            secondary="2015 - 2018 | 3 años"
                        />
                    </ListItem> */}
                </List>
            </Collapse>
            {
                experiencia.length > 2 && (
                    <Button 
                        variant="text" 
                        onClick={()=>{ setAbrirCollapse(!abrirCollapse) }}
                        sx={{
                            // backgroundColor:'red',
                            width:'100%',
                            textTransform:'none',
                            color:'#F5323F',
                            '&:hover':{
                                backgroundColor:'#f2f2f2',

                            }
                        }}
                    >
                        <b>Ver Más</b>
                    </Button>
                )
            }
                    
        </Box>
    );
}

export { Experiencia }