import React from "react";
import { Container, Box } from "@mui/material";
import { BenefitsContainer } from "../../../components/BenefitsContainer";
import { CustomTypography } from "../../../components/CustomTypography";
import theme from "../../../App/theme";

const BenefitsSection = () => {
  const benefitsList = [
    {
      img: "https://storage.casaspecsa.com/invierte-renta/beneficios/apreciacion-de-valor.webp",
      title: "Apreciación del valor",
      description: (
        <Box>
          Invertir en{" "}
          <CustomTypography variant="textPrimaryBold" txtColor="primary">
            Casas Pecsa
          </CustomTypography>{" "}
          ofrece{" "}
          <CustomTypography variant="textPrimaryBold">
            ganancias significativas
          </CustomTypography>{" "}
          a largo plazo al aprovechar el constante{" "}
          <CustomTypography variant="textPrimaryBold">
            aumento en el valor
          </CustomTypography>{" "}
          de las propiedades.
        </Box>
      ),
    },

    {
      img: "https://storage.casaspecsa.com/invierte-renta/beneficios/diversificacion-de-cartera.webp",
      title: "Diversificación de tu cartera",
      direction: "row-reverse",
      description: (
        <Box>
          La{" "}
          <CustomTypography variant="textPrimaryBold">
            inversión en bienes raíces
          </CustomTypography>{" "}
          es una excelente manera de diversificar tu cartera,{" "}
          <CustomTypography variant="textPrimaryBold">
            reduciendo el riesgo general de tus inversiones.
          </CustomTypography>
        </Box>
      ),
    },

    {
      img: "https://storage.casaspecsa.com/invierte-renta/beneficios/control-de-inversion.webp",
      title: "Control sobre tu inversión",
      description: (
        <Box>
          Como propietario de una{" "}
          <CustomTypography variant="textPrimaryBold" txtColor="primary">
            Casa Pecsa
          </CustomTypography>
          , tienes el control total para propiedad, lo que te permite{" "}
          <CustomTypography variant="textPrimaryBold">
            maximizar tus ganancias.
          </CustomTypography>
        </Box>
      ),
    },
  ];

  return (
    <Box
      component="section"
      sx={{
        backgroundColor: theme.palette.grey[300],
        py: "2.5rem",
        [theme.breakpoints.up("sm")]: {
          py: "5rem",
        },
      }}
    >
      <Container
        sx={{
          display: "flex",
          textAlign: "center",
          flexDirection: "column",
          gap: 3.75,
        }}
      >
        <CustomTypography variant="subheadingPrimary" txtColor="primary">
          Beneficios de Invertir en Casas Pecsa
        </CustomTypography>

        <BenefitsContainer benefits={benefitsList} />
      </Container>
    </Box>
  );
};

export { BenefitsSection };
