import { Elements } from "@stripe/react-stripe-js";
import {loadStripe} from '@stripe/stripe-js';
import axios from "axios";
import React, { useEffect, useState } from "react";
import { PayFormAll } from "./PayFormAll";
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import { Box } from "@mui/system";
import TagManager from "react-gtm-module";
import { CircularProgress, Typography } from "@mui/material";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`)

const PortaPayFormAll = ({ lote, cliente, asesor }) => {
    const [clientSecret, setClientSecret] = useState(null);
    const [transaccion, setTransaccion] = useState(null);
    const [loteDisponible, setLoteDisponible] = useState(true);

    const options = {
        // passing the client secret obtained in step 2
        //clientSecret: 'pi_3LT7R5FmRKo9q8Cv0Aj645v0_secret_ku6boDGacHHuGXCwrJDVRqF9h',
        clientSecret: clientSecret,
        // Fully customizable with appearance API.
        // appearance: {/*...*/},
    };

    const getFormatoMoneda = (cantidad) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD', //MXN
            minimumFractionDigits: 0
        })
    
        var value = 10000
    
        return formatter.format(cantidad)
    }

    const [promocion, setPromocion] = useState(null);
    const [precioFinal, setPrecioFinal] = useState(null)

    const findPromocion = (lote) => {
        const promocionResult = {};
        if(lote.promocion){
            setPromocion(lote.promocion);
        }else{
            if(lote.manzana.promocion){
                setPromocion(lote.manzana.promocion);
            }else{
                if(lote.manzana.etapa.promocion){
                    setPromocion(lote.manzana.etapa.promocion);
                }else{
                    if(lote.manzana.etapa.fraccionamiento.promocion){
                        setPromocion(lote.manzana.etapa.fraccionamiento.promocion);
                    }else{
                        if(lote.fachada.modelo.promocion){
                            setPromocion(lote.fachada.modelo.promocion);
                        }
                    }
                }
            }
        }
    }
    const aplicaPromocion = (lote, promocion) => {
        if(promocion.cantidadADescontar){
            setPrecioFinal(lote.precio - promocion.cantidadADescontar);
        }
        if(promocion.porcentajeADescontar){
            setPrecioFinal(lote.precio - (lote.precio * (promocion.porcentajeADescontar/100)));
        }

    }

    const addTagManager = (datosTransaccion) => {
        TagManager.dataLayer({
            dataLayer: {
                event:'eventoTransaccionDatosPersonales',
                activar:true,
                datos:{
                    transaccion: {
                        folio: datosTransaccion.transaccion.folio,
                        folioPasarela: datosTransaccion.transaccion.idMedioPago,
                        estatus:'pendiente',
                    },                        
                    lote:{
                        lote:lote.numeroDeLote,
                        manzana:lote.manzana.nombre,
                        fraccionamiento: lote.manzana.etapa.fraccionamiento.nombre,                        
                        modelo:lote.fachada.modelo.nombre,
                        fachada:lote.fachada.nombre,
                        folio:lote.folio,
                    },                                                
                    datos_persona: {
                        nombre:cliente.datos.primerNombre,
                        apellidoPaterno:cliente.datos.primerApellido,
                        apellidoMaterno:cliente.datos.segundoApellido,
                        email:cliente.autenticacion.email,
                        telefono:cliente.autenticacion.telefono,
                    },
                }
            }
        })
    }

    useEffect(()=>{
        // const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        const token = localStorage.getItem('token') && localStorage.getItem('type') ? localStorage.getItem('type') === 'cliente' ? localStorage.getItem('token') : null : null;
        //const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');
        const folio = localStorage.getItem('id') && token ? localStorage.getItem('id') : null;

        //console.log(folio);
        
        axios.post(`${process.env.REACT_APP_API_URL}/api/venta/transaccion/`,{
            idLote: lote.id,
            idCliente: cliente.id,
            idAsesor: asesor,
            description:  `Separacion de Lote: ${lote.numeroDeLote} Manzana: ${lote.manzana.nombre} en Fraccionamiento: ${lote.manzana.etapa.fraccionamiento.nombre} Modelo: ${lote.fachada.modelo.nombre},Precio:${lote.precio} por parte de: ${cliente.datos.primerNombre ? (cliente.datos.primerNombre + ' '):''}${cliente.datos.segundoNombre ? (cliente.datos.segundoNombre + ' '):''}${cliente.datos.primerApellido ? (cliente.datos.primerApellido + ' '):''}${cliente.datos.segundoApellido ? (cliente.datos.segundoApellido + ' '):''} `,
        }
        // {
        //     headers: { 
        //     Authorization: `Bearer ${token}`,
        //     }              
        // }
        )
        .then((response) => {
            // handle success
            console.log(response);
            // options.clientSecret = response.data.client_secret;
            // console.log(options);
            setClientSecret(response.data.client_secret);
            console.log(options);
            setTransaccion(response.data.transaccion);
            //setLote(response.data.transaccion.lote);
            findPromocion(response.data.transaccion.lote);
            addTagManager(response.data);
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigation(-1);
            setLoteDisponible(false);
        })
        .then(() => {
            // handle success
            //console.log('sabe');
        });
    },[]);
    return(
        <React.Fragment>
            {
                loteDisponible ? (
                    (options && clientSecret) ? (
                        <Elements stripe={stripePromise} options={options}>
                            <PayFormAll lote={lote} transaccion={transaccion} promocion={promocion} />
                        </Elements>
                    ) : (
                        <Box
                            sx={{
                                // backgroundColor:'greenyellow',
                                width:'100%',
                                height:200,
                                display:'flex',
                                alignItems:'center',
                                alignContent:'center'
                            }}
                        >
                            <Box 
                                sx={{ 
                                    // backgroundColor:'pink',                            
                                    m:'0 auto', 
                                    textAlign:'center',
                                }}
                            >
                                <CircularProgress sx={{ m:'0 auto', color:'#F5323F' }} />
                                <Typography>
                                    <b>Cargando...</b>
                                </Typography>
                                <Typography>
                                    <b>Espere un momento, por favor.</b>
                                </Typography>
                            </Box>
                        </Box>
                    )
                ) : (
                    <Box
                        sx={{
                            mt:12,
                            textAlign:'center',
                            color: '#f5323f',
                        }}
                    >
                        <ProductionQuantityLimitsIcon sx={{ fontSize:100 }}/>
                        <h1>Casa no disponible</h1>
                    </Box>
                )
            }
        </React.Fragment>
    );
}

export { PortaPayFormAll }