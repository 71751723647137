import { Box } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Helmet } from "react-helmet";

const Estrenaton = () => {
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down('md'));

    return(
        <Box
            sx={{
                // backgroundColor:'pink',
                width:'100%',
                minHeight: 800,
                // pt:12
            }}
        >
            <Helmet>
                <title>{ `Estrenatón Sab 21 Oct 9am - 7pm | Casas Pecsa` }</title>
                <meta name="description" content="¡Llego a Saltillo el fin de semana más barato para comprar tu casa! Este sábado 21 de octubre asiste al Estrenaton de Casas Pecsa y no dejes pasar el día más barato para comprar tu nueva casa con promociones nunca antes vistas. Visita los fraccionamientos Bosques de las Lomas, Lomas del Morillo y Altavista Residencial." />
            </Helmet>
            <iframe
                // src="https://estrenaton2023.wixsite.com/estrenaton-casaspecs"
                src="https://estrenaton2023.wixsite.com/estrenaton-casaspecs?siteRevision=79"
                style={{
                    // backgroundColor:'coral',
                    width:'100%',
                    minHeight: small ? 850 /*10650*/ : 6800,
                    border:'0px solid black',
                    margin:'0px',
                    padding:'0px',
                }}
                allowFullScreen
                scrolling={small ? "auto" :"no"}
            />
        </Box>
    );
}

export { Estrenaton }