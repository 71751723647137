import { Typography } from "@mui/material";
import { Box } from "@mui/system";

const ShortItem = ({ video }) => {
    return(
        <Box
            sx={{
                // backgroundColor:'green',
                width:'100%',                
                // mb:2,
                cursor:'pointer',
            }}
        >
            <Box
                sx={{
                    backgroundColor:'yellow',
                    width:'100%',
                    height:{xs:230, md:260},
                    // m:'0 auto',
                    display:'flex',
                    alignItems:'center',
                    alignContent:'center',
                    overflow:'hidden',
                    borderRadius:'12px',
                    position: 'relative',
                }}
            >
                {/* <Box
                    sx={{
                        backgroundColor:'white',
                        // m:'0 auto',
                        // minWidth:'480px',
                        minWidth:'160%',
                        textAlign:'center',
                        position:'relative',
                        left:'-50%',
                    }}
                >
                    @@@
                </Box> */}
                <Box
                    component='img'
                    src={video.imagenHigh}
                    sx={{
                        minHeight:'100%',
                        maxHeight:'110%',
                        m:'0 auto',
                        position:'relative',
                        left:{xs:'-59%', sm:'-59%', md:'-52%'},
                    }}
                />
            </Box>
            {/* <Box
                sx={{
                    backgroundColor:'yellow',
                    width:'100%',
                    display:'flex',
                    alignItems:'center',
                    alignContent:'center',
                }}
            >
                <Box
                    sx={{
                        backgroundColor:'white',
                        width:'100%',
                        height:260,
                        m:'0 auto',
                        display:'flex',
                        alignItems:'center',
                        alignContent:'center',
                        overflow:'hidden',
                        borderRadius:'12px',
                    }}
                >
                    
                    <Box
                        component='img'
                        src={video.imagenHigh}
                        sx={{
                            minWidth:'100%',
                            maxHeight:'105%',
                            m:'0 auto',
                        }}
                    />
                </Box>
            </Box> */}
            <Box
                sx={{
                    // backgroundColor:'yellow',
                    width:'100%',
                    display:'flex',
                    alignItems:'center',
                    alignContent:'center',
                }}
            >
                <Box
                    sx={{
                        // backgroundColor:'white',
                        width:'97%',
                        // height:139,
                        m:'0 auto',
                        color:'#707070',
                    }}
                >
                    <Typography 
                        variant="body2" 
                        sx={{
                            // backgroundColor:'red',
                            width:'100%', 
                            height:43, 
                            textOverflow: 'ellipsis',
                            overflow:'hidden',
                            display:'-webkit-box !important',
                            WebkitLineClamp:2,
                            WebkitBoxOrient:'vertical',
                            whiteSpace:'normal',
                            color:'#F5323F',
                        }}
                    >
                        <b>{ video.titulo }</b>
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export { ShortItem }