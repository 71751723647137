import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { useEffect } from 'react';
import { Box, Container, Grid } from '@mui/material';
import { FotosComponent } from '../../../components/Fotos/FotosComponent';
import { CardContactInvestment } from '../../../components/Investment/CardContactInvestment';

const SimulationContainer = ({ children, datosLote = [] }) => {
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down('md'));

    const [fotos, setFotos] = useState([]);

    const addFoto = (newFoto) => {
        setFotos(oldFoto=>[...oldFoto, ...newFoto])
    }

    useEffect(()=>{
        // console.log(datosLote);
        if(datosLote){
            // console.log('orale');
            setFotos([]);
            addFoto(datosLote.lote.fotos.map(foto => ({ id:foto.id, url:foto.url })));
            addFoto(datosLote.lote.fachada.modelo.interiores.map(interior => ({ id:interior.id, url:interior.imagen })));
        }
    },[datosLote]);
    // console.log(datosLote);
    if(datosLote){    
        return(
            <Box>
                <Container sx={{ p:{ xs:'0 16px 0 16px', sm:0, md:0, }, }}>
                    <Box sx={{ mt:{ xs:10, sm:8, md:15 }, mb:{ xs:8, sm:8, md:9 }, }}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12}>
                                {
                                    small && (
                                        <Box
                                            sx={{
                                                // backgroundColor:'purple',
                                                width:'100%',
                                                height:350
                                            }}
                                        >
                                            {
                                                fotos.length > 0 && (
                                                    <FotosComponent fotos={fotos}/>
                                                )
                                            }
                                        </Box>
                                    )
                                }
                            </Grid>
                            <Grid item xs={12} sm={8} md={8}>
                                { children }
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                {
                                    !small && (
                                        <Box
                                            sx={{
                                                // backgroundColor:'gray',
                                                width:'100%',
                                                position:'sticky',
                                                top: 120,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    // backgroundColor:'purple',
                                                    width:'100%',
                                                    height:350
                                                }}
                                            >
                                                {
                                                    fotos.length > 0 && (
                                                        <FotosComponent fotos={fotos}/>
                                                    )
                                                }
                                            </Box>
                                            <Box
                                                sx={{
                                                    // backgroundColor:'green',
                                                    width:'100%',
                                                    // height:350
                                                }}
                                            >
                                                <CardContactInvestment/>
                                            </Box>
                                        </Box>
                                    )
                                }                                        
                            </Grid>
                        </Grid>
                    </Box>
                    {
                        small && (
                            <Box
                                sx={{
                                    // backgroundColor:'purple',
                                    // height:200,
                                    position:'sticky',
                                    bottom:0,
                                }}
                            >
                                <CardContactInvestment/>
                            </Box>
                        )
                    }
                </Container>
            </Box>
        );
    }
}

export { SimulationContainer }