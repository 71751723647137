import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { useState } from "react";

const stylePecsaInputs = {
    "& label.Mui-focused": {
        color: "#f5323f"
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#d9d9d9"
    },
    "& .MuiOutlinedInput-root": {
        // '& fieldset': {
        //   borderColor: 'black',
        // },
        "&:hover fieldset": {
            borderColor: "#d9d9d9"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#d9d9d9"
        }
    }
}

const CustomInputPassword = ({ id = "outlined-adornment-password", label = "Contraseña", name = "password", handleChange, required = false, endAdornment, autoFocus = false }) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return(
        <FormControl required={required} sx={{...stylePecsaInputs, width:'100%', mt:2}}>
                <InputLabel htmlFor={id}>{ label }</InputLabel>
                <OutlinedInput
                    id={id}
                    name={name}                    
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            { endAdornment }
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                { showPassword ? <VisibilityOff /> : <Visibility /> }
                            </IconButton>
                        </InputAdornment>
                    }
                    autoComplete="current-password"
                    label={label}
                    onChange={(e)=>handleChange(e.target.value)}
                    autoFocus={autoFocus}
                />
            </FormControl>
    );
}

export { CustomInputPassword }