import axios from "axios";
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation, Navigate, Link, useParams } from "react-router-dom";
import { Alert, Avatar, Backdrop, Button, Checkbox, Container, Divider, FormControlLabel, Grid, Snackbar, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system";

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useState } from "react";

import CircularProgress from '@mui/material/CircularProgress';

import logo from '../../assets/logo.svg'
import { CustomInputPassword } from "../../components/CustomInputPassword";

const stylePecsaInputs = {
    "& label.Mui-focused": {
        color: "#f5323f"
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#d9d9d9"
    },
    "& .MuiOutlinedInput-root": {
        // '& fieldset': {
        //   borderColor: 'black',
        // },
        "&:hover fieldset": {
            borderColor: "#d9d9d9"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#d9d9d9"
        }
    }
}

const CambiarPassword = () => {
    let navigate = useNavigate();
    let location = useLocation();

    let { idtoken } = useParams();

    console.log(idtoken);

    const [open, setOpen] = useState(false);
    const [openExpiro, setOpenExpiro] = useState(false);

    const [cargando, setCargando] = useState(false);

    const [MsgRecoveryPasswordState, setMsgRecoveryPasswordState] = useState(false);

    const MostarAlert = () => {
        setOpen(true);
    };
    
    const CerrarAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
    };
    const MostarAlertExpiro = () => {
        setOpenExpiro(true);
    };
    
    const CerrarAlertExpiro = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenExpiro(false);
    };
    const change = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if(data.get('newPassword') !== data.get('confirmPassword')){
            MostarAlert();
            return;
        }
        setCargando(true);
        console.log({
            token: idtoken,
            password: data.get('newPassword'),
            confirmPassword: data.get('confirmPassword'),
        });
        axios.post(`${process.env.REACT_APP_API_URL}/api/clientes/changePassword`,{
            token: idtoken,
            password: data.get('newPassword'),
            confirmPassword: data.get('confirmPassword'),
        })
        .then((response) => {
            // handle success            
            console.log(response.data);
            if(response.data.passwordChanged){
                setMsgRecoveryPasswordState(true);
                setCargando(false);
                //navigate('/login');            
            }            
            //navigate(`/cargas/inventario/${fraccionamiento.folio.toString()}`, {state:{fraccionamiento:fraccionamiento}});
            //navigate(-1, { replace: true });
        })
        .catch((error) => {
            // handle success
            console.log(error);
            setCargando(false);
            MostarAlertExpiro();
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sabe');
        });
    }
    return(
        <Box >
            <Container sx={{ mt: { xs:8, md:15 }, minHeight:830, }}>
                <Grid 
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    
                >
                    <Grid item md={6}>
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                component='img'
                                src={logo}
                            />
                            {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                <LockOutlinedIcon />
                            </Avatar> */}
                            <Typography component="h1" variant="h5" sx={{ color: '#707070' }}>
                                Restablecer Contraseña
                            </Typography>
                            
                            {
                                MsgRecoveryPasswordState ? (
                                    <Typography component="h5" sx={{ color: '#038926', textAlign:'center' }}>
                                        Tu contraseña ah sido cambiada con exito!
                                    </Typography>
                                ) 
                                : (
                                    <Typography component="body2"  sx={{ color: '#707070', textAlign:'center' }}>
                                        Ingresa tu nueva contraseña
                                    </Typography>
                                )
                            }
                            {
                                !MsgRecoveryPasswordState && (
                                    <Box component="form" onSubmit={change} noValidate sx={{ mt: 1 }}>
                                        {/* <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="newPassword"
                                            label="Nueva Contraseña"
                                            type="password"
                                            id="newPassword"
                                            autoComplete="current-password"
                                            sx={{ ...stylePecsaInputs }}
                                        /> */}
                                        <CustomInputPassword 
                                            id="newPassword"
                                            name="newPassword"
                                            label="Nueva Contraseña"
                                            required
                                        />
                                        {/* <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="confirmPassword"
                                            label="Confirma Contraseña"
                                            type="password"
                                            id="confirmPassword"
                                            autoComplete="current-password"
                                            sx={{ ...stylePecsaInputs }}
                                        /> */}
                                        <CustomInputPassword
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            label="Confirma Contraseña"
                                            required
                                        />
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="outlined" 
                                            color={MsgRecoveryPasswordState ? "success": "inherit"}
                                            sx={{ /*fontSize:'11px',*/ 
                                                mt: 3,
                                                mb: 2,
                                                //width: { xs:'95%', sm:'90%', md:'100%' }, 
                                                p:{ xs:1, sm:1, md:1 },
                                                borderColor: (MsgRecoveryPasswordState ? '038926':'#F5323F') ,
                                                color: (MsgRecoveryPasswordState ? '038926':'#F5323F'),
                                                borderWidth:3,
                                            }}
                                            disabled={MsgRecoveryPasswordState ? true : false}
                                        >
                                            <b>Restablecer Contraseña</b>
                                        </Button>
                                        {/* <Divider>ó</Divider>*/}
                                    </Box>
                                )
                            }
                            {
                                MsgRecoveryPasswordState && (
                                    <Button                                
                                        variant="outlined" 
                                        color="inherit" 
                                        sx={{ /*fontSize:'11px',*/ 
                                            mt: 3,
                                            mb: 2,
                                            width: { xs:'95%', sm:'90%', md:'80%' }, 
                                            p:{ xs:1, sm:1, md:1 },
                                            borderColor:'#707070',
                                            color:'#707070',
                                            borderWidth:2,
                                        }}
                                        onClick={()=>{ navigate('/login'); }}
                                    >
                                        Iniciar Sesion
                                    </Button>
                                )
                            }                             
                        </Box>
                    </Grid>
                </Grid>
                
                <Snackbar open={open} autoHideDuration={6000} onClose={CerrarAlert}>
                    <Alert onClose={CerrarAlert} severity="error" sx={{ width: '100%' }}>
                        Contraseñas no coinciden.
                    </Alert>
                </Snackbar>
                <Snackbar open={openExpiro} autoHideDuration={6000} onClose={CerrarAlertExpiro}>
                    <Alert onClose={CerrarAlertExpiro} severity="error" sx={{ width: '100%' }}>
                        El enlace de restablecer contraseña expiro!
                    </Alert>
                </Snackbar>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={cargando}
                    //onClick={handleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        </Box>
    );
}

export { CambiarPassword }