import { Box } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";

const Base = (props) => {
    let navigate = useNavigate();
    //const [personajes, setPersonajes] = useState(props.datos.personajes || []);

    return(
        <Box >            
            <Outlet/>
        </Box>
    );
}

export { Base }