import { Avatar, Badge, Chip, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider, PickersDay, StaticDatePicker } from "@mui/x-date-pickers";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";

const horas = [
    {
        mostrar:'11:00',
        interno:{
        	horaInicio:'11:00:00',
        	horaFinal:'12:00:00',
        },
    }, 
    {
        mostrar:'12:00',
        interno:{
        	horaInicio:'12:00:00',
        	horaFinal:'13:00:00',
        },
    }, 
    {
        mostrar:'13:00',
        interno:{
        	horaInicio:'13:00:00',
        	horaFinal:'14:00:00',
        },
    }, 
    {
        mostrar:'14:00',
        interno:{
        	horaInicio:'14:00:00',
        	horaFinal:'15:00:00',
        },
    }, 
    {
        mostrar:'15:00',
        interno:{
        	horaInicio:'15:00:00',
        	horaFinal:'16:00:00',
        },
    }, 
    {
        mostrar:'16:00',
        interno:{
        	horaInicio:'16:00:00',
        	horaFinal:'17:00:00',
        },
    }, 
    {
        mostrar:'17:00',
        interno:{
        	horaInicio:'17:00:00',
        	horaFinal:'18:00:00',
        },
    }, 
    {
        mostrar:'18:00',
        interno:{
        	horaInicio:'18:00:00',
        	horaFinal:'19:00:00',
        },
    }, 
    {
        mostrar:'19:00',
        interno:{
        	horaInicio:'19:00:00',
        	horaFinal:'20:00:00'
        },
    },
]
const CalendarioAgenda = ({ asesor, getDia, }) => {
    const [fraccionamientos, setFraccionamientos] = useState([]);
    const [fecha, setFecha] = useState(null);

    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_API_URL}/api/fraccionamiento/datos-extra`)
        .then((response) => {
            // handle success
            console.log(response);
            setFraccionamientos(response.data);
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            // console.log('sae');
        });
    },[asesor]);

    const renderAlgo = (date, selectedDates, pickersDayProps) => {
        // console.log(date)
        const dia = asesor.horario.find((element)=>date.format('DD-MM-YYYY') === moment(element.fecha, 'YYYY-MM-DD').format('DD-MM-YYYY') && moment(element.fecha, 'YYYY-MM-DD').format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') );
        const citasDelDia = asesor.citas.map((cita) => moment(cita.fecha, 'YYYY-MM-DD').format('DD-MM-YYYY') === moment(dia.fecha, 'YYYY-MM-DD').format('DD-MM-YYYY') );
        if(dia){
            // console.log('hay dia',dia);
            if(fraccionamientos.length > 0){
                const fraccionamiento = fraccionamientos.find((fracc) => fracc.idFraccionamiento === dia.fraccionamiento.id);
                // console.log('ahhh', fraccionamiento);
                return (
                    <Badge badgeContent={citasDelDia.length} color="error">
                        <PickersDay
                            color="error" 
                            sx={{ 
                                backgroundColor:fraccionamiento.color,
                                fontWeight:'bold',
                                // color:'#262626',
                                color:'white',
                                '&:hover, &:focus': {
                                    backgroundColor: fraccionamiento.hover,
                                    color:'white',
                                },              
                            }} 
                            // onClick={()=>getDia(moment(dia.fecha, 'YYYY-MM-DD').format('DD-MM-YYYY'))}
                            onClick={()=>getDia(dia)}
                            {...pickersDayProps} 
                        />
                    </Badge>
                    
                ); 
            }           
        }

        return (
            <PickersDay 
                {...pickersDayProps} 
            />
        );
        
    }

    return(
        <Box>
            <Grid container>
                <Grid item xs={12} sm={6} md={6}>                    
                    <Box
                        sx={{
                            // backgroundColor:'red',
                            width:'100%',
                            color:'#707070',
                        }}
                    >
                        <Box>
                            <Typography variant="h5" sx={{ color:'#F5323F' }}>
                                <b>Agenda del asesor</b>
                            </Typography>
                            <Typography variant="body1">
                                Aqui podras ver tu horario y las citas del dia.
                            </Typography>
                        </Box>
                        {
                            fraccionamientos.length > 0 && (
                                fraccionamientos.map((fraccionamiento) => {
                                    if(asesor.horario.find((guardia)=>guardia.fraccionamiento.id === fraccionamiento.idFraccionamiento)){
                                        return(
                                            <Chip
                                                avatar={<Avatar alt={fraccionamiento.fraccionamiento.nombre} src={fraccionamiento.fraccionamiento.logo} />}
                                                label={<b>{ fraccionamiento.fraccionamiento.nombre }</b>}
                                                variant="outlined"
                                                sx={{
                                                    mt:0.5,
                                                    color: fraccionamiento.color,
                                                    borderColor:fraccionamiento.color,
                                                    '&:hover':{
                                                        color: fraccionamiento.hover,
                                                        borderColor:fraccionamiento.hover,
                                                    }
                                                }}
                                            />
                                        )
                                    }
                                })
                            )
                        }                            
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}> 
                    {
                        fraccionamientos.length > 0 && (
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
                                <StaticDatePicker
                                    displayStaticWrapperAs="desktop"
                                    openTo="day"
                                    // views={['day', 'month']}        
                                    // showDaysOutsideCurrentMonth                
                                    // disablePast
                                    value={fecha}
                                    onChange={(newValue) => {
                                        setFecha(newValue);
                                    }}
                                    shouldDisableDate={(day) =>{
                                        // console.log(day.format('DD-MM-YYYY'));
                                        // console.log(horario.find((element)=>
                                        //   day.format('DD-MM-YYYY')===moment(element.fecha).format('DD-MM-YYYY') && element.idFraccionamiento === 1
                                        // ));
                                        // console.log(horario.map((element)=>element.fecha));
                                        // if(horario.find((element)=>day.format('DD-MM-YYYY') === moment(element.fecha).format('DD-MM-YYYY') && element.idFraccionamiento === 1)){
                                        // const hres = horario.find((element)=>day.format('DD-MM-YYYY') === moment(element.fecha).format('DD-MM-YYYY'))
                                        const hres = asesor.horario.find((element)=>{
                                            if(day.format('DD-MM-YYYY') === moment(element.fecha, 'YYYY-MM-DD').format('DD-MM-YYYY')){
                                                // console.log('dia',day.format('DD-MM-YYYY'))
                                                // console.log('element',moment(element.fecha).format('DD-MM-YYYY'))
                                                return element
                                            }                        
                                        })
                                        if(hres){
                                            // console.log('',hres)
                                            return false;
                                        }else{
                                            return true;
                                        }                    
                                    }}
                                    renderDay={renderAlgo}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>        
                        )
                    }                   
                    
                </Grid>
            </Grid>
        </Box>
    );
}

export { CalendarioAgenda }