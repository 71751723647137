import {
  Alert,
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Link,
  Snackbar,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { CustomTypography } from "../../components/CustomTypography";
import { CustomButton } from "../../components/CustomButton";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import theme from "../../App/theme";
import { Helmet } from "react-helmet";

const LoteParaRentaContacto = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // console.log('params',searchParams.get('lote'));
  const [primerNombre, setPrimerNombre] = useState(null);
  const [segundoNombre, setSegundoNombre] = useState(null);
  const [primerApellido, setPrimerApellido] = useState(null);
  const [segundoApellido, setSegundoApellido] = useState(null);
  const [email, setEmail] = useState(null);
  const [telefono, setTelefono] = useState(null);
  const [idLote, setIdLote] = useState(false);
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const [alert, setAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState("success");

  const styleAlert = {
    ...(typeAlert === "success" && {
      backgroundColor: "#8fd693",
      color: "#2E7D32",
    }),
    ...(typeAlert === "error" && {
      backgroundColor: "#edabab",
      color: "#D32F2F",
    }),
  };

  const getIdLote = () => {
    if (!searchParams.get("lote")) {
      setIdLote(null);
      return;
    }
    if (isNaN(searchParams.get("lote"))) {
      setIdLote(null);
      return;
    }
    setIdLote(parseInt(searchParams.get("lote")));
  };

  const getChecked = (event) => {
    setChecked(event.target.checked);
  };

  const getAlert = () => {
    setAlert(!alert);
  };

  const checkDatosPersonales = () => {
    console.log("hey");
    if (
      primerNombre &&
      primerApellido &&
      segundoApellido &&
      email &&
      telefono &&
      checked
    ) {
      return false;
    } else {
      return true;
    }
  };
  const stylePecsaMoffin = {
    "& label.Mui-focused": {
      color: "#f5323f",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#d9d9d9",
    },
    "& .MuiOutlinedInput-root": {
      // '& fieldset': {
      //   borderColor: 'black',
      // },
      backgroundColor: "#FFF",
      "&:hover fieldset": {
        borderColor: "#d9d9d9",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#d9d9d9",
      },
    },
  };

  const enviar = (event) => {
    event.preventDefault();
    if (checked) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/interesados-renta`, {
          primerNombre,
          ...(segundoNombre && { segundoNombre: segundoNombre }),
          primerApellido,
          segundoApellido,
          email,
          telefono,
          ...(idLote && { idLote: idLote }),
        })
        .then((response) => {
          console.log(response);
          getAlert();
          setTypeAlert("success");
          // getEnviado();
          // setLoadingEnviado(false);
        })
        .catch((error) => {
          // handle success
          console.log(error);
          getAlert();
          setTypeAlert("error");
        })
        .then(() => {
          // handle success
          //console.log('sabe');
        });
    }
  };
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    // console.log('cambio, searchParams');
    getIdLote();
  }, [searchParams]);
  useEffect(() => {
    setDisabled(checkDatosPersonales());
  }, [primerNombre, primerApellido, segundoApellido, email, telefono, checked]);
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Contactar - Renta Casa | Casas Pecsa</title>
        <meta
          name="description"
          content="Encuentra Casa en Saltillo, ¿No sabes cuál es tu mejor opción de crédito para comprar una casa? Nosotros te ayudamos. Agenda tu cita y obtén Asesoría Gratis."
        />
      </Helmet>
      <Box
        sx={{
          backgroundImage:
            'url("https://storage.casaspecsa.com/invierte-renta/ventajas/ventajas-img-fondo.webp")',
          backgroundSize: "cover",
          backgroundPosition: "center center",
          minHeight: "calc(100vh - 12.5rem)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: "6.25rem",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.35)",
        }}
      >
        <Container>
          <Box
            sx={{
              // backgroundColor:'pink',
              width: "100%",
            }}
          >
            <Box
              sx={{
                // backgroundColor:'greenyellow',
                background: "rgba(255, 255, 255, 0.43)",
                borderRadius: "16px",
                boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                backdropFilter: "blur(6.1px)",
                "-webkit-backdrop-filter": "blur(6.1px)",
                border: "1px solid rgba(255, 255, 255, 0.3)",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "23px",
              }}
            >
              <Box
                sx={{
                  // backgroundColor:'purple',
                  width: { xs: "95%", sm: "95%", md: "60%" },
                }}
                component="form"
                onSubmit={enviar}
              >
                <Grid container spacing={{ xs: 2, sm: 1, md: 2 }}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box
                      sx={{
                        textAlign: "center",
                        mt: 3,
                        mb: 2,
                      }}
                    >
                      <CustomTypography
                        variant="subheadingSecondary"
                        txtColor="primary"
                      >
                        ¡Regístrate para encontrar tu hogar ideal!
                      </CustomTypography>
                    </Box>
                    <Box
                      sx={{
                        // backgroundColor:'pink',
                        mt: 2,
                      }}
                    >
                      <CustomTypography variant="textPrimary">
                        Obtén información sobre nuestras propiedades en renta y
                        permite que los dueños de las casas se pongan en
                        contacto contigo directamente. Tu búsqueda personalizada
                        comienza aquí.
                      </CustomTypography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      id="outlined-basic"
                      type="text"
                      label="Primer Nombre"
                      variant="outlined"
                      name="primerNombre"
                      sx={{ ...stylePecsaMoffin, width: "100%" }}
                      defaultValue={primerNombre}
                      onChange={(e) => setPrimerNombre(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      id="outlined-basic"
                      type="text"
                      label="Segundo Nombre"
                      variant="outlined"
                      name="segundoNombre"
                      sx={{ ...stylePecsaMoffin, width: "100%" }}
                      defaultValue={segundoNombre}
                      onChange={(e) => setSegundoNombre(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      id="outlined-basic"
                      type="text"
                      label="Primer Apellido"
                      variant="outlined"
                      name="primerApellido"
                      sx={{ ...stylePecsaMoffin, width: "100%" }}
                      defaultValue={primerApellido}
                      onChange={(e) => setPrimerApellido(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      id="outlined-basic"
                      type="text"
                      label="Segundo Apellido"
                      variant="outlined"
                      name="segundoApellido"
                      sx={{ ...stylePecsaMoffin, width: "100%" }}
                      defaultValue={segundoApellido}
                      onChange={(e) => setSegundoApellido(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      id="outlined-basic"
                      type="email"
                      label="Correo Electrónico"
                      variant="outlined"
                      name="email"
                      sx={{ ...stylePecsaMoffin, width: "100%" }}
                      defaultValue={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      id="outlined-basic"
                      type="phone"
                      label="Número Celular"
                      variant="outlined"
                      name="telefono"
                      sx={{ ...stylePecsaMoffin, width: "100%" }}
                      defaultValue={telefono}
                      onChange={(e) => setTelefono(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={checked} onChange={getChecked} />
                      }
                      label={
                        <CustomTypography variant="textTertiary">
                          <b>Aceptar</b> He leído y acepto los{" "}
                          <Link
                            href="https://storage.casaspecsa.com/invierte-renta/Pol%C3%ADtica+de+Privacidad+-+Invierte+en+Casas+Pecsa+y+Renta+una+Casa+Pecsa.pdf"
                            target="_blank"
                          >
                            Términos y Condiciones
                          </Link>{" "}
                          y entiendo que mis datos personales serán utilizados
                          únicamente con el propósito de facilitar la búsqueda y
                          alquiler de propiedades.
                        </CustomTypography>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box
                      sx={{
                        // backgroundColor:'pink',
                        textAlign: "center",
                        mb: 2,
                      }}
                    >
                      <CustomButton
                        text="Enviar"
                        variant="contained"
                        color="primary"
                        disabled={disabled}
                        type="submit"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Snackbar
        open={alert}
        autoHideDuration={6000}
        onClose={getAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={getAlert}
          severity={typeAlert}
          sx={{ width: "100%", ...styleAlert }}
        >
          <CustomTypography variant="textPrimary">
            {typeAlert === "success" && "!Tus datos se han enviado con exito!"}
            {typeAlert === "error" &&
              "Error! No se han podido enviar tus datos."}
          </CustomTypography>
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export { LoteParaRentaContacto };
