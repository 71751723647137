import { Route, Routes, useNavigate } from "react-router-dom";
import { Button, Card, CardActionArea, CardContent, CardMedia, Container, FormControl, Grid, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Carousel from "react-material-ui-carousel";
import moment from "moment";
// import 'moment/locale/es';
import { ItemCalendarioSemanaAsesores } from "./ItemCalendarioSemanaAsesores";
import { useEffect } from "react";


// moment().locale("es")

const CalendarioSemanaAsesores = ({ asesores }) => {
    let navigate = useNavigate();
    console.log(asesores);

    const dosSemanas = [
        {
            fechaInicio: moment().startOf('isoweek'),
            fechaFinal: moment().endOf('isoweek'),

            lunes: moment().startOf('isoweek'),
            martes: moment().startOf('isoweek').add(1, 'days'),
            miercoles: moment().startOf('isoweek').add(2, 'days'),
            jueves: moment().startOf('isoweek').add(3, 'days'),
            viernes: moment().startOf('isoweek').add(4, 'days'),
            sabado: moment().startOf('isoweek').add(5, 'days'),
            domingo: moment().startOf('isoweek').add(6, 'days'),
        },
        {
            fechaInicio: moment().add(7, 'days').startOf('isoweek'),
            fechaFinal: moment().add(7, 'days').endOf('isoweek'),

            lunes: moment().add(7, 'days').startOf('isoweek'),
            martes: moment().add(7, 'days').startOf('isoweek').add(1, 'days'),
            miercoles: moment().add(7, 'days').startOf('isoweek').add(2, 'days'),
            jueves: moment().add(7, 'days').startOf('isoweek').add(3, 'days'),
            viernes: moment().add(7, 'days').startOf('isoweek').add(4, 'days'),
            sabado: moment().add(7, 'days').startOf('isoweek').add(5, 'days'),
            domingo: moment().add(7, 'days').startOf('isoweek').add(6, 'days'),
        },
    ];
    console.log(dosSemanas);

    useEffect(()=>{},[]);
    return(
        <Carousel 
            indicators={false}
            autoPlay={false}
            sx={{ width:'100%', minHeight:500 }}
        >
            {
                dosSemanas.map((semana)=>(
                    <Box>
                        { `${semana.fechaInicio.format('DD/MM/YYYY')} - ${semana.fechaFinal.format('DD/MM/YYYY')}` }
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Asesor</TableCell>
                                    <TableCell align="center">{ `Lunes ${(semana.lunes.format('DD'))}` }</TableCell>
                                    <TableCell align="center">{ `Martes ${(semana.martes.format('DD'))}` }</TableCell>
                                    <TableCell align="center">{ `Miercoles ${(semana.miercoles.format('DD'))}` }</TableCell>
                                    <TableCell align="center">{ `Jueves ${(semana.jueves.format('DD'))}` }</TableCell>
                                    <TableCell align="center">{ `Viernes ${(semana.viernes.format('DD'))}` }</TableCell>
                                    <TableCell align="center">{ `Sabado ${(semana.sabado.format('DD'))}` }</TableCell>
                                    <TableCell align="center">{ `Domingo ${(semana.domingo.format('DD'))}` }</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    asesores.map((asesor)=>(
                                        <TableRow>
                                            <TableCell>
                                                <Box>
                                                    <Typography>
                                                        { asesor.nombre }
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell align="center" sx={{ backgroundColor:'red' }}>
                                                <ItemCalendarioSemanaAsesores dia={semana.lunes} asesor={asesor} />
                                            </TableCell>
                                            <TableCell align="center" sx={{ backgroundColor:'red' }}>
                                                <ItemCalendarioSemanaAsesores dia={semana.martes} asesor={asesor} />
                                            </TableCell>
                                            <TableCell align="center" sx={{ backgroundColor:'red' }}>
                                                <ItemCalendarioSemanaAsesores dia={semana.miercoles} asesor={asesor} />
                                            </TableCell>
                                            <TableCell align="center" sx={{ backgroundColor:'red' }}>
                                                <ItemCalendarioSemanaAsesores dia={semana.jueves} asesor={asesor} />
                                            </TableCell>
                                            <TableCell align="center" sx={{ backgroundColor:'red' }}>
                                                <ItemCalendarioSemanaAsesores dia={semana.viernes} asesor={asesor} />
                                            </TableCell>
                                            <TableCell align="center" sx={{ backgroundColor:'red' }}>
                                                <ItemCalendarioSemanaAsesores dia={semana.sabado} asesor={asesor} />
                                            </TableCell>
                                            <TableCell align="center" sx={{ backgroundColor:'red' }}>
                                                <ItemCalendarioSemanaAsesores dia={semana.domingo} asesor={asesor} />
                                            </TableCell>
                                            {/* <TableCell align="center">
                                                <Box
                                                    sx={{
                                                        width:'100%',
                                                    }}
                                                >
                                                    <Card>
                                                        <CardActionArea>
                                                            <CardContent>
                                                                <Typography>
                                                                    Agregar Horario
                                                                </Typography>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>                                            
                                                </Box>
                                            </TableCell> */}
                                            
                                        </TableRow>
                                    ))
                                }
                                
                            </TableBody>
                        </Table>
                    </Box>
                ))
            }

        </Carousel> 
    );
}
export { CalendarioSemanaAsesores }