import React, {useState,useEffect} from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation, useParams, Link, NavLink, Navigate } from "react-router-dom";
import { Box, Skeleton } from '@mui/material';
import { DatosManzana } from '../../components/DatosManzana';
import { MenuLotes } from '../../components/MenuLotes';

const InventarioManzana = () => {
    let navigate = useNavigate();
    const location = useLocation();
    let parametros = useParams();
    // const [etapa, setEtapa] = useState(location.state.etapa);
    const [manzana, setManzana] = useState(null);
    //console.log(location.state.etapa);
    console.log(parametros);
    
    useEffect(() => {
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        

        axios.get(`${process.env.REACT_APP_API_URL}/api/manzana/${parametros.manzana}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((response) => {
            // handle success
            console.log(response);
            setManzana(response.data)            
        })
        .catch((error) => {
            // handle success
            console.log(error);
            navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sae');
        });
    }, [setManzana]);

    return(
        <div>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'green' }}>
                {
                    !manzana ?
                    <Skeleton variant="rectangular" width={510} height={118} /> :
                    <DatosManzana datos={manzana}/>
                }                              
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'yellow' }}>
                {
                    !manzana ?
                    <Skeleton variant="rectangular" width={510} height={118} /> :
                    <MenuLotes manzana={manzana}/>
                }                 
            </Box>
        </div>
    );
}

export { InventarioManzana }