import { Button, Dialog, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";

const AddHorario = ({ handleClose, open, asesor, dia }) => {
    // console.log('modal',dia.format('YYYY-MM-DD'));
    const [fraccionamientos, setFraccionamientos] = useState([]);

    const [fraccionamientoSelected, setFraccionamientoSelected] = useState(null);
    const getFraccionamientSelected = (event) => {
        setFraccionamientoSelected(event.target.value);
    }
    const [horarioSelected, setHorarioSelected] = useState(null);
    const getHorarioSelected = (event) => {
        setHorarioSelected(event.target.value);
    }
    const add = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        const horarios = [
            { horaInicio:`11:00:00`, horaFinal:`15:00:00` },
            { horaInicio:`11:00:00`, horaFinal:`20:00:00` },
            { horaInicio:`15:00:00`, horaFinal:`20:00:00` },
        ];

        // console.log(fraccionamientoSelected);
        // console.log(horarioSelected, horarios[horarioSelected]);
        // console.log(asesor);
        // console.log({
        //     idAsesor: asesor.id,
        //     idFraccionamiento: fraccionamientoSelected,
        //     fecha: dia.format('YYYY-MM-DD'),
        //     ...horarios[horarioSelected],
        //     horaInicio:,
        //     horaFinal:,
        // }); return;
        axios.post(`${process.env.REACT_APP_API_URL}/api/asesores/create-horario`,
            {
                idAsesor: asesor.id,
                idFraccionamiento: fraccionamientoSelected,
                fecha: dia.format('YYYY-MM-DD'),
                ...horarios[horarioSelected],
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
        .then((response)=>{
            console.log(response);
            // setFraccionamientos(response.data);
        })
        .catch((error)=>{
            console.log(error);
        });
    }        

    useEffect(()=>{
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');

        axios.get(`${process.env.REACT_APP_API_URL}/api/producto/fraccionamientos`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
        .then((response)=>{
            // console.log(response);
            setFraccionamientos(response.data);
        })
        .catch((error)=>{
            console.log(error);
        });
    },[]);

    return(
        <Dialog 
            onClose={handleClose} 
            open={open}
            fullWidth='md'
        >
            <DialogTitle>Agregar Horario</DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        // backgroundColor:'red',
                        width:'100%',
                    }}
                >
                    <Box component="form" onSubmit={add}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormControl fullWidth sx={{ mt:1 }}>
                                    <InputLabel id="demo-simple-select-label">Fraccionamiento</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={fraccionamientoSelected}
                                        label="Fraccionamiento"
                                        onChange={getFraccionamientSelected}
                                        required
                                    >
                                        {
                                            fraccionamientos.map((fraccionamiento)=>(
                                                <MenuItem value={fraccionamiento.id}>{ fraccionamiento.nombre }</MenuItem>        
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Horario</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={horarioSelected}
                                        label="Horario"
                                        onChange={getHorarioSelected}
                                        required
                                    >
                                        <MenuItem value={0}>11:00 - 15:00</MenuItem>
                                        <MenuItem value={1}>11:00 - 20:00</MenuItem>
                                        <MenuItem value={2}>15:00 - 20:00</MenuItem>                                    
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Box>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            width:'100%',
                                        }}
                                        type="submit"
                                    >
                                        Agregar
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Box>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            width:'100%',
                                        }}
                                        onClick={handleClose}
                                    >
                                        Cancelar
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export { AddHorario }