/**
 * Componente UpperContent representa la sección superior del pie de página.
 * Incluye contenedores para enlaces sociales, información de contacto y enlaces del pie de página.
 *
 * @component
 * @param {Object} props - Propiedades del componente.
 * @param {Array} props.fraccionamientos - Lista de fraccionamientos.
 * @param {Array} props.socialMediaLinks - Lista de enlaces a redes sociales.
 * @param {Object} props.logo - Información sobre el logo.
 * @param {Object} props.contactMethod - Información de contacto.
 * @param {Array} props.purchasePrograms - Lista de programas de compra.
 * @param {Array} props.features - Lista de características.
 * @returns {JSX.Element} JSX Element que representa la sección superior del pie de página.
 *
 * @example
 * // Ejemplo de uso del componente UpperContent
 * <UpperContent
 *   fraccionamientos={fraccionamientosData}
 *   socialMediaLinks={socialMediaLinksData}
 *   logo={logoData}
 *   contactMethod={contactMethodData}
 *   purchasePrograms={purchaseProgramsData}
 *   features={featuresData}
 * />
 */

import React from "react";
import { Box } from "@mui/material";
import { SocialContainer } from "./SocialContainer";
import { AddressContainer } from "./AdressContainer";
import { FooterLinksContainer } from "./FooterLinksContainer";

/* Estilos */

const gridContainer = {
  display: "grid",
  gridTemplateColumns: { xs: "1fr", md: "repeat(3, 1fr)" },
  gap: 2,
};

const UpperContent = ({
  fraccionamientos,
  socialMediaLinks,
  logo,
  contactMethod,
  purchasePrograms,
  features,
}) => {
  return (
    <Box sx={gridContainer}>
      <SocialContainer socialMediaLinks={socialMediaLinks} logo={logo} />
      <AddressContainer contactMethod={contactMethod} />
      <FooterLinksContainer
        fraccionamientos={fraccionamientos}
        purchasePrograms={purchasePrograms}
        features={features}
      />
    </Box>
  );
};

export { UpperContent };
