import { Button, ButtonBase, Container, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";

import PhotoIcon from '@mui/icons-material/Photo';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import AddCommentIcon from '@mui/icons-material/AddComment';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from "react";

// import hotsale from '../../assets/hotsale.svg'
import axios from "axios";

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const CargasPromociones = () => {
    function createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
      }
      
      const rows = [
        createData('Hot Sale 2022', 159, 6.0, '07/09/2022', '07/09/2022'),
        createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
        createData('Eclair', 262, 16.0, 24, 6.0),
        createData('Cupcake', 305, 3.7, 67, 4.3),
        createData('Gingerbread', 356, 16.0, 49, 3.9),
      ];
    const [promociones, setPromociones] = useState([]);
    
    const [nuevaPromocion, setNuevaPromocion] = useState(true);

    const [tipoDescuento, setTipoDescuento] = useState(1);
    const getTipoDescuento = (event) => {      
      setTipoDescuento(event.target.value);
    }

    const [logo, setLogo] = useState(null);
    const [foto, setFoto] = useState(null);
    const [documento, setDocumento] = useState(null);

    const [editPromocion, setEditPromocion] = useState(null);

    const add = (event) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      // // // const data = new FormData();    
      const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
      const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');
      console.log('a ver',documento.name);

      if(data.get('tipoDescuento') === '1'){
        data.append('cantidadADescontar', data.get('descuento'));
        // data.append('porcentajeADescontar', null);
      }
      if(data.get('tipoDescuento') === '2'){
        data.append('porcentajeADescontar', data.get('descuento'));
        // data.append('cantidadADescontar', null);
      }
      // if(data.get('tipoDescuento') === '3'){
      //   data.append('otro', data.get('otro'));
      //   // data.append('cantidadADescontar', null);
      // }
      // data.delete('logoImg')
      // data.delete('fotoImg')
      // return;
      axios.post(`${process.env.REACT_APP_API_URL}/api/promocion`, 
      data,
      {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data', 
        }
      })
      .then((response) => {
        // handle success
        console.log(response);
        window.location.reload();
        
      })
      .catch((error) => {
        // handle success
        console.log(error);
        // navigate('/usuarios/login');
      })
      .then(() => {
        // handle success
      });

    }
    const edit = (event) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      // // // const data = new FormData();    
      const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
      const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');
      console.log(data.get('fotoImg'));
      
      if(data.get('tipoDescuento') === '1'){
        data.append('cantidadADescontar', data.get('descuento'));
        data.append('porcentajeADescontar', null);
      }
      if(data.get('tipoDescuento') === '2'){
        data.append('porcentajeADescontar', data.get('descuento'));
        data.append('cantidadADescontar', null);
      }
      
      data.set('id', editPromocion.id);
      axios.post(`${process.env.REACT_APP_API_URL}/api/promocion/update`, 
      data,
      {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data', 
        }
      })
      .then((response) => {
        // handle success
        console.log(response);
        
      })
      .catch((error) => {
        // handle success
        console.log(error);
        // navigate('/usuarios/login');
      })
      .then(() => {
        // handle success
      });

    }
    const deletePromocion = (event) => {
      event.preventDefault();
      //const data = new FormData(event.currentTarget);
      // // // const data = new FormData();    
      const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
      const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');
      
    //   console.log({
    //     id: event.target.value,
    //     activo: event.target.checked,
    //   },);
    // return;

      //data.set('id', editPromocion.id);
      axios.post(`${process.env.REACT_APP_API_URL}/api/promocion/borrar`, 
      {
        id: event.target.value,
        activo: event.target.checked,
      },
      {
        headers: {
            Authorization: `Bearer ${token}`,
            // 'Content-Type': 'multipart/form-data', 
        }
      })
      .then((response) => {
        // handle success
        console.log(response);
        
      })
      .catch((error) => {
        // handle success
        console.log(error);
        // navigate('/usuarios/login');
      })
      .then(() => {
        // handle success
      });

    }
    const [isChecked, setIsChecked] = useState(null);
    const isCheckboxChecked = (index, e) => {
      setIsChecked((isChecked) => {
        return isChecked.map((c, i) => {
          if (i === index) return e.target.checked;
          return c;
        });
      });
      deletePromocion(e);
      if(e.target.checked){
          console.log('se mostrara disponible', e.target.value);
      }
      else{
          console.log('se mostrara No disponible', e.target.value);
      }
  };
    const addEditPromocion = (event) => {
      if(nuevaPromocion){
        add(event);
      }
      if(!nuevaPromocion){
        edit(event)
      }

    }
    const getEditPromocion = (promocion) => {      
      setNuevaPromocion(false);
      setEditPromocion(promocion);
      setLogo(promocion.logo);
      setFoto(promocion.foto);
      if(promocion.cantidadADescontar){
        setTipoDescuento(1)
      }
      if(promocion.porcentajeADescontar){
        setTipoDescuento(2)
      }
      console.log(promocion);
    }
    useEffect(()=>{
      const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
      const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');
      axios.get(`${process.env.REACT_APP_API_URL}/api/promocion`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        // handle success
        console.log(response);
        setPromociones(response.data);
        setIsChecked(() =>
        promociones.map((promocion) => promocion.activo)
    )
      })
      .catch((error) => {
        // handle success
        console.log(error);
        // navigate('/usuarios/login');
      })
      .then(() => {
        // handle success
      });
    }, []);
    return(
        <Container sx={{ mt:12 }}>
          <Box sx={{ /*backgroundColor:'yellow'*/ }}>                
            <h1>Promociones</h1>                                 
          </Box>
          <Box>
            <Box component="form" onSubmit={addEditPromocion} encType="multipart/form-data">
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item md={12}>
                  {
                    editPromocion ? (
                      <TextField id="outlined-basic" label="Nombre" variant="outlined" name="nombre" value={editPromocion.nombre} onChange={(e)=> setEditPromocion({...editPromocion, nombre: e.target.value})} sx={{ width: '100%'}}/>
                    ) : (
                      <TextField id="outlined-basic" label="Nombre" variant="outlined" name="nombre" sx={{ width: '100%'}}/>
                    )
                  }
                </Grid>              
                <Grid item md={6}>
                  {
                    editPromocion ? (
                      <TextField
                        label={tipoDescuento === 1 ? 'Cantidad a descontar' : 'Porcentaje a descontar'}
                        id="outlined-start-adornment"
                        sx={{ mr: 1, /*width: '25ch'*/ }}
                        type='number'
                        InputProps={{
                          startAdornment: tipoDescuento === 1 ? (<InputAdornment position="start">$</InputAdornment>) : null,
                          endAdornment: tipoDescuento === 2 ? (<InputAdornment position="end">%</InputAdornment>) : null,
                        }}
                        name="descuento"
                        value={editPromocion.cantidadADescontar ? editPromocion.cantidadADescontar : editPromocion.porcentajeADescontar}
                        onChange={(e)=> setEditPromocion({...editPromocion, cantidadADescontar: tipoDescuento === 1 ? e.target.value : null, porcentajeADescontar: tipoDescuento === 2 ? e.target.value : null })}
                      />
                    ) : (
                      <>
                        {
                          tipoDescuento === 3 ? (
                            <TextField name="otro" label="texto" sx={{ mr:1 }}/>
                          ) : (
                            <TextField
                              label={tipoDescuento === 1 ? 'Cantidad a descontar' : 'Porcentaje a descontar'}
                              id="outlined-start-adornment"
                              sx={{ mr: 1, /*width: '25ch'*/ }}
                              type='number'
                              InputProps={{
                                startAdornment: tipoDescuento === 1 ? (<InputAdornment position="start">$</InputAdornment>) : null,
                                endAdornment: tipoDescuento === 2 ? (<InputAdornment position="end">%</InputAdornment>) : null,
                              }}
                              name="descuento"
                            />
                          )
                        }
                        
                      </>
                    )
                  }
                  {
                    editPromocion ? (
                      <FormControl>
                        <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={tipoDescuento}
                          label="Tipo"
                          onChange={getTipoDescuento}
                          name="tipoDescuento"
                        >
                          <MenuItem value={1}>Cantidad ($)</MenuItem>
                          <MenuItem value={2}>Porcentaje (%)</MenuItem>
                          <MenuItem value={3}>Otro</MenuItem>
                        </Select>        
                      </FormControl>
                    ) : (
                      <FormControl>
                        <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={tipoDescuento}
                          label="Tipo"
                          onChange={getTipoDescuento}
                          name="tipoDescuento"
                        >
                          <MenuItem value={1}>Cantidad ($)</MenuItem>
                          <MenuItem value={2}>Porcentaje (%)</MenuItem>
                          <MenuItem value={3}>Otro</MenuItem>
                        </Select>        
                      </FormControl>
                    )
                  }                      
                </Grid>
                <Grid item md={3}>
                  {
                    editPromocion ? (
                      <TextField id="outlined-basic" label="Fecha Inicio" variant="outlined" name="fechaInicio" type='date' value={editPromocion.fechaInicio} onChange={(e)=> setEditPromocion({...editPromocion, fechaInicio: e.target.value})} sx={{ width: '100%'}}/>
                    ) : (
                      <TextField id="outlined-basic" label="Fecha Inicio" variant="outlined" name="fechaInicio" type='date' sx={{ width: '100%'}}/>
                    )
                  }                  
                </Grid>
                <Grid item md={3}>
                  {
                    editPromocion ? (
                      <TextField id="outlined-basic" label="Fecha Final" variant="outlined" name="fechaFinal" type='date' value={editPromocion.fechaFinal} onChange={(e)=> setEditPromocion({...editPromocion, fechaFinal: e.target.value})} sx={{ width: '100%'}}/>
                    ) : (
                      <TextField id="outlined-basic" label="Fecha Final" variant="outlined" name="fechaFinal" type='date' sx={{ width: '100%'}}/>
                    )
                  }                  
                </Grid>
                <Grid item md={4}>
                  <ButtonBase  aria-label="upload picture" component="label">
                    <input hidden accept="image/*" type="file" name="logoImg" onChange={(e)=>{ setLogo(URL.createObjectURL((e.target.files[0]))) }}/>
                    {
                      logo ? (
                        <Box
                          component='img'
                          src={logo}
                          sx={{ 
                            width:380,
                            height:230,
                          }}
                        />
                      ) : (
                        <Box component="span" sx={{ p: 12, border: '1px dashed grey' }}>
                          <AddCommentIcon/>
                          Agregar Logo
                        </Box>
                      )
                    }
                  </ButtonBase>
                </Grid>
                <Grid item md={4}>
                  <ButtonBase  aria-label="upload picture" component="label">
                    <input hidden accept="image/*" type="file" name="fotoImg" onChange={(e)=>{ setFoto(URL.createObjectURL((e.target.files[0]))) }}/>
                    {
                      foto ? (
                        <Box
                          component='img'
                          src={foto}
                          sx={{ 
                            width:380,
                            height:230,
                          }}
                        />
                      ) : (
                        <Box component="span" sx={{ p: 12, border: '1px dashed grey' }}>
                          <AddPhotoAlternateIcon/>
                          Agregar Foto
                        </Box>
                      )
                    }                        
                  </ButtonBase>
                </Grid>
                <Grid item md={4}>
                  <ButtonBase  aria-label="upload picture" component="label">
                    <input hidden accept="pdf/*" type="file" name="archivoTerminosYCondiciones" onChange={(e)=>{ setDocumento((e.target.files[0])) }}/>
                    {
                      documento ? (                        
                        <Box component="span" sx={{ p: 8, border: '1px dashed grey' }}>
                          <InsertDriveFileIcon sx={{ fontSize: 40 }}/>
                          {
                            documento.name && (documento.name)
                          }
                        </Box>
                      ) : (
                        <Box component="span" sx={{ p: 8, border: '1px dashed grey' }}>
                          <InsertDriveFileIcon/>
                          Agregar Terminos y condiciones
                        </Box>
                      )
                    }                        
                  </ButtonBase>
                </Grid>
                <Grid item md={3}>
                  <Button variant="outlined" type="submit">Guardar</Button>
                </Grid>
                <Grid item md={3}>
                  <Button variant="outlined">Cancelar</Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
            <Box>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombre</TableCell>
                      <TableCell align="right">Logo</TableCell>
                      <TableCell align="right">Descuento</TableCell>
                      <TableCell align="right">Fecha inicio</TableCell>
                      <TableCell align="right">Fecha fin</TableCell>
                      <TableCell align="right">Foto</TableCell>
                      <TableCell align="right">Editar</TableCell>
                      <TableCell align="right">Disponible</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      promociones.length > 0 ? (
                        promociones.map((row, index) => (
                          <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              {row.nombre}
                            </TableCell>
                            <TableCell align="right">
                              <Box>
                                {
                                  row.logo ? (
                                    <Box
                                        component='img'
                                        src='https://casaspecsa-bucket.s3.us-east-2.amazonaws.com/Promociones/Hot+Sale+Pecsa+2022+express/logo/hotsale.svg'
                                        sx={{
                                          width: 45
                                        }}                                        
                                    />
                                  ) : (
                                    <AddPhotoAlternateIcon/>
                                  )
                                }
                                    
                              </Box>
                            </TableCell>
                            <TableCell align="right">
                              {
                                row.cantidadADescontar ? (
                                  `$${row.cantidadADescontar}`
                                ) : (
                                  row.porcentajeADescontar ? (
                                    `${row.porcentajeADescontar}%`
                                  ) : (
                                    ''
                                  )
                                )
                              }
                            </TableCell>
                            <TableCell align="right">{row.fechaInicio}</TableCell>
                            <TableCell align="right">{row.fechaFinal}</TableCell>
                            <TableCell align="right">
                              {
                                row.foto ? (
                                  <Box
                                    component='img'
                                    src={row.foto}
                                    sx={{
                                      width: 45
                                    }}                                        
                                  />
                                ) : (
                                  <AddPhotoAlternateIcon/>
                                )
                              }
                              {/* <IconButton aria-label="upload picture" component="label">
                                  <input hidden accept="image/*" type="file" />
                                  <AddPhotoAlternateIcon/>
                              </IconButton>*/}
                            </TableCell> 
                            <TableCell align="right">
                              <IconButton onClick={()=>{ getEditPromocion(row) }}>
                                  <EditIcon/>
                              </IconButton>
                            </TableCell>
                            <TableCell align="right">
                              <Switch 
                                //checked={row.activo ? true : false} 
                                color="success" 
                                {...label} 
                                checked={isChecked[index]}
                                value={row.id}
                                onChange={(e)=>{ isCheckboxChecked(index, e); }}
                                />
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={8} align="center">
                            <Typography variant="h5" gutterBottom>
                                Sin Promociones registradas
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
        </Container>
    );
}

export { CargasPromociones }