import { Button, Card, CardContent, CardMedia, CircularProgress, Divider, Grid, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Elements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import './CardElement.css';
import { CardForm } from "./CardForm";
import { PayForm } from "./PayForm";

import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

const Pago = () => {
    let navigation = useNavigate();
    let location = useLocation();
    console.log(location.state);
    const [lote, setLote] = useState(null);
    const [transaccion, setTransaccion] = useState(null);
    const [idCliente, setIdCliente] = useState(null);

    const [clientSecret, setClientSecret] = useState(null);
    
    const [loteDisponible, setLoteDisponible] = useState(true);

    const options = {
        // passing the client secret obtained in step 2
        // clientSecret: 'pi_3LT7R5FmRKo9q8Cv0Aj645v0_secret_ku6boDGacHHuGXCwrJDVRqF9h',
        clientSecret: clientSecret,
        // Fully customizable with appearance API.
        // appearance: {/*...*/},
    };

    const getFormatoMoneda = (cantidad) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD', //MXN
            minimumFractionDigits: 0
          })
      
          var value = 10000
      
          return formatter.format(cantidad)
    }
    const [promocion, setPromocion] = useState(null);
    const [precioFinal, setPrecioFinal] = useState(null)

    const findPromocion = (lote) => {
        const promocionResult = {};
        if(lote.promocion){
            setPromocion(lote.promocion);
        }else{
            if(lote.manzana.promocion){
                setPromocion(lote.manzana.promocion);
            }else{
                if(lote.manzana.etapa.promocion){
                    setPromocion(lote.manzana.etapa.promocion);
                }else{
                    if(lote.manzana.etapa.fraccionamiento.promocion){
                        setPromocion(lote.manzana.etapa.fraccionamiento.promocion);
                    }else{
                        if(lote.fachada.modelo.promocion){
                            setPromocion(lote.fachada.modelo.promocion);
                        }
                    }
                }
            }
        }
    }
    const aplicaPromocion = (lote, promocion) => {
        if(promocion.cantidadADescontar){
            setPrecioFinal(lote.precio - promocion.cantidadADescontar);
        }
        if(promocion.porcentajeADescontar){
            setPrecioFinal(lote.precio - (lote.precio * (promocion.porcentajeADescontar/100)));
        }

    }

    useEffect(()=>{
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');
        
        if (location.state){
            axios.post(`${process.env.REACT_APP_API_URL}/api/venta/transaccion/`,{
                idLote: location.state.idLote,
                idCliente: location.state.idCliente,
                description:  `Separacion de Lote ${location.state.numeroLote}`,
            },{
                headers: { 
                Authorization: `Bearer ${token}`,
                }              
            })
            .then((response) => {
                // handle success
                console.log(response);
                // options.clientSecret = response.data.client_secret;
                // console.log(options);
                setClientSecret(response.data.client_secret);
                console.log(options);
                setTransaccion(response.data.transaccion);
                setLote(response.data.transaccion.lote);
                findPromocion(response.data.transaccion.lote);
            })
            .catch((error) => {
                // handle success
                console.log(error);
                // navigation(-1);
                setLoteDisponible(false);
            })
            .then(() => {
                // handle success
                //console.log('sabe');
            });
        }
    }, [setLote, setTransaccion,]);

    useEffect(()=>{
        if(promocion){
            aplicaPromocion(lote, promocion)
        }
    }, [promocion]);

    return(
        <Container sx={{ mt: { md:12 }, minHeight:830 }}>
            <Box>                
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12} sm={4} md={7}>
                        <Card>
                            {
                                lote ? (
                                    <CardMedia
                                        component="img"
                                        height="500"
                                        image={lote.fotos[0].url}
                                        alt="green iguana"
                                    />
                                ) : (
                                    loteDisponible ? (
                                        <Skeleton variant="rectangular" width={'100%'} height={500} />
                                    ) : (
                                        <Box
                                            sx={{
                                                mt:12,
                                                textAlign:'center',
                                                color: '#f5323f',
                                            }}
                                        >
                                            <ProductionQuantityLimitsIcon sx={{ fontSize:200 }}/>
                                        </Box>
                                    )
                                    
                                )
                            }
                            {/* <CardMedia
                                component="img"
                                height="500"
                                image="/static/images/cards/contemplative-reptile.jpg"
                                alt="green iguana"
                            /> */}
                            {/* <Skeleton variant="rectangular" width={'100%'} height={500} /> */}
                            <CardContent>
                                {
                                    lote ? (
                                        <React.Fragment>
                                            <Box sx={{ color:'#707070' }}>
                                                <Typography gutterBottom variant="h5" component="div" sx={{ color:'#F5323F' }}>
                                                    MODELO <b>{lote.fachada.modelo.nombre}</b>
                                                </Typography>
                                                {
                                                    promocion && (
                                                        <Typography variant="body2">
                                                            Precio total del lote: <b><del>{getFormatoMoneda(lote.precio)}</del></b>
                                                            {
                                                                promocion.cantidadADescontar ? ` -${getFormatoMoneda(promocion.cantidadADescontar)}`
                                                                : ` -${promocion.porcentajeADescontar}%`
                                                            }
                                                            <Box
                                                                component='img'
                                                                src={promocion.logo}
                                                                sx={{ width:20 }}
                                                            />
                                                        </Typography>
                                                    )
                                                }
                                                <Typography variant="body1">
                                                    Precio total del lote: 
                                                    <b>
                                                        {
                                                            !promocion ? 
                                                            ( 
                                                                getFormatoMoneda(lote.precio)
                                                            ) : (
                                                                `${getFormatoMoneda(precioFinal)} `
                                                            )
                                                        }
                                                    </b>
                                                </Typography>
                                                <Typography variant="body2">
                                                    Fraccionamiento: <b>{lote.manzana.etapa.fraccionamiento.nombre}</b>
                                                </Typography>
                                                <Typography variant="body2">
                                                    Manzana: <b>{lote.manzana.nombre}</b>
                                                </Typography>
                                                <Typography variant="body2">
                                                    Lote: <b>{lote.numeroDeLote}</b>
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <TableContainer >
                                                    <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell sx={{ color:'#707070' }} align="center" colSpan={2}>
                                                                    Detalles 
                                                                </TableCell>
                                                                <TableCell sx={{ color:'#707070' }} align="center">Precio</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell sx={{ color:'#707070' }} colSpan={3}>Desc</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow >
                                                                <TableCell sx={{ color:'#707070' }} colSpan={2}>Separacion Modelo <b>{lote.fachada.modelo.nombre}</b></TableCell>
                                                                <TableCell sx={{ color:'#707070' }} align="center">$5,000</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell rowSpan={3} />
                                                                <TableCell sx={{ color:'#707070' }} align="center">Subtotal</TableCell>
                                                                <TableCell sx={{ color:'#707070' }} align="center">$5,000</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell sx={{ color:'#707070' }} align="center">Descuento</TableCell>
                                                                <TableCell sx={{ color:'#707070' }} align="center">-$0</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell sx={{ color:'#707070' }} align="center"><b>Total</b></TableCell>
                                                                <TableCell sx={{ color:'#707070' }} align="center"><b>$5,000</b></TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Box>
                                            {/* <Box>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <Grid item xs={6} sm={6} md={6}>
                                                        <Typography variant="body2" color="text.secondary">
                                                            <b>Separacion</b>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={6}>
                                                        <Typography variant="body1" color="text.secondary">
                                                            $5000
                                                        </Typography>
                                                    </Grid>                                        
                                                </Grid>
                                                <Divider variant="middle" />
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <Grid item xs={6} sm={6} md={6}>
                                                        <Typography variant="body1" color="text.secondary">
                                                            <b>Total a Pagar:</b>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={6}>
                                                        <Typography variant="body1" color="text.secondary">
                                                            <b>$5000</b>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box> */}
                                        </React.Fragment>
                                    ) : (
                                        
                                        loteDisponible ? (
                                            <React.Fragment>
                                                <Skeleton variant="text" width={210} height={68}/>
                                                <Skeleton variant="text" width={210}/>
                                                <Skeleton variant="text" width={210}/>
                                                <Skeleton variant="text" width={210}/>
                                                <Skeleton variant="text" width={210}/>
                                                
                                                <Skeleton variant="rectangular" width={'100%'} height={89} />
                                            </React.Fragment>
                                        ) : (
                                            <Typography variant="h4" gutterBottom sx={{ color:'#707070' }}>
                                                El lote ya no esta disponible
                                            </Typography>
                                        )
                                        
                                        
                                    )
                                }
                                {/* <Box>
                                    <Typography gutterBottom variant="h5" component="div" >
                                        MODELO T146
                                    </Typography>
                                    <Typography variant="body1" color="text.secondary">
                                        <b>precio</b>
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Fraccionamiento: <b>nOMBRE</b>
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Manzana: <b>nOMBRE</b>
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Lote: <b>nOMBRE</b>
                                    </Typography>
                                </Box> */}
                                
                                {/* <Box>
                                    <Typography gutterBottom variant="body1" color="text.secondary" >
                                        Nombre: nombre persona
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Email: <b>nOMBRE</b>
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Telefono: <b>nOMBRE</b>
                                    </Typography>
                                </Box>
                                <Divider/> */}
                                {/* <Box>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography variant="body2" color="text.secondary">
                                                <b>Separacion</b>
                                            </Typography>
                                            <Typography variant="body1" color="text.secondary">
                                                MODELO <b>T-146</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography variant="body1" color="text.secondary">
                                                $5000
                                            </Typography>
                                        </Grid>                                        
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography variant="body1" color="text.secondary">
                                                <b>Total a Pagar:</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography variant="body1" color="text.secondary">
                                                <b>$5000</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box> */}
                                
                                <Box sx={{ fontSize: 2, mt:1, color:'#707070' }}>
                                    {/* <Typography variant="body1">
                                        <b>Metodos de Pago:</b>
                                    </Typography> */}

                                    {
                                        lote &&  (
                                            <Elements stripe={stripePromise} options={options}>
                                                <PayForm lote={lote} transaccion={transaccion} promocion={promocion}/>
                                                {/* <CardForm lote={lote} transaccion={transaccion}/> */}
                                            </Elements>
                                        )
                                    }
                                    
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    
                </Grid>
                {/* <Elements stripe={stripePromise}>
                    <form>
                        <CardElement/>
                    </form>
                </Elements> */}
            </Box>            
        </Container>
    );
}


export { Pago }