import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Box, Breadcrumbs, Container, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { Principal } from "./Principal";
import { Link, useLocation } from "react-router-dom";
import { OchoRazones } from "../../components/OchoRazones";
import { EspecificacionesModelo } from "../../components/EspecificacionesModelo";
import { PlantaArquitectonica } from "../../components/PlantaArquitectonica";
import { Disponibilidad } from "../../components/Disponibilidad";
import { ComparaMensualidades } from "../../components/Banners/ComparaMensualidades";
import { ProcesosConstructivos } from "../../components/ProcesosConstructivos";
import { Garantias } from "../../components/Garantias";
import { FeedbackModelo } from "../../components/FeedbackModelo";
import { QueremosSaberTuOpinion } from "../../components/QueremosSaberTuOpinion";
import { SimuladorDeCredito } from "../../components/SimuladorDeCredito";
import { OpinionesFamiliaPecsa } from "../../components/OpinionesFamiliaPecsa";
import { VideosModelo } from "../../components/VideosModelo";
import { BannerOpciones } from "../../components/BannerOpciones";
import { MetaPixel } from "../../components/MetaPixel";
import { getLoteConPrecioMasBajo } from "../../functions/getLoteConPrecioMasBajo";
// import { SlideAnuncios } from "../../components/SlideAnuncios";


const Modelo = ({ modelo, simulador }) => {
    let location = useLocation();
    console.log('modelooooo', modelo);
    //console.log(location.state.modelo);
    //const [modelo, setModelo] = useState(location.state.modelo || {});
    // const [modelo, setModelo] = useState(props.modelo || {});
    const [precio, setPrecio] = useState(0);
    
    const getPrecio = (value) => {
        setPrecio(value);
    }

    useEffect(()=>{
        window.scroll(0,0);
        // getPrecio(modelo.fachadas ? modelo.fachadas.map((fachada)=>fachada.lotes).flat().reduce((menor, actual) => {
        //     return (actual.precio < menor.precio) ? actual : menor;
        // }).precio : 0);
        getPrecio(getLoteConPrecioMasBajo(modelo.fachadas.map((fachada)=>fachada.lotes).flat()).precio);
    }, [modelo]);
    
    return(
        <div>   
            <Helmet>
                <title>Casas Pecsa | Modelo {modelo.nombre}</title>
                <meta name="description" content="Conoce Las Increíbles Casas que tenemos Para Ti. Contamos con un recorrido virtual 100% En Línea y Un Sistema de Separado en Línea. También puedes agendar tu cita para visitar Nuestras Casas Hoy mismo." />
            </Helmet>
            <MetaPixel/>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, mt: { xs:7, sm:9, md: 11 } }}>
                <Container>
                <Box
                    sx={{
                        // backgroundColor:'red',
                        pb:1,
                        pt:0.6,
                        pl:1,
                        color:'#707070',
                    }}
                >
                    <Breadcrumbs aria-label="breadcrumb">
                        {/* <Link underline="hover" color="inherit">
                            Inicio
                        </Link> */}
                        <Link to={'/'} style={{ color:'#707070', }}>
                            Inicio
                        </Link>
                        <Typography sx={{ color:'#F5323F', }}>Modelo</Typography>
                        <Typography sx={{ color:'#F5323F', }}>{ modelo.nombre }</Typography>
                    </Breadcrumbs>
                </Box>
                </Container>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, /*mt: { xs:7, sm:5, md: 12 }*/ }}>
                <Principal modelo={modelo}/>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'#F4F4F4' }}>
                <VideosModelo modelo={modelo}/>
            </Box> 
            {/* <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'#F4F4F4' }}>
                <EspecificacionesModelo/>
            </Box> */}

            <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                <PlantaArquitectonica plantas= {modelo.plantas}/>
            </Box>
            {/* <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                <Disponibilidad/>
            </Box>  */}
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                <ProcesosConstructivos modelo={modelo} />
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'#F4F4F4' }}>
                <Garantias/>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                <OchoRazones/>
            </Box>
            {/* <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'#F4F4F4' }}>
                <ComparaMensualidades precio={modelo.precio} simulador={simulador}/>
            </Box> */}
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                <SimuladorDeCredito precio={precio} simulador={simulador}/>
            </Box>
            {/* <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'#DE4647', color: 'white' }}>
                <FeedbackModelo/>
            </Box> */}
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'#F4F4F4' }}>
                <OpinionesFamiliaPecsa/>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' },  }}>
                <QueremosSaberTuOpinion modelo={modelo}/>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'#F4F4F4' }}>
                <BannerOpciones />
            </Box>
        </div>
    );
}

export { Modelo };