import { Box, Skeleton } from '@mui/material';
import React, {useState,useEffect} from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation, useParams, Link, NavLink, Navigate } from "react-router-dom";
import { DatosFraccionamiento } from '../../components/DatosFraccionamiento';
import { MenuEtapas } from '../../components/MenuEtapas';
import { MenuAmenidades } from '../../components/MenuAmenidades';

const InventarioFraccionamiento = () => {
    let navigate = useNavigate();
    const location = useLocation();
    let parametros = useParams();
    //const [fraccionamiento, setFraccionamiento] = useState(location.state.fraccionamiento);
    const [fraccionamiento, setFraccionamiento] = useState(null);
    //console.log(location.state.fraccionamiento);
    //console.log(parametros);

    useEffect(() => {
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        

        axios.get(`${process.env.REACT_APP_API_URL}/api/fraccionamiento/${parametros.fraccionamiento}`, {
              headers: {
                  Authorization: `Bearer ${token}`
              }
        })
        .then((response) => {
            // handle success
            console.log(response);
            setFraccionamiento(response.data)            
        })
        .catch((error) => {
            // handle success
            console.log(error);
            navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sae');
        });
    }, [setFraccionamiento]);

    return(
        <div>
            <Box sx={{ mt:12 }}>
                <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'#f4f4f4' }}>
                    {
                        !fraccionamiento ?
                        <Skeleton variant="rectangular" width={510} height={118} /> :
                        <DatosFraccionamiento datos={fraccionamiento}/>
                    }
                </Box>
                <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'white' }}>   
                    {
                        !fraccionamiento ?
                        <Skeleton variant="rectangular" width={510} height={118} /> :
                        <MenuAmenidades fraccionamiento={fraccionamiento}/>
                    }                                         
                </Box>
                <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'white' }}>   
                    {
                        !fraccionamiento ?
                        <Skeleton variant="rectangular" width={510} height={118} /> :
                        <MenuEtapas fraccionamiento={fraccionamiento}/>
                    }                                         
                </Box>
            </Box>
        </div>
    );
}

export { InventarioFraccionamiento }