import { Grid } from "@mui/material";
import { Benefit } from "../Benefit";

const BenefitsContainer = ({ benefits }) => {
  return (
    <Grid container spacing={3.75} justifyContent="center">
      {benefits.map((benefit, index) => (
        <Grid key={index} item xs={12} sm={6} md={12}>
          <Benefit {...benefit} />
        </Grid>
      ))}
    </Grid>
  );
};

export { BenefitsContainer };
