import { Box, Grid, Typography } from "@mui/material";
import { getFormatoMoneda } from "../../../../../functions/getFormatoMoneda";
import { CustomTypography } from "../../../../CustomTypography";
import theme from "../../../../../App/theme";

const Investment = ({ apartado, enganche, inversionMinima, inversionInicial, restanteDeInversionInicial, gastos, restanteAPagar }) => {
	return(
		<Box
			sx={{
				// backgroundColor:'#E0FFFF',
			}}
		>
			<Grid
				container
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				sx={{
					gap:1,
				}}
			>
				<Grid item xs={12} sm={5} md={5}>
					<Box
						sx={{
							// backgroundColor:'#5F9EA0',
							display:'flex',
							flexDirection:'column',
						}}
					>
						<CustomTypography variant="textTertiary">
							Apartado
						</CustomTypography>
						<CustomTypography variant="textSecondaryBold">
							{ getFormatoMoneda(apartado) }MXN
						</CustomTypography>
					</Box>
				</Grid>
				<Grid item xs={12} sm={5} md={5}>
					<Box
						sx={{
							// backgroundColor:'#5F9EA0',
							display:'flex',
							flexDirection:'column',
						}}
					>
						<CustomTypography variant="textTertiary">
							Enganche
						</CustomTypography>
						<CustomTypography variant="textSecondaryBold">
							{ getFormatoMoneda(enganche) }MXN
						</CustomTypography>
					</Box>
				</Grid>
				<Grid item xs={12} sm={12} md={12}>
					<Box
						sx={{
							// backgroundColor:'#5F9EA0',
							display:'flex',
							flexDirection:'column',
						}}
					>
						<CustomTypography variant="textTertiary">
							Gastos
						</CustomTypography>
						{/* <CustomTypography variant="textTertiaryLight">
							7% del valor de la vivienda para gastos en la adquisicion de la vivienda
						</CustomTypography> */}
						<CustomTypography variant="textSecondaryBold">
							{ getFormatoMoneda(gastos) }MXN
						</CustomTypography>
					</Box>
				</Grid>
				<Grid item xs={12} sm={12} md={12}>
					<Box
						sx={{
							// backgroundColor:'#5F9EA0',
							display:'flex',
							flexDirection:'column',
						}}
					>
						<CustomTypography variant="textTertiary">
							Inversion minima (<CustomTypography variant="textTertiaryLight">apartado + enganche + gastos</CustomTypography>)
						</CustomTypography>
						<CustomTypography variant="textSecondaryBold">
							{ getFormatoMoneda(inversionMinima) }MXN
						</CustomTypography>
					</Box>
				</Grid>
				<Grid item xs={12} sm={12} md={12}>
					<Box
						sx={{
							// backgroundColor:'#5F9EA0',
							display:'flex',
							flexDirection:'column',
						}}
					>
						<CustomTypography variant="textTertiary">
							Inversion inicial
						</CustomTypography>
						<CustomTypography variant="textSecondaryBold">
							{ getFormatoMoneda(inversionInicial) }MXN
						</CustomTypography>
					</Box>
				</Grid>
				{
					restanteDeInversionInicial >= 0 ? (
					<>
						<Grid item xs={12} sm={12} md={12}>
							<Box
								sx={{
									// backgroundColor:'#5F9EA0',
									display:'flex',
									flexDirection:'column',
								}}
							>
								<CustomTypography variant="textTertiaryLight">
									Con la <CustomTypography variant="textTertiaryBold">Inversion inicial</CustomTypography> se cubre la <CustomTypography variant="textTertiaryBold">Inversion minima</CustomTypography> y el resto se descontara del precio total de la casa
								</CustomTypography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<Box
								sx={{
									// backgroundColor:'#5F9EA0',
									display:'flex',
									flexDirection:'column',
								}}
							>
								<CustomTypography variant="textTertiary">
									Inversion restante
								</CustomTypography>
								<CustomTypography variant="textSecondaryBold">
									{ getFormatoMoneda(restanteDeInversionInicial) }MXN
								</CustomTypography>
							</Box>
						</Grid>
					</>
					) : (
						<Grid item xs={12} sm={12} md={12}>
							<Box
								sx={{
									// backgroundColor:'#5F9EA0',
									minHeight:{ md:99 },
									display:'flex',
									alignItems:'center',
									justifyContent:'center',
									flexDirection:'column',
								}}
							>
								<CustomTypography variant="textSecondary">
									Te faltan <CustomTypography variant="textSecondaryBold" txtColor={theme.palette.warning.main}>{ getFormatoMoneda(restanteDeInversionInicial * -1) }</CustomTypography> para cubrir la inversion minima.
								</CustomTypography>
							</Box>
						</Grid>
					)
				}
								
				<Grid item xs={12} sm={5} md={5}>
					<Box
						sx={{
							// backgroundColor:'#5F9EA0',
							display:'flex',
							flexDirection:'column',
						}}
					>
						<CustomTypography variant="textTertiary">
							Restante a pagar
						</CustomTypography>
						<CustomTypography variant="textSecondaryBold">
							{ getFormatoMoneda(restanteAPagar) }MXN
						</CustomTypography>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

export { Investment }