import { Button, Card, CardActions, Chip, Grid } from "@mui/material";
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

import  modelonotfound  from '../../assets/modelonotfound.png'
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import GradeIcon from '@mui/icons-material/Grade';
import StarRoundedIcon from '@mui/icons-material/StarRounded';

const TiendaItem = ({ lote, masVisitado }) => {
    // console.log('tiendaItem', lote);
    let navigate = useNavigate();

    // const [promocion, setPromocion] = useState(null);
    // const [precioFinal, setPrecioFinal] = useState(null);
    const findPromocion = (lote) => {
        const promocionResult = {};
        if(lote.promocion){
            // setPromocion(lote.promocion);
            return lote.promocion;
        }else{
            if(lote.manzana.promocion){
                // setPromocion(lote.manzana.promocion);
                return lote.manzana.promocion;
            }else{
                if(lote.manzana.etapa.promocion){
                    // setPromocion(lote.manzana.etapa.promocion);
                    return lote.manzana.etapa.promocion;
                }else{
                    if(lote.manzana.etapa.fraccionamiento.promocion){
                        // setPromocion(lote.manzana.etapa.fraccionamiento.promocion);
                        return lote.manzana.etapa.fraccionamiento.promocion;
                    }else{
                        if(lote.fachada.modelo.promocion){
                            // setPromocion(lote.fachada.modelo.promocion);
                            return lote.fachada.modelo.promocion;
                        }else{
                            // console.log('No tiene promocion:_', lote.numeroDeLote, 'mzn:', lote.manzana.nombre)
                            return null;                            
                        }
                    }
                }
            }
        }
    }
    const [promocion, setPromocion] = useState(findPromocion(lote));
    const [precioFinal, setPrecioFinal] = useState(null);

    const getFormatoMoneda = (cantidad) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD', //MXN
            minimumFractionDigits: 0
          })
      
          var value = 10000
      
          return formatter.format(cantidad)
    }
    // const findPromocion = (lote) => {
    //     const promocionResult = {};
    //     if(lote.promocion){
    //         // setPromocion(lote.promocion);
    //         return lote.promocion;
    //     }else{
    //         if(lote.manzana.promocion){
    //             // setPromocion(lote.manzana.promocion);
    //             return lote.manzana.promocion;
    //         }else{
    //             if(lote.manzana.etapa.promocion){
    //                 // setPromocion(lote.manzana.etapa.promocion);
    //                 return lote.manzana.etapa.promocion;
    //             }else{
    //                 if(lote.manzana.etapa.fraccionamiento.promocion){
    //                     // setPromocion(lote.manzana.etapa.fraccionamiento.promocion);
    //                     return lote.manzana.etapa.fraccionamiento.promocion;
    //                 }else{
    //                     if(lote.fachada.modelo.promocion){
    //                         // setPromocion(lote.fachada.modelo.promocion);
    //                         return lote.fachada.modelo.promocion;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }
    const aplicaPromocion = (lote, promocion) => {
        if(promocion.cantidadADescontar){
            setPrecioFinal(lote.precio - promocion.cantidadADescontar);
        }
        if(promocion.porcentajeADescontar){
            setPrecioFinal(lote.precio - (lote.precio * (promocion.porcentajeADescontar/100)));
        }
        if(promocion.otro){
            setPrecioFinal(lote.precio);
        }
    }
    // useEffect(()=>{
    //     findPromocion(lote);
    //     // if(promocion){
    //     //     console.log('haaaaa');
    //     //     aplicaPromocion(lote, promocion)
    //     // }
    // }, [lote]);
    useEffect(()=>{
        if(promocion){
            aplicaPromocion(lote, promocion)
        }else{
            console.log('no hay promocion', lote.numeroDeLote, ', mzn:', lote.manzana.nombre);
        }
    }, [promocion]);
    return(
        <Card 
            sx={{ 
                //maxWidth: { xs:345, sm:345, md: 345 },
                minHeight: { xs:415, sm:415, md: 415 },
                borderRadius: '12px',
                boxShadow: '0px 5px 12px 0px rgb(0, 0, 0, 0.19)',
            }}
        >
            <CardActionArea
                onClick={()=>{ navigate(`/tienda/${lote.sku.replace(/ /g, "_")}`) }}
            >
                {
                    lote.fotos ? (
                        <CardMedia
                            component="img"
                            // height="260"
                            image={lote.fotos[0].url}
                            alt="green iguana"
                            sx={{
                                height:{ xs:310, sm:415, md:260 },
                            }}
                        />
                    ) 
                    : (
                        <CardMedia
                            component="img"
                            height="260"
                            image={modelonotfound}
                            alt="green iguana"
                        />
                    )
                }
                
                <CardContent sx={{ minHeight: { xs:170, sm:190, md:190, } }}>
                    
                    <Box
                        sx={{
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'space-between',
                        }}
                    >
                        <Typography variant="h5" component="div" sx={{ color:'#F5323F', }} >
                            <b>{lote.fachada.modelo.nombre}</b>                            
                        </Typography>
                        {
                            masVisitado && (
                                <Chip 
                                    icon={<StarRoundedIcon sx={{ color:'#F1C40F', }}/>} 
                                    size="small" 
                                    label="Mas Visitado" 
                                    variant="outlined" 
                                    sx={{
                                        color:'#F1C40F',
                                        borderColor:'#F1C40F',
                                    }}
                                    color='warning'
                                />
                            )
                        }
                    </Box>
                    {/* <Typography variant="h5" component="div" sx={{ color:'#F5323F', }} >
                        <b>{lote.fachada.modelo.nombre}</b>                        
                    </Typography> */}
                    {/* <Typography variant="body1" color="text.secondary">
                        <del>{promocion && (promocion.nombre)}</del>
                    </Typography>
                    <Typography gutterBottom variant="h5"  >
                        <b>{getFormatoMoneda(lote.precio)}</b>
                    </Typography> */}
                    <Typography variant="body1" sx={{ color:'#707070' }}>
                        {/* <del>{promocion ? (`${getFormatoMoneda(lote.precio)}`) : (` `)}</del> */}
                        {
                            promocion ? (
                                promocion.otro ? (
                                    `${promocion.otro}`
                                ) : (
                                    <del>{`${getFormatoMoneda(lote.precio)}`}</del>
                                )
                            ) : (` `)
                        }
                        {/* { 
                            promocion && (
                                <React.Fragment>
                                    {`- ${getFormatoMoneda(promocion.cantidadADescontar)} `}<del>{` ${getFormatoMoneda(lote.precio)} `}</del>
                                </React.Fragment>
                                // `${getFormatoMoneda(lote.precio)} -${getFormatoMoneda(promocion.cantidadADescontar)}`
                            ) 
                        } */}
                    </Typography>
                    <Typography gutterBottom variant="h5" sx={{ color:'#707070', }} >
                        <b>
                            {
                                !promocion ? 
                                (
                                    getFormatoMoneda(lote.precio)
                                ) : (
                                    // <React.Fragment>
                                    //     {`${getFormatoMoneda(precioFinal)} `}
                                    //     <Box
                                    //         component='img'
                                    //         src={promocion.logo} 
                                    //         sx={{
                                    //             width:50
                                    //         }}
                                    //     />

                                    // </React.Fragment>
                                    <Box
                                        sx={{
                                            // backgroundColor:'greenyellow',
                                            maxHeight:40,
                                            display:'flex',
                                            alignItems:'center'
                                        }}
                                    >
                                        {`${getFormatoMoneda(precioFinal)} `}
                                        <Box
                                            component='img'
                                            src={promocion.logo} 
                                            sx={{
                                                ml:1,
                                                // maxWidth:35,
                                                maxHeight:40,
                                            }}
                                        />

                                    </Box>
                                ) 
                            }
                        </b>                    
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Fraccionamiento: <b>{lote.manzana.etapa.fraccionamiento.nombre}</b>
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Manzana: <b>{lote.manzana.nombre}</b>
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Lote: <b>{lote.numeroDeLote}</b>
                    </Typography>
                </CardContent>
                <CardActions>
                    <Box
                        sx={{ 
                            // backgroundColor:'green',
                            width:'100%',
                            textAlign:'center',
                        }}
                    >
                        <Button 
                            size="small" 
                            endIcon={<ArrowForwardIcon />} 
                            sx={{ color:'#f5323f', textTransform: 'none'  }}
                            //onClick={() => navigate(`/modelo/${modelo.nombre}`, {state:{modelo:modelo}})}
                        >
                            <b>Ver más</b>
                        </Button>
                    </Box>
                </CardActions>
            </CardActionArea>
                
        </Card>
    );
}
export { TiendaItem }