import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

function CalendlyModal({children}) {
  const calendlyURL =
    "https://calendly.com/casas-pecsa/videollamada?primary_color=eb3235"; // Reemplaza con tu enlace de Calendly

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleButtonClick = () => {
    handleOpen();
  };

  return (
    <div>
			<div onClick={handleButtonClick}>
				{children}
			</div>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>Agendar Videollamada</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <iframe
              title="Calendly"
              src={calendlyURL}
              width="100%"
              height="600px"
              frameBorder="0"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export { CalendlyModal };