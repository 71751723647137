import React, { useEffect, useState } from "react";
import Carousel from 'react-material-ui-carousel'
import { Box, Container, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography, SvgIcon, Button, IconButton, MobileStepper, CardMedia } from "@mui/material";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

// import { ReactComponent as terreno } from '../../assets/terreno_icon.svg'
// import { ReactComponent as construccion } from '../../assets/construccion_icon.svg'
// import { ReactComponent as habitaciones } from '../../assets/habitaciones_icon.svg'
import {ReactComponent as banito} from '../../assets/banito.svg'
// import { ReactComponent as casita } from '../../assets/casita.svg'
import BedIcon from '@mui/icons-material/Bed';
import WcIcon from '@mui/icons-material/Wc';
// import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

import m146 from '../../assets/casas/m146_1.png';
import { useRef } from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import BanioPrueba from '../../assets/banioPrueba.svg';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { getLoteConPrecioMasBajo } from "../../functions/getLoteConPrecioMasBajo";

const Principal = ({ modelo }) => {
    let navigate = useNavigate();
    // console.log(modelo);
    // const [modelo, setModelo] = useState(props.modelo || {});

    const [selectedFachada, setSelectedFachada] = useState(null);
    const [selectedFraccionamiento, setSelectedFraccionamiento] = useState(null);
    const [selectedTerreno, setSelectedTerreno] = useState(null);
    
    const getFachadas = (modeloFachadas) => {
        // return modeloFachadas.filter((fachada) => { if(selectedFachada){ return fachada.id === selectedFachada.id } else{ return fachada } })
        return modeloFachadas.filter((fachada) => { return fachada  })
    }
    const getFraccionamientos = (modeloFachadas) => {
        const fraccionamientos = [];
        modeloFachadas.map((fachada)=> {
            fachada.lotes.map((lote) => {
                if(!fraccionamientos.find((fraccionamiento) => { return fraccionamiento.id === lote.manzana.etapa.fraccionamiento.id })){
                    fraccionamientos.push(lote.manzana.etapa.fraccionamiento)
                }                
            })
        })
        return fraccionamientos;
    }
    const getTerrenos = (modeloFachadas) => {
        const terrenos = [];
        modeloFachadas.filter((fachada) => { 
            fachada.lotes.map((lote) => {
                if(!terrenos.find((terreno) => { return terreno.id === lote.terreno.id})){
                    terrenos.push(lote.terreno);
                }                
            });
        });
        return terrenos;
    }
    const getFiltros = (modeloFachadas) => {

        const newModelo = modeloFachadas.filter((fachada) => {
            return selectedFachada ? (fachada.id === selectedFachada.id) : fachada
        })/*.filter((fachada) => {
            return selectedFraccionamiento ? (
                fachada.lotes.find((lote) => {
                    return lote.manzana.etapa.fraccionamiento.id === selectedFraccionamiento.id
                })
            ) : fachada
        })*/
        
        setFraccionamientos(getFraccionamientos(newModelo))
        // setFotos(newModelo.map((fachada) => { return fachada.fotos[0].url }));
        setFotos(newModelo.map((fachada) => { return fachada.fotos.map((foto) => { return foto }) }).flat(2));
        setFotoPrincipal(newModelo[0].fotos[0].url);
    }
    const [fachadas, setFachadas] = useState(modelo.fachadas);
    const [fraccionamientos, setFraccionamientos] = useState([]);
    const [terrenos, setTerrenos] = useState(getTerrenos(modelo.fachadas));
    
    const getFormatoMoneda = (cantidad) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD', //MXN
            minimumFractionDigits: 0
          })
      
          var value = 10000
      
          return formatter.format(cantidad)
    }
    // const prueb = () => {
    //     getFormatoMoneda(1200)
    // }
    // prueb();

    const reedirigir = () =>{
        // console.log('sss');
        window.open(modelo.linkTourVirtual)
    }
    const reedirigirCalendly = () =>{
        window.open('https://calendly.com/casas-pecsa')
    }
    const reedirigirATienda = (fraccionamiento) =>{
        
        if(fraccionamiento){
            navigate(`/tienda?modelofilter=${modelo.nombre.replace(/ /g, "+")}&fraccionamientofilter=${fraccionamiento.nombre?fraccionamiento.nombre.replace(/ /g, "+"):''}`)
        }else{
            navigate(`/tienda?modelofilter=${modelo.nombre.replace(/ /g, "+")}&fraccionamientofilter=${''}`)
        }
        // navigate('/tienda')
    }

    const [fotoPrincipal, setFotoPrincipal] = useState(null);
    // const [fotos, setFotos] = useState([]);
    const [fotos, setFotos] = useState(
        modelo.fachadas.map((fachada) =>{ return fachada.fotos })
    );
    const [interiores, setInteriores] = useState(
        modelo.interiores
    );

    const constraintsRef = useRef(null);
    const containerRef = useRef(null);

    const [Xside, setXside] = React.useState(0);
    const [XIside, setXIside] = React.useState(0);

    const [constraintsWidth, setConstraintsWidth] = React.useState(0);
    const [containerWidth, setContainerWidth] = React.useState(0);

    const atras = (Xside) => {
        setXside(Xside+395.96);
        setXIside((XIside - 384) < constraintsWidth ? constraintsWidth : (XIside - 384))
        // console.log(constraintsRef.current.clientWidth);
        // console.log(containerRef.current.clientWidth);
    };
    const adelante = (Xside) => {
        setXside(Xside-395.96);
        setXIside((XIside + 384) > containerWidth ? containerWidth : (XIside + 384))
        // console.log(constraintsRef.current.clientWidth);
        // console.log(containerRef.current.clientWidth);
    };
    useEffect(() => {
        setXIside(constraintsRef.current.clientWidth);
        setConstraintsWidth(constraintsRef.current.clientWidth)
        setContainerWidth(containerRef.current.clientWidth)
        console.log('constraintsRef:::::', constraintsRef.current.clientWidth)
        console.log('containerRef:::::', containerRef.current.clientWidth)
        console.log('Xside:::::', Xside)
        console.log('XIside:::::', XIside)
        //
      }, [setXIside, setConstraintsWidth, setContainerWidth]);
    useEffect(()=>{
        // console.log('useefect');        
        getFiltros(modelo.fachadas);
    }, [selectedFachada])
    useEffect(()=>{
        setFachadas(modelo.fachadas);
        setFraccionamientos([])
        // setFotos(modelo.fachadas.map((fachada) => { return fachada.fotos[0].url }));
        setFotos((modelo.fachadas.map((fachada) => { return fachada.fotos.map((foto) => { return foto }) })).flat(2));
        // console.log((modelo.fachadas.map((fachada) => { return fachada.fotos.map((foto) => { return foto }) })).flat(2));
        setFotoPrincipal(modelo.fachadas[0].fotos[0].url)
        // setFotos([...fotos, ]);
        // console.log(
        //     interiores
        // );
        setInteriores(modelo.interiores);
        // setFotos(modelo.fachadas.map((fachada) => { return fachada.fotos[0].url }));
        // setFotoPrincipal(m146)
        // console.log('constraintsRef:::::', constraintsRef.current.clientWidth)
        // console.log('containerRef:::::', containerRef.current.clientWidth)
        // setConstraintsWidth(constraintsRef.current.clientWidth)
        // setContainerWidth(containerRef.current.clientWidth)
        // console.log('constraintsRef:::::', constraintsRef.current.clientWidth)
        // console.log('containerRef:::::', containerRef.current.clientWidth)
    }, [modelo]);
    return(
        <Container sx={{ backgroundColor:'white' }}>
            <Grid 
                container 
                //columnSpacing={{ xs: 0, sm: 2, md: 2, lg: 2 }}
                sx={{
                    pb:3
                }}
                //spacing={1}
            >
                <Grid item xs={12} sm={7} md={7} sx={{ display: { xs:'none', sm:'flex', md:'flex' } }}>
                    <Box
                        sx={{
                            // backgroundColor:'red',
                            width:'100%',
                            height:{ sm:500, md:620, }
                        }}
                    >
                        <Box
                            sx={{
                                // backgroundColor:'green',
                                width:'100%',
                                height: {  sm:400, md:500 },
                                display:'flex',
                                justifyContent:'center',
                            }}
                        >
                            {
                                fotoPrincipal && (
                                    <Box
                                        component="img"
                                        src={fotoPrincipal}

                                        // sx={{
                                        //     width:'95%',
                                        //     m:1,
                                            
                                        // }}
                                        sx={{
                                            //width:{  sm:'95%', md:'95%' },
                                            m:1,
                                            maxWidth: { sm:'85%', md:'90%' },
                                            maxHeight: {  sm:400, md:500 }
                                            
                                        }}
                                    />
                                )
                            }
                        </Box>
                        <Box
                            component={motion.div}
                            ref={constraintsRef}
                            sx={{
                                backgroundColor:'white', 
                                display: 'flex',
                                placeContent: 'start',
                                placeItems: 'center',
                                overflow: 'hidden',
                                position:'relative'
                            }}
                        >
                            <Stack
                                    ref={containerRef}
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    //spacing={2}
                                    component={motion.div}
                                    animate={{ x:Xside }}
                                    
                            >
                                {
                                    fotos.length > 0 && (
                                        fotos.map((foto) => (
                                            <Box
                                                sx={{ 
                                                    // width: '375px'
                                                    // width: '185px'
                                                    // backgroundColor:'green',
                                                    display:'flex',
                                                    justifyContent:'center',
                                                    width:{ xs: '55px', sm:98, md:130 },
                                                    height:{sm:95, md:100},
                                                    mr:2,
                                                    // pointerEvents: 'none',
                                                    
                                                                                        
                                                }}
                                            >
                                                <Box
                                                    component="img"
                                                    sx={{ 
                                                        // width: '375px'
                                                        // width: '185px'
                                                        //width:{ xs: '55px', sm:'100px', md:'100%' },
                                                        maxWidth:{ xs: '55px', sm:98, md:130 },
                                                        maxHeight:{sm:95, md:100}
                                                        
                                                        // pointerEvents: 'none',
                                                        
                                                                                            
                                                    }}
                                                    src={foto.url}
                                                    onClick={()=>{ setFotoPrincipal(foto.url) }}
                                                />
                                            </Box> 
                                        ))
                                    )
                                }
                                {
                                    interiores.length > 0 && (
                                        interiores.map((interior) => (
                                            <Box
                                                sx={{ 
                                                    // width: '375px'
                                                    // width: '185px'
                                                    // backgroundColor:'green',
                                                    display:'flex',
                                                    justifyContent:'center',
                                                    maxWidth:{ xs: '55px', sm:98, md:130 },
                                                    height:{sm:95, md:100},
                                                    mr:2,
                                                    // pointerEvents: 'none',
                                                    
                                                                                        
                                                }}
                                            >
                                                <Box
                                                    component="img"
                                                    sx={{ 
                                                        // width: '375px'
                                                        // width: '185px'
                                                        //width:{ xs: '55px', sm:'100px', md:'100%' },
                                                        maxWidth:{ xs: '55px', sm:98, md:130 },
                                                        maxHeight:{sm:95, md:100}
                                                        
                                                        // pointerEvents: 'none',
                                                        
                                                                                            
                                                    }}
                                                    src={interior.imagen}
                                                    onClick={()=>{ setFotoPrincipal(interior.imagen) }}
                                                />
                                            </Box>
                                        ))
                                    )
                                }
                            </Stack>
                        </Box>

                                <Stack
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    spacing={2}
                                    sx={{
                                        paddingRight:3,
                                        mb:3,
                                    }}
                                >
                                    <IconButton aria-label="delete" size="small" onClick={()=> atras(Xside)} disabled={XIside !== constraintsWidth ? false : true}>
                                        <ChevronLeftIcon />
                                    </IconButton>
                                    <IconButton aria-label="delete" size="small" onClick={()=> adelante(Xside)} disabled={XIside !== containerWidth ? false : true}>
                                        <ChevronRightIcon />
                                    </IconButton>
                                </Stack>

                    </Box>
                    {/* <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"                        
                    >
                        {
                            fotoPrincipal && (
                                <Box
                                    component="img"
                                    src={fotoPrincipal}

                                    sx={{
                                        width:'95%',
                                        m:1,
                                        
                                    }}
                                />
                            )
                        }                                            
                            <Box
                                component={motion.div}
                                ref={constraintsRef}
                                sx={{
                                    backgroundColor:'white', 
                                    display: 'flex',
                                    placeContent: 'start',
                                    placeItems: 'center',
                                    overflow: 'hidden',
                                    position:'relative'
                                }}
                            >
                                <Stack
                                        ref={containerRef}
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={2}
                                        component={motion.div}
                                        animate={{ x:Xside }}
                                        
                                >
                                    {
                                        fotos.length > 0 && (
                                            fotos.map((foto) => (
                                                <Box
                                                    component="img"
                                                    sx={{ 
                                                        // width: '375px'
                                                        // width: '185px'
                                                        width:{ xs: '55px', sm:'100px', md:'140px', lg:'140px' },
                                                        // pointerEvents: 'none',
                                                        
                                                                                            
                                                    }}
                                                    src={foto.url}
                                                    onClick={()=>{ setFotoPrincipal(foto.url) }}
                                                /> 
                                            ))
                                        )
                                    }
                                </Stack>
                            </Box>
                        {
                            fotos.length > 3 && (
                                <Stack
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    spacing={2}
                                    sx={{
                                        paddingRight:3
                                    }}
                                >
                                    <IconButton aria-label="delete" size="small" onClick={()=> atras(Xside)}>
                                        <ChevronLeftIcon />
                                    </IconButton>
                                    <IconButton aria-label="delete" size="small" onClick={()=> adelante(Xside)}>
                                        <ChevronRightIcon />
                                    </IconButton>
                                </Stack>
                            ) 
                        }
                                
                    </Stack> */}
                </Grid>
                <Grid item xs={12} sm={7} md={7} sx={{ display: { xs:'flex', sm:'none', md:'none' }, mt:0 }}>
                    <Carousel sx={{ width:'100%' }}>
                        {
                            fotos.map((foto) => (
                                <Box
                                    component="img"
                                    src={foto.url}

                                    sx={{
                                        width:'100%',
                                        height:280,
                                    
                                    }}
                                />
                            ))
                        }
                    </Carousel>
                </Grid>
                <Grid item xs={12} sm={5} md={5}>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={0.5}
                        sx={{
                            paddingTop:{ xs:1, sm:5, md: 7 }
                        }}
                    >
                        <Typography variant="h5" component="div" sx={{ color:'#DE4647' }}>
                            MODELO <b>{modelo.nombre}</b>
                        </Typography>
                        <Typography variant="h4" component="div" sx={{ color:'#CDCDCD' }}>
                            { getFormatoMoneda(getLoteConPrecioMasBajo(modelo.fachadas.map((fachada)=>fachada.lotes).flat()).precio) /*getFormatoMoneda(modelo.precio)*/ }
                        </Typography>
                        <Grid 
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            // spacing={1}
                        >                            
                            <Grid item md={6}>
                                <Box
                                    sx={{ 
                                        width:'100%', 
                                        // backgroundColor:'red', 
                                        display:'flex',
                                        alignItems:'center',
                                        pl:1,
                                        pt:1,
                                        color:'#707070'
                                    }}
                                >
                                    <DesignServicesIcon 
                                        sx={{
                                            width:30,
                                            height:25,
                                        }}
                                    />
                                    <Typography>
                                        {modelo.superficieDeConstruccion} m² de construcción
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={6}>
                                <Box
                                    sx={{ 
                                        width:'100%', 
                                        // backgroundColor:'red', 
                                        display:'flex',
                                        alignItems:'center',
                                        pl:1,
                                        pt:1,
                                        color:'#707070'
                                    }}
                                >
                                    <DirectionsCarIcon 
                                        sx={{
                                            width:30,
                                            height:25,
                                        }}
                                    />
                                    <Typography>
                                        Cochera
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={6}>
                                <Box
                                    sx={{ 
                                        width:'100%', 
                                        // backgroundColor:'red', 
                                        display:'flex',
                                        alignItems:'center',
                                        pl:1,
                                        pt:1,
                                        color:'#707070'
                                    }}
                                >
                                    <BedOutlinedIcon 
                                        sx={{
                                            width:30,
                                            height:25,
                                        }}
                                    />
                                    <Typography>
                                        <b>{modelo.habitaciones}</b> Habitaciones
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={6}>
                                <Box
                                    sx={{ 
                                        width:'100%', 
                                        // backgroundColor:'red', 
                                        display:'flex',
                                        alignItems:'center',
                                        pl:1,
                                        pt:1,
                                        color:'#707070'
                                    }}
                                >
                                    <Box
                                        component='img'
                                        src={BanioPrueba}
                                        sx={{
                                            width:25,
                                            height:20,
                                            color:'green',
                                        }}
                                    />
                                    <Typography>
                                        <b>{modelo.banios}</b> Baños
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid 
                            container 
                            //columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 2 }}
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            sx={{ color:'#8A8A8A' }}
                        >
                            <Grid item xs={12} sm={12} md={12}>
                                <Grid 
                                    container 
                                    
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"                                    
                                >
                                    <Grid item xs={12} sm={12} md={4}>
                                        <Typography variant="label" component="div">
                                            <b>Fachada</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8}>
                                        <FormControl sx={{ m: { xs:'none', sm:1, md:1 }, width: { xs:'100%', sm:'80%', md:'90%' } }} size="small">                                            
                                            <Select                                                
                                                id="demo-select-small"
                                                // onChange={(e)=>{console.log(e.target.value)}}
                                            >
                                                {
                                                    fachadas.map((fachada)=>(
                                                        <MenuItem value={fachada.id} onClick={()=>{ setSelectedFachada(fachada) }}>{fachada.nombre}</MenuItem>
                                                    ))
                                                }
                                                
                                            </Select>                                            
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* <Grid item lg={7}>
                                <Grid 
                                    container 
                                    spacing={1} 
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <Typography variant="label" component="div">
                                            Terreno
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControl sx={{ m: 1, minWidth: 120, }} size="small">
                                            
                                            <Select                                                
                                                id="demo-select-small"
                                            >
                                                {
                                                    modelo && (
                                                        terrenos.map((terreno)=>(                                                            
                                                            <MenuItem value={terreno.id} onClick={()=>{ setSelectedTerreno(terreno) }}>{terreno.nombre}</MenuItem>
                                                        ))
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid> */}
                            <Grid item xs={12} sm={12} md={12}>
                                <Grid 
                                    container 
                                    spacing={1} 
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <Grid item xs={12} sm={12} md={4}>
                                        <Typography variant="label" component="div">
                                            <b>Fraccionamiento</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8}>
                                        <FormControl sx={{ m: { xs:'none', sm:1, md:1 }, width: { xs:'100%', sm:'80%', md:'90%' } }} size="small">
                                            
                                            <Select                                                
                                                id="demo-select-small"
                                                sx={{
                                                    borderColor:'red'
                                                }}
                                            >   
                                                {
                                                    modelo  && (
                                                        fraccionamientos.length > 0 ? (
                                                            fraccionamientos.map((fraccionamiento)=>(                                                            
                                                                <MenuItem value={fraccionamiento.id} onClick={()=>{ setSelectedFraccionamiento(fraccionamiento) }}>{fraccionamiento.nombre}</MenuItem>
                                                            ))
                                                        ) : (
                                                            <MenuItem value={0} disabled>Elige una Fachada</MenuItem>
                                                        )                                                        
                                                    )
                                                }
                                                {/* {
                                                    fachadas.length > 0 && (
                                                        fachadas.map((fachada) => {
                                                            if(fachada.lotes.length > 0){
                                                                fachada.lotes.map((lote) => (
                                                                    <MenuItem value={lote.manzana.etapa.fraccionamiento.id}>{lote.manzana.etapa.fraccionamiento.nombre}</MenuItem>
                                                                ))
                                                            }                                                        
                                                        })
                                                    )
                                                    
                                                } */}
                                                {/* <MenuItem value={10}>Lomas del Bosque</MenuItem>
                                                <MenuItem value={20}>El Rocal</MenuItem> */}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid 
                            container 
                            //columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 2 }}
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            sx={{ display: 'none' }}
                        >
                            <Grid item lg={5}>
                                <Grid container>
                                    <Grid item>
                                        {/* <SvgIcon component={habitaciones} sx={{ fontSize: '2em' }} /> */}
                                        <BedIcon fontSize="large"/>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="p" component="div" sx={{ marginLeft:1, marginRight:1, marginTop:1 }}>
                                            4 Habitaciones
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item lg={5}>
                                <Grid container>
                                    <Grid item>
                                        {/* <SvgIcon component={banito} sx={{ fontSize: '1em' }} /> */}
                                        <WcIcon fontSize="large"/>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="p" component="div" sx={{ marginLeft:1, marginRight:1, marginTop:1 }}>
                                            3 Baños
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item lg={5}>
                                <Grid container>
                                    <Grid item>
                                        {/* <SvgIcon component={habitaciones} sx={{ fontSize: '2em' }} /> */}
                                        <DirectionsCarIcon fontSize="large"/>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="p" component="div" sx={{ marginLeft:1, marginRight:1, marginTop:1 }}>
                                            Cochera 2 Vehiculos
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item lg={5}>
                                <Grid container>
                                    <Grid item>
                                        <SvgIcon component={banito} fontSize='large' />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="p" component="div" sx={{ marginLeft:1, marginRight:1, marginTop:1 }}>
                                            Terreno
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid 
                            container 
                            spacing={1}
                        >
                            <Grid item xs={12} sm={12} md={6}>
                                <Button variant="outlined" size="small" color="error" 
                                    sx={{ /*fontSize:'10px',*/ 
                                        width: { xs:'95%', sm:'90%', md:'100%' }, 
                                        p:{ xs:'none', sm:'none', md:1 },
                                        borderColor:'#F5323F',
                                        color:'#F5323F',
                                    }} 
                                    onClick={reedirigir}
                                    disabled={modelo.linkTourVirtual ? modelo.linkTourVirtual !== '' ? false : true : true}
                                >
                                    <b>RECORRIDO VIRTUAL</b>
                                </Button>
                            </Grid>                            
                            <Grid item xs={12} sm={12} md={6}>
                                <Button variant="outlined" size="small" color="error" 
                                    sx={{ /*fontSize:'10px',*/ 
                                        width: { xs:'95%', sm:'90%', md:'100%' }, 
                                        p:{ xs:'none', sm:'none', md:1 },
                                        borderColor:'#F5323F',
                                        color:'#F5323F',
                                    }} 
                                    // onClick={reedirigirCalendly}
                                    onClick={()=>{ navigate('/citas'); }}
                                >
                                    <b>AGENDAR CITA</b>
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Button 
                                    variant="outlined" 
                                    size="small" 
                                    color="error" 
                                    sx={{ /*fontSize:'11px',*/ 
                                        width: { xs:'95%', sm:'90%', md:'100%' }, 
                                        p:{ xs:1, sm:1, md:1 },
                                        borderColor:'#F5323F',
                                        color:'#F5323F',
                                    }} 
                                    onClick={()=>reedirigirATienda(selectedFraccionamiento)}
                                >
                                    <b>VER CASA</b>
                                </Button>
                            </Grid>
                        </Grid>
                        {/* <Typography variant="h6" component="div" sx={{ textAlign:'left' }}>
                            Seleccionar tamaño, terreno, y fraccionamiento y cambia el precio acorde
                        </Typography> */}
                    </Stack>
                </Grid>
            </Grid>
        </Container>
    );
}

export { Principal }