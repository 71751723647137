import React, {useState,useEffect} from 'react';
import axios from "axios";
import { Autocomplete, Button, ButtonBase, Checkbox, Container, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation, useParams, Link, NavLink, Navigate } from "react-router-dom";

import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import sinlogo from '../../assets/sinlogo.png';

import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import ClearIcon from '@mui/icons-material/Clear';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const EditFraccionamiento = () => {
    let navigate = useNavigate();
    const location = useLocation();
    let parametros = useParams();
    console.log('parametros', parametros);

    const [nombre, setNombre] = useState(null);
    const [fraccionamiento, setFraccionamiento] = useState(null);

    const [ciudades, setCiudades] = useState([]);
    const [selectedCiudad, setSelectedCiudad] = useState(null);

    const [promociones, setPromociones] = useState([]);
    const [selectedPromocion, setSelectedPromocion] = useState(null);

    // const [selectedImage, setSelectedImage] = useState(null);
    // const [imageUrl, setImageUrl] = useState(null);
    const [selectedImage, setSelectedImage] = useState([]);
    const [imageUrl, setImageUrl] = useState(null);
    const [imageUrlAWS, setImageUrlAWS] = useState(null);

    const [storageImages, setStorageImages] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [imagesUrl, setImagesUrl] = useState([]);

    const [selectedMapa, setSelectedMapa] = useState([]);
    const [mapaUrl, setMapaUrl] = useState(null);
    const [mapaUrlAWS, setMapaUrlAWS] = useState(null);

    const [storageImagesAWS, setStorageImagesAWS] = useState([]);
    const [imagesUrlAWS, setImagesUrlAWS] = useState([]);

    const [principal, setPrincipal] = useState(0);

    const edit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // // // const data = new FormData();
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');
        //var formData = new FormData();
        // // // data.append('idMpio', selectedCiudad.id);
        data.set('idMpio', selectedCiudad.id);
        data.set('id', fraccionamiento.id);
        data.set('principal', principal);
        data.delete('prefotosImg');
        storageImages.map((foto) => {
            data.append('fotosImg', foto);
        });
        data.append('imagenesBorrarAWS', storageImagesAWS);
        
        data.set('folioUpdatedBy', folio);

        // console.log(storageImagesAWS);
        // console.log(data);
        // return;
        if(selectedPromocion === 'Sin Promocion'){
            data.set('idPromocion', null);
        }else{
            data.set('idPromocion', selectedPromocion);
        }        
        const prueba = {
            nombre: data.get('nombre'),
            idMpio: selectedCiudad.id,
            logoImg: selectedImage,
            fotosImg: storageImages,
            folioCreateBy: folio,
        }
        //console.log('prueba::::', prueba); return;
        // console.log('data::::', data.get('fotosImg')); return;

        // for(const pair of data.entries()) {
        //     console.log(`${pair[0]}, ${pair[1]}`);
        // }
        // return;
        axios.post(`${process.env.REACT_APP_API_URL}/api/fraccionamiento/update`, 
            // // {
            // //     nombre: data.get('nombre'),
            // //     idMpio: selectedCiudad.id,
            // //     logoImg: selectedImage,
            // //     fotosImg: storageImages,
            // //     folioCreateBy: folio,
            // // },
            data,
            {
              headers: { 
                Authorization: `Bearer ${token}`,   
                'Content-Type': 'multipart/form-data',               
                //ContentType: 'multipart/form-data'
              }              
        })
        .then((response) => {
            // handle success            
            console.log(response);
            console.log('success axio');
            //navigate(-1, { replace: true });
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sabe');
        });
    }
    const getPromocion = (event) => {
        setSelectedPromocion(event.target.value);
      };
    const removeImagenAWS = (id) => {
        storageImagesAWS.push(id);
    }
    const removeImagen = (index) => {
        storageImages.splice(index, 1);
        imagesUrl.splice(index, 1);
        setStorageImages(storageImages);
        setImagesUrl(imagesUrl);
    }
    useEffect(() => {
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        
        if (selectedImage.length > 0) {
            setImageUrl(URL.createObjectURL(selectedImage[0]));
        }
        if (selectedImages.length > 0) {
            // console.log('imagenes seleccionsadoas: ', selectedImages);
            for (let i = 0; i < selectedImages.length; i++) {
                if(!storageImages.find( element => element === selectedImages[i])){
                    storageImages.push(selectedImages[i]);
                    imagesUrl.push(URL.createObjectURL(selectedImages[i]))
                }                
            }
            // console.log('imagenes en storage: ', storageImages);
            // console.log('imagenes seleccionadoas: ', imagesUrl);
        }

        axios.get(`${process.env.REACT_APP_API_URL}/api/producto/updateview/${parametros.fraccionamiento}`, {
              headers: {
                  Authorization: `Bearer ${token}`
              }
          })
          .then((response) => {
            // handle success
            console.log(response);
            setFraccionamiento(response.data.fraccionamiento);
            setNombre(response.data.fraccionamiento.nombre);
            setSelectedCiudad(response.data.fraccionamiento.municipio);
            setImagesUrlAWS(response.data.fraccionamiento.fotos);
            setImageUrlAWS(response.data.fraccionamiento.logo);
            setCiudades(response.data.ciudades);
            setPromociones(response.data.promociones);
          })
          .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
          })
          .then(() => {
            // handle success
          });
    }, [setCiudades, selectedImage, selectedImages, imagesUrl, setImageUrl]);
    return(
        <Container sx={{ mt:12 }}>
            <Box component="form" onSubmit={edit} encType="multipart/form-data" >
                <h2>Nuevo Fraccionamiento</h2>
                <Grid 
                    container 
                    spacing={3}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    
                >
                    <Grid item lg={7} sx={{ p:1 }}>
                        <Stack spacing={3}>
                            {
                                nombre ? 
                                (<TextField id="outlined-basic" label="Nombre" variant="outlined" name="nombre" value={nombre} onChange={(e) => setNombre(e.target.value) }  required/>) : 
                                (<TextField id="outlined-basic" label="Nombre" variant="outlined" name="nombre" onChange={(e) => setNombre(e.target.value) }  required/>)
                            }                            
                            <Autocomplete
                                id="grouped-demo"
                                options={ciudades}
                                groupBy={(ciudad) => ciudad.estado.nombre}
                                getOptionLabel={(ciudad) => ciudad.nombre}
                                value={selectedCiudad}
                                onChange={(event, newCiudad)=>{setSelectedCiudad(newCiudad);}}
                                sx={{ width: 400 }}
                                renderInput={(params) => <TextField {...params} label="Ciudad" name="ciudad" required/>}
                            />
                        </Stack>                        
                    </Grid>
                    <Grid item lg={5} > 
                        {
                            imageUrlAWS && !imageUrl ? (
                                <Box
                                    component="img"
                                    src={imageUrlAWS}
                                    sx={{
                                        width: 400,
                                        backgroundColor: 'white',
                                        border: 10,
                                        borderColor: '#f4f4f4',
                                        borderRadius: '9px',
                                        m:3,
                                        boxShadow: 3
                                    }}
                                />
                            ) :
                            imageUrl ? selectedImage ? (
                                <Box
                                    component="img"
                                    src={imageUrl}
                                    sx={{
                                        width: 400,
                                        backgroundColor: 'white',
                                        border: 10,
                                        borderColor: '#f4f4f4',
                                        borderRadius: '9px',
                                        m:3,
                                        boxShadow: 3
                                    }}
                                />
                            ) : (
                                <Box
                                    component="img"
                                    src={sinlogo}
                                    sx={{
                                        width: 400,
                                        backgroundColor: 'white',
                                        border: 10,
                                        borderColor: '#f4f4f4',
                                        borderRadius: '9px',
                                        m:3,
                                        boxShadow: 3
                                    }}
                                />
                            ) : (
                                <Box
                                    component="img"
                                    src={sinlogo}
                                    sx={{
                                        width: 400,
                                        backgroundColor: 'white',
                                        border: 10,
                                        borderColor: '#f4f4f4',
                                        borderRadius: '9px',
                                        m:3,
                                        boxShadow: 3
                                    }}
                                />
                            )
                        }
                        
                        <label htmlFor="contained-button-file">                            
                            <input accept="image/*" id="contained-button-file" type="file" name="logoImg" style={{ display: 'none' }} onChange={e => setSelectedImage([e.target.files[0]])}/>
                            <Button endIcon={<AddAPhotoIcon />} component="span">
                                Cargar/Cambiar Logo
                            </Button>
                        </label> 
                    </Grid>
                </Grid>
                <Button variant="contained" type="submit">Guardar</Button>
                <Divider variant="middle" />
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    rowSpacing={1}
                    sx={{
                        pt:2,
                        pb:2
                    }}
                >
                    <Grid item xs={12} sm={12} md={12}>
                        <Box>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Grid item xs={12} sm={4} md={3}
                                    sx={{
                                        // backgroundColor:'green',
                                        height:240,
                                        p:1
                                    }}
                                >
                                    <label htmlFor="contained-button-files-fotosfraccionamiento">
                                        {/* <input accept="image/*" type="file" name="fotosImg" style={{ display: 'none' }} multiple/> */}
                                        <input accept="image/*" id="contained-button-files-fotosfraccionamiento" name="prefotosImg" type="file" multiple style={{ display: 'none' }} onChange={e => setSelectedImages(e.target.files)}/>                                                            
                                        <ButtonBase component="span" sx={{ width: '100%', height:'100%', }}>
                                            <Box
                                                component='div'
                                                sx={{
                                                    width: '100%',
                                                    
                                                    borderRadius: '8px',
                                                    backgroundColor:'#F4F4F4',
                                                    textAlign: 'center',
                                                    boxShadow: 4,
                                                    // pt:6,
                                                    // pb:6
                                                }}

                                            >
                                                <Box
                                                    sx={{
                                                        m:3
                                                    }}
                                                >
                                                <AddAPhotoIcon/>
                                                <Typography variant="body1" gutterBottom component="div">
                                                    <b>Cargar Foto</b>
                                                </Typography>
                                                </Box>
                                            </Box>
                                        </ButtonBase>
                                    </label>
                                </Grid>
                                {
                                    imagesUrlAWS && (
                                        imagesUrlAWS.map((imagen, index) => (
                                            <Grid item xs={12} sm={4} md={3}
                                                sx={{
                                                    // backgroundColor:'yellow',
                                                    height:240,
                                                    p:1
                                                }}
                                            >
                                                <Box
                                                    component='div'
                                                    sx={{
                                                        width:'100%', 
                                                        height: '100%',                                                                   
                                                        borderRadius: '8px',
                                                        boxShadow: 8,
                                                        position: 'relative',
                                                        backgroundColor:'white',
                                                        backgroundImage: `url(${imagen.url})`,
                                                        backgroundPosition: 'center',
                                                        backgroundSize: 'cover'
                                                    }}
                                                    key={imagen.id}
                                                    //onClick={ () => { setImageUrl(imagen) } }
                                                >
                                                    {/* <img src={imagen} style={{ width:'100%', display:'block', objectFit:'cover' }}/> */}
                                                    <Checkbox 
                                                        {...label} 
                                                        icon={<StarOutlineRoundedIcon sx={{ color:'gray' }}/>} 
                                                        checkedIcon={<StarRoundedIcon sx={{ color:'gold' }}/>}
                                                        sx={{
                                                            position: 'absolute',
                                                            bottom:0, 
                                                            left:0,
                                                        }}
                                                        checked={index === principal ? (true) : (false)} 
                                                        onChange={ () => { setPrincipal(index) } }
                                                    />
                                                    <IconButton
                                                        sx={{ 
                                                            color: 'rgba(128, 128, 128, 0.85)',
                                                            position: 'absolute',
                                                            top:0, 
                                                            right:0,
                                                        }}
                                                        key={imagen.id}
                                                        onClick={ () => { removeImagenAWS(imagen.id) } }
                                                    >
                                                        <ClearIcon />
                                                    </IconButton>
                                                    {/* <IconButton
                                                        sx={{ 
                                                            color: 'rgba(255, 255, 255, 0.85)',
                                                            position: 'absolute',
                                                            bottom:0, 
                                                            left:0,
                                                        }}
                                                        onClick={ () => {  } }
                                                    >
                                                        <StarOutlineRoundedIcon />
                                                    </IconButton> */}
                                                </Box>
                                            </Grid>
                                        ))
                                    )
                                }
                                {
                                    imagesUrl && (
                                        imagesUrl.map((imagen, index) => (
                                            <Grid item xs={12} sm={4} md={3}
                                                sx={{
                                                    // backgroundColor:'yellow',
                                                    height:240,
                                                    p:1
                                                }}
                                            >
                                                <Box
                                                    component='div'
                                                    sx={{
                                                        width:'100%', 
                                                        height: '100%',                                                                   
                                                        borderRadius: '8px',
                                                        boxShadow: 8,
                                                        position: 'relative',
                                                        backgroundColor:'white',
                                                        backgroundImage: `url(${imagen})`,
                                                        backgroundPosition: 'center',
                                                        backgroundSize: 'cover'
                                                    }}                                                    
                                                    //onClick={ () => { setImageUrl(imagen) } }
                                                >
                                                    {/* <img src={imagen} style={{ width:'100%', display:'block', objectFit:'cover' }}/> */}
                                                    <Checkbox 
                                                        {...label} 
                                                        icon={<StarOutlineRoundedIcon sx={{ color:'gray' }}/>} 
                                                        checkedIcon={<StarRoundedIcon sx={{ color:'gold' }}/>}
                                                        sx={{
                                                            position: 'absolute',
                                                            bottom:0, 
                                                            left:0,
                                                        }}
                                                        checked={imagesUrlAWS.length + index === principal ? (true) : (false)} 
                                                        onChange={ () => { setPrincipal(imagesUrlAWS.length + index) } }
                                                    />
                                                    <IconButton
                                                        sx={{ 
                                                            color: 'rgba(128, 128, 128, 0.85)',
                                                            position: 'absolute',
                                                            top:0, 
                                                            right:0,
                                                        }}
                                                        onClick={ () => { removeImagen(index) } }
                                                    >
                                                        <ClearIcon />
                                                    </IconButton>
                                                    {/* <IconButton
                                                        sx={{ 
                                                            color: 'rgba(255, 255, 255, 0.85)',
                                                            position: 'absolute',
                                                            bottom:0, 
                                                            left:0,
                                                        }}
                                                        onClick={ () => {  } }
                                                    >
                                                        <StarOutlineRoundedIcon />
                                                    </IconButton> */}
                                                </Box>
                                            </Grid>
                                        ))
                                    )
                                }

                            </Grid>
                        </Box>
                    </Grid> 
                    <Grid item xs={12} sm={12} md={4}>

                    </Grid>
                                                                
                </Grid>
                <Divider variant="middle" />
                <Grid 
                    container 
                    spacing={3}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    
                >
                    <Grid item lg={5} > 
                        {
                            mapaUrlAWS && !mapaUrl ? (
                                <Box
                                    component="img"
                                    src={mapaUrlAWS}
                                    sx={{
                                        width: 400,
                                        backgroundColor: 'white',
                                        border: 10,
                                        borderColor: '#f4f4f4',
                                        borderRadius: '9px',
                                        m:3,
                                        boxShadow: 3
                                    }}
                                />
                            ) :
                            mapaUrl ? selectedImage ? (
                                <Box
                                    component="img"
                                    src={mapaUrl}
                                    sx={{
                                        width: 400,
                                        backgroundColor: 'white',
                                        border: 10,
                                        borderColor: '#f4f4f4',
                                        borderRadius: '9px',
                                        m:3,
                                        boxShadow: 3
                                    }}
                                />
                            ) : (
                                <Box
                                    component="img"
                                    src={sinlogo}
                                    sx={{
                                        width: 400,
                                        backgroundColor: 'white',
                                        border: 10,
                                        borderColor: '#f4f4f4',
                                        borderRadius: '9px',
                                        m:3,
                                        boxShadow: 3
                                    }}
                                />
                            ) : (
                                <Box
                                    component="img"
                                    src={sinlogo}
                                    sx={{
                                        width: 400,
                                        backgroundColor: 'white',
                                        border: 10,
                                        borderColor: '#f4f4f4',
                                        borderRadius: '9px',
                                        m:3,
                                        boxShadow: 3
                                    }}
                                />
                            )
                        }
                        
                        <label htmlFor="contained-button-mapa-fraccionamiento">                            
                            <input accept="image/*" id="contained-button-mapa-fraccionamiento" type="file" name="MapaImg" style={{ display: 'none' }} onChange={e => setSelectedMapa([e.target.files[0]])}/>
                            <Button endIcon={<AddAPhotoIcon />} component="span">
                                Cargar/Cambiar Mapa
                            </Button>
                        </label> 
                    </Grid>
                </Grid>
                <Divider variant="middle" />
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item md={3}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Promociones</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedPromocion}
                                label="Promociones"
                                onChange={getPromocion}
                            >
                                <MenuItem value={'Sin Promocion'}>{ 'Sin Promocion' }</MenuItem>
                                {
                                    promociones.map((promocion) => (
                                        <MenuItem value={promocion.id}>{ promocion.nombre }</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Divider variant="middle" />
            </Box>
        </Container>
    );
}

export { EditFraccionamiento }