import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  Box,
  Container,
} from "@mui/material";
import { SimpleHomeCard } from "../../../components/SimpleHomeCard";
import { CustomTypography } from "../../../components/CustomTypography";
import { Slider } from "../../../components/Slider";
import { CustomButton } from "../../../components/CustomButton";
import { InputButtonCombo } from "../../../components/Investment/InputButtonCombo";
import theme from "../../../App/theme";
import { useNavigate } from "react-router-dom";
import { CircularLoadingAnimation } from "../../../components/CircularLoadingAnimation";

const ListingSection = () => {
  const [items, setItems] = useState([]);
  const [preResult, setPreResult] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedOption, setSelectedOption] = useState("Todas");
  const isDesktop = useMediaQuery("(min-width:1100px)");

  const [presupuesto, setPresupuesto] = useState(null);

  const getPresupuesto = (newValue) => {
    setPresupuesto(newValue);
  };

  let navigate = useNavigate();

  const navigateList = () => {
    navigate("/renta/contacto");
  };

  const handleChange = (event) => {
    const selectedValue = event.target.value;

    if (isDesktop) {
      // Si estamos en una pantalla de escritorio
      setSelectedOption(selectedValue);
    } else {
      // Si estamos en un dispositivo móvil, asegúrate de que se establezca en ""
      setSelectedOption(selectedValue === selectedOption ? "" : selectedValue);
    }
  };

  const handleListSelection = (value) => {
    if (isDesktop) {
      // Si estamos en una pantalla de escritorio
      setSelectedOption(value);
    }
  };

  const fraccionamientoFiltrado = (items, fraccionamiento) => {
    if (fraccionamiento === "Todas") {
      return items;
    }
    // console.log('Filtrado',items.filter((element) => element.manzana.etapa.fraccionamiento.nombre === fraccionamiento).length);
    return items.filter(
      (element) =>
        element.manzana.etapa.fraccionamiento.nombre === fraccionamiento
    );
  };

  useEffect(() => {
    if (items.length > 0) {
      const preResult2 = items.map(
        (lote) => lote.manzana.etapa.fraccionamiento.nombre
      );
      const uniqueFraccionamientos = [...new Set(preResult2)]; // Eliminar duplicados
      setPreResult(["Todas", ...uniqueFraccionamientos]);
      // const preResult2 = items.map((lote) => lote.manzana.etapa.fraccionamiento.nombre )
      // setPreResult(preResult2.filter((element, index) => preResult2.indexOf(element) === index))
    }
  }, [items]);

  useEffect(() => {
    setLoading(true);
    let parametro = presupuesto ? `?presupuesto=${presupuesto}` : "";
    fetch(
      `${process.env.REACT_APP_API_URL}/api/lotes-inversion/en-renta${parametro}`
    )
      .then((response) => response.json())
      .then((data) => {
        setItems(data.lotesEnRenta);
        setLoading(false);
      });
  }, [presupuesto]);

  return (
    <Box
      component="section"
      id="listing"
      sx={{
        backgroundColor: theme.palette.grey[200],
        py: "2.5rem",
        [theme.breakpoints.up("sm")]: {
          py: "5rem",
        },
      }}
    >
      <Container
        sx={{
          display: "flex",
          textAlign: "center",
          flexDirection: "column",
          gap: 3.75,
        }}
      >
        <CustomTypography variant="subheadingPrimary" txtColor="primary">
          Conoce las Casas que tenemos disponibles para Rentar
        </CustomTypography>
        <InputButtonCombo
          variantLabel="textPrimary"
          label="¿De cuánto es tu presupuesto?"
          buttonText="Buscar"
          handleClick={getPresupuesto}
        />
        <FormControl style={{ width: "100%" }}>
          {isDesktop ? ( // Comprobamos si estamos en una pantalla de escritorio
            <Box sx={{ display: "flex", justifyContent: "center", gap: 2.5 }}>
              {preResult.map((element, index) => (
                <MenuItem
                  value={element}
                  sx={{
                    "&:hover": {
                      borderBottom: `2px solid ${theme.palette.primary.main}`, // Barra azul en enfoque (hover)
                    },
                    borderBottom:
                      selectedOption === element
                        ? `2px solid ${theme.palette.primary.main}`
                        : "none", // Línea azul debajo del texto si está seleccionado
                    backgroundColor: "transparent", // Fondo transparente
                    color:
                      selectedOption === element
                        ? `${theme.palette.primary.main}`
                        : "black", // Texto en azul si está seleccionado
                  }}
                  onClick={() => handleListSelection(element)} // Cambia la selección haciendo clic
                >
                  {element}
                </MenuItem>
              ))}
            </Box>
          ) : (
            // Si estamos en un dispositivo móvil, se muestra como un Dropdown
            <Box>
              <InputLabel>Selecciona un fraccionamiento</InputLabel>
              <Select
                value={selectedOption}
                onChange={handleChange}
                style={{ width: "100%" }}
                label="Selecciona un fraccionamiento"
              >
                {preResult.map((element, index) => (
                  <MenuItem value={element}>{element}</MenuItem>
                ))}
              </Select>
            </Box>
          )}
        </FormControl>

        {loading ? (
          <Box>
            <CircularLoadingAnimation />
          </Box>
        ) : items?.length <= 0 ? (
          <>
            <Box
              sx={{
                backgroundColor: theme.palette.warning.light,
                border: 2,
                borderColor: theme.palette.warning.main,
                width: "99%",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                py: "15px",
              }}
            >
              <Container
                sx={{
                  display: "flex",
                  textAlign: "center",
                  flexDirection: "column",
                  gap: 3.75,
                }}
              >
                <Box>
                  <CustomTypography variant="textPrimary">
                    ¡Gracias por tu interés! En este momento, lamentablemente,
                    no tenemos casas disponibles que se ajusten. Te invitamos a
                    unirte a nuestra lista de espera. Serás el primero en
                    enterarte cuando haya una casa que cumpla con tus
                    requisitos.
                  </CustomTypography>
                </Box>
              </Container>
            </Box>
            <Box>
              <CustomButton
                variant="contained"
                text="¡Regístrate Ahora!"
                onClick={navigateList}
              />
            </Box>
          </>
        ) : (
          <Slider>
            {fraccionamientoFiltrado(items, selectedOption)?.map((item) => (
              <Box sx={{ width: { md: "368px", xs: "325px", sm: "420px" } }}>
                <SimpleHomeCard
                  key={item.id}
                  homeData={item}
                  toRedirect={`/renta/${
                    item.folio
                  }/${item.sku.replace(/ /g, "_")}`}
                  showSection={false}
                />
              </Box>
            ))}
          </Slider>
        )}
      </Container>
    </Box>
  );
};

export { ListingSection };
