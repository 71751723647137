import { InvestmentAmountSection } from "./InvestmentAmountSection";
import { SimulationSection } from "./SimulationSection";
import { CTASection } from "./CTASection";

import { ThemeProvider } from "@mui/material";
import theme from "../../App/theme";
import { useLoteToSimulator } from "../../hooks/investment/useLoteToSimulator"
import { useQueryParamsToJson } from "../../hooks/useQueryParamsToJson";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { SimulationContainer } from "./SimulationContainer";

const InvestmentSimulator = () => {
    // const queryparams = useQueryParamsToJson();
    // console.log(queryparams)
    const [searchParams, setSearchParams] = useSearchParams();
    const { lote1, inversionInicial } = useQueryParamsToJson();
    const [datosLote, setDatosLote] = useLoteToSimulator(lote1, inversionInicial);
    const getDatosLote = (inversionInicial) => {
        setDatosLote(lote1, inversionInicial);
        setSearchParams({lote1:lote1, inversionInicial:inversionInicial})
    }
    return (
        <ThemeProvider theme={theme}>
            <Helmet>
				<title>{ `Invierte en casas Pecsa - Simulador | Casas Pecsa` }</title>
				<meta name="description" content="Rentar copy" />
            </Helmet>
            <SimulationContainer datosLote={datosLote}>
                <InvestmentAmountSection datosLote={datosLote} handleClick={getDatosLote}/>
                <SimulationSection datosLote={datosLote} />
            </SimulationContainer>
            {/* <Box sx={{ mt:{ xs:10, sm:8, md:15 }, mb:{ xs:8, sm:8, md:9 }, }}>                
                <InvestmentAmountSection datosLote={datosLote} handleClick={getDatosLote}/>
                <SimulationSection datosLote={datosLote} />
            </Box> */}
        </ThemeProvider>
    )
}

export { InvestmentSimulator } 