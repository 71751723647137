import React, {useState,useEffect} from 'react';
import axios from "axios";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Autocomplete, ButtonBase, Card, CardActionArea, CardMedia, Checkbox, Container, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, Input, InputAdornment, InputLabel, MenuItem, OutlinedInput, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import InfoIcon from '@mui/icons-material/Info';

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import ClearIcon from '@mui/icons-material/Clear';

import sinlogo from '../../assets/sinlogo.png';
import ms1 from '../../assets/ms1.png';
import { Lote } from '../Lote';

const steps = [
    "Informacion",
    "Caracteristicas",
    "Vista Previa"
];
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const AddLote = () => {
    /***variables de formulario-***/
    let navigate = useNavigate();
    let parametros = useParams();
    const location = useLocation();
    console.log(parametros);

    const [modelos, setModelos] = useState([]);
    const [selectedModelo, setSelectedModelo] = useState(null);

    const [fachadas, setFachadas] = useState([]);
    const [selectedFachada, setSelectedFachada] = useState(null);

    const [superficieDeTerreno, setSuperficieDeTerreno] = useState(null);
    const [precio, setPrecio] = useState(null);
    const [numeroOficial, setNumeroOficial] = useState(null);
    const [calle, setCalle] = useState(null);
    const [numeroDeLote, setNumeroDeLote] = useState(null);

    const [selectedEspecificaciones, setSelectedEspecificaciones] = useState(null);
    const [selectedPredial, setSelectedPredial] = useState(null);
    const [selectedNumeroOficial, setSelectedNumeroOficial] = useState(null);

    const [storageImages, setsStorageImages] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [imagesUrl, setImagesUrl] = useState([]);
    const [imageUrl, setImageUrl] = useState(null);

    const [linkTourVirtual, setLinkTourVirtual] = useState(null);
    const [SelectedRecamaras, setSelectedRecamaras] = useState(null);
    const [valorRenta, setValorRenta] = useState(null);
    const [SelectedBanios, setSelectedBanios] = useState(null);
    const [SelectedMedioBanios, setSelectedMedioBanios] = useState(null);
    const [SelectedTipoYEstilo, setSelectedTipoYEstilo] = useState(null);
    const [piso, setPiso] = useState(null);
    const [sistemaDeConstruccion, setSistemaDeConstruccion] = useState(null);
    const [SelectedEstacionamiento, setSelectedEstacionamiento] = useState(null);
    const [SelectedVoltajeDeLuz, setSelectedVoltajeDeLuz] = useState(null);
    const [SelectedTipoDeGas, setSelectedTipoDeGas] = useState(null);
    const [SelectedMinisplit, setSelectedMinisplit] = useState(null);    

    const [orientacionDelSol, setOrientacionDelSol] = useState(null);
    const [ubicacionDentroDelFraccionamiento, setUbicacionDentroDelFraccionamiento] = useState(null);

    const [imageUrlOrientacionDelSol, setImageUrlOrientacionDelSol] = useState(null);
    const [imageUrlUbicacionDentroDelFraccionamiento, setImageUrlUbicacionDentroDelFraccionamiento] = useState(null);

    const [gmIframe, setGmIframe] = useState(null);

    const [enVentaChecked, setEnVentaChecked] = useState(false);
    /***-variables de formulario***/
    /***cosas de step-***/
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        if(activeStep === steps.length - 1){
            add();
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    /***-cosas de step***/
    const setSelectedModeloFachada = (newModelo) => {
        setSelectedModelo(newModelo);
        setFachadas(newModelo.fachadas);
    }
    const Redirigir = (link) => {
        // window.location.href = link;
        window.open = link;
    }
    const add = () => {
        const data = new FormData();    

        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');        
        
        data.set('idManzana', parametros.manzana);
        data.set('modelo', selectedModelo.id);
        data.set('idFachada', selectedFachada.id);
        data.set('superficieDeTerreno', superficieDeTerreno);
        data.set('precio', precio);
        data.set('numeroOficial', numeroOficial);
        data.set('calle', calle);
        data.set('numeroDeLote', numeroDeLote);

        if(selectedEspecificaciones) {
            data.append('archivoEspecificacionesDoc', selectedEspecificaciones);
        }
        if(selectedPredial) {
            data.append('archivoPredialDoc', selectedPredial);
        }
        if(selectedNumeroOficial) {
            data.append('archivoNumeroOficialDoc', selectedNumeroOficial);
        }

        storageImages.map((foto) => {
            data.append('fotosImg', foto);
        })

        //data.set('linkTourVirtual', linkTourVirtual);
        data.set('recamaras', SelectedRecamaras);
        data.set('valorRenta', valorRenta);
        data.set('banios', SelectedBanios);
        data.set('medioBanios', SelectedMedioBanios);
        data.set('tipoYEstilo', SelectedTipoYEstilo);
        data.set('piso', piso);
        data.set('sistemaDeConstruccion', sistemaDeConstruccion);
        data.set('estacionamiento', SelectedEstacionamiento);
        data.set('voltajeDeLuz', SelectedVoltajeDeLuz);
        data.set('tipoDeGas', SelectedTipoDeGas);
        data.set('minisplit', SelectedMinisplit);

        if(orientacionDelSol) {
            data.append('orientacionDelSolImg', orientacionDelSol);
        }
        if(ubicacionDentroDelFraccionamiento) {
            data.append('ubicacionDentroDelFraccionamientoImg', ubicacionDentroDelFraccionamiento);
        }

        data.set('gmIframe', gmIframe);
        if(enVentaChecked){
            data.set('idEstatus', 1);
        }else{
            data.set('idEstatus', 4);
        }        
        data.set('disponible', true);
        data.set('folioCreateBy', folio);
        // data.set('superficieDeTerreno', superficieDeTerreno);
        // data.set('superficieDeTerreno', superficieDeTerreno);
        // data.set('superficieDeTerreno', superficieDeTerreno);

        // console.log(data.get('modelo'));
        // console.log(data.get('fachada'));
        // console.log(data.get('superficieDeTerreno'));
        // console.log(data.get('precio'));
        // console.log(data.get('numeroOficial'));
        // console.log(data.get('calle'));
        // console.log(data.get('numeroDeLote'));
        // console.log(data.get('archivoEspecificacionesDoc'));
        // console.log(data.get('archivoPredialDoc'));
        // console.log(data.get('archivoNumeroOficialDoc'));

        // console.log(data.get('fotosImg'));

        // console.log(data.get('linkTourVirtual'));
        // console.log(data.get('SelectedRecamaras'));
        // console.log(data.get('valorRenta'));
        // console.log(data.get('SelectedBanios'));
        // console.log(data.get('SelectedMedioBanios'));
        // console.log(data.get('SelectedTipoYEstilo'));
        // console.log(data.get('piso'));
        // console.log(data.get('sistemaDeConstruccion'));
        // console.log(data.get('SelectedEstacionamiento'));
        // console.log(data.get('SelectedVoltajeDeLuz'));
        // console.log(data.get('SelectedTipoDeGas'));
        // console.log(data.get('SelectedMinisplit'));
        // console.log(data.get('orientacionDelSolImg'));
        // console.log(data.get('ubicacionDentroDelFraccionamientoImg'));

        // console.log(data.get('gmIframe'));

        axios.post(`${process.env.REACT_APP_API_URL}/api/lote/`, 
            data,
            {
              headers: { 
                Authorization: `Bearer ${token}`,   
                'Content-Type': 'multipart/form-data',               
                //ContentType: 'multipart/form-data'
              }              
        })
        .then((response) => {
            // handle success            
            console.log(response);
            console.log('success axio');
            //navigate(-1, { replace: true });
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sabe');
        });

    }
    useEffect(() => {
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');

        if (selectedImages.length > 0) {
            console.log('imagenes seleccionadoas: ', selectedImages);
            for (let i = 0; i < selectedImages.length; i++) {
                if(!storageImages.find( element => element === selectedImages[i])){
                    storageImages.push(selectedImages[i]);
                    imagesUrl.push(URL.createObjectURL(selectedImages[i]))
                }                
            }
            console.log('imagenes en storage: ', storageImages);
            console.log('imagenes seleccionadoas: ', imagesUrl);
        }
        if(orientacionDelSol){
            setImageUrlOrientacionDelSol(URL.createObjectURL(orientacionDelSol));
        }
        if(ubicacionDentroDelFraccionamiento){
            setImageUrlUbicacionDentroDelFraccionamiento(URL.createObjectURL(ubicacionDentroDelFraccionamiento));
        }

        axios.get(`${process.env.REACT_APP_API_URL}/api/lote/createview`, {
              headers: {
                  Authorization: `Bearer ${token}`
              }
          })
          .then((response) => {
            // handle success
            console.log(response);
            setModelos(response.data.modelos);
            // setFachadas(response.data.fachadas);
          })
          .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
          })
          .then(() => {
            // handle success
          });
    }, [setModelos, setFachadas, selectedImages, selectedModelo, orientacionDelSol, ubicacionDentroDelFraccionamiento]); //aqui va selectedImage

    return(
        <Container>
            <Box sx={{ width: "100%", pt:3 }}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepOptional(index)) {
                            labelProps.optional = (
                                <Typography variant="caption">Optional</Typography>
                            );
                        }
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                            <Box sx={{ flex: "1 1 auto" }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <React.Fragment>
                            {
                                (activeStep === 0) && (
                                    <Box
                                        sx={{
                                            textAlign: 'left',
                                            mt:1,
                                            pl:4,
                                            pr:4
                                        }}
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{
                                                pb:1
                                            }}
                                        >
                                            <Grid item xs={12} sm={6} md={6}>
                                                <Autocomplete
                                                    id="grouped-demo"
                                                    options={modelos}
                                                    getOptionLabel={(empresa) => empresa.nombre}
                                                    value={selectedModelo}
                                                    onChange={(event, newModelo)=>{setSelectedModeloFachada(newModelo);}}
                                                    sx={{ width: 300 }}
                                                    renderInput={(params) => <TextField {...params} label="Modelo" name="modelo" />}
                                                />
                                                <Autocomplete
                                                    id="grouped-demo"
                                                    options={fachadas}
                                                    getOptionLabel={(fachada) => fachada.nombre}
                                                    value={selectedFachada}
                                                    onChange={(event, newFachada)=>{setSelectedFachada(newFachada);}}
                                                    sx={{ width: 300 }}
                                                    renderInput={(params) => <TextField {...params} label="Fachada" name="fachada" />}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <Typography variant="subtitle2" gutterBottom component="div">
                                                    <b>DATOS DEL MODELO</b>
                                                </Typography>
                                                <Typography variant="body2" gutterBottom component="div">
                                                    Superficie de Construcción:
                                                    {
                                                        selectedModelo && (`${selectedModelo.superficieDeConstruccion} m2`)
                                                    }
                                                    {/* Superficie de Construcción: m2 */}
                                                </Typography>
                                                <Typography variant="body2" gutterBottom component="div">
                                                    Plano Arquitectónico:
                                                    {
                                                        selectedModelo && (
                                                            <Button variant="text" endIcon={<RemoveRedEyeOutlinedIcon />} onClick={()=>{Redirigir(selectedModelo.archivoPlanoArquitectonico)}} >
                                                                Ver Plano Arquitectónico
                                                            </Button>
                                                        )
                                                    }                                                    
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider variant="middle" />
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{
                                                mt:2,
                                                mb:2
                                            }}
                                            spacing={2}
                                        >
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextField id="outlined-basic" label="Superficie del Terreno" defaultValue={superficieDeTerreno} onChange={(e)=>{ setSuperficieDeTerreno(e.target.value) }} variant="outlined" name="superficieDeTerreno" sx={{ width: { xs:'100%', sm:'100%', md:'70%' } }} required/>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                {/* <TextField id="outlined-basic" label="Precio" variant="outlined" name="precio" required/> */}
                                                <FormControl sx={{ width: { xs:'100%', sm:'100%', md:'70%' } }}>
                                                    <InputLabel htmlFor="outlined-adornment-amount">Precio</InputLabel>
                                                    <OutlinedInput
                                                        id="outlined-adornment-amount"
                                                        // value={values.amount}
                                                        // onChange={handleChange('amount')}
                                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                        label="Precio"
                                                        type='number'
                                                        name="precio"
                                                        defaultValue={precio} onChange={(e)=>{ setPrecio(e.target.value) }}
                                                        required
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextField id="outlined-basic" label="Numero Oficial" variant="outlined" defaultValue={numeroOficial} onChange={(e)=>{ setNumeroOficial(e.target.value) }} name="numeroOficial" sx={{ width: { xs:'100%', sm:'100%', md:'70%' } }} required/>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextField id="outlined-basic" label="Calle" variant="outlined" defaultValue={calle} onChange={(e)=>{ setCalle(e.target.value) }} name="calle" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextField id="outlined-basic" label="Numero de Lote" variant="outlined" defaultValue={numeroDeLote} onChange={(e)=>{ setNumeroDeLote(e.target.value) }} name="numeroDeLote" sx={{ width: { xs:'100%', sm:'100%', md:'70%' } }} required/>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                
                                            </Grid>
                                        </Grid>
                                        <Divider variant="middle" />
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            sx={{
                                                mt:2,
                                                mb:2
                                            }}
                                        >
                                            <Grid item xs={12} sm={4} md={5}>
                                                <TableContainer>
                                                    <Table sx={{  }} aria-label="simple table">
                                                        <TableHead>
                                                        <TableRow>
                                                            <TableCell>Archivos</TableCell>
                                                            <TableCell align="center">Cargar</TableCell>
                                                            <TableCell align="center">Ver</TableCell>
                                                        </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell align="left">
                                                                    {
                                                                        selectedEspecificaciones ? (
                                                                            <Typography variant="body1" gutterBottom sx={{ color: 'green' }}>
                                                                                <CheckOutlinedIcon/> Especificaciones
                                                                            </Typography>
                                                                        ) : (
                                                                            <Typography variant="body1" gutterBottom>
                                                                                <HourglassEmptyIcon/> Especificaciones
                                                                            </Typography>
                                                                        )
                                                                    }                                                                    
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <label htmlFor="contained-button-file-especificaciones">
                                                                        <Input accept="image/*, .pdf" id="contained-button-file-especificaciones" name="archivoEspecificacionesDoc" type="file" sx={{ display:'none' }}  onChange={(e)=>{ setSelectedEspecificaciones(e.target.files[0]) }} />
                                                                        <IconButton aria-label="delete" component="span">
                                                                            <UploadFileIcon/>
                                                                        </IconButton>
                                                                    </label>                                                                    
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <IconButton aria-label="delete">
                                                                        <RemoveRedEyeOutlinedIcon/>
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell align="left">
                                                                    {
                                                                        selectedPredial ? (
                                                                            <Typography variant="body1" gutterBottom sx={{ color: 'green' }}>
                                                                                <CheckOutlinedIcon/> Predial
                                                                            </Typography>
                                                                        ) : (
                                                                            <Typography variant="body1" gutterBottom>
                                                                                <HourglassEmptyIcon/> Predial
                                                                            </Typography>
                                                                        )
                                                                    }                                                                             
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <label htmlFor="contained-button-file-predial">
                                                                        <Input accept="image/*, .pdf" id="contained-button-file-predial" name="archivoPredialDoc" type="file" sx={{ display:'none' }} onChange={(e)=>{ setSelectedPredial(e.target.files[0]) }}/>
                                                                        <IconButton aria-label="delete" component="span">
                                                                            <UploadFileIcon/>
                                                                        </IconButton>
                                                                    </label> 
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <IconButton aria-label="delete">
                                                                        <RemoveRedEyeOutlinedIcon/>
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell align="left">
                                                                    {
                                                                        selectedNumeroOficial ? (
                                                                            <Typography variant="body1" gutterBottom sx={{ color: 'green' }}>
                                                                                <CheckOutlinedIcon/> Número Oficial
                                                                            </Typography>
                                                                        ) : (
                                                                            <Typography variant="body1" gutterBottom>
                                                                                <HourglassEmptyIcon/> Número Oficial
                                                                            </Typography>
                                                                        )
                                                                    }                                                                            
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <label htmlFor="contained-button-file-numerooficial">
                                                                        <Input accept="image/*, .pdf" id="contained-button-file-numerooficial" name="archivoNumeroOficialDoc" type="file" sx={{ display:'none' }} onChange={(e)=>{ setSelectedNumeroOficial(e.target.files[0]) }}/>
                                                                        <IconButton aria-label="delete" component="span">
                                                                            <UploadFileIcon/>
                                                                        </IconButton>
                                                                    </label>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <IconButton aria-label="delete">
                                                                        <RemoveRedEyeOutlinedIcon/>
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        {/* {rows.map((row) => (
                                                            <TableRow
                                                            key={row.name}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                            <TableCell component="th" scope="row">
                                                                {row.name}
                                                            </TableCell>
                                                            <TableCell align="center">{row.calories}</TableCell>
                                                            <TableCell align="center">{row.fat}</TableCell>
                                                            <TableCell align="center">{row.carbs}</TableCell>
                                                            </TableRow>
                                                        ))} */}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )
                            }
                            {
                                (activeStep === 1) && (
                                    <Box
                                        sx={{
                                            textAlign: 'left',
                                            mt:1,
                                            pl:4,
                                            pr:4
                                        }}
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="flex-start"
                                            sx={{
                                                pb:1
                                            }}
                                        >
                                            <Grid item xs={12} sm={6} md={6} sx={{  }}>
                                                {
                                                    imageUrl ? (
                                                        <Box
                                                            component='img'
                                                            src={imageUrl}
                                                            sx={{
                                                                width: '80%',
                                                                height: 400,

                                                                border: '1px dashed grey'
                                                            }}
                                                        />
                                                    ) : (
                                                        <Box
                                                            component='div'
                                                            sx={{
                                                                width: '80%',
                                                                height: 400,

                                                                border: '1px dashed grey'
                                                            }}
                                                        >
                                                            No hay foto
                                                        </Box>
                                                    )
                                                }
                                                
                                                
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} sx={{ pl:1, backgroundColor: 'yellow' }}>
                                                <Box>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        alignItems="center"
                                                    >
                                                        <Grid item xs={12} sm={4} md={4}>
                                                            <label htmlFor="contained-button-file-fotoslote">
                                                                <input accept="image/*" id="contained-button-file-fotoslote" name="fotosloteImg" type="file" multiple style={{ display: 'none' }} onChange={e => setSelectedImages(e.target.files)}/>                                                            
                                                                <ButtonBase component="span" sx={{ width: '80%', }}>
                                                                    <Box
                                                                        component='div'
                                                                        sx={{
                                                                            width: '100%',
                                                                            height: 100,
                                                                            borderRadius: '8px',
                                                                            backgroundColor:'#F4F4F4',
                                                                            textAlign: 'center',
                                                                            boxShadow: 4,
                                                                            // pt:6,
                                                                            // pb:6
                                                                        }}

                                                                    >
                                                                        <Box
                                                                            sx={{
                                                                                m:3
                                                                            }}
                                                                        >
                                                                        <AddAPhotoIcon/>
                                                                        <Typography variant="body1" gutterBottom component="div">
                                                                            <b>Cargar Foto</b>
                                                                        </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </ButtonBase>
                                                            </label>
                                                        </Grid>
                                                        {
                                                            imagesUrl && (
                                                                imagesUrl.map((imagen) => (
                                                                    <Grid item xs={12} sm={4} md={4}>
                                                                        <Box
                                                                            component='div'
                                                                            sx={{
                                                                                width:'80%', 
                                                                                height: 100,                                                                   
                                                                                borderRadius: '8px',
                                                                                position: 'relative',
                                                                            }}
                                                                            onClick={ () => { setImageUrl(imagen) } }
                                                                        >
                                                                            <img src={imagen} style={{ width:'100%', height: 'auto', display:'block', objectFit:'cover' }}/>
                                                                            <Checkbox 
                                                                                {...label} 
                                                                                icon={<StarOutlineRoundedIcon />} 
                                                                                checkedIcon={<StarRoundedIcon sx={{ color:'gold' }}/>}
                                                                                sx={{
                                                                                    position: 'absolute',
                                                                                    bottom:0, 
                                                                                    left:0,
                                                                                }} 
                                                                            />
                                                                            <IconButton
                                                                                sx={{ 
                                                                                    color: 'rgba(255, 255, 255, 0.85)',
                                                                                    position: 'absolute',
                                                                                    top:0, 
                                                                                    right:0,
                                                                                }}
                                                                            >
                                                                                <ClearIcon />
                                                                            </IconButton>
                                                                            {/* <IconButton
                                                                                sx={{ 
                                                                                    color: 'rgba(255, 255, 255, 0.85)',
                                                                                    position: 'absolute',
                                                                                    bottom:0, 
                                                                                    left:0,
                                                                                }}
                                                                                onClick={ () => {  } }
                                                                            >
                                                                                <StarOutlineRoundedIcon />
                                                                            </IconButton> */}
                                                                        </Box>
                                                                    </Grid>
                                                                ))
                                                            )
                                                        }
                                                        {/* <Grid item xs={12} sm={4} md={4}>
                                                            <Box
                                                                component='div'
                                                                sx={{
                                                                    width:'95%',

                                                                    borderRadius: '8px',
                                                                    position: 'relative',


                                                                }}
                                                            >
                                                                <img src={ms1} style={{ width:'100%', height: 'auto', display:'block', objectFit:'cover' }}/>
                                                                <IconButton
                                                                    sx={{
                                                                        color: 'rgba(255, 255, 255, 0.85)',
                                                                        position: 'absolute',
                                                                        top:0,
                                                                        right:0,
                                                                    }}
                                                                >
                                                                    <ClearIcon />
                                                                </IconButton>
                                                                <IconButton
                                                                    sx={{
                                                                        color: 'rgba(255, 255, 255, 0.85)',
                                                                        position: 'absolute',
                                                                        bottom:0,
                                                                        left:0,
                                                                    }}
                                                                >
                                                                    <StarOutlineRoundedIcon />
                                                                </IconButton>
                                                            </Box>
                                                        </Grid> */}



                                                    </Grid>
                                                </Box>
                                                {/* <Box
                                                    sx={{
                                                        width:'70%',
                                                        height: 385
                                                     }}
                                                >
                                                    <Typography variant="h6" gutterBottom component="div">
                                                        <b>Cargar Fotos</b>
                                                    </Typography>
                                                    <ImageList sx={{ width: '100%', height: '100%' }}>
                                                        <ImageListItem key="Subheader" cols={2}>
                                                            <ListSubheader component="div">December</ListSubheader>
                                                        </ImageListItem>
                                                        <ButtonBase sx={{ width: '100%' }}>
                                                            <Paper
                                                                sx={{
                                                                    p:3,
                                                                    textAlign:'center',
                                                                    color: '#707070',
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                <AddAPhotoIcon sx={{ fontSize:40 }} />
                                                                <Typography variant="h6" gutterBottom component="div">
                                                                    <b>Cargar Foto</b>
                                                                </Typography>
                                                            </Paper>
                                                        </ButtonBase>
                                                        {itemData.map((item) => (
                                                            <ImageListItem key={item.img}>
                                                                <img
                                                                    src={`${item.img}?w=248&fit=crop&auto=format`}
                                                                    srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                                    alt={item.title}
                                                                    loading="lazy"
                                                                />
                                                                <ImageListItemBar
                                                                    title={item.title}
                                                                    subtitle='Elegir Foto Principal'
                                                                    actionIcon={
                                                                    <IconButton
                                                                        sx={{ color: 'rgba(255, 255, 255, 0.75)' }}
                                                                        aria-label={`info about ${item.title}`}
                                                                    >
                                                                        <StarOutlineRoundedIcon />
                                                                    </IconButton>
                                                                    }
                                                                />
                                                                <ImageListItemBar
                                                                    sx={{
                                                                        background:
                                                                        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                                                        'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                                                    }}
                                                                    title={item.title}
                                                                    position="top"
                                                                    actionIcon={
                                                                        <IconButton
                                                                            sx={{ color: 'white' }}
                                                                            aria-label={`star ${item.title}`}
                                                                        >
                                                                            <ClearIcon />
                                                                        </IconButton>
                                                                    }
                                                                    actionPosition="left"
                                                                />

                                                            </ImageListItem>
                                                        ))}
                                                    </ImageList>
                                                </Box> */}
                                            </Grid>
                                        </Grid>
                                        <Divider variant="middle" />
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="flex-start"
                                            sx={{
                                                pt:2,
                                                pb:2,
                                            }}
                                        >
                                            {/* <Grid item xs={12} sm={12} md={6}>
                                                <TextField id="outlined-basic" label="Link de Tour Virtual" variant="outlined" defaultValue={linkTourVirtual} onChange={(e)=>{setLinkTourVirtual(e.target.value)}} name="linkTourVirtual" sx={{ width:'80%' }} required/>
                                            </Grid> */}
                                            <Grid item xs={12} sm={12} md={6}>
                                                <TextField id="outlined-basic" label="Promoción" variant="outlined" name="promocion"/>
                                            </Grid>
                                        </Grid>
                                        <Divider variant="middle" />
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="flex-start"
                                            rowSpacing={1}
                                            sx={{
                                                pt:2,
                                                pb:2
                                            }}
                                        >
                                            <Grid item xs={12} sm={12} md={2}>
                                                <b>Recámaras*:</b>
                                            </Grid> 
                                            <Grid item xs={12} sm={12} md={4}>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    // value={age}
                                                    name='recamaras' 
                                                    value={SelectedRecamaras} onChange={(e)=>{setSelectedRecamaras(e.target.value)}}
                                                    // onChange={handleChange}
                                                    sx={{
                                                        width:'60%'
                                                    }}
                                                    required
                                                >
                                                    <MenuItem value={1}>1</MenuItem>
                                                    <MenuItem value={2}>2</MenuItem>
                                                    <MenuItem value={3}>3</MenuItem>
                                                    <MenuItem value={4}>4 o mas</MenuItem>
                                                </Select>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={2}>
                                                <b>Valor de Renta:</b>
                                            </Grid> 
                                            <Grid item xs={12} sm={12} md={4}>
                                                <TextField id="outlined-basic" variant="outlined" defaultValue={valorRenta} onChange={(e)=>{setValorRenta(e.target.value)}} name="valorRenta" required/>
                                            </Grid> 
                                            <Grid item xs={12} sm={12} md={2}>
                                                <b>Baños*:</b>
                                            </Grid> 
                                            <Grid item xs={12} sm={12} md={4}>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    // value={age}
                                                    
                                                    // onChange={handleChange}
                                                    name='banios' 
                                                    value={SelectedBanios} onChange={(e)=>{setSelectedBanios(e.target.value)}}
                                                    sx={{
                                                        width:'60%'
                                                    }}
                                                    required
                                                >
                                                    <MenuItem value={1}>1</MenuItem>
                                                    <MenuItem value={2}>2</MenuItem>
                                                    <MenuItem value={3}>3</MenuItem>
                                                    <MenuItem value={4}>4 o mas</MenuItem>
                                                </Select>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={2}>
                                                <b>Medio Baños:</b>
                                            </Grid> 
                                            <Grid item xs={12} sm={12} md={4}>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    // value={age}
                                                    
                                                    // onChange={handleChange}
                                                    name='medioBanios' 
                                                    value={SelectedMedioBanios} onChange={(e)=>{setSelectedMedioBanios(e.target.value)}}
                                                    sx={{
                                                        width:'60%'
                                                    }}
                                                    required
                                                >
                                                    <MenuItem value={'1/2'}>1</MenuItem>
                                                    <MenuItem value={'2 1/2'}>2</MenuItem>
                                                    <MenuItem value={'3 1/2'}>3</MenuItem>
                                                    <MenuItem value={'4 1/2'}>4 o mas</MenuItem>
                                                </Select>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={2}>
                                                <b>Tipo y estilo:</b>
                                            </Grid> 
                                            <Grid item xs={12} sm={12} md={4}>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    // value={age}
                                                    
                                                    // onChange={handleChange}
                                                    name='tipoYEstilo' 
                                                    value={SelectedTipoYEstilo} onChange={(e)=>{setSelectedTipoYEstilo(e.target.value)}}
                                                    sx={{
                                                        width:'60%'
                                                    }}
                                                >
                                                    <MenuItem value={'Vivienda familiar'}>Vivienda familiar</MenuItem>
                                                </Select>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={2}>
                                                <b>Piso:</b>
                                            </Grid> 
                                            <Grid item xs={12} sm={12} md={4}>
                                                <TextField id="outlined-basic" variant="outlined" defaultValue={piso} onChange={(e)=>{setPiso(e.target.value)}} name="piso" required/> 
                                            </Grid> 
                                            <Grid item xs={12} sm={12} md={2}>
                                                <b>Sistema de Construcción:</b>
                                            </Grid> 
                                            <Grid item xs={12} sm={12} md={4}>
                                                <TextField id="outlined-basic" variant="outlined" defaultValue={sistemaDeConstruccion} onChange={(e)=>{setSistemaDeConstruccion(e.target.value)}} name="sistemaDeConstruccion" required/> 
                                            </Grid> 
                                            <Grid item xs={12} sm={12} md={2}>
                                                <b>Estacionamiento:</b>
                                            </Grid> 
                                            <Grid item xs={12} sm={12} md={4}>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    // value={age}
                                                    
                                                    // onChange={handleChange}
                                                    name='estacionamiento' 
                                                    value={SelectedEstacionamiento} onChange={(e)=>{setSelectedEstacionamiento(e.target.value)}}
                                                    sx={{
                                                        width:'60%'
                                                    }}
                                                >
                                                    <MenuItem value={'1 Auto'}>1 Auto</MenuItem>
                                                    <MenuItem value={'2 Autos'}>2 Autos</MenuItem>
                                                    <MenuItem value={'3 Autos'}>3 Autos</MenuItem>
                                                </Select>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={2}>
                                                <b>Voltaje de Luz:</b>
                                            </Grid> 
                                            <Grid item xs={12} sm={12} md={4}>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    // value={age}
                                                    
                                                    // onChange={handleChange}
                                                    name='voltajeDeLuz' 
                                                    value={SelectedVoltajeDeLuz} onChange={(e)=>{setSelectedVoltajeDeLuz(e.target.value)}}
                                                    sx={{
                                                        width:'60%'
                                                    }}
                                                >
                                                    <MenuItem value={'220 o 110 voltios'}>220 o 110 voltios</MenuItem>
                                                </Select>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={2}>
                                                <b>Tipo de Gas:</b>
                                            </Grid> 
                                            <Grid item xs={12} sm={12} md={4}>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    // value={age}
                                                    
                                                    // onChange={handleChange}
                                                    name='tipoDeGas' 
                                                    value={SelectedTipoDeGas} onChange={(e)=>{setSelectedTipoDeGas(e.target.value)}}
                                                    sx={{
                                                        width:'60%'
                                                    }}
                                                >
                                                    <MenuItem value={'LP / Gas natural'}>LP / Gas natural</MenuItem>
                                                </Select>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={2}>
                                                <b>Minisplit:</b>
                                            </Grid> 
                                            <Grid item xs={12} sm={12} md={4}>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    // value={age}
                                                    
                                                    // onChange={handleChange}
                                                    name='minisplit' 
                                                    value={SelectedMinisplit} onChange={(e)=>{setSelectedMinisplit(e.target.value)}}
                                                    sx={{
                                                        width:'60%'
                                                    }}
                                                >
                                                    <MenuItem value={'N/A'}>N/A</MenuItem>
                                                    <MenuItem value={'En algunas habitaciones'}>En algunas habitaciones</MenuItem>
                                                    <MenuItem value={'En todas las recamaras'}>En todas las recamaras</MenuItem>
                                                </Select>
                                            </Grid>
                                                                                     
                                        </Grid>
                                        <Divider variant="middle" />
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="flex-start"
                                            sx={{ textAlign:'center', pt:2, pb:2, }}
                                        >
                                            
                                            <Grid item xs={12} sm={12} md={6} >
                                                {
                                                    imageUrlOrientacionDelSol && (
                                                        <Box
                                                            component='img'
                                                            src={imageUrlOrientacionDelSol}
                                                            sx={{
                                                                width: '60%',
                                                                height: 200,
                                                                mb:1,
                                                                boxShadow: 3,
                                                            }}
                                                        />
                                                    )
                                                }                                                
                                                <label htmlFor="contained-button-file-orientaciondelsol">
                                                    <Input accept="image/*" id="contained-button-file-orientaciondelsol" type="file" name="orientacionDelSolImg" onChange={(e) => { setOrientacionDelSol(e.target.files[0]) }} multiple style={{ display: 'none' }}/>
                                                    <Button variant="contained" component="span">
                                                        Cargar orientacion del sol
                                                    </Button>
                                                </label>
                                            </Grid> 
                                            <Grid item xs={12} sm={12} md={6} >
                                                {
                                                    imageUrlUbicacionDentroDelFraccionamiento && (
                                                        <Box
                                                            component='img'
                                                            src={imageUrlUbicacionDentroDelFraccionamiento}
                                                            sx={{
                                                                width: '60%',
                                                                height: 200,
                                                                mb:1,
                                                                boxShadow: 3,
                                                            }}
                                                        />
                                                    )
                                                }                                                
                                                <label htmlFor="contained-button-file-ubicaciondentrodelfraccionamiento">
                                                    <Input accept="image/*" id="contained-button-file-ubicaciondentrodelfraccionamiento" type="file" name="ubicacionDentroDelFraccionamientoImg" onChange={(e) => { setUbicacionDentroDelFraccionamiento(e.target.files[0]) }} multiple style={{ display: 'none' }}/>
                                                    <Button variant="contained" component="span">
                                                        Carga ubicacion dentro del fraccionamiento
                                                    </Button>
                                                </label>
                                            </Grid>  
                                            
                                                                                     
                                        </Grid>
                                        <Divider variant="middle" />
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="flex-start"
                                            sx={{
                                                pt:3,
                                                pb:2,
                                                textAlign:'left',
                                            }}
                                        >
                                            <Grid item xs={12} sm={12} md={6}>
                                                <TextField 
                                                    id="outlined-basic" 
                                                    label="Agregar Link de Google maps" 
                                                    variant="outlined" 
                                                    //name="linkTourVirtual"
                                                    defaultValue={gmIframe} 
                                                    onChange={ (e) => { setGmIframe(e.target.value) }} 
                                                    required
                                                    sx={{
                                                        width:'80%'
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <Box
                                                    component='iframe'
                                                    src={gmIframe}
                                                    sx={{
                                                        width:{ xs:"100%", sm:"80%", md:"80%" },
                                                        height:"300px",
                                                    }}                        
                                                />                                                
                                            </Grid>                                           
                                        </Grid>
                                        <Divider variant="middle" />
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="flex-start"
                                        >
                                            <Grid item xs={12} sm={12} md={6}>
                                                <FormGroup>
                                                    <FormControlLabel 
                                                        control={
                                                            <Switch 
                                                                checked={enVentaChecked}
                                                                onChange={(e)=>{ setEnVentaChecked(e.target.checked) }} 
                                                            />
                                                        } 
                                                        label="¿Deseas poner en Stock este lote?" 
                                                    />
                                                </FormGroup>
                                            </Grid>                                            
                                        </Grid>
                                    </Box>
                                )
                            }
                            {
                                (activeStep === 2) && (
                                    <div> 
                                        {/* <Lote/> */}
                                        no hay vista previa
                                    </div>
                                )
                            }
                        </React.Fragment>
                        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Atras
                            </Button>
                            <Box sx={{ flex: "1 1 auto" }} />
                            {isStepOptional(activeStep) && (
                                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                    Skip
                                </Button>
                            )}
                            <Button onClick={handleNext}>
                                {activeStep === steps.length - 1 ? "Finalizar" : "Siguiente"}
                            </Button>
                        </Box>
                    </React.Fragment>
                )}
            </Box>
        </Container>
    );
}

export { AddLote }