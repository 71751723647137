import { Outlet } from "react-router-dom";

const CargasAsesores = () => {
    return(
        <div>            
            <Outlet/>
        </div>
    );
}

export { CargasAsesores }