import React from "react";
import { Box } from "@mui/material";

/* Estilos */

const wrapperStyles = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem",
};

const logoStyles = {
  width: { xs: "90%", sm: "40%", md: "90%" },
};

const iconsContainerStyles = {
  display: "flex",
  gap: "1rem",
};

/**
 * Componente SocialIcon
 *
 * @component
 * @param {Object} props - Propiedades del componente.
 * @param {React.Element} props.icon - Icono de la red social.
 * @param {Function} props.onClick - Función de clic para el icono.
 * @param {Object} props.sx - Estilos personalizados del icono.
 * @returns {React.Element} - Retorna un fragmento con el icono de red social.
 */

const SocialIcon = ({ icon, onClick, sx }) => (
  <React.Fragment>
    {React.cloneElement(icon, {
      onClick,
      sx,
    })}
  </React.Fragment>
);

/**
 * Función renderSocialIcons
 *
 * @param {Array} socialMediaLinks - Lista de enlaces de redes sociales.
 * @returns {Array} - Retorna una lista de componentes SocialIcon.
 */

const renderSocialIcons = (socialMediaLinks) => {
  return socialMediaLinks.map((item, index) => (
    <SocialIcon
      key={index}
      icon={item.icon}
      onClick={item.icon.props.onClick}
      sx={item.icon.props.sx}
    />
  ));
};

/**
 * Componente SocialContainer
 *
 * @component
 * @example
 * // Ejemplo de uso:
 * <SocialContainer logo={logo} socialMediaLinks={socialMediaLinks} />
 *
 * @param {Object} props - Propiedades del componente.
 * @param {Array} props.socialMediaLinks - Lista de enlaces de redes sociales.
 * @param {Object} props.logo - Configuración del logotipo.
 * @param {string} props.logo.src - Fuente del logotipo.
 * @param {string} props.logo.alt - Texto alternativo para el logotipo.
 * @returns {React.Element} - Retorna un contenedor con el logotipo y enlaces de redes sociales.
 */

const SocialContainer = ({ socialMediaLinks, logo }) => {
  return (
    <Box sx={wrapperStyles}>
      <Box component={"img"} src={logo.src} sx={logoStyles} alt={logo.alt} />
      <Box sx={iconsContainerStyles}>{renderSocialIcons(socialMediaLinks)}</Box>
    </Box>
  );
};

export { SocialContainer };
