import { Box, Container, Grid } from "@mui/material"
import { CustomTypography } from "../../../components/CustomTypography"
import { CustomTextField } from "../../../components/CustomTextField";
import { CustomButton } from "../../../components/CustomButton";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getFormatoMoneda } from "../../../functions/getFormatoMoneda";
import { InputButtonCombo } from "../../../components/Investment/InputButtonCombo";


const InvestmentAmountSection = ({ datosLote, handleClick }) => {
	const [searchParams] = useSearchParams();	
	const [initialValue, setInitialValue] = useState(searchParams.get('inversionInicial'));
	
	if(!datosLote){
		return(<h1>Cargando</h1>)
	}
	return (
		<Box>
			<Container maxWidth="sm" sx={{ p:{ xs:'0 16px 0 16px', sm:0, md:0, }, }}>
				<Box
					sx={{						 
						// backgroundColor:'pink',
						width:'100%',
						mb:{ xs:2, sm:4, md:4, }
					}}
				>					
					<Grid 
						container
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						sx={{
							gap:{ xs:0.5, sm:1, md:1 },
						}}
					>
						{/* <Grid item xs={12} sm={12} md={12}>
							<Box
								sx={{
									backgroundColor:'pink',
									width:'100%',
									height:450,
								}}
							>
								foto
							</Box>
						</Grid> */}
						<Grid item xs={6} sm={6} md={6}>
							<Box 
								sx={{
									// backgroundColor:'#F6DDCC',
									minHeight:45,
									display:'flex',
									justifyContent:"flex-start",
									alignItems:"flex-end",
								}}
							>
								<CustomTypography variant="textPrimary">
									{ datosLote.lote.manzana.etapa.fraccionamiento.nombre }
								</CustomTypography>
							</Box>
						</Grid>
						<Grid item xs={5} sm={5} md={5}>
							<Box 
								sx={{
									// backgroundColor:'#F6DDCC', 
									textAlign:'right',
									minHeight:45,
									display:'flex',
									justifyContent:"flex-end",
									alignItems:"flex-end",
								}}
							>
								<CustomTypography variant="textPrimaryBold">
									{ getFormatoMoneda(datosLote.lote.precio) }<CustomTypography variant="textTertiaryBold">MXN</CustomTypography>
								</CustomTypography>
							</Box>
						</Grid>
						<Grid item xs={7} sm={7} md={7}>							
							<Box>
								<CustomTypography variant="textTertiaryBold">
									Simulación en <CustomTypography variant="textSecondaryBold">Modelo { datosLote.lote.fachada.modelo.nombre }</CustomTypography>
								</CustomTypography>
							</Box>
						</Grid>
						<Grid item xs={7} sm={7} md={7}>							
							<Box>
								<CustomTypography variant="textTertiaryLight">
									Lote { datosLote.lote.numeroDeLote }
								</CustomTypography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<InputButtonCombo initialValue={initialValue} handleClick={handleClick} label="¿Cuánto es tu inversión inicial?" variantLabel="textPrimary" buttonText="Buscar"/>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</Box>
	)
}

export { InvestmentAmountSection }