import { useState } from "react";
import { useQueryParamsToJson } from "../../useQueryParamsToJson";
import { useEffect } from "react";

export const useIdLotesToCompare = () => {
	const queryParams = useQueryParamsToJson();

	const [lotes, setLotes] = useState([]);
	
	const addLote = (newLote) => {
		setLotes(oldLotes=>[...oldLotes, newLote])
	}
	
	let count = 1;
	
	useEffect(()=>{		
		while (queryParams[`lote${count}`]) {
			// console.log(queryParams[`lote${count}`]);
			addLote(queryParams[`lote${count}`]);
			count++;
		}
	},[]);
	
	return lotes;
}