import React from "react";
import { Helmet } from "react-helmet";
import { CheckBox, ExpandMore } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import axios from "axios";
import { useState } from "react";

import decora from "../../../assets/decora/decoraprueba.svg";
import decoraoctubre from "../../../assets/decora/decorapruebaoctubre.svg";
import decoratitulorojo from "../../../assets/decora/decoratitulorojo.svg";
import decoraipad from "../../../assets/decora/decoraipad-svg.svg";
// import decoradiciembre from '../../../assets/decora/decoradiciembre.png';
import decorafebrero from "../../../assets/decora/decorafebrero_temp.svg";
import ExpandLess from "@mui/icons-material/ExpandLess";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import decoraomarzo from "../../../assets/decora/decoraportadamarzo.png";
import { MetaPixel } from "../../../components/MetaPixel";
import { ScrollToTop } from "../../../components/ScrollToTop";
const DecoraForm = () => {
  const [nombreCompleto, setNombreCompleto] = useState(null);
  const [email, setEmail] = useState(null);
  const [telefono, setTelefono] = useState(null);
  const [acepta1, setAcepta1] = useState(false);

  const [descargar, setDescargar] = useState(false);

  const handleChangeChecked1 = (event) => {
    setAcepta1(event.target.checked);
  };
  const checkDatos = () => {
    if (nombreCompleto && email && telefono && acepta1) {
      return true;
    } else {
      return false;
    }
  };
  const add = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // console.log({
    //     nombreCompleto: data.get('nombreCompleto'),
    //     email: data.get('email'),
    //     telefono: data.get('telefono'),
    // });
    // return;
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/decora/`, {
        nombreCompleto: data.get("nombreCompleto"),
        email: data.get("email"),
        telefono: data.get("telefono"),
      })
      .then((response) => {
        // handle success
        console.log(response);
        console.log("success axio");
        // navigate(-1, { replace: true });
        // // reedirigirRevistaDecoraActual()
        setDescargar(true);
      })
      .catch((error) => {
        // handle success
        console.log(error);
        // navigate('/usuarios/login');
      })
      .then(() => {
        // handle success
        console.log("sabe");
      });
  };
  const reedirigirRevistaDecoraActual = () => {
    window.open(
      "https://storage.casaspecsa.com/Decora/Septiembre/Decora+16+Digital.pdf"
    );
  };
  const reedirigirRevistaDecora = (link) => {
    window.open(link);
  };
  const stylePecsaMoffin = {
    "& label.Mui-focused": {
      color: "#f5323f",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#d9d9d9",
    },
    "& .MuiOutlinedInput-root": {
      // '& fieldset': {
      //   borderColor: 'black',
      // },
      "&:hover fieldset": {
        borderColor: "#d9d9d9",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#d9d9d9",
      },
    },
  };
  const ediciones = [
    {
      año: 2023,
      revistas: [
        {
          mes: "Febrero",
          url: "https://storage.casaspecsa.com/Decora/Febrero/Decora_13WEB.pdf",
        },
        {
          mes: "Marzo",
          url: "https://storage.casaspecsa.com/Decora/Marzo/Decora_15_fr.pdf",
        },
        {
          mes: "Septiembre",
          url: "https://storage.casaspecsa.com/Decora/Septiembre/Decora+16+Digital.pdf",
        },
      ],
    },
    {
      año: 2022,
      revistas: [
        {
          mes: "Diciembre",
          url: "https://storage.casaspecsa.com/Decora/Diciembre/decora_DIC-2022.pdf",
        },
        {
          mes: "Octubre",
          url: "https://storage.casaspecsa.com/Decora/Octubre/decora_octubre-2022.pdf",
        },
      ],
    },
  ];
  const [selecionado, setSeleccionado] = useState(null);
  const handleClick = (año) => {
    setSeleccionado(selecionado === año ? null : año);
  };
  return (
    <Container>
      <Helmet>
        <title>Casas Pecsa | Revista DECORA</title>
        <meta
          name="description"
          content="Encuentra en tu revista Decora los mejores tips y tendencias para decorar tu casa y que luzca genial ¡completamente gratis! Descárgala hoy y dale un toque único a cada espacio."
        />
      </Helmet>
      <MetaPixel />
      <ScrollToTop />
      <Box sx={{ mt: 12, mb: 5 }}>
        <Box component="form" onSubmit={add}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item md={7}>
              <Box
                sx={{
                  // backgroundColor:'red',
                  width: "100%",
                  height: 700,
                  display: "flex",
                  alignContent: "center",
                  position: "relative",
                }}
              >
                {/* <Box
                                    component='img'
                                    src={decorafebrero}
                                    sx={{
                                        height:'58%',
                                        width:'47%',
                                        // borderRadius:'19px',
                                        boxShadow:10,
                                        m:'0 auto',
                                        position:'absolute',
                                        // zIndex:1,
                                        // top:{xs:145, sm:145, md:145},
                                        // left:{xs:194, sm:194, md:194},
                                        top:{xs:145, sm:145, md:'20.5%'},
                                        left:{xs:194, sm:194, md:'29%'},
                                    }}
                                /> */}
                <Box
                  component="img"
                  src="https://storage.casaspecsa.com/Decora/Septiembre/Decora+16+Digital-portada.png"
                  sx={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    borderRadius: "19px",
                    boxShadow: 10,
                    m: "0 auto",
                    // zIndex:2,
                    // position:'absolute',
                  }}
                />
              </Box>
            </Grid>
            <Grid item md={4}>
              {descargar ? (
                <Box>
                  <Box>
                    <Button
                      variant="outlined"
                      onClick={reedirigirRevistaDecoraActual}
                      sx={{
                        width: "100%",
                        p: { xs: 1, sm: 1, md: 1 },
                        borderColor: "#F5323F",
                        color: "#F5323F",
                        borderWidth: 3,
                        mt: 2,
                      }}
                      color="inherit"
                      // disabled={checkDatos() === true ? false : true}
                      type="submit"
                    >
                      <b>Descargar la revista de este mes</b>
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      textAlign: "center",
                      pt: 2,
                    }}
                  >
                    <Typography sx={{ color: "#707070" }}>
                      ó<br /> descarga alguno de las ediciones anteriores
                    </Typography>
                  </Box>
                  <List
                    sx={{ width: "100%", color: "#F5323F" }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                      <ListSubheader component="div" id="nested-list-subheader">
                        Ediciones anteriores
                      </ListSubheader>
                    }
                  >
                    {ediciones.map((edicion) => (
                      <React.Fragment>
                        <ListItemButton
                          onClick={() => handleClick(edicion.año)}
                        >
                          <ListItemText primary={<b>{edicion.año}</b>} />
                          {edicion.revistas ? (
                            selecionado !== edicion.año ? (
                              <ExpandMore />
                            ) : (
                              <ExpandLess />
                            )
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItemButton>

                        <Collapse
                          in={selecionado === edicion.año ? true : false}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {edicion.revistas &&
                              edicion.revistas.map((revista) => (
                                <ListItemButton
                                  sx={{ pl: 4 }}
                                  onClick={() => {
                                    window.open(revista.url);
                                  }}
                                >
                                  <ListItemIcon>
                                    <FileDownloadIcon />
                                  </ListItemIcon>
                                  <ListItemText primary={revista.mes} />
                                </ListItemButton>
                              ))}
                          </List>
                        </Collapse>
                      </React.Fragment>
                    ))}
                  </List>
                </Box>
              ) : (
                <Grid container spacing={{ xs: 2, sm: 1, md: 2 }}>
                  <Grid item xs={12} sm={6} md={12}>
                    <Box
                      sx={{
                        //backgroundColor:'red',
                        width: "100%",
                        //height:700,
                        display: "flex",
                        alignContent: "center",
                      }}
                    >
                      <Box
                        component="img"
                        src={decoratitulorojo}
                        sx={{
                          maxHeight: "100%",
                          maxWidth: "90%",
                          borderRadius: "19px",
                          boxShadow: 10,
                          m: "0 auto",
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={12}>
                    <Typography variant="body1" sx={{ color: "#707070" }}>
                      Completa el siguiente formulario y ¡Descarga tu revista
                      completamente gratis!.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={12}>
                    <TextField
                      id="outlined-basic"
                      label="Nombre completo"
                      variant="outlined"
                      name="nombreCompleto"
                      sx={{ width: "100%", ...stylePecsaMoffin }}
                      defaultValue={nombreCompleto}
                      onChange={(e) => setNombreCompleto(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      id="outlined-basic"
                      type="email"
                      label="Correo Electrónico"
                      variant="outlined"
                      name="email"
                      sx={{ width: "100%", ...stylePecsaMoffin }}
                      defaultValue={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      id="outlined-start-adornment"
                      label="Número Celular"
                      variant="outlined"
                      name="telefono"
                      sx={{ width: "100%", ...stylePecsaMoffin }}
                      defaultValue={telefono}
                      onChange={(e) => setTelefono(e.target.value)}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">+52 </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={acepta1}
                            onChange={handleChangeChecked1}
                            inputProps={{ "aria-label": "controlled" }}
                            sx={{
                              color: "#f5323f",
                              "&.Mui-checked": {
                                color: "#f5323f",
                              },
                            }}
                          />
                        }
                        label="Quiero recibir en mi correo más información."
                        sx={{ color: "#707070" }}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={6} md={12}>
                    <Typography variant="body2" sx={{ color: "#707070" }}>
                      Aviso: Tus datos están seguros y son completamente
                      confidenciales
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box>
                      <Button
                        variant="outlined"
                        //onClick={handleNext}
                        sx={{
                          width: "100%",
                          p: { xs: 1, sm: 1, md: 1 },
                          borderColor: "#F5323F",
                          color: "#F5323F",
                          borderWidth: 3,
                          mt: 2,
                        }}
                        color="inherit"
                        disabled={checkDatos() === true ? false : true}
                        type="submit"
                      >
                        <b>Descargar</b>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export { DecoraForm };
