import { Alert, Autocomplete, Box, Button, Grid, Snackbar, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Navigate } from "react-router-dom";

const DetallesCuenta = () => {
    
    const [ciudades, setCiudades] = useState([]);
    const [selectedCiudad, setSelectedCiudad] = useState(null);
    const [cliente, setCliente] = useState(null);

    const [open, setOpen] = useState(false);

    const MostarAlert = () => {
        setOpen(true);
    };
    
    const CerrarAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
    };

    const addDatos = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');

        axios.post(`${process.env.REACT_APP_API_URL}/api/clientes/update`, 
            {
                idCliente: cliente.id,

                primerNombre: data.get('primerNombre'),
                segundoNombre: data.get('segundoNombre'),
                primerApellido: data.get('primerApellido'),
                segundoApellido: data.get('segundoApellido'),
                email: data.get('email'),
                telefono: data.get('telefono'),
                // curp: data.get('curp'),
                // salarioMensual: data.get('salarioMensual'),
                // calle: data.get('calle'),
                // noExterior: data.get('noExterior'),
                // noInterior: data.get('noInterior'),
                // codigoPostal: data.get('codigoPostal'),
                // colonia: data.get('colonia'),
                // ciudad: data.get('ciudad'),
                // idMunicipio: selectedCiudad.id,
            },
            // data,
            {
              headers: { 
                Authorization: `Bearer ${token}`,
              }              
        })
        .then((response) => {
            // handle success            
            console.log(response);
            console.log('success axio');
            MostarAlert();
            if(response.data.result === true){
                console.log('autorizado');
            }
            else {
                console.log('No autorizado');
            }
            // navigate('/tienda');
            // navigate('', {state:{ idCliente: cliente.id, idLote: lote.id, }});
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            console.log('sabe');
        });
    }
    useEffect(()=>{
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        const folio = !localStorage.getItem('id') ? '': localStorage.getItem('id');
    
        axios.post(`${process.env.REACT_APP_API_URL}/api/clientes/acount/`,{
            folio: folio
        },{
            headers: { 
              Authorization: `Bearer ${token}`,
            }              
        })
        .then((response) => {
            // handle success
            console.log(response);
            setCliente(response.data.cliente);
            if(response.data.cliente.datos){    
                if(response.data.cliente.datos.idMunicipio){
                    setSelectedCiudad(response.data.ciudades.filter((ciudad) => { return ciudad.id === response.data.cliente.datos.idMunicipio })[0] || null);
                }
            }
            setCiudades(response.data.ciudades);
        })
        .catch((error) => {
          // handle success
          console.log(error);
        })
        .then(() => {
          // handle success
          //console.log('sabe');
        });
    }, [setCliente, setCiudades])
    return(
        <Box component='form' onSubmit={addDatos} sx={{ minHeight:840 }}>
            <Helmet>
                <title>Mi Cuenta - Datos de mi cuenta | Casas Pecsa</title>
                <meta name="description" content="Encuentra Casa en Saltillo, ¿No sabes cuál es tu mejor opción de crédito para comprar una casa? Nosotros te ayudamos. Agenda tu cita y obtén Asesoría Gratis." />
            </Helmet>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h6" gutterBottom component="div" sx={{ color:'#F5323F', fontFamily:'Montserrat' }}>
                        <b>Datos de Usuario</b>
                    </Typography>
                </Grid>
                {
                    cliente && (
                        <React.Fragment>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField id="outlined-basic" label="Primer Nombre"  defaultValue={cliente.datos !== null ? (cliente.datos.primerNombre) : '' } variant="outlined" name="primerNombre" sx={{ width:'100%' }} required/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField id="outlined-basic" label="Segundo Nombre" defaultValue={cliente.datos !== null ? (cliente.datos.segundoNombre) : null } variant="outlined" name="segundoNombre" sx={{ width:'100%' }}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField id="outlined-basic" label="Primer Apellido" defaultValue={cliente.datos !== null ? (cliente.datos.primerApellido) : '' } variant="outlined" name="primerApellido" sx={{ width:'100%' }} required/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField id="outlined-basic" label="Segundo Apellido" defaultValue={cliente.datos !== null ? (cliente.datos.segundoApellido) : '' } variant="outlined" name="segundoApellido" sx={{ width:'100%' }} required/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField id="outlined-basic" label="Correo Electrónico" defaultValue={cliente.autenticacion !== null && cliente.autenticacion.email} variant="outlined" name="email" sx={{ width:'100%' }} required/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField id="outlined-basic" label="Número Celular" defaultValue={cliente.autenticacion !== null && cliente.autenticacion.telefono} variant="outlined" name="telefono" sx={{ width:'100%' }} required/>
                            </Grid>
                        </React.Fragment>
                    )
                }                

                {/* <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h6" gutterBottom component="div">
                        <b>Datos Generales</b>
                    </Typography>
                </Grid>
                {
                    cliente && (
                        <React.Fragment>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField id="outlined-basic" label="CURP" defaultValue={cliente.datos !== null ? cliente.datos.curp : '' } variant="outlined" name="curp" sx={{ width:'100%' }} />    
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField id="outlined-basic" label="Salario Mensual" defaultValue={cliente.datos !== null ? cliente.datos.salarioMensual : '' } variant="outlined" name="salarioMensual" sx={{ width:'100%' }} />    
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField id="outlined-basic" label="Calle" defaultValue={cliente.datos !== null ? cliente.datos.calle : '' } variant="outlined" name="calle" sx={{ width:'100%' }} />    
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <TextField id="outlined-basic" label="Número Exterior" defaultValue={cliente.datos !== null ? cliente.datos.noExterior : '' } variant="outlined" name="noExterior" sx={{ width:'100%' }} />    
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <TextField id="outlined-basic" label="Número Interior" defaultValue={cliente.datos !== null ? cliente.datos.noInterior : '' } variant="outlined" name="noInterior" sx={{ width:'100%' }}/>    
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <TextField id="outlined-basic" label="Código Postal" defaultValue={cliente.datos !== null ? cliente.datos.codigoPostal : '' } variant="outlined" name="codigoPostal" sx={{ width:'100%' }} />    
                            </Grid>
                            <Grid item xs={12} sm={5} md={5}>
                                <TextField id="outlined-basic" label="Colonia" defaultValue={cliente.datos !== null ? cliente.datos.colonia : '' } variant="outlined" name="colonia" sx={{ width:'100%' }} />    
                            </Grid>
                        </React.Fragment>
                    )
                } 
                
                <Grid item xs={12} sm={4} md={4}>
                    <Autocomplete
                        id="grouped-demo"
                        options={ciudades}
                        groupBy={(ciudad) => ciudad.estado.nombre}
                        getOptionLabel={(ciudad) => ciudad.nombre}
                        value={selectedCiudad}
                        onChange={(event, newCiudad)=>{setSelectedCiudad(newCiudad);}}
                        sx={{ width:'100%' }}
                        renderInput={(params) => <TextField {...params} label="Ciudad" name="ciudad" required/>}
                    />  
                </Grid> */}                   
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="caption" display="block" gutterBottom sx={{ color:'#707070', fontFamily:'Montserrat' }}>
                        *Datos obligatorios
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Button type="submit" variant="outlined" color="error"
                        sx={{
                            //p:{ xs:'none', sm:'none', md:1 },
                            borderColor:'#F5323F',
                            color:'#F5323F',
                        }}
                    >
                        <b>Guardar</b>
                    </Button>
                </Grid>
                
                






                
                
                
                
            </Grid>
            <Snackbar open={open} autoHideDuration={6000} onClose={CerrarAlert}>
                <Alert onClose={CerrarAlert} severity="success" sx={{ width: '100%' }}>
                    Datos de Usuario Guardados Correctamente
                </Alert>
            </Snackbar>
        </Box>
    );
}

export { DetallesCuenta }