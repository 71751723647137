import { Button, Card, Checkbox, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import axios from "axios";
import { useState } from "react";

const Formulario = ({ getOpen }) => {

    const [nombre, setNombre] = useState(null);
    const [email, setEmail] = useState(null);
    const [telefono, setTelefono] = useState(null);
    const [check, setCheck] = useState(false);

    const checkDatos = () => {
        console.log(nombre , email , telefono);
        if((nombre === null || nombre === '') || email === null || telefono === null || check === false){
            console.log(nombre , email , telefono);
            return false;
        }
        else{
            return true;
        }
    }
    const stylePecsaMoffin = {
        "& label.Mui-focused": {
            color: "#69542C"
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#d9d9d9"
        },
        "& .MuiOutlinedInput-root": {
            // '& fieldset': {
            //   borderColor: 'black',
            // },
            "&:hover fieldset": {
                borderColor: "#d9d9d9"
            },
            "&.Mui-focused fieldset": {
                borderColor: "#d9d9d9"
            }
        }
    }
    const sendForm = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        // console.log({
        //     nombre: data.get('nombre'),
        //     email: data.get('email'),
        //     telefono: data.get('telefono'),
        //     origen:'Morillo'
        // });
        // return;
        axios.post(`${process.env.REACT_APP_API_URL}/api/formLandings`,
        {
            nombre: data.get('nombre'),
            email: data.get('email'),
            telefono: data.get('telefono'),
            origen:'Altavista'
        }
        )
        .then((response)=>{
            console.log('dddd');
        })
        .catch((error)=>{
            console.log(error);
        });
    }
    return(
        <Box>           
            <Container>
                <Box
                    sx={{
                        mt:2,
                    }}
                >
                    {/* <Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Box
                                sx={{
                                    width:'100%',
                                    textAlign:'center',
                                    color:'#69542C',
                                    mt:4,
                                    mb:2,
                                }}
                            >
                                <Typography variant="h3" sx={{ fontFamily:'Montserrat', }} >
                                    <b>Estrena facil.</b>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid> */}
                    <Card
                        sx={{
                            // pt:3,
                            backgroundColor:'rgba(255, 255, 255, 0.9)',
                            '&:hover':{
                                backgroundColor:'rgba(255, 255, 255, 1)',
                            },
                            pl:{ xs:1, sm:2, md:2 },
                            pr:{ xs:1, sm:2, md:2 },
                            pb:2,
                            borderRadius:'12px',
                            boxShadow:6,
                        }}
                    >
                        <Box component="form" onSubmit={sendForm}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Box
                                        sx={{
                                            width:'100%',
                                            textAlign:'center',
                                            color:'#69542C',
                                            mt:2,
                                            mb:2,
                                        }}
                                    >
                                        <Typography variant="h6" sx={{ fontFamily:'Montserrat', }}>
                                            ¡Déjanos tus datos y te ayudamos a estrenar!
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={3} md={4}>
                                    <Box>
                                        <TextField id="outlined-basic" label="Nombre" name="nombre" variant="outlined" sx={{ width:{ xs:'100%', sm:'90%', md:'90%'}, ...stylePecsaMoffin, }} onChange={(e)=>setNombre(e.target.value)} required />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={3} md={4}>
                                    <Box>
                                        <TextField id="outlined-basic" label="Correo Electronico" name="email" variant="outlined" sx={{ width:{ xs:'100%', sm:'90%', md:'90%'}, ...stylePecsaMoffin, }} onChange={(e)=>setEmail(e.target.value)} required />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={3} md={4}>
                                    <Box>
                                        <TextField id="outlined-basic" label="Telefono" name="telefono" variant="outlined" sx={{ width:{ xs:'100%', sm:'90%', md:'90%'}, ...stylePecsaMoffin, }} onChange={(e)=>setTelefono(e.target.value)} required />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={3} md={8}>
                                    <Box>
                                        <FormControlLabel 
                                            control={
                                                <Checkbox 
                                                    // defaultChecked 
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: '#69542C',
                                                        },
                                                    }}
                                                    onChange={()=>setCheck(!check)}
                                                    required
                                                />
                                            } 
                                            label="Acepto que toda la información recabada por este formulario será tratada de conformidad con el Aviso de Privacidad*" 
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={3} md={4}>
                                    <Box
                                        sx={{
                                            width:{ xs:'100%', sm:'90%', md:'90%'},
                                            textAlign:{ xs:'center', sm:'center', md:'right' }
                                        }}
                                    >
                                        <Button 
                                            variant="contained"
                                            sx={{
                                                // width:'40%',
                                                width:{ xs:'100%', sm:'40%', md:'50%'},
                                                backgroundColor:'#69542C',
                                                borderRadius:'8px',
                                            }}
                                            type="submit"
                                            onClick={getOpen}
                                            disabled={ !checkDatos() ? true : false}
                                        >
                                            Enviar
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        
                    </Card>
                </Box>                
            </Container>
        </Box>
    );
}

export { Formulario }