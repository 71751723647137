import React, { useState, useEffect } from "react";
import axios from "axios";
import { BrowserRouter as Router, Routes, Route, Link, NavLink, Navigate, useLocation, } from "react-router-dom";
import { MyHeader } from "../components/Header";
import { Home } from "../pages/Home";
import { CargasMenu } from "../pages/CargasMenu";
import { Cargas } from "../pages/Cargas";
import { Inventario } from "../pages/Inventario";
import { LoginUsuarios } from "../pages/LoginUsuarios";
import { AddFraccionamiento } from "../pages/AddFraccionamiento";
import { CargasFraccionamiento } from "../pages/CargasFraccionamiento";
import { InventarioFraccionamiento } from "../pages/InventarioFraccionamiento";
import { AddEtapa } from "../pages/AddEtapa";
import { CargasEtapa } from "../pages/CargasEtapa";
import { InventarioEtapa } from "../pages/InventarioEtapa";
import { AddManzana } from "../pages/AddManzana";
import { CargasManzana } from "../pages/CargasManzana";
import { InventarioManzana } from "../pages/InventarioManzana";
import { AddLote } from "../pages/AddLote";
import { EditFraccionamiento } from "../pages/EditFraccionamiento";
import { EditEtapa } from "../pages/EditEtapa";
import { EditManzana } from "../pages/EditManzana";
import { CargasModelos } from "../pages/CargasModelos";
import { ListaModelos } from "../pages/ListaModelos";
import { AddModelo } from "../pages/AddModelo";
import { CargasModelo } from "../pages/CargasModelo";
import { InventarioModelo } from "../pages/InventarioModelo";
import { AddFachada } from "../pages/AddFachada";
import { CargasEmpresas } from "../pages/CargasEmpresas";
import { ListaEmpresas } from "../pages/ListaEmpresas";
import { AddEmpresa } from "../pages/AddEmpresa";
import { Modelo } from "../pages/Modelo";
import { Fraccionamiento } from "../pages/Fraccionamiento";
import { Tienda } from "../pages/Tienda";
import { Base } from "../pages/Base";
import { Lote } from "../pages/Lote";
import { Login } from "../pages/Login";
import { Signin } from "../pages/Signin";
import { VerificacionDeDatos } from "../pages/VerificacionDeDatos";
import { Pago } from "../pages/Pago";
import { MiCuenta } from "../pages/MiCuenta";
import { DetallesCuenta } from "../pages/MiCuenta/DetallesCuenta";
import { MisCompras } from "../pages/MiCuenta/MisCompras";
import { HeaderDePrueba } from "../components/HeaderDePrueba";
import { MyFooter } from "../components/Footer";
import { PagoRealizadoConExito } from "../pages/PagoRealizadoConExito";
import { OlvidastePassword } from "../pages/OlvidastePassword";
import { CambiarPassword } from "../pages/CambiarPassword";
import { CargasPromociones } from "../pages/CargasPromociones";
import { Backdrop, SvgIcon } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { PaginaEnProceso } from "../pages/PaginaEnProceso";
import { RealizarCheckout } from "../pages/RealizarCheckout";
import { WhatsappFAB } from "../components/BotonesFlotantes/WhatsappFAB";
import { UpFAB } from "../components/BotonesFlotantes/UpFAB";
import { PruebaPDF } from "../pages/PruebaPDF";
import { AddAmenidad } from "../pages/AddAmenidad";
import { Prueba360 } from "../pages/Prueba360";
import { PaginaPrincipal } from "../pages/PaginaPrincipal";
import { PreAutorizacion } from "../pages/PreAutorizacion";
import { DecoraForm } from "../pages/Decora/DecoraForm";
import { Blog } from "../pages/Blog";
import { BlogPublicacion } from "../pages/BlogPublicacion";
import { Nieve } from "../components/Nieve";
import { RecomiendaYGana } from "../pages/RecomiendaYGana";

import TagManager from "react-gtm-module";
import { VideoBlog } from "../pages/VideoBlog";
import { VideoBlogReproductor } from "../pages/VideoBlogReproductor";
import { CentroDeRespuestas } from "../pages/CentroDeRespuestas";
import { CentroDeRespuestasResponder } from "../pages/CentroDeRespuestasResponder";

import Smartlook from "smartlook-client";
import { Asesor } from "../pages/Asesor";
import { Asesores } from "../pages/Asesores";
import { CargasAsesores } from "../pages/CargasAsesores";
import { ListaAsesores } from "../pages/ListaAsesores";
import { CargasUsuarios } from "../pages/CargasUsuarios";
import { ListaUsuarios } from "../pages/ListaUsuarios";
import { AddUsuario } from "../pages/AddUsuario";
import { EspacioAsesor } from "../pages/EspacioAsesor";
import { EspacioAsesorInformacion } from "../components/EspacioAsesorInformacion";
import { EspacioAsesorPublicaciones } from "../components/EspacioAsesorPublicaciones";
import { EspacioAsesorFotos } from "../components/EspacioAsesorFotos";
import { Citas } from "../pages/Citas";
import { CitasSuccess } from "../pages/CitasSuccess";
import { EspacioAsesorPerfil } from "../components/EspacioAsesorPerfil";
import { MessengerFAB } from "../components/BotonesFlotantes/MessengerFAB";
import { CalificarAsesor } from "../pages/CalificarAsesor";
import { EspacioAsesorAgenda } from "../components/EspacioAsesorAgenda";
import { MorilloLandingPage } from "../pages/LandingPages/MorilloLandingPage";
import { RocalLandingPage } from "../pages/LandingPages/RocalLandingPage";
import { BosquesLandingPage } from "../pages/LandingPages/BosquesLandingPage";
import { AltavistaLandingPage } from "../pages/LandingPages/AltavistaLandingPage";
import { TuCasaEnMexico } from "../pages/TuCasaEnMexico";
import { CargasClientes } from "../pages/CargasClientes";
import { ListasClientes } from "../pages/ListasClientes";
import { InvestmentHome } from "../pages/InvestmentHome";
import { InvestmentComparator } from "../pages/InvestmentComparator";
import { InvestmentListing } from "../pages/InvestmentListing";
import { InvestmentSimulator } from "../pages/InvestmentSimulator";
import { RentalHome } from "../pages/RentalHome";
import { GeneralOutlet } from "../components/GeneralOutlet";
import { NotFound } from "../pages/NotFound";
import { Estrenaton } from "../pages/Estrenaton";
import { MisInversiones } from "../pages/MiCuenta/MisInversiones";
import { LoteParaRenta } from "../pages/LoteParaRenta";
import { LoteParaRentaContacto } from "../pages/LoteParaRentaContacto";

import ScrollToHashElement from "../components/ScrollToHashElement";

const tagManagerArgs = {
  // gtmId: 'GTM-M3N7TFB',
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER,
  // dataLayer: {
  //     userId: '001',
  //     userProject: 'project'
  // }
};

TagManager.initialize(tagManagerArgs);
Smartlook.init(process.env.REACT_APP_SMARTLOOK, { region: "eu" }, () =>
  console.log("Smartlook is now initialized")
);

function App() {
  const [datoMain, setDatoMain] = useState(null);

  console.log("principal");
  const hey = () => {
    console.log("funcion!!!!");
  };
  const getTitulo = () => {
    document.title = "Casas Pecsa";
  };

  useEffect(() => {
    const token = !localStorage.getItem("token")
      ? ""
      : localStorage.getItem("token");
    getTitulo();

    axios
      .get(`${process.env.REACT_APP_API_URL}/api/principal`)
      .then((response) => {
        // handle success
        // console.log(response);
        setDatoMain(response.data);
      })
      .catch((error) => {
        // handle success
        console.log(error);
      })
      .then(() => {
        // handle success
        //console.log('sabea');
      });
  }, [setDatoMain]);

  return (
    <Router>
      <ScrollToHashElement />
      {datoMain ? (
        <div className="App" style={{ fontFamily: "Montserrat" }}>
          {/* <Nieve/> */}
          {datoMain && (
            <MyHeader
              modelos={datoMain.modelos}
              fraccionamientos={datoMain.fraccionamientos}
              cliente={localStorage.getItem("userClient")}
            />
          )}
          {/* {
            datoMain && (
              // datoMain.modelos && (
                <HeaderDePrueba modelos={datoMain.modelos}/> 
              // )            
            )
          } */}
          <Routes>
            {/* {
                  datoMain && (
                    <React.Fragment>
                      <Route path='/' element={<Home datos={datoMain}/>} />
                      <Route path='/tienda' element={<Tienda lotes={datoMain.lotes} />} />
                      <Route path='/modelo/:modelo' element={<Modelo/>} />
                      <Route path='/fraccionamiento/:fraccionamiento' element={<Fraccionamiento/>} />
                    </React.Fragment>
                  )
                } */}
            {datoMain && (
              <Route path="/" element={<Base />}>
                {/* <Route path='/' element={<PaginaEnProceso/>}> */}
                <Route index element={<Home datos={datoMain} />} />
                <Route path="tienda" element={<Tienda lotes={datoMain.lotes} />} />
                {datoMain.lotes.map((lote) => (
                  <Route key={lote.id} path={`/tienda/${lote.sku.replace(/ /g, "_")}`} element={ <Lote lote={lote} lotes={datoMain.lotes} simulador={datoMain.simulador} /> } />
                ))}
                {datoMain.lotes.map((lote) => (
                  <Route
                    key={lote.id}
                    path={`/checkout/${lote.sku.replace(/ /g, "_")}`}
                    element={<RealizarCheckout lote={lote} />}
                  />
                ))}
                {datoMain.modelos.map((modelo) => (
                  <Route
                    key={modelo.id}
                    path={`/modelo/${modelo.nombre.replace(/ /g, "_")}`}
                    element={
                      <Modelo modelo={modelo} simulador={datoMain.simulador} />
                    }
                  />
                ))}
                {datoMain.fraccionamientos.map((fraccionamiento) => (
                  <Route
                    key={fraccionamiento.id}
                    path={`/fraccionamiento/${fraccionamiento.nombre.replace(
                      / /g,
                      "_"
                    )}`}
                    element={
                      <Fraccionamiento fraccionamiento={fraccionamiento} />
                    }
                  />
                ))}
              </Route>
            )}

            {/* <Route path='/' element={<Home datos={datoMain}/>} /> */}

            {/* <Route path='/modelo/:modelo' element={<Home personajes={datoMain}/>} />
                <Route path='/fraccionamiento/:fraccionamiento' element={<Home personajes={datoMain}/>} />
                <Route path='/tienda/' element={<Tienda personajes={datoMain}/>} />
                <Route path='/tienda/:lote' element={<Lote/>} /> */}
            <Route path="/olvide-password" element={<OlvidastePassword />} />
            <Route
              path="/cambiar-password/:idtoken"
              element={<CambiarPassword />}
            />

            <Route path="/revista-Decora" element={<DecoraForm />} />
            <Route path="/preautorizacion" element={<PreAutorizacion />} />
            <Route path="/recomienda" element={<RecomiendaYGana />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:idPost/:texto" element={<BlogPublicacion />} />
            <Route path="/videoblog" element={<VideoBlog />} />
            <Route
              path="/videoblog/:idYoutube"
              element={<VideoBlogReproductor />}
            />
            <Route
              path="/centro-de-respuestas/:tokenInvitado"
              element={<CentroDeRespuestas />}
            />
            <Route
              path="/centro-de-respuestas/:tokenInvitado/:idLote"
              element={<CentroDeRespuestasResponder />}
            />
            <Route path="/citas" element={<Citas />} />
            {/* <Route path='/citas/:origen' element={<Citas/>} /> */}
            <Route path="/citas/success/*" element={<CitasSuccess />} />
            <Route path="/asesores" element={<Asesores />} />
            <Route path="/asesores/:folio" element={<Asesor />} />
            <Route
              path="/espacio-asesor"
              element={<Navigate to="/espacio-asesor/:folio" />}
            />
            <Route path="/espacio-asesor/:folio" element={<EspacioAsesor />}>
              <Route path="posts" element={<EspacioAsesorPublicaciones />} />
              <Route
                path="informacion"
                element={<EspacioAsesorInformacion />}
              />
              <Route path="fotos" element={<EspacioAsesorFotos />} />
              <Route path="perfil" element={<EspacioAsesorPerfil />} />
              <Route path="agenda" element={<EspacioAsesorAgenda />} />
            </Route>
            <Route
              path="/calificar-asesor/:folio"
              element={<CalificarAsesor />}
            />
            <Route
              path="/agendar-cita/altavista-residencial"
              element={<AltavistaLandingPage />}
            />
            <Route
              path="/agendar-cita/lomas-del-morillo"
              element={<MorilloLandingPage />}
            />
            {/* <Route path='/agendar-cita/el-rocal-residencial' element={<RocalLandingPage/>} /> */}
            <Route
              path="/agendar-cita/bosques-de-las-lomas"
              element={<BosquesLandingPage />}
            />
            <Route
              path="/pruebaPaginaPrincipal"
              element={<PaginaPrincipal />}
            />
            <Route path="/prueba360" element={<Prueba360 />} />
            <Route path="/estrenaton" element={<Estrenaton />} />
            <Route path="/pruebacheckout" element={<RealizarCheckout />} />
            <Route path="/pruebaPDF" element={<PruebaPDF />} />
            <Route path="/checkData" element={<VerificacionDeDatos />} />
            <Route path="/pago" element={<Pago />} />
            <Route path="/pagoSuccess/:folio" element={<PagoRealizadoConExito />} />
            <Route path="/login" element={<Login />} />
            <Route path="/profile/" element={<MiCuenta />}>
              <Route path="datos" element={<DetallesCuenta />} />
              <Route path="movimientos" element={<MisCompras />} />
              <Route path="inversiones/:id" element={<MisInversiones />} />
            </Route>
            <Route path="/registrarse" element={<Signin />} />
            <Route path="/usuarios/login" element={<LoginUsuarios />} />
            <Route path="/cargas/" element={<CargasMenu />} />
            <Route path="/cargas/inventario/*" element={<Cargas />}>
              <Route index element={<Inventario />} />
              <Route path="add" element={<AddFraccionamiento />} />
              <Route
                path=":fraccionamiento/*"
                element={<CargasFraccionamiento />}
              >
                <Route index element={<InventarioFraccionamiento />} />
                <Route path="add" element={<AddEtapa />} />
                <Route path="addAmenidad" element={<AddAmenidad />} />
                <Route path="edit" element={<EditFraccionamiento />} />
                <Route path=":etapa/*" element={<CargasEtapa />}>
                  <Route index element={<InventarioEtapa />} />
                  <Route path="add" element={<AddManzana />} />
                  <Route path="edit" element={<EditEtapa />} />
                  <Route path=":manzana/*" element={<CargasManzana />}>
                    <Route index element={<InventarioManzana />} />
                    <Route path="add" element={<AddLote />} />
                    <Route path="edit" element={<EditManzana />} />
                    {/* <Route path='edit' element={<EditarPersonaje/>} />
                        <Route path=':lote/*' element={<CargasPersonaje/>} >
                          <Route index element={<Personaje/>} />
                          <Route path='add' element={<AddCapitulo/>} />
                          <Route path='edit' element={<EditarPersonaje/>} />

                        </Route>*/}
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="/cargas/modelos/*" element={<CargasModelos />}>
              <Route index element={<ListaModelos />} />
              <Route path="add" element={<AddModelo />} />
              <Route path=":modelo/*" element={<CargasModelo />}>
                <Route index element={<InventarioModelo />} />
                <Route path="add" element={<AddFachada />} />
              </Route>
            </Route>
            <Route path="/cargas/clientes/*" element={<CargasClientes />}>
              <Route index element={<ListasClientes />} />
            </Route>
            <Route path="/cargas/promociones" element={<CargasPromociones />} />
            <Route path="/cargas/empresas/*" element={<CargasEmpresas />}>
              <Route index element={<ListaEmpresas />} />
              <Route path="add" element={<AddEmpresa />} />
              {/* <Route path=':empresa/*' element={<CargasModelo/>} >
                      <Route index element={<InventarioModelo/>} />
                      <Route path='add' element={<AddFachada/>} />
                  </Route> */}
            </Route>
            <Route path="/cargas/usuarios/*" element={<CargasUsuarios />}>
              <Route index element={<ListaUsuarios />} />
              <Route path="add" element={<AddUsuario />} />
              {/* <Route path=':empresa/*' element={<CargasModelo/>} >
                      <Route index element={<InventarioModelo/>} />
                      <Route path='add' element={<AddFachada/>} />
                  </Route> */}
            </Route>
            <Route path="/cargas/asesores/*" element={<CargasAsesores />}>
              <Route index element={<ListaAsesores />} />
              {/* <Route path='add' element={<AddEmpresa/>} /> */}
              {/* <Route path=':empresa/*' element={<CargasModelo/>} >
                      <Route index element={<InventarioModelo/>} />
                      <Route path='add' element={<AddFachada/>} />
                  </Route> */}
            </Route>
            <Route
              path="/tu-casa-en-mexico"
              element={<TuCasaEnMexico />}
            ></Route>
            <Route
              path="/invierte/*"
              element={<GeneralOutlet />}
            >
              <Route index element={<InvestmentHome />} />
              <Route path="comparador" element={<InvestmentComparator />} />
              <Route path="listado" element={<InvestmentListing />} />
              <Route path="simulador" element={<InvestmentSimulator />} />
            </Route>
            <Route
              path="/renta"
              element={<RentalHome />}
            ></Route>
            <Route
              path="/renta/contacto"
              element={<LoteParaRentaContacto />}
            />
            <Route
              path="/renta/:folio/:sku"
              element={<LoteParaRenta />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
          {/* <MessengerFAB/> */}
          <WhatsappFAB />
          <UpFAB />
          <MyFooter fraccionamientos={datoMain.fraccionamientos} />
        </div>
      ) : (
        // <PaginaEnProceso/>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={!datoMain ? true : false}
          // onClick={handleClose}
        >
          <CircularProgress sx={{ color: "#F5323F" }} />
        </Backdrop>
      )}
    </Router>
  );
}

export default App;
