import { AppBar, Box, Button, Container, Dialog, Grid, IconButton, Slide, Toolbar, Typography } from "@mui/material";
import { forwardRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { CustomTypography } from "../../CustomTypography";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
import { GalleryPhotos } from "./GalleryPhotos";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';


// ------------------

// ------------------


const FotosComponent = ({ fotos, gallery = true }) => {
    const [fotoPrincipal, setFotoPrincipal] = useState(null);
    const [openGallery, setOpenGallery] = useState(false);

    const getFotoPrincipal = (newFoto) => {
        setFotoPrincipal(newFoto);
    }

    const handleOpenGallery = () => {
        setOpenGallery(!openGallery);
    }
    useEffect(()=>{
        getFotoPrincipal(fotos[0].url)
    },[]);
    return(
        <Box
            sx={{
                // backgroundColor:'pink',
                width:'100%',
                height:'100%'
            }}
        >
            <Box
                sx={{
                    // backgroundColor:'greenyellow',
                    width:'100%',
                    height: gallery ? '80%' : '100%',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                }}
            >
                <Box
                    component="img"
                    src={fotoPrincipal}
                    sx={{
                        maxWidth:'90%',
                        maxHeight:'100%',
                        borderRadius:'12px',
                        boxShadow:'0px 10px 15px -3px rgba(0,0,0,0.1)',
                        cursor:'pointer',
                    }}
                    onClick={handleOpenGallery}
                />
            </Box>
            {
                gallery && (
                    <Box
                        sx={{
                            // backgroundColor:'yellow',
                            // width:'100%',
                            height:'20%',
                            overflow:'hidden',
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'center',
                            gap:0.7,
                        }}
                    >
                        {
                            fotos.map((foto, index)=> {
                                if(index <= 3){
                                    if(index === 3){
                                        if(fotos.length > 3){
                                            return(
                                                <Box
                                                    sx={{
                                                        // backgroundColor:'purple',
                                                        height:'90%',
                                                        overflow:'hidden',
                                                        borderRadius:'4px',
                                                        boxShadow:'0px 10px 15px -3px rgba(0,0,0,0.1)',
                                                        cursor:'pointer',
                                                        display:'flex',
                                                        alignItems:'center',
                                                        justifyContent:'center',
                                                        position:'relative',
                                                    }}
                                                    // onClick={()=>getFotoPrincipal(foto.url)}
                                                    onClick={handleOpenGallery}
                                                >
                                                    <Box
                                                        component="img"
                                                        src={foto.url}
                                                        sx={{
                                                            height:'100%',
                                                            // position:'absolute',
                                                        }}
                                                    />
                                                    <Box
                                                        sx={{
                                                            backgroundColor:'rgba(243, 243, 243, 0.8)',
                                                            width:'100%',
                                                            height:'100%',
                                                            display:'flex',
                                                            alignItems:'center',
                                                            justifyContent:'center',
                                                            position:'absolute',
                                                        }}
                                                    >
                                                        <CustomTypography variant="textTertiaryBold" txtColor="primary">
                                                            Ver mas
                                                        </CustomTypography>
                                                    </Box>
                                                </Box>
                                            )
                                        }
                                    }
                                    return(
                                        <Box
                                            sx={{
                                                // backgroundColor:'purple',
                                                height:'90%',
                                                overflow:'hidden',
                                                borderRadius:'4px',
                                                boxShadow:'0px 10px 15px -3px rgba(0,0,0,0.1)',
                                                cursor:'pointer',
                                            }}
                                            onClick={()=>getFotoPrincipal(foto.url)}
                                        >
                                            <Box
                                                component="img"
                                                src={foto.url}
                                                sx={{
                                                    height:'100%',
                                                }}
                                            />
                                        </Box>
                                    )
                                }                            
                            })
                        }                                            
                    </Box>
                )
            }
            <GalleryPhotos fotos={fotos} open={openGallery} handleOpen={handleOpenGallery} />
        </Box>
    );
}

export { FotosComponent }