import { Box, Button, Card, CardContent, CardMedia, Divider, FormControl, Grid, InputLabel, List, ListItem, ListItemText, MenuItem, Select, Step, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";

import foto360 from '../../assets/prueba/GS__4166.JPG';
import React, { useEffect, useState } from "react";
import axios from "axios";


const PaginaPrincipal = ({ lote }) => {

    //console.log('Realizar checkout',lote);
    const [clientSecret, setClientSecret] = useState(null);

    const addCliente = () => {
        //console.log('guardarClienate', fotos[0]);
        // axios.post(`${process.env.REACT_APP_API_URL}/api/venta/checkClient/`,
        // datosPersonales
        // // {
        // //     headers: { 
        // //     Authorization: `Bearer ${token}`,
        // //     }              
        // // }
        // )
        // .then((response) => {
        //     // handle success
        //     console.log(response);
        //     setNewCliente(response.data);
        // })
        // .catch((error) => {
        //     // handle success
        //     console.log(error);
        //     // navigation(-1);
        //     //setLoteDisponible(false);
        // })
        // .then(() => {
        //     // handle success
        //     //console.log('sabe');
        // });
    }
    // const Mostrar = () => {
    //     const porta = document.getElementById("porta");
    //     const panorama = new PANOLENS.ImagePanorama(foto360);
    //     const viewer = new PANOLENS.Viewer({ 
    //         // output: "console" 
    //         container:porta
    //     });

    //     viewer.add(panorama);
    //     return(<div id="porta"></div>);
    // }
    // // const Mostrar = () => {
    // //     return(<div></div>);
    // // }

    // // useEffect(()=>{
    // //     const panorama = new PANOLENS.ImagePanorama(foto360);
    // //     const viewer = new PANOLENS.Viewer({ 
    // //         // output: "console" 
    // //         container:<Mostrar/>
    // //     });

    // //     viewer.add(panorama);
    // // },[]);
    // // return(
    // //     <Container sx={{ mt:{ xs:9, sm:9, md:12, } }}>
    // //         <Box
    // //             sx={{
    // //                 height:800,
    // //                 pt:1
    // //             }}
    // //         >
    // //             <Mostrar/>
    // //         </Box>
    // //     </Container>
    // // );
    return(
        <Container sx={{ mt:{ xs:9, sm:9, md:12, } }}>
            <Box
                sx={{
                    backgroundColor:'red',
                    height:800,
                    pt:1
                }}
            >
                <h1>hola</h1>
                
            </Box>
        </Container>
    );
}

export { PaginaPrincipal }