import { AppBar, Box, Container, Dialog, IconButton, Slide, Toolbar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { CustomTypography } from "../../../../CustomTypography";
import { CarouselPhotos } from "./CarouselPhotos";
import { forwardRef } from "react";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PhotosViewer = ({ fotos, indexImage, open, handleClose }) => {   
    // console.log(fotos.map((foto)=>foto.url)); 
    return(
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            PaperProps={{
                style: {
                backgroundColor: 'rgba(255,255,255, 1)',
                boxShadow: 'none',
                },
            }}
        >
            <AppBar color="transparent" sx={{ position: 'relative', boxShadow:0 }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <CustomTypography variant="textPrimary">
                        Cerrar
                    </CustomTypography>
                    {/* <Button autoFocus color="inherit" onClick={handleClose}>
                        save
                    </Button> */}
                </Toolbar>
            </AppBar>
            <Box
                sx={{
                    mt:2,
                }}
            >
                <Container>
                    <Box
                        // className="example-container"
                        sx={{
                            // backgroundColor:'yellow',
                            // width:'100%',
                            maxWidth: '100%',
                            height: '90vh',
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            overflow:'hidden',
                            // position:'relative',
                        }}
                    >
                        <CarouselPhotos images={fotos.map((foto)=>foto.url)} indexImage={indexImage}/>
                    </Box>
                </Container>
            </Box>
        </Dialog>
    );
}

export { PhotosViewer }