import { Button, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useState } from "react";

import EditIcon from '@mui/icons-material/Edit';
import { useParams } from "react-router-dom";
import axios from "axios";

const AddEducacion = ({ getAgregar, addListEducacion }) => {
    const { folio } = useParams();

    const [fechaInicio, setFechaInicio] = useState(null);
    const [fechaFinal, setFechaFinal] = useState(null);

    const add = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        
        const token = !localStorage.getItem('token') ? '': localStorage.getItem('token');
        // const prueba = {
        //     escuela: data.get('escuela'),
        //     titulo: data.get('titulo'),
        //     fechaInicio: fechaInicio.format('yyyy-MM-DD HH:mm'),
        //     fechaFinal: fechaFinal.format('yyyy-MM-DD HH:mm'),
        // };
        // console.log(prueba); return;
        axios.post(`${process.env.REACT_APP_API_URL}/api/asesores/profile/${folio}/informacion/educacion`,
            {
                escuela: data.get('escuela'),
                titulo: data.get('titulo'),
                fechaInicio: fechaInicio.format('yyyy-MM-DD HH:mm'),
                fechaFinal: fechaFinal.format('yyyy-MM-DD HH:mm'),
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
        .then((response)=>{
            console.log(response);
            addListEducacion({
                escuela: data.get('escuela'),
                titulo: data.get('titulo'),
                fechaInicio: fechaInicio.format('yyyy-MM-DD HH:mm'),
                fechaFinal: fechaFinal.format('yyyy-MM-DD HH:mm'),
            });
            getAgregar()
            // setInformacion(response.data)            
        })
        .catch((error)=>{
            console.log(error);
        });
    }

    return(
        <Box>
            <Box component="form" onSubmit={add}>
                <Grid
                    container
                    spacing={1}
                >
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" label="Lugar Donde Realizo sus Estudios" variant="outlined" name="escuela" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField id="outlined-basic" label="Titulo Obtenido" variant="outlined" name="titulo" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        {/* <TextField id="outlined-basic" label="Nombre" variant="outlined" name="nombre" sx={{ width: { xs:'100%', sm:'100%', md:'90%' } }} required/> */}
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="Fecha de Inicio"
                                value={fechaInicio}
                                onChange={(newValue) => {
                                    setFechaInicio(newValue);
                                }}
                                name="fechaInicio"
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="Fecha de Fin"
                                value={fechaFinal}
                                onChange={(newValue) => {
                                    setFechaFinal(newValue);
                                }}
                                name="fechaFinal"
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box
                            sx={{
                                mt:1,
                                // mb:1,
                                // backgroundColor:'red',
                                width:'100%',
                                // textAlign:'right',
                            }}
                        >
                            <Button 
                                variant="contained" 
                                endIcon={<EditIcon />}
                                sx={{
                                    backgroundColor:'#707070',
                                    textTransform:'none',
                                    borderRadius:'10px',
                                    width:{ xs:'100%', sm:'100%', md:'40%' },
                                    mr:0.5,
                                }}
                                onClick={getAgregar}
                            >
                                <b>Cancelar</b>
                            </Button>
                            <Button 
                                variant="contained" 
                                endIcon={<EditIcon />}
                                sx={{
                                    backgroundColor:'#707070',
                                    textTransform:'none',
                                    borderRadius:'10px',
                                    width:{ xs:'100%', sm:'100%', md:'40%' }
                                }}
                                type='submit'
                            >
                                <b>Guardar</b>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export { AddEducacion }