import { Button, Container, Grid, Rating, TextareaAutosize, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useEffect, useState } from "react";

import imagenprueba from '../../assets/imagenprueba.jpg'
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { LoadingButton } from "@mui/lab";

const QueremosSaberTuOpinion = ({ modelo }) =>{
    const [calificacion, setCalificacion] = useState(0);
    const [cliente, setCliente] = useState(null);

    const [reviewSendSuccess, setReviewSendSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const addReview = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const token = !localStorage.getItem('token') ? null: localStorage.getItem('token');
        const folio = !localStorage.getItem('id') ? null: localStorage.getItem('id');
        setLoading(true);
        // console.log(token);
        // console.log(folio);
        // console.log(data.get('nombre'));
        // console.log(data.get('texto'));
        // console.log(calificacion);
        // console.log(modelo.id);
        
        axios.post(`${process.env.REACT_APP_API_URL}/api/review/modelo`,
        {
            folio: localStorage.getItem('type') ? localStorage.getItem('type') === 'cliente' ? folio : null : null,
            idModelo: modelo.id,
            nombre: data.get('nombre'),            
            calificacion: calificacion,
            descripcion: data.get('texto'),
        }
        )
        .then((response) => {
            // handle success
            console.log(response);
            setReviewSendSuccess(true);
            setLoading(false);
        })
        .catch((error) => {
            // handle success
            console.log(error);
        })
        .then(() => {
            // handle success
            //console.log('sabe');
        });

    }

    useEffect(()=>{
        if(localStorage.getItem('token') && localStorage.getItem('type')){
            if(localStorage.getItem('type') === 'cliente'){
                setCliente(localStorage.getItem('id'));
            }
        }
        setReviewSendSuccess(false);
    },[modelo])
    return(
        <Container
            sx={{
                paddingTop:2,
                paddingBottom: 3,
                //textAlign:'center',
                color: '#F5323F',
                fontFamily:'Montserrat',
            }}
        >
            <Box sx={{ color:'#f5323f',/*backgroundColor:'yellow'*/ }}>
                <h1>Queremos Saber tu Opinion</h1>                
            </Box>
            <Box>
                <Grid 
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    {/* <Grid item xs={12} sm={6} md={6} sx={{ display:{ xs:'block', sm:'none', md:'none' } }}>
                        <Box
                            component='img'
                            src={imagenprueba}
                            sx={{
                                width:'50%',
                                //height:450,
                            }}
                        />
                    </Grid> */}
                    <Grid item xs={12} sm={6} md={6}>
                        {
                            !reviewSendSuccess ? (
                                <Box
                                    component="form" 
                                    onSubmit={addReview}
                                    sx={{
                                        // backgroundColor:'green',
                                        textAlign:'left',
                                        mt:2,
                                    }}
                                >
                                    {
                                        !cliente && (
                                            <TextField  label="Nombre" variant="outlined" name="nombre" sx={{ width:{ xs:'100%', sm:'90%', md:'90%' }, mb:2, }} color='error' />
                                        )
                                    }                            
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="¿Que opinas de este modelo?"
                                        multiline
                                        rows={4}
                                        // defaultValue="Default Value"
                                        placeholder='Deja tu reseña sobre tu experiencia de compra con Casas Pecsa'
                                        sx={{
                                            width:{ xs:'100%', sm:'100%', md:'90%' },
                                            mb:2,
                                        }}
                                        color='error'
                                        name="texto"
                                    />
                                    <Box
                                        sx={{
                                            textAlign: { xs:'center', sm:'center', md:'left' },
                                            mb:2,
                                        }}
                                    >
                                        <Typography component="legend">¿Como calificarias este modelo?</Typography>
                                        <Rating
                                            name="simple-controlled"
                                            value={calificacion}
                                            onChange={(event, newValue) => {
                                            setCalificacion(newValue);
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            textAlign:'center',
                                            mb:2,
                                        }}
                                    >
                                        {
                                            !loading ? (
                                                <Button 
                                                    variant="outlined" 
                                                    size="medium" 
                                                    color="error"
                                                    sx={{
                                                        //p:{ xs:'none', sm:'none', md:1 },
                                                        borderColor:'#F5323F',
                                                        color:'#F5323F',
                                                        fontFamily:'Montserrat',
                                                    }}
                                                    type='submit'
                                                >
                                                    <b>Enviar</b>
                                                </Button>
                                            ) : (
                                                <LoadingButton loading variant="outlined">
                                                    Submit
                                                </LoadingButton>
                                            )
                                        }
                                    </Box>
                                </Box>
                            ) : (
                                <Box>
                                    <Typography variant="h6" gutterBottom>
                                        Gracias por tu opinion, es muy importante para nosotros.
                                    </Typography>
                                    <Box
                                        sx={{
                                            textAlign:'center'
                                        }}
                                    >
                                        <TaskAltIcon 
                                            sx={{ 
                                                fontSize: 300,
                                                color:'#707070',
                                            }}
                                        />
                                    </Box>
                                </Box>
                            )
                        }                                                                
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={{ display:{ xs:'none', sm:'block', md:'block' } }}>
                        <Box
                            component='img'
                            src={imagenprueba}
                            sx={{
                                maxWidth:'100%',
                                height:{ xs:'90%', sm:'90%', md:450 },
                            }}
                        />
                    </Grid>
                </Grid>                
            </Box>
            {/* <Box>
                <TextareaAutosize
                    aria-label="empty textarea"
                    placeholder="Deja tu reseña sobre tu experiencia de compra con Casas Pecsa"
                    style={{ 
                        width: '100%',
                        height: '100px',
                        borderRadius: '11px',
                        borderColor: '#F5323F',
                        marginTop: 2,
                        marginBottom:6
                    }}
                />
                <Button 
                    variant="outlined" 
                    size="medium" 
                    color="error"
                    sx={{
                        //p:{ xs:'none', sm:'none', md:1 },
                        borderColor:'#F5323F',
                        color:'#F5323F',
                        fontFamily:'Montserrat',
                    }}
                >
                    <b>Enviar</b>
                </Button>
            </Box> */}
        </Container>
    );
}

export { QueremosSaberTuOpinion }