import { Button, Divider, Grid, Rating, Skeleton, TextField, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import asesorPerfil2 from "../../assets/asesores/asesorPerfil2.png";

const CalificarAsesor = () => {
    const { folio } = useParams();

    const [asesor, setAsesor] = useState(null);
    const [calificacion, setCalificacion] = useState(0);
    const [comentario, setComentario] = useState('');

    const sendForm = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        // console.log({
        //     nombre: data.get('nombre'),
        //     puntuacion: calificacion,
        //     comentario: data.get('comentario'),
        // })
        // return;
        axios.post(`${process.env.REACT_APP_API_URL}/api/asesores/profile/${folio}/opiniones`,
        {
            nombre: data.get('nombre'),
            puntuacion: calificacion,
            comentario: data.get('comentario'),
        })
        .then((response) => {
            // handle success
            console.log(response);
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            // console.log('sae');
        });
    }
    
    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_API_URL}/api/asesores/data-form/${folio}`)
        .then((response) => {
            // handle success
            console.log(response);
            setAsesor(response.data);
        })
        .catch((error) => {
            // handle success
            console.log(error);
            // navigate('/usuarios/login');
        })
        .then(() => {
            // handle success
            // console.log('sae');
        });
    },[folio]);
    return(
        <Box>
            <Box
                sx={{
                    mt:12,
                }}
            >
                <Container>
                    <Box>
                        <Grid container>
                            <Grid item xs={12} sm={6} md={6}>                                
                                <Box
                                    sx={{
                                        // backgroundColor:'red',
                                        width:'100%',
                                        //height:'100%',
                                        display:'flex',
                                        alignItems:'center',
                                        alignContent:'center',
                                        overflow:'hidden',
                                    }}
                                >
                                    {
                                        asesor ? (
                                            <Box
                                                component='img'
                                                src={asesor.foto}
                                                sx={{
                                                    minWidth:'95%',
                                                    maxWidth:'110%',
                                                    m:'0 auto',
                                                    borderRadius:'12px',
                                                }}
                                            />
                                        ) : (
                                            <Skeleton/>
                                        )
                                    }
                                            
                                </Box>
                                <Box sx={{ ml:2, color:'#707070', }}>
                                    {
                                        asesor ? (
                                            <Box>
                                                <Typography variant="h5" sx={{ color:'#F5323F' }}>
                                                    <b>{ asesor.nombre }</b>
                                                </Typography>
                                                <Typography variant="body2">
                                                    Asesor
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <Skeleton/>
                                        )
                                    }                                                                                
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Box
                                    sx={{
                                        // backgroundColor:'yellow',
                                        width:'100%',
                                        display:'flex',
                                        alignItems:'center',
                                        alignContent:'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            // backgroundColor:'green',
                                            width:'90%',
                                            m:'0 auto',
                                            color:'#707070',
                                        }}
                                    >
                                        <Box component="form" onSubmit={sendForm}> 
                                            <Box
                                                sx={{
                                                    mt:1,                                                
                                                }}
                                            >
                                                <Typography variant="h6">
                                                    <b>Califica a tu asesor Pecsa</b>
                                                </Typography>
                                                <Typography variant="body1">
                                                    Tu opinión es muy importante para nosotros. Te agradeceremos calificar la atención recibida por nuestro asesor de ventas. Califica por favor seleccionando en el rango de estrellas, dónde la calificación más alta son 5.
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    mt:1,
                                                }}
                                            >
                                                <Typography component="legend">
                                                    <b>Calificalo</b>
                                                </Typography>
                                                <Rating
                                                    name="simple-controlled"
                                                    size="large"
                                                    value={calificacion}
                                                    onChange={(event, newValue) => {
                                                    setCalificacion(newValue);
                                                    }}
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    mt:1,
                                                    mb:2,
                                                }}
                                            >
                                                <Typography component="legend">
                                                    <b>Deja algun comentario sobre tu asesor.</b>
                                                </Typography>
                                                <TextField
                                                    id="outlined-multiline-static"
                                                    label="Comentario"
                                                    multiline
                                                    rows={4}
                                                    sx={{
                                                        width:'100%',
                                                        mt:1,
                                                    }}
                                                    name="comentario"
                                                />
                                            </Box>
                                            <Divider/>
                                            <Box
                                                sx={{
                                                    mt:1,                                                
                                                }}
                                            >
                                                <Typography variant="body1">
                                                    <b>Informacion Personal</b>
                                                </Typography>
                                                <Typography variant="body2">
                                                    Déjanos tu nombre y correo para recibir antes que nadie promociones, noticias, así como interesante información que harán la compra de tu casa más fácil.
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    mt:1,
                                                    mb:1,
                                                }}
                                            >
                                                <TextField id="outlined-basic" label="Nombre" variant="outlined" name="nombre" fullWidth />
                                            </Box>
                                            <Box
                                                sx={{
                                                    mt:1,
                                                    mb:1,
                                                }}
                                            >
                                                <TextField id="outlined-basic" label="Email" variant="outlined" name="email" fullWidth />
                                            </Box>
                                            <Box>
                                                <Box
                                                    sx={{
                                                        // backgroundColor:'yellowgreen',
                                                        width:'100%',
                                                        textAlign:'center',
                                                        
                                                    }}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color="inherit"
                                                        sx={{
                                                            mt:1,
                                                            mb:2,
                                                            width:'100%',
                                                            height:45,
                                                            backgroundColor:'#F5323F',
                                                            // borderColor:'#F5323F',
                                                            color:'white',
                                                            // borderWidth:2,
                                                            borderRadius:'6px',
                                                            '&:hover': {
                                                                backgroundColor: '#db0a18',
                                                                // color: 'white',
                                                            },
                                                            textTransform: 'none',
                                                        }}
                                                        type="submit" 
                                                    >
                                                        <b>Guardar</b>
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}

export { CalificarAsesor }