import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import { Box, Breadcrumbs, Container, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { Principal } from "./Principal";
import { OchoRazones } from "../../components/OchoRazones";
// import { EspecificacionesModelo } from "../../components/EspecificacionesModelo";
// import { PlantaArquitectonica } from "../../components/PlantaArquitectonica";
// import { Disponibilidad } from "../../components/Disponibilidad";
// import { ComparaMensualidades } from "../../components/Banners/ComparaMensualidades";
// import { ProcesosConstructivos } from "../../components/ProcesosConstructivos";
import { Garantias } from "../../components/Garantias";
// import { FeedbackModelo } from "../../components/FeedbackModelo";
// import { QueremosSaberTuOpinion } from "../../components/QueremosSaberTuOpinion";
// import { SlideAnuncios } from "../../components/SlideAnuncios";
// import { CaracteristicasLote } from "../../components/CaracteristicasLote";
// import { OrientcionDelSol } from "../../components/OrientacionDelSol";
// import { UbicacionDentroDelFraccionamiento } from "../../components/UbicacionDentroDelFraccionamiento";
import { UbicacionDelFraccionamiento } from "../../components/UbicacionDelFraccionamiento";
import { ServiciosDisponibles } from "../../components/ServiciosDisponibles";
import { LugaresCercanos } from "../../components/LugaresCercanos";
// import { BannerAsesores } from "../../components/BannerAsesores";
// import { LotesCarrusel } from "../../components/LotesCarrusel";
import { AmenidadesFraccionamiento } from "../../components/AmenidadesFraccionamiento";
import { PruebaCirculo } from "../../components/PruebaCirculo";
import { MapaFraccionamiento } from "../../components/MapaFraccionamiento";
import { BannerOpciones } from "../../components/BannerOpciones";
// import { SembradoCasas } from "../../components/SembradoCasas";
import HomeIcon from '@mui/icons-material/Home';
import { VideosFraccionamiento } from "../../components/VideosFraccionamiento";
import { MetaPixel } from "../../components/MetaPixel";
import { ModelosPorFraccionamiento } from "../../components/ModelosPorFraccionamiento";

const Fraccionamiento = ({ fraccionamiento }) => {
    let location = useLocation();
    console.log(fraccionamiento);
    const [fraccionamientoeBreadCrumb, setFraccionamientoeBreadCrumb] = useState(null);
    const [textoFraccionamientoBreadCrumb, setTextoFraccionamientoBreadCrumb] = useState(null);
    // const [fraccionamiento, setFraccionamiento] = useState(location.state.fraccionamiento || {});
    // const [fraccionamiento, setFraccionamiento] = useState(props.fraccionamiento || {});
    useEffect(()=>{
        window.scroll(0,0);
        setTextoFraccionamientoBreadCrumb(window.innerWidth > 591 ? `Fraccionamiento` : `Frac...` )
        //setFraccionamientoeBreadCrumb(window.innerWidth > 591 ? `${fraccionamiento.nombre} - Mzn:${lote.manzana.nombre} - ${lote.manzana.etapa.fraccionamiento.nombre}`: `Lote:${lote.numeroDeLote} - Mzn:${lote.manzana.nombre}...`);
    }, [fraccionamiento]);
    return(
        <div>
            <Helmet>
                <title>Casas Pecsa | { fraccionamiento.nombre }</title>
                <meta name="description" content="Conoce Nuestros Increíbles Fraccionamientos, Con Las Mejores Locaciones, Teniendo Cerca de ti Lo Indispensable Para vivir Adecuadamente. Puedes Visitarnos De Manera 100% Online, o Agendar Una Cita Para Conocerlo de Manera presencial." />
            </Helmet>
            <MetaPixel/>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, mt: { xs:7, sm:9, md: 11 } }}>
                <Container>
                    <Box
                        sx={{
                            // backgroundColor:'red',
                            pb:1,
                            pt:0.6,
                            pl:1,
                            color:'#707070',
                            
                        }}
                    >
                        <Breadcrumbs aria-label="breadcrumb">
                            {/* <Link underline="hover" color="inherit">
                                Inicio
                            </Link> */}
                            <Link to={'/'} style={{ color:'#707070', }}>
                                {/* Inicio */}
                                {/* <HomeIcon fontSize="small"/> */}
                                {
                                    window.innerWidth > 591 ? (`Inicio`) : (<HomeIcon fontSize="small" sx={{ color:'#707070' }}/>)
                                }
                            </Link>
                            <Typography sx={{ color:'#F5323F', }}>
                                { textoFraccionamientoBreadCrumb }
                            </Typography>
                            <Typography sx={{ color:'#F5323F', }}>
                                { fraccionamiento.nombre }
                                {/* { `${lote.manzana.etapa.fraccionamiento.nombre}` } */}
                            </Typography>
                        </Breadcrumbs>
                    </Box>
                </Container>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, /*mt: { xs:6.5, sm:8, md: 12 }*/ }}>
                <Principal fraccionamiento={fraccionamiento}/>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                <VideosFraccionamiento fraccionamiento={fraccionamiento}/>
            </Box> 
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'#F4F4F4' }}>
                <AmenidadesFraccionamiento amenidades={fraccionamiento.amenidades}/>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                <ModelosPorFraccionamiento fraccionamiento={fraccionamiento}/>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'#F4F4F4' }}>
                <MapaFraccionamiento fraccionamiento={fraccionamiento} />
            </Box>
            {/* {
                fraccionamiento.amenidades && (
                    fraccionamiento.amenidades.length > 0 && (
                        <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'#F4F4F4' }}>
                            <AmenidadesFraccionamiento amenidades={fraccionamiento.amenidades}/>
                        </Box>
                    )
                )
            }             */}

            {/* <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                <CaracteristicasLote/>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                <PlantaArquitectonica/>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'#F4F4F4' }}>
                <Garantias/>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                <OrientcionDelSol/>
            </Box> */}

            {/* <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                <SembradoCasas/>
            </Box> */}
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                <ServiciosDisponibles fraccionamiento={fraccionamiento}/>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'#F4F4F4' }}>
                <UbicacionDelFraccionamiento fraccionamiento={fraccionamiento}/>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                <LugaresCercanos lugares={fraccionamiento.lugaresCerca}/>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'#F4F4F4' }}>
                <Garantias/>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                <OchoRazones/>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'#F4F4F4' }}>
                <BannerOpciones />
            </Box>
            {/* <Box sx={{ display: { xs: 'flex', md: 'flex' }, backgroundColor:'#F4F4F4' }}>
                <ComparaMensualidades/>
            </Box> */}
            {/* <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                <SimuladorDeCredito modelo={modelo} simulador={simulador}/>
            </Box> */}
            {/* <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                <BannerAsesores/>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, }}>
                <LotesCarrusel/>
            </Box> */}
        </div>
    );
}

export { Fraccionamiento };